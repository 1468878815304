export function getCookie(name): string | null {
  const nameEQ = name + "=";
  const cookieArray = document.cookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  return null;
}

export function setCookie({
  name,
  value,
  days,
  domain,
}: {
  name: string;
  value: string;
  days: number;
  domain: string;
}) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  // Set the domain attribute to share the cookie across subdomains
  const domainAttribute = domain ? "; domain=" + domain : "";

  // Set the cookie with the specified name, value, expiration date, and domain
  document.cookie =
    name + "=" + (value || "") + expires + "; path=/" + domainAttribute;
}
