import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { rhapsodyApi } from "../api/rhapsodyApi";

const chairsAdapter = createEntityAdapter<RhapsodyChair_Entity>();
const initialState = chairsAdapter.getInitialState();

export const chairEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getChairs: build.query<
      EntityState<RhapsodyChair_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `chairs`,
          params: args,
        };
      },
      transformResponse: (responseData: RhapsodyChair_Entity[]) => {
        return chairsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["chairs"],
    }),
    chairsReorder: build.mutation<
      number,
      {
        id: number;
        body: {
          projectID: number;
          sectionID?: number;
          preview?: boolean;
          position: number;
          chairIDs?: number[];
          workSessionIDs?: number[];
          workSessionID?: number;
        };
      }
    >({
      query: (args) => {
        return {
          method: "PUT",
          body: args.body,
          url: `chairs/reorder`,
        };
      },
      invalidatesTags: ["chairs"],
    }),
    chairsGravity: build.mutation<
      number,
      {
        id: number;
        body: {
          projectID: number;
          sectionID?: number;
          preview?: boolean;
          workSessionIDs?: number[];
          workSessionID?: number;
          chairIDs?: number[];
        };
      }
    >({
      query: (args) => {
        return {
          method: "PUT",
          body: args.body,
          url: `chairs/gravity`,
        };
      },
    }),
    cleanChairs: build.mutation<
      number,
      {
        id: number;
        body: {
          projectID: number;
          preview?: boolean;
        };
      }
    >({
      query: (args) => {
        return {
          method: "PUT",
          body: args.body,
          url: `chairs/cleanup`,
        };
      },
    }),
    getChair: build.query<RhapsodyChair_Entity, number>({
      query: (id) => `chairs/${id}`,
      providesTags: (result, error, id) => [{ type: "chairs", id }],
    }),
    createChair: build.mutation<
      RhapsodyChair_Entity,
      Partial<RhapsodyChair_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `chairs`,
      }),
      invalidatesTags: ["chairs"],
    }),
    createChairs: build.mutation<
      RhapsodyChair_Entity,
      Partial<RhapsodyChair_Entity>[]
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `chairs`,
      }),
      invalidatesTags: ["chairs", "assignments"],
    }),
    createChairs2: build.mutation<
      RhapsodyChair_Entity,
      Partial<RhapsodyChair_Entity>[]
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `chairs`,
      }),
      invalidatesTags: [],
    }),
    updateChair: build.mutation<
      void,
      { id: number; body: Partial<RhapsodyChair_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `chairs/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "chairs", id },
        "chairs",
        "assignments",
      ],
    }),
    updateBatchChairs: build.mutation<
      RhapsodyChair_Entity,
      Partial<RhapsodyChair_Entity>[]
    >({
      query: (body) => ({
        method: "PUT",
        body: body,
        url: `chairs/batch`,
      }),
      invalidatesTags: () => ["chairs", "assignments"],
    }),
    deleteChair: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `chairs/${id}`,
      }),
      invalidatesTags: ["chairs"],
    }),
    deleteBatchChairs: build.mutation<
      RhapsodyChair_Entity,
      Partial<RhapsodyChair_Entity>[]
    >({
      query: (body) => ({
        method: "DELETE",
        body: body,
        url: `chairs/batch`,
      }),
      invalidatesTags: () => ["chairs", "assignments"],
    }),
  }),
});

export const {
  useChairsReorderMutation,
  useCleanChairsMutation,
  useChairsGravityMutation,
  useDeleteBatchChairsMutation,
  useUpdateBatchChairsMutation,
  useCreateChairsMutation,
  useLazyGetChairsQuery,
  useGetChairQuery,
  useGetChairsQuery,
  useCreateChairs2Mutation,
  useCreateChairMutation,
  useDeleteChairMutation,
  useUpdateChairMutation,
} = chairEndpoints;

export default chairEndpoints;
