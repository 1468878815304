import {
  Avatar,
  Button,
  IconButton,
  Input,
  ListItemDecorator,
  Option,
  Select,
  Slider,
  Switch,
  Tab,
  TabList,
  Tabs,
  Typography,
  useTheme,
} from "@mui/joy";
import { Hidden, Tooltip, useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import moment from "moment";
// material-ui
import { REACT_APP_API } from "config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useWorkSession } from "redux/workSession/workSessionHooks";
import { useWorkSessionTypes } from "redux/workSessionType/workSessionTypeHooks";
import {
  selectedWorkSessionIDSelector,
  setDescription420,
  setFormOpen,
} from "reducers/rhapsody";
import {
  overture,
  useMySubscription,
} from "hooks/subscription/restrictionHooks";
import { useDispatch } from "react-redux";
import { useExporter } from "features/exporter/exporter";

const fonts = ["helvetica", "courier", "times"];

const checks = [];

const labels = {};

const marks = [
  {
    value: -5,
    label: "-5",
  },
  {
    value: -4,
  },
  {
    value: -3,
  },
  {
    value: -2,
  },
  {
    value: -1,
  },
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
    label: "+5",
  },
];

export default function ReportForm() {
  const [pdf, setPDF] = useState();
  const { workSessionTypes: sessionTypes } = useWorkSessionTypes();
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const { workSession } = useWorkSession(workSessionID);
  const theme = useTheme();
  const dispatch = useDispatch();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const mySubscription = useMySubscription();
  const exporter = useExporter();

  const [config, setConfig] = useState({
    font: "helvetica",
    orientation: workSession?.sessionTypeID === 2 ? "landscape" : "portrait",
    options: {
      formTitle:
        workSession?.sessionTypeID > 1
          ? "AMERICAN FEDERATION OF MUSICIANS REPORT FORM"
          : "",
      formID: `${workSession?.sessionTypeID}`,
      formSubtitle: "Continuation Sheet",
      fontSizeZoom: "0",
    },
  });

  useEffect(() => {
    refreshPDF(config);
  }, []);

  const refreshPDF = async (c) => {
    await fetch(
      `${REACT_APP_API}/rhapsody/workSessions/${workSessionID}/reportForm`,
      {
        body: JSON.stringify(c),
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
      }
    )
      .then((response) => response.blob())
      .then((d) => {
        setPDF(window.URL.createObjectURL(d));
      });
  };

  const downloadPDF = async () => {
    await fetch(
      `${REACT_APP_API}/rhapsody/workSessions/${workSessionID}/reportForm`,
      {
        body: JSON.stringify({ ...config, disposition: "attachment" }),
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
      }
    )
      .then((response) => response.blob())
      .then((d) => {
        downloadBlob(d);
      });
  };

  function downloadBlob(blob) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob)
      return window.navigator.msSaveOrOpenBlob(blob);

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = data;
    link.download = `${workSession.title}_${moment(
      workSession.dateFromUTC
    ).format("MM/DD/YYYY")}_Report_Form.pdf`;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  if (mySubscription === overture) {
    return (
      <Box
        sx={{
          display: "flex",
          p: 2,
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <i style={{ fontSize: 48 }} className="fa-duotone fa-file-invoice"></i>
        <Typography level="h6">Subscribe to generate Report Forms</Typography>
        <Typography level="body2">
          This feature is exclusive to the Finale plan.
        </Typography>
        <Button
          onClick={() => {
            dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
            dispatch(
              setDescription420(
                "Report forms is an exclusive to Mezzo & Finale."
              )
            );
          }}
        >
          Subscribe Now
        </Button>
      </Box>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flex: 1,
        alignItems: "stretch",
      }}
    >
      <Hidden smDown>
        {workSession.id ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
            key={1}
          >
            {pdf ? (
              <iframe
                title="preview"
                type="application/pdf"
                src={pdf}
                style={{ flex: 1, height: "100%", width: "100%" }}
              />
            ) : (
              []
            )}
          </div>
        ) : (
          []
        )}
      </Hidden>
      <Hidden smDown>
        <Divider orientation="vertical" />
      </Hidden>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          position: "relative",
          width: !sm ? 280 : "100%",
        }}
      >
        <Box
          sx={{
            p: 1,
            overflow: "auto",
            height: 700,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Button
              onClick={downloadPDF}
              startDecorator={<i class="fa-regular fa-file-arrow-down"></i>}
            >
              Download Report Form
            </Button>
            <Button
              onClick={() => exporter.workSessionContinuationForm()}
              variant="soft"
              color="neutral"
              startDecorator={<i class="fa-regular fa-file-arrow-down"></i>}
            >
              Export in Excel
            </Button>
            <Box>
              <Typography level="body2">Title</Typography>
              <Input
                value={config.options.formTitle ?? ""}
                placeholder="Form Title"
                variant="outlined"
                onBlur={() => refreshPDF(config)}
                onChange={(e) =>
                  setConfig((c) => ({
                    ...c,
                    options: { ...c.options, formTitle: e.target.value },
                  }))
                }
              />
            </Box>
            <Box>
              <Typography level="body2">Description</Typography>
              <Input
                value={config.options.formDescription ?? ""}
                placeholder="Form Description"
                variant="outlined"
                onBlur={() => refreshPDF(config)}
                onChange={(e) =>
                  setConfig((c) => ({
                    ...c,
                    options: { ...c.options, formDescription: e.target.value },
                  }))
                }
              />
            </Box>
            <Box>
              <Typography level="body2">Subtitle</Typography>
              <Input
                value={config.options.formSubtitle ?? ""}
                placeholder="Form Subtitle"
                variant="outlined"
                onBlur={() => refreshPDF(config)}
                onChange={(e) =>
                  setConfig((c) => ({
                    ...c,
                    options: { ...c.options, formSubtitle: e.target.value },
                  }))
                }
              />
            </Box>
            <Box>
              <Typography level="body2">Font</Typography>
              <Tabs
                aria-label="Plain tabs"
                value={fonts.indexOf(config.font)}
                size="sm"
                onChange={(event, value) =>
                  setConfig((c) => {
                    const _c = { ...c, font: fonts[value] };
                    refreshPDF(_c);
                    return _c;
                  })
                }
                sx={{ borderRadius: "lg" }}
              >
                <TabList variant="outlined">
                  {fonts.map((f) => (
                    <Tab
                      variant={config.font === f ? "soft" : "plain"}
                      color={config.font === f ? "primary" : "neutral"}
                      key={f}
                    >
                      {f}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            </Box>
            <Box>
              <Typography level="body2">Orientation</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Tooltip title="Portrait">
                  <IconButton
                    variant={
                      config.orientation === "portrait" ? "soft" : "outlined"
                    }
                    color={
                      config.orientation === "portrait" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setConfig((c) => {
                        const _c = { ...c, orientation: "portrait" };
                        refreshPDF(_c);
                        return _c;
                      })
                    }
                  >
                    <i class="fa-duotone fa-image-portrait"></i>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Landscape">
                  <IconButton
                    variant={
                      config.orientation === "landscape" ? "soft" : "outlined"
                    }
                    color={
                      config.orientation === "landscape" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setConfig((c) => {
                        const _c = { ...c, orientation: "landscape" };
                        refreshPDF(_c);
                        return _c;
                      })
                    }
                  >
                    <i class="fa-duotone fa-image-landscape"></i>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ pr: 1 }}>
              <Typography level="body2">
                Zoom: {config.options.fontSizeZoom}
              </Typography>
              <Slider
                track={false}
                aria-labelledby="track-false-slider"
                defaultValue={0}
                marks={marks}
                min={-5}
                max={5}
                step={1}
                onChangeCommitted={() => refreshPDF(config)}
                onChange={(e, v) =>
                  setConfig((c) => ({
                    ...c,
                    options: { ...c.options, fontSizeZoom: `${v}` },
                  }))
                }
              />
            </Box>
            <Typography level="body2">Options</Typography>
            <Box>
              <Typography level="body2">Form ID</Typography>
              <Select
                value={config.options.formID ?? ""}
                onChange={(e, v) =>
                  setConfig((c) => {
                    const _c = {
                      ...c,
                      options: { ...c.options, formID: `${v}` },
                    };
                    refreshPDF(_c);
                    return _c;
                  })
                }
              >
                {sessionTypes.map((st) => (
                  <Option
                    value={`${st.id}`}
                    key={st.id}
                    sx={{ display: "flex", gap: 1 }}
                  >
                    <ListItemDecorator>
                      <Avatar
                        color="primary"
                        variant="soft"
                        size="sm"
                        sx={{ height: 24, width: 24 }}
                      >
                        {st.code}
                      </Avatar>
                    </ListItemDecorator>
                    {st.name}
                  </Option>
                ))}
              </Select>
            </Box>
            {checks.map((option) => (
              <Box
                key={option}
                sx={{ display: "flex", justifyContent: "space-between" }}
                onClick={() => {
                  setConfig((c) => ({
                    ...c,
                    options: {
                      ...c.options,
                      [option]: c.options[option] === "true" ? "false" : "true",
                    },
                  }));
                }}
              >
                <Typography level="body2">{labels[option]}</Typography>
                <Switch checked={config.options[option] === "true"} size="sm" />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
