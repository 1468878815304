import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { ProjectPieceChair_Entity } from "entities/projectPieceChair";
import { rhapsodyApi } from "../api/rhapsodyApi";
import { ProjectPieceSection_Entity } from "entities/projectPieceSection";

const projectPieceChairsAdapter =
  createEntityAdapter<ProjectPieceChair_Entity>();
const initialState = projectPieceChairsAdapter.getInitialState();

export const projectPieceChairEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectPieceChairs: build.query<
      EntityState<ProjectPieceChair_Entity>,
      number
    >({
      query: (projectPieceID) =>
        `projectPieceChairs/${projectPieceID}/sections`,
      transformResponse: (responseData: ProjectPieceSection_Entity[]) => {
        const projectPieceChairs: ProjectPieceChair_Entity[] = [];
        responseData.forEach((s) => projectPieceChairs.push(...s.chairs));

        return projectPieceChairsAdapter.setAll(
          initialState,
          projectPieceChairs
        );
      },
      providesTags: ["projectPieceChairs"],
    }),
    getProjectPieceChair: build.query<ProjectPieceChair_Entity, number>({
      query: (id) => `projectPieceChairs/${id}`,
      providesTags: (result, error, id) => [{ type: "projectPieceChairs", id }],
    }),
    createProjectPieceChair: build.mutation<
      ProjectPieceChair_Entity,
      Partial<ProjectPieceChair_Entity> | Partial<ProjectPieceChair_Entity>[]
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `projectPieceChairs`,
      }),
      invalidatesTags: ["projectPieceChairs", "projectPieceSections"],
    }),
    updateBatchProjectPieceChair: build.mutation<
      ProjectPieceChair_Entity,
      Partial<ProjectPieceChair_Entity>[]
    >({
      query: (body) => ({
        method: "PUT",
        body: body,
        url: `projectPieceChairs/batch`,
      }),
      invalidatesTags: ["projectPieceChairs", "projectPieceSections"],
    }),
    updateProjectPieceChair: build.mutation<
      void,
      { id: number; body: Partial<ProjectPieceChair_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projectPieceChairs/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "projectPieceChairs", id },
        "projectPieceChairs",
        "projectPieceSections",
      ],
    }),
    deleteProjectPieceChair: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projectPieceChairs/${id}`,
      }),
      invalidatesTags: ["projectPieceChairs", "projectPieceSections"],
    }),
  }),
});

export const {
  useGetProjectPieceChairQuery,
  useUpdateBatchProjectPieceChairMutation,
  useGetProjectPieceChairsQuery,
  useCreateProjectPieceChairMutation,
  useDeleteProjectPieceChairMutation,
  useUpdateProjectPieceChairMutation,
} = projectPieceChairEndpoints;

export default projectPieceChairEndpoints;
