import {
  Alert,
  Button,
  Checkbox,
  Chip,
  ColorPaletteProp,
  IconButton,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { show as showIntercom } from "@intercom/messenger-js-sdk";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { useUpdateCompanyMutation } from "redux/company/companyEndpoints";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";
import { meSelector } from "selectors/me";

/**
 *
 * @returns {ReactElement} StripeBalance page
 */
export function StripeBalance() {
  const open = useSelector(formOpenSelector("stripeBalance"));
  const dispatch = useDispatch();
  const settings = useCompanySettings();
  const me = useSelector(meSelector);
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;
  const [updateCompany] = useUpdateCompanyMutation();
  const { company } = useCurrentCompany();

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "stripeBalance" }));
  };

  const [dueDate, setDueDate] = useState("");
  const [balance, setBalance] = useState("");
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (settings) {
      setDueDate(settings.stripeBalance?.balance);
      setDueDate(settings.stripeBalance?.dueDate);
      setShow(settings.stripeBalance?.show ?? false);
      setBalance(settings.stripeBalance?.balance);
      setBlocking(settings.stripeBalance?.blocking ?? false);
      setRows(settings.stripeBalance?.rows ?? []);
    }
  }, [settings]);

  const onSave = () => {
    updateCompany({
      id: company.id,
      body: {
        ...company,
        settings: JSON.stringify({
          ...settings,
          stripeBalance: {
            dueDate,
            show,
            blocking,
            rows,
            balance,
          },
        }),
      },
    });
  };

  const onRowUpdate = async (row, old) => {
    return new Promise((resolve) => {
      const index = rows.findIndex((r) => r.id === old.id);
      setRows((r) => {
        const _r = JSON.parse(JSON.stringify(r));
        _r[index] = row;
        return _r;
      });
      resolve(row);
    });
  };

  const columns: any = [
    {
      field: "id",
      headerName: "Invoice #",
      width: 150,
      editable: superadmin,
    },
    {
      field: "invoiceDate",
      headerName: "Sent",
      width: 120,
      editable: superadmin,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 120,
      editable: superadmin,
    },
    {
      field: "status",
      headerName: "Status",
      editable: superadmin,
      renderCell: (e) => {
        let color: ColorPaletteProp = "primary";
        if (e.value === "Past Due") color = "danger";
        return (
          <Chip size="sm" color={color} variant="soft">
            {e.value}
          </Chip>
        );
      },
    },
    {
      field: "amount",
      editable: superadmin,
      align: "right",
      headerName: "Amount",
      flex: 1,
      headerAlign: "right",
      renderHeader: (e) => (
        <Typography
          level="body2"
          sx={{ textAlign: "right", flex: 1, fontWeight: 500 }}
        >
          Amount
        </Typography>
      ),
    },
    {
      field: "url",
      editable: superadmin,
      headerName: "Pay Now",
      renderCell: (e) => (
        <Button
          variant="solid"
          color="neutral"
          size="sm"
          onClick={() => window.open((e.row as any).url, "blank")}
        >
          Pay Now
        </Button>
      ),
    },
  ];

  if (superadmin)
    columns.push({
      field: "delete",
      headerName: "",
      width: 30,
      renderCell: (e) => (
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          onClick={() => setRows((r) => r.filter((f) => f.id !== e.row.id))}
        >
          <i className="fa-solid fa-trash"></i>
        </IconButton>
      ),
    });

  let color: ColorPaletteProp = "warning";
  if (blocking) color = "danger";

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={!blocking || superadmin ? onClose : undefined}
    >
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-solid fa-triangle-exclamation"></i>}
          level="h5"
          color={color}
        >
          {balance} Outstanding Balance
        </Typography>
      </DialogTitle>
      <DialogContent>
        {blocking ? (
          <Alert color="danger" variant="solid" sx={{ mb: 1 }}>
            Your access to Rhapsody has been suspended.
          </Alert>
        ) : (
          []
        )}
        <b>{company?.name}</b>: Our records show that we are still awaiting
        payment of past due invoices.
        <br />
        {!blocking ? (
          <span>
            To avoid any interruption of service, We kindly request that you
            settle the outstanding balance{" "}
            {dueDate ? (
              <span>
                by{" "}
                <b style={{ color: "#ED6D03" }}>
                  {moment(dueDate).format("ll")}
                </b>
              </span>
            ) : (
              ""
            )}
            .
            <br />
            <br />
          </span>
        ) : (
          <span>
            Proceed to the payement of the following invoice
            {rows.length > 1 ? "s" : ""} to re-activate your account.
            <br />
            <br />
          </span>
        )}
        If you have any questions or require further assistance, please don’t
        hesitate to contact us.
        <br />
        <br />
        <DataGridPremium
          processRowUpdate={onRowUpdate}
          autoHeight
          hideFooter
          columns={columns}
          rows={rows}
        />
        {superadmin ? (
          <Sheet
            color="info"
            variant="soft"
            sx={{
              mt: 2,
              p: 1,
              borderRadius: 8,
              display: "flex",
              gap: 1,
              flexDirection: "column",
            }}
          >
            Only Super Admins see this
            <Checkbox
              checked={show}
              onChange={(e) => setShow(e.target.checked)}
              label="Show at launch"
            ></Checkbox>
            <Checkbox
              checked={blocking}
              onChange={(e) => setBlocking(e.target.checked)}
              label="Blocking window"
            ></Checkbox>
            <Input
              startDecorator={"Due Date: "}
              size="sm"
              type="date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
            <Input
              startDecorator={"Balance: "}
              size="sm"
              value={balance}
              onChange={(e) => setBalance(e.target.value)}
            />
            <Chip
              size="sm"
              color="info"
              onClick={() =>
                setRows((r) => [
                  ...r,
                  {
                    id: moment().utc().format(),
                    invoiceDate: "",
                    dueDate: "",
                    amount: "",
                    status: "",
                    url: "",
                  },
                ])
              }
            >
              New Row
            </Chip>
            <Button onClick={onSave} color="info">
              Save Configuration
            </Button>
          </Sheet>
        ) : (
          []
        )}
        <br />
        <Typography level="body3">
          You can ignore this message if you have already paid the outstanding
          balance.
        </Typography>
      </DialogContent>
      <DialogActions>
        {!blocking || superadmin ? (
          <Button variant="soft" color="neutral" onClick={onClose}>
            Close
          </Button>
        ) : (
          []
        )}
        <Button
          onClick={showIntercom}
          startDecorator={<i className="fa-solid fa-comment"></i>}
        >
          Chat with us
        </Button>
      </DialogActions>
    </Dialog>
  );
}
