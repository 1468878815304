/**
 * ProjectPiece_Entity type guard.
 *
 * @param {any} projectPieceJson ProjectPiece object from API
 * @returns {boolean} Return true if type is ProjectPiece_Entity
 */

export default class ProjectPiece_Entity {
  constructor(projectPieceJson: ProjectPiece_Entity) {
    try {
      this.id = projectPieceJson.id;
      this.pieceID = projectPieceJson.pieceID;
      this.projectID = projectPieceJson.projectID;
      this.createdAt = projectPieceJson.createdAt;
      this.description = projectPieceJson.description;
      this.position = projectPieceJson.position;
      this.updatedAt = projectPieceJson.updatedAt;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  pieceID: number;
  projectID: number;
  position: number;
  description: string;
  createdAt: string;
  updatedAt: string;
}
