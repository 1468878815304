import {
  createSelector,
  createSlice,
  Dictionary,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ProjectHiring, ProjectHiring_Entity } from "entities/projectHiring";
import { WorkSession_Entity } from "entities/workSession";
import { mapToArray } from "helpers";
import { RootState } from "store";

type Hover = {
  musicianID: number;
  sectionOrder: number;
  sectionID: number;
};

interface ProjectRostersState {
  selectedSessionMap: Dictionary<WorkSession_Entity>;
  hover?: Hover;
  grouped: boolean;
}

const initialState = {
  selectedSessionMap: {},
  grouped: window.localStorage.getItem("projectRostersGrouped") === "true",
} as ProjectRostersState;

export const projectRostersSlice = createSlice({
  name: "projectRosters",
  initialState,
  reducers: {
    projectRosters_UnselectAll_WorkSessions(state) {
      state.selectedSessionMap = {};
    },
    setProjectRostersGrouped(state, action: PayloadAction<boolean>) {
      state.grouped = action.payload;
    },
    projectRosters_Select_WorkSessions(
      state,
      action: PayloadAction<WorkSession_Entity[]>
    ) {
      const workSessions = action.payload;
      workSessions.forEach((m) => {
        state.selectedSessionMap[m.id] = m;
      });
    },
    projectRosters_Unselect_WorkSessions(
      state,
      action: PayloadAction<WorkSession_Entity[]>
    ) {
      const workSessions = action.payload;
      workSessions.forEach((m) => {
        delete state.selectedSessionMap[m.id];
      });
    },
    projectRosters_Set_Hover(state, action: PayloadAction<Hover>) {
      const hover = action.payload;
      state.hover = hover;
    },
  },
});

export const {
  projectRosters_UnselectAll_WorkSessions,
  projectRosters_Select_WorkSessions,
  projectRosters_Unselect_WorkSessions,
  projectRosters_Set_Hover,
  setProjectRostersGrouped,
} = projectRostersSlice.actions;

export const projectRostersSelectionSelector = createSelector(
  (s: RootState) => s.projectRosters.selectedSessionMap,
  (selectedSessionMap) => ({
    selectedSessions: mapToArray(selectedSessionMap),
    selectedSessionMap,
  })
);

export const projectRostersHoverSelector = createSelector(
  (s: RootState) => s.projectRosters.hover,
  (hover) => hover
);

export const projectRostersIsHoverSelector = (payload: Hover) => {
  return createSelector(
    (s: RootState) => s.projectRosters.hover,
    (hover) => {
      const emptyChair = hover && hover.musicianID === undefined;
      const sameMusician = hover?.musicianID === payload?.musicianID;
      const sameSection = payload && hover?.sectionID === payload?.sectionID;
      const sameSectionOrder =
        payload && hover?.sectionOrder === payload?.sectionOrder;

      if (emptyChair) {
        return sameSection && sameSectionOrder && sameMusician;
      }

      return sameMusician && sameSection;
    }
  );
};

export const jobToUpdateSelector = (projectHiring: ProjectHiring_Entity) =>
  createSelector(
    [
      (s: RootState) => s.projectRosters.hover,
      (s: RootState) => s.projectRosters.selectedSessionMap,
    ],
    (hover, selection) => {
      if (!hover || !projectHiring) return [];
      if (hover.musicianID) {
        return projectHiring.jobs.filter(
          (j) =>
            j.musicianID === hover.musicianID &&
            j.sectionID === hover.sectionID &&
            selection[j.sessionID]
        );
      }
      return projectHiring.jobs.filter(
        (j) =>
          j.sectionOrder === hover.sectionOrder &&
          j.sectionID === hover.sectionID &&
          selection[j.sessionID]
      );
    }
  );

export const projectRostersGroupedSelector = createSelector(
  (s: RootState) => s.projectRosters.grouped,
  (s) => s
);
