import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
// material-ui
// constants
import { EMAIL_REGEXP } from "config/regexp";
import { useEffect, useState } from "react";
// styles
import {
  Alert,
  CircularProgress,
  IconButton,
  Sheet,
  Typography,
} from "@mui/joy";
import { Grow, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { NerdPhoneField } from "@nerdjs/nerd-ui";
import { useUpdateCurrentUserMutation } from "redux/user/userEndpoint";
import { useCurrentUser } from "redux/user/userHook";
import {
  useDeleteVerificationCodeMutation,
  useLazyResendVerificationCodeQuery,
  useValidateVerificationCodeMutation,
} from "redux/verificationCode/verificationCodeEndpoints";

export default function FormPersonalInfo() {
  const [validEmail, setValidEmail] = useState();
  const [hasChanged, setHasChanged] = useState(false);
  const { me } = useCurrentUser();
  const [updateCurrentUser] = useUpdateCurrentUserMutation();
  const [_me, setMe] = useState(me);

  useEffect(() => {
    setMe(me);
  }, [me]);

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;
    if (name === "email") {
      let _validEmail = false;

      if (EMAIL_REGEXP.test(value)) {
        _validEmail = true;
      }

      setValidEmail(_validEmail);
    }

    setMe((m) => ({ ...m, [name]: value }));
    setHasChanged(true);
  };

  const isValid = () => {
    return hasChanged && EMAIL_REGEXP.test(_me?.email);
  };

  const save = async () => {
    updateCurrentUser({ body: _me });
    setHasChanged(false);
  };

  return (
    <Sheet variant="soft" sx={{ p: 2, borderRadius: "8px" }}>
      <Typography level="h6" gutterBottom>
        User Information
      </Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Input
            onBlur={save}
            fullWidth
            id="firstName"
            startDecorator="First Name *"
            value={_me?.firstName ?? ""}
            onChange={handleChange("firstName")}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            fullWidth
            onBlur={save}
            id="lastName"
            startDecorator="Last Name *"
            value={_me?.lastName ?? ""}
            onChange={handleChange("lastName")}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            fullWidth
            onBlur={save}
            error={!validEmail && !EMAIL_REGEXP.test(_me?.email)}
            id="email"
            helperText={
              !validEmail && !EMAIL_REGEXP.test(_me?.email)
                ? "Email not valid"
                : ""
            }
            startDecorator="Login Email *"
            value={_me?.email ?? ""}
            onChange={handleChange("email")}
          />
          <Box sx={{ mt: 1, display: "flex", flexDirection: "column", gap: 1 }}>
            {_me?.verificationCodes
              ?.filter((s) => s.kind === "EMAIL")
              .map((vc) => (
                <VerifyContact vc={vc} key={vc.id} />
              ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <NerdPhoneField
            value={_me?.phone ?? ""}
            onAccept={(e) => handleChange("phone")({ target: { value: e } })}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              onBlur: save,
            }}
          />
          <Typography level="body3">
            OTP Code will be sent by SMS to this phone number.
          </Typography>
          <Box sx={{ mt: 1, display: "flex", flexDirection: "column", gap: 1 }}>
            {_me?.verificationCodes
              ?.filter((s) => s.kind === "PHONE")
              .map((vc) => (
                <VerifyContact vc={vc} key={vc.id} />
              ))}
          </Box>
        </Grid>
      </Grid>
    </Sheet>
  );
}

function VerifyContact({ vc }) {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [resent, setResent] = useState(false);
  const [resendVerificationCode] = useLazyResendVerificationCodeQuery();
  const [validateVerificationCode] = useValidateVerificationCodeMutation();
  const [deleteVerificationCode] = useDeleteVerificationCodeMutation();

  const validate = () => {
    setLoading(true);
    validateVerificationCode({ code, value: vc.value })
      .unwrap()
      .then((r) => setLoading(false));
  };

  const resend = () => {
    resendVerificationCode(vc.id);
    setResent(true);
  };

  const dismissVerificationCode = () => {
    deleteVerificationCode(vc.id);
  };

  return (
    <Alert size="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "start" }}>
          <Typography level="body2" sx={{ color: "inherit", flexGrow: 1 }}>
            Enter the code you received in order to validate your new{" "}
            {vc.kind === "EMAIL" ? "email address" : "phone number"}:{" "}
            <b>{vc.value}</b>
          </Typography>
          <Tooltip title="Cancel email change">
            <IconButton onClick={dismissVerificationCode}>
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          </Tooltip>
        </Box>
        <Input
          type="tel"
          startDecorator={loading ? <CircularProgress size="sm" /> : []}
          endDecorator={
            <Button
              size="sm"
              variant="solid"
              color="primary"
              onClick={validate}
            >
              Verify
            </Button>
          }
          value={code}
          onChange={(e) => setCode(e.target.value)}
          size="sm"
          placeholder="Verfication Code"
        />
        {!resent ? (
          <Typography level="body3">
            Have not received the code?{" "}
            <u style={{ cursor: "pointer" }} onClick={resend}>
              Resend now
            </u>
          </Typography>
        ) : (
          <Grow in>
            <Typography level="body3">Code has been resent!</Typography>
          </Grow>
        )}
      </Box>
    </Alert>
  );
}
