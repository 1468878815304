import { MercuryJob_Entity } from "entities/mercuryJob";

export default class Assignment_Entity {
  constructor(assignmentJson: Assignment_Entity) {
    try {
      this.id = assignmentJson.id;
      this.active = assignmentJson.active;
      this.projectID = assignmentJson.projectID;
      this.musicianID = assignmentJson.musicianID;
      this.missionID = assignmentJson.missionID;
      this.memo = assignmentJson.memo;
      this.mercuryStageID = assignmentJson.mercuryStageID;
      this.mercuryJobID = assignmentJson.mercuryJobID;
      this.publicToken = assignmentJson.publicToken;
      this.paidToMusician = assignmentJson.paidToMusician;
      this.wageAgreement = assignmentJson.wageAgreement;
      this.createdAt = assignmentJson.createdAt;
      this.job = assignmentJson.job;
      this.updatedAt = assignmentJson.updatedAt;
      this.importantChanges = assignmentJson.importantChanges;
      this.criticalChanges = assignmentJson.criticalChanges;
      this.releaseWorkSessionIDs = assignmentJson.releaseWorkSessionIDs;
      this.releaseExplanation = assignmentJson.releaseExplanation;
      this.workSessionIDs = assignmentJson.workSessionIDs;
    } catch (e) {
      console.log(
        "Failed to create new instance of ${ Assignment_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  active?: boolean;
  projectID: number;
  musicianID: number;
  mercuryStageID: number;
  missionID: number;
  mercuryJobID: string;
  publicToken: string;
  job?: MercuryJob_Entity;
  paidToMusician: boolean;
  wageAgreement: string;
  memo: string;
  createdAt: string;
  updatedAt: string;
  importantChanges: boolean;
  criticalChanges: boolean;
  releaseWorkSessionIDs: string;
  releaseExplanation: string;
  workSessionIDs: string;
}
