import { HiringNotify_Entity } from "entities/hiringNotify";
import { rhapsodyApi } from "redux/api";

export const hiringNotifyEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getHiringNotify: build.query<
      HiringNotify_Entity,
      {
        projectID: number;
        body: { musicianIDs: number[]; sessionIDs?: number[] };
      }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projects/${args.projectID}/hiringNotify`,
      }),
      providesTags: (result, error, args) => [
        { type: "hiringNotify", id: args.projectID },
      ],
    }),
  }),
});

export const { useGetHiringNotifyQuery, useLazyGetHiringNotifyQuery } =
  hiringNotifyEndpoints;

export default hiringNotifyEndpoints;
