import { NerdDateTimeRangePicker } from "@nerdjs/nerd-ui";
import { DateTime } from "luxon";
import moment from "moment";

/**
 *
 * @returns {ReactElement} TestDatePicker page
 */
export function DateTimePicker({
  dateFrom,
  dateTo,
  tzName,
  onChange,
}: {
  dateFrom: string;
  dateTo: string;
  tzName: string;
  onChange: (dateFrom: string, dateTo: string) => void;
}) {
  // const _tzName = tzName ? tzName : moment.tz.guess();
  const _tzName = tzName ?? moment.tz.guess();
  return (
    <NerdDateTimeRangePicker
      key={_tzName}
      defaultTimeZone={"UTC"}
      disableAllDay
      disableTimeZones
      timeFormat="12"
      startDate={
        dateFrom ? utcToLocal(DateTime.fromISO(dateFrom), _tzName) : null
      }
      endDate={dateTo ? utcToLocal(DateTime.fromISO(dateTo), _tzName) : null}
      onChange={(startDate, endDate) => {
        onChange(
          localToUTC(startDate, _tzName).toISO(),
          localToUTC(endDate, _tzName).toISO()
        );
      }}
    />
  );
}

function localToUTC(date: DateTime, tzName: string): DateTime {
  return date.setZone(tzName, { keepLocalTime: true }).toUTC();
}

function utcToLocal(date: DateTime, tzName: string): DateTime {
  return date.setZone(tzName).setZone("UTC", { keepLocalTime: true });
}
