import { Stage_Entity } from "entities/stage";

/**
 * Pipeline_Entity type guard.
 *
 * @param {any} pipelineJson Pipeline object from API
 * @returns {boolean} Return true if type is Pipeline_Entity
 */

export default class Pipeline_Entity {
  static requiredFields = ["stages"];

  constructor(pipelineJson: Pipeline_Entity) {
    try {
      this.id = pipelineJson.id;
      this.name = pipelineJson.name;
      this.organizationID = pipelineJson.organizationID;
      this.public = pipelineJson.public;
      this.inherit = pipelineJson.inherit;
      this.stages = pipelineJson.stages;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id?: number;
  name?: string;
  organizationID?: number;
  public?: boolean;
  inherit?: string;
  stages: Array<Stage_Entity>;
}
