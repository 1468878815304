import { Box, IconButton, Skeleton, Typography } from "@mui/joy";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

/**
 *
 * @returns {ReactElement} JournalWidget page
 */
export function JournalWidget({
  title,
  favicon,
  description,
  value,
  children,
  loading = false,
  href,
}: {
  title?: string;
  favicon?: string;
  description?: string;
  value?: string;
  children?: ReactElement;
  loading?: boolean;
  href?: string;
}) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        border: "solid 1px rgba(151,151,151,0.29)",
        borderRadius: 8,
        minWidth: 250,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Typography
          startDecorator={
            favicon ? (
              <Skeleton variant="circular" width={35} loading={loading}>
                <i className={favicon} />
              </Skeleton>
            ) : undefined
          }
          level="h6"
        >
          <Skeleton loading={loading} variant="rectangular">
            {title}
          </Skeleton>
        </Typography>
        {!loading && href ? (
          <IconButton
            onClick={() => navigate(href)}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <i className="fa-solid fa-chevron-right"></i>
          </IconButton>
        ) : (
          []
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Typography level="h4" sx={{ fontWeight: 800 }}>
          <Skeleton loading={loading}>{value}</Skeleton>
        </Typography>
        {!loading ? children : []}
      </Box>
      <Typography level="body2">
        <Skeleton loading={loading}>{description}</Skeleton>
      </Typography>
    </Box>
  );
}
