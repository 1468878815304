import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { SectionRole_Entity } from "entities/sectionRole";
import { rhapsodyApi } from "../api/rhapsodyApi";

const sectionRolesAdapter = createEntityAdapter<SectionRole_Entity>();
const initialState = sectionRolesAdapter.getInitialState();

export const sectionRoleEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getSectionRoles: build.query<EntityState<SectionRole_Entity>, void>({
      query: () => `sectionRoles`,
      transformResponse: (responseData: SectionRole_Entity[]) => {
        return sectionRolesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["sectionRoles"],
    }),
    getSectionRole: build.query<SectionRole_Entity, number>({
      query: (id) => `sectionRoles/${id}`,
      providesTags: (result, error, id) => [{ type: "sectionRoles", id }],
    }),
    createSectionRole: build.mutation<
      SectionRole_Entity,
      Partial<SectionRole_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `sectionRoles`,
      }),
      invalidatesTags: ["sectionRoles"],
    }),
    updateSectionRole: build.mutation<
      void,
      { id: number; body: Partial<SectionRole_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `sectionRoles/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "sectionRoles", id },
        "sectionRoles",
      ],
    }),
    deleteSectionRole: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `sectionRoles/${id}`,
      }),
      invalidatesTags: ["sectionRoles"],
    }),
  }),
});

export const {
  useGetSectionRoleQuery,
  useGetSectionRolesQuery,
  useCreateSectionRoleMutation,
  useDeleteSectionRoleMutation,
  useUpdateSectionRoleMutation,
} = sectionRoleEndpoints;

export default sectionRoleEndpoints;

export const selectSectionRolesResult =
  sectionRoleEndpoints.endpoints.getSectionRoles.select();

const selectSectionRolesData = createSelector(
  selectSectionRolesResult,
  (sectionRolesResult) => sectionRolesResult.data
);

const store = configureStore({
  reducer: {
    [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const {
  selectAll: selectAllSectionRoles,
  selectById: selectSectionRoleById,
} = sectionRolesAdapter.getSelectors<RootState>(
  (state) => selectSectionRolesData(state) ?? initialState
);
