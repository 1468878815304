import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  Link,
  Sheet,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Skeleton } from "@mui/material";
import Id from "atoms/Id/Id";
import { Project_Entity } from "entities/project";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import {
  useAddProjectChairRequest,
  useProjectActionsRequired,
} from "features/projects/useProjectHooks";
import { heightWithToolbar } from "global";

import { RouterConfig } from "hooks/AppRouter/AppRouter";
import Autopilot from "hooks/Autopilot/Autopilot";
import { MercuryWidget } from "hooks/MercuryWidget/mercuryWidget";
import { helpdeskDismiss, helpdeskStatus } from "hooks/helpCard/helpCard";
import { finale, useMySubscription } from "hooks/subscription/restrictionHooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setDescription420,
  setFormOpen,
} from "reducers/rhapsody";
import { useProjectAutopilot } from "redux/autopilot/autopilotHooks";
import { useProjectLinks } from "redux/link/linkHooks";
import { useUpdateProjectMutation } from "redux/project/projectEndpoints";
import { useProject } from "redux/project/projectHooks";
import { useProjectAlternates } from "redux/projectAlternate/projectAlternateHooks";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { useProjectWorkSessions } from "redux/workSession/workSessionHooks";

/**
 *
 * @returns {ReactElement} ProjectSideBar page
 */
export function ProjectSideBar() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { project: _project } = useProject(projectID);
  const [projectName, setProjectName] = useState("");
  const [project, setProject] = useState<Project_Entity>();
  const open = useSelector(formOpenSelector("projectSidebar")) ?? true;
  const { projectAlternates, isLoading: projectAlternatesLoading } =
    useProjectAlternates(projectID);
  const [updateProject] = useUpdateProjectMutation();
  const { projectWorkSessions, isLoading: workSessionsLoading } =
    useProjectWorkSessions(projectID);
  const askQuestion = useAskQuestion();
  const { autopilot } = useProjectAutopilot(projectID);
  const handleNewProjectChair = useAddProjectChairRequest();
  const { hasActions, musicianJobs } = useProjectActionsRequired();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscription = useMySubscription();

  const { links, isLoading: linksLoading } = useProjectLinks(projectID);

  useEffect(() => {
    setProjectName(project?.name ?? "");
  }, [project]);

  const { projectNotes } = useProjectNotes(projectID);

  const chairCount = projectAlternates?.reduce((a, i) => {
    if (i.prime) a++;
    return a;
  }, 0);

  const musicianCount = projectAlternates?.reduce((a, i) => {
    if (i.musicianID && i.prime) a++;
    return a;
  }, 0);

  const subChairCount = projectAlternates?.reduce((a, i) => {
    if (!i.prime) a++;
    return a;
  }, 0);

  const subCount = projectAlternates?.reduce((a, i) => {
    if (i.musicianID && !i.prime) a++;
    return a;
  }, 0);

  const memoCount = projectAlternates?.reduce((a, i) => {
    if (i.notes) a++;
    return a;
  }, 0);

  const roleCount = projectAlternates?.reduce((a, i) => {
    if (i.sectionRoleID !== 4) a++;
    return a;
  }, 0);

  const steps = [
    {
      id: 2,
      label: `Add chairs`,
      required: true,
      completed: chairCount > 0,
      loading: projectAlternatesLoading,
      endDecorator:
        chairCount > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {chairCount}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: handleNewProjectChair,
    },
    {
      id: 3,
      label: "Assign musicians",
      required: true,
      endDecorator:
        musicianCount > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {musicianCount}
            </Typography>
          </Avatar>
        ) : undefined,
      disabled: chairCount === 0,
      tooltip: "Add chairs first",
      completed: musicianCount > 0,
      loading: projectAlternatesLoading,
      onClick: () => navigate(`${RouterConfig.projects}/${projectID}/seating`),
    },
    {
      id: 4,
      label: "Add Chairs for Subs",
      required: false,
      loading: projectAlternatesLoading,
      tooltip: "Add chairs first",
      completed: subChairCount > 0,
      endDecorator:
        subChairCount > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {subChairCount}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        navigate(`${RouterConfig.projects}/${projectID}/seating`);
        dispatch(setFormOpen({ isOpen: true, formID: "instrumentation" }));
      },
    },
    {
      id: 5,
      label: "Assign Subs",
      required: false,
      loading: projectAlternatesLoading,
      disabled: musicianCount === 0,
      tooltip: "Add chairs first",
      completed: subCount > 0,
      endDecorator:
        subCount > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {subCount}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => navigate(`${RouterConfig.projects}/${projectID}/seating`),
    },
    {
      id: 6,
      label: "Adding Chair memos",
      required: false,
      loading: projectAlternatesLoading,
      disabled: musicianCount === 0,
      tooltip: "Add chairs first",
      completed: memoCount > 0,
      endDecorator:
        memoCount > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {memoCount}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => navigate(`${RouterConfig.projects}/${projectID}/seating`),
    },
    {
      id: 7,
      label: "Assigning Roles",
      required: false,
      loading: projectAlternatesLoading,
      disabled: musicianCount === 0,
      tooltip: "Add chairs first",
      completed: roleCount > 0,
      onClick: () => navigate(`${RouterConfig.projects}/${projectID}/seating`),
    },
    {
      id: 8,
      label: "Add Work Sessions",
      required: true,
      loading: workSessionsLoading,
      disabled: false,
      tooltip: "Add chairs and assign musicians first",
      completed: projectWorkSessions.length > 0,
      endDecorator:
        projectWorkSessions.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {projectWorkSessions.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        if (!helpdeskStatus("Create a Work Session quickly"))
          askQuestion(`Helpdesk`, ["Got it"], {
            subtitle: (
              <Typography>
                To create a Work Session quickly, drag and drop it to the
                correct date and time on the calendar.
              </Typography>
            ),
          }).then(() => helpdeskDismiss("Create a Work Session quickly"));
        navigate(`${RouterConfig.projects}/${projectID}/calendar`);
      },
    },
    {
      id: 9,
      label: "Add Links",
      required: false,
      completed: links.length,
      loading: linksLoading,
      endDecorator:
        links.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {links.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        navigate(`${RouterConfig.projects}/${projectID}/links`);
        dispatch(setFormOpen({ isOpen: true, formID: "link" }));
      },
    },
    {
      id: 10,
      label: "Add Notes",
      required: false,
      completed: projectNotes.length > 0,
      endDecorator:
        projectNotes.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {projectNotes.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        navigate(`${RouterConfig.projects}/${projectID}/notes`);
        dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }));
      },
    },
    {
      id: 11,
      label: "Send the call",
      required: true,
      completed: false,
      onClick: () => {
        dispatch(setFormOpen({ isOpen: true, formID: "notifier" }));
      },
      disabled: musicianCount === 0,
      tooltip: "Add chairs first",
    },
  ];

  const completion = Math.round(
    (steps.filter((s) => s.completed).length / steps.length) * 100
  );

  useEffect(() => {
    setProject(_project);
  }, [_project]);

  if (!project?.id) return <Skeleton sx={{ width: 300, p: 1 }} />;

  return (
    <Box sx={{ display: "flex" }}>
      {!open ? (
        <Box sx={{ display: "flex", flex: 1 }}>
          <Tooltip
            title={`Open ${project.template ? "Template" : "Project"} Sidebar`}
            placement="right"
            arrow
          >
            <IconButton
              onClick={() => {
                dispatch(
                  setFormOpen({ formID: "projectSidebar", isOpen: !open })
                );
              }}
              size="sm"
              color="neutral"
              sx={{ borderRadius: 0 }}
            >
              <i className="fa-solid fa-chevrons-right"></i>
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" />
        </Box>
      ) : (
        []
      )}
      {open ? (
        <Box
          sx={{
            width: 300,
            height: heightWithToolbar,
            p: 2,
            flexShrink: 0,
            display: "flex",
            gap: 2,
            flexDirection: "column",
          }}
        >
          <Box>
            <Box
              sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}
            >
              <Link
                sx={{ pl: 1 }}
                component={RouterLink}
                to={
                  project.template
                    ? RouterConfig.templates
                    : RouterConfig.projects
                }
                startDecorator={<i className="fa-regular fa-chevron-left"></i>}
                level="body1"
                underline="none"
              >
                {project.template ? "Templates" : "Projects"}
              </Link>
              <Tooltip
                title={`Close ${
                  project.template ? "Template" : "Project"
                } Sidebar`}
                arrow
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      setFormOpen({ formID: "projectSidebar", isOpen: !open })
                    );
                  }}
                  size="sm"
                  color="neutral"
                  variant="soft"
                >
                  <i className="fa-solid fa-chevrons-left"></i>
                </IconButton>
              </Tooltip>
            </Box>
            <Typography level="h6">
              <Input
                variant="plain"
                onBlur={() =>
                  updateProject({
                    id: project?.id,
                    body: { ...project, name: projectName },
                  })
                }
                onChange={(e) => setProjectName(e.target.value)}
                sx={{
                  p: 1,
                  "&:hover": {
                    background: "rgba(155,155,155,0.1)",
                  },
                  mb: 1,
                }}
                value={projectName}
              />
            </Typography>
            <Typography
              sx={{ pl: 1 }}
              level="body2"
              endDecorator={
                <Id
                  startDecorator={<i className="fa-solid fa-folder-open"></i>}
                >
                  {project.id}
                </Id>
              }
            >
              {project.template ? "Template" : "Project"}
            </Typography>
          </Box>
          {project?.archived ? (
            <Alert color="danger" variant="solid">
              This {project.template ? "template" : "project"} is archived
            </Alert>
          ) : (
            []
          )}
          {hasActions && !project?.archived ? (
            <Button
              onClick={() =>
                dispatch(
                  setFormOpen({ formID: "projectActionRequired", isOpen: true })
                )
              }
              color="danger"
              startDecorator={<i className="fa-solid fa-bell fa-shake"></i>}
            >
              <Box>
                {musicianJobs.length} Action{musicianJobs.length > 1 ? "s" : ""}{" "}
                Required
                <Typography
                  level="body4"
                  sx={{ color: "white", fontWeight: 400 }}
                >
                  Click to review
                </Typography>
              </Box>
            </Button>
          ) : (
            []
          )}
          {/* <Chip
            startDecorator={<i className="fa-solid fa-robot"></i>}
            variant="soft"
            color={autopilot?.enabled ? "success" : "neutral"}
            endDecorator={autopilot?.enabled ? "On" : "Off"}
            onClick={() => {
              if (subscription !== finale) {
                dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));
                dispatch(
                  setDescription420(
                    "Autopilot is an exclusive feature to the Finale plan."
                  )
                );
              } else {
                dispatch(setFormOpen({ formID: "autopilot", isOpen: true }));
              }
            }}
          >
            Autopilot
          </Chip> */}
          {/* <Autopilot projectID={projectID} /> */}
          {!project.template ? (
            <Sheet
              variant="outlined"
              sx={{
                borderRadius: "8px",
                p: 1,
                display: "flex",
                gap: 1,
                flexDirection: "column",
                alignItems: "start",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography level="body2">Checklist:</Typography>
                <Typography level="body3" color="success">
                  <b>{completion}% Completed</b>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                {steps.map((s, i) => {
                  return (
                    <>
                      {s?.loading ? (
                        <Skeleton
                          sx={{ width: s.label.length * 10, height: 25 }}
                          key={s.id}
                        />
                      ) : (
                        <Tooltip
                          variant="solid"
                          arrow
                          title={s.disabled ? s.tooltip : ""}
                          key={s.id}
                        >
                          <Chip
                            onClick={s.onClick}
                            disabled={s.disabled}
                            size="sm"
                            endDecorator={
                              s.endDecorator ? (
                                s.endDecorator
                              ) : (
                                <>
                                  {!s.required && !s.completed ? (
                                    <Typography level="body4">
                                      Optional
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            color={s.completed ? "success" : "neutral"}
                            variant={s.completed ? "soft" : "outlined"}
                            startDecorator={
                              s.completed ? (
                                <i className="fa-solid fa-check"></i>
                              ) : (
                                []
                              )
                            }
                          >
                            {s.label}
                          </Chip>
                        </Tooltip>
                      )}
                    </>
                  );
                })}
              </Box>
            </Sheet>
          ) : (
            []
          )}
          <Sheet
            variant="outlined"
            sx={{
              borderRadius: "8px",
              p: 1,
              display: "flex",
              gap: 1,
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography level="body2">Internal Memo:</Typography>
            <Textarea
              placeholder={`Anything to write down about this ${
                project.template ? "Template" : "Project"
              }?`}
              sx={{ width: "100%", background: "rgba(255,235,59,0.1)" }}
              onChange={(e) =>
                setProject((p) => ({ ...p, description: e.target.value }))
              }
              value={project.description ?? ""}
              onBlur={() => {
                updateProject({
                  id: project.id,
                  body: { ...project, description: project.description },
                });
              }}
              size="sm"
              endDecorator={
                <Typography level="body4">Not visible by musicians</Typography>
              }
            />
          </Sheet>
          {!project.template ? (
            <Sheet
              variant="outlined"
              sx={{
                borderRadius: "8px",
                p: 1,
              }}
            >
              <Typography level="body2">Hiring Progress:</Typography>
              <MercuryWidget projectID={projectID} />
            </Sheet>
          ) : (
            []
          )}
        </Box>
      ) : (
        []
      )}
    </Box>
  );
}
