import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { MusicianNote_Entity } from "entities/musicianNote";
import { rhapsodyApi } from "../api/rhapsodyApi";

const musicianNotesAdapter = createEntityAdapter<MusicianNote_Entity>();
const initialState = musicianNotesAdapter.getInitialState();

export const musicianNoteEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getMusicianNotes: build.query<EntityState<MusicianNote_Entity>, void>({
      query: (args) => {
        return {
          url: `musicianNotes`,
          params: args,
        };
      },
      transformResponse: (responseData: MusicianNote_Entity[]) => {
        return musicianNotesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["musicianNotes"],
    }),
    getMusicianNote: build.query<MusicianNote_Entity, number>({
      query: (id) => `musicianNotes/${id}`,
      providesTags: (result, error, id) => [{ type: "musicianNotes", id }],
    }),
    createMusicianNote: build.mutation<
      MusicianNote_Entity,
      Partial<MusicianNote_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `musicianNotes`,
      }),
      invalidatesTags: ["musicianNotes"],
    }),
    updateMusicianNote: build.mutation<
      void,
      { id: number; body: Partial<MusicianNote_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `musicianNotes/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "musicianNotes", id },
        "musicianNotes",
      ],
    }),
    deleteMusicianNote: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `musicianNotes/${id}`,
      }),
      invalidatesTags: ["musicianNotes"],
    }),
  }),
});

export const {
  useGetMusicianNoteQuery,
  useGetMusicianNotesQuery,
  useCreateMusicianNoteMutation,
  useDeleteMusicianNoteMutation,
  useUpdateMusicianNoteMutation,
} = musicianNoteEndpoints;

export default musicianNoteEndpoints;
