import { show } from "@intercom/messenger-js-sdk";
import {
  Box,
  IconButton,
  Link,
  Option,
  Select,
  Sheet,
  Switch,
  Textarea,
  Typography,
} from "@mui/joy";
import { Rating } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { NerdPhoneField } from "@nerdjs/nerd-ui";
import ColorPicker from "atoms/ColorPicker";
import { MusicianNote } from "entities/musicianNote";
import { NoteType } from "entities/noteType";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedMusicianIDSelector } from "reducers/rhapsody";
import {
  useCreateMusicianNoteMutation,
  useDeleteMusicianNoteMutation,
  useUpdateMusicianNoteMutation,
} from "redux/musicianNote/musicianNoteEndpoints";
import { useMusicianNotes } from "redux/musicianNote/musicianNoteHooks";
import { useNoteTypes } from "redux/noteType/noteTypeHooks";

/**
 *
 * @returns {ReactElement} MusicianPrivateNotes page
 */
export function MusicianPrivateNotes() {
  const { noteTypes, noteTypesMap } = useNoteTypes();
  const readOnly = useReadOnly();
  const musicianID = useSelector(selectedMusicianIDSelector);
  const [createMusicianNote] = useCreateMusicianNoteMutation();
  const [deleteMusicianNote] = useDeleteMusicianNoteMutation();
  const { musicianNotes, isLoading, isFetching } = useMusicianNotes({
    filters: JSON.stringify([
      {
        name: "musician_notes.musicianID",
        comparison: "eq",
        value: musicianID,
      },
    ]),
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {musicianNotes.map((e) => {
        const noteType = noteTypesMap[e.noteTypeID];
        if (!noteType) return;
        return (
          <Sheet
            key={e.id}
            variant="outlined"
            sx={{
              borderRadius: "8px",
              p: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexGrow: 1,
                gap: 1,
              }}
            >
              <Typography level="body2">{noteType?.name}:</Typography>
              <Item musicianNote={e} noteType={noteType} />
            </Box>
            <IconButton
              onClick={() => deleteMusicianNote(e.id)}
              size="sm"
              variant="plain"
              color="neutral"
              disabled={readOnly}
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          </Sheet>
        );
      })}
      <Select
        disabled={readOnly}
        slotProps={{
          listbox: { sx: { zIndex: 9999 } },
        }}
        sx={{ background: "white" }}
        variant="soft"
        value=""
        placeholder={
          <Typography level="body2" sx={{ fontWeight: 600, color: "#424242" }}>
            + Add field
          </Typography>
        }
        onChange={(e, v) => {
          if (v)
            createMusicianNote({
              musicianID,
              noteTypeID: parseInt(v),
              description: "",
              value: "",
            });
        }}
      >
        {noteTypes.map((n) => {
          const d = noteTypeData.find((e) => e.value === n.kind);
          return (
            <Option key={n.id} value={n.id}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Typography>{n.name}</Typography>
                <Typography
                  startDecorator={<i className={d?.icon} />}
                  level="body2"
                >
                  {d?.label}
                </Typography>
              </Box>
            </Option>
          );
        })}
        <Box sx={{ textAlign: "center" }}>
          <Typography level="body4">
            Need a custom field?{" "}
            <Link
              startDecorator={<i className="fa-solid fa-comment"></i>}
              onClick={() => {
                show();
              }}
            >
              Chat with us.
            </Link>
          </Typography>
        </Box>
      </Select>
    </Box>
  );
}

function Item({
  noteType,
  musicianNote,
}: {
  noteType: NoteType;
  musicianNote: MusicianNote;
}) {
  const readOnly = useReadOnly();
  const [value, setValue] = useState(musicianNote.value);
  const [updateMusicianNote] = useUpdateMusicianNoteMutation();

  useEffect(() => {
    setValue(musicianNote.value);
  }, [musicianNote]);

  switch (noteType?.kind) {
    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={moment(value)}
            onChange={(e) => {
              setValue(e.utc().format());
              updateMusicianNote({
                id: musicianNote.id,
                body: {
                  ...musicianNote,
                  value: e.utc().format(),
                },
              });
            }}
            slotProps={{
              textField: {
                size: "small",
                variant: "standard",
              },
            }}
          />
        </LocalizationProvider>
      );
    case "phone":
      return (
        <NerdPhoneField
          textFieldProps={{
            size: "small",
            disabled: readOnly,
          }}
          value={value}
          onAccept={(e) => {
            updateMusicianNote({
              id: musicianNote.id,
              body: {
                ...musicianNote,
                value: e,
              },
            });
          }}
        />
      );
    case "time":
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            value={moment(value)}
            onChange={(e) => {
              setValue(e.utc().format());
              updateMusicianNote({
                id: musicianNote.id,
                body: {
                  ...musicianNote,
                  value: e.utc().format(),
                },
              });
            }}
            slotProps={{
              textField: {
                disabled: readOnly,
                size: "small",
                variant: "standard",
              },
            }}
          />
        </LocalizationProvider>
      );
    case "color":
      return (
        <ColorPicker
          label=""
          disabled={readOnly}
          onChange={(e) =>
            updateMusicianNote({
              id: musicianNote.id,
              body: { ...musicianNote, value: e },
            })
          }
          color={musicianNote.value ?? "#000000"}
        />
      );
    case "select":
      return (
        <Select
          slotProps={{
            listbox: { sx: { zIndex: 9999 } },
          }}
          disabled={readOnly}
          value={value ?? ""}
          onChange={(e, v) => {
            if (v) {
              updateMusicianNote({
                id: musicianNote.id,
                body: { ...musicianNote, value: v },
              });
            }
          }}
          placeholder={"Select an option..."}
        >
          {noteType?.data?.split(",").map((e) => {
            return (
              <Option key={e} value={e}>
                {e}
              </Option>
            );
          })}
        </Select>
      );
    case "textfield":
      return (
        <Textarea
          disabled={readOnly}
          size="sm"
          placeholder="Type here..."
          onBlur={() =>
            updateMusicianNote({
              id: musicianNote.id,
              body: { ...musicianNote, value },
            })
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      );
    case "boolean":
      return (
        <Switch
          disabled={readOnly}
          onClick={(e) => {
            updateMusicianNote({
              id: musicianNote.id,
              body: {
                ...musicianNote,
                value: value === "true" ? "false" : "true",
              },
            });
          }}
          checked={musicianNote.value === "true" ? true : false}
        />
      );
    case "rating":
      return (
        <Rating
          disabled={readOnly}
          value={parseInt(value)}
          onChange={(event, newValue) => {
            updateMusicianNote({
              id: musicianNote.id,
              body: {
                ...musicianNote,
                value: `${newValue}`,
              },
            });
          }}
        />
      );
    case "dateRange":
      const r = JSON.parse(
        musicianNote.value ? musicianNote.value : "[null, null]"
      );
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateRangePicker
            sx={{ maxWidth: 300 }}
            onChange={(e) => {
              updateMusicianNote({
                id: musicianNote.id,
                body: {
                  ...musicianNote,
                  value: JSON.stringify([
                    e[0].utc().format(),
                    e[1].utc().format(),
                  ]),
                },
              });
            }}
            value={[moment(r[0]), moment(r[1])]}
            slotProps={{
              textField: {
                size: "small",
                disabled: readOnly,
                variant: "standard",
              },
            }}
          />
        </LocalizationProvider>
        // <Box sx={{ display: "flex", gap: 1 }}>
        //   <Chip variant="soft">{moment(r[0]).format("ll")}</Chip>
        //   <Chip variant="soft">{moment(r[1]).format("ll")}</Chip>
        // </Box>
      );
    default:
      return <Box />;
  }
}

export const noteTypeData = [
  {
    value: "textfield",
    label: "Text",
    icon: "fa-regular fa-i-cursor",
    testValue: (e: string) => e,
    format: (e: string) => e?.trim(),
  },
  {
    value: "phone",
    label: "Phone",
    icon: "fa-duotone fa-phone",
    testValue: (e: string) => {
      return isValidNumber(`${e}`, "US");
    },
    format: (e: string) => {
      try {
        if (isValidNumber(`${e}`, "US")) {
          const phoneNumber = parsePhoneNumber(`${e}`, "US");
          return phoneNumber.format("E.164");
        }
      } catch (error) {}
      return "";
    },
  },
  {
    value: "date",
    label: "Date",
    icon: "fa-duotone fa-calendar-days",
    testValue: (e: string) => e,
    format: (e: string) => (e ? moment(e).utc().format() : e),
  },
  {
    value: "time",
    label: "Time",
    icon: "fa-duotone fa-clock",
    testValue: (e: string) => e,
    format: (e: string) => (e ? moment(e).utc().format() : e),
  },
  {
    value: "dateRange",
    label: "Date Range",
    icon: "fa-duotone fa-calendar-range",
    testValue: (e: string) => e,
    format: (e: string) => e?.trim(),
  },
  {
    value: "color",
    label: "Color",
    icon: "fa-duotone fa-palette",
    testValue: (e: string) => e,
    format: (e: string) => e?.trim(),
  },
  {
    value: "boolean",
    label: "Checkbox",
    icon: "fa-duotone fa-square-check",
    testValue: (e: string) => e,
    format: (e: string) => e === "true",
  },
  {
    value: "rating",
    label: "Star Rating",
    icon: "fa-duotone fa-star",
    testValue: (e: string) => e,
    format: (e: string) => (e ? parseInt(e) : e),
  },
  {
    value: "select",
    label: "Select",
    icon: "fa-solid fa-rectangle-history",
    testValue: (e: string) => e,
    format: (e: string) => e?.trim(),
  },
];
