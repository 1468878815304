import { Alert, Button, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { NerdFileUpload } from "@nerdjs/nerd-ui";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Musician_Entity } from "entities/musician";
import { processMusiciansTemplate } from "features/exporter/models/musicianImportTemplate";
import {
  mezzo,
  overture,
  useMySubscription,
} from "hooks/subscription/restrictionHooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { useCreateMusicianMutation } from "redux/musician/musicianEndpoints";
import { useMusicians } from "redux/musician/musicianHooks";

/**
 *
 * @returns {ReactElement} FormUploadMusicianTemplate page
 */
export function FormUploadMusicianTemplate() {
  const [excelFile, setExcelFile] = useState<File>();
  const open = useSelector(formOpenSelector("uploadMusicianTemplate"));
  const { musicians: addressBook } = useMusicians();
  const subscription = useMySubscription();
  const [musicians, setMusicians] = useState<Partial<Musician_Entity>[]>();
  const dispatch = useDispatch();
  const [createMusician] = useCreateMusicianMutation();

  useEffect(() => {
    if (excelFile) {
      processMusiciansTemplate(excelFile).then((m) => setMusicians(m));
    }
  }, [excelFile]);

  function importNow() {
    const count = addressBook?.length;
    let limit;
    if (subscription === overture) limit = 30;
    if (subscription === mezzo) limit = 100;

    if (count + musicians?.length > limit) {
      dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));
    } else {
      musicians.forEach((m) => createMusician(m));
      dispatch(
        setFormOpen({ isOpen: false, formID: "uploadMusicianTemplate" })
      );
      onClose();
    }
  }

  function onClose() {
    dispatch(setFormOpen({ isOpen: false, formID: "uploadMusicianTemplate" }));
    setExcelFile(null);
    setMusicians([]);
  }

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle>Upload</DialogTitle>
      <DialogContent sx={{ maxWidth: 400 }}>
        {musicians?.length ? (
          <>
            <Alert variant="outlined" color="neutral">
              You are about to import {musicians?.length} musician
              {musicians?.length > 1 ? "s" : ""}. please keep in mind that any
              entries with an email address already present in your database
              will be ignored.
            </Alert>
          </>
        ) : (
          <>
            <Typography level="body2">
              Instruction: Drag and drop the filled Excel template in this
              window or click on the button bellow:
            </Typography>
            <br />
            <NerdFileUpload
              dropZoneLabel="File upload example"
              uploadFile={(e: File) => {
                setExcelFile(e);
              }}
              hideFilesList
              dense
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="soft" color="neutral">
          Cancel
        </Button>
        {musicians?.length ? <Button onClick={importNow}>Import</Button> : []}
      </DialogActions>
    </DialogClose>
  );
}
