import { Box, Tooltip, Typography } from "@mui/joy";
import moment from "moment";
import { useMusicians } from "redux/musician/musicianHooks";

/**
 *
 * @returns {ReactElement} MusicianBirthdays page
 */
export function MusicianBirthdays() {
  const { musicians } = useMusicians();

  const today = moment().format("MM/DD");
  const birthdays = musicians.reduce((a, v) => {
    if (v.birthdate && moment(v.birthdate).utc().format("MM/DD") === today) {
      a.push(
        `${v.fullName()}: ${moment(v.birthdate).utc().format("MM/DD/YYYY")}`
      );
    }
    return a;
  }, []);

  if (birthdays.length === 0) return <Box />;

  console.log(birthdays);

  return (
    <Tooltip
      arrow
      variant="outlined"
      size="sm"
      title={
        <Box>
          {birthdays.map((e) => (
            <Box key={e}>• {e}</Box>
          ))}
        </Box>
      }
    >
      <Typography level="body2" startDecorator={"🥳"}>
        {birthdays.length} birthday{birthdays.length !== 1 ? "s" : ""} today{" "}
      </Typography>
    </Tooltip>
  );
}
