import { Box, Input, Textarea } from "@mui/joy";
import { PdProject_Entity } from "entities/pdProject";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectedPdProjectIDSelector,
  selectedProjectIDSelector,
} from "reducers/rhapsody";
import { useUpdatePdProjectMutation } from "redux/pdProject/pdProjectEndpoints";
import { usePdProject } from "redux/pdProject/pdProjectHooks";
import { useProject } from "redux/project/projectHooks";

/**
 *
 * @returns {ReactElement} PdHeader page
 */
export function PdProjectInfo() {
  const pdProjectID = useSelector(selectedPdProjectIDSelector);
  const { pdProject } = usePdProject(pdProjectID);
  const rhapsodyProjectID = useSelector(selectedProjectIDSelector);
  const { project } = useProject(rhapsodyProjectID);
  const [updatePdProject] = useUpdatePdProjectMutation();

  const [_pdProject, setPdProject] = useState<PdProject_Entity>(pdProject);

  const save = () => updatePdProject({ id: _pdProject.id, body: _pdProject });

  useEffect(() => {
    setPdProject(pdProject);
  }, [pdProject]);

  return (
    <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Input
          startDecorator="Name:"
          value={_pdProject?.name}
          onChange={(e) =>
            setPdProject((p) => ({ ...p, name: e.target.value }))
          }
          onBlur={save}
          placeholder={project?.name ?? "My awsome Project"}
          sx={{ flex: 3 }}
        />
        <Input
          startDecorator="Revision:"
          value={_pdProject?.revision}
          onBlur={save}
          placeholder="ex: v1"
          onChange={(e) =>
            setPdProject((p) => ({ ...p, revision: e.target.value }))
          }
        />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Textarea
          sx={{ flex: 1 }}
          startDecorator="Description:"
          value={_pdProject?.description}
          onBlur={save}
          onChange={(e) =>
            setPdProject((p) => ({ ...p, description: e.target.value }))
          }
          placeholder="ex: 3 Rehearsals  + 1 Concert"
        />
      </Box>
    </Box>
  );
}
