import { ProjectNote } from "entities/projectNote";
import { useMemo } from "react";
import {
  useGetProjectNoteQuery,
  useGetProjectNotesQuery,
} from "./projectNoteEndpoints";

export function useProjectNote(projectNoteID: number | undefined) {
  const projectNoteQuery = useGetProjectNoteQuery(projectNoteID ?? 0, {
    skip: (projectNoteID ?? 0) <= 0,
  });
  const projectNoteEntity = projectNoteQuery.data;

  return useMemo(() => {
    const ret: typeof projectNoteQuery & {
      projectNote?: ProjectNote | undefined;
    } = {
      ...projectNoteQuery,
    };
    if (projectNoteEntity) ret.projectNote = new ProjectNote(projectNoteEntity);
    return ret;
  }, [projectNoteEntity]);
}

export function useProjectNotes(projectID: number) {
  const projectNoteQuery = useGetProjectNotesQuery(
    {
      filters: JSON.stringify([
        {
          name: "project_notes.projectID",
          comparison: "eq",
          value: projectID,
        },
      ]),
    },
    { skip: projectID === undefined }
  );
  const projectNoteEntitiesMap = projectNoteQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectNoteQuery & {
      projectNotes?: ProjectNote[];
      projectNotesMap?: { [id: number]: ProjectNote };
    } = {
      ...projectNoteQuery,
      projectNotes: [],
      projectNotesMap: {},
    };
    if (projectNoteEntitiesMap) {
      const projectNotes = [];
      const projectNotesMap = {};

      for (const key in projectNoteEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(projectNoteEntitiesMap, key)) {
          const c = projectNoteEntitiesMap[key];
          const projectNote = new ProjectNote(c);
          projectNotes.push(projectNote);
          projectNotesMap[projectNote.id] = projectNote;
        }
      }
      ret.projectNotes = projectNotes;
      ret.projectNotesMap = projectNotesMap;
    }

    return ret;
  }, [projectNoteEntitiesMap]);
}
