import { Action } from "entities/action";
import { Option } from "../option";
import { Trigger } from "../trigger";

/**
 * Hook_Entity type guard.
 *
 * @param {any} hookJson Hook object from API
 * @returns {boolean} Return true if type is Hook_Entity
 */

export default class Hook_Entity {
  constructor(hookJson: Hook_Entity) {
    try {
      this.id = hookJson.id;
      this.name = hookJson.name;
      this.description = hookJson.description;
      this.triggers = hookJson.triggers;
      this.options = hookJson.options;
      this.actions = hookJson.actions;
      this.createdAt = hookJson.createdAt;
      this.updatedAt = hookJson.updatedAt;
      this.enabled = hookJson.enabled;
      this.notify = hookJson.notify;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  description: string;
  triggers: Array<Trigger>;
  actions: Array<Action>;
  options: Array<Option>;
  enabled: boolean;
  model: "musician" | "workSession" | "project";
  notify: boolean;
  createdAt: string;
  updatedAt: string;
}
