export const paydayMap = {
  1: "Payroll",
  2: "H&W",
  3: "Pension",
  4: "Contigency",
};

export const scaleTypeMap = {
  service: "Work Session Type",
  sectionRole: "Role",
  payday: "Budget",
  doubling: "Doubling",
  muse: "Cartage",
};
