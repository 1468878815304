import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import DanielsWork_Entity from "./danielsWork";
import { Dictionary } from "@reduxjs/toolkit";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { DanielsSection } from "entities/danielsPiece/danielsPiece";
import { mapToArray } from "helpers";
import { Instrument } from "entities/instrument";
import { Section } from "entities/section";

export default class DanielsWork extends DanielsWork_Entity {
  static fromList(danielsWorksJSON: unknown): Array<DanielsWork> {
    const danielsWorks: DanielsWork[] = [];
    if (danielsWorksJSON)
      Array.isArray(danielsWorksJSON) &&
        danielsWorksJSON.forEach((danielsWorkJSON) => {
          danielsWorks.push(new DanielsWork(danielsWorkJSON));
        });
    return danielsWorks;
  }

  ensembleArray(): DanielsSection[] {
    const ret: DanielsSection[] = [];
    console.log(this.ensemble);
    for (const k in this.ensemble) {
      if (Object.prototype.hasOwnProperty.call(this.ensemble, k)) {
        for (const j in this.ensemble[k]) {
          if (Object.prototype.hasOwnProperty.call(this.ensemble[k], j)) {
            ret.push({
              ...this.ensemble[k][j],
              count: parseInt(this.ensemble[k][j].count),
              order: parseInt(this.ensemble[k][j].order),
            });
          }
        }
      }
    }

    return ret;
  }

  instrumentation(
    utils: LayoutUtils,
    shorthand?: string,
    defaultStrings?: number[]
  ) {
    const ret: Dictionary<Partial<RhapsodyChair_Entity>[]> = {};
    const unkownSections: string[] = [];
    const { sectionsMap, instrumentsMap } = utils;
    const danielsSections = this.ensembleArray();

    const museSections = mapToArray(sectionsMap);
    const museInstruments = mapToArray(instrumentsMap);

    const pattern = /\b[a-zA-Z]+[a-zA-Z0-9]*\b/g;

    // Use match method to find all occurrences
    const matches = shorthand.match(pattern);
    const matchIntruments: Instrument[] = [];

    matches.forEach((m) => {
      const instrument = museInstruments.find((i) => i.shorthand === m);
      if (instrument) matchIntruments.push(instrument);
    });

    danielsSections.forEach((s) => {
      const _museSections = museSections.filter(
        (m) =>
          m.shorthand?.toLowerCase().trim() ===
          s.abbreviation?.toLowerCase().trim()
      );

      let museSection: Section;
      if (_museSections.length === 1) {
        museSection = _museSections[0];
      }

      if (_museSections.length >= 2) {
        // timpiani to be on Percussion
        if (s.abbreviation === "tmp") {
          museSection = _museSections.find(
            (s) => s.familyName === "Percussion"
          );
        }

        // Harp to be on Strings
        if (s.abbreviation === "hp") {
          museSection = _museSections.find((s) => s.familyName === "Strings");
        }

        // Double Bass to be on Strings
        if (s.abbreviation === "db") {
          museSection = _museSections.find((s) => s.familyName === "Strings");
        }
      }

      if (!museSection && _museSections.length) {
        museSection = _museSections[_museSections.length - 1];
      }

      if (museSection) {
        ret[museSection.id] = [];
        for (let index = 0; index < s.count; index++) {
          if (
            defaultStrings &&
            defaultStrings[museSection.id - 1] &&
            ret[museSection.id].length >= defaultStrings[museSection.id - 1]
          ) {
            continue;
          }
          ret[museSection.id].push({
            sectionID: museSection.id,
            sectionRoleID: 4,
            workSessionIDs: [],
            id: -1,
            projectID: utils.project.id,
            instrumentIDs: sectionsMap[museSection.id].defaultInstrumentID
              ? JSON.stringify([
                  sectionsMap[museSection.id].defaultInstrumentID,
                ])
              : "[]",
          });
        }
      } else {
        unkownSections.push(s.abbreviation);
      }
    });

    return { chairs: ret, unkownSections };
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}

function decodeInstrumentation(shorthand) {
  // Step 1: Define instrument codes and other mappings
  const instrumentCodes = {
    pic: "Piccolo",
    Eh: "English Horn",
    bcl: "Bass Clarinet",
    asx: "Alto Saxophone",
    tsx: "Tenor Saxophone",
    bsx: "Baritone Saxophone",
    tmp: "Timpani",
    cel: "Celesta",
    str: "Strings",
    // Add more mappings as necessary
  };

  // Helper function to decode a single token
  function decodeToken(token) {
    if (token.includes("[")) {
      // Extract and split the content inside brackets and preceding number
      const matches = token.match(/(\d*)\[([^\]]+)\]/);
      const quantity = matches[1] ? parseInt(matches[1], 10) : 1;
      const contents = matches[2].split(".");

      return contents
        .map((code) => {
          // Handle special cases like "tmp+4" or codes not in dictionary
          if (code in instrumentCodes) {
            return `${quantity} x ${instrumentCodes[code]}`;
          } else if (code.includes("+")) {
            const parts = code.split("+");
            return `${instrumentCodes[parts[0]]} + ${parts[1]} more`;
          }
          return `${quantity} x ${code}`;
        })
        .join(", ");
    } else if (token.trim() === "—") {
      return "\n"; // New line for section separation
    }
    // Handle simple numeric sequences and other separators if necessary
    return token;
  }

  // Step 2 & 3: Split the input and decode each part
  const parts = shorthand.split(" ");
  const decodedParts = parts.map(decodeToken);

  // Step 4: Assemble the decoded string
  return decodedParts.join(" ");
}
