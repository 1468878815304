import { useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Selecto from "react-selecto";
import {
  layoutSelect,
  layoutSetMoveToSectionID,
  layoutUnselect,
} from "reducers/layout";

export function GroupSelection({ reference }) {
  const [selectByClick, setSelectByClick] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.addEventListener("keyup", onkeyup);
    document.addEventListener("keydown", onkeydown);

    return () => {
      document.removeEventListener("keyup", onkeyup);
      document.removeEventListener("keydown", onkeydown);
    };
  }, []);

  function onkeyup(e: KeyboardEvent) {
    if (e.key === "Shift") {
      setSelectByClick(false);
    }
  }

  function onkeydown(e: KeyboardEvent) {
    if (e.key === "Shift") {
      setSelectByClick(true);
    }
  }

  if (sm) return <div />;

  return (
    <Selecto
      preventClickEventOnDrag
      clickBySelectEnd={false}
      dragContainer={reference?.current}
      selectableTargets={[".selecto"]}
      hitRate={1}
      selectByClick={selectByClick}
      selectFromInside={false}
      ratio={0}
      toggleContinueSelect={"shift"}
      onSelect={(e) => {
        e.added.forEach((el) => {
          el.classList.add("selectoSelected");
        });
        dispatch(
          layoutSelect(
            e.added.reduce((a, i) => {
              a.push(parseInt(i.id));
              return a;
            }, [])
          )
        );
        e.removed.forEach((el) => {
          el.classList.remove("selectoSelected");
          dispatch(layoutSetMoveToSectionID());
        });
        dispatch(
          layoutUnselect(
            e.removed.reduce((a, i) => {
              a.push(parseInt(i.id));
              return a;
            }, [])
          )
        );
      }}
    ></Selecto>
  );
}
