import Autopilot_Entity from "./autopilot";
export default class Autopilot extends Autopilot_Entity {
  static fromList(autopilotsJSON: unknown): Array<Autopilot> {
    const autopilots: Autopilot[] = [];
    if (autopilotsJSON)
      Array.isArray(autopilotsJSON) &&
        autopilotsJSON.forEach((autopilotJSON) => {
          autopilots.push(new Autopilot(autopilotJSON));
        });
    return autopilots;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
