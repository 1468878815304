import { Link } from "entities/link";
import { useMemo } from "react";
import {
  useGetLinkQuery,
  useGetLinksQuery,
  useGetMusicianLinksQuery,
  useGetProjectLinksQuery,
  useGetTagLinksQuery,
  useGetWorkSessionLinksQuery,
} from "./linkEndpoints";

export function useLink(linkID: number | undefined) {
  const linkQuery = useGetLinkQuery(linkID ?? 0, {
    skip: (linkID ?? 0) <= 0,
  });
  const linkEntity = linkQuery.data;

  return useMemo(() => {
    const ret: typeof linkQuery & { link?: Link | undefined } = {
      ...linkQuery,
    };
    if (linkEntity) ret.link = new Link(linkEntity);
    return ret;
  }, [linkEntity]);
}

export function useProjectLinks(projectID) {
  const linkQuery = useGetProjectLinksQuery(projectID, {
    skip: (projectID ?? 0) <= 0,
  });
  const linkEntitiesMap = linkQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof linkQuery & {
      links?: Link[];
      linksMap?: { [id: number]: Link };
    } = {
      ...linkQuery,
      links: [],
      linksMap: {},
    };
    if (linkEntitiesMap) {
      const links = [];
      const linksMap = {};

      for (const key in linkEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(linkEntitiesMap, key)) {
          const c = linkEntitiesMap[key];
          const link = new Link(c);
          links.push(link);
          linksMap[link.id] = link;
        }
      }
      ret.links = links;
      ret.linksMap = linksMap;
    }

    return ret;
  }, [linkEntitiesMap]);
}

export function useMusicianLinks(musicianID) {
  const linkQuery = useGetMusicianLinksQuery(musicianID, {
    skip: (musicianID ?? 0) <= 0,
  });
  const linkEntitiesMap = linkQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof linkQuery & {
      links?: Link[];
      linksMap?: { [id: number]: Link };
    } = {
      ...linkQuery,
      links: [],
      linksMap: {},
    };
    if (linkEntitiesMap) {
      const links = [];
      const linksMap = {};

      for (const key in linkEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(linkEntitiesMap, key)) {
          const c = linkEntitiesMap[key];
          const link = new Link(c);
          links.push(link);
          linksMap[link.id] = link;
        }
      }
      ret.links = links;
      ret.linksMap = linksMap;
    }

    return ret;
  }, [linkEntitiesMap]);
}

export function useTagLinks(tagID) {
  const linkQuery = useGetTagLinksQuery(tagID, {
    skip: (tagID ?? 0) <= 0,
  });
  const linkEntitiesMap = linkQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof linkQuery & {
      links?: Link[];
      linksMap?: { [id: number]: Link };
    } = {
      ...linkQuery,
      links: [],
      linksMap: {},
    };
    if (linkEntitiesMap) {
      const links = [];
      const linksMap = {};

      for (const key in linkEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(linkEntitiesMap, key)) {
          const c = linkEntitiesMap[key];
          const link = new Link(c);
          links.push(link);
          linksMap[link.id] = link;
        }
      }
      ret.links = links;
      ret.linksMap = linksMap;
    }

    return ret;
  }, [linkEntitiesMap]);
}

export function useWorkSessionLinks(workSessionID) {
  const linkQuery = useGetWorkSessionLinksQuery(workSessionID, {
    skip: (workSessionID ?? 0) <= 0,
  });
  const linkEntitiesMap = linkQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof linkQuery & {
      links?: Link[];
      linksMap?: { [id: number]: Link };
    } = {
      ...linkQuery,
      links: [],
      linksMap: {},
    };
    if (linkEntitiesMap) {
      const links = [];
      const linksMap = {};

      for (const key in linkEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(linkEntitiesMap, key)) {
          const c = linkEntitiesMap[key];
          const link = new Link(c);
          links.push(link);
          linksMap[link.id] = link;
        }
      }
      ret.links = links;
      ret.linksMap = linksMap;
    }

    return ret;
  }, [linkEntitiesMap]);
}

export function useLinks() {
  const linkQuery = useGetLinksQuery();
  const linkEntitiesMap = linkQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof linkQuery & {
      links?: Link[];
      linksMap?: { [id: number]: Link };
    } = {
      ...linkQuery,
      links: [],
      linksMap: {},
    };
    if (linkEntitiesMap) {
      const links = [];
      const linksMap = {};

      for (const key in linkEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(linkEntitiesMap, key)) {
          const c = linkEntitiesMap[key];
          const link = new Link(c);
          links.push(link);
          linksMap[link.id] = link;
        }
      }
      ret.links = links;
      ret.linksMap = linksMap;
    }

    return ret;
  }, [linkEntitiesMap]);
}
