/**
 * SectionInstrument_Entity type guard.
 *
 * @param {any} sectionInstrumentJson SectionInstrument object from API
 * @returns {boolean} Return true if type is SectionInstrument_Entity
 */

export default class SectionInstrument_Entity {
  constructor(sectionInstrumentJson: SectionInstrument_Entity) {
    try {
      this.id = sectionInstrumentJson.id;
      this.instrumentID = sectionInstrumentJson.instrumentID;
      this.sectionID = sectionInstrumentJson.sectionID;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  instrumentID: number;
  sectionID: number;
}
