import { Avatar, Button, Chip, Typography } from "@mui/joy";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  darken,
  lighten,
} from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import {
  PreFlightItemTypes,
  PreFlightTypeColor,
} from "entities/internal/helper";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  layoutInternalSelector,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";
import { useUpdateProjectMutation } from "redux/project/projectEndpoints";
import { useProject } from "redux/project/projectHooks";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ProjectPreFlight() {
  const open = useSelector(formOpenSelector("projectPreFlight"));
  const dispatch = useDispatch();
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const project = utils?.project;
  const [updateProject] = useUpdateProjectMutation();
  const { preFlight } = useSelector(layoutInternalSelector);
  const { musiciansMap } = useSelector(layoutUtilsSelector);

  const [selectedType, setSelectedType] = useState<string | null>();

  const [dressCode, setDressCode] = useState(project?.dressCode);

  useEffect(() => {
    if (project) {
      setDressCode(project.dressCode);
    }
  }, [project]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectPreFlight" }));
  };

  const save = () => {
    updateProject({
      id: selectedProjectID,
      body: { ...project, dressCode },
    });
    onClose();
  };

  return (
    <DialogClose maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-solid fa-plane"></i>}
          level="h6"
        >
          Pre-flight Check
        </Typography>
        <Typography level="body2">
          Rhapsody helps you from human errors by showing you oddities. Review
          them before sending the call.
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            <Typography
              level={"body4"}
              onClick={() => setSelectedType(null)}
              variant="outlined"
              sx={{
                padding: 0.5,
                pl: 1,
                pr: 1,
                borderRadius: 16,
                cursor: "pointer",
              }}
            >
              All
            </Typography>
          </Grid>
          {PreFlightItemTypes.map((t) => {
            const items = preFlight.filter((i) => i.type === t);
            if (items.length)
              return (
                <Grid item key={t}>
                  <Typography
                    level={"body4"}
                    onClick={() => setSelectedType(t)}
                    endDecorator={
                      <Typography
                        sx={{
                          background: lighten(PreFlightTypeColor[t], 0.5),
                          color: darken(PreFlightTypeColor[t], 0.6),
                          fontSize: 10,
                          fontWeight: 600,
                          padding: 0.5,
                          borderRadius: 8,
                          width: 14,
                          height: 14,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {items.length}
                      </Typography>
                    }
                    sx={{
                      background: PreFlightTypeColor[t],
                      padding: 0.5,
                      pl: 1,
                      pr: 1,
                      borderRadius: 16,
                      cursor: "pointer",
                      "&:hover": {
                        background: darken(PreFlightTypeColor[t], 0.2),
                      },
                    }}
                  >
                    {t}
                  </Typography>
                </Grid>
              );
          })}
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          gap: 1.5,
          display: "flex",
          flexDirection: "column",
          height: "60vh",
        }}
      >
        {preFlight.length === 0 ? (
          <Box sx={{ textAlign: "center", pt: 6 }}>
            <Typography>
              <b>🎉 Good Job!</b>
            </Typography>
            <Typography level="body2">
              Everything looks good on this Project.
            </Typography>
          </Box>
        ) : (
          []
        )}
        {preFlight
          .filter((i) => (selectedType ? selectedType === i.type : true))
          .map((i) => (
            <Box
              key={i.label}
              sx={{
                background: "#F2F2F2",
                padding: 1,
                borderRadius: "12px",
                display: "flex",
                gap: 1,
              }}
            >
              {i.musicianID ? (
                <MusicianAvatar musician={musiciansMap[i.musicianID]} />
              ) : (
                []
              )}
              {i.projectPieceID ? (
                <Avatar sx={{ background: PreFlightTypeColor[i.type] }}>
                  <i className="fa-solid fa-feather"></i>
                </Avatar>
              ) : (
                []
              )}
              {i.type === "Empty Chairs" ? (
                <Avatar sx={{ background: PreFlightTypeColor[i.type] }}>
                  <i className="fa-solid fa-chair"></i>
                </Avatar>
              ) : (
                []
              )}
              {i.type === "Work Session" ? (
                <Avatar sx={{ background: PreFlightTypeColor[i.type] }}>
                  <i className="fa-solid fa-calendar"></i>
                </Avatar>
              ) : (
                []
              )}
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    level="body2"
                    sx={{ color: "black", fontWeight: 500 }}
                  >
                    {i.label}
                  </Typography>
                  <Typography
                    level="body4"
                    variant="solid"
                    sx={{ background: PreFlightTypeColor[i.type] }}
                  >
                    {i.type}
                  </Typography>
                </Box>
                <Typography level="body2">{i.description}</Typography>
                <Typography
                  startDecorator={<i className="fa-solid fa-lightbulb"></i>}
                  level="body3"
                  variant="outlined"
                  sx={{
                    background: "rgba(255,255,255,0.6)",
                    borderColor: "#e0e0e0",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Resolution</span>:{" "}
                  {i.resolution}
                </Typography>
              </Box>
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
