import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "account" }),
  endpoints: () => ({}),
  tagTypes: ["users", "verificationCodes", "organizations"],
});
