import {
  Alert,
  Button,
  Link as JoyLink,
  List,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/joy";
import { Box, Drawer, Grow, useMediaQuery } from "@mui/material";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import CreateCenter from "hooks/CreateCenter";
import { iconForPlan } from "hooks/subscription/constants";
import { mezzo, overture } from "hooks/subscription/restrictionHooks";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { pricingPlanSelector } from "reducers/onboarding";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { useCurrentCompany } from "redux/company/companyHooks";
import { useMusicians } from "redux/musician/musicianHooks";
import { useVenues } from "redux/venue/venueHooks";

export default function Onboarding() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const navigate = useNavigate();
  const pricingPlan = useSelector(pricingPlanSelector);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down(800));
  const open = useSelector(formOpenSelector("onboarding"));
  const dispatch = useDispatch();
  const askQuestion = useAskQuestion();
  const { company } = useCurrentCompany();
  const { musicians } = useMusicians();
  const { venues } = useVenues();

  const menuItems = [
    {
      title: "Pricing Plan",
      icon: "fa-solid fa-tag",
      disabled: false,
      completed: pricingPlan,
      selected: pathnames.indexOf("pricing-plan") >= 0,
      onClick: () => console.log(""),
      to: "pricing-plan",
    },
    {
      title: "Contractor Profile",
      icon: "fa-solid fa-user-vneck",
      disabled: !pricingPlan,
      completed: company?.contact && company?.email,
      selected: pathnames.indexOf("profile") >= 0,
      onClick: () => console.log(""),
      to: "profile",
    },
    {
      title: "Your Organization",
      icon: "fa-sharp fa-regular fa-globe",
      disabled: !pricingPlan,
      completed:
        company?.name &&
        company?.address &&
        company?.city &&
        company?.zipcode &&
        company?.state,
      selected: pathnames.indexOf("company") >= 0,
      onClick: () => console.log(""),
      to: "company",
    },
    {
      title: "Venues",
      icon: "fa-solid fa-location-dot",
      completed: venues?.length > 0,
      selected: pathnames.indexOf("venues") >= 0,
      disabled: !pricingPlan,
      onClick: () => console.log(""),
      to: "venues",
    },
    {
      title: "Address Book",
      icon: "fa-solid fa-user-music",
      disabled: !pricingPlan,
      selected: pathnames.indexOf("address-book") >= 0,
      onClick: () => console.log(""),
      to: "address-book",
    },
    {
      title: "Team Members",
      icon: "fa-solid fa-user-plus",
      disabled: !pricingPlan || pricingPlan === overture,
      selected: pathnames.indexOf("team-members") >= 0,
      onClick: () =>
        askQuestion(`Not Included in ${pricingPlan}`, ["Cancel", "Yes"], {
          subtitle: (
            <Typography>
              Do you want to change your plan to get access this premium
              feature?
            </Typography>
          ),
        }).then((i) => {
          if (i == 1) navigate("pricing-plan");
        }),
      to: "team-members",
      requirement: "Mezzo or Finale",
    },
    {
      title: "Branding",
      icon: "fa-solid fa-palette",
      disabled:
        !pricingPlan || pricingPlan === overture || pricingPlan === mezzo,
      onClick: () =>
        askQuestion(`Not Included in ${pricingPlan}`, ["Cancel", "Yes"], {
          subtitle: (
            <Typography>
              Do you want to change your plan to get access this premium
              feature?
            </Typography>
          ),
        }).then((i) => {
          if (i == 1) navigate("pricing-plan");
        }),
      selected: pathnames.indexOf("branding") >= 0,
      to: "branding",
      requirement: "Finale",
    },
    {
      title: "Checkout",
      icon: "fa-solid fa-cart-shopping",
      onClick: () => console.log(""),
      to: "checkout",
      hidden: pricingPlan !== mezzo,
    },
  ];

  const next = {
    "/onboarding/pricing-plan": {
      disabled: pricingPlan === null,
      hidden: !pricingPlan,
      to: "profile",
    },
    "/onboarding/profile": {
      disabled: !company?.contact || !company?.email,
      hidden: false,
      back: "pricing-plan",
      to: "company",
    },
    "/onboarding/company": {
      disabled: !(
        company?.name &&
        company?.address &&
        company?.city &&
        company?.zipcode &&
        company?.state
      ),
      hidden: false,
      back: "profile",
      to: "venues",
    },
    "/onboarding/venues": {
      disabled: venues?.length === 0,
      hidden: false,
      to: "address-book",
      back: "company",
    },
    "/onboarding/address-book": {
      disabled: musicians?.length === 0,
      hidden: false,
      to: "address-book",
      back: "company",
    },
  };

  return (
    <Box
      className="canvas"
      sx={{
        minHeight: "100vh",
        width: "100vw",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        position: "relative",
      }}
    >
      <CreateCenter hidden />
      <Drawer
        onClose={() =>
          dispatch(setFormOpen({ formID: "onboarding", isOpen: false }))
        }
        open={sm ? open : true}
        variant={sm ? "temporary" : "permanent"}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            overflow: "auto",
            alignItems: "center",
            width: 320,
            position: "relative",
            flexDirection: "column",
            justifyContent: "center",
            p: 2,
            gap: 2,
          }}
        >
          <img
            alt="logo"
            src="https://storage.googleapis.com/rhapsody/logos/logo_main.png"
            height="40"
          />
          <Grow in={pricingPlan !== null}>
            <Alert sx={{ width: "100%" }} color="neutral" variant="soft">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography level="body2">Selected plan:</Typography>
                <Box sx={{ textAlign: "right", lineHeight: 0.5 }}>
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    startDecorator={iconForPlan[pricingPlan]}
                  >
                    {pricingPlan}
                  </Typography>
                  <JoyLink
                    component={Link}
                    to="/onboarding/pricing-plan"
                    level="body4"
                    color="neutral"
                  >
                    Change
                  </JoyLink>
                </Box>
              </Box>
            </Alert>
          </Grow>
          <Box sx={{ width: "100%" }}>
            <List>
              {menuItems
                ?.filter((h) => !h.hidden)
                ?.map((m) => (
                  <ListItemButton
                    onClick={m.disabled ? m.onClick : undefined}
                    key={m.to}
                    variant={m.selected ? "soft" : undefined}
                    to={!m.disabled ? m.to : undefined}
                    component={!m.disabled ? Link : undefined}
                    sx={{
                      gap: 2,
                      cursor: m.disabled ? "default" : "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      opacity: m.disabled ? 0.3 : 1,
                    }}
                  >
                    <Typography
                      sx={{ flex: 1 }}
                      startDecorator={<i class={m.icon}></i>}
                    >
                      {m.title}
                    </Typography>
                    <Typography level="body3">{m.requirement}</Typography>
                    {m.completed ? (
                      <Grow in>
                        <Typography color="success">
                          <i class="fa-solid fa-square-check"></i>
                        </Typography>
                      </Grow>
                    ) : (
                      []
                    )}
                  </ListItemButton>
                ))}
            </List>
          </Box>
        </Box>
      </Drawer>
      <Box
        sx={{
          textAlign: "center",
          flex: 1,
          marginLeft: !sm ? "320px" : 0,
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ p: 2, minHeight: "calc(100vh - 98px)", display: "flex" }}>
            <Outlet />
          </Box>
          {!next[location.pathname]?.hidden ? (
            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                p: 3,
                background: "white",
                borderTop: "solid 1px #E0E0E0",
                display: "flex",
                gap: 1,
              }}
            >
              {next[location.pathname]?.back ? (
                <Button
                  onClick={() => navigate(next[location.pathname]?.back)}
                  autoFocus
                  size="lg"
                  fullWidth
                  color="neutral"
                  variant="soft"
                  startDecorator={<i className="fa-solid fa-chevron-left"></i>}
                >
                  Back
                </Button>
              ) : (
                []
              )}
              <Button
                onClick={() => navigate(next[location.pathname]?.to)}
                autoFocus
                size="lg"
                fullWidth
                disabled={next[location.pathname]?.disabled}
                endDecorator={<i className="fa-solid fa-chevron-right"></i>}
              >
                Next
              </Button>
              {location.pathname}
            </Box>
          ) : (
            []
          )}
        </Box>
      </Box>
    </Box>
  );
}
