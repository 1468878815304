/**
 * Subscription_Entity type guard.
 *
 * @param {any} subscriptionJson Subscription object from API
 * @returns {boolean} Return true if type is Subscription_Entity
 */

import { CancellationDetail_Entity } from "entities/cancellationDetail";

export default class Subscription_Entity {
  constructor(subscriptionJson: Subscription_Entity) {
    try {
      this.id = subscriptionJson.id;
      this.customerID = subscriptionJson.customerID;
      this.email = subscriptionJson.email;
      this.productID = subscriptionJson.productID;
      this.productName = subscriptionJson.productName;
      this.created = subscriptionJson.created;
      this.startDate = subscriptionJson.startDate;
      this.trialStart = subscriptionJson.trialStart;
      this.trialEnd = subscriptionJson.trialEnd;
      this.endedAt = subscriptionJson.endedAt;
      this.daysUntilDue = subscriptionJson.daysUntilDue;
      this.currentPeriodStart = subscriptionJson.currentPeriodStart;
      this.currentPeriodEnd = subscriptionJson.currentPeriodEnd;
      this.canceledAt = subscriptionJson.canceledAt;
      this.cancelAtPeriodEnd = subscriptionJson.cancelAtPeriodEnd;
      this.cancelAt = subscriptionJson.cancelAt;
      this.cancellationDetail = subscriptionJson.cancellationDetail;
      this.discount = subscriptionJson.discount;
      this.paymentMethod = subscriptionJson.paymentMethod;
      this.status = subscriptionJson.status;
      this.companyID = subscriptionJson.companyID;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }
  id: string;
  customerID: string;
  email: string;
  productID: string;
  productName: string;
  created: string;
  startDate: string;
  trialStart: string;
  trialEnd: string;
  endedAt: string;
  daysUntilDue: number;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  canceledAt: string;
  cancelAtPeriodEnd: boolean;
  cancelAt: string;
  cancellationDetail: CancellationDetail_Entity;
  discount: string;
  paymentMethod: string;
  status: string;
  companyID: number;
}
