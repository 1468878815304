import {
  Chip,
  Divider,
  Sheet,
  Tooltip,
  Typography,
  useTheme,
  Button,
} from "@mui/joy";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  alpha,
  useMediaQuery,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dictionary } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import Id from "atoms/Id/Id";
import { Project } from "entities/project";
import { Stage } from "entities/stage";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { MusicianTalkbackList } from "hooks/MusicianTalkbackList/MusicianTalkbackList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectedTagIDSelector,
  setBackToSeason,
  setFormOpen,
  setSelectedMusicianID,
} from "reducers/rhapsody";
import { rhapsodyApi } from "redux/api";
import { mercuryApi } from "redux/api/mercuryApi";
import {
  useAssignments,
  useMusicianAssignments,
} from "redux/assignment/assignmentHooks";
import { useUpdateProjectMutation } from "redux/project/projectEndpoints";
import {
  useArchivedProjects,
  useArchivedTemplates,
  useCustomerProjects,
  useMusicianProjects,
  usePayrollProjects,
  useProjects,
  useProjectsForPieceID,
  useTagProjects,
  useTemplates,
  useVenueProjects,
} from "redux/project/projectHooks";
import { useProjectMercury2 } from "redux/projectMercury/projectMercuryHooks";
import {
  useCreateProjectTagMutation,
  useDeleteProjectTagMutation,
} from "redux/projectTag/projectTagEndpoints";
import { useProjectTags } from "redux/projectTag/projectTagHooks";
import { useStages } from "redux/stage/stageHooks";
import { useTag, useTags } from "redux/tag/tagHooks";
import { getPieChartData } from "routes/journal/v2/projectHiring";

export default function ProjectsDataGrid({
  tagID,
  musicianID,
  customerID,
  venueID,
  payrollID,
  template,
  archivedTemplate,
  archived,
  pieceID,
  noHiring,
  autoheight = true,
  noCheckboxes,
  onSelect,
}: {
  tagID?: number;
  musicianID?: number;
  customerID?: number;
  venueID?: number;
  payrollID?: number;
  template?: boolean;
  archivedTemplate?: boolean;
  archived?: number;
  pieceID?: number;
  noHiring?: boolean;
  autoheight?: boolean;
  noCheckboxes?: boolean;
  onSelect?: (p: Project) => void;
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [selected, setSelected] = useState([]);
  const { stagesMap } = useStages();
  const { projects, isFetching: f1, isLoading: l1 } = useProjects();
  const { templates, isFetching: f2, isLoading: l2 } = useTemplates();
  const { tagProjects, isFetching: f3, isLoading: l3 } = useTagProjects(tagID);
  const { projects: pieceProjects, projectPieces } =
    useProjectsForPieceID(pieceID);
  const {
    musicianProjects,
    isFetching: f4,
    isLoading: l4,
  } = useMusicianProjects(musicianID);
  const {
    venueProjects,
    isFetching: f5,
    isLoading: l5,
  } = useVenueProjects(venueID);
  const {
    payrollProjects,
    isFetching: f6,
    isLoading: l6,
  } = usePayrollProjects(payrollID);
  const {
    customerProjects,
    isFetching: f7,
    isLoading: l7,
  } = useCustomerProjects(customerID);
  const {
    projects: archivedProjects,
    isFetching: f8,
    isLoading: l8,
  } = useArchivedProjects();
  const {
    templates: archivedTemplates,
    isFetching: f9,
    isLoading: l9,
  } = useArchivedTemplates(!archivedTemplate);

  const { assignments } = useMusicianAssignments(musicianID);

  let rows = projects;
  if (customerID) rows = customerProjects;
  if (payrollID) rows = payrollProjects;
  if (archived) rows = archivedProjects;
  if (musicianID) rows = musicianProjects;
  if (venueID) rows = venueProjects;
  if (tagID > 0) rows = tagProjects;
  if (template) rows = templates;
  if (archivedTemplate) rows = archivedTemplates;
  if (pieceID) rows = pieceProjects;

  if (!rows) rows = [];

  if (projectPieces) {
    rows = rows.reduce((a, v) => {
      const pp = projectPieces.filter(
        (p) => p.projectID === v.id && p.pieceID === pieceID
      );
      if (pp.length) {
        a.push({ ...v, shorthand: pp[0].description });
      } else {
        a.push(v);
      }
      return a;
    }, []);
  }

  const hasV1 = rows.reduce((a, v) => {
    if (v.version === 1) a = true;
    return a;
  }, false);

  const rowsLoading =
    l1 ||
    l2 ||
    l3 ||
    l4 ||
    l5 ||
    l6 ||
    l7 ||
    l8 ||
    l9 ||
    f1 ||
    f2 ||
    f3 ||
    f4 ||
    f5 ||
    f6 ||
    f7 ||
    f8 ||
    f9;

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (!checkboxSelection && apiRef?.current) {
      setSelected([]);
      apiRef.current.selectRows(selected, false);
    }
  }, [checkboxSelection]);

  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id startDecorator={<i className="fa-solid fa-folder-open"></i>}>
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "logoURL",
      headerName: "Cover",
      type: "string",
      width: 75,
      renderCell: (p) =>
        p.row.logoURL ? (
          <img
            src={p.row.logoURL}
            style={{ width: 37, height: 37, objectFit: "cover" }}
          />
        ) : (
          <Box />
        ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      width: 250,
      renderCell: (p) => (
        <Typography
          level="body2"
          startDecorator={
            <Box
              sx={{
                background: p.row.color,
                height: 15,
                width: 15,
                borderRadius: 10,
              }}
            />
          }
          endDecorator={
            p.row.version === 2 && hasV1 ? (
              <Typography color="info" variant="soft" level="body4">
                Maestro
              </Typography>
            ) : (
              []
            )
          }
        >
          {p.row.name}
        </Typography>
      ),
    },
    {
      field: "description",
      headerName: "Internal Memo",
      type: "string",
      flex: 1,
      renderCell: (p) => (
        <Sheet sx={{ background: "rgba(255,235,59,0.1)", p: p.value && 1 }}>
          <Typography level="body3">{p.value}</Typography>
        </Sheet>
      ),
    },
  ];

  if (!noHiring && !template) {
    columns.push({
      field: "messages",
      headerName: "Messages",
      type: "string",
      width: 150,
      renderCell: (p) => (
        <Messages projectID={p.row.id} stagesMap={stagesMap} />
      ),
    });
  }

  if (!noHiring && !template) {
    columns.push({
      field: "hiring",
      headerName: "Hiring",
      type: "string",
      width: 150,
      renderCell: (p) => (
        <HiringProgress
          version={p.row.version}
          projectID={p.row.id}
          stagesMap={stagesMap}
        />
      ),
    });
  }

  if (pieceID) {
    columns.push({
      field: "shorthand",
      headerName: "Instr. Shorthand",
      type: "string",
      flex: 1,
      renderCell: undefined,
    });
  }

  if (musicianID) {
    columns.push({
      field: "mercuryJobID",
      headerName: "Status",
      type: "string",
      width: 220,
      renderCell: (p) => {
        const assignment = assignments.find((a) => a.projectID === p.row.id);
        const stage = stagesMap[assignment?.mercuryStageID];
        if (!stage) return <Box />;
        return (
          <Chip
            size="sm"
            sx={{ background: alpha(stage.color, 0.3), color: stage.color }}
          >
            {stage?.wording()}
          </Chip>
        );
      },
    });
  }

  return (
    <>
      <DataGridPro
        autoHeight={autoheight}
        pagination
        pageSize={12}
        loading={rowsLoading}
        density="compact"
        apiRef={apiRef}
        onRowClick={(p) => {
          if (onSelect) onSelect(p.row);
          if (tagID > 0) {
            dispatch(setBackToSeason(`${tagID}/projects`));
          }
          if (musicianID) {
            dispatch(setSelectedMusicianID());
          }
          if (template || archivedTemplate) {
            if (p.row.version === 2) {
              navigate(`${RouterConfig.templates}/${p.row.id}/mission-control`);
            } else {
              navigate(`${RouterConfig.templates}/${p.row.id}/seating`);
            }
          } else {
            if (p.row.version === 2) {
              navigate(`${RouterConfig.projects}/${p.row.id}/mission-control`);
            } else {
              navigate(
                `${RouterConfig.projects}/${p.row.id}/${
                  p.row.defaultTab.length === 0 ? "seating" : p.row.defaultTab
                }`
              );
            }
          }
        }}
        getRowClassName={(r) => (r.row.archived ? "archived" : "")}
        rows={template ? rows.filter((p) => p.template) : rows}
        columns={columns}
        disableSelectionOnClick
        onSelectionModelChange={(e) => {
          setSelected(e);
        }}
        columnVisibilityModel={{
          id: !sm,
          color: !sm,
          description: !sm,
        }}
        checkboxSelection={
          !sm && !template && !archivedTemplate && !noCheckboxes
        }
        components={{
          Toolbar: CustomToolbar,
        }}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "id",
                sort: "desc",
              },
            ],
          },
          pinnedColumns: {
            right: ["mercuryJobID"],
          },
        }}
        componentsProps={{
          toolbar: {
            setCheckboxSelection,
            selected,
            tagID,
            template,
            rows,
          },
        }}
      />
      {/* {selectedProjectID && autopilotShortcut ? (
        <Autopilot quickview projectID={selectedProjectID} />
      ) : (
        []
      )} */}
    </>
  );
}

export function Messages({
  projectID,
  stagesMap,
}: {
  projectID: number;
  stagesMap?: Dictionary<Stage>;
}) {
  const { hProject } = useProjectMercury2({
    id: projectID,
    body: {},
  });
  const api = rhapsodyApi.endpoints as any;
  console.log(api.getProjectMercury.select());
  // const selectGetUserResult = mercuryApi.endpoints.hProjects.select();
  const [open, setOpen] = useState(false);
  const talkback = hProject?.musicianTalkback();
  const dispatch = useDispatch();
  const newTalkback = talkback?.filter((t) => !t.success);

  const onClose = () => {
    dispatch(
      rhapsodyApi.util.invalidateTags([
        { type: "projectMercury", id: projectID },
      ])
    );
    setOpen(false);
  };

  if (newTalkback?.length)
    return (
      <>
        <Chip
          size="sm"
          color="danger"
          startDecorator={<i className="fa-solid fa-comment"></i>}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(true);
          }}
        >
          {newTalkback?.length} Message{newTalkback?.length !== 1 ? "s" : ""}
        </Chip>
        <DialogClose open={open} onClose={onClose} maxWidth="xs" fullWidth>
          <DialogTitle>
            <Typography level="h6">Messages</Typography>
            <Typography
              startDecorator={<i className="fa-solid fa-eye"></i>}
              level="body2"
            >
              Click on messages to mark them as read
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <MusicianTalkbackList hProject={hProject} stagesMap={stagesMap} />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={onClose} color="neutral" variant="soft">
              Close
            </Button>
          </DialogActions>
        </DialogClose>
      </>
    );

  return <Box />;
}

function HiringProgress({
  projectID,
  stagesMap,
  version,
}: {
  projectID: number;
  stagesMap: Dictionary<Stage>;
  version: number;
}) {
  const { assignments } = useAssignments(version === 2 ? projectID : undefined);

  if (version !== 2) return <Box />;

  const pieChart = getPieChartData(assignments, stagesMap);
  const { data, palette, labels } = pieChart;

  const release = assignments.filter((a) => a.releaseWorkSessionIDs);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 0.5,
        height: 10,
        position: "relative",
        alignItems: "center",
        borderRadius: "16px",
        width: "100%",
        flex: 1,
      }}
    >
      {data.map((e, i) => (
        <Tooltip
          key={e.label}
          variant="outlined"
          size="sm"
          arrow
          title={
            <Typography
              level="body2"
              sx={{ color: palette[i] }}
              startDecorator={<img src={labels[i].icon} height="14px" />}
            >
              {labels[i].count} {labels[i].label}
            </Typography>
          }
        >
          <Box
            sx={{
              height: "100%",
              flex: e.value,
              background: alpha(palette[i], 0.8),
              border: `solid 1px ${palette[i]}`,
              borderRadius: "16px",
            }}
          ></Box>
        </Tooltip>
      ))}
      {release.length ? (
        <Tooltip
          title={`${release.length} release request${
            release.length > 1 ? "s" : ""
          }`}
          variant="outlined"
          size="sm"
          arrow
        >
          <Typography level="body3" color="danger">
            <i className="fa-solid fa-person-from-portal"></i>
          </Typography>
        </Tooltip>
      ) : (
        []
      )}
    </Box>
  );
}

function CustomToolbar({
  setCheckboxSelection,
  selected,
  tagID,
  template,
  rows,
}) {
  const dispatch = useDispatch();
  const selectedTagID = useSelector(selectedTagIDSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>();
  const { tags } = useTags();
  const { tag: selectedTag } = useTag(tagID);
  const { tagProjectsMap } = useTagProjects(tagID);
  const [deleteProjectTag] = useDeleteProjectTagMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [createProjectTag] = useCreateProjectTagMutation();

  const apiRef = useGridApiContext();

  const handleCreateProjectTag = async (tID) => {
    setAnchorEl(null);
    for (const key in selected) {
      if (Object.hasOwnProperty.call(selected, key)) {
        const projectID = selected[key];
        createProjectTag({
          projectID,
          tagID: tID,
        });
      }
    }

    dispatch(
      rhapsodyApi.util.invalidateTags([
        { type: "projects", id: `tag_${tagID}` },
      ])
    );
    apiRef.current.selectRows(selected, false);
    setCheckboxSelection(false);
  };

  const handleDeleteProjectTag = async () => {
    for (const key in selected) {
      if (Object.hasOwnProperty.call(selected, key)) {
        const projectID = selected[key];
        deleteProjectTag(tagProjectsMap[projectID].tagRelationID);
      }
    }
    dispatch(
      rhapsodyApi.util.invalidateTags([
        { type: "projects", id: `tag_${tagID}` },
      ])
    );
    apiRef.current.selectRows(selected, false);
    setCheckboxSelection(false);
  };

  const handleArchive = async () => {
    const archived = `${tagID}` !== "-2";

    for (const key in selected) {
      if (Object.hasOwnProperty.call(selected, key)) {
        const projectID = selected[key];
        const project = rows.find((p) => p.id === projectID);
        updateProject({
          id: projectID,
          body: {
            ...project,
            archived,
          },
        });
      }
    }
    dispatch(rhapsodyApi.util.invalidateTags(["projects"]));

    apiRef.current.selectRows(selected, false);
    setCheckboxSelection(false);
  };

  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
      <>
        {!template ? (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center", m: 1 }}>
            {/* {selected?.length && selectedTag ? (
              <Button variant="outlined" onClick={handleDeleteProjectTag}>
                Remove {selected.length} Project
                {selected.length > 1 ? "s" : ""} from {selectedTag.name}
              </Button>
            ) : (
              []
            )} */}
            {selected?.length && !selectedTagID ? (
              <Button
                color="primary"
                variant="outlined"
                onClick={handleArchive}
              >
                {`${tagID}` !== "-2" ? "Archive" : "Unarchive"}{" "}
                {selected.length} Project
                {selected.length > 1 ? "s" : ""}
              </Button>
            ) : (
              []
            )}
            {/* {selected?.length && tags.length && !selectedTagID ? (
              <Button
                color="primary"
                variant="outlined"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                Add {selected.length} Project{selected.length > 1 ? "s" : ""} to
                Season
                <i className="fa-solid fa-angle-down p-left"></i>
              </Button>
            ) : (
              []
            )} */}
          </Box>
        ) : (
          []
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {tags.map((t) => (
            <MenuItem
              key={t.id}
              sx={{ display: "flex", gap: 1 }}
              onClick={() => handleCreateProjectTag(t.id)}
            >
              <img src={t.image} height={30} />
              {t.name}
            </MenuItem>
          ))}
        </Menu>
      </>
      {/* {tagID > 0 ? (
        <Button
          color="neutral"
          variant="soft"
          onClick={() =>
            dispatch(
              setFormOpen({ isOpen: true, formID: "addProjectToSeason" })
            )
          }
        >
          Add Projects to Season
        </Button>
      ) : (
        []
      )} */}
    </Box>
  );
}
