import Scale_Entity from "./scale";
export default class Scale extends Scale_Entity {
  static fromList(scalesJSON: unknown): Array<Scale> {
    const scales: Scale[] = [];
    if (scalesJSON)
      Array.isArray(scalesJSON) &&
        scalesJSON.forEach((scaleJSON) => {
          scales.push(new Scale(scaleJSON));
        });
    return scales;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
