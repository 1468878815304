import {
  Box,
  Button,
  Chip,
  ColorPaletteProp,
  Input,
  Option,
  Select,
  Sheet,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import Upload from "atoms/FileUpload/FileUpload";
import { Tag_Entity } from "entities/tag";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectedTagIDSelector, setFormOpen } from "reducers/rhapsody";
import { layoutDebugSelector } from "reducers/v2/missionControl";
import { useCurrentCompany } from "redux/company/companyHooks";
import {
  useDeleteTagMutation,
  useUpdateTagMutation,
} from "redux/tag/tagEndpoints";
import { useTag } from "redux/tag/tagHooks";

/**
 *
 * @returns {ReactElement} SeasonSettings page
 */
export function SeasonSettings() {
  const tagID = useSelector(selectedTagIDSelector);
  const { tag } = useTag(tagID);

  return (
    <Box sx={{ p: 1, flex: 1, pr: 2 }}>
      <Grid container spacing={2} alignItems={"stretch"}>
        <Grid
          item
          xs={12}
          md={6}
          spacing={1}
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
          }}
        >
          <TagSettingsAbout />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          spacing={1}
          sx={{ display: "flex", gap: 2, flexDirection: "column" }}
        >
          <TagScale />
          {/* <ProjectDressCode /> */}
        </Grid>
        {/* <Grid item xs={12}>
          <ProjectContacts />
        </Grid>
        <Grid item xs={12}>
          <ProjectLinks />
        </Grid>
        <Grid item xs={12}>
          <ProjectNotes />
        </Grid> */}

        <Grid item xs={12} sx={{ mb: 2 }}>
          <TagDangerZone />
        </Grid>
      </Grid>
    </Box>
  );
}

export function TagScale() {
  const dispatch = useDispatch();

  return (
    <BoxWrapper>
      <Typography
        startDecorator={<i className="fa-solid fa-wallet"></i>}
        level="h6"
      >
        Season Scales
      </Typography>
      <Typography level="body3">
        Define scales for Season's projects.
      </Typography>
      <Box>
        <Button
          color="neutral"
          variant="outlined"
          sx={{ background: "white" }}
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "scales" }))
          }
        >
          View Scales
        </Button>
      </Box>
    </BoxWrapper>
  );
}

export function TagSettingsAbout() {
  const tagID = useSelector(selectedTagIDSelector);
  const { tag } = useTag(tagID);
  const [_tag, setTag] = useState<Tag_Entity>(tag);
  const [updateTag] = useUpdateTagMutation();

  const save = () => {
    updateTag({ id: tagID, body: { ..._tag } });
  };

  useEffect(() => {
    setTag(tag);
  }, [tag]);

  return (
    <BoxWrapper>
      <Typography level="h6">About the Season</Typography>
      <TagCover />
      <Input
        startDecorator="Name:"
        value={_tag?.name}
        onChange={(e) => setTag((p) => ({ ...p, name: e.target.value }))}
        onBlur={save}
      />
    </BoxWrapper>
  );
}

export function TagCover() {
  const tagID = useSelector(selectedTagIDSelector);
  const { tag } = useTag(tagID);
  const [updateTag] = useUpdateTagMutation();
  const [_tag, setTag] = useState<Tag_Entity>(tag);

  useEffect(() => {
    setTag(tag);
  }, [tag]);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {_tag?.image ? (
        <img
          src={_tag.image}
          style={{ width: 95, height: 95, borderRadius: "8px" }}
        />
      ) : (
        <Upload
          onUpload={(f) => {
            updateTag({ id: tagID, body: { ..._tag, image: f } });
          }}
          cropDimension={{ width: 250, height: 250 }}
        >
          <Box
            sx={{
              width: 95,
              height: 95,
              flexShrink: 0,
              border: "dashed 1.5px #9e9e9e",
              borderRadius: "8px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography level="h4" sx={{ color: "#9e9e9e" }}>
              <i className="fa-duotone fa-image"></i>
            </Typography>
          </Box>
        </Upload>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography level="body2" sx={{ color: "black", fontWeight: 600 }}>
          Season Cover
        </Typography>
        <Typography level="body3">
          An artwork respresenting your Season. It must be at least 250px by
          250px with a max size of 512KB. The supported MIME types are JPG, PNG,
          and GIf.
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Upload
            cropDimension={{ width: 250, height: 250 }}
            onUpload={(f) => {
              setTag((pro) => ({ ...pro, logoURL: f }));
              updateTag({
                id: tagID,
                body: { ..._tag, image: f },
              });
            }}
          />
          {_tag?.image ? (
            <Chip
              onClick={() =>
                updateTag({
                  id: tagID,
                  body: { ..._tag, image: "" },
                })
              }
              size="sm"
              variant="outlined"
              color="danger"
            >
              Remove Cover
            </Chip>
          ) : (
            []
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function TagDangerZone() {
  const tagID = useSelector(selectedTagIDSelector);
  const readOnly = useReadOnly();
  const [updateTag] = useUpdateTagMutation();
  const { tag } = useTag(tagID);
  const [confirm, setConfirm] = useState(false);
  const [tagName, setTagName] = useState("");
  const { company } = useCurrentCompany();
  const [deleteTag] = useDeleteTagMutation();
  const navigate = useNavigate();
  const debugMode = useSelector(layoutDebugSelector);

  return (
    <BoxWrapper color="danger">
      <Typography level="h6" color="danger">
        Danger Zone
      </Typography>
      {debugMode ? (
        <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
          <Select
            disabled={readOnly}
            sx={{ width: 200 }}
            color="danger"
            startDecorator="Pipeline:"
            value={tag.pipelineID}
            onChange={(e, v) => {
              updateTag({
                id: tag.id,
                body: {
                  ...tag,
                  pipelineID: v,
                },
              });
            }}
          >
            {company?.pipelines?.map((p) => (
              <Option value={p.id} key={p.id}>
                {p.name}
              </Option>
            ))}
          </Select>
          <Typography level="body3" color="danger">
            Select the Mercury Pipeline you would like to use for this project.
          </Typography>
        </Box>
      ) : (
        []
      )}
      <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
        <Box>
          <Button
            sx={{ flexShrink: 0, width: 200 }}
            color="danger"
            variant="solid"
            onClick={() => setConfirm(true)}
          >
            Delete Season
          </Button>
        </Box>
        <Typography level="body3" color="danger">
          Related projects won't be deleted.
          <br />
          There is no way back.
        </Typography>
      </Box>
      <DialogClose open={confirm} onClose={() => setConfirm(false)}>
        <DialogTitle>One last time: Are you sure?</DialogTitle>
        <DialogContent>
          <Typography level={"body2"}>
            Please confirm this un-recoverable action by writting the name of
            the Season:
          </Typography>
          <Input
            autoFocus
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
            placeholder={tag?.name}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirm(false)}
            color="neutral"
            variant="soft"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteTag(tag.id);
              navigate(RouterConfig.projects);
            }}
            color="danger"
            disabled={tag?.name !== tagName}
          >
            Delete forever
          </Button>
        </DialogActions>
      </DialogClose>
    </BoxWrapper>
  );
}

function BoxWrapper({
  children,
  color = "neutral",
}: {
  children: ReactNode;
  color?: ColorPaletteProp;
}) {
  return (
    <Sheet
      variant="soft"
      color={color}
      sx={{
        p: 2,
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        flex: 1,
      }}
    >
      {children}
    </Sheet>
  );
}
