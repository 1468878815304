import { Button, Chip, Option, Select, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useCompanySettings } from "redux/company/companyHooks";
import { useUpdateProjectMutation } from "redux/project/projectEndpoints";
import { convert } from "html-to-text";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ProjectDressCode() {
  const open = useSelector(formOpenSelector("projectDressCode"));
  const dispatch = useDispatch();
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const project = utils?.project;
  const [updateProject] = useUpdateProjectMutation();
  const settings = useCompanySettings();
  const [dressCode, setDressCode] = useState(project?.dressCode);

  useEffect(() => {
    if (project) {
      setDressCode(project.dressCode);
    }
  }, [project]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectDressCode" }));
  };

  const save = () => {
    const empty = convert(dressCode).trim().length === 0;

    updateProject({
      id: selectedProjectID,
      body: { ...project, dressCode: empty ? "" : dressCode },
    });
    onClose();
  };

  const dressCodePreset = settings?.defaultDressCodes?.find(
    (e) => e.content === dressCode
  );

  return (
    <DialogClose maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-solid fa-clothes-hanger"></i>}
          level="h6"
        >
          Dress Code
        </Typography>
        <Typography level="body2">
          This info will be included in the call and communication.
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ gap: 1, display: "flex", flexDirection: "column", height: 550 }}
      >
        <Select
          startDecorator={
            <Typography level="body2">
              <b>Use Snippet:</b>
            </Typography>
          }
          slotProps={{
            listbox: { sx: { zIndex: 9999 } },
          }}
          size="sm"
          variant="soft"
          placeholder={
            dressCodePreset ? dressCodePreset.name : "Select a Snippet"
          }
          value={""}
          onChange={(e, v) => {
            if (v) {
              setDressCode(v);
              updateProject({
                id: selectedProjectID,
                body: { ...project, dressCode: v },
              });
            }
          }}
        >
          {settings.defaultDressCodes?.map((o) => (
            <Option key={o.content} value={o.content}>
              {o.name}
            </Option>
          ))}
          <Option
            onClick={() =>
              dispatch(
                setFormOpen({ isOpen: true, formID: "defaultDressCodes" })
              )
            }
            value="create"
            color="primary"
          >
            <b>+ Create Snippet</b>
          </Option>
        </Select>

        <ReactQuill
          theme="snow"
          style={{ height: 400 }}
          value={dressCode}
          onChange={(e) => {
            console.log(e);
            setDressCode(e);
          }}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
            "color",
            "background",
            "align",
            "direction",
            "code",
            "code-block",
          ]}
          modules={{
            toolbar: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "code-block"],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ["clean"], // remove formatting button
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}
