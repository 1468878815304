import Email_Entity from "./email";
export default class Email extends Email_Entity {
  static fromList(emailsJSON: unknown): Array<Email> {
    const emails: Email[] = [];
    if (emailsJSON)
      Array.isArray(emailsJSON) &&
        emailsJSON.forEach((emailJSON) => {
          emails.push(new Email(emailJSON));
        });
    return emails;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
