import React, { useEffect } from "react";
// import PropTypes from 'prop-types';

// material-ui
import Typography from "@mui/material/Typography";

// styles
import { Chip, CircularProgress, useTheme } from "@mui/joy";
import { Backdrop, Grid, Popover, useMediaQuery } from "@mui/material";

import ListButton from "atoms/ListButton";
import { AssignmentLookup } from "features/chair/v2/assignmentLookup";
import { MercuryHistory } from "features/chair/v2/mercuryHistory";
import { PositionLookup } from "features/chair/v2/positionLookup";
import { FormAutofill } from "features/forms/FormAutofill/FormAutofill";
import { Formcompany } from "features/forms/FormCompany/FormCompany";
import FormCustomer from "features/forms/FormCustomer";
import FormGroup from "features/forms/FormGroup";
import FormLink from "features/forms/FormLink";
import FormMusician from "features/forms/FormMusician/FormMusician";
import FormMusicianNote from "features/forms/FormMusicianNote";
import { FormOrganizationUser } from "features/forms/FormOrganizationUser/FormOrganizationUser";
import FormPayroll from "features/forms/FormPayroll";
import FormPiece from "features/forms/FormPiece";
import FormProject from "features/forms/FormProject";
import FormProjectNote from "features/forms/FormProjectNote/FormProjectNote";
import { FormProjectPiece } from "features/forms/FormProjectPiece/v1/FormProjectPiece";
import { FormProjectPiece2 } from "features/forms/FormProjectPiece/v2/FormProjectPiece";
import { FormProjectPieceReorder } from "features/forms/FormProjectPieceReorder/FormProjectPieceReorder";
import { FormProjectProgram } from "features/forms/FormProjectProgram/FormProjectProgram";
import FormTag from "features/forms/FormTag";
import { FormUploadMusicianTemplate } from "features/forms/FormUploadMusicianTemplate/FormUploadMusicianTemplate";
import FormVenue from "features/forms/FormVenue";
import FormWorkSession from "features/forms/FormWorkSession";
import { SearchMusician } from "features/forms/SearchMusician/searchMusician";
import { ProjectActionRequired } from "features/projects/ProjectActionsRequired/ProjectActionsRequired";
import { ProjectPieceAdder } from "features/projects/projectPieces/projectPieceAdder";
import { RetrievePieceInstrumentation } from "features/projects/projectPieces/retrievePieceInstrumentation";
import EditWorkSession from "hooks/EditWorkSession";
import { FormerAssignments } from "hooks/FormerAssignments/FormerAssignments";
import { ImportRules } from "hooks/ImportRules/importRules";
import { MusicianTalkback } from "hooks/MusicianTalkback/MusicianTalkback";
import { MusiciansForChairs } from "hooks/MusiciansForChairs/MusiciansForChairs";
import { Notifier } from "hooks/Notifier/v1/notifier";
import { Notifier2 } from "hooks/Notifier/v2/notifier";
import { PayDayProject } from "hooks/PayDayProject/PdProject";
import { PdReorderGroups } from "hooks/PayDayProject/PdReorderGroups";
import { ProjectImport } from "hooks/ProjectImport/ProjectImport";
import ProjectNotesFromProject from "hooks/ProjectNotesFromProject/ProjectNotesFromProject";
import { ReleaseAdmin } from "hooks/ReleaseAdmin/ReleaseAdmin";
import { ReleaseRequest } from "hooks/ReleaseRequest/ReleaseRequest";
import RescheduleWorkSession from "hooks/RescheduleWorkSession";
import { RevisionCallInfo } from "hooks/RevisionCallInfo/RevisionCallInfo";
import { ScalesForm } from "hooks/Scales/ScalesForm";
import { WorkSessionMusicians } from "hooks/WorkSessionMusicians/WorkSessionMusicians";
import { ProjectContactForm } from "hooks/projectContact/projectContact";
import { ProjectContacts } from "hooks/projectContacts/projectContacts";
import { ProjectDressCode } from "hooks/projectDressCode/projectDressCode";
import { ProjectPieceShorthand } from "hooks/projectPieceShorthand/projectPieceShorthand";
import { ProjectPreFlight } from "hooks/projectPreFlight/projectPreFlight";
import { ProjectRoster } from "hooks/projectRoster/projectRoster";
import { ProjectWageInfo } from "hooks/projectWageInfo/projectWageInfo";
import { ReorderMusicianInSection } from "hooks/reorderMusicianInSection/reorderMusicianInSection";
import { ReplaceMusicians } from "hooks/replaceMusicians/replaceMusicians";
import { Subscribe } from "hooks/subscription/subscribe";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { WorkSessionMemos } from "hooks/workSessionMemos/workSessionMemos";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { rescheduleEventSelector } from "reducers/workSessions";
import { useGetCurrentCompanyQuery } from "redux/company/companyEndpoints";
import { AddProjectToSeason } from "hooks/AddProjectToSeason/AddProjectToSeason";
import { useCompanySettings } from "redux/company/companyHooks";
import { RestrictedAccount } from "hooks/RestrictedAccount/RestrictedAccount";
import { meSelector } from "selectors/me";
import { Notifier3 } from "hooks/Notifier/v3/notifier";
import { Utils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { internalInitSelector } from "reducers/v2/missionControl";
import { DefaultString } from "hooks/DefaultStrings/DefaultStrings";
import { DefaultDressCodes } from "hooks/DefaultDressCode/DefaultDressCode";
import { ProjectLinks } from "hooks/projectLinks/projectLinks";
import { NotifierProjectNotes } from "hooks/Notifier/v2/notifierProjectNotes";
import { ProjectNeeded } from "features/forms/FormWorkSession/ProjectNeeded";
import { ProjectRosters } from "hooks/projectRosters/projectRosters";
import { DefaultProjectNotes } from "hooks/DefaultProjectNotes/DefaultProjectNotes";
import { StripeBalance } from "hooks/stripeBalance/stripeBalance";

export default function CreateCenter({ hidden = false }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const companyQuery = useGetCurrentCompanyQuery();
  const readOnly = useReadOnly();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const formerAssignmentsOpen = useSelector(
    formOpenSelector("formerAssignments")
  );
  const replaceMusicianOpen = useSelector(formOpenSelector("replaceMusician"));
  const addProjectToSeasonOpen = useSelector(
    formOpenSelector("addProjectToSeason")
  );
  const internalInit = useSelector(internalInitSelector);
  const companyOpen = useSelector(formOpenSelector("company"));
  const defaultStringOpen = useSelector(formOpenSelector("defaultStrings"));
  const defaultDressCodesOpen = useSelector(
    formOpenSelector("defaultDressCodes")
  );
  const defaultProjectNotesOpen = useSelector(
    formOpenSelector("defaultProjectNotes")
  );
  const formPayrollOpen = useSelector(formOpenSelector("payroll"));
  const rosterOpen = useSelector(formOpenSelector("roster"));
  const searchMusicianOpen = useSelector(formOpenSelector("searchMusician"));
  const formVenueOpen = useSelector(formOpenSelector("venue"));
  const formCustomerOpen = useSelector(formOpenSelector("customer"));
  const musicianTalkbackOpen = useSelector(
    formOpenSelector("musicianTalkback")
  );
  const formMusicianOpen = useSelector(formOpenSelector("musician"));
  const importRuleOpen = useSelector(formOpenSelector("importRule"));
  const projectImportOpen = useSelector(formOpenSelector("projectImport"));
  const showMusiciansForChairs = useSelector(
    formOpenSelector("showMusiciansForChairs")
  );
  const revisionCallInfoOpen = useSelector(
    formOpenSelector("revisionCallInfo")
  );
  const formProjectOpen = useSelector(formOpenSelector("project"));
  const adminReleaseOpen = useSelector(formOpenSelector("releaseAdmin"));
  const formAutofillOpen = useSelector(formOpenSelector("autofill"));
  const formTemplateOpen = useSelector(formOpenSelector("template"));
  const formTagOpen = useSelector(formOpenSelector("tag"));
  const formProjectNoteOpen = useSelector(formOpenSelector("projectNote"));
  const formProjectContactsOpen = useSelector(
    formOpenSelector("projectContacts")
  );
  const formProjectContactOpen = useSelector(
    formOpenSelector("projectContact")
  );
  const projectPreFlightOpen = useSelector(
    formOpenSelector("projectPreFlight")
  );
  const formProjectPiece2Open = useSelector(formOpenSelector("projectPiece2"));
  const formProjectPieceOpen = useSelector(formOpenSelector("projectPiece"));
  const formProjectPieceReorderOpen = useSelector(
    formOpenSelector("projectPieceReorder")
  );
  const formProjectPieceAdderOpen = useSelector(
    formOpenSelector("projectPieceAdded")
  );
  const formGroupOpen = useSelector(formOpenSelector("group"));
  const formProjectProgramOpen = useSelector(
    formOpenSelector("projectProgram")
  );
  const organizationUserOpen = useSelector(
    formOpenSelector("organizationUser")
  );
  const formRetrievePieceInstrumentationOpen = useSelector(
    formOpenSelector("retrieveInstrumentationForPiece")
  );
  const formPieceOpen = useSelector(formOpenSelector("piece"));
  const formMusicianNoteOpen = useSelector(formOpenSelector("musicianNote"));
  const formWorkSessionOpen = useSelector(formOpenSelector("workSession"));
  const notifierOpen = useSelector(formOpenSelector("notifier"));
  const notifier2Open = useSelector(formOpenSelector("notifier2"));
  const notifier3Open = useSelector(formOpenSelector("notifier3"));
  const subscribeOpen = useSelector(formOpenSelector("subscribe"));
  const projectPieceShorthandOpen = useSelector(
    formOpenSelector("projectPieceShorthand")
  );
  const workSessionMemosOpen = useSelector(
    formOpenSelector("workSessionMemos")
  );
  const projectDressCodeOpen = useSelector(
    formOpenSelector("projectDressCode")
  );
  const projectLinksOpen = useSelector(formOpenSelector("projectLinks"));
  const projectRostersOpen = useSelector(formOpenSelector("projectRosters"));
  const notifierProjectNotesOpen = useSelector(
    formOpenSelector("notifierProjectNotes")
  );
  const projectWageInfoOpen = useSelector(formOpenSelector("projectWageInfo"));
  const workSessionMusicians = useSelector(
    formOpenSelector("workSessionMusicians")
  );
  const overlayOpen = useSelector(formOpenSelector("overlay"));
  const uploadMusicianTemplateOpen = useSelector(
    formOpenSelector("uploadMusicianTemplate")
  );
  const stripeBalanceOpen = useSelector(formOpenSelector("stripeBalance"));
  const projectActionRequiredOpen = useSelector(
    formOpenSelector("projectActionRequired")
  );
  const pdReorderGroupsOpen = useSelector(formOpenSelector("pdReorderGroups"));
  const formProjectNotesFromProjectOpen = useSelector(
    formOpenSelector("projectNotesFromProject")
  );
  const formLinkOpen = useSelector(formOpenSelector("link"));
  const formEditWorkSessionOpen = useSelector(
    formOpenSelector("workSessionEdit")
  );
  const positionLookupOpen = useSelector(formOpenSelector("positionLookup"));
  const assignmentLookupOpen = useSelector(
    formOpenSelector("assignmentLookup")
  );
  const releaseRequestOpen = useSelector(formOpenSelector("releaseRequests"));
  const reorderInSectionOpen = useSelector(
    formOpenSelector("reorderInSection")
  );
  const settings = useCompanySettings();
  const scalesOpen = useSelector(formOpenSelector("scales"));

  const pdProjectOpen = useSelector(formOpenSelector("pdProject"));

  const mercuryHistory = useSelector(formOpenSelector("mercuryHistory"));

  const me = useSelector(meSelector);
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;

  const formRescheduleWorkSessionOpen =
    useSelector(rescheduleEventSelector) != undefined;

  useEffect(() => {
    if (companyQuery?.error?.status === 404)
      dispatch(setFormOpen({ isOpen: true, formID: "company" }));
  }, [companyQuery]);

  useEffect(() => {
    if (settings.stripeBalance?.show) {
      dispatch(setFormOpen({ isOpen: true, formID: "stripeBalance" }));
    }
  }, [settings]);

  return (
    <>
      {!hidden ? (
        <Chip
          data-tut="reactour__create"
          size="sm"
          sx={{ background: "#ffc107" }}
          color="warning"
          endDecorator={<i class="far fa-plus-circle"></i>}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          disabled={readOnly}
        >
          {!sm ? "Create" : ""}
        </Chip>
      ) : (
        []
      )}
      <Popover
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Grid container style={{ padding: 13, width: 340 }}>
          <Grid item xs={12}>
            <Typography variant="h6">Create</Typography>
          </Grid>
          <Grid item xs={12}>
            <ListButton
              title="Project"
              context={window.location.pathname === `/projects`}
              icon={
                <i style={{ color: "#1D1F23" }} class="fad fa-folder-open" />
              }
              subtitle="A group of work sessions."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "project" }));
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 12 }}>
            <ListButton
              title="Work Sessions"
              context={
                window.location.pathname === `/project` ||
                window.location.pathname === `/workSessions`
              }
              icon={
                <i
                  style={{ color: "#1D1F23" }}
                  class="fa-duotone fa-calendar"
                />
              }
              subtitle="A date when your musicians play."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "workSession" }));
              }}
            />
          </Grid>
          {/* <Grid item xs={12} style={{ marginTop: 12 }}>
            <ListButton
              title="Piece"
              context={window.location.pathname === `/pieces`}
              icon={
                <i
                  style={{ color: "#1D1F23" }}
                  class="fa-duotone fa-music-note"
                />
              }
              subtitle="A musical work that has been created."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "piece" }));
              }}
            />
          </Grid> */}
          <Grid item xs={12}>
            <ListButton
              title="Customer"
              context={window.location.pathname === `/customers`}
              icon={<i style={{ color: "#1D1F23" }} class="fad fa-building" />}
              subtitle="Input contact info to be ready to work on a new project."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "customer" }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton
              context={window.location.pathname === `/venues`}
              title="Venue"
              icon={
                <i style={{ color: "#1D1F23" }} class="fad fa-location-dot" />
              }
              subtitle="Setup a new place to work with your musicians."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "venue" }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton
              context={window.location.pathname === `/payrolls`}
              title="Payroll"
              icon={
                <i style={{ color: "#1D1F23" }} class="fad fa-university" />
              }
              subtitle="Link your projects with a new payroll company."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "payroll" }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton
              context={window.location.pathname === `/musicians`}
              title="Musician"
              icon={
                <i style={{ color: "#1D1F23" }} class="fad fa-user-music" />
              }
              subtitle="Start working with new musicians. Invite them on your work sessions."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "musician" }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton
              title="Group"
              icon={
                <i
                  style={{ color: "#1D1F23" }}
                  class="fa-duotone fa-user-group"
                />
              }
              subtitle="A group is a set of musicians."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "group" }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton
              title="Template"
              context={window.location.pathname === `/templates`}
              icon={
                <i
                  style={{ color: "#1D1F23" }}
                  class="fa-duotone fa-square-dashed"
                />
              }
              subtitle="A template is a call order you can use to autofill new projects."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "template" }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton
              title="Season"
              icon={
                <i style={{ color: "#1D1F23" }} class="fa-duotone fa-ticket" />
              }
              subtitle="A season is a group of projects."
              onClick={() => {
                setAnchorEl();
                dispatch(setFormOpen({ isOpen: true, formID: "tag" }));
              }}
            />
          </Grid>
        </Grid>
      </Popover>
      {formPayrollOpen && <FormPayroll />}
      {formVenueOpen && <FormVenue />}
      {formCustomerOpen && <FormCustomer />}
      {formMusicianOpen && <FormMusician />}
      {formProjectOpen && <FormProject />}
      {formTemplateOpen && <FormProject template />}
      {formWorkSessionOpen && (
        <ProjectNeeded>
          <FormWorkSession />
        </ProjectNeeded>
      )}
      {formTagOpen && <FormTag />}
      {formGroupOpen && <FormGroup />}
      {formPieceOpen && <FormPiece />}
      {formLinkOpen && <FormLink />}
      {formProjectNoteOpen && <FormProjectNote />}
      {formProjectNotesFromProjectOpen && <ProjectNotesFromProject />}
      {formMusicianNoteOpen && <FormMusicianNote />}
      {formRescheduleWorkSessionOpen && <RescheduleWorkSession />}
      {formEditWorkSessionOpen && <EditWorkSession />}
      {projectActionRequiredOpen && <ProjectActionRequired />}
      {notifierOpen && <Notifier />}
      {notifier2Open && <Notifier2 />}
      {notifier3Open && <Notifier3 />}
      {subscribeOpen && <Subscribe />}
      {workSessionMusicians && <WorkSessionMusicians />}
      {uploadMusicianTemplateOpen && <FormUploadMusicianTemplate />}
      {organizationUserOpen && <FormOrganizationUser />}
      {formProjectProgramOpen && <FormProjectProgram />}
      {formProjectPieceAdderOpen && <ProjectPieceAdder />}
      {formRetrievePieceInstrumentationOpen && <RetrievePieceInstrumentation />}
      {formProjectPieceOpen && <FormProjectPiece />}
      {formAutofillOpen && <FormAutofill />}
      {formProjectPieceReorderOpen && <FormProjectPieceReorder />}
      {overlayOpen && (
        <Backdrop open sx={{ zIndex: 99999999 }}>
          <CircularProgress />
        </Backdrop>
      )}
      {positionLookupOpen && <PositionLookup />}
      {searchMusicianOpen && <SearchMusician />}
      {assignmentLookupOpen && internalInit && <AssignmentLookup />}
      {reorderInSectionOpen && <ReorderMusicianInSection />}
      {mercuryHistory && <MercuryHistory />}
      {projectPieceShorthandOpen && <ProjectPieceShorthand />}
      {workSessionMemosOpen && <WorkSessionMemos />}
      {projectWageInfoOpen && <ProjectWageInfo />}
      {projectDressCodeOpen && <ProjectDressCode />}
      {projectLinksOpen && <ProjectLinks />}
      {projectRostersOpen && <ProjectRosters />}
      {notifierProjectNotesOpen && <NotifierProjectNotes />}
      {formProjectContactsOpen && <ProjectContacts />}
      {formProjectContactOpen && <ProjectContactForm />}
      {projectPreFlightOpen && <ProjectPreFlight />}
      {replaceMusicianOpen && <ReplaceMusicians />}
      {rosterOpen && <ProjectRoster />}
      {pdProjectOpen && <PayDayProject />}
      {scalesOpen && <ScalesForm />}
      {pdReorderGroupsOpen && <PdReorderGroups />}
      {showMusiciansForChairs && <MusiciansForChairs />}
      {releaseRequestOpen && <ReleaseRequest />}
      {adminReleaseOpen && <ReleaseAdmin />}
      {revisionCallInfoOpen && <RevisionCallInfo />}
      {projectImportOpen && <ProjectImport />}
      {importRuleOpen && <ImportRules />}
      {formProjectPiece2Open && <FormProjectPiece2 />}
      {musicianTalkbackOpen && <MusicianTalkback />}
      {formerAssignmentsOpen && <FormerAssignments />}
      {companyOpen && <Formcompany />}
      {addProjectToSeasonOpen && <AddProjectToSeason />}
      {settings.restricted && !superadmin && <RestrictedAccount />}
      {selectedProjectID && <Utils />}
      {defaultStringOpen && <DefaultString />}
      {defaultDressCodesOpen && <DefaultDressCodes />}
      {defaultProjectNotesOpen && <DefaultProjectNotes />}
      {stripeBalanceOpen && <StripeBalance />}
    </>
  );
}
