import { MercuryJob } from "entities/mercuryJob";
import HProject_Entity from "./hProject";
import { Dictionary } from "@reduxjs/toolkit";
import { Stage } from "entities/stage";
import moment from "moment";
export default class HProject extends HProject_Entity {
  jobs: MercuryJob[];

  constructor(json: HProject_Entity) {
    super(json);
    this.jobs = MercuryJob.fromList(json.jobs);
  }

  static fromList(hProjectsJSON: unknown): Array<HProject> {
    const hProjects: HProject[] = [];
    if (hProjectsJSON)
      Array.isArray(hProjectsJSON) &&
        hProjectsJSON.forEach((hProjectJSON) => {
          hProjects.push(new HProject(hProjectJSON));
        });
    return hProjects;
  }

  countPerStatus() {
    return this.jobs.reduce<Dictionary<number>>((a, v) => {
      if (!v.currentStageID) {
        if (a[-1]) {
          a[-1]++;
        } else {
          a[-1] = 1;
        }
      } else if (a[v.currentStageID]) {
        a[v.currentStageID]++;
      } else {
        a[v.currentStageID] = 1;
      }
      return a;
    }, {});
  }

  countPerStatusLabels(stagesMap: Dictionary<Stage>) {
    const countPerStatus = this.countPerStatus();
    const ret: {
      label: string;
      icon: string;
      color: string;
      count: number;
    }[] = [];
    for (const key in countPerStatus) {
      if (Object.hasOwnProperty.call(countPerStatus, key)) {
        ret.push({
          label: stagesMap[key]?.wording() ?? "Ready",
          icon:
            stagesMap[key]?.icon ??
            "https://storage.googleapis.com/rhapsody/logos/ready.png",
          color: stagesMap[key]?.color ?? "#9e9e9e",
          count: countPerStatus[key],
        });
      }
    }

    return ret;
  }

  pieChartData(stagesMap: Dictionary<Stage>): {
    data: { label: string; value: number }[];
    palette: string[];
  } {
    const data: { label: string; value: number }[] = [];
    const palette: string[] = [];
    const countPerStatus = this.countPerStatus();

    for (const key in countPerStatus) {
      if (Object.hasOwnProperty.call(countPerStatus, key)) {
        const stage = stagesMap[key];
        data.push({
          label: stage?.wording() ?? "Ready",
          value: countPerStatus[key],
        });
        palette.push(stage?.color ?? "#9e9e9e");
      }
    }
    return { data, palette };
  }

  musicianTalkback() {
    const ret: MusicianTalkback[] = [];

    this.jobs?.forEach((j) => {
      j.stages?.forEach((s) => {
        s.inspectors?.forEach((i) => {
          if (i.title === "Message") {
            ret.push({
              musicianID: j.refID,
              stage: s,
              success: i.success,
              message: i.message,
              createdAt: i.createdAt,
              createdBy: i.createdBy,
              id: i.id,
            });
          }
        });
      });
    });

    ret.sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
    );
    return ret;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}

type MusicianTalkback = {
  musicianID: number;
  success: boolean;
  stage: Stage;
  message: string;
  createdAt: string;
  createdBy: string;
  id: number;
};
