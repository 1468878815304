import Trail_Entity from "./trail";
export default class Trail extends Trail_Entity {
  static fromList(trailsJSON: unknown): Array<Trail> {
    const trails: Trail[] = [];
    if (trailsJSON)
      Array.isArray(trailsJSON) &&
        trailsJSON.forEach((trailJSON) => {
          trails.push(new Trail(trailJSON));
        });
    return trails;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
