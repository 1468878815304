/**
 * ProjectAlternate_Entity type guard.
 *
 * @param {any} projectAlternateJson ProjectAlternate object from API
 * @returns {boolean} Return true if type is ProjectAlternate_Entity
 */

import { Musician_Entity } from "entities/musician";

export default class ProjectAlternate_Entity {
  constructor(projectAlternateJson: ProjectAlternate_Entity) {
    try {
      this.id = projectAlternateJson.id;
      this.musicianID = projectAlternateJson.musicianID;
      this.sectionRoleID = projectAlternateJson.sectionRoleID;
      this.sectionID = projectAlternateJson.sectionID;
      this.sectionOrder = projectAlternateJson.sectionOrder;
      this.seatingRank = projectAlternateJson.seatingRank;
      this.prime = projectAlternateJson.prime;
      this.notes = projectAlternateJson.notes;
      this.musician = projectAlternateJson.musician;
      this.instrumentIDs = projectAlternateJson.instrumentIDs;
      this.emptyChairIndex = projectAlternateJson.emptyChairIndex;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  musicianID: number;
  projectID: number;
  sectionRoleID: number;
  sectionID: number;
  sectionOrder: number;
  seatingRank: number;
  prime: boolean;
  notes: string;
  musician?: Musician_Entity;
  instrumentIDs?: string;
  emptyChairIndex?: number;
}
