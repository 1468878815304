import {
  Checkbox,
  Chip,
  Divider,
  List,
  ListItemButton,
  Tooltip,
} from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { useState } from "react";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";
import { useNoteTypes } from "redux/noteType/noteTypeHooks";

/**
 *
 * @returns {ReactElement} AddMusicianFieldToPayroll page
 */
export function AddMusicianFieldToPayroll() {
  const settings = useCompanySettings();
  const { company } = useCurrentCompany();
  const [updateCompany] = useUpdateCurrentCompanyMutation();
  const { noteTypes } = useNoteTypes();
  const [open, setOpen] = useState(false);

  const {
    payrollMusicianCustomFieldIDs: _payrollMusicianCustomFieldIDs,
    payrollMusicianFields: _payrollMusicianFields,
    payrollMusicianAggregationFieldIDs: _payrollMusicianAggregationFieldIDs,
  } = settings;
  const payrollMusicianCustomFieldIDs = _payrollMusicianCustomFieldIDs ?? [];
  const payrollMusicianFields = _payrollMusicianFields ?? [];
  const payrollMusicianAggregationFieldIDs =
    _payrollMusicianAggregationFieldIDs ?? [];

  return (
    <Tooltip
      size="sm"
      variant="outlined"
      open={open}
      title={
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <List sx={{ maxHeight: 500, overflow: "auto" }}>
            {musicianFields.map((f) => {
              const checked = payrollMusicianFields?.includes(f.key) ?? false;
              return (
                <ListItemButton
                  onClick={() => {
                    updateCompany({
                      ...company,
                      settings: JSON.stringify({
                        ...settings,
                        payrollMusicianFields: checked
                          ? payrollMusicianFields.filter((a) => a !== f.key)
                          : [...payrollMusicianFields, f.key],
                      }),
                    });
                  }}
                  sx={{ gap: 1 }}
                  key={f.key}
                >
                  <Checkbox checked={checked} />
                  {f.label}
                </ListItemButton>
              );
            })}
            <Divider>Custom Fields</Divider>
            {noteTypes.map((nt) => {
              const checked =
                payrollMusicianCustomFieldIDs?.includes(nt.id) ?? false;
              return (
                <ListItemButton
                  onClick={() => {
                    updateCompany({
                      ...company,
                      settings: JSON.stringify({
                        ...settings,
                        payrollMusicianCustomFieldIDs: checked
                          ? payrollMusicianCustomFieldIDs.filter(
                              (a) => a !== nt.id
                            )
                          : [...payrollMusicianCustomFieldIDs, nt.id],
                      }),
                    });
                  }}
                  key={nt.id}
                  sx={{ gap: 1 }}
                >
                  <Checkbox checked={checked} />
                  {nt.name}
                </ListItemButton>
              );
            })}
            <Divider>Aggregation</Divider>
            {aggregations.map((nt) => {
              const checked =
                payrollMusicianAggregationFieldIDs?.includes(nt.id) ?? false;
              return (
                <ListItemButton
                  onClick={() => {
                    updateCompany({
                      ...company,
                      settings: JSON.stringify({
                        ...settings,
                        payrollMusicianAggregationFieldIDs: checked
                          ? payrollMusicianAggregationFieldIDs.filter(
                              (a) => a !== nt.id
                            )
                          : [...payrollMusicianAggregationFieldIDs, nt.id],
                      }),
                    });
                  }}
                  key={nt.id}
                  sx={{ gap: 1 }}
                >
                  <Checkbox checked={checked} />
                  {nt.name}
                </ListItemButton>
              );
            })}
          </List>
        </ClickAwayListener>
      }
    >
      <Chip onClick={() => setOpen(true)} size="sm" variant="outlined">
        + Musician Field
      </Chip>
    </Tooltip>
  );
}

export const aggregations = [
  {
    id: 1,
    name: "Full Address",
    pattern: "{address} {city} {state} {zipcode}",
  },
];

export const musicianFields = [
  {
    key: "firstName",
    label: "First Name",
  },
  {
    key: "middleName",
    label: "Middle Name",
  },
  {
    key: "lastName",
    label: "Last Name",
  },
  {
    key: "nickName",
    label: "Nickname",
  },
  {
    key: "comments",
    label: "Internal Memo",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "phone",
    label: "Phone",
  },
  {
    key: "address",
    label: "Street",
  },
  {
    key: "zipcode",
    label: "Zip code",
  },
  {
    key: "city",
    label: "City",
  },
  {
    key: "state",
    label: "State",
  },
  {
    key: "instrumentNames",
    label: "Instruments",
  },
  {
    key: "sub",
    label: "Sub",
  },
  {
    key: "star",
    label: "Favorite",
  },
  {
    key: "contactBySMS",
    label: "SMS Enabled",
  },
];
