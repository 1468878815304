import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LibrarianPiece_Entity } from "entities/librarianPiece";
import { librarianApi } from "redux/api/librarian";

const librarianPiecesAdapter = createEntityAdapter<LibrarianPiece_Entity>();
const initialState = librarianPiecesAdapter.getInitialState();

export const librarianPieceEndpoints = librarianApi.injectEndpoints({
  endpoints: (build) => ({
    getLibrarianPieces: build.query<
      EntityState<LibrarianPiece_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `pieces`,
          params: args,
        };
      },
      transformResponse: (responseData: LibrarianPiece_Entity[]) => {
        return librarianPiecesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["pieces"],
    }),
    getLibrarianPiece: build.query<LibrarianPiece_Entity, number>({
      query: (id) => `pieces/${id}`,
      providesTags: (result, error, id) => [{ type: "pieces", id }],
    }),
    createLibrarianPiece: build.mutation<
      LibrarianPiece_Entity,
      Partial<LibrarianPiece_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `pieces`,
      }),
      invalidatesTags: ["pieces"],
    }),
    updateLibrarianPiece: build.mutation<
      void,
      { id: number; body: Partial<LibrarianPiece_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `pieces/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "pieces", id },
        "pieces",
      ],
    }),
    deleteLibrarianPiece: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `pieces/${id}`,
      }),
      invalidatesTags: ["pieces"],
    }),
  }),
});

export const {
  useGetLibrarianPieceQuery,
  useGetLibrarianPiecesQuery,
  useCreateLibrarianPieceMutation,
  useDeleteLibrarianPieceMutation,
  useUpdateLibrarianPieceMutation,
} = librarianPieceEndpoints;

export default librarianPieceEndpoints;
