import { REACT_APP_API, REACT_APP_ID } from "config";
import { AppConfig } from "environement";

const token = AppConfig.api.authServerToken;

export default class Fetcher {
  get(request) {
    const headers = {
      "content-type": "application/json",
      "accept-encoding": "gzip, deflate",
      "app-id": REACT_APP_ID,
    };
    if (token) headers["auth-server-token"] = token;
    return fetch(`${REACT_APP_API}${request.url}`, {
      credentials: "include",
      headers,
      method: "GET",
    });
  }

  post(request) {
    const headers = {
      "content-type": "application/json",
      "app-id": REACT_APP_ID,
    };
    if (token) headers["auth-server-token"] = token;

    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: "include",
      headers,
      method: "POST",
    });
  }

  data(request) {
    const headers = {
      "content-type": "application/json",
      "app-id": REACT_APP_ID,
    };
    if (token) headers["auth-server-token"] = token;

    return fetch(`${REACT_APP_API}${request.url}`, {
      body: request.body,
      credentials: "include",
      headers,
      method: "POST",
    });
  }

  delete(request) {
    const headers = {
      "content-type": "application/json",
      "app-id": REACT_APP_ID,
    };
    if (token) headers["auth-server-token"] = token;

    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: "include",
      headers,
      method: "DELETE",
    });
  }

  put(request) {
    const headers = {
      "content-type": "application/json",
      "app-id": REACT_APP_ID,
    };
    if (token) headers["auth-server-token"] = token;

    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: "include",
      headers,
      method: "PUT",
    });
  }
}
