import { Sheet, Tooltip, Typography } from "@mui/joy";
import { alpha } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { useMissionControlModeTint } from "hooks/Layout/v2";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setActionInitiator } from "reducers/rhapsody";
import {
  layoutUtilsSelector,
  selectProjectPieceID,
  selectWorkSessionID,
  selectionSelector,
  setSelectedProjectPieceIDs,
  setSelectedWorkSessionIDs,
} from "reducers/v2/missionControl";

export function EditButton({ onClick }: { onClick: () => void }) {
  const missionControlModeTint = "#666666";

  return (
    <Tooltip size="sm" title="Edit" enterDelay={1000} enterNextDelay={1000}>
      <Sheet
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }}
        // variant="outlined"
        sx={{
          borderColor: missionControlModeTint,
          color: missionControlModeTint,
          width: 20,
          height: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 4,
          cursor: "pointer",
          "&:hover": {
            background: alpha("#3774CB", 0.1),
          },
          "&:active": {
            background: alpha("#3774CB", 0.2),
          },
        }}
      >
        <Typography level="body3" sx={{ color: "inherit" }}>
          <i className="fa-solid fa-pen"></i>
        </Typography>
      </Sheet>
    </Tooltip>
  );
}

export function SoloButton({ onClick }: { onClick: () => void }) {
  const missionControlModeTint = useMissionControlModeTint();

  return (
    <Tooltip size="sm" title="Solo" enterDelay={1000} enterNextDelay={1000}>
      <Sheet
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }}
        variant="outlined"
        sx={{
          borderColor: missionControlModeTint,
          color: missionControlModeTint,
          width: 20,
          height: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 4,
          cursor: "pointer",
          "&:hover": {
            background: alpha("#3774CB", 0.1),
          },
          "&:active": {
            background: alpha("#3774CB", 0.2),
          },
        }}
      >
        <Typography level="body3" sx={{ color: "inherit" }}>
          S
        </Typography>
      </Sheet>
    </Tooltip>
  );
}

// has exactly one item to true
export function oneTrue(s: Dictionary<boolean>) {
  let count = 0;

  for (const key in s) {
    if (Object.prototype.hasOwnProperty.call(s, key)) {
      if (s[key]) count++;
    }
  }

  return count === 1;
}

export function useSoloWorkSession() {
  const { projectPieces } = useSelector(layoutUtilsSelector);

  const dispatch = useDispatch();
  const handleSoloWorkSession = (id: number) => {
    dispatch(setActionInitiator("workSession"));
    // dispatch(layoutUnselectAll());
    dispatch(
      setSelectedWorkSessionIDs({
        [id]: true,
      })
    );

    dispatch(
      setSelectedProjectPieceIDs(
        projectPieces.reduce((a, v) => {
          a[v.id] = true;
          return a;
        }, {})
      )
    );
  };

  return handleSoloWorkSession;
}

export function useSoloProjectPiece() {
  const { workSessions } = useSelector(layoutUtilsSelector);

  const dispatch = useDispatch();
  const handleSoloProjectPiece = (id: number) => {
    dispatch(setActionInitiator("projectPiece"));
    dispatch(
      setSelectedProjectPieceIDs({
        [id]: true,
      })
    );

    dispatch(
      setSelectedWorkSessionIDs(
        workSessions?.reduce((a, v) => {
          a[v.id] = true;
          return a;
        }, {}) ?? {}
      )
    );
  };

  return handleSoloProjectPiece;
}

export function useMissionControlSelectAll() {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const workSessionID = params.get("workSessionID");
  const layoutUtils = useSelector(layoutUtilsSelector);
  const utils = layoutUtils;
  const projectPieces = utils?.projectPieces;
  const workSessions = utils?.workSessions;

  const { selectedWorkSessionIDs, selectedProjectPieceIDs } =
    useSelector(selectionSelector);

  // Selects all work sessions by default
  useEffect(() => {
    if (
      !workSessionID &&
      workSessions?.length &&
      Object.keys(selectedWorkSessionIDs).length === 0
    ) {
      selectAllWorkSessions();
    }
  }, [workSessions]);

  // Selects all project pieces by default
  useEffect(() => {
    if (
      !workSessionID &&
      projectPieces?.length &&
      Object.keys(selectedProjectPieceIDs).length === 0
    ) {
      selectAllProjectPieces();
    }
  }, [projectPieces]);

  function selectWorkSession(workSessionID) {
    dispatch(selectWorkSessionID(workSessionID));
  }

  function selectAllWorkSessions() {
    // dispatch(layoutUnselectAll());
    dispatch(
      setSelectedWorkSessionIDs(
        workSessions.reduce((a, v) => {
          a[v.id] = true;
          return a;
        }, {})
      )
    );
  }

  function selectAllProjectPieces() {
    // dispatch(layoutUnselectAll());
    dispatch(
      setSelectedProjectPieceIDs(
        projectPieces.reduce((a, v) => {
          a[v.id] = true;
          return a;
        }, {})
      )
    );
  }

  function selectProjectPiece(projectPieceID) {
    dispatch(selectProjectPieceID(projectPieceID));
  }

  function selectAll() {
    selectAllWorkSessions();
    selectAllProjectPieces();
  }

  return {
    selectAllWorkSessions,
    selectAllProjectPieces,
    selectAll,
    selectProjectPiece,
    selectWorkSession,
  };
}
