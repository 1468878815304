import { Group } from "entities/group";
import { Instrument_Entity } from "entities/instrument";
import { Stage_Entity } from "entities/stage";

/**
 * Musician_Entity type guard.
 *
 * @param {any} musicianJson Musician object from API
 * @returns {boolean} Return true if type is Musician_Entity
 */

export default class Musician_Entity {
  constructor(musicianJson: Musician_Entity) {
    try {
      this.id = musicianJson.id;
      this.sub = musicianJson.sub;
      this.userUUID = musicianJson.userUUID;
      this.email = musicianJson.email;
      this.avatar = musicianJson.avatar;
      this.firstName = musicianJson.firstName;
      this.middleName = musicianJson.middleName;
      this.lastName = musicianJson.lastName;
      this.nickName = musicianJson.nickName;
      this.companyName = musicianJson.companyName;
      this.address = musicianJson.address;
      this.city = musicianJson.city;
      this.state = musicianJson.state;
      this.zipcode = musicianJson.zipcode;
      this.phone = musicianJson.phone;
      this.ssn = musicianJson.ssn;
      this.ein = musicianJson.ein;
      this.localNumber = musicianJson.localNumber;
      this.cardNumber = musicianJson.cardNumber;
      this.organizationID = musicianJson.organizationID;
      this.active = musicianJson.active;
      this.trusted = musicianJson.trusted;
      this.verified = musicianJson.verified;
      this.contactBySMS = musicianJson.contactBySMS;
      this.comments = musicianJson.comments;
      this.birthdate = musicianJson.birthdate;
      this.star = musicianJson.star;
      this.instrumentNames = musicianJson.instrumentNames;
      this.instruments = musicianJson.instruments;
      // this.lastStatus = musicianJson.lastStatus;
      this.mercuryStage = musicianJson.mercuryStage;
      // this.local420Musician = musicianJson.local420Musician;
      this.inSync = musicianJson.inSync;
      this.groupRelationID = musicianJson.groupRelationID;
      this.groups = musicianJson.groups;
      this.groupIDs = musicianJson.groupIDs;
      this.sectionRoleID = musicianJson.sectionRoleID;
      this.settled = musicianJson.settled;
      this.onDeck = musicianJson.onDeck;
      this.prime = musicianJson.prime;
      this.order = musicianJson.order;
      this.projectNotes = musicianJson.projectNotes;
      this.position = musicianJson.position;
      this.subRuleID = musicianJson.subRuleID;
    } catch (e) {
      console.log(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }
  id: number;
  sub: boolean;
  userUUID?: string;
  email?: string;
  avatar?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nickName?: string;
  companyName?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  phone?: string;
  ssn?: string;
  ein?: string;
  localNumber?: string;
  cardNumber?: string;
  organizationID?: number;
  active?: boolean;
  trusted?: boolean;
  verified?: boolean;
  contactBySMS?: boolean;
  comments?: string;
  birthdate?: string;
  star?: boolean;
  instrumentNames: string;
  instruments: Instrument_Entity[];
  // lastStatus?: <NotFound>;
  mercuryStage?: Stage_Entity;
  // local420Musician?: <NotFound>;
  inSync?: boolean;
  groupRelationID?: number;
  groups: Array<Group>;
  groupIDs: Array<number>;
  sectionRoleID?: number;
  settled: boolean;
  onDeck: boolean;
  prime: boolean;
  order: number;
  projectNotes: string;
  position?: number;
  subRuleID?: number;
}
