import Piece_Entity from "./piece";
export default class Piece extends Piece_Entity {
  static fromList(piecesJSON: unknown): Array<Piece> {
    const pieces: Piece[] = [];
    if (piecesJSON)
      Array.isArray(piecesJSON) &&
        piecesJSON.forEach((pieceJSON) => {
          pieces.push(new Piece(pieceJSON));
        });
    return pieces;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
