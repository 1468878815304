import { alpha } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { RhapsodyChair } from "entities/rhapsodyChair";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import InternalPosition_Entity from "./InternalPosition";
import { Selection_Entity } from "entities/selection";
export default class InternalPosition extends InternalPosition_Entity {
  workSessionID?: number;
  musiciansIDs: number[];
  visibleAssignmentIDs: number[];
  visibleMusicianIDs: number[];
  visibleChairIDs: number[];
  visibleSectionRoleIDs: number[];
  visibleInstrumentIDs: number[];
  workSessionChairIDs: number[];
  projectPieceIDsForWorkSessionChairs: number[];
  projectPieceIDs: number[];
  visibleProjectPieceIDs: number[];
  pieceIDs: number[];
  enabled: boolean;
  atLeast1VisibleMusician: boolean;
  hasWorkSessionChairs: boolean;
  emptyVisibleChairsCount: number;
  firstChairID?: number;
  firstVisibleChairID?: number;
  visibleStageIDs: number[];
  stagesColors: string[];
  misplacedMusicianIDs: number[];
  firstEmptyChairID?: number;
  visibleMemos: string[];
  constructor(
    json: InternalPosition_Entity,
    utils: LayoutUtils,
    selection: Selection_Entity
  ) {
    super(json);
    const chairs = utils.chairs.filter((c) => this.chairIDs?.includes(c.id));
    const workSessionIDs = Object.keys(selection.selectedWorkSessionIDs).map(
      (e) => {
        if (selection.selectedWorkSessionIDs[e]) return parseInt(e);
      }
    );
    this.pieceIDs = chairs.reduce((a, v) => {
      const pieceID = utils.projectPiecesMap[v.projectPieceID];
      if (!a.includes(pieceID)) a.push(pieceID);
      return a;
    }, []);
    const projectPieceIDs = Object.keys(selection.selectedProjectPieceIDs).map(
      (e) => {
        if (selection.selectedProjectPieceIDs[e]) return parseInt(e);
      }
    );
    const emptyChairs = chairs.filter((c) => !c.hidden && !c.musicianID);
    this.firstEmptyChairID = emptyChairs?.length
      ? emptyChairs[0].id
      : undefined;
    this.chairIDs = chairs.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []);
    this.musiciansIDs = this.assignmentIDs?.reduce((a, v) => {
      const assignment = utils.assignmentsMap[v];
      if (!a.includes(assignment.musicianID)) a.push(assignment.musicianID);
      return a;
    }, []);
    this.misplacedMusicianIDs = chairs.reduce((a, v) => {
      const musician = utils.musiciansMap[v.musicianID];
      const instrumentIDs = JSON.parse(
        v.instrumentIDs ? v.instrumentIDs : "[]"
      );
      instrumentIDs.forEach((instrumentID) => {
        if (
          musician?.instruments?.findIndex((i) => i.id === instrumentID) === -1
        ) {
          a.push(musician.id);
        }
      }, []);
      return a;
    }, []);
    this.workSessionChairIDs = chairs
      .reduce((a, v) => {
        if (v.workSessionID) a.push(v.id);
        return a;
      }, [])
      .sort();
    this.projectPieceIDsForWorkSessionChairs = this.workSessionChairIDs.reduce(
      (a, v) => {
        const _chair = utils.chairsMap[v];
        if (!a.includes(_chair?.projectPieceID)) a.push(_chair?.projectPieceID);
        return a;
      },
      []
    );
    this.hasWorkSessionChairs = this.workSessionChairIDs.length > 0;
    this.chairIDs = chairs
      .reduce((a, v) => {
        a.push(v.id);
        return a;
      }, [])
      .sort();
    this.projectPieceIDs = chairs
      .reduce((a, v) => {
        if (!a.includes(v.projectPieceID)) return a;
      }, [])
      .sort();
    this.visibleChairIDs = chairs.reduce((a, chair) => {
      let visible = false;
      visible = checkWorkSessions(chair, workSessionIDs, this.forWorkSessionID);
      if (Object.keys(utils.workSessionsMap).length === 0) visible = true;
      if (visible) {
        visible = checkProjectPieces(
          chair,
          projectPieceIDs,
          this.forProjectPieceID
        );
      }
      if (visible) {
        if (!chair.hidden) a.push(chair.id);
      }
      return a;
    }, []);
    this.visibleProjectPieceIDs = this.visibleChairIDs
      .reduce((a, v) => {
        const _chair = utils.chairsMap[v];
        if (!a.includes(_chair?.projectPieceID)) a.push(_chair?.projectPieceID);
        return a;
      }, [])
      .sort();
    this.visibleMemos = this.visibleChairIDs.reduce((a, v) => {
      const _chair = utils.chairsMap[v];
      if (_chair?.memo && !a.includes(_chair?.memo)) a.push(_chair?.memo);
      return a;
    }, []);
    this.visibleAssignmentIDs = this.visibleChairIDs.reduce((a, v) => {
      const _chair = utils.chairsMap[v];
      if (_chair?.assignmentID && !a.includes(_chair?.assignmentID))
        a.push(_chair?.assignmentID);
      return a;
    }, []);
    this.visibleStageIDs = this.visibleAssignmentIDs.reduce((a, v) => {
      const assignment = utils.assignmentsMap[v];
      if (assignment?.mercuryStageID && !a.includes(assignment?.mercuryStageID))
        a.push(assignment.mercuryStageID);
      return a;
    }, []);
    this.stagesColors = this.visibleStageIDs.reduce((a, v) => {
      if (utils.stagesMap[v]?.color && !a.includes(utils.stagesMap[v]?.color))
        a.push(utils.stagesMap[v].color);
      return a;
    }, []);
    this.visibleMusicianIDs = this.visibleChairIDs.reduce((a, v) => {
      const _chair = utils.chairsMap[v];
      if (!a.includes(_chair?.musicianID)) a.push(_chair?.musicianID);
      return a;
    }, []);
    this.firstChairID = chairs.length ? chairs[0].id : undefined;
    this.firstVisibleChairID = this.visibleChairIDs.length
      ? this.visibleChairIDs[0]
      : undefined;
    this.visibleSectionRoleIDs = this.visibleChairIDs.reduce((a, v) => {
      const _chair = utils.chairsMap[v];
      if (!a.includes(_chair?.sectionRoleID)) a.push(_chair?.sectionRoleID);
      return a;
    }, []);
    this.visibleInstrumentIDs = this.visibleChairIDs.reduce((a, v) => {
      const _chair = utils.chairsMap[v];
      const instruments: number[] = JSON.parse(
        _chair?.instrumentIDs ? _chair?.instrumentIDs : "[]"
      );
      instruments.forEach((i) => {
        if (!a.includes(i)) a.push(i);
      });
      return a;
    }, []);
    this.atLeast1VisibleMusician = this.visibleMusicianIDs.length >= 1;
    this.emptyVisibleChairsCount = this.visibleChairIDs.filter((c) => {
      const _chair = utils.chairsMap[c];
      return !_chair?.assignmentID || _chair?.assignmentID < 0;
    }).length;
    this.enabled = this.visibleChairIDs.length > 0;
  }

  static fromList(
    InternalPositionsJSON: unknown,
    utils: LayoutUtils,
    selection: Selection_Entity
  ): Array<InternalPosition> {
    const InternalPositions: InternalPosition[] = [];
    if (InternalPositionsJSON)
      Array.isArray(InternalPositionsJSON) &&
        InternalPositionsJSON.forEach((InternalPositionJSON) => {
          InternalPositions.push(
            new InternalPosition(InternalPositionJSON, utils, selection)
          );
        });
    return InternalPositions;
  }

  backgroundImage() {
    const config = {
      1: ["50% 100%"],
      2: ["15% 11%", "82% 79%"],
      3: ["90% 38%", "15% 11%", "38% 89%"],
      4: ["50% 91%", "94% 53%", "47% 5%", "5% 53%"],
    };

    const spread = { 1: "500%", 2: "60%", 3: "50%", 4: "50%" };

    const stagesColors = this.stagesColors;

    const ret = [];
    stagesColors.forEach((c, i) => {
      ret.push(
        `radial-gradient(at ${config[stagesColors.length][i]}, ${alpha(
          c,
          0.2
        )} 0px, transparent ${spread[stagesColors.length]})`
      );
    });
    return ret.join(", ");
  }

  clone(): InternalPosition_Entity {
    const _internalPosition: InternalPosition_Entity = JSON.parse(
      JSON.stringify(this)
    );
    return _internalPosition;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}

function checkProjectPieces(
  chair: RhapsodyChair,
  projectPieceIDs: number[],
  forProjectPieceID?: number
) {
  if (forProjectPieceID) {
    if (forProjectPieceID && chair.projectPieceID === forProjectPieceID) {
      return true;
    } else {
      return false;
    }
  }
  return projectPieceIDs.includes(chair.projectPieceID);
}

function checkWorkSessions(
  chair: RhapsodyChair,
  selectedWorkSessionIDs: number[],
  forWorkSessionID?: number
) {
  if (forWorkSessionID) {
    if (chair.workSessionIDs.includes(forWorkSessionID)) {
      return true;
    } else {
      return false;
    }
  }
  const inCommon = chair.workSessionIDs.filter((value) =>
    selectedWorkSessionIDs.includes(value)
  );
  return inCommon.length > 0;
}

export function arrayToMap<T extends { id: number }>(
  elements: Array<T>
): { [key: number]: T } {
  const ret = {};
  for (const key in elements) {
    if (Object.prototype.hasOwnProperty.call(elements, key)) {
      const element = elements[key];
      ret[element.id] = element;
    }
  }
  return ret;
}
