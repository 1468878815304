import { Dictionary } from "@reduxjs/toolkit";
import { Musician } from "entities/musician";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setFormOpen } from "reducers/rhapsody";
import { useMusicians } from "redux/musician/musicianHooks";
import { Indexes, createWorkbook, downloadExcel } from "../utils";
import { useMusicianNotes } from "redux/musicianNote/musicianNoteHooks";
import { useNoteTypes } from "redux/noteType/noteTypeHooks";
import { NoteType } from "entities/noteType";

export function useDownloadMusiciansDatabaseExcel() {
  const [trigger, setTrigger] = useState(false);
  const { musiciansMap, musicians, isSuccess: s1 } = useMusicians();
  const musicianIDs = Object.keys(musiciansMap ?? {});
  const { noteTypesMap } = useNoteTypes();
  const { musicianNotes, isLoading } = useMusicianNotes();

  const dispatch = useDispatch();

  useEffect(() => {
    if (trigger && s1) {
      setTrigger(false);
      download();
    }
  }, [trigger, s1]);

  function download() {
    const workbook = createWorkbook();
    const fileName = `Rhapsody Musician Export`;

    const worksheet = workbook.addWorksheet(`Musicians`, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    worksheet.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };

    const indexes = new Indexes(worksheet);

    const usedNoteTypes = musicianNotes.reduce<NoteType[]>((a, v) => {
      if (
        noteTypesMap[v.noteTypeID] &&
        !a.includes(noteTypesMap[v.noteTypeID])
      ) {
        a.push(noteTypesMap[v.noteTypeID]);
      }
      return a;
    }, []);

    const columns = [
      "firstName",
      "middleName",
      "lastName",
      "instrumentNames",
      "email",
      "phone",
      "nickName",
      "companyName",
      "address",
      "city",
      "state",
      "zipcode",
      "comments",
      "birthdate",
      "star",
      "sub",
      "ssn",
      "ein",
      "localNumber",
      "cardNumber",
      "active",
      "contactBySMS",
      "groups",
    ];

    const header = {
      firstName: "First Name",
      middleName: "Middle Name",
      lastName: "Last Name",
      instrumentNames: "Instrument(s)",
      email: "Email",
      phone: "Phone",
      nickName: "Nickname",
      companyName: "Company Name",
      address: "Address",
      city: "City",
      state: "State",
      zipcode: "Zipcode",
      comments: "Internal Memo",
      birthdate: "Birthdate",
      star: "Favorite",
      sub: "Sub",
      ssn: "SSN",
      ein: "EIN",
      localNumber: "Union Number",
      cardNumber: "Employee Number",
      active: "Archived",
      contactBySMS: "SMS Enabled",
      groups: "Groups",
    };

    const valueGetters: Dictionary<(m: Musician) => string> = {
      firstName: (m) => m.firstName,
      middleName: (m) => m.middleName,
      lastName: (m) => m.lastName,
      instrumentNames: (m) => m.instrumentNames,
      email: (m) => m.email,
      phone: (m) => m.phone,
      nickName: (m) => m.nickName,
      companyName: (m) => m.companyName,
      address: (m) => m.address,
      city: (m) => m.city,
      state: (m) => m.state,
      zipcode: (m) => m.zipcode,
      comments: (m) => m.comments,
      birthdate: (m) => m.birthdate,
      star: (m) => (m.star ? "Yes" : "No"),
      sub: (m) => (m.star ? "Yes" : "No"),
      ssn: (m) => m.ssn,
      ein: (m) => m.ein,
      localNumber: (m) => m.localNumber,
      cardNumber: (m) => m.cardNumber,
      active: (m) => (!m.active ? "Yes" : "No"),
      contactBySMS: (m) => (m.contactBySMS ? "Yes" : "No"),
      groups: (m) =>
        m.groups
          ?.reduce((a, v) => {
            a.push(v.name);
            return a;
          }, [])
          .join(", "),
    };

    columns.forEach((c) => {
      indexes.width(25);
      indexes.text(header[c]);
      indexes.header();
      indexes.nextColumn();
    });

    usedNoteTypes.forEach((c) => {
      indexes.width(25);
      indexes.text(c.name);
      indexes.header();
      indexes.nextColumn();
    });

    indexes.goToColumn(0);
    indexes.nextRow();

    const _musicians = [...musicians];
    _musicians.sort((a, b) => a.lastName?.localeCompare(b.lastName));

    _musicians.forEach((m) => {
      columns.forEach((c) => {
        indexes.text(valueGetters[c](m));
        indexes.nextColumn();
      });

      usedNoteTypes.forEach((c) => {
        const note = musicianNotes.find(
          (n) => n.noteTypeID === c.id && n.musicianID === m.id
        );
        indexes.text(note?.value ?? "");
        indexes.nextColumn();
      });

      indexes.goToColumn(0);
      indexes.nextRow();
    });
    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
    downloadExcel(workbook, fileName);
  }

  return () => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setTrigger(true);
  };
}
