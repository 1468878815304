import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  ListItemDecorator,
  Menu,
  MenuItem,
  Sheet,
  Tab,
  TabList,
  Tabs,
  Textarea,
  Typography,
} from "@mui/joy";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Skeleton,
  TextField,
  alpha,
} from "@mui/material";
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import DialogClose from "atoms/DialogClose/DialogClose";
import Upload from "atoms/FileUpload/FileUpload";
import { REACT_APP_API } from "config";
import { MercuryJob_Entity } from "entities/mercuryJob";
import { Musician_Entity } from "entities/musician";
import { Project_Entity } from "entities/project";
import { WorkSession_Entity } from "entities/workSession";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useProjectHiringSelectAll } from "features/projects/ProjectHiring/useProjectHiringDataGrid";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import ProjectNotesPicker from "hooks/ProjectNotesPicker";
import SlidingDialog from "hooks/SlidingDialog/SlidingDialog";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { notifierBodySelector, setBody } from "reducers/notifier";
import {
  projectHiringSelectionSelector,
  projectHiring_UnselectAll_Musicians,
  projectHiring_UnselectAll_WorkSessions,
} from "reducers/projectHiring";
import {
  formOpenSelector,
  notifierSelectedStageSelector,
  selectedProjectIDSelector,
  setFormOpen,
  setMissionControlMode,
  setMissionControlViewMode,
  setNotifierSelectedStage,
  setSelectedProjectID,
} from "reducers/rhapsody";
import { rhapsodyApi } from "redux/api";
import { useCurrentCompany } from "redux/company/companyHooks";
import { useGetHiringNotifyQuery } from "redux/hiringNotify/hiringNotifyEndpoints";
import { useUpdateProjectMutation } from "redux/project/projectEndpoints";
import { useProject, useProjects } from "redux/project/projectHooks";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { useLazyGetProjectNotifyQuery } from "redux/projectNotify/hiringNotifyEndpoints";
/**
 *
 * @returns {ReactElement} Notifier page
 */
export function Notifier() {
  const open = useSelector(formOpenSelector("notifier"));
  const { projects, projectsMap, isLoading: projectsLoading } = useProjects();
  const navigate = useNavigate();
  const selectedStage = useSelector(notifierSelectedStageSelector);
  const projectID: number = useSelector(selectedProjectIDSelector);
  const {
    projectHiring,
    isLoading: projectHiringLoading,
    isFetching: projectHiringFetching,
  } = useProjectHiring(projectID);
  const { project: _project, isLoading: projectLoading } =
    useProject(projectID);
  const [updateProject] = useUpdateProjectMutation();
  const [sendCall] = useLazyGetProjectNotifyQuery();
  const { company } = useCurrentCompany();
  const selection: {
    selectedMusicians: Musician_Entity[];
    selectedSessions: WorkSession_Entity[];
  } = useSelector(projectHiringSelectionSelector);
  const body = useSelector(notifierBodySelector);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();
  const [preview, setPreview] = useState<string>();
  const [acknowledge, setAcknowledge] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [jobForPreview, setJobForPreview] =
    useState<MercuryJob_Entity | null>();
  const [project, setProject] = useState<Project_Entity | null>();
  const [needSelect, setNeedSelect] = useState<{
    workSession: boolean;
    musician: boolean;
  }>({
    musician: false,
    workSession: false,
  });
  const [mobileTab, setMobileTab] = useState<"info" | "preview">("info");
  const [previewTimeout, setPreviewTimeout] = useState<{
    timeoutID: TimeoutId;
    time: number;
  } | null>();
  const ref = useRef();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const projectPage = pathnames.indexOf(RouterConfig.projects.slice(1)) >= 0;
  const selectAll = useProjectHiringSelectAll();
  const askQuestion = useAskQuestion();
  const dispatch = useDispatch();
  const { projectNotes } = useProjectNotes(projectID);

  useEffect(() => {
    return () => {
      if (!projectPage) {
        dispatch(setSelectedProjectID());
        dispatch(setNotifierSelectedStage(null));
        dispatch(projectHiring_UnselectAll_Musicians());
        dispatch(projectHiring_UnselectAll_WorkSessions());
        dispatch(
          rhapsodyApi.util.invalidateTags([
            { type: "projectHirings", id: projectID },
          ])
        );
        dispatch(
          rhapsodyApi.util.invalidateTags([
            { type: "hiringNotify", id: projectID },
          ])
        );
      }
    };
  }, []);

  useEffect(() => {
    if (_project?.id) {
      setProject(_project);
      getHTMLPreview();
    }
  }, [_project]);

  useEffect(() => {
    if (previewTimeout) {
      clearTimeout(previewTimeout.timeoutID);
    }
    const time = new Date().getTime();
    const timeoutID = setTimeout(() => {
      getHTMLPreview();
      setPreviewTimeout(null);
    }, 500);
    setPreviewTimeout({
      time,
      timeoutID,
    });
  }, [body, jobForPreview, projectNotes]);

  useEffect(() => {
    if (selectedStage?.jobs?.length && !jobForPreview) {
      setJobForPreview(selectedStage?.jobs[0]);
    }
  }, [selectedStage]);

  const notify = () => {
    const _b = {
      ...body,
      ...projectNotifyBody,
      mercuryStageID: selectedStage.id,
    };
    const _notes = [];
    _b.projectNoteIDs?.forEach((id) => {
      const projectNote = projectNotes.find((pn) => pn.id === id);
      _notes.push(projectNote.value);
    });
    _b.notes = _notes.join("<hr />");
    sendCall({
      projectID,
      body: _b,
    });
    onClose();
  };

  const saveProject = () => {
    if (project)
      updateProject({
        id: project.id,
        body: { ...project },
      });
  };

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "notifier" }));
  };

  const projectNotifyBody = {
    musicianIDs: selection.selectedMusicians.reduce((a, item) => {
      a.push(item.id);
      return a;
    }, new Array<number>()),
    sessionIDs: selection.selectedSessions.reduce((a, item) => {
      a.push(item.id);
      return a;
    }, new Array<number>()),
  };

  const hasSelection =
    projectNotifyBody.musicianIDs.length && projectNotifyBody.sessionIDs.length;

  const {
    data: projectNotify,
    isLoading,
    isFetching,
  } = useGetHiringNotifyQuery(
    { projectID, body: projectNotifyBody },
    {
      skip: !projectID || !open || !hasSelection,
      refetchOnMountOrArgChange: true,
    }
  );

  const getHTMLPreview = async () => {
    if (!selectedStage || !jobForPreview) return;
    setLoading(true);
    const _b = {
      ...body,
      ...projectNotifyBody,
      musician: { id: jobForPreview.refID },
    };
    _b.mercuryStageID = selectedStage.id;
    const _notes = [];
    _b.projectNoteIDs?.forEach((id) => {
      const projectNote = projectNotes.find((pn) => pn.id === id);
      _notes.push(projectNote.value);
    });
    _b.notes = _notes.join("<hr />");

    fetch(`${REACT_APP_API}/rhapsody/projects/${projectID}/mercury/html`, {
      body: JSON.stringify(_b),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      method: "PUT",
    })
      .then((t) => t.text())
      .then((t) => {
        setPreview(t);
        setLoading(false);
      });
  };

  const noProjects = (
    <>
      <DialogTitle>
        <Typography level="h5" sx={{ fontWeight: 800 }}>
          Not so fast 😁!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>You need to create a project first.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(setFormOpen({ formID: "project", isOpen: true }));
            dispatch(setFormOpen({ formID: "notifier", isOpen: false }));
          }}
          fullWidth
        >
          Let's do it!
        </Button>
      </DialogActions>
    </>
  );

  const selectProjectFirst = (
    <>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-duotone fa-paper-plane"></i>}
          level="h6"
          sx={{ fontWeight: 800 }}
        >
          Let's contact your musicians
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          {projectID && project ? (
            <Alert
              sx={{ color: project.color, borderColor: project.color }}
              startDecorator={
                <i
                  style={{ color: project.color, fontSize: 20 }}
                  className="fa-duotone fa-folder-open"
                ></i>
              }
              variant="outlined"
              endDecorator={
                <IconButton
                  variant="plain"
                  color="neutral"
                  onClick={() => dispatch(setSelectedProjectID())}
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
              }
            >
              <Box>
                <Typography
                  sx={{ color: project.color, opacity: 0.58 }}
                  level="body3"
                >
                  Project
                </Typography>
                <Typography sx={{ color: project.color }} level="body2">
                  {" "}
                  {project?.name}
                </Typography>
              </Box>
            </Alert>
          ) : (
            <Autocomplete
              id="combo-box-demo"
              sx={{ pt: 1 }}
              onChange={(e, v) => {
                if (v?.value) {
                  dispatch(setSelectedProjectID(v.value));
                  const project = projectsMap[v.value];
                  if (project.version === 2) {
                    dispatch(setMissionControlMode("invite"));
                    dispatch(setMissionControlViewMode("assignments"));
                    navigate(
                      `${RouterConfig.projects}/${project.id}/mission-control`
                    );
                    dispatch(
                      setFormOpen({ formID: "notifier", isOpen: false })
                    );
                  }
                }
              }}
              options={
                projects
                  ?.filter((p) => !p.archived)
                  ?.sort((a, b) => b.id - a.id)
                  .reduce((a, p) => {
                    a.push({ label: p.name, value: p.id });
                    return a;
                  }, []) ?? []
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Select a Project" />
              )}
            />
          )}
          {project && projectID ? (
            <>
              {projectHiringLoading ||
              projectHiringFetching ||
              !projectHiring ? (
                <Skeleton />
              ) : (
                <Box>
                  {projectHiring?.sessions.length &&
                  projectHiring?.musicians.length &&
                  selection?.selectedMusicians?.length === 0 ? (
                    <Box
                      sx={{ display: "flex", gap: 2, flexDirection: "column" }}
                    >
                      <Box>
                        <Typography>Who should get this call?</Typography>
                        <Tabs
                          defaultValue={"everybody"}
                          size="sm"
                          variant="soft"
                          sx={{ width: 180 }}
                          onChange={(e, v) => {
                            switch (v) {
                              case "customize":
                                setNeedSelect((s) => ({
                                  ...s,
                                  musician: true,
                                }));
                                break;
                              case "everybody":
                                setNeedSelect((s) => ({
                                  ...s,
                                  musician: false,
                                }));
                                break;
                            }
                          }}
                        >
                          <TabList variant="outlined" color="neutral">
                            <Tab value="customize">Customize</Tab>
                            <Tab value="everybody">Everybody</Tab>
                          </TabList>
                        </Tabs>
                      </Box>
                      <Box>
                        <Typography>
                          Which work sessions is this call about?
                        </Typography>
                        <Tabs
                          defaultValue={"all"}
                          size="sm"
                          variant="soft"
                          sx={{ width: 140 }}
                          onChange={(e, v) => {
                            switch (v) {
                              case "customize":
                                setNeedSelect((s) => ({
                                  ...s,
                                  workSession: true,
                                }));
                                break;
                              case "all":
                                setNeedSelect((s) => ({
                                  ...s,
                                  workSession: false,
                                }));
                                break;
                            }
                          }}
                        >
                          <TabList variant="outlined" color="neutral">
                            <Tab value="customize">Customize</Tab>
                            <Tab value="all">All</Tab>
                          </TabList>
                        </Tabs>
                      </Box>
                      {needSelect.workSession || needSelect.musician ? (
                        <Button
                          onClick={() => {
                            onClose();
                            askQuestion("Instruction", ["Got it!"], {
                              subtitle:
                                "Use the checkboxes to select the Musicians and the Work Sessions to build thet target you want to notify.",
                            });
                            navigate(
                              `${RouterConfig.projects}/${projectID}/hiring`
                            );
                          }}
                          color="info"
                        >
                          Customize the Target
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            selectAll();
                          }}
                        >
                          Build the Call
                        </Button>
                      )}
                    </Box>
                  ) : (
                    <Typography>
                      🧐 Humm... Looks like there is nobody to notify here.
                    </Typography>
                  )}
                </Box>
              )}
            </>
          ) : (
            []
          )}
        </Box>
      </DialogContent>
    </>
  );

  const stages = body.nudge
    ? projectNotify?.currentStages.filter((s) => s.nudgeable)
    : projectNotify?.upcomingStages;

  const selectStage = projectNotify && (
    <>
      <DialogContent>
        <Typography level="h6" sx={{ mb: 2 }}>
          What kind of communication would you like to send?
        </Typography>
        {isLoading || isFetching ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Tabs size="sm" defaultValue={1} sx={{ width: 150 }}>
              <TabList>
                <Tab
                  onClick={() => dispatch(setBody({ ...body, nudge: false }))}
                  value={1}
                >
                  Action
                </Tab>
                <Tab
                  onClick={() => dispatch(setBody({ ...body, nudge: true }))}
                  value={2}
                >
                  Nudge
                </Tab>
              </TabList>
            </Tabs>
            <Grid
              container
              alignItems={"stretch"}
              spacing={2}
              sx={{ width: "calc(100% + 16px)", mt: 1 }}
            >
              {stages?.map((stage) => (
                <Grid
                  item
                  xs={6}
                  md={3}
                  key={stage.id}
                  onClick={() => {
                    console.log("");
                  }}
                >
                  <Card
                    invertedColors
                    onClick={() => dispatch(setNotifierSelectedStage(stage))}
                    sx={{
                      cursor: "pointer",
                      height: "100%",
                      background: stage.color,
                      "&:hover": { background: alpha(stage.color, 0.9) },
                      "&:active": { background: alpha(stage.color, 0.8) },
                    }}
                  >
                    <Badge
                      color="danger"
                      badgeContent={
                        body.nudge ? (
                          <i className="fa-solid fa-bullhorn"></i>
                        ) : (
                          0
                        )
                      }
                    >
                      <img
                        src={stage.icon}
                        style={{
                          height: 32,
                          width: 32,
                          filter: "grayscale(100%) brightness(100)",
                        }}
                        alt="status"
                      />
                    </Badge>
                    <Typography
                      level="body1"
                      sx={{ fontWeight: 600, color: "white", mt: 1 }}
                    >
                      {stage.name}
                      {body.nudge ? " Nudge" : ""}
                    </Typography>
                    <Typography level="body3" sx={{ color: "white" }}>
                      {stage.jobCount} Musician{stage.jobCount > 1 ? "s" : ""}
                    </Typography>
                  </Card>
                </Grid>
              ))}
              {stages.length === 0 ? (
                <Grid item>
                  <Typography>
                    Looks like there is nothing available in this category
                  </Typography>
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Box>
        )}
      </DialogContent>
    </>
  );

  const jobForPreviewDropdown = (
    <Menu
      size="sm"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      sx={{ zIndex: 9999, maxHeight: 600 }}
    >
      {selectedStage?.jobs
        ?.filter((j) => j.refID !== jobForPreview?.refID)
        ?.map((j) => (
          <MenuItem
            onClick={() => {
              setJobForPreview(j);
              setAnchorEl(null);
            }}
            sx={{ gap: 1 }}
            key={j.id}
          >
            <ListItemDecorator>
              <MusicianAvatar size={26} noBadge musicianID={j.refID} />
            </ListItemDecorator>
            {j.name}
          </MenuItem>
        ))}
    </Menu>
  );

  const callPreview = selectedStage && project && company && (
    <Sheet
      variant="soft"
      color="neutral"
      sx={{
        flex: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        background: "#F2F5F9",
      }}
    >
      <Box sx={{ p: 1 }}>
        <Typography level="body3" startDecorator="To:">
          <Chip
            startDecorator={
              <MusicianAvatar
                size={20}
                noBadge
                musicianID={jobForPreview?.refID}
              />
            }
            size="sm"
            color="neutral"
          >
            {jobForPreview?.name} (
            {jobForPreview?.channels?.find((c) => c.name === "Email")
              ?.contact ?? "Unkown"}
            )
          </Chip>
          {selectedStage.jobs.length > 1 ? (
            <>
              <Chip
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: "rgba(155,155,155,0.2)",
                  },
                }}
                size="sm"
                color="neutral"
                variant="outlined"
              >
                {selectedStage.jobs.length - 1 >= 1
                  ? ` +${selectedStage.jobs.length - 1} more ▾`
                  : ""}
              </Chip>
              {jobForPreviewDropdown}
            </>
          ) : (
            []
          )}
        </Typography>
        <Typography level="body3" startDecorator="From:">
          {body.messenger || company?.name || "Rhapsody Bot"}
        </Typography>
        <Typography level="body3" startDecorator="Reply To:">
          {company?.email}
        </Typography>
        <Typography level="body3" startDecorator="Subject:">
          {jobForPreview?.channels?.find((c) => c.name === "Email")?.subject ??
            ""}
          {selectedStage?.channels?.find((c) => c.name === "Email")?.subject ??
            ""}
        </Typography>
      </Box>
      <Divider>Email Preview</Divider>
      <Box sx={{ flexGrow: 1, display: "flex", position: "relative" }}>
        {preview ? (
          <iframe
            title="preview"
            ref={ref}
            srcDoc={preview}
            style={{
              border: "none",
              width: "100%",
              flex: 1,
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              flex: 1,
              p: 2,
            }}
          >
            <Skeleton />
            <Skeleton />
          </Box>
        )}
        <Box
          sx={{
            backdropFilter: loading ? "blur(5px)" : "blur(0px)",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "backdrop-filter .2s",
            pointerEvents: "none",
          }}
        >
          {loading ? <CircularProgress /> : []}
        </Box>
      </Box>
    </Sheet>
  );

  const callInfo = selectedStage && project && (
    <Sheet
      variant="plain"
      color="neutral"
      sx={{
        flex: 1,
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography
        sx={{ color: selectedStage.color }}
        startDecorator={<img src={selectedStage.icon} height={25} />}
        level="h6"
      >
        {selectedStage.name}
      </Typography>
      <Input
        onChange={(e) =>
          dispatch(setBody({ ...body, messenger: e.target.value }))
        }
        startDecorator="Sender:"
        placeholder=""
      />
      <Sheet variant="outlined" sx={{ p: 1, borderRadius: "8px" }}>
        <FormLabel>Project cover</FormLabel>
        <Typography level="body2">
          Upload an image to illustrate your project
        </Typography>
        {project?.logoURL ? (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <img src={project?.logoURL} style={{ height: 48, width: 150 }} />
            <Button
              onClick={() => {
                setProject((pro) => {
                  return { ...pro, logoURL: "" };
                });
                updateProject({
                  id: projectID,
                  body: { ...project, logoURL: "" },
                });
              }}
              size="sm"
              color="neutral"
              variant="soft"
            >
              Remove
            </Button>
          </Box>
        ) : (
          <Upload
            cropDimension={{ width: 600, height: 200 }}
            disabled={false}
            onUpload={(f) => {
              setProject((pro) => {
                return { ...pro, logoURL: f };
              });
              updateProject({
                id: projectID,
                body: { ...project, logoURL: f },
              });
            }}
          />
        )}
      </Sheet>
      <FormControl>
        <FormLabel>Greetings</FormLabel>
        <Textarea
          value={body.greeting}
          onChange={(e) =>
            dispatch(setBody({ ...body, greeting: e.target.value }))
          }
          placeholder=""
          minRows={2}
        />
        <FormHelperText>255 char left</FormHelperText>
      </FormControl>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Checkbox
          size="sm"
          label="Include Session Details"
          variant="solid"
          checked={body.showSessionDetails}
          onChange={(e) =>
            dispatch(setBody({ ...body, showSessionDetails: e.target.checked }))
          }
        />
        <Checkbox
          size="sm"
          label="Add Roster"
          variant="solid"
          checked={body.showRoster}
          onChange={(e) =>
            dispatch(setBody({ ...body, showRoster: e.target.checked }))
          }
        />
      </Box>
      <Divider>Other Options</Divider>
      <Input
        startDecorator="Composer:"
        placeholder=""
        onBlur={saveProject}
        value={project.composer}
        onChange={(e) =>
          setProject((p) => ({
            ...p,
            composer: e.target.value,
          }))
        }
      />
      <Input
        startDecorator="Leader:"
        placeholder=""
        onBlur={saveProject}
        value={project.leader}
        onChange={(e) =>
          setProject((p) => ({
            ...p,
            leader: e.target.value,
          }))
        }
      />
      <Input
        startDecorator="Wage Scale:"
        onBlur={saveProject}
        placeholder=""
        value={project.agreement}
        onChange={(e) =>
          setProject((p) => ({
            ...p,
            agreement: e.target.value,
          }))
        }
      />
      <ProjectNotesPicker
        value={body?.projectNoteIDs}
        onChange={(projectNoteIDs: number[]) => {
          dispatch(setBody({ ...body, projectNoteIDs }));
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box
        sx={{
          p: 1,
          mb: "30px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "sticky",
          bottom: 30,
          backdropFilter: "blur(10px)",
          background: "rgba(255,255,255,0.2)",
        }}
      >
        <Checkbox
          size="sm"
          onChange={(e) => setAcknowledge(e.target.checked)}
          checked={acknowledge}
          sx={{ alignItems: "center", gap: 2 }}
          label={
            <Typography level="body2">
              I understand that this action will send emails/SMS.
              <br />
              All informations concerning the project and the work sessions are
              correct
            </Typography>
          }
          variant="solid"
          defaultChecked
        />
        <Button fullWidth disabled={!acknowledge} onClick={notify}>
          Send Now!
        </Button>
      </Box>
    </Sheet>
  );

  const expired = selection?.selectedSessions?.reduce((a, i) => {
    return a || moment(i.dateToUTC).isBefore(moment());
  }, false);

  const callBuilder = selectedStage && (
    <>
      <Hidden smUp>
        <Box sx={{ p: 1 }}>
          <Tabs size="sm">
            <TabList variant="soft" color="neutral">
              <Tab onClick={() => setMobileTab("info")}>Info</Tab>
              <Tab onClick={() => setMobileTab("preview")}>Preview</Tab>
            </TabList>
          </Tabs>
        </Box>
      </Hidden>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {expired ? (
          <Alert
            color="warning"
            sx={{ borderRadius: 0, borderBottom: "solid 1px #492E0A22" }}
          >
            <Box sx={{ textAlign: "center", width: "100%" }}>
              <b>
                <i className="fa-solid fa-triangle-exclamation"></i> WARNING:
              </b>{" "}
              Looks like you're trying to send a communication about a Work
              Session that has already taken place.
            </Box>
          </Alert>
        ) : (
          []
        )}
        <Box sx={{ flex: 1, display: "flex" }}>
          <Hidden smDown>
            {callPreview} {callInfo}
          </Hidden>
          <Hidden smUp>
            {mobileTab === "preview" ? callPreview : callInfo}
          </Hidden>
        </Box>
      </Box>
    </>
  );

  const DialogComponent = selectedStage ? SlidingDialog : DialogClose;

  const getContent = () => {
    if (projectsLoading)
      return (
        <Box sx={{ p: 4 }}>
          <CircularProgress />
        </Box>
      );
    if (projects?.length === 0 && !isLoading) return noProjects;
    if (!projectID || !hasSelection) return selectProjectFirst;
    if (projectNotify && !selectedStage) return selectStage;
    if (selectedStage) return callBuilder;
  };

  return (
    <DialogComponent open={open} onClose={onClose}>
      {getContent()}
    </DialogComponent>
  );
}
