import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { WorkSessionSection_Entity } from "entities/workSessionSection";
import { rhapsodyApi } from "../api/rhapsodyApi";

const workSessionSectionsAdapter =
  createEntityAdapter<WorkSessionSection_Entity>();
const initialState = workSessionSectionsAdapter.getInitialState();

export const workSessionSectionEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkSessionSections: build.query<
      EntityState<WorkSessionSection_Entity>,
      number
    >({
      query: (workSessionID) => `workSessions/${workSessionID}/sections`,
      transformResponse: (responseData: WorkSessionSection_Entity[]) => {
        return workSessionSectionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["workSessionSections"],
    }),
    getWorkSessionSection: build.query<WorkSessionSection_Entity, number>({
      query: (id) => `sessionSections/${id}`,
      providesTags: (result, error, id) => [
        { type: "workSessionSections", id },
      ],
    }),
    createWorkSessionSection: build.mutation<
      WorkSessionSection_Entity,
      Partial<WorkSessionSection_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `sessionSections`,
      }),
      invalidatesTags: ["workSessionSections"],
    }),
    updateWorkSessionSection: build.mutation<
      void,
      { id: number; body: Partial<WorkSessionSection_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `sessionSections/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "workSessionSections", id },
        "workSessionSections",
      ],
    }),
    deleteWorkSessionSection: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `sessionSections/${id}`,
      }),
      invalidatesTags: ["workSessionSections"],
    }),
  }),
});

export const {
  useGetWorkSessionSectionQuery,
  useGetWorkSessionSectionsQuery,
  useCreateWorkSessionSectionMutation,
  useDeleteWorkSessionSectionMutation,
  useUpdateWorkSessionSectionMutation,
} = workSessionSectionEndpoints;

export default workSessionSectionEndpoints;
