import Mission_Entity from "./mission";
export default class Mission extends Mission_Entity {
  static fromList(missionsJSON: unknown): Array<Mission> {
    const missions: Mission[] = [];
    if (missionsJSON)
      Array.isArray(missionsJSON) &&
        missionsJSON.forEach((missionJSON) => {
          missions.push(new Mission(missionJSON));
        });
    return missions;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
