import WorkSessionMercury_Entity from "./workSessionMercury";
export default class WorkSessionMercury extends WorkSessionMercury_Entity {
  static fromList(workSessionMercurysJSON: unknown): Array<WorkSessionMercury> {
    const workSessionMercurys: WorkSessionMercury[] = [];
    if (workSessionMercurysJSON)
      Array.isArray(workSessionMercurysJSON) &&
        workSessionMercurysJSON.forEach((workSessionMercuryJSON) => {
          workSessionMercurys.push(new WorkSessionMercury(workSessionMercuryJSON));
        });
    return workSessionMercurys;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
