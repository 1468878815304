/**
 * DanielsWork_Entity type guard.
 *
 * @param {any} danielsWorkJson DanielsWork object from API
 * @returns {boolean} Return true if type is DanielsWork_Entity
 */

export default class DanielsWork_Entity {
  constructor(danielsWorkJson: DanielsWork_Entity) {
    this.formula = danielsWorkJson.formula;
    this.additional_info = danielsWorkJson.additional_info;
    this.duration = danielsWorkJson.duration;
    this.title = danielsWorkJson.title;
    this.remarks = danielsWorkJson.remarks;
    this.domo_uid = danielsWorkJson.domo_uid;
    this.composed_from = danielsWorkJson.composed_from;
    this.composed_to = danielsWorkJson.composed_to;
    this.perc_info = danielsWorkJson.perc_info;
    this.ensemble = danielsWorkJson.ensemble;
    this.movements = danielsWorkJson.movements;
  }

  formula?: string;
  additional_info: string;
  duration: string;
  title: string;
  remarks: string;
  domo_uid: string;
  composed_from: string;
  composed_to: string;
  perc_info: string;
  movements?: {
    name?: string; // Movement title
    duration?: string; // Movement duration
  }[];
  ensemble?: any;
}
