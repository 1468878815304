import { ProjectPieceChair } from "entities/projectPieceChair";
import { useMemo } from "react";
import {
  useGetProjectPieceChairQuery,
  useGetProjectPieceChairsQuery,
} from "./projectPieceChairEndpoints";

export function useProjectPieceChair(projectPieceChairID: number | undefined) {
  const projectPieceChairQuery = useGetProjectPieceChairQuery(
    projectPieceChairID ?? 0,
    {
      skip: (projectPieceChairID ?? 0) <= 0,
    }
  );
  const projectPieceChairEntity = projectPieceChairQuery.data;

  return useMemo(() => {
    const ret: typeof projectPieceChairQuery & {
      projectPieceChair?: ProjectPieceChair | undefined;
    } = {
      ...projectPieceChairQuery,
    };
    if (projectPieceChairEntity)
      ret.projectPieceChair = new ProjectPieceChair(projectPieceChairEntity);
    return ret;
  }, [projectPieceChairEntity]);
}

export function useProjectPieceChairs(projectPieceID: number | undefined) {
  const projectPieceChairQuery = useGetProjectPieceChairsQuery(projectPieceID, {
    skip: (projectPieceID ?? 0) <= 0,
  });
  const projectPieceChairEntitiesMap = projectPieceChairQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectPieceChairQuery & {
      projectPieceChairs?: ProjectPieceChair[];
      projectPieceChairsMap?: { [id: number]: ProjectPieceChair };
    } = {
      ...projectPieceChairQuery,
      projectPieceChairs: [],
      projectPieceChairsMap: {},
    };
    if (projectPieceChairEntitiesMap) {
      const projectPieceChairs = [];
      const projectPieceChairsMap = {};

      for (const key in projectPieceChairEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(
            projectPieceChairEntitiesMap,
            key
          )
        ) {
          const c = projectPieceChairEntitiesMap[key];
          const projectPieceChair = new ProjectPieceChair(c);
          projectPieceChairs.push(projectPieceChair);
          projectPieceChairsMap[projectPieceChair.id] = projectPieceChair;
        }
      }
      ret.projectPieceChairs = projectPieceChairs;
      ret.projectPieceChairsMap = projectPieceChairsMap;
    }

    return ret;
  }, [projectPieceChairEntitiesMap]);
}
