import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { ProjectSection_Entity } from "entities/projectSection";
import { rhapsodyApi } from "../api/rhapsodyApi";

const projectSectionsAdapter = createEntityAdapter<ProjectSection_Entity>();
const initialState = projectSectionsAdapter.getInitialState();

export const projectSectionEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectSections: build.query<EntityState<ProjectSection_Entity>, number>(
      {
        query: (projectID) => `projects/${projectID}/sections`,
        transformResponse: (responseData: ProjectSection_Entity[]) => {
          return projectSectionsAdapter.setAll(initialState, responseData);
        },
        providesTags: (result, error, id) => [{ type: "projectSections", id }],
      }
    ),
    getProjectSection: build.query<ProjectSection_Entity, number>({
      query: (id) => `projectSections/${id}`,
      providesTags: (result, error, id) => [{ type: "projectSections", id }],
    }),
    createProjectSection: build.mutation<
      ProjectSection_Entity,
      Partial<ProjectSection_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `projectSections`,
      }),
      invalidatesTags: ["projectSections"],
    }),
    updateProjectSection: build.mutation<
      void,
      { id: number; body: Partial<ProjectSection_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projectSections/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "projectSections", id },
        "projectSections",
      ],
    }),
    deleteProjectSection: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projectSections/${id}`,
      }),
      invalidatesTags: ["projectSections"],
    }),
  }),
});

export const {
  useGetProjectSectionQuery,
  useGetProjectSectionsQuery,
  useCreateProjectSectionMutation,
  useDeleteProjectSectionMutation,
  useUpdateProjectSectionMutation,
} = projectSectionEndpoints;

export default projectSectionEndpoints;
