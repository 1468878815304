import { Dictionary } from "@reduxjs/toolkit";
import { ProjectSection } from "entities/projectSection";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedProjectIDSelector, setFormOpen } from "reducers/rhapsody";
import { useFamilies } from "redux/family/familyHooks";
import { useMusicians } from "redux/musician/musicianHooks";
import { useProject } from "redux/project/projectHooks";
import { useProjectSections } from "redux/projectSection/projectSectionHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { Indexes, createWorkbook, downloadExcel } from "../utils";

export function useDownloadProjectSeatingExcel() {
  const [trigger, setTrigger] = useState(false);
  const [projectID, setProjectID] = useState<number>();
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const { project, isSuccess: s1 } = useProject(
    projectID ?? selectedProjectID,
    { skip: !trigger }
  );
  const { projectSections, isSuccess: s2 } = useProjectSections(
    projectID ?? selectedProjectID
  );
  const { musiciansMap, isSuccess: s3 } = useMusicians(undefined, {
    skip: !trigger,
  });
  const dispatch = useDispatch();
  const { sectionRolesMap, isSuccess: s4 } = useSectionRoles();
  const { familiesMap, isSuccess: s5 } = useFamilies();
  const { sectionsMap, isSuccess: s6 } = useSections();

  useEffect(() => {
    if (trigger && s1 && s2 && s3 && s4 && s5 && s6) {
      setTrigger(false);
      download();
    }
  }, [trigger, s1, s2, s3, s4, s5, s6]);

  function download() {
    const workbook = createWorkbook();
    const fileName = `${project.name} - Seating`;
    const fmap: Dictionary<ProjectSection[]> = projectSections.reduce(
      (a, s) => {
        if (a[s.familyID]) {
          a[s.familyID].push(s);
        } else {
          a[s.familyID] = [s];
        }
        return a;
      },
      {}
    );

    for (const familyID in fmap) {
      if (Object.hasOwnProperty.call(fmap, familyID)) {
        const sections = fmap[familyID];
        if (sections.length === 0) continue;
        const worksheet = workbook.addWorksheet(familiesMap[familyID]?.name, {
          pageSetup: {
            paperSize: undefined,
            fitToPage: true,
            fitToWidth: 1,
            blackAndWhite: true,
            horizontalCentered: true,
            fitToHeight: 1,
          },
        });
        worksheet.pageSetup.margins = {
          left: 0.5,
          right: 0.5,
          top: 0,
          bottom: 0.5,
          header: 0,
          footer: 0.3,
        };
        const indexes = new Indexes(worksheet);

        sections.forEach((s) => {
          const section = sectionsMap[s.sectionID];
          if (s.projectMusicians?.length === 0) return;
          indexes.select(2, 0);
          indexes.merge();
          indexes.unselect();
          indexes.text(section.name);
          indexes.header();
          indexes.nextRow();

          s.projectMusicians.forEach((j) => {
            const musician = musiciansMap[j.musicianID];
            if (musician) {
              indexes.text(`${musician.fullName()}`);
              indexes.width(20);
              indexes.bold();
              if (j.prime) {
                indexes.color({ argb: `FFD1B856` });
              } else {
                indexes.color({ argb: `FF80939E` });
              }
              indexes.nextColumn();
              indexes.text(sectionRolesMap[j.sectionRoleID].name);
              indexes.width(10);
              indexes.nextColumn();
              indexes.text(musician.email);
              indexes.width(30);
              indexes.previousColumn();
              indexes.previousColumn();
            } else {
              indexes.text("Empty Position");
            }
            indexes.nextRow();
          });
          indexes.goToRow(1);
          indexes.nextColumn();
          indexes.nextColumn();
          indexes.nextColumn();
        });
        indexes.nextColumn();
      }
    }
    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
    downloadExcel(workbook, fileName);
  }

  return (_projectID?: number) => {
    setProjectID(_projectID);
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setTrigger(true);
  };
}
