import React, { ReactElement } from "react";
import { Box, Tooltip } from "@mui/material";
import { copyTextToClipboard } from "helpers";

export default function Id({
  children,
  startDecorator,
  tooltip,
  noStyle,
}: {
  children?: ReactElement | string | number;
  startDecorator?: ReactElement | null | undefined;
  tooltip?: string;
  noStyle?: boolean;
}) {
  const [click, setClick] = React.useState(false);

  return (
    <Tooltip title={click ? "Copied!" : tooltip ?? "Click to Copy"}>
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setClick(true);
          copyTextToClipboard(children);
        }}
        sx={
          !noStyle
            ? {
                fontSize: 10,
                border: "solid 1px #757575",
                color: "#757575",
                background: "rgba(155,155,155,0.05)",
                borderRadius: "4px",
                cursor: "pointer",
                height: 16,
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                position: "relative",
                flexShrink: 0,
              }
            : { display: "inline-block" }
        }
      >
        {startDecorator ? (
          <Box
            sx={{
              fontSize: 9,
              color: "white",
              background: "#757575",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "2px",
            }}
          >
            {startDecorator}
          </Box>
        ) : (
          []
        )}
        <Box sx={{ p: "2px" }}>
          {noStyle ? "" : "#"}
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
}
