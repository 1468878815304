import { Dictionary } from "@reduxjs/toolkit";
import { WorkSession, WorkSession_Entity } from "entities/workSession";
import { useMemo } from "react";
import {
  useGetActiveWorkSessionsQuery,
  useGetArchivedWorkSessionsQuery,
  useGetCustomerWorkSessionsQuery,
  useGetMusicianWorkSessionsQuery,
  useGetPayrollWorkSessionsQuery,
  useGetProjectWorkSessionsQuery,
  useGetVenueWorkSessionsQuery,
  useGetWorkSessionQuery,
  useGetWorkSessionsQuery,
} from "./workSessionEndpoints";
import moment from "moment";

export function useWorkSession(
  workSessionID: number | undefined,
  opt?: { skip?: boolean }
) {
  const workSessionQuery = useGetWorkSessionQuery(workSessionID ?? 0, {
    skip: (workSessionID ?? 0) <= 0 || opt?.skip,
  });
  const workSessionEntity = workSessionQuery.data;

  return useMemo(() => {
    const ret: typeof workSessionQuery & {
      workSession?: WorkSession | undefined;
    } = {
      ...workSessionQuery,
    };
    if (workSessionEntity) ret.workSession = new WorkSession(workSessionEntity);
    return ret;
  }, [workSessionEntity]);
}

export function useActiveWorkSessions(opt?: { skip?: boolean }) {
  const workSessionQuery = useGetActiveWorkSessionsQuery(undefined, {
    skip: opt?.skip ?? false,
  });
  const workSessionEntitiesMap = workSessionQuery.data?.entities;

  return useMemo(() => {
    const f = workSessionsFactory(workSessionEntitiesMap);
    return {
      ...workSessionQuery,
      activeWorkSessions: f.array,
      activeWorkSessionsMap: f.map,
    };
  }, [workSessionEntitiesMap]);
}

export function useArchivedWorkSessions({ skip }: { skip: boolean }) {
  const workSessionQuery = useGetArchivedWorkSessionsQuery(undefined, {
    skip,
  });
  const workSessionEntitiesMap = workSessionQuery.data?.entities;

  return useMemo(() => {
    const f = workSessionsFactory(workSessionEntitiesMap);
    return {
      ...workSessionQuery,
      archivedWorkSessions: f.array,
      archivedWorkSessionsMap: f.map,
    };
  }, [workSessionEntitiesMap]);
}

export function useWorkSessionsFilter(args?: any, opt?: any) {
  const workSessionQuery = useGetWorkSessionsQuery(args, opt);
  const workSessionEntitiesMap = workSessionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof workSessionQuery & {
      workSessions?: WorkSession[];
      workSessionsMap?: { [id: number]: WorkSession };
    } = {
      ...workSessionQuery,
      workSessions: [],
      workSessionsMap: {},
    };
    if (workSessionEntitiesMap) {
      const workSessions = [];
      const workSessionsMap = {};

      for (const key in workSessionEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(workSessionEntitiesMap, key)) {
          const c = workSessionEntitiesMap[key];
          const workSession = new WorkSession(c);
          workSessions.push(workSession);
          workSessionsMap[workSession.id] = workSession;
        }
      }
      ret.workSessions = workSessions;
      ret.workSessionsMap = workSessionsMap;
    }

    return ret;
  }, [workSessionEntitiesMap]);
}

export function useCustomerWorkSessions(customerID?: number | undefined) {
  const workSessionQuery = useGetCustomerWorkSessionsQuery(customerID, {
    skip: !customerID,
  });
  const workSessionEntitiesMap = workSessionQuery.data?.entities;

  return useMemo(() => {
    const f = workSessionsFactory(workSessionEntitiesMap);
    return {
      ...workSessionQuery,
      customerWorkSessions: f.array,
      customerWorkSessionsMap: f.map,
    };
  }, [workSessionEntitiesMap]);
}

export function usePayrollWorkSessions(payrollID?: number | undefined) {
  const workSessionQuery = useGetPayrollWorkSessionsQuery(payrollID, {
    skip: !payrollID,
  });
  const workSessionEntitiesMap = workSessionQuery.data?.entities;

  return useMemo(() => {
    const f = workSessionsFactory(workSessionEntitiesMap);
    return {
      ...workSessionQuery,
      payrollWorkSessions: f.array,
      payrollWorkSessionsMap: f.map,
    };
  }, [workSessionEntitiesMap]);
}

export function useProjectWorkSessions(projectID?: number | undefined) {
  const workSessionQuery = useGetProjectWorkSessionsQuery(projectID, {
    skip: !projectID,
  });
  const workSessionEntitiesMap = workSessionQuery.data?.entities;

  return useMemo(() => {
    const f = workSessionsFactory(workSessionEntitiesMap);
    const ret = {
      ...workSessionQuery,
      projectWorkSessions: f.array,
      projectWorkSessionsMap: f.map,
    };

    ret.projectWorkSessions.sort(
      (a, b) =>
        moment(a.dateFromUTC).valueOf() - moment(b.dateFromUTC).valueOf()
    );

    return ret;
  }, [workSessionEntitiesMap]);
}

export function useMusicianWorkSessions(musicianID?: number | undefined) {
  const workSessionQuery = useGetMusicianWorkSessionsQuery(musicianID, {
    skip: !musicianID,
  });
  const workSessionEntitiesMap = workSessionQuery.data?.entities;

  return useMemo(() => {
    const f = workSessionsFactory(workSessionEntitiesMap);
    return {
      ...workSessionQuery,
      musicianWorkSessions: f.array,
      musicianWorkSessionsMap: f.map,
    };
  }, [workSessionEntitiesMap]);
}

export function useVenueWorkSessions(venueID?: number | undefined) {
  const workSessionQuery = useGetVenueWorkSessionsQuery(venueID, {
    skip: !venueID,
  });
  const workSessionEntitiesMap = workSessionQuery.data?.entities;

  return useMemo(() => {
    const f = workSessionsFactory(workSessionEntitiesMap);
    return {
      ...workSessionQuery,
      venueWorkSessions: f.array,
      venueWorkSessionsMap: f.map,
    };
  }, [workSessionEntitiesMap]);
}

function workSessionsFactory(map: Dictionary<WorkSession_Entity>): {
  array: WorkSession[];
  map: { [id: number]: WorkSession };
} {
  const ret: {
    array: WorkSession[];
    map: { [id: number]: WorkSession };
  } = { array: [], map: {} };
  if (map) {
    const customerWorkSessions = [];
    const customerWorkSessionsMap = {};

    for (const key in map) {
      if (Object.prototype.hasOwnProperty.call(map, key)) {
        const c = map[key];
        const workSession = new WorkSession(c);
        customerWorkSessions.push(workSession);
        customerWorkSessionsMap[workSession.id] = workSession;
      }
    }
    ret.array = customerWorkSessions;
    ret.map = customerWorkSessionsMap;
  }

  return ret;
}
