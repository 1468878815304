/**
 * Company_Entity type guard.
 *
 * @param {any} companyJson Company object from API
 * @returns {boolean} Return true if type is Company_Entity
 */

import { Pipeline_Entity } from "entities/pipeline";

export default class Company_Entity {
  static requiredFields = ["id"];

  constructor(companyJson: Company_Entity) {
    try {
      this.id = companyJson.id;
      this.active = companyJson.active;
      this.address = companyJson.address;
      this.city = companyJson.city;
      this.contact = companyJson.contact;
      this.defaultTab = companyJson.defaultTab;
      this.email = companyJson.email;
      this.initiated = companyJson.initiated;
      this.logoURL = companyJson.logoURL;
      this.musicianID = companyJson.musicianID;
      this.name = companyJson.name;
      this.organizationID = companyJson.organizationID;
      this.phone = companyJson.phone;
      this.pipelineID = companyJson.pipelineID;
      this.referralCode = companyJson.referralCode;
      this.restricted = companyJson.restricted;
      this.signatureURL = companyJson.signatureURL;
      this.state = companyJson.state;
      this.trusted = companyJson.trusted;
      this.userName = companyJson.userName;
      this.zipcode = companyJson.zipcode;
      this.createdAt = companyJson.createdAt;
      this.updatedAt = companyJson.updatedAt;
      this.stripeCustomerID = companyJson.stripeCustomerID;
      this.color = companyJson.color;
      this.pipelines = companyJson.pipelines;
      this.icon = companyJson.icon;
      this.enableRelease = companyJson.enableRelease;
      this.defaultProjectVersion = companyJson.defaultProjectVersion;
      this.settings = companyJson.settings;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  defaultProjectVersion: number;
  active?: boolean;
  address?: string;
  city?: string;
  contact?: string;
  defaultTab?: number;
  email?: string;
  initiated?: boolean;
  logoURL?: string;
  musicianID?: number;
  name?: string;
  organizationID?: number;
  phone?: string;
  pipelines?: Pipeline_Entity[];
  pipelineID?: number;
  referralCode?: string;
  restricted?: boolean;
  signatureURL?: string;
  state?: string;
  trusted?: boolean;
  userName?: string;
  zipcode?: string;
  createdAt: string;
  updatedAt: string;
  stripeCustomerID?: string;
  color?: string;
  icon?: string;
  enableRelease?: boolean;
  settings?: string;
}
