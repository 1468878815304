import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { ProjectRoster_Entity } from "entities/projectRoster";
import { rhapsodyApi } from "../api/rhapsodyApi";

const projectRostersAdapter = createEntityAdapter<ProjectRoster_Entity>();
const initialState = projectRostersAdapter.getInitialState();

export const projectRosterEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectRosters: build.query<EntityState<ProjectRoster_Entity>, void>({
      query: () => `projectRosters`,
      transformResponse: (responseData: ProjectRoster_Entity[]) => {
        return projectRostersAdapter.setAll(initialState, responseData);
      },
      providesTags: ["projectRosters"],
    }),
    getProjectProjectRosters: build.query<
      EntityState<ProjectRoster_Entity>,
      number
    >({
      query: (projectID) => {
        return {
          url: `projectRosters`,
          params: {
            filters: JSON.stringify([
              {
                name: "project_rosters.projectID",
                comparison: "eq",
                value: projectID,
              },
            ]),
          },
        };
      },
      transformResponse: (responseData: ProjectRoster_Entity[]) => {
        return projectRostersAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, id) => [
        { type: "projectRosters", id: `project_${id}` },
      ],
    }),
    getMusicianProjectRosters: build.query<
      EntityState<ProjectRoster_Entity>,
      number
    >({
      query: (musicianID) => {
        return {
          url: `projectRosters`,
          params: {
            filters: JSON.stringify([
              {
                name: "musicianID",
                comparison: "eq",
                value: musicianID,
              },
            ]),
          },
        };
      },
      transformResponse: (responseData: ProjectRoster_Entity[]) => {
        return projectRostersAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, id) => [
        { type: "projectRosters", id: `musician_${id}` },
      ],
    }),
    getTagProjectRosters: build.query<
      EntityState<ProjectRoster_Entity>,
      number
    >({
      query: (tagID) => {
        return {
          url: `projectRosters`,
          params: {
            filters: JSON.stringify([
              {
                name: "tagID",
                comparison: "eq",
                value: tagID,
              },
            ]),
          },
        };
      },
      transformResponse: (responseData: ProjectRoster_Entity[]) => {
        return projectRostersAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, id) => [
        { type: "projectRosters", id: `musician_${id}` },
      ],
    }),
    getWorkSessionProjectRosters: build.query<
      EntityState<ProjectRoster_Entity>,
      number
    >({
      query: (workSessionID) => {
        return {
          url: `projectRosters`,
          params: {
            filters: JSON.stringify([
              {
                name: "workSessionID",
                comparison: "eq",
                value: workSessionID,
              },
            ]),
          },
        };
      },
      transformResponse: (responseData: ProjectRoster_Entity[]) => {
        return projectRostersAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, id) => [
        { type: "projectRosters", id: `workSession_${id}` },
      ],
    }),
    getProjectRoster: build.query<ProjectRoster_Entity, number>({
      query: (id) => `projectRosters/${id}`,
      providesTags: (result, error, id) => [{ type: "projectRosters", id }],
    }),
    createProjectRoster: build.mutation<
      ProjectRoster_Entity,
      Partial<ProjectRoster_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `projectRosters`,
      }),
      invalidatesTags: ["projectRosters"],
    }),
    updateProjectRoster: build.mutation<
      void,
      { id: number; body: Partial<ProjectRoster_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projectRosters/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "projectRosters", id },
        "projectRosters",
      ],
    }),
    deleteProjectRoster: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projectRosters/${id}`,
      }),
      invalidatesTags: ["projectRosters"],
    }),
  }),
});

export const {
  useGetProjectRosterQuery,
  useGetTagProjectRostersQuery,
  useGetMusicianProjectRostersQuery,
  useGetProjectProjectRostersQuery,
  useGetWorkSessionProjectRostersQuery,
  useGetProjectRostersQuery,
  useCreateProjectRosterMutation,
  useDeleteProjectRosterMutation,
  useUpdateProjectRosterMutation,
} = projectRosterEndpoints;

export default projectRosterEndpoints;
