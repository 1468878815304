import { createSelector } from "@reduxjs/toolkit";

const selectCalendarOptions = (state) => state.app.calendarOptions;
const selectDrawerOpen = (s) => s.app.drawerOpen;
const selectApp = (s) => s.app;

export const calendarOptionsSelector = createSelector(
  [selectCalendarOptions],
  (s) => s
);

export const drawerOpenSelector = createSelector(selectDrawerOpen, (s) => s);

export const appSelector = createSelector(selectApp, (s) => s);
