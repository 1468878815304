import {
  Button,
  Chip,
  Typography as JoyTypography,
  Sheet,
  useTheme,
} from "@mui/joy";
import {
  CircularProgress,
  Grid,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridFooter,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Id from "atoms/Id/Id";
import { MercuryWorkSessionGlance } from "features/mercury/MercurySessionGlance/MercurySessionGlance";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFormOpen, setSelectedWorkSessionID } from "reducers/rhapsody";
import { useMusicianAssignments } from "redux/assignment/assignmentHooks";
import { useCompanySettings } from "redux/company/companyHooks";
import { useStages } from "redux/stage/stageHooks";
import { useVenues } from "redux/venue/venueHooks";
import { useUpdateWorkSessionMutation } from "redux/workSession/workSessionEndpoints";
import {
  useActiveWorkSessions,
  useArchivedWorkSessions,
  useCustomerWorkSessions,
  useMusicianWorkSessions,
  usePayrollWorkSessions,
  useProjectWorkSessions,
  useVenueWorkSessions,
} from "redux/workSession/workSessionHooks";

export default function WorkSessionsDataGrid({
  archived,
  active,
  projectID,
  musicianID,
  venueID,
  customerID,
  payrollID,
  pieceID,
  autoheight = true,
  createButton = false,
  bulkActions = false,
}: {
  archived?: boolean;
  active?: boolean;
  projectID?: number;
  musicianID?: number;
  venueID?: number;
  customerID?: number;
  payrollID?: number;
  pieceID?: number;
  createButton?: boolean;
  bulkActions?: boolean;
  autoheight?: boolean;
}) {
  const { activeWorkSessions, isFetching: l1 } = useActiveWorkSessions({
    skip: active === undefined,
  });
  const { stagesMap } = useStages();
  const { archivedWorkSessions, isFetching: l2 } = useArchivedWorkSessions({
    skip: archived === undefined,
  });
  const { customerWorkSessions, isFetching: l3 } =
    useCustomerWorkSessions(customerID);
  const { payrollWorkSessions, isFetching: l4 } =
    usePayrollWorkSessions(payrollID);
  const { projectWorkSessions, isFetching: l5 } =
    useProjectWorkSessions(projectID);
  const { musicianWorkSessions, isFetching: l6 } =
    useMusicianWorkSessions(musicianID);
  const { venueWorkSessions, isFetching: l7 } = useVenueWorkSessions(venueID);
  const [pageSize, setPageSize] = useState(25);
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [selected, setSelected] = useState([]);
  const apiRef = useGridApiRef();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { assignments } = useMusicianAssignments(musicianID);
  const settings = useCompanySettings();

  let rows = [];
  if (active) rows = activeWorkSessions;
  if (archived) rows = archivedWorkSessions;
  if (customerID) rows = customerWorkSessions;
  if (payrollID) rows = payrollWorkSessions;
  if (projectID) rows = projectWorkSessions;
  if (musicianID) rows = musicianWorkSessions;
  if (venueID) rows = venueWorkSessions;

  if (!rows) rows = [];

  const rowsLoading = l1 || l2 || l3 || l4 || l5 || l6 || l7;

  const { venues } = useVenues();
  const dispatch = useDispatch();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id startDecorator={<i className="fa-solid fa-calendar"></i>}>
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "title",
      headerName: "title",
      type: "string",
      width: 180,
      //   renderCell: (p) => (
      //     <JoyTypography
      //       level="body2"
      //       endDecorator={
      //         p.row.projectVersion === 2 ? (
      //           <Chip size="sm" color="warning" variant="soft">
      //             v2 BETA
      //           </Chip>
      //         ) : (
      //           []
      //         )
      //       }
      //     >
      //       {p.row.title}
      //     </JoyTypography>
      //   ),
    },
    {
      field: "description",
      headerName: "Internal Memo",
      type: "string",
      width: 250,
      renderCell: (p) => (
        <Sheet sx={{ background: "rgba(255,235,59,0.1)", p: p.value && 1 }}>
          <JoyTypography level="body3">{p.value}</JoyTypography>
        </Sheet>
      ),
    },
    {
      field: "projectName",
      headerName: "Project",
      type: "string",
      width: 200,
    },
    {
      field: "dateFromUTC",
      headerName: "Date",
      renderCell: (p) => {
        return (
          <JoyTypography
            endDecorator={
              moment(p.row.dateToUTC).isBefore(moment()) ? (
                <JoyTypography level="body4" color="warning">
                  {moment(p.row.dateToUTC).fromNow()}
                </JoyTypography>
              ) : (
                <JoyTypography level="body4" color="success">
                  {moment(p.row.dateToUTC).fromNow()}
                </JoyTypography>
              )
            }
            level="body2"
          >
            {p.row.formatDateRange()}
          </JoyTypography>
        );
      },
      type: "date",
      width: 320,
    },
    {
      field: "venueID",
      headerName: "Venue",
      type: "singleSelect",
      width: 200,
      valueOptions: getOptions(venues),
      valueGetter: (p) => p.row.studioName,
    },
    {
      field: "hiring",
      headerName: "Hiring",
      type: "string",

      renderCell: (p) => <MercuryWorkSessionGlance workSessionID={p.row.id} />,
    },
  ];

  // if (musicianID) {
  //   columns.push({
  //     field: "mercuryJobID",
  //     headerName: "Status",
  //     type: "string",
  //     width: 220,
  //     renderCell: (p) => {
  //       const assignment = assignments.find(
  //         (a) => a.projectID === p.row.projectID
  //       );
  //       const stage = stagesMap[assignment?.mercuryStageID];
  //       if (!stage) return <Box />;
  //       return (
  //         <Chip
  //           size="sm"
  //           sx={{ background: alpha(stage.color, 0.3), color: stage.color }}
  //         >
  //           {stage?.wording()}
  //         </Chip>
  //       );
  //     },
  //   });
  // }

  if (projectID && rows.length === 0) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "50vh", textAlign: "center" }}
      >
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            <i
              style={{ fontSize: 70 }}
              className="fa-duotone fa-music-note"
            ></i>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            Work Sessions
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Any singular date, event, or occasion where Contractors need to
            hire/invite personnel.
            <br />
            Work Session Types include engagements such as rehearsals, concerts,
            or recording sessions.
          </Typography>
          <br />
          <Button
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "workSession" }))
            }
            startDecorator={<i className="fa-solid fa-plus"></i>}
            sx={{ mt: 1 }}
          >
            Add Work Session
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <DataGridPro
        density="compact"
        autoHeight={autoheight ?? false}
        onRowClick={(p) => {
          if (p.row.projectVersion === 2) {
            navigate(
              `${RouterConfig.projects}/${p.row.projectID}/mission-control?workSessionID=${p.row?.id}`
            );
          } else {
            dispatch(setSelectedWorkSessionID(p.row.id));
          }
        }}
        onSelectionModelChange={(e) => {
          setSelected(e);
        }}
        apiRef={apiRef}
        checkboxSelection={!sm}
        rows={rows}
        loading={rowsLoading}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "dateFromUTC",
                sort: settings.workSessionSort === "asc" ? "asc" : "desc",
              },
            ],
          },
        }}
        pageSize={pageSize}
        columns={columns}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
          Footer: GridFooter,
        }}
        columnVisibilityModel={{
          id: !sm,
          description: !sm,
          projectName: !sm,
        }}
        componentsProps={{
          toolbar: {
            setCheckboxSelection,
            checkboxSelection,
            selected,
            archived,
            bulkActions,
            createButton,
          },
        }}
      />
    </>
  );
}

function getOptions(items) {
  const res = [];
  for (const key in items) {
    if (Object.prototype.hasOwnProperty.call(items, key)) {
      const item = items[key];
      res.push({
        value: item.id,
        label: `${item.name}`,
      });
    }
  }

  return res;
}

function CustomToolbar({
  setCheckboxSelection,
  selected,
  tagID,
  archived,
  createButton,
}) {
  const [updateWorkSession, { isLoading: isUpdating }] =
    useUpdateWorkSessionMutation();
  const dispatch = useDispatch();

  const apiRef = useGridApiContext();

  const handleArchive = async () => {
    for (const key in selected) {
      if (Object.hasOwnProperty.call(selected, key)) {
        const workSessionID = selected[key];
        updateWorkSession({
          id: workSessionID,
          body: { archived: !archived },
        });
      }
    }
    apiRef.current.selectRows(selected, false);
    setCheckboxSelection(false);
  };

  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", m: 1 }}>
        {isUpdating ? <CircularProgress size={20} /> : []}
        {selected?.length ? (
          <Button color="primary" variant="outlined" onClick={handleArchive}>
            {!archived ? "Archive" : "Unarchive"} {selected.length} Work Session
            {selected.length > 1 ? "s" : ""}
          </Button>
        ) : (
          []
        )}
        {createButton ? (
          <Button
            onClick={() =>
              dispatch(
                setFormOpen({
                  isOpen: true,
                  formID: "workSession",
                })
              )
            }
            size="sm"
            startDecorator={<i className="fa-solid fa-plus"></i>}
          >
            Add Work Session
          </Button>
        ) : (
          []
        )}
      </Box>
    </Box>
  );
}
