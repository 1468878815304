import { Typography, useMediaQuery, useTheme } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogClose from "atoms/DialogClose/DialogClose";
// material-ui

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import { Box } from "@mui/system";
import {
  formOpenSelector,
  selectedGroupIDSelector,
  setFormOpen,
  setTabIndex,
  setSelectedGroupID,
} from "reducers/rhapsody";
import {
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
} from "redux/group/groupEndpoints";
import { useGroup } from "redux/group/groupHooks";
import { Group_Entity } from "entities/group";
import { Button } from "@mui/joy";

export default function FormGroup() {
  const [loading, setLoading] = useState(false);
  const selectedGroupID = useSelector(selectedGroupIDSelector);
  const { group: _group } = useGroup(selectedGroupID);
  const [createGroup, { isLoading: createLoading }] = useCreateGroupMutation();
  const [deleteGroup, { isLoading: deleteLoading }] = useDeleteGroupMutation();
  const [updateGroup, { isLoading: updateLoading }] = useUpdateGroupMutation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("lg"));
  const formOpen = useSelector(formOpenSelector("group"));
  const [group, setGroup] = useState<Partial<Group_Entity>>({ name: "" });

  useEffect(() => {
    if (_group?.id) {
      setGroup(_group);
    }
  }, [_group]);

  const dispatch = useDispatch();

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setGroup((s) => ({ ...s, [name]: value }));
  };

  const save = async () => {
    setLoading(true);
    if (group?.id) {
      updateGroup({ id: group.id, body: group });
    } else {
      createGroup(group);
    }
    onClose();
  };

  const disabled = group.name.length === 0;

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "group" }));
    dispatch(setSelectedGroupID());
  };

  const onDelete = async () => {
    deleteGroup(group.id);
    dispatch(setFormOpen({ isOpen: false, formID: "group" }));
    dispatch(setTabIndex({ tabID: "musicians", value: 0 }));
  };

  return (
    <DialogClose
      onClose={onClose}
      open={formOpen}
      scroll="paper"
      aria-labelledby="responsive-dialog-title"
    >
      {loading ? (
        <div>
          <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogContent>
            <div
              style={{
                background: "#e3f2fd",
                padding: 16,
                borderRadius: "16px",
                textAlign: "center",
              }}
            >
              <i
                style={{ fontSize: 80, color: "#001E3C" }}
                className="fa-duotone fa-user-group"
              ></i>
              <Typography style={{ color: "#001E3C" }} variant="h6">
                {group?.id ? "Edit" : "New"} Group
              </Typography>
              <Typography
                style={{ color: "#001E3C", opacity: 0.8 }}
                variant="body2"
              >
                A group is a set of musician.
              </Typography>
            </div>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 8 }}
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  autoFocus
                  helperText="Required"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={group.name}
                  onChange={handleChange("name")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {group?.id ? (
              <Button variant="soft" color="danger" onClick={onDelete}>
                Delete
              </Button>
            ) : (
              []
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="soft" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={group.name.length === 0} onClick={save}>
              {group?.id ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </>
      )}
    </DialogClose>
  );
}
