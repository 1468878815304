/**
 * WorkSessionMercury_Entity type guard.
 *
 * @param {any} workSessionMercuryJson WorkSessionMercury object from API
 * @returns {boolean} Return true if type is WorkSessionMercury_Entity
 */

import { Job_Entity } from "entities/job";
import { Pipeline_Entity } from "entities/pipeline";
import { Stage_Entity } from "entities/stage";

export default class WorkSessionMercury_Entity {
  constructor(workSessionMercuryJson: WorkSessionMercury_Entity) {
    try {
      this.id = workSessionMercuryJson.id;
      this.currentStages = workSessionMercuryJson.currentStages;
      this.formerJobs = workSessionMercuryJson.formerJobs;
      this.formerStages = workSessionMercuryJson.formerStages;
      this.upcomingStages = workSessionMercuryJson.upcomingStages;
      this.jobs = workSessionMercuryJson.jobs;
      this.pipeline = workSessionMercuryJson.pipeline;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  currentStages: Stage_Entity[];
  formerJobs: Job_Entity[];
  formerStages: Stage_Entity[];
  upcomingStages: Stage_Entity[];
  jobs: Job_Entity[];
  pipeline: Pipeline_Entity;
}
