import { Box, Popover } from "@mui/material";
import { InstrumentPicker } from "./InstrumentPicker";

/**
 *
 * @returns {ReactElement} InstrumentsPopover page
 */
export function InstrumentsPopover({
  anchorEl,
  onClose,
  onSelect,
  sectionID,
  familyID,
}: {
  anchorEl: HTMLAnchorElement;
  onClose: () => void;
  onSelect: (i: number[]) => void;
  sectionID?: number;
  familyID?: number;
}) {
  const handleOnSelect = (instrumentsIds) => {
    onSelect(instrumentsIds);
    onClose();
  };

  return (
    <Popover
      onClose={onClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box
        sx={{
          width: 500,
          minHeight: 600,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InstrumentPicker
          onSelect={handleOnSelect}
          sectionID={sectionID}
          familyID={familyID}
        />
      </Box>
    </Popover>
  );
}
