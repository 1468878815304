import WorkSessionProjectPiece_Entity from "./workSessionProjectPiece";
export default class WorkSessionProjectPiece extends WorkSessionProjectPiece_Entity {
  static fromList(workSessionProjectPiecesJSON: unknown): Array<WorkSessionProjectPiece> {
    const workSessionProjectPieces: WorkSessionProjectPiece[] = [];
    if (workSessionProjectPiecesJSON)
      Array.isArray(workSessionProjectPiecesJSON) &&
        workSessionProjectPiecesJSON.forEach((workSessionProjectPieceJSON) => {
          workSessionProjectPieces.push(new WorkSessionProjectPiece(workSessionProjectPieceJSON));
        });
    return workSessionProjectPieces;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
