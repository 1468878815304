import { Group } from "entities/group";
import { useMemo } from "react";
import { useGetGroupQuery, useGetGroupsQuery } from "./groupEndpoints";

export function useGroup(groupID: number | undefined) {
  const groupQuery = useGetGroupQuery(groupID ?? 0, {
    skip: (groupID ?? 0) <= 0,
  });
  const groupEntity = groupQuery.data;

  return useMemo(() => {
    const ret: typeof groupQuery & { group?: Group | undefined } = {
      ...groupQuery,
    };
    if (groupEntity) ret.group = new Group(groupEntity);
    return ret;
  }, [groupEntity]);
}

export function useGroups() {
  const groupQuery = useGetGroupsQuery();
  const groupEntitiesMap = groupQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof groupQuery & {
      groups?: Group[];
      groupsMap?: { [id: number]: Group };
    } = {
      ...groupQuery,
      groups: [],
      groupsMap: {},
    };
    if (groupEntitiesMap) {
      const groups = [];
      const groupsMap = {};

      for (const key in groupEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(groupEntitiesMap, key)) {
          const c = groupEntitiesMap[key];
          const group = new Group(c);
          groups.push(group);
          groupsMap[group.id] = group;
        }
      }
      ret.groups = groups;
      ret.groupsMap = groupsMap;
    }

    return ret;
  }, [groupEntitiesMap]);
}
