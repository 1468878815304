import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Box, Skeleton } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Musician } from "entities/musician";
import MusicianAvatar from "features/musicians/MusicianAvatar/MusicianAvatar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedWorkSessionIDSelector,
  setFormOpen,
  workSessionMusiciansIDSelector,
} from "reducers/rhapsody";
import { useWorkSessionActiveJobs } from "redux/job/jobHooks";
import {
  useMusician,
  useWorkSessionMusicians,
} from "redux/musician/musicianHooks";

/**
 *
 * @returns {ReactElement} WorkSessionMusicians page
 */
export function WorkSessionMusicians() {
  const open = useSelector(formOpenSelector("workSessionMusicians"));
  const workSessionMusicianID = useSelector(workSessionMusiciansIDSelector);
  const selectedWorkSessionID = useSelector(selectedWorkSessionIDSelector);
  const workSessionID = workSessionMusicianID ?? selectedWorkSessionID;
  const { musiciansMap, isLoading } = useWorkSessionMusicians(workSessionID);

  const { jobs } = useWorkSessionActiveJobs(workSessionID);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "workSessionMusicians" }));
  };

  const emailAll = (): string => {
    const emails = Object.keys(
      jobs.reduce((a, i) => {
        a[musiciansMap[i.musicianID]?.email] = true;
        return a;
      }, {})
    );
    return `mailto:?bcc=${emails}`;
  };
  return (
    <DialogClose onClose={onClose} open={open}>
      <Box
        sx={{
          minWidth: 380,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "100%",
        }}
      >
        <Sheet
          sx={{
            p: 1,
            position: "sticky",
            top: 0,
            zIndex: 999,
            display: "flex",
            justifyContent: "space-between",
          }}
          variant="soft"
          color="neutral"
        >
          <Typography level="h6">Musicians</Typography>
          <Button
            variant="outlined"
            color="neutral"
            size="sm"
            sx={{ background: "white" }}
            href={emailAll()}
            component={"a"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Email all
          </Button>
        </Sheet>
        <Divider></Divider>
        <List
          sx={{
            flexGrow: 1,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          }}
        >
          {jobs
            ?.filter((j) => j.musicianID)
            ?.sort((a, b) =>
              musiciansMap[a.musicianID]?.lastName?.localeCompare(
                musiciansMap[b.musicianID]?.lastName
              )
            )
            ?.map((j) => (
              <MusicianMenuItem
                key={j.id}
                musician={musiciansMap[j.musicianID]}
              />
            ))}
        </List>
        {isLoading ? (
          <Box sx={{ p: 5, textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          []
        )}
        <Divider></Divider>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            background: "white",
            zIndex: 999,
          }}
        >
          <Divider />
          <Box sx={{ p: 1 }}>
            <Button onClick={onClose} fullWidth color="neutral" variant="soft">
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogClose>
  );
}

function MusicianMenuItem({ musician }: { musician?: Musician }) {
  if (!musician) return <Skeleton sx={{ ml: 2, mr: 2 }} height={42} />;

  return (
    <ListItem sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <MusicianAvatar size={30} musician={musician} />
        <Typography level="body2">{musician?.fullName()}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {musician.email ? (
          <Tooltip title={musician.email}>
            <IconButton
              sx={{ textDecoration: "none" }}
              component={Link}
              href={`mailto:${musician.email}`}
            >
              <i className="fa-solid fa-envelope"></i>
            </IconButton>
          </Tooltip>
        ) : (
          <Box sx={{ width: 40 }} />
        )}
        {musician.phone ? (
          <Tooltip title={musician.phone}>
            <IconButton
              color="success"
              component={Link}
              sx={{ textDecoration: "none" }}
              href={`tel:${musician.phone}`}
            >
              <i className="fa-solid fa-phone"></i>
            </IconButton>
          </Tooltip>
        ) : (
          <Box sx={{ width: 40 }} />
        )}
      </Box>
    </ListItem>
  );
}
