import { SearchMetadata } from "@nerdjs/nerd-core";
import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Email_Entity } from "entities/email";
import { postalApi } from "../api/postalApi";

const emailsAdapter = createEntityAdapter<Email_Entity>();
const initialState = emailsAdapter.getInitialState();

export const emailEndpoints = postalApi.injectEndpoints({
  endpoints: (build) => ({
    getEmails: build.query<
      EntityState<Email_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `emails`,
          params: args,
        };
      },
      transformResponse: (responseData: Email_Entity[]) => {
        return emailsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["emails"],
    }),
    getEmail: build.query<Email_Entity, number>({
      query: (id) => `emails/${id}`,
      providesTags: (result, error, id) => [{ type: "emails", id }],
    }),
    createEmail: build.mutation<Email_Entity, Partial<Email_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `emails`,
      }),
      invalidatesTags: ["emails"],
    }),
    updateEmail: build.mutation<
      void,
      { id: number; body: Partial<Email_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `emails/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "emails", id },
        "emails",
      ],
    }),
    forwardEmail: build.mutation<
      void,
      { id: number; body: Partial<Email_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `emails/${args.id}/forward`,
      }),
    }),
    deleteEmail: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `emails/${id}`,
      }),
      invalidatesTags: ["emails"],
    }),
    getEmailSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `emails/searchMetadata`,
    }),
  }),
});

export const {
  useGetEmailQuery,
  useForwardEmailMutation,
  useGetEmailSearchMetadataQuery,
  useLazyGetEmailsQuery,
  useGetEmailsQuery,
  useCreateEmailMutation,
  useDeleteEmailMutation,
  useUpdateEmailMutation,
} = emailEndpoints;

export default emailEndpoints;
