import Project_Entity from "./project";
export default class Project extends Project_Entity {
  static fromList(projectsJSON: unknown): Array<Project> {
    const projects: Project[] = [];
    if (projectsJSON)
      Array.isArray(projectsJSON) &&
        projectsJSON.forEach((projectJSON) => {
          projects.push(new Project(projectJSON));
        });
    return projects;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
