import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const paydayApi = createApi({
  reducerPath: "paydayApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "payday" }),
  endpoints: () => ({}),
  tagTypes: ["pdProjects", "pdGroups", "pdItems", "pdLabors"],
});
