import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SeasonUtils } from "features/seasons/SeasonUtils";
import { RootState } from "store";

interface SeasonControlState {
  seasonUtils?: SeasonUtils;
  selectedMissionIDs?: number[];
}

const initialState = {
  selectedMissionIDs: [],
} as SeasonControlState;

export const seasonControlSlice = createSlice({
  name: "seasonControl",
  initialState,
  reducers: {
    setSeasonUtils(state, action: PayloadAction<SeasonUtils>) {
      state.seasonUtils = action.payload;
    },
    selectMissions(state, action: PayloadAction<number[]>) {
      const missionIDs = action.payload;
      const selectedMissionIDs = state.selectedMissionIDs;
      missionIDs.forEach((missionID) => {
        if (!selectedMissionIDs.includes(missionID))
          selectedMissionIDs.push(missionID);
      });

      state.selectedMissionIDs = selectedMissionIDs;
    },
    unselectMissions(state, action: PayloadAction<number[]>) {
      const missionIDs = action.payload;
      const selectedMissionIDs = state.selectedMissionIDs;
      missionIDs.forEach((p) => {
        const index = selectedMissionIDs?.findIndex((s) => s === p);
        if (index >= 0) selectedMissionIDs.splice(index, 1);
      });
    },
    unselectedAllMissions(state) {
      state.selectedMissionIDs = [];
    },
  },
});

export const {
  setSeasonUtils,
  selectMissions,
  unselectMissions,
  unselectedAllMissions,
} = seasonControlSlice.actions;

export const seasonUtilsSelector = createSelector(
  (s: RootState) => s.seasonControl.seasonUtils,
  (s) => s
);

export const selectedMissionsSelector = createSelector(
  (s: RootState) => s.seasonControl.selectedMissionIDs,
  (s) => s
);
