/**
 * Internal_Entity type guard.
 *
 * @param {any} internalJson Internal object from API
 * @returns {boolean} Return true if type is Internal_Entity
 */

import { InternalFamily_Entity } from "./InternalFamily";

export default class Internal_Entity {
  constructor(ret: Internal_Entity) {
    this.families = ret.families;
    this.forWorkSessionID = ret.forWorkSessionID;
    this.forProjectPieceID = ret.forProjectPieceID;
  }
  families: InternalFamily_Entity[];
  forWorkSessionID?: number;
  forProjectPieceID?: number;
}
