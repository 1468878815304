import { Alert, Button, Chip, Typography } from "@mui/joy";
import {
  Backdrop,
  Divider,
  Grid,
  Grow,
  Hidden,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import SlidingDialog from "hooks/SlidingDialog/SlidingDialog";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pricingPlanSelector, setPricingPlan } from "reducers/onboarding";
import {
  description420Selector,
  formOpenSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useCurrentSubscription } from "redux/subscription/subscriptionHooks";
import { meSelector } from "selectors/me";
import {
  acceptedPayments,
  additionalWorkSessionBaseRate,
  iconForPlan,
  maxWidth,
  pricingContent,
  pricingHeaders,
  tier1Name,
  tier2Name,
  tier3Name,
} from "./constants";
import { finale, mezzo, overture } from "./restrictionHooks";

/**
 *
 * @returns {ReactElement} Subscribe page
 */
export function Subscribe() {
  const open = useSelector(formOpenSelector("subscribe"));
  const description420 = useSelector(description420Selector);
  const dispatch = useDispatch();

  return (
    <SlidingDialog
      open={open}
      onClose={() =>
        dispatch(setFormOpen({ formID: "subscribe", isOpen: false }))
      }
    >
      <Box
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography level="display2">Subsribe Now</Typography>
        <Typography level="h6">
          You have reached the limit of your current Rhapsody plan. Subscribe to
          unlock.
        </Typography>
        {description420 ? (
          <Alert size="lg" color="warning" variant="solid">
            {description420}
          </Alert>
        ) : (
          []
        )}
        <Plans />
      </Box>
    </SlidingDialog>
  );
}

function Definition({ whatIsIt, icon }) {
  return (
    <Paper
      sx={{
        maxWidth: 500,
        margin: "auto",
        padding: 32,
        position: "relative",
      }}
      variant="outlined"
    >
      <div className="recommanded">
        <Typography level="body3">What is it?</Typography>
      </div>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography level="h2">
          {icon ? <i className={icon}></i> : []}
        </Typography>
      </Box>
      {whatIsIt}
    </Paper>
  );
}

function PricingRow({
  icon,
  title,
  subtitle,
  selected,
  whatIsIt,
  dynamic,
  goldenDynamic,
  select,
  plans = [],
  tabIndex,
  recommanded,
}: any) {
  const me = useSelector(meSelector);
  const [hover, setHover] = React.useState<number>();
  const [workSessionDefinitionOpen, setWorkSessionDefinitionOpen] =
    React.useState(false);
  const pricingPlan = useSelector(pricingPlanSelector);

  const indexForPricingPlan = {
    0: overture,
    1: mezzo,
    2: finale,
  };

  return (
    <>
      <Grid item={true} md={3} xs={6} className="pricingHeader">
        <div>
          <Typography level="body2">{title}</Typography>
          {subtitle ? <Typography level="body3">{subtitle}</Typography> : []}
        </div>
      </Grid>
      {plans.map((p, i) => (
        <Hidden mdDown={Number(i) !== tabIndex} key={p.id}>
          <Grid
            item
            xs={6}
            md={3}
            onMouseEnter={p.selectable ? () => setHover(i) : undefined}
            onMouseLeave={p.selectable ? () => setHover(0) : undefined}
            className={`pricingItem ${
              select && indexForPricingPlan[i] === pricingPlan ? "featured" : ""
            } ${!select && p.recommanded ? "featured" : ""}
            } ${p.selectable ? "selectable" : ""} ${
              selected && p.selectable ? "selected" : ""
            }`}
          >
            <div style={{ textAlign: "center" }}>
              {p.title ? (
                <Typography level="body2">
                  {p.selectable ? (
                    <i
                      className={
                        selected
                          ? "fal fa-minus-circle pright"
                          : "fas fa-dollar-sign amber"
                      }
                    ></i>
                  ) : (
                    []
                  )}
                  {(p.selectable && hover === i) ||
                  (p.selectable && selected) ? (
                    <b className="amber"> {p.title}/Work Session</b>
                  ) : (
                    ""
                  )}
                  {!p.selectable ? p.title : ""}
                </Typography>
              ) : (
                []
              )}
              {p.dynamic ? (
                <Grow in key={dynamic}>
                  <Typography level="body2">{`$${dynamic}/Work Session`}</Typography>
                </Grow>
              ) : (
                []
              )}
              {p.goldenDynamic ? (
                <Grow in key={goldenDynamic}>
                  <Typography level="body2">{`$${goldenDynamic}/Work Session`}</Typography>
                </Grow>
              ) : (
                []
              )}
              {p.subtitle ? (
                <Typography level="body3" sx={{ opacity: 0.58, fontSize: 11 }}>
                  {p.subtitle}
                </Typography>
              ) : (
                []
              )}
              {p.infinite ? (
                <Typography level="body2">
                  <i className="far fa-infinity green"></i>
                </Typography>
              ) : (
                []
              )}
              {p.cross ? (
                <Typography level="body2">
                  <i className="fas fa-times" style={{ color: "#f44336" }}></i>
                </Typography>
              ) : (
                []
              )}
              {p.checked ? (
                <Typography level="body1">
                  <i className="fas fa-check green"></i>
                </Typography>
              ) : (
                []
              )}
            </div>
          </Grid>
        </Hidden>
      ))}
      <Backdrop
        sx={{
          zIndex: 999999,
        }}
        open={workSessionDefinitionOpen}
        onClick={() => setWorkSessionDefinitionOpen(false)}
      >
        <Definition icon={icon} whatIsIt={whatIsIt} />
      </Backdrop>
    </>
  );
}

function PricingHeader({
  recommanded,
  title,
  description,
  idealFor,
  dynamic,
  buttonText,
  href,
  price,
  priceDescription,
  select,
}: {
  recommanded?: any;
  title?: any;
  description?: any;
  idealFor?: any;
  dynamic?: any;
  buttonText?: any;
  href?: any;
  price?: any;
  priceDescription?: any;
  select?: boolean;
}) {
  const { subscription } = useCurrentSubscription();
  const md = useMediaQuery("(min-width:900px)");
  const selectedPricingPlan = useSelector(pricingPlanSelector);
  const dispatch = useDispatch();
  return (
    <Grid
      item
      xs={12}
      md={3}
      style={{
        border: !md ? "solid 1px #E5E8EC" : undefined,
        borderRadius: !md && 12,
        margin: !md && "16px 0px",
      }}
      className={recommanded ? "pricingRecommandedHeader" : "pricingTopHeader"}
    >
      {recommanded && !select ? (
        <Chip
          sx={{ position: "absolute", top: -12, background: "white" }}
          size="sm"
          variant="outlined"
        >
          {select && selectedPricingPlan === title.toLowerCase()
            ? "Selected"
            : "Recommended"}
        </Chip>
      ) : (
        []
      )}
      <div>
        <Typography
          level="h6"
          startDecorator={iconForPlan[title.toLowerCase()]}
        >
          {title}
        </Typography>
        <br />
        <Typography level="body2" fontWeight="500">
          {idealFor}
        </Typography>
      </div>
      <div style={{ paddingTop: 16 }}>
        {price || dynamic ? (
          <>
            <Typography display="inline-block" level="h4" color="primary">
              {price}
            </Typography>
            <Typography display="inline" level="body2">
              {priceDescription}
            </Typography>
            {dynamic ? (
              <Typography display="inline" level="body2">
                monthly
              </Typography>
            ) : (
              []
            )}
            <Typography sx={{ mt: 1 }} level="body2" display="block">
              <i>{description}</i>
            </Typography>
          </>
        ) : (
          []
        )}
        {select && title ? (
          <Button
            fullWidth
            variant={recommanded ? "solid" : "outlined"}
            size="lg"
            sx={{ mt: 2 }}
            onClick={() => {
              dispatch(setPricingPlan(title.toLowerCase()));
            }}
          >
            Select
          </Button>
        ) : (
          <>
            {buttonText ? (
              <Button
                fullWidth
                variant={recommanded ? "solid" : "outlined"}
                size="lg"
                sx={{ mt: 2 }}
                onClick={() => {
                  window.open(
                    `${href}?prefilled_email=${subscription.email}`,
                    "_blank"
                  );
                }}
              >
                {buttonText}
              </Button>
            ) : (
              <Button sx={{ opacity: 0, pointerEvents: "none" }} />
            )}
          </>
        )}
      </div>
    </Grid>
  );
}

export function Plans({ select }: { select?: boolean }) {
  const [tabIndex, setTabIndex] = React.useState(1);
  const selectedPricingPlan = useSelector(pricingPlanSelector);
  const dynamicPremiumWorkSessionPrice = additionalWorkSessionBaseRate;
  const creditCost = additionalWorkSessionBaseRate;

  const md = useMediaQuery("(min-width:900px)");

  return (
    <div style={{ background: "white" }}>
      <div
        style={{
          maxWidth: maxWidth,
        }}
        className="pricingContainer"
      >
        <Hidden mdUp>
          <Tabs
            value={tabIndex}
            onChange={(e, v) => setTabIndex(v)}
            aria-label="basic tabs example"
            className="tabs"
          >
            <Tab style={{ flex: 1 }} label={tier1Name} />
            <Tab style={{ flex: 1 }} label={tier2Name} />
            <Tab style={{ flex: 1 }} label={tier3Name} />
          </Tabs>
        </Hidden>
        <Grid
          container
          alignItems="stretch"
          style={{ position: md ? "sticky" : undefined, top: -260 }}
        >
          {pricingHeaders.map((h, i) => (
            <Hidden mdDown={Number(i - 1) !== tabIndex} key={h.id}>
              <PricingHeader
                select={select}
                {...h}
                recommanded={
                  select && selectedPricingPlan === h.title.toLowerCase()
                }
              />
            </Hidden>
          ))}
          {md ? (
            <Grid item={true} xs={12}>
              <Divider />
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <Grid container alignItems="stretch">
          {pricingContent.map((p, k) => (
            <>
              {p.content.map((c, i) => (
                <PricingRow
                  {...c}
                  key={c.id}
                  dynamic={creditCost}
                  goldenDynamic={dynamicPremiumWorkSessionPrice}
                  tabIndex={tabIndex}
                  select={select}
                />
              ))}
            </>
          ))}
        </Grid>
        <Divider />
        <div
          style={{
            textAlign: "center",
            fontSize: 11,
            padding: 16,
            opacity: 1,
            color: "rgba(0,0,0,0.5)",
            maxWidth: 850,
            margin: "auto",
            marginTop: 16,
            background: "white",
          }}
        >
          <Grid container justifyContent="center">
            {acceptedPayments.map((p) => (
              <Grid item={true} key={p.tooltip}>
                <Tooltip title={p.tooltip}>
                  <Typography level="h4" style={{ padding: 8 }}>
                    <i className={p.iconClass}></i>
                  </Typography>
                </Tooltip>
              </Grid>
            ))}
            <Grid item={true} xs={12} style={{ textAlign: "center" }}>
              <Typography level="body2" fontSize="inherit">
                Rhapsody partners with Stripe for simplified billing.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
