import { Button, Input, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";
import { useSections } from "redux/section/sectionHooks";

/**
 *
 * @returns {ReactElement} DefaultString page
 */
export function DefaultString() {
  const open = useSelector(formOpenSelector("defaultStrings"));
  const dispatch = useDispatch();
  const { company } = useCurrentCompany();
  const settings = useCompanySettings();
  const [updateCompany] = useUpdateCurrentCompanyMutation();
  const [defaultStrings, setDefaultString] = useState([
    "11",
    "11",
    "8",
    "8",
    "6",
  ]);
  const { sectionsMap } = useSections();

  const onClose = () => {
    dispatch(setFormOpen({ formID: "defaultStrings", isOpen: false }));
  };

  useEffect(() => {
    if (settings.defaultStrings)
      setDefaultString(settings.defaultStrings.map((e) => `${e}`));
  }, [settings]);

  const save = () => {
    updateCompany({
      ...company,
      settings: JSON.stringify({
        ...settings,
        defaultStrings: defaultStrings.map((e) => parseInt(e)),
      }),
    });
    onClose();
  };

  return (
    <DialogClose open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography level="h6">Default Strings</Typography>
        <Typography level="body2">
          Quickly set your string chairs when creating a new Project Piece or
          while importing from the Daniels database.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        {defaultStrings.map((s, i) => (
          <Input
            key={i}
            startDecorator={`${sectionsMap[i + 1]?.name}:`}
            type="number"
            slotProps={{
              input: { sx: { textAlign: "right" } },
            }}
            value={defaultStrings[i]}
            onChange={(e) =>
              setDefaultString((s) => {
                s[i] = e.target.value;
                return [...s];
              })
            }
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}
