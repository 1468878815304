import {
  Alert,
  Button,
  Chip,
  IconButton,
  Input,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { ClickAwayListener, Grid, Paper, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import { Chair_Entity } from "entities/chair";
import hotkeys from "hotkeys-js";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  layoutHoverSectionID,
  layoutInternalSelector,
  layoutReorderingSelector,
  layoutSetInternal,
  layoutSetMoveToSectionID,
  layoutSetReordering,
  layoutUnselectAll,
} from "reducers/layout";
import { useFamilies } from "redux/family/familyHooks";
import { useSections } from "redux/section/sectionHooks";
import { useStages } from "redux/stage/stageHooks";
import { useLongPress } from "use-long-press";
import GroupActions from "./GroupActions/v1";
import { GroupSelection } from "./GroupSelection/v1";
import { Internal, LayoutProps, Section } from "./types";

/**
 * This is a simple wrapper that ensures families and sections are fetched from the API before rendering the LayoutComponent
 */
export function Layout(a: LayoutProps) {
  const { isLoading: l1 } = useSections();
  const { isLoading: l2 } = useFamilies();
  const { isLoading: l3 } = useStages();

  if (l1 || l2 || l3) return <div />;

  return <LayoutComponent {...a} />;
}

/**
 *
 * @returns {ReactElement} Layout page
 */
export function LayoutComponent({
  sections,
  renderChair,
  onOrderChange,
  onDeleteSection,
  chairType,
  layers = 1,
  onCreateChairs,
  enableCreateAltChairs = false,
}: LayoutProps) {
  const { sectionsMap } = useSections();
  const { familiesMap } = useFamilies();
  const longPress = useLongPress(() => {
    dispatch(layoutUnselectAll());
    dispatch(layoutSetReordering(true));
  });
  const reordering = useSelector(layoutReorderingSelector);
  const _longPress = reordering ? () => ({}) : longPress;
  const internal = useSelector(layoutInternalSelector);
  const [initial, setInital] = useState<Internal>([]);
  const [collapseMap, setCollapseMap] = useState({});
  const ref = useRef();
  const { stagesMap } = useStages();
  const dispatch = useDispatch();

  hotkeys("esc", function (event) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault();
    dispatch(layoutSetReordering(false));
    dispatch(layoutSetMoveToSectionID(null));
    dispatch(layoutUnselectAll());
  });

  useEffect(() => {
    if (sections?.length && initial.length === 0) {
      const _internal = getInternal(sections);
      setInital(_internal);
    }
  }, [sections]);

  useEffect(() => {
    if (sections?.length) {
      const _internal = getInternal(sections);
      dispatch(layoutSetInternal(_internal));
    }
  }, [sections]);

  useEffect(() => {
    return () => {
      dispatch(layoutSetInternal(null));
    };
  }, []);

  const getInternal = (s: Section[]) => {
    const ret: Internal = [];
    // to prevent displaying duplicate sections
    const alreadyAddedSection = {};
    JSON.parse(JSON.stringify(s)).forEach((s) => {
      const sectionObj = sectionsMap[s.sectionID];
      const familyObj = familiesMap[sectionObj.familyID];
      const familyIndex = ret?.findIndex((f) => f.familyID === familyObj.id);
      const chairs: Chair_Entity[] = [
        ...(s.jobs ?? []),
        ...(s.projectMusicians ?? []),
        ...(s.chairs ?? []),
      ];
      let emptyChairIndex = 0;
      chairs.forEach((c) => {
        if (!c.musicianID || c.musicianID === -1) {
          c.emptyChairIndex = emptyChairIndex;
          emptyChairIndex++;
        } else {
          c.emptyChairIndex = undefined;
        }
      });

      const section = {
        id: s.id,
        sectionID: s.sectionID,
        sectionPos: sectionObj.pos,
        chairs,
      };
      if (familyIndex >= 0) {
        if (!alreadyAddedSection[section.sectionID]) {
          ret[familyIndex].sections.push(section);
          alreadyAddedSection[section.sectionID] = true;
        }
      } else {
        ret.push({
          familyID: familyObj.id,
          familyPos: familyObj.pos,
          sections: [section],
        });
      }
    });

    ret.sort((a, b) => a.familyPos - b.familyPos);
    ret.forEach((f) => f.sections.sort((a, b) => a.sectionPos - b.sectionPos));

    return ret;
  };

  function getContent({ layer, layers }: { layer: number; layers: number }) {
    const last = layer === layers - 1;
    const first = layer === 0;
    let _internal = initial;
    if (last) _internal = internal;
    if (!_internal) return <div />;
    return (
      <Box
        id="background"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          flexGrow: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          cursor: reordering ? "pointer" : "crosshair",
          userSelect: "none",
        }}
      >
        <DragDropContextWrapper
          disabled={!last || !reordering}
          onOrderChange={onOrderChange}
        >
          <>
            {_internal.map((f, familyIndex) => (
              <Sheet
                sx={{
                  p: 2,
                  borderRadius: "8px",
                  background: first ? "#F6F8FA" : "none",
                  borderColor: first ? undefined : "transparent",
                }}
                variant={"outlined"}
                key={f.familyID}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography level="h6" sx={{ opacity: first ? 1 : 0 }}>
                    {familiesMap[f.familyID]?.name}
                  </Typography>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    onClick={() => {
                      setCollapseMap((s) => ({
                        ...s,
                        [f.familyID]: !(s[f.familyID] ?? true),
                      }));
                    }}
                  >
                    <i
                      className={
                        collapseMap[f.familyID] ?? true
                          ? "fa-solid fa-chevron-up"
                          : "fa-solid fa-chevron-down"
                      }
                    ></i>
                  </IconButton>
                </Box>
                {collapseMap[f.familyID] ?? true ? (
                  <Grid
                    container
                    spacing={1}
                    alignItems="stretch"
                    sx={{ minHeight: 50 }}
                  >
                    {f.sections?.map((s, sectionIndex) => (
                      <Grid item key={s.sectionID} sx={{ height: "100%" }}>
                        <Typography
                          level="body2"
                          sx={{ opacity: first ? 1 : 0 }}
                        >
                          {sectionsMap[s.sectionID]?.name}
                        </Typography>
                        <DroppableWrapper
                          disabled={!last || !reordering}
                          familyID={f.familyID}
                          sectionID={s.sectionID}
                          droppableId={`${familyIndex}#${sectionIndex}#`}
                        >
                          <Box
                            sx={{
                              background:
                                !last || !reordering
                                  ? "rgba(0,255,255,255, 0.1)"
                                  : "",
                            }}
                          >
                            {onDeleteSection && s.chairs.length === 0 ? (
                              <Paper
                                sx={{
                                  width: 200,
                                  textAlign: "center",
                                  p: 1,
                                  background: "none",
                                  mt: 1,
                                }}
                                variant="outlined"
                              >
                                <Typography level="body3">
                                  <i>This section is empty!</i>
                                </Typography>
                                <Chip
                                  size="sm"
                                  color="neutral"
                                  onClick={() => {
                                    onDeleteSection(s.id);
                                  }}
                                >
                                  Remove
                                </Chip>
                              </Paper>
                            ) : (
                              []
                            )}
                            {s.chairs.map((chair, chairIndex) => (
                              <DraggableWrapper
                                key={chair.id}
                                sectionID={s.sectionID}
                                disabled={!last || !reordering}
                                draggableId={`${familyIndex}#${sectionIndex}#${chair.id}`}
                                index={chairIndex}
                                element={chair}
                              >
                                <Box
                                  sx={{
                                    cursor: "default",
                                  }}
                                  {..._longPress()}
                                >
                                  {renderChair({
                                    chair,
                                    layer: reordering ? layer : undefined,
                                    emptyChairIndex: chair.emptyChairIndex,
                                    stagesMap,
                                  })}
                                </Box>
                              </DraggableWrapper>
                            ))}
                          </Box>
                        </DroppableWrapper>
                        {onCreateChairs ? (
                          <AddChairs
                            enableCreateAltChairs={enableCreateAltChairs}
                            onCreateChairs={(e) =>
                              onCreateChairs({
                                [s.sectionID]: {
                                  createCount: e.createCount,
                                  altCount: e.altCount,
                                },
                              })
                            }
                          />
                        ) : (
                          []
                        )}
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  []
                )}
              </Sheet>
            ))}
          </>
        </DragDropContextWrapper>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={reordering}
        >
          <Alert
            endDecorator={
              <Button
                onClick={() => dispatch(layoutSetReordering(false))}
                color="neutral"
                variant="solid"
              >
                Done Reordering
              </Button>
            }
            variant="solid"
            size="lg"
            color="neutral"
          ></Alert>
        </Snackbar>
      </Box>
    );
  }

  return (
    <>
      <Box
        ref={ref}
        sx={{
          position: "relative",
          flex: 1,
        }}
      >
        {Array.from(Array(!reordering ? 1 : layers).keys()).map((i) =>
          getContent({
            layer: reordering ? i : 0,
            layers: reordering ? layers : 1,
          })
        )}
        {ref?.current ? <GroupSelection reference={ref} /> : []}
      </Box>
      <GroupActions chairType={chairType} />
    </>
  );
}

function AddChairs({
  onCreateChairs,
  enableCreateAltChairs,
}: {
  enableCreateAltChairs: boolean;
  onCreateChairs: ({
    createCount,
    altCount,
  }: {
    createCount: number;
    altCount: number;
  }) => void;
}) {
  const [open, setOpen] = useState(false);
  const [createCount, setCreateCount] = useState<string>("0");
  const [altCount, setAltCount] = useState<string>("0");

  const primeColor = "primary";
  const subColor = "neutral";

  const handleChange = (
    key: "createCount" | "altCount",
    e: React.ChangeEvent<HTMLInputElement> | "plus" | "minus"
  ) => {
    let value;
    if (key === "createCount") value = parseInt(createCount);
    if (key === "altCount") value = parseInt(altCount);
    if (typeof e !== "string") {
      value = parseInt(e.target.value);
    } else {
      switch (e) {
        case "minus":
          value--;
          if (value < 0) value = 0;
          break;
        case "plus":
          value++;
          break;
      }
    }
    if (key === "altCount") setAltCount(`${value}`);
    if (key === "createCount") setCreateCount(`${value}`);
  };

  const total = parseInt(createCount) + parseInt(altCount);

  const handleSubmit = () => {
    setOpen(false);
    onCreateChairs({
      createCount: parseInt(createCount),
      altCount: parseInt(altCount),
    });
    setCreateCount("0");
    setAltCount("0");
  };

  return (
    <Tooltip
      arrow
      open={open}
      color="neutral"
      variant="outlined"
      title={
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: 200,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  startDecorator={<i className="fa-solid fa-chair"></i>}
                  level="body2"
                >
                  Chairs:
                </Typography>
                <Input
                  value={createCount}
                  slotProps={{
                    input: { style: { textAlign: "center" } },
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") handleSubmit();
                  }}
                  autoFocus
                  onChange={(e) => handleChange("createCount", e)}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  type="tel"
                  startDecorator={
                    <IconButton
                      color="neutral"
                      onClick={() => handleChange("createCount", "minus")}
                    >
                      <i className="fa-solid fa-minus"></i>
                    </IconButton>
                  }
                  endDecorator={
                    <IconButton
                      color="neutral"
                      onClick={() => handleChange("createCount", "plus")}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </IconButton>
                  }
                  size="sm"
                  variant={createCount !== "0" ? "solid" : "soft"}
                  color={createCount !== "0" ? primeColor : "neutral"}
                  sx={{ width: 92, textAlign: "center" }}
                />
              </Box>
              {enableCreateAltChairs ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    level="body2"
                    startDecorator={
                      <i className="fa-solid fa-arrow-turn-down-right"></i>
                    }
                  >
                    Subs:
                  </Typography>
                  <Input
                    value={altCount}
                    slotProps={{
                      input: { style: { textAlign: "center" } },
                    }}
                    onKeyPress={(e) => {
                      console.log(e);
                      if (e.key === "Enter") handleSubmit();
                    }}
                    onChange={(e) => handleChange("altCount", e)}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    type="tel"
                    startDecorator={
                      <IconButton
                        color="neutral"
                        onClick={() => handleChange("altCount", "minus")}
                      >
                        <i className="fa-solid fa-minus"></i>
                      </IconButton>
                    }
                    endDecorator={
                      <IconButton
                        color="neutral"
                        onClick={() => handleChange("altCount", "plus")}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </IconButton>
                    }
                    size="sm"
                    variant={altCount !== "0" ? "solid" : "soft"}
                    color={altCount !== "0" ? subColor : "neutral"}
                    sx={{ width: 92, textAlign: "center" }}
                  />
                </Box>
              ) : (
                []
              )}
              <Button
                onClick={handleSubmit}
                type="submit"
                disabled={total === 0}
              >
                Add Chairs
              </Button>
            </Box>
          </form>
        </ClickAwayListener>
      }
    >
      <Box
        onClick={() => setOpen((s) => !s)}
        sx={{
          textAlign: "center",
          p: 1,
          width: 200,
        }}
      >
        <Tooltip title="Add Chairs">
          <IconButton size="sm" color="neutral" variant="outlined">
            <i className="fa-solid fa-plus"></i>
          </IconButton>
        </Tooltip>
      </Box>
    </Tooltip>
  );
}

function DraggableWrapper({
  sectionID,
  disabled,
  children,
  element,
  draggableId,
  index,
}: {
  sectionID: number;
  disabled: boolean;
  children: ReactElement;
  draggableId: string;
  index: number;
  element: Chair_Entity;
}) {
  if (disabled)
    return (
      <Box
        sx={{
          mt: 1,
        }}
      >
        {children}
      </Box>
    );
  return (
    <Draggable key={element.id} draggableId={draggableId} index={index}>
      {(p) => (
        <Box
          sx={{
            mt: 1,
            position: "relative",
          }}
          ref={p.innerRef}
          {...p.draggableProps}
          {...p.dragHandleProps}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Box
            sx={{
              animationDuration: `.${Math.floor(Math.random() * 11) + 20}s`,
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </Draggable>
  );
}

function DroppableWrapper({
  disabled,
  children,
  familyID,
  sectionID,
  droppableId,
}: {
  disabled: boolean;
  children: ReactElement;
  familyID: number;
  sectionID: number;
  droppableId: string;
}) {
  const dispatch = useDispatch();
  if (disabled) return children;
  return (
    <Box
      onMouseEnter={() => {
        dispatch(layoutHoverSectionID(sectionID));
      }}
      onMouseLeave={() => {
        dispatch(layoutHoverSectionID(null));
      }}
    >
      <Droppable droppableId={droppableId} type={`${familyID}_${sectionID}`}>
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            id="layout-conatiner"
          >
            {children}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
}

function DragDropContextWrapper({
  disabled,
  children,
  onOrderChange,
}: {
  disabled: boolean;
  children: ReactElement;
  onOrderChange: (
    elementID: number,
    sectionID: number,
    sectionOrder: number
  ) => void;
}) {
  const dispatch = useDispatch();
  const internal = useSelector(layoutInternalSelector);
  const onDragStart = () => {
    return dispatch(layoutUnselectAll());
  };

  function getIndexes(droppableId) {
    const split = droppableId.split("#");
    return {
      familyIndex: parseInt(split[0]),
      sectionIndex: parseInt(split[1]),
      chairID: parseInt(split[2]),
    };
  }

  const onDragEnd = ({ source, destination }) => {
    if (!destination ?? !source) return;
    const sourceIndexes = getIndexes(source.droppableId);
    const destinationIndexes = getIndexes(destination.droppableId);
    const _internal: Internal = JSON.parse(JSON.stringify(internal));

    // we copy the element we dragged:
    const _element =
      _internal[sourceIndexes.familyIndex].sections[sourceIndexes.sectionIndex]
        .chairs[source.index];

    // we remove the source element:
    _internal[sourceIndexes.familyIndex].sections[
      sourceIndexes.sectionIndex
    ].chairs.splice(source.index, 1);

    // we place the saved element in the new spot:
    _internal[destinationIndexes.familyIndex].sections[
      destinationIndexes.sectionIndex
    ].chairs.splice(destination.index, 0, _element);

    // callback for parent
    onOrderChange(
      _element.id,
      _internal[destinationIndexes.familyIndex].sections[
        destinationIndexes.sectionIndex
      ].sectionID,
      destination.index
    );

    // save in the state
    dispatch(layoutSetInternal(_internal));
  };

  if (disabled) return children;

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {children}
    </DragDropContext>
  );
}
