import React from "react";
import styled from "styled-components";
import SideBase from "./SideBase";
import { variants, AvailableVariants } from "../preferences/variants";

type BackStyles = {
  style?: React.CSSProperties;
  variant?: AvailableVariants;
};

const CardBack = styled(SideBase)<BackStyles>`
  transform: rotateY(180deg);
`;

export type BackProps = {
  children?: any;
} & BackStyles &
  React.HTMLAttributes<HTMLDivElement>;

const Back: React.FC<BackProps> = ({ children, ...props }: BackProps) => {
  return <CardBack {...props}>{children}</CardBack>;
};

export default Back;
export { CardBack as BackCSS };
