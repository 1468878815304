import { Typography } from "@mui/joy";
import { Box } from "@mui/material";
import { ProviderProps, StepType } from "@reactour/tour";
import { Dictionary } from "@reduxjs/toolkit";

export const chairBoardStep: StepType = {
  selector: ".tour-layout",
  highlightedSelectors: [".tour-layout"],
  content: (
    <Box>
      <Typography level="h6" sx={{ color: "inherit" }}>
        Chair Board:
      </Typography>
      <Typography level="body2" sx={{ color: "inherit" }}>
        This is where your Chairs and musicians are displayed.
      </Typography>
    </Box>
  ),
};

export const sideBarStep: StepType = {
  selector: ".tour-sidebar",
  content: (
    <Box>
      <Typography level="h6" sx={{ color: "inherit" }}>
        Selection sidebar:
      </Typography>
      <Typography level="body2" sx={{ color: "inherit" }}>
        List of your Work Sessions and Pieces. Check/Uncheck them: the{" "}
        <b>Chair Board</b> will change accordingly to show you only what's
        relevant.
      </Typography>
    </Box>
  ),
};

export const modesTour: StepType = {
  selector: ".tour-mode",
  content: (
    <Box>
      <Typography level="h6" sx={{ color: "inherit" }}>
        3 Modes:
      </Typography>
      <ul>
        <li>
          <b>View Mode</b>
          <Typography level="body3" sx={{ color: "inherit" }}>
            Read only mode.
          </Typography>
        </li>
        <li>
          <b>Edit Mode</b>
          <Typography level="body3" sx={{ color: "inherit" }}>
            Switch to this mode to edit the structure and manage musicians.
          </Typography>
        </li>
        <li>
          <b>Notify Mode</b>
          <Typography level="body3" sx={{ color: "inherit" }}>
            The mode you need whenever you want to send the call or a
            communication.
          </Typography>
        </li>
      </ul>
    </Box>
  ),
};

export const editModeTour: StepType = {
  selector: ".tour-mode",
  stepInteraction: true,
  content: (
    <Box>
      <Typography level="body2" sx={{ color: "inherit" }}>
        You need to switch to <b>Edit</b> mode
      </Typography>
    </Box>
  ),
};

export const projectCoverTour: StepType = {
  selector: ".tour-cover",
  stepInteraction: true,
  content: (
    <Box>
      <Typography level="body2" sx={{ color: "inherit" }}>
        Click here to upload an image to illustrate your Project.
      </Typography>
    </Box>
  ),
};

export const inviteModeTour: StepType = {
  selector: ".tour-mode",
  stepInteraction: true,
  content: (
    <Box>
      <Typography level="body2" sx={{ color: "inherit" }}>
        You need to switch to <b>Notify</b> mode
      </Typography>
    </Box>
  ),
};

export const viewsTour: StepType = {
  selector: ".tour-view",
  content: (
    <Box sx={{ color: "inherit" }}>
      <Typography level="h6" sx={{ color: "inherit" }}>
        4 Views:
      </Typography>
      <ul>
        <li>
          <b>Genius</b>
          <Typography level="body3" sx={{ color: "inherit" }}>
            Displays dynamicaly the ensemble based on your selected Work
            Sessions and Pieces in the <b>Selection Sidebar</b>.
          </Typography>
        </li>
        <li>
          <b>Pieces</b>
          <Typography level="body3" sx={{ color: "inherit" }}>
            Selected Pieces arranged side by side.
          </Typography>
        </li>
        <li>
          <b>Work Sessions</b>
          <Typography level="body3" sx={{ color: "inherit" }}>
            Selected Work Sessions arranged side by side.
          </Typography>
        </li>
        <li>
          <b>Assignments</b>
          <Typography level="body3" sx={{ color: "inherit" }}>
            Listing of what each musician does for this Project. See at glance
            what's in each call.
          </Typography>
        </li>
      </ul>
    </Box>
  ),
};

export const checklistTour: StepType = {
  selector: ".tour-checklist",
  content: (
    <Box>
      <Typography level="h6" sx={{ color: "inherit" }}>
        Checklist
      </Typography>
      <Typography level="body2" sx={{ color: "inherit" }}>
        This checklist guides you on the steps you should follow to build your
        Project. Click on an item to be guided. At any point, see your progress.
      </Typography>
    </Box>
  ),
};

export const emptyChairTour: StepType = {
  selector: ".tour-empty",
  content: (
    <Box>
      <Typography level="h6" sx={{ color: "inherit" }}>
        This is an empty Chair
      </Typography>
      <Typography level="body2" sx={{ color: "inherit" }}>
        Click on the Assign button and select a musician.
      </Typography>
    </Box>
  ),
};

export const duplicateMusicianTour = (musicianNames: string[]) => {
  return {
    selector: ".tour-duplicate",
    content: (
      <Box>
        <Typography level="h6" sx={{ color: "inherit" }}>
          This chair has an issue
        </Typography>
        <Typography level="body2" sx={{ color: "inherit" }}>
          A musician in this position is playing on another position. Here is
          complete list of duplicates:
          <ul>
            {musicianNames.map((m) => (
              <li key={m}>{m}</li>
            ))}
          </ul>
        </Typography>
      </Box>
    ),
  };
};

export const misplacedMusicianTour = (musicianNames: string[]) => {
  return {
    selector: ".tour-misplaced",
    content: (
      <Box>
        <Typography level="h6" sx={{ color: "inherit" }}>
          This chair has an issue
        </Typography>
        <Typography level="body2" sx={{ color: "inherit" }}>
          A musician in this position is not playing the instrument. Here is
          complete list:
          <ul>
            {musicianNames.map((m) => (
              <li key={m}>{m}</li>
            ))}
          </ul>
        </Typography>
      </Box>
    ),
  };
};

export const roleTour: StepType = {
  selector: ".tour-role",
  content: (
    <Box>
      <Typography level="h6" sx={{ color: "inherit" }}>
        Role
      </Typography>
      <Typography level="body2" sx={{ color: "inherit" }}>
        This where you select the role for this chair.
      </Typography>
    </Box>
  ),
};

export const chairMemoTour: StepType = {
  selector: ".tour-chair-memo",
  content: (
    <Box>
      <Typography level="h6" sx={{ color: "inherit" }}>
        Piece Memo
      </Typography>
      <Typography level="body2" sx={{ color: "inherit" }}>
        Select the chair(s) you wish to include a memo and press this button.
      </Typography>
    </Box>
  ),
};

export const notifyTour: StepType = {
  selector: ".tour-notifier",
  content: (
    <Box>
      <Typography level="h6" sx={{ color: "inherit" }}>
        Notify Musicians
      </Typography>
      <Typography level="body2" sx={{ color: "inherit" }}>
        Select the event you would like to send:
      </Typography>
    </Box>
  ),
};

export const tourScenarios: Dictionary<StepType[]> = {
  "guided-tour": [
    checklistTour,
    chairBoardStep,
    sideBarStep,
    modesTour,
    viewsTour,
  ],
  sidebar: [emptyChairTour, roleTour, chairMemoTour, notifyTour],
};

export const tourConfig: Partial<ProviderProps> = {
  showNavigation: true,
  // showBadge: false,
  showDots: false,
  showPrevNextButtons: true,
  // steps: tourScenarios["guided-tour"],
  // styles: {
  //   popover: (base) => ({
  //     ...base,
  //     "--reactour-accent": "#001e3c",
  //     borderRadius: 10,
  //     background: "#2196f3",
  //     color: "white",
  //   }),
  //   maskArea: (base) => ({ ...base, rx: 10 }),
  //   arrow: (base) => {
  //     return { ...base, color: "white" };
  //   },
  //   maskWrapper: (base) => ({ ...base, color: "#001e3c" }),
  //   badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
  //   controls: (base) => ({ ...base }),
  //   close: (base) => ({
  //     ...base,
  //     right: "auto",
  //     left: 8,
  //     top: 8,
  //     color: "white",
  //   }),
  // },
  onClickClose: ({ setSteps, setIsOpen }) => {
    setIsOpen(false);
    setSteps(tourScenarios["guided-tour"]);
  },
  onClickMask: ({
    setCurrentStep,
    currentStep,
    steps,
    setIsOpen,
    setSteps,
  }) => {
    if (steps) {
      if (currentStep === steps.length - 1) {
        setIsOpen(false);
        setSteps(tourScenarios["guided-tour"]);
      }
      setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1));
    }
  },
};
