/**
 * Mission_Entity type guard.
 *
 * @param {any} missionJson Mission object from API
 * @returns {boolean} Return true if type is Mission_Entity
 */

import { MercuryJob, MercuryJob_Entity } from "entities/mercuryJob";

export default class Mission_Entity {
  constructor(missionJson: Mission_Entity) {
    this.id = missionJson.id;
    this.tagID = missionJson.tagID;
    this.musicianID = missionJson.musicianID;
    this.mercuryJobID = missionJson.mercuryJobID;
    this.publicToken = missionJson.publicToken;
    this.releaseWorkSessionIDs = missionJson.releaseWorkSessionIDs;
    this.releaseExplanation = missionJson.releaseExplanation;
    this.job = missionJson.job;
    this.memo = missionJson.memo;
    this.mapping = missionJson.mapping;
    this.createdAt = missionJson.createdAt;
    this.updatedAt = missionJson.updatedAt;
  }

  id: number;
  tagID: number;
  musicianID: number;
  mercuryJobID: string;
  publicToken: string;
  releaseWorkSessionIDs: string;
  releaseExplanation: string;
  memo: string;
  job: MercuryJob_Entity;
  mapping: Map<string, any>;
  createdAt: string;
  updatedAt: string;
}
