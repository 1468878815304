import { Alert, Box, Button, Chip, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";

/**
 *
 * @returns {ReactElement} RevisionCallInfo page
 */
export function RevisionCallInfo() {
  const open = useSelector(formOpenSelector("revisionCallInfo"));
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "revisionCallInfo" }));
  };

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle>
        <Typography level="h6">About Revision Calls</Typography>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Typography level="body1">
          A Revision Call may be required if the content of the Assignment
          changes after you send the call. There are 2 levels of Revisions:
        </Typography>
        <Alert
          variant="soft"
          color="warning"
          startDecorator={<i className="fa-solid fa-bolt"></i>}
        >
          <Box>
            <Typography sx={{ color: "inherit", fontWeight: 600 }}>
              Important Changes
            </Typography>
            <Typography level="body2" sx={{ color: "inherit" }}>
              Happens when the role, the order, the memo, or the instrument list
              has changed. You are advised to notify the musician about the
              changes.
            </Typography>
            <Chip size="sm" color="warning">
              No actions required by the musician
            </Chip>
          </Box>
        </Alert>
        <Alert
          variant="soft"
          color="danger"
          startDecorator={<i className="fa-solid fa-bolt"></i>}
        >
          <Box>
            <Typography sx={{ color: "inherit", fontWeight: 600 }}>
              Critical Changes
            </Typography>
            <Typography level="body2" sx={{ color: "inherit" }}>
              Happens when the venue, date & time, or the list of Pieces has
              changed. You are{" "}
              <u>
                <b>strongly</b>
              </u>{" "}
              encouraged to notify the musician about the changes.
            </Typography>
            <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
              <Chip size="sm" color="danger">
                The musician needs to Acknowledge
              </Chip>
              <Typography level="body3" sx={{ color: "inherit" }}>
                (Accept, decline, hold buttons)
              </Typography>
            </Box>
          </Box>
        </Alert>
        <Typography level="body1">
          The warning is cleared out after you send a Revision Call to the
          musician. It will reappear if you make a change ensuring a crystal
          clear communication with your musicians.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="neutral" variant="soft" onClick={onClose}>
          Got it!
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
