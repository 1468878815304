import { Input, Menu, MenuItem, Sheet, Typography, useTheme } from "@mui/joy";
import { Box, Divider, InputBase, Tooltip } from "@mui/material";
import { ComparisonOperator, Filters } from "@nerdjs/nerd-core";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { AdvancedSearchBaiscTypes, SearchableProps } from "./types";
import { formatLabel, genericIcons, getTextWidth } from "./utils";

/**
 *
 * @param {SearchableProps} props Component props
 * @returns {ReactElement} Searchable
 */
export function Searchable({
  index,
  definition,
}: SearchableProps): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const filtersString = searchParams.get("filters");
  const filters: Filters = filtersString ? JSON.parse(filtersString) : [];
  const filter = filters[index];

  const onFilterValueChange = (
    value: number | string | boolean | undefined
  ) => {
    if (value != undefined) {
      filters[index].value = value;
      onChange(filters);
    }
  };
  const onFilterComparisonChange = (comparison: ComparisonOperator) => {
    if (comparison != undefined) {
      filters[index].comparison = comparison;
      onChange(filters);
    }
  };

  const onChange = (newFilters: Filters) => {
    setSearchParams((prevState) => {
      prevState.set("filters", JSON.stringify(newFilters));
      prevState.set("offset", "0");
      return prevState;
    });
  };

  const handleRemoveField = () => {
    if (index >= 0) {
      filters.splice(index, 1);
      setSearchParams((prevState) => {
        if (filters.length) {
          prevState.set("filters", JSON.stringify(filters));
        } else {
          prevState.delete("filters");
        }
        prevState.set("offset", "0");
        return prevState;
      });
    }
  };

  const getInteractor = () => {
    if (!definition) return <Typography>{filter.value}</Typography>;
    if (definition.renderInput)
      return definition.renderInput(filter.value, onFilterValueChange);
    switch (definition.type) {
      case "bool":
        return (
          <Comparison
            type={definition.type}
            value={filter.value ? "eq" : "neq"}
            postfix={formatLabel(definition.label).toLowerCase()}
            onChange={(v) => {
              onFilterValueChange(v === "eq");
            }}
          />
        );

      case "int":
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="neutral" level="body2">
              {formatLabel(definition.label)}
            </Typography>
            <Comparison
              type={definition.type}
              value={filter.comparison}
              onChange={(value) => {
                onFilterComparisonChange(value);
              }}
            />
            <Input
              placeholder="Type here..."
              variant="plain"
              autoFocus
              type="number"
              size="sm"
              value={`${filter.value}`}
              onChange={(e) => {
                onFilterValueChange(parseInt(e.target.value));
              }}
            />
          </Box>
        );
      case "string":
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="neutral" level="body2">
              {formatLabel(definition.label)}
            </Typography>
            <Comparison
              type={definition.type}
              value={filter.comparison}
              onChange={(value) => {
                onFilterComparisonChange(value);
              }}
            />
            <InputBase
              placeholder={"Type here..."}
              autoFocus
              value={`${filter.value}`}
              sx={{
                fontSize: 14,
                width: getTextWidth(`${filter.value}`),
                minWidth: `${filter.value}`.length === 0 ? 80 : undefined,
              }}
              onChange={(e) => {
                onFilterValueChange(e.target.value);
              }}
            />
          </Box>
        );
      case "date":
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="neutral" level="body2">
              {formatLabel(definition.label)}
            </Typography>
            <Comparison
              type={definition.type}
              value={filter.comparison}
              onChange={(value) => {
                onFilterComparisonChange(value);
              }}
            />
            <Input
              placeholder={"Type here..."}
              variant="plain"
              autoFocus
              size="sm"
              value={`${filter.value}`}
              type="date"
              onChange={(e) => {
                onFilterValueChange(e.target.value);
              }}
            />
          </Box>
        );
      default:
        return <div />;
    }
  };

  let faIcon = "";
  if (definition) {
    faIcon = genericIcons[definition.type];
    if (definition.faIcon) {
      faIcon = definition.faIcon;
    }
    if (definition.getFaIcon) {
      faIcon = definition.getFaIcon(filter.value);
    }
  }

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        color: "text.primary",
        p: 1,
        gap: 1,
        height: 32,
      }}
    >
      {definition ? (
        <Typography level="body2" sx={{ display: "flex", gap: 1 }}>
          <i className={faIcon} />
        </Typography>
      ) : (
        []
      )}
      <Divider orientation="vertical" />
      <Box sx={{ flexGrow: 1 }}>{getInteractor()}</Box>
      <Tooltip title={"Remove this field"}>
        <i
          style={{ color: "inherit", cursor: "pointer" }}
          className="fa-solid fa-xmark"
          onClick={handleRemoveField}
        ></i>
      </Tooltip>
    </Box>
  );
}

type ComparisonProps = {
  type: AdvancedSearchBaiscTypes;
  prefix?: string;
  postfix?: string;
  value: ComparisonOperator;
  onChange: (c: ComparisonOperator) => void;
};

/**
 *
 * @param {ComparisonProps} props Component props
 * @returns {ReactElement} Comparison
 */
function Comparison({
  type,
  value,
  onChange,
  prefix,
  postfix,
}: ComparisonProps): ReactElement {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const config: {
    [k: string]: {
      comparison: ComparisonOperator;
      title: string;
    }[];
  } = {
    string: [
      { comparison: "eq", title: "is" },
      { comparison: "neq", title: "is not" },
      { comparison: "like", title: "is like" },
    ],
    bool: [
      { comparison: "eq", title: "is" },
      { comparison: "neq", title: "is not" },
    ],
    date: [
      { comparison: "eq", title: "is same as" },
      { comparison: "neq", title: "is not" },
      { comparison: "gte", title: "is after" },
      { comparison: "lte", title: "is before" },
    ],
    int: [
      { comparison: "eq", title: "is" },
      { comparison: "neq", title: "is not" },
      { comparison: "gt", title: "is greater than" },
      { comparison: "gte", title: "is greater or equal than" },
      { comparison: "lt", title: "is less than" },
      { comparison: "lte", title: "is less or equal than" },
    ],
  };

  const getOptions = () => {
    const JSX = [];
    for (const key in config[type]) {
      if (Object.prototype.hasOwnProperty.call(config[type], key)) {
        const e = config[type][key];

        JSX.push(
          <MenuItem
            key={e.comparison}
            onClick={() => {
              onChange(e.comparison);
              setAnchorEl(null);
            }}
          >
            {prefix} {e.title} {postfix}
          </MenuItem>
        );
      }
    }
    return JSX;
  };

  return (
    <>
      <Tooltip title={"Change comparison"}>
        <Sheet
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            p: 1,
            color: "text.primary",
            cursor: "pointer",
            background: "rgba(155,155,155,0.0)",
            transition: "background .3s",
            fontSize: 14,
            "&:hover": {
              background: "rgba(155,155,155,0.15)",
            },
          }}
        >
          {prefix}{" "}
          <b style={{ color: theme.palette.primary[600] }}>
            {config[type].find((e) => e.comparison === value)?.title}
          </b>{" "}
          {postfix}{" "}
          <i
            style={{ fontSize: 11 }}
            className="fa-regular fa-chevron-down"
          ></i>
        </Sheet>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {getOptions()}
      </Menu>
    </>
  );
}
