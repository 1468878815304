import { RhapsodyChair } from "entities/rhapsodyChair";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedProjectIDSelector } from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useProject } from "redux/project/projectHooks";
import { Indexes, createWorkbook, downloadExcel } from "../utils";
import { mapToArray } from "helpers";

export function useFormerAssignments() {
  const [trigger, setTrigger] = useState(false);
  const utils = useSelector(layoutUtilsSelector);
  const hProject = utils?.hProject;
  const musiciansMap = utils?.musiciansMap ?? {};
  const stagesMap = utils?.stagesMap ?? {};

  const projectID = useSelector(selectedProjectIDSelector);
  const { project } = useProject(projectID);

  const formerAssignments = mapToArray(utils?.assignmentsMap)?.filter(
    (a) => !a.active
  );

  useEffect(() => {
    if (trigger) {
      setTrigger(false);
      download();
    }
  }, [trigger]);

  function newPage(workbook, name) {
    const worksheet = workbook.addWorksheet(name, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    worksheet.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };

    return worksheet;
  }

  function download() {
    const workbook = createWorkbook();
    const fileName = `${project.name} Former Assignments ${moment().format(
      "lll"
    )}`;

    const former = newPage(workbook, "Former Assignments");

    const indexes = new Indexes(former);

    indexes.text("Musician");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.nextColumn();
    indexes.text("Email");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.nextColumn();
    indexes.text("Last Status");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.nextColumn();
    indexes.text("Message");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.nextColumn();
    indexes.text("Date");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.nextColumn();
    indexes.text("Author");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.nextColumn();
    indexes.goToColumn(0);
    indexes.nextRow();

    formerAssignments.forEach((a) => {
      const job = hProject.jobs.find((j) => j.uuid === a.mercuryJobID);
      const message = job?.currentStage?.inspectors?.find(
        (i) => i.title === "Message"
      )?.message;

      indexes.text(musiciansMap[a.musicianID]?.fullName());
      indexes.width(25);
      indexes.rectangle();
      indexes.nextColumn();
      indexes.width(25);
      indexes.text(musiciansMap[a.musicianID]?.email);
      indexes.rectangle();
      indexes.nextColumn();
      indexes.width(25);
      indexes.text(stagesMap[a.mercuryStageID]?.wording());
      indexes.rectangle();
      indexes.nextColumn();
      indexes.width(18);
      indexes.text(message ?? "");
      indexes.rectangle();
      indexes.nextColumn();
      indexes.width(18);
      indexes.text(moment(job?.currentStage?.createdAt).format("lll"));
      indexes.rectangle();
      indexes.nextColumn();
      indexes.width(18);
      indexes.text(job?.currentStage?.createdBy);
      indexes.rectangle();
      indexes.nextColumn();
      indexes.width(18);

      indexes.goToColumn(0);

      indexes.nextRow();
    });

    downloadExcel(workbook, fileName);
  }

  return () => {
    setTrigger(true);
  };
}
