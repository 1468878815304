import Subscription_Entity from "./subscription";
export default class Subscription extends Subscription_Entity {
  static fromList(subscriptionsJSON: unknown): Array<Subscription> {
    const subscriptions: Subscription[] = [];
    if (subscriptionsJSON)
      Array.isArray(subscriptionsJSON) &&
        subscriptionsJSON.forEach((subscriptionJSON) => {
          subscriptions.push(new Subscription(subscriptionJSON));
        });
    return subscriptions;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
