import MusicianNote_Entity from "./musicianNote";
export default class MusicianNote extends MusicianNote_Entity {
  static fromList(musicianNotesJSON: unknown): Array<MusicianNote> {
    const musicianNotes: MusicianNote[] = [];
    if (musicianNotesJSON)
      Array.isArray(musicianNotesJSON) &&
        musicianNotesJSON.forEach((musicianNoteJSON) => {
          musicianNotes.push(new MusicianNote(musicianNoteJSON));
        });
    return musicianNotes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
