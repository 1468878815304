import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/joy";
import { Slide, useMediaQuery } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SlidingDialog({ children, onClose, open }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <DialogClose
      open={open}
      TransitionComponent={Transition}
      fullScreen
      sx={
        !sm && {
          margin: "70px auto auto auto",
          height: "calc(100% - 40px)",
          borderRadius: "8px",
          maxWidth: "90vw",
          padding: 0,
        }
      }
      onClose={() => (onClose ? onClose() : navigate(-1))}
      aria-describedby="alert-dialog-slide-description"
    >
      {children}
    </DialogClose>
  );
}

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
