import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectNotify_Entity } from "entities/projectNotify";
import { RootState } from "store";

interface NotifierState {
  body: Partial<ProjectNotify_Entity>;
}

const initialState = {
  body: {
    greeting: "",
    messenger: "",
    showRoster: false,
    nudge: false,
    showSessionDetails: true,
    projectNoteIDs: [],
  },
} as NotifierState;

export const notifierSlice = createSlice({
  name: "notifierSlice",
  initialState,
  reducers: {
    setBody(state, action: PayloadAction<Partial<ProjectNotify_Entity>>) {
      state.body = { ...state.body, ...action.payload };
    },
  },
});

export const { setBody } = notifierSlice.actions;

export const notifierBodySelector = createSelector(
  (s: RootState) => s.notifierSlice.body,
  (s) => s
);
