import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { SubRule_Entity } from "entities/subRule";
import { rhapsodyApi } from "../api/rhapsodyApi";

const subRulesAdapter = createEntityAdapter<SubRule_Entity>();
const initialState = subRulesAdapter.getInitialState();

export const subRuleEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getSubRules: build.query<
      EntityState<SubRule_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `subRules`,
          params: args,
        };
      },
      transformResponse: (responseData: SubRule_Entity[]) => {
        return subRulesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["subRules"],
    }),
    getSubRule: build.query<SubRule_Entity, number>({
      query: (id) => `subRules/${id}`,
      providesTags: (result, error, id) => [{ type: "subRules", id }],
    }),
    createSubRule: build.mutation<SubRule_Entity, Partial<SubRule_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `subRules`,
      }),
      invalidatesTags: ["subRules"],
    }),
    updateSubRule: build.mutation<
      void,
      { id: number; body: Partial<SubRule_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `subRules/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "subRules", id },
        "subRules",
      ],
    }),
    deleteSubRule: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `subRules/${id}`,
      }),
      invalidatesTags: ["subRules"],
    }),
  }),
});

export const {
  useLazyGetSubRulesQuery,
  useGetSubRuleQuery,
  useGetSubRulesQuery,
  useCreateSubRuleMutation,
  useDeleteSubRuleMutation,
  useUpdateSubRuleMutation,
} = subRuleEndpoints;

export default subRuleEndpoints;

export const selectSubRulesResult =
  subRuleEndpoints.endpoints.getSubRules.select();

const selectSubRulesData = createSelector(
  selectSubRulesResult,
  (subRulesResult) => subRulesResult.data
);

const store = configureStore({
  reducer: {
    [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllSubRules, selectById: selectSubRuleById } =
  subRulesAdapter.getSelectors<RootState>(
    (state) => selectSubRulesData(state) ?? initialState
  );
