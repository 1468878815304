import { createSelector, Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { WorkSession_Entity } from "entities/workSession";
import { RootState } from "store";

interface WorkSessionsState {
  tmpWorkSessions: Partial<WorkSession_Entity>[];
  rescheduleEvent?: any;
  mode: string;
  workSessionNeeds: Dictionary<number>;
}

const getInitialState = () => {
  let ret;

  try {
    ret = {
      workSessionNeeds: window.localStorage.getItem("workSessionNeeds")
        ? JSON.parse(window.localStorage.getItem("workSessionNeeds"))
        : {},
      tmpWorkSessions: window.localStorage.getItem("tmpWorkSessions")
        ? JSON.parse(window.localStorage.getItem("tmpWorkSessions"))
        : [],
      rescheduleEvent: null,
      mode: window.localStorage.getItem("workSessionMode")
        ? window.localStorage.getItem("workSessionMode")
        : "single",
    } as WorkSessionsState;
  } catch (error) {
    ret = {
      workSessionNeeds: {},
      tmpWorkSessions: [],
      mode: "single",
    };
  }

  return ret;
};

export const workSessionsSlice = createSlice({
  name: "workSessionsSlice",
  initialState: getInitialState(),
  reducers: {
    setTmpWorkSession(
      state,
      action: PayloadAction<{
        index: number;
        workSession: Partial<WorkSession_Entity>;
      }>
    ) {
      const { index, workSession } = action.payload;
      if (workSession.id === undefined) {
        workSession.id = getRandomArbitrary(0, 9999999);
      }
      if (state.tmpWorkSessions.length > index) {
        state.tmpWorkSessions[index] = workSession;
      } else {
        state.tmpWorkSessions = [workSession];
      }

      window.localStorage.setItem(
        "tmpWorkSessions",
        JSON.stringify(state.tmpWorkSessions)
      );
    },
    setTmpWorkSessions(
      state,
      action: PayloadAction<Partial<WorkSession_Entity>[]>
    ) {
      state.tmpWorkSessions = action.payload;
      window.localStorage.setItem(
        "tmpWorkSessions",
        JSON.stringify(action.payload)
      );
    },
    setRescheduleEvent(state, action: PayloadAction<any>) {
      state.rescheduleEvent = action.payload;
    },
    setWorkSessionNeeds(state, action: PayloadAction<any>) {
      state.workSessionNeeds = action.payload;
      window.localStorage.setItem(
        "workSessionNeeds",
        JSON.stringify(action.payload)
      );
    },
    setWorkSessionMode(state, action: PayloadAction<any>) {
      state.mode = action.payload;
      window.localStorage.setItem("workSessionMode", action.payload);
    },
  },
});

export const {
  setWorkSessionNeeds,
  setTmpWorkSession,
  setTmpWorkSessions,
  setRescheduleEvent,
  setWorkSessionMode,
} = workSessionsSlice.actions;

export const tempWorkSessionsSelector = createSelector(
  (s: RootState) => s.workSessionsSlice.tmpWorkSessions,
  (s) => s
);

export const tempWorkSessionSelector = (index) => {
  return createSelector(
    [(s: RootState) => s.workSessionsSlice.tmpWorkSessions],
    (s) => {
      if (s.length > index) return s[index];

      return {};
    }
  );
};

export const workSessionModeSelector = createSelector(
  (s: RootState) => s.workSessionsSlice.mode,
  (s) => s
);

export const workSessionNeedsSelector = createSelector(
  (s: RootState) => s.workSessionsSlice.workSessionNeeds,
  (s) => s
);

export const rescheduleEventSelector = createSelector(
  (s: RootState) => s.workSessionsSlice.rescheduleEvent,
  (s) => s
);

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
