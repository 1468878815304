import {
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Menu,
  MenuItem,
  Sheet,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import { Dictionary } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import { DanielsWork } from "entities/danielsWork";
import { ProjectPiece } from "entities/projectPiece";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { ChairSectionRole } from "features/chair/chairSectionRole/v2";
import {
  CHAIR_WIDTH,
  ChairInstruments,
  PositionBase,
} from "features/chair/v2/position";
import { ProjectPieceAdderItem } from "features/forms/FormProjectPiece/v2/FormProjectPiece";
import { AnimatedList } from "helpers/animation/animatedList";
import { ImportFromDaniels } from "hooks/ImportFromDaniels/ImportFromDaniels";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedProjectIDSelector } from "reducers/rhapsody";
import {
  layoutInternalSelector,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";
import { useCompanySettings } from "redux/company/companyHooks";
import { useFamilies } from "redux/family/familyHooks";
import { useSections } from "redux/section/sectionHooks";
import {
  templateChairsAll,
  templateInputsAll,
  UseTemplate,
} from "./UseTemplate";
import { useLazyGetChairsQuery } from "redux/rhapsodyChair/rhapsodyChairEndpoints";

/**
 *
 * @returns {ReactElement} Instrumentation page
 */

export function InstrumentationRaw({
  sameAs,
  instrumentation,
  daniels,
  onChange,
  pieceName,
  pieceComposer,
}: {
  sameAs?: Partial<ProjectPieceAdderItem>[];
  daniels: DanielsWork;
  instrumentation: Dictionary<Partial<RhapsodyChair_Entity>[]>;
  onChange: (
    e: Dictionary<Partial<RhapsodyChair_Entity>[]>,
    v?: DanielsWork
  ) => void;
  pieceName?: string;
  pieceComposer?: string;
}) {
  const internal = useSelector(layoutInternalSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>();
  const [chairs, setChairs] = useState<
    Dictionary<Partial<RhapsodyChair_Entity>[]>
  >({});
  const [sectionInputs, setSectionInputs] = useState<Dictionary<number>>({});
  const [initailSectionInputs] = useState<Dictionary<number>>({});
  const [open, setOpen] = useState(false);
  const { families } = useFamilies();
  const { sections, sectionsMap } = useSections();
  const [getChairs] = useLazyGetChairsQuery();

  useEffect(() => {
    if (instrumentation) setChairs(instrumentation);
  }, [instrumentation]);

  useEffect(() => {
    resetSectionInputs();
  }, [chairs]);

  const settings = useCompanySettings();
  const utils = useSelector(layoutUtilsSelector);
  const instrumentsMap = utils?.instrumentsMap ?? {};
  const familiesMap = utils?.familiesMap ?? {};
  const sectionRoles = utils?.sectionRoles ?? [];
  const sectionRolesMap = utils?.sectionRolesMap ?? {};
  const projectPieces = utils?.projectPieces ?? [];
  const piecesMap = utils?.piecesMap ?? {};
  const [templateID, setTemplateID] = useState<number>();
  const projectID = useSelector(selectedProjectIDSelector);
  const [selectedFamilyID, setSelectedFamilyID] = useState<number>();
  const [selectedSectionID, setSelectedSectionID] = useState<number>();
  const [searchText, setSearchText] = useState("");
  const total = [];
  for (const key in chairs) {
    if (Object.hasOwnProperty.call(chairs, key)) {
      total.push(...chairs[key]);
    }
  }

  useEffect(() => {
    if (sections.length) {
      setSelectedSectionID(sections[0].id);
    }
  }, [sectionsMap]);

  useEffect(() => {
    if (families.length) {
      setSelectedFamilyID(families[0].id);
    }
  }, [familiesMap]);

  // to display shorthand summary bellow the family name
  const getSummaryForFamily = (familyID: number) => {
    const internalSections = internal?.sections?.filter(
      (s) => s.familyID === familyID
    );
    const sectionNumberMap: Dictionary<number> = {};
    const sectionNumberArray = [];
    let ret = [];
    internalSections?.forEach((s) => (sectionNumberMap[s.sectionID] = 0));

    for (const key in sectionInputs) {
      if (Object.hasOwnProperty.call(sectionInputs, key)) {
        if (sectionsMap[parseInt(key)]?.familyID !== familyID) continue;
        if (sectionNumberMap[key]) {
          sectionNumberMap[key] += sectionInputs[key];
        } else {
          sectionNumberMap[key] = sectionInputs[key];
        }
      }
    }

    for (const key in sectionNumberMap) {
      if (Object.hasOwnProperty.call(sectionNumberMap, key)) {
        if (sectionNumberMap[key] > 0)
          sectionNumberArray.push({
            pos: sectionsMap[parseInt(key)]?.pos,
            value: sectionNumberMap[key],
            sectionID: key,
          });
      }
    }

    sectionNumberArray.sort((a, b) => a.pos - b.pos);
    ret = sectionNumberArray.reduce((a, v) => {
      a.push(
        `${v.value}${
          sectionsMap[v.sectionID]?.shorthand ?? sectionsMap[v.sectionID]?.name
        }`
      );
      return a;
    }, []);
    return ret.join(", ");
  };

  const handleChange =
    (sectionID: number) =>
    (e: React.ChangeEvent<HTMLInputElement> | "plus" | "minus") => {
      let value = sectionInputs[sectionID] ?? 0;
      let diff = 0;
      if (typeof e !== "string") {
        let parsed = parseInt(e.target.value);
        if (parsed > 99) parsed = 99;
        if (isNaN(parsed)) parsed = 0;
        if (parsed >= (initailSectionInputs[sectionID] ?? 0)) {
          value = parsed;
          if (initailSectionInputs[sectionID]) {
            diff = parsed - (sectionInputs[sectionID] ?? 0);
          } else {
            diff =
              parsed -
              (initailSectionInputs[sectionID] ?? 0) -
              (sectionInputs[sectionID] ?? 0);
          }
        }
      } else {
        switch (e) {
          case "minus":
            value--;
            diff = -1;
            if (value < 0) value = 0;
            break;
          case "plus":
            diff = +1;
            value++;
            break;
        }
      }
      const _chairs = { ...chairs };
      if (!_chairs[sectionID]) _chairs[sectionID] = [];
      if (diff > 0) {
        for (let index = 0; index < diff; index++) {
          _chairs[sectionID].push({
            sectionID,
            sectionRoleID: 4,
            workSessionIDs: [],
            id: -1,
            projectID,
            instrumentIDs: sectionsMap[sectionID].defaultInstrumentID
              ? JSON.stringify([sectionsMap[sectionID].defaultInstrumentID])
              : "[]",
          });
        }
      }
      if (diff < 0) {
        _chairs[sectionID].splice(_chairs[sectionID].length + diff, -diff);
      }
      onChange(_chairs);
      // setSectionInputs((e) => ({ ...e, [sectionID]: value }));
    };

  const onClose = () => {
    // onChange(chairs, danielsWork);
    setOpen(false);
  };

  const handleSameAs = (pp: Partial<ProjectPieceAdderItem>) => {
    setAnchorEl(null);
    onChange(pp.instrumentation);
    // const _sectionInputs: Dictionary<number> = {};

    // for (const key in pp.instrumentation) {
    //   if (Object.hasOwnProperty.call(pp.instrumentation, key)) {
    //     const chairs = pp.instrumentation[key];
    //     _sectionInputs[key] = chairs.length;
    //   }
    // }

    // setSectionInputs(_sectionInputs);
  };

  const resetSectionInputs = () => {
    const _sectionInputs: Dictionary<number> = {};
    for (const key in chairs) {
      if (Object.hasOwnProperty.call(chairs, key)) {
        const sectionChairs = chairs[key];
        _sectionInputs[key] = sectionChairs.length;
      }
    }

    setSectionInputs(_sectionInputs);
  };

  const handleSameAsProjectPiece = (projectPiece: ProjectPiece) => {
    setAnchorEl(null);
    const _chairs: RhapsodyChair_Entity[] = JSON.parse(
      JSON.stringify(
        utils.chairs.filter(
          (c) => c.projectPieceID == projectPiece.id && !c.workSessionID
        )
      )
    ).sort((a, b) => a.sectionOrder - b.sectionOrder);
    const _sectionInputs: Dictionary<number> = {};
    const ret: Dictionary<Partial<RhapsodyChair_Entity>[]> = {};

    _chairs.forEach((c) => {
      const item = {
        sectionID: c.sectionID,
        instrumentIDs: c.instrumentIDs,
        sectionRoleID: c.sectionRoleID,
        sectionOrder: c.sectionOrder,
        projectID,
        workSessionIDs: [],
      };
      if (!ret[c.sectionID]) {
        _sectionInputs[c.sectionID] = 1;
        ret[c.sectionID] = [item];
      } else {
        ret[c.sectionID].push(item);
        _sectionInputs[c.sectionID]++;
      }
    });

    onChange(ret);
    // setSectionInputs(_sectionInputs);
  };

  const notDefaultStrings =
    settings.defaultStrings?.reduce((a, v, i) => {
      if (sectionInputs[i + 1] !== v) a = true;
      return a;
    }, false) ?? false;

  console.log("Render Instrumentation");

  return (
    <>
      <DialogClose
        open={open}
        maxWidth={daniels ? "lg" : "md"}
        fullWidth
        classes={{
          paper: "dialog-paper",
        }}
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 0,
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              alignItems: "start",
            }}
          >
            <Box>
              <Typography level="h6">Instrumentation</Typography>
              {pieceName ? (
                <Chip
                  size="sm"
                  variant="outlined"
                  endDecorator={
                    pieceComposer ? (
                      <Typography
                        sx={{ ml: "2px", borderRadius: 12 }}
                        color="primary"
                        variant="soft"
                        level="body4"
                      >
                        {pieceComposer}
                      </Typography>
                    ) : (
                      []
                    )
                  }
                >
                  {pieceName}
                </Chip>
              ) : (
                []
              )}
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              {pieceName ? (
                <ImportFromDaniels
                  name={pieceName}
                  composer={pieceComposer}
                  onChange={(e, v) => {
                    onChange(e, v);
                  }}
                />
              ) : (
                []
              )}
              {sameAs?.length || projectPieces.length ? (
                <Button
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  size="sm"
                  color="neutral"
                  variant="plain"
                  endDecorator={<i className="fa-solid fa-chevron-down"></i>}
                >
                  Same Instrumentation as
                </Button>
              ) : (
                []
              )}
              <Menu
                disablePortal
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {sameAs?.map((pp) => (
                  <MenuItem key={pp.pieceID} onClick={() => handleSameAs(pp)}>
                    <Box>
                      <Typography sx={{ fontWeight: 600 }} level="body1">
                        {pp?.pieceName ?? "Seating Assignment"}
                      </Typography>
                      <Typography level="body2">
                        {pp?.pieceComposer ?? ""}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
                {projectPieces.map((pp) => (
                  <MenuItem
                    key={pp.id}
                    onClick={() => handleSameAsProjectPiece(pp)}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 600 }} level="body1">
                        {piecesMap[pp.pieceID]?.name ?? "Seating Assignment"}
                      </Typography>
                      <Typography level="body2">
                        {piecesMap[pp.pieceID]?.composer ??
                          "Seating Assignment"}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box sx={{ pl: 1, pr: 1, pb: 1 }}>
            <Input
              value={searchText}
              startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
              endDecorator={
                <IconButton
                  onClick={() => setSearchText("")}
                  color="neutral"
                  variant="plain"
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
              }
              onChange={(e) => setSearchText(e.target.value)}
              size="sm"
              variant="soft"
              placeholder="Search for a section..."
            />
          </Box>
          {!searchText ? (
            <Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {daniels ? (
                  <>
                    <Typography level="body3" sx={{ width: 267, ml: 2, mr: 2 }}>
                      Daniels' Orchestral Database info
                    </Typography>
                    <Divider orientation="vertical" />
                  </>
                ) : (
                  []
                )}
                <Typography level="body3" sx={{ width: 160, ml: 2 }}>
                  Families
                </Typography>
                <Divider orientation="vertical" />
                <Typography level="body3" sx={{ flexGrow: 1, ml: 2, mr: 2 }}>
                  Sections
                </Typography>
                <Divider orientation="vertical" />
                <Typography
                  level="body3"
                  sx={{
                    ml: 2,
                    mr: 2,
                    width: CHAIR_WIDTH,
                  }}
                >
                  Chairs
                </Typography>
              </Box>
            </Box>
          ) : (
            []
          )}
          <Divider sx={{ m: 0 }} />
        </DialogTitle>
        <DialogContent sx={{ p: 0, height: 600, display: "flex", mt: 0 }}>
          {daniels ? (
            <Sheet
              invertedColors
              variant="solid"
              color="primary"
              sx={{ width: 300, p: 1, color: "white" }}
            >
              <Box>
                <Typography sx={{ color: "inherit" }} level="body1">
                  <b>{daniels?.title}</b>
                </Typography>
                <List size="sm">
                  <ListItem>
                    <ListItemContent>Composed in: </ListItemContent>
                    <Typography level="body2">
                      {daniels?.composed_from}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemContent>Duration:</ListItemContent>
                    <Typography level="body2">{daniels?.duration}</Typography>
                  </ListItem>
                  <ListItem
                    sx={{ flexDirection: "column", alignItems: "start" }}
                  >
                    <ListItemContent>Shorthand:</ListItemContent>
                    <Typography level="body2">{daniels?.formula}</Typography>
                  </ListItem>
                  <ListItem
                    sx={{ flexDirection: "column", alignItems: "start" }}
                  >
                    <ListItemContent>Percussion Info:</ListItemContent>
                    <Typography level="body2">{daniels?.perc_info}</Typography>
                  </ListItem>
                  {daniels?.additional_info ? (
                    <ListItem
                      sx={{ flexDirection: "column", alignItems: "start" }}
                    >
                      <ListItemContent>Additional Info:</ListItemContent>
                      <Typography level="body2">
                        {daniels?.additional_info}
                      </Typography>
                    </ListItem>
                  ) : (
                    []
                  )}
                </List>
              </Box>
            </Sheet>
          ) : (
            []
          )}
          {!searchText ? (
            <Box
              sx={{
                minHeight: "auto",
                height: "100%",
                overflowY: "auto",
                width: 176,
              }}
            >
              <List sx={{ pt: 0 }}>
                {families.map((f) => (
                  <ListItemButton
                    color={"neutral"}
                    selected={selectedFamilyID === f.id}
                    variant={selectedFamilyID === f.id ? "soft" : "plain"}
                    onClick={() => setSelectedFamilyID(f.id)}
                    key={f.id}
                  >
                    <ListItemContent
                      sx={{
                        minHeight: 32,
                        fontWeight: selectedFamilyID === f.id ? 700 : undefined,
                        color:
                          selectedFamilyID === f.id ? "#4186DE" : undefined,
                      }}
                    >
                      {f.name}
                      <br />
                      <Typography sx={{ color: "inherit" }} level="body4">
                        {getSummaryForFamily(f.id)}
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                ))}
              </List>
            </Box>
          ) : (
            []
          )}
          <Divider orientation="vertical" />
          <Box
            sx={{
              flexGrow: 1,
              minHeight: "auto",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <List sx={{ pt: 0 }}>
              {(searchText
                ? sections.filter(
                    (s) =>
                      s.name.toLowerCase().indexOf(searchText.toLowerCase()) !==
                      -1
                  )
                : sections.filter((s) => s.familyID === selectedFamilyID)
              ).map((s) => {
                let variant: "soft" | "solid" = "soft";
                let color: "neutral" | "primary" = "neutral";

                if (initailSectionInputs[s.id] || sectionInputs[s.id])
                  color = "primary";
                if (
                  sectionInputs[s.id] &&
                  initailSectionInputs[s.id] !== sectionInputs[s.id]
                )
                  variant = "solid";
                return (
                  <ListItemButton
                    key={s.id}
                    color={"neutral"}
                    selected={selectedSectionID === s.id}
                    variant={selectedSectionID === s.id ? "soft" : "plain"}
                    onClick={() => setSelectedSectionID(s.id)}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <ListItemContent
                        sx={{
                          fontWeight:
                            selectedSectionID === s.id ? 700 : undefined,
                          color:
                            selectedSectionID === s.id ? "#4186DE" : undefined,
                        }}
                      >
                        {s.name}
                        {searchText ? (
                          <Typography level="body4">{s.familyName}</Typography>
                        ) : (
                          []
                        )}
                      </ListItemContent>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Input
                        value={sectionInputs[s.id] ?? "0"}
                        slotProps={{
                          input: {
                            style: { textAlign: "center" },
                          },
                        }}
                        onChange={handleChange(s.id)}
                        onFocus={(event) => {
                          event.target.select();
                          setTimeout(() => {
                            event.target.select();
                          }, 200);
                        }}
                        type="tel"
                        startDecorator={
                          <IconButton
                            color="neutral"
                            disabled={
                              (sectionInputs[s.id] ?? 0) <=
                              (initailSectionInputs[s.id] ?? 0)
                            }
                            onClick={() => handleChange(s.id)("minus")}
                          >
                            <i className="fa-solid fa-minus"></i>
                          </IconButton>
                        }
                        endDecorator={
                          <IconButton
                            color="neutral"
                            onClick={() => handleChange(s.id)("plus")}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </IconButton>
                        }
                        size="sm"
                        variant={variant}
                        color={color}
                        sx={{
                          width: 92,
                          textAlign: "center",
                          appearance: "none",
                        }}
                      />
                    </Box>
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
          <Divider orientation="vertical" />
          <Sheet
            variant="soft"
            sx={{
              width: CHAIR_WIDTH + 2 * 16,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              minHeight: "auto",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {chairs[selectedSectionID]?.length ? (
              <>
                <Typography
                  endDecorator={
                    <Typography variant="soft" color="primary" level="body4">
                      +{chairs[selectedSectionID]?.length}
                    </Typography>
                  }
                  level="body2"
                >
                  New Chairs:
                </Typography>
                <AnimatedList animation={"grow"}>
                  {chairs[selectedSectionID].map((c, i) => (
                    <PositionBase
                      height={60}
                      key={c.sectionOrder}
                      leading={
                        <>
                          <ChairSectionRole
                            sectionRoles={sectionRoles}
                            sectionRolesMap={sectionRolesMap}
                            sectionRoleIDs={
                              c.sectionID ? [c.sectionRoleID] : []
                            }
                            onChange={(e) => {
                              const c = { ...chairs };
                              c[selectedSectionID][i] = {
                                ...c[selectedSectionID][i],
                                sectionRoleID: e,
                              };
                              onChange(c);
                            }}
                          />
                        </>
                      }
                      bottom={
                        <ChairInstruments
                          instrumentsMap={instrumentsMap}
                          sectionID={selectedSectionID}
                          onChange={(e) => {
                            const c = { ...chairs };
                            c[selectedSectionID][i] = {
                              ...c[selectedSectionID][i],
                              instrumentIDs: JSON.stringify(e),
                            };
                            onChange(c);
                          }}
                          instrumentIDs={JSON.parse(
                            c.instrumentIDs ? c.instrumentIDs : "[]"
                          )}
                        />
                      }
                    />
                  ))}
                </AnimatedList>
              </>
            ) : (
              []
            )}
          </Sheet>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              onClick={() => {
                setSectionInputs({});
                setChairs({});
                setTemplateID(undefined);
              }}
              endDecorator={<i className="fa-regular fa-delete-left"></i>}
              size="sm"
              color="neutral"
              variant="solid"
            >
              Reset
            </Button>
            <UseTemplate
              templateID={templateID}
              onChange={(e) => {
                getChairs({
                  filters: JSON.stringify([
                    {
                      name: "chairs.projectID",
                      comparison: "eq",
                      value: e,
                    },
                  ]),
                })
                  .unwrap()
                  .then((e) => {
                    const templateChairs = e.ids.reduce((a, v) => {
                      a.push(e.entities[v]);
                      return a;
                    }, []);

                    onChange(templateChairsAll(templateChairs));
                  });
                setTemplateID(e);
              }}
            />
            {settings.defaultStrings && notDefaultStrings ? (
              <Button
                startDecorator={<i className="fa-light fa-violin"></i>}
                onClick={() => {
                  setSectionInputs((e) => ({
                    ...e,
                    1: settings.defaultStrings[0],
                    2: settings.defaultStrings[1],
                    3: settings.defaultStrings[2],
                    4: settings.defaultStrings[3],
                    5: settings.defaultStrings[4],
                  }));
                  setChairs((c) => {
                    settings.defaultStrings.forEach((s, i) => {
                      c[i + 1] = Array.from({ length: s }, (v, i) => i).reduce(
                        (a) => {
                          const sectionID = i + 1;
                          a.push({
                            sectionID,
                            workSessionIDs: [],
                            sectionRoleID: 4,
                            projectID,
                            instrumentIDs: sectionsMap[sectionID]
                              .defaultInstrumentID
                              ? JSON.stringify([
                                  sectionsMap[sectionID].defaultInstrumentID,
                                ])
                              : "[]",
                          });
                          return a;
                        },
                        []
                      );
                    });
                    return { ...c };
                  });
                }}
                size="sm"
                color="neutral"
                variant="soft"
              >
                Use default Strings
              </Button>
            ) : (
              []
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              disabled={total.length === 0}
              onClick={() => {
                onClose();
              }}
            >
              {total.length} Chairs
            </Button>
          </Box>
        </DialogActions>
      </DialogClose>
      <Box onClick={() => setOpen(true)}>
        <Button
          sx={{ color: total.length === 0 ? "#757575" : undefined }}
          color={total.length ? "primary" : "neutral"}
          variant={"plain"}
        >
          {total.length > 0
            ? `${total.length > 0 ? total.length : ""} Chair${
                total.length > 1 ? "s" : ""
              }`
            : "Add Chairs"}
        </Button>
      </Box>
    </>
  );
}
