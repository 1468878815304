import { Family } from "entities/family";
import { useMemo } from "react";
import { useGetFamilyQuery, useGetFamiliesQuery } from "./familyEndpoints";

export function useFamily(familyID: number | undefined) {
  const familyQuery = useGetFamilyQuery(familyID ?? 0, {
    skip: (familyID ?? 0) <= 0,
  });
  const familyEntity = familyQuery.data;

  return useMemo(() => {
    const ret: typeof familyQuery & { family?: Family | undefined } = {
      ...familyQuery,
    };
    if (familyEntity) ret.family = new Family(familyEntity);
    return ret;
  }, [familyEntity]);
}

export function useFamilies(opt?: { skip: boolean }) {
  const familyQuery = useGetFamiliesQuery(null, opt);
  const familyEntitiesMap = familyQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof familyQuery & {
      families?: Family[];
      familiesMap?: { [id: number]: Family };
    } = {
      ...familyQuery,
      families: [],
      familiesMap: {},
    };
    if (familyEntitiesMap) {
      const families = [];
      const familiesMap = {};

      for (const key in familyEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(familyEntitiesMap, key)) {
          const c = familyEntitiesMap[key];
          const family = new Family(c);
          families.push(family);
          familiesMap[family.id] = family;
        }
      }
      ret.families = families;
      ret.familiesMap = familiesMap;

      ret.families.sort((a, b) => a.pos - b.pos);
    }

    return ret;
  }, [familyEntitiesMap]);
}
