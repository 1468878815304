import { Typography } from "@mui/joy";
import { Divider, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useRouteMatch } from "helpers";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import AppSettings from "hooks/AppSettings";
import MyAccountContractorSettings from "hooks/MyAccountContractorSettings";
import MyAccountCredentials from "hooks/MyAccountCredentials";
import { Helmet } from "react-helmet";
import { Link as RouterLink, Route, Routes } from "react-router-dom";
import { Link } from "@mui/joy";
import { OrganizationUsers } from "hooks/OrganizationUsers/OrganizationUsers";
import { Branding } from "hooks/Branding/branding";

export default function MyAccount() {
  const routeMatch = useRouteMatch([
    `/${RouterConfig.myAccount}/credentials`,
    `/${RouterConfig.myAccount}/contractor-settings`,
    `/${RouterConfig.myAccount}/branding`,
    `/${RouterConfig.myAccount}/preferences`,
    `/${RouterConfig.myAccount}/users`,
  ]);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        flexDirection: "column",
        margin: "auto",
        maxWidth: 1600,
      }}
    >
      <Helmet>
        <title>Rhapsody | My Account</title>
      </Helmet>
      <Box>
        <Link
          component={RouterLink}
          to={RouterConfig.myAccount}
          level="body1"
          underline="none"
        >
          Rhapsody
        </Link>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography level="h5">Settings</Typography>
        </Box>
        <MyTabs routeMatch={routeMatch} />
        <Divider />
      </Box>
      <Routes>
        <Route
          path={"credentials"}
          element={
            <Box sx={{ p: 2 }}>
              <MyAccountCredentials />
            </Box>
          }
        ></Route>
        <Route
          path={"contractor-settings"}
          element={
            <Box sx={{ p: 2 }}>
              <MyAccountContractorSettings />
            </Box>
          }
        ></Route>
        <Route
          path={"branding"}
          element={
            <Box sx={{ p: 2 }}>
              <Branding />
            </Box>
          }
        ></Route>
        <Route
          path={"preferences"}
          element={
            <Box sx={{ p: 2 }}>
              <AppSettings />
            </Box>
          }
        ></Route>
        <Route
          path={"users"}
          element={
            <Box sx={{ p: 2 }}>
              <OrganizationUsers />
            </Box>
          }
        ></Route>
      </Routes>
    </Box>
  );
}

function MyTabs({ routeMatch }) {
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Tabs value={currentTab}>
      <Tab
        label="Credentials"
        value={`/${RouterConfig.myAccount}/credentials`}
        to="credentials"
        component={RouterLink}
      />
      <Tab
        label="Contractor Settings"
        value={`/${RouterConfig.myAccount}/contractor-settings`}
        to="contractor-settings"
        component={RouterLink}
      />
      <Tab
        label="Branding"
        value={`/${RouterConfig.myAccount}/branding`}
        to="branding"
        component={RouterLink}
      />
      {/* <Tab
        label="Branding"
        value={`/${RouterConfig.myAccount}/branding`}
        to="contractor-settings"
        component={RouterLink}
      /> */}
      <Tab
        label="Preferences"
        value={`/${RouterConfig.myAccount}/preferences`}
        to="preferences"
        component={RouterLink}
      />
      <Tab
        label="Team Members"
        value={`/${RouterConfig.myAccount}/users`}
        to="users"
        component={RouterLink}
      />
    </Tabs>
  );
}
