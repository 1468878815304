import { Interactor } from "../interactor";
import { Inspector } from "../inspector";
import { Stage } from "../stage";
import { Channel } from "../channel";
import { JobStage } from "entities/jobStage";

/**
 * MercuryMercuryJob_Entity type guard.
 *
 * @param {any} MercuryJobJson MercuryJob object from API
 * @returns {boolean} Return true if type is MercuryMercuryJob_Entity
 */

/**
 * @param MercuryJobJson
 */

export default class MercuryMercuryJob_Entity {
  constructor(MercuryJobJson: MercuryMercuryJob_Entity) {
    try {
      this.id = MercuryJobJson.id;
      this.name = MercuryJobJson.name;
      this.createdAt = MercuryJobJson.createdAt;
      this.uuid = MercuryJobJson.uuid;
      this.pipelineID = MercuryJobJson.pipelineID;
      this.currentStageID = MercuryJobJson.currentStageID;
      this.refID = MercuryJobJson.refID;
      this.groupID = MercuryJobJson.groupID;
      this.stages = MercuryJobJson.stages;
      this.jobStages = MercuryJobJson.jobStages;
      this.channels = MercuryJobJson.channels;
      this.currentStage = MercuryJobJson.currentStage;
      this.currentJobStage = MercuryJobJson.currentJobStage;
      this.validUpcomingStages = MercuryJobJson.validUpcomingStages;
      this.validUpcomingStageIDs = MercuryJobJson.validUpcomingStageIDs;
      this.validInteractors = MercuryJobJson.validInteractors;
      this.activateStageID = MercuryJobJson.activateStageID;
      this.activateInteractorID = MercuryJobJson.activateInteractorID;
      this.activatedBy = MercuryJobJson.activatedBy;
      this.newInspectors = MercuryJobJson.newInspectors;
      this.data = MercuryJobJson.data;
      this.reqEmailBody = MercuryJobJson.reqEmailBody;
      this.emailBody = MercuryJobJson.emailBody;
      this.nudge = MercuryJobJson.nudge;
      this.blank = MercuryJobJson.blank;
      this.version = MercuryJobJson.version;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ MercuryMercuryJob_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  createdAt?: string;
  uuid?: string;
  version?: number;
  pipelineID?: number;
  currentStageID?: number;
  refID?: number;
  groupID?: number;
  stages: Array<Stage>;
  jobStages: Array<JobStage>;
  channels: Array<Channel>;
  currentStage?: JobStage;
  currentJobStage?: JobStage;
  validUpcomingStages: Array<Stage>;
  validUpcomingStageIDs: Array<number>;
  validInteractors: Array<Interactor>;
  activateStageID?: number;
  activateInteractorID?: number;
  activatedBy?: string;
  newInspectors: Array<Inspector>;
  data?: string;
  reqEmailBody?: boolean;
  emailBody?: string;
  nudge?: boolean;
  blank?: boolean;
}
