// material-ui
import { setCentrifugo, websocketMessageReceived } from "actions";
import { Centrifuge } from "centrifuge";
import { CENTRIFUGO_URL } from "config";
// constants
import { CENTRIFUGO_TOKEN } from "config";
import { createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { centrifugoSelector } from "selectors/centrifugo";
// styles

export const CentrifugoContext = createContext({});

export default function Centrifugo({ children }) {
  const dispatch = useDispatch();
  const instance = useSelector(centrifugoSelector);
  useEffect(() => {
    const centrifuge = new Centrifuge(CENTRIFUGO_URL, {
      token: CENTRIFUGO_TOKEN,
    });
    centrifuge.connect();
    centrifuge.on("disconnected", function (ctx) {
      console.log(ctx);
      debugger;
    });
    dispatch(setCentrifugo(centrifuge));
  }, []);

  const getInstance = () => {
    return instance;
  };

  const subscribe = (channel, onMessageReceived) => {
    const subscription = instance?.newSubscription(channel);
    subscription.on("publication", (m) => {
      if (onMessageReceived) onMessageReceived(m);
      dispatch(websocketMessageReceived(m));
    });
    subscription.subscribe();
    return subscription;
  };

  return (
    <CentrifugoContext.Provider
      value={{
        subscribe,
        getInstance,
      }}
    >
      {instance && children}
    </CentrifugoContext.Provider>
  );
}
