import ProjectPieceSection_Entity from "./projectPieceSection";
export default class ProjectPieceSection extends ProjectPieceSection_Entity {
  static fromList(projectPieceSectionsJSON: unknown): Array<ProjectPieceSection> {
    const projectPieceSections: ProjectPieceSection[] = [];
    if (projectPieceSectionsJSON)
      Array.isArray(projectPieceSectionsJSON) &&
        projectPieceSectionsJSON.forEach((projectPieceSectionJSON) => {
          projectPieceSections.push(new ProjectPieceSection(projectPieceSectionJSON));
        });
    return projectPieceSections;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
