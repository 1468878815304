import { isRejectedWithValue } from "@reduxjs/toolkit";
import { setDescription420, setFormOpen } from "reducers/rhapsody";
import { Middleware, MiddlewareAPI } from "redux";
import { store } from "store";

export const middleware420: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const dispatch = store.dispatch;
    if (isRejectedWithValue(action)) {
      const payload = action.payload;
      if (payload?.data?.status === 420) {
        dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));
        dispatch(setDescription420(payload?.data?.description));
      }
    }
    return next(action);
  };
