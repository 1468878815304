import { Job } from "entities/job";
import { WorkSession } from "entities/workSession";
import ProjectHiring_Entity from "./projectHiring";
export default class ProjectHiring extends ProjectHiring_Entity {
  jobs: Job[];
  sessions: WorkSession[];

  constructor(projectHiringJson: ProjectHiring_Entity) {
    super(projectHiringJson);

    this.jobs = Job.fromList(projectHiringJson.jobs);
    this.sessions = WorkSession.fromList(projectHiringJson.sessions);
  }

  static fromList(projectHiringsJSON: unknown): Array<ProjectHiring> {
    const projectHirings: ProjectHiring[] = [];
    if (projectHiringsJSON)
      Array.isArray(projectHiringsJSON) &&
        projectHiringsJSON.forEach((projectHiringJSON) => {
          projectHirings.push(new ProjectHiring(projectHiringJSON));
        });
    return projectHirings;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
