/**
 * Selecto_Entity type guard.
 *
 * @param {any} selectoJson Selecto object from API
 * @returns {boolean} Return true if type is Selecto_Entity
 */

export default class Selecto_Entity {
  constructor(selectoJson: Selecto_Entity) {
    try {
      this.positionIDs = selectoJson.positionIDs;
    } catch (e) {
      console.log(`Failed to create new instance of ${Selecto_Entity.name}`);
    }
  }

  positionIDs?: string[];
}
