import { CircularProgress, Input, Sheet, Typography } from "@mui/joy";
import Grid from "@mui/material/Grid";
import { NerdPhoneField } from "@nerdjs/nerd-ui";
// material-ui

// custom
import Id from "atoms/Id/Id";
import { Company_Entity } from "entities/company";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useEffect, useState } from "react";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import { useCurrentCompany } from "redux/company/companyHooks";

export default function MyAccountContractorSettings({ variant }) {
  const { company } = useCurrentCompany();
  const readOnly = useReadOnly();
  const [_company, setCompany] = useState<Partial<Company_Entity>>();
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (company) {
      setCompany(company);
    }
  }, [company]);

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;
    setCompany((m) => ({ ...m, [name]: value }));
    setHasChanged(true);
  };

  const save = async () => {
    updateCurrentCompany({ ..._company });
  };

  const onboarding = variant === "onboarding";

  if (!_company) return <CircularProgress />;

  return (
    <Grid
      container
      spacing={2}
      alignItems="stretch"
      sx={{ maxWidth: !onboarding && 900, margin: "auto" }}
    >
      <Grid item xs={12} md={6}>
        <Sheet variant="soft" sx={{ p: 2, borderRadius: "8px" }}>
          <Typography level="h6" gutterBottom>
            Me as a Contractor
          </Typography>
          <Typography
            level="body2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Company ID:{" "}
            <Id startDecorator={<i className="fa-solid fa-building"></i>}>
              {company.id}
            </Id>
          </Typography>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Input
                disabled={readOnly}
                fullWidth
                onBlur={save}
                id="contact"
                startDecorator="Contractor Name:"
                value={_company.contact}
                onChange={handleChange("contact")}
              />
              <Typography level="body3">
                This name will appear in your communications and payroll forms.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={readOnly}
                fullWidth
                onBlur={save}
                id="contact"
                startDecorator="Username:"
                value={_company.userName}
                onChange={handleChange("userName")}
              />
              <Typography level="body3">
                All your mail communications will be sent using the address{" "}
                {_company.userName || "venue"}@rhapsody.la
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={readOnly}
                fullWidth
                id="email"
                onBlur={save}
                startDecorator="Contractor Email:"
                value={_company.email}
                onChange={handleChange("email")}
              />
              <Typography level="body3">
                This email will be used as the reply-to address.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <NerdPhoneField
                value={_company?.phone ?? ""}
                onAccept={(e) =>
                  handleChange("phone")({ target: { value: e } })
                }
                textFieldProps={{
                  fullWidth: true,
                  variant: "outlined",
                  onBlur: save,
                  size: "small",
                  label: "Phone",
                  disabled: readOnly,
                }}
              />
              <Typography level="body3">
                This phone will be displayed your communications.
              </Typography>
            </Grid>
          </Grid>
        </Sheet>
      </Grid>
      <Grid item xs={12} md={6}>
        <Sheet variant="soft" sx={{ p: 2, borderRadius: "8px" }}>
          <Typography level="h6" gutterBottom>
            Company Info
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>
                <Grid container>
                  {/* <Grid item xs={12}>
                    {_company?.logoURL && (
                      <>
                        <img
                          src={_company?.logoURL}
                          alt="Your Logo"
                          style={{
                            width: "100%",
                            border: "solid 1px rgba(155,155,155,0.3)",
                            borderRadius: "16px",
                          }}
                        />
                        <br />
                      </>
                    )}
                    <FileUpload
                      cropDimension={{ width: 460, height: 90 }}
                      onUpload={(f) => {
                        setCompany((c) => {
                          if (c) return { ...c, logoURL: f };
                          return c;
                        });
                      }}
                    />
                    <Typography level="body2">
                      Upload the Logo that will appear by default on Work
                      Session Invites and also on the Roster.
                    </Typography>
                  </Grid> */}
                  <Grid item xs={12}></Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={readOnly}
                fullWidth
                onBlur={save}
                id="companyName"
                startDecorator="Company Name:"
                value={_company.name}
                onChange={handleChange("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={readOnly}
                variant="outlined"
                id="address"
                onBlur={save}
                startDecorator="Address:"
                value={_company.address}
                onChange={handleChange("address")}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                variant="outlined"
                disabled={readOnly}
                id="city"
                onBlur={save}
                startDecorator="City:"
                value={_company.city}
                onChange={handleChange("city")}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={readOnly}
                fullWidth
                id="zipcode"
                onBlur={save}
                startDecorator="Zipcode:"
                value={_company.zipcode}
                onChange={handleChange("zipcode")}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={readOnly}
                fullWidth
                id="state"
                onBlur={save}
                startDecorator="State:"
                value={_company.state}
                onChange={handleChange("state")}
              />
            </Grid>
          </Grid>
        </Sheet>
      </Grid>
    </Grid>
  );
}
