import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedWorkSessionIDSelector, setFormOpen } from "reducers/rhapsody";
import { useMusicians } from "redux/musician/musicianHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { useWorkSession } from "redux/workSession/workSessionHooks";
import { useWorkSessionSections } from "redux/workSessionSection/workSessionSectionHooks";
import {
  Indexes,
  camelCaseToHumanReadable,
  createWorkbook,
  downloadExcel,
} from "../utils";
import { useFamilies } from "redux/family/familyHooks";
import { Dictionary } from "@reduxjs/toolkit";
import { WorkSessionSection } from "entities/workSessionSection";
import { useSections } from "redux/section/sectionHooks";
import { useDispatch } from "react-redux";
import moment from "moment";

export function useDownloadWorkSessionRosterExcel() {
  const [trigger, setTrigger] = useState(false);
  const [workSessionID, setWorkSessionID] = useState<number>();
  const selectedWorkSessionID = useSelector(selectedWorkSessionIDSelector);
  const { workSession, isSuccess: s1 } = useWorkSession(
    workSessionID ?? selectedWorkSessionID,
    { skip: !trigger }
  );
  const { workSessionSections, isSuccess: s2 } = useWorkSessionSections(
    workSessionID ?? selectedWorkSessionID
  );
  const { musiciansMap, isSuccess: s3 } = useMusicians(undefined, {
    skip: !trigger,
  });
  const dispatch = useDispatch();

  const { sectionRolesMap, isSuccess: s4 } = useSectionRoles();
  const { familiesMap, isSuccess: s5 } = useFamilies();
  const { sectionsMap, isSuccess: s6 } = useSections();

  useEffect(() => {
    if (trigger && s1 && s2 && s3 && s4 && s5 && s6) {
      setTrigger(false);
      download();
    }
  }, [trigger, s1, s2, s3, s4, s5, s6]);

  function download() {
    const workbook = createWorkbook();
    const fileName = `${workSession.title} - Roster`;
    const fmap: Dictionary<WorkSessionSection[]> = workSessionSections.reduce(
      (a, s) => {
        if (a[s.familyID]) {
          a[s.familyID].push(s);
        } else {
          a[s.familyID] = [s];
        }
        return a;
      },
      {}
    );
    const worksheet = workbook.addWorksheet(`Roster`, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    worksheet.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };
    const indexes = new Indexes(worksheet);
    const musicians = [];

    // Work Session Title
    indexes.text(`${workSession.title}`);
    indexes.color({ argb: `FF272838` });
    indexes.fontSize(32);
    indexes.bold();
    indexes.nextRow();

    // Work Session Date
    indexes.text(
      `${moment
        .tz(workSession.dateFromUTC, workSession.tzName)
        .format("dddd, MMMM D, YYYY - h:mmA")} to ${moment
        .tz(workSession.dateToUTC, workSession.tzName)
        .format("h:mmA")}`
    );
    indexes.color({ argb: `FF272838` });
    indexes.fontSize(22);
    indexes.nextRow();

    // Venue
    indexes.text(workSession.studioName);
    indexes.fontSize(18);
    indexes.color({ argb: `FF5B666D` });
    indexes.italic();
    indexes.nextRow();
    indexes.nextRow();

    // Roster content
    for (const familyID in fmap) {
      if (Object.hasOwnProperty.call(fmap, familyID)) {
        const sections = fmap[familyID];
        if (sections.length === 0) continue;
        let maxJob = 0;
        const initialRow = indexes.getRow();
        // indexes.text(familiesMap[familyID]?.name?.toUpperCase());
        // indexes.color({ argb: `FF272838` });
        // indexes.nextRow();

        sections.forEach((s) => {
          const section = sectionsMap[s.sectionID];
          if (s.jobs?.length === 0) return;
          if (maxJob < s.jobs?.length) {
            maxJob = s.jobs?.length;
          }
          indexes.text(section?.name?.toUpperCase());
          indexes.fontSize(14);
          indexes.width(30);
          indexes.underline();
          indexes.color({ argb: `FF272838` });
          indexes.nextRow();

          s.jobs.forEach((j) => {
            const musician = musiciansMap[j.musicianID];
            musicians.push(musician);
            if (musician) {
              indexes.text(
                `${musician.fullName()}${
                  j.sectionRoleID !== 4
                    ? `, ${sectionRolesMap[j.sectionRoleID].name}`
                    : ``
                }`
              );
              if ([2, 5, 6].indexOf(j.mercuryStageID) >= 0) {
                indexes.color({ argb: `FF272838` });
              } else {
                indexes.color({ argb: `FF94A3AD` });
              }
            } else {
              indexes.text("Empty Position");
            }
            indexes.nextRow();
          });
          indexes.goToRow(initialRow);
          indexes.nextColumn();
        });

        indexes.goToColumn(0);
        indexes.goToRow(initialRow + maxJob + 2);
      }
    }

    // musicians data
    musicians.sort((a, b) => a?.lastName?.localeCompare(b?.lastName));
    const worksheet2 = workbook.addWorksheet(`Musicians Data`, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    worksheet2.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };
    const indexes2 = new Indexes(worksheet2);

    const columns = [
      "firstName",
      "middleName",
      "lastName",
      "instrumentNames",
      "email",
      "phone",
      "nickName",
      "companyName",
      "address",
      "city",
      "state",
      "zipcode",
      "comments",
      "birthdate",
      "star",
    ];
    columns.forEach((c) => {
      indexes2.width(25);
      indexes2.text(camelCaseToHumanReadable(c));
      indexes2.header();
      indexes2.nextColumn();
    });

    indexes2.goToColumn(0);
    indexes2.nextRow();

    const _musicians = [...musicians];
    _musicians.sort((a, b) => a.lastName?.localeCompare(b.lastName));

    _musicians.forEach((m) => {
      if (!m) return;
      columns.forEach((c) => {
        indexes2.text(m[c]);
        indexes2.nextColumn();
      });
      indexes2.goToColumn(0);
      indexes2.nextRow();
    });

    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
    downloadExcel(workbook, fileName);
  }

  return (_workSessionID?: number) => {
    setWorkSessionID(_workSessionID);
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setTrigger(true);
  };
}
