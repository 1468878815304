import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { NerdFileUpload } from "@nerdjs/nerd-ui";
import { Dictionary } from "@reduxjs/toolkit";
import { EMAIL_REGEXP } from "config/regexp";
import { Instrument } from "entities/instrument";
import { Musician } from "entities/musician";
import { Worksheet } from "exceljs";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { Indexes, createWorkbook } from "features/exporter/utils";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { noteTypeData } from "features/musicians/MusicianGeneral/MusiciansPrivateNotes";
import fileToArrayBuffer from "file-to-array-buffer";
import { heightWithToolbar } from "global";
import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedMusicianID } from "reducers/rhapsody";
import { rhapsodyApi } from "redux/api";
import { useInstruments } from "redux/instrument/instrumentHooks";
import {
  useCreateMusician2Mutation,
  useDeleteMusicianInstruments2Mutation,
  useUpdateMusician2Mutation,
  useUpdateMusicianInstruments2Mutation,
} from "redux/musician/musicianEndpoints";
import { useMusicians } from "redux/musician/musicianHooks";
import { useCreateMusicianNoteMutation } from "redux/musicianNote/musicianNoteEndpoints";
import { useNoteTypes } from "redux/noteType/noteTypeHooks";

/**
 *
 * @returns {ReactElement} Importer page
 */
export function Importer() {
  const [excelFile, setExcelFile] = useState<File>();
  const [worksheet, setWorksheet] = useState<Worksheet>();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [mapping, setMapping] = useState<Dictionary<string>>({});
  const [ignoreIt, setIgnoreIt] = useState(false);
  const [createMusicianNote] = useCreateMusicianNoteMutation();
  const [createMusician] = useCreateMusician2Mutation();
  const [updateMusician] = useUpdateMusician2Mutation();
  const [deleteMusicianInstrument] = useDeleteMusicianInstruments2Mutation();
  const [importedMusicianEmails, setImportedMusicianEmails] = useState<
    string[]
  >([]);
  const [errorMusicianEmails, setErrorMusicianEmails] = useState<string[]>([]);
  const [importing, setImporting] = useState(0);
  const [updating, setUpdating] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const { musicians } = useMusicians();
  const { noteTypes } = useNoteTypes();
  const { instruments } = useInstruments();
  const [loading, setLoading] = useState<string>("");
  const [updateMusicianInstrument] = useUpdateMusicianInstruments2Mutation();
  const dispatch = useDispatch();
  const askQuestion = useAskQuestion();
  const [outOfSync, setOutOfSync] = useState<
    { musician: Musician; issues: string[]; row: any }[]
  >([]);
  const apiRef = useGridApiRef();

  const reversedMapping = reverseDict(mapping);

  const instrumentByNameMap = useMemo(() => {
    return instruments.reduce<Dictionary<Instrument>>((a, v) => {
      a[v.name?.trim().toLowerCase()] = v;
      return a;
    }, {});
  }, [instruments]);

  const musiciansByEmail = useMemo(() => {
    return musicians.reduce<Dictionary<Musician>>((a, v) => {
      a[v.email?.trim().toLowerCase()] = v;
      return a;
    }, {});
  }, [musicians]);

  useEffect(() => {
    setMapping(
      localStorage.getItem("importerMapping")
        ? JSON.parse(localStorage.getItem("importerMapping"))
        : {}
    );

    setRows(
      localStorage.getItem("importerRows")
        ? JSON.parse(localStorage.getItem("importerRows"))
        : []
    );
  }, []);

  useEffect(() => {
    if (mapping) {
      localStorage.setItem("importerMapping", JSON.stringify(mapping));
    }
  }, [mapping]);

  useEffect(() => {
    if (rows) {
      localStorage.setItem("importerRows", JSON.stringify(rows));
    }
  }, [rows]);

  useEffect(() => {
    if (excelFile) process();
  }, [excelFile]);

  useEffect(() => {
    if (worksheet) {
      buildDataGrid();
    }
  }, [outOfSync]);

  useEffect(() => {
    if (worksheet) {
      genOutOfSync();
      buildDataGrid();
    }
  }, [worksheet, mapping, musicians, loading, rows, ignoreIt]);

  const musicianFields: any[] = [
    {
      field: "email",
      required: true,
      noteType: false,
      name: "Email",
      testValue: (e: string) => {
        return EMAIL_REGEXP.test(e);
      },
      format: (e: string) => {
        console.log("format", ignoreIt);
        if (ignoreIt && e) {
          const parts = e.split("@");
          return `${parts[0]}@ignore.it`.toLowerCase();
        } else {
          return e?.trim()?.toLowerCase();
        }
      },
    },
    {
      field: "firstName",
      required: true,
      noteType: false,
      name: "First Name",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "lastName",
      required: true,
      noteType: false,
      name: "Last Name",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "phone",
      required: false,
      noteType: false,
      name: "Phone",
      testValue: (e: string) => {
        return isValidNumber(`${e}`, "US");
      },
      format: (e: string) => {
        try {
          if (isValidNumber(`${e}`, "US")) {
            const phoneNumber = parsePhoneNumber(`${e}`, "US");
            return phoneNumber.format("E.164");
          }
        } catch (error) {}
        return "";
      },
    },
    {
      field: "middleName",
      required: false,
      noteType: false,
      name: "Middlename",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "nickName",
      required: false,
      noteType: false,
      name: "NickName",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "companyName",
      required: false,
      noteType: false,
      name: "CompanyName",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "birthdate",
      required: false,
      noteType: false,
      name: "Birth Date",
      testValue: (e: string) => e,
      format: (e: string) => (e ? moment(e).utc().format() : ""),
    },
    {
      field: "instruments",
      required: false,
      noteType: false,
      name: "Instruments",
      testValue: (e: string) => {
        try {
          const split = e.split(",");
          let valid = true;
          split.forEach((e) => {
            const instrumentName = e?.trim().toLowerCase();
            if (instrumentByNameMap[instrumentName] === undefined)
              valid = false;
          });
          return valid;
        } catch (error) {}
      },
      format: (e: string) => {
        try {
          const split = e.split(",");
          const ret = [];
          split.forEach((e) => {
            const instrumentName = e?.trim().toLowerCase();
            if (instrumentByNameMap[instrumentName]) ret.push(instrumentName);
          });
          ret.sort((a, b) => a.localeCompare(b));
          return ret.join(",");
        } catch (error) {}
      },
    },
    {
      field: "address",
      required: false,
      noteType: false,
      name: "Address",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "city",
      required: false,
      noteType: false,
      name: "City",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "state",
      required: false,
      noteType: false,
      name: "State",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "zipcode",
      required: false,
      noteType: false,
      name: "Zipcode",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "ssn",
      required: false,
      noteType: false,
      name: "SSN",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "ein",
      required: false,
      noteType: false,
      name: "EIN",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "cardNumber",
      required: false,
      noteType: false,
      name: "Employee Number",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "localNumber",
      required: false,
      noteType: false,
      name: "Union Number",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
    {
      field: "comments",
      required: false,
      noteType: false,
      name: "Comments",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
  ];

  noteTypes.forEach((nt) => {
    const d = noteTypeData.find((e) => e.value === nt.kind);
    musicianFields.push({
      field: nt.id,
      required: false,
      noteType: true,
      name: nt.name,
      testValue: d.testValue,
      format: d.format,
    });
  });

  const process = async () => {
    const workbook = createWorkbook();
    const arrayBuffer = await fileToArrayBuffer(excelFile);
    await workbook.xlsx.load(arrayBuffer);
    const firstWorkSheet = workbook.worksheets[0];
    setWorksheet(firstWorkSheet);
  };

  const format = (field: string) => {
    const musicianField = musicianFields.find(
      (e) => e.field === mapping[field]
    );
    const _rows = [...rows];
    _rows.forEach((r) => (r[field] = musicianField.format(r[field])));
    setRows(_rows);
  };

  const buildDataGrid = async () => {
    const _columns: GridColDef[] = [];
    const _rows = [];
    const indexes = new Indexes(worksheet);

    let processingColumnsFinished = false;
    while (!processingColumnsFinished) {
      const headerValue = await indexes.get();
      if (headerValue) {
        _columns.push({
          field: `${headerValue}`,
          width: 200,
          editable: true,
          renderHeader: (e) => (
            <Box
              sx={{ display: "flex", flex: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {mapping[e.field] ? (
                <Tooltip
                  title="Format"
                  variant="outlined"
                  size="sm"
                  arrow
                  enterDelay={1000}
                  enterNextDelay={1000}
                >
                  <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    onClick={() => {
                      format(e.field);
                    }}
                  >
                    <i className="fa-solid fa-wand-magic-sparkles"></i>
                  </IconButton>
                </Tooltip>
              ) : undefined}
              <Select
                size="sm"
                placeholder={e.field}
                variant={"soft"}
                color={mapping[e.field] ? "success" : "neutral"}
                sx={{ flexGrow: 1 }}
                value={mapping[e.field] ? mapping[e.field] : null}
                onChange={(a, v) => {
                  if (v) setMapping((m) => ({ ...m, [e.field]: v }));
                }}
              >
                {musicianFields
                  .filter((f) => !reversedMapping[f.field])
                  .map((f) => (
                    <Option
                      key={f.field}
                      value={f.field}
                      color={f.noteType ? "info" : undefined}
                      variant={f.noteType ? "soft" : undefined}
                    >
                      {f.noteType ? (
                        <Typography
                          level="body2"
                          endDecorator={
                            <Typography level="body4">Custom Field</Typography>
                          }
                        >
                          {f.name}
                        </Typography>
                      ) : (
                        <Typography
                          level="body2"
                          endDecorator={
                            f.required ? (
                              <Typography color="warning" level="body4">
                                Required
                              </Typography>
                            ) : undefined
                          }
                        >
                          {f.name}
                        </Typography>
                      )}
                    </Option>
                  ))}
              </Select>
            </Box>
          ),
        });
      } else {
        processingColumnsFinished = true;
      }
      indexes.nextColumn();
    }

    indexes.goToColumn(0);
    indexes.goToRow(2);

    let processingRowFinished = false;
    let count = 1;

    if (!rows || rows.length === 0) {
      while (!processingRowFinished) {
        const item = { id: count };
        if (!(await indexes.get())) {
          processingRowFinished = true;
          continue;
        }
        for (const c in _columns) {
          if (Object.prototype.hasOwnProperty.call(_columns, c)) {
            const _column = _columns[c];
            const cell = await indexes.get();
            let text = cell?.toString();
            if (cell && typeof cell === "object") {
              const _cell = cell as any;
              if (typeof _cell.text === "object") {
                text = _cell.text.richText[0].text;
              } else {
                text = _cell?.text;
              }
            }
            item[_column.field] = text;
            indexes.nextColumn();
          }
        }
        _rows.push(item);
        count++;
        indexes.goToColumn(0);
        indexes.nextRow();
      }
    }

    _columns.push({
      field: "action",
      width: 60,
      headerName: "",
      valueGetter: (e) => {
        const email = e.row[reversedMapping["email"]];
        if (!email) return 4;
        if (outOfSync.find((e) => e.musician.email === email)) return 2;
        if (errorMusicianEmails.includes(email)) return 3;
        return 1;
      },
      renderCell: (e) => {
        const email = e.row[reversedMapping["email"]];
        if (errorMusicianEmails.includes(email))
          return (
            <IconButton size="sm" variant="plain" color="danger">
              <i className="fa-solid fa-triangle-exclamation"></i>
            </IconButton>
          );
        if (loading && email === loading) return <CircularProgress size="sm" />;
        if (musiciansByEmail[email]) {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flex: 1,
                position: "relative",
              }}
            >
              {outOfSync.find((e) => e.musician.email === email) ? (
                <i
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    zIndex: 99,
                    background: "#2196f3",
                    color: "white",
                    borderRadius: 20,
                    fontSize: 8,
                    padding: "2px",
                  }}
                  className="fa-solid fa-right-left-large"
                ></i>
              ) : (
                <i
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    zIndex: 99,
                    color: "#2A5C2C",
                    background: "white",
                    borderRadius: 20,
                    fontSize: 11,
                  }}
                  className="fa-solid fa-circle-check"
                ></i>
              )}
              <MusicianAvatar musician={musiciansByEmail[email]} size={22} />
            </Box>
          );
        }
        if (importedMusicianEmails.includes(email)) {
          return (
            <Tooltip
              title="Musician Imported"
              variant="outlined"
              size="sm"
              arrow
              enterDelay={1000}
              enterNextDelay={1000}
            >
              <IconButton
                onClick={() =>
                  dispatch(setSelectedMusicianID(musiciansByEmail[email].id))
                }
                size="sm"
                variant="plain"
                color="success"
              >
                <i className="fa-solid fa-circle-check"></i>
              </IconButton>
            </Tooltip>
          );
        }
        if (!email) return <Box />;
        return (
          <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <Tooltip
              title="Import Now"
              variant="outlined"
              size="sm"
              arrow
              enterDelay={1000}
              enterNextDelay={1000}
              onClick={async () => {
                await importMusician(e.row);
                dispatch(rhapsodyApi.util.invalidateTags(["musicians"]));
              }}
            >
              <IconButton size="sm" variant="plain">
                <i className="fa-solid fa-arrow-down-to-line"></i>
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    });
    setColumns(_columns);
    if (!rows || rows.length === 0) setRows(_rows);
  };

  const prepareMusicianForImport = (r) => {
    const body: any = { sub: false };
    const privateNotes: any[] = [];
    Object.keys(mapping).forEach((m) => {
      if (!mapping[m]) return;
      if (r[m] && /^-?\d+$/.test(mapping[m])) {
        privateNotes.push({
          noteTypeID: parseInt(mapping[m]),
          description: m,
          value: r[m],
        });
      } else {
        body[mapping[m]] = r[m];
      }
    });

    return { body, privateNotes };
  };

  const patchMusicians = async () => {
    let count = 0;
    setUpdating(0);
    for (const c in outOfSync) {
      if (Object.prototype.hasOwnProperty.call(outOfSync, c)) {
        // if (count < 5) {
        const musicianID = await patchMusician(
          outOfSync[c].row,
          outOfSync[c].musician,
          outOfSync[c].issues
        );
        if (musicianID) {
          count++;
          setUpdating(count);
        }
        // }
      }
    }
    dispatch(rhapsodyApi.util.invalidateTags(["musicians"]));
    setUpdating(0);
  };

  const patchMusician = async (r: any, musician: Musician, issue: string[]) => {
    const { body } = prepareMusicianForImport(r);
    setLoading(body.email);
    const _body = JSON.parse(JSON.stringify(body));
    delete _body.instruments;

    try {
      const index = rows?.findIndex(
        (r) => r[reversedMapping["email"]] === body.email
      );
      apiRef.current.scrollToIndexes({ rowIndex: index });
      await updateMusician({
        id: musician.id,
        body: _body,
      }).unwrap();
      if (issue.includes("instruments")) {
        for (const c in musician.instruments) {
          if (Object.prototype.hasOwnProperty.call(musician.instruments, c)) {
            const instrument = musician.instruments[c];
            await deleteMusicianInstrument({
              id: musician.id,
              body: { instrumentID: instrument.id },
            });
          }
        }
        if (body.instruments) {
          const instrumentNames = body.instruments.split(",");

          for (const key in instrumentNames) {
            if (instrumentNames.hasOwnProperty(key)) {
              const instrumentName = instrumentNames[key];
              const instrument = instrumentByNameMap[instrumentName];
              if (instrument) {
                await updateMusicianInstrument({
                  id: musician.id,
                  body: { instrumentID: instrument.id },
                });
              }
            }
          }
        }
      }

      setLoading(undefined);
      return musician.id;
    } catch (error) {
      setErrorMusicianEmails((i) => [...i, body.email]);
      setErrors((i) => [
        ...i,
        `${body.firstName} ${body.lastName}: [${error.data.status}] ${error.data.description}`,
      ]);
    }
  };

  const importMusicians = async () => {
    let count = 0;
    setImporting(0);
    for (const c in rows) {
      if (Object.prototype.hasOwnProperty.call(rows, c)) {
        // if (count < 5) {
        const musicianID = await importMusician(rows[c]);
        if (musicianID) {
          count++;
          setImporting(count);
        }
        // }
      }
    }
    dispatch(rhapsodyApi.util.invalidateTags(["musicians"]));
    setImporting(0);
  };

  const importMusician = async (r) => {
    const { body, privateNotes } = prepareMusicianForImport(r);

    if (
      musiciansByEmail[body.email] ||
      importedMusicianEmails.includes(body.email)
    )
      return;

    setLoading(body.email);
    const _body = JSON.parse(JSON.stringify(body));
    delete _body.instruments;

    try {
      const index = rows?.findIndex(
        (r) => r[reversedMapping["email"]] === body.email
      );
      apiRef.current.scrollToIndexes({ rowIndex: index });
      const musician = await createMusician({ ..._body, sub: true }).unwrap();
      if (!musician) return;
      setImportedMusicianEmails((e) => [...e, musician.email]);
      if (body.instruments) {
        const instrumentNames = body.instruments.split(",");

        for (const key in instrumentNames) {
          if (instrumentNames.hasOwnProperty(key)) {
            const instrumentName = instrumentNames[key];
            const instrument = instrumentByNameMap[instrumentName];
            if (instrument) {
              await updateMusicianInstrument({
                id: musician.id,
                body: { instrumentID: instrument.id },
              });
            }
          }
        }
      }

      for (const key in privateNotes) {
        if (privateNotes.hasOwnProperty(key)) {
          const privateNote = privateNotes[key];
          await createMusicianNote({
            musicianID: musician.id,
            ...privateNote,
          }).unwrap();
        }
      }

      setLoading(undefined);
      return musician.id;
    } catch (error) {
      setErrorMusicianEmails((i) => [...i, body.email]);
      setErrors((i) => [
        ...i,
        `${body.firstName} ${body.lastName}: [${error.data.status}] ${error.data.description}`,
      ]);
    }
  };

  const onRowUpdate = async (newRow: any) => {
    return new Promise<any>((resolve) => {
      const index = rows?.findIndex((r) => r.id === newRow.id);
      const _rows = [...rows];
      _rows[index] = newRow;
      setRows(_rows);
      resolve(newRow);
    });
  };

  const genOutOfSync = () => {
    const ret: { musician: Musician; issues: string[]; row: any }[] = [];
    rows?.forEach((r) => {
      const email = r[reversedMapping["email"]];
      if (!email) return;
      const musician = musiciansByEmail[email];
      if (!musician) return;
      const issues: string[] = [];
      for (const rowKey in mapping) {
        if (mapping.hasOwnProperty(rowKey)) {
          const musicianKey = mapping[rowKey];
          const musicianField = musicianFields.find(
            (m) => m.field === musicianKey
          );

          const musicianValue = musician[musicianKey];
          const rowValue = r[rowKey];

          if (
            musicianField &&
            rowValue &&
            !musicianField.noteType &&
            musicianField.format(rowValue) !== musicianValue
          ) {
            if (musicianKey === "instruments") {
              const instr =
                musician.instruments?.reduce((a, v) => {
                  a.push(v.name.toLowerCase());
                  return a;
                }, []) ?? [];
              instr.sort((a, b) => a.localeCompare(b));
              if (instr.join(",") !== musicianField.format(rowValue))
                issues.push(musicianKey);
            } else {
              issues.push(musicianKey);
            }
          }
        }
      }
      if (issues.length) ret.push({ musician, issues, row: r });
    });
    setOutOfSync(ret);
  };

  const getCellClassName = (c: GridCellParams) => {
    const field = mapping[c.field];
    const musicianField = musicianFields.find((m) => m.field === field);
    const email = c.row[reversedMapping["email"]];
    const oos = outOfSync.find((e) => e.musician.email === email);

    if (oos && oos.issues.includes(mapping[c.field])) {
      return "cellInfo";
    }
    if (
      musicianField &&
      musicianField.required &&
      !musicianField?.testValue(c.value)
    ) {
      return "cellDanger";
    }

    if (
      musicianField &&
      !musicianField.required &&
      !musicianField?.testValue(c.value)
    ) {
      return "cellWarning";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        p: 1,
        background: "#eceff1",
        height: heightWithToolbar,
        width: "100vw",
        position: "relative",
      }}
    >
      <Box sx={{ flex: 1, display: "flex" }}>
        {!worksheet ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                level="h6"
                startDecorator={<i className="fa-solid fa-file-excel"></i>}
              >
                Upload Excel
              </Typography>
              <Typography level="body4">
                Drag and drop or click the button bellow
              </Typography>
              <Box sx={{ width: 150, mt: 2 }}>
                <NerdFileUpload
                  uploadFile={(e: File) => {
                    setExcelFile(e);
                  }}
                  hideFilesList
                  dense
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 1,
              flex: 1,
              minWidth: "auto",
              width: 0,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Button
                  size="sm"
                  color="neutral"
                  variant="outlined"
                  sx={{ background: "white" }}
                  onClick={() => {
                    setMapping({});
                  }}
                  startDecorator={<i className="fa-solid fa-table"></i>}
                >
                  Clear Mapping
                </Button>
                <Button
                  size="sm"
                  color="neutral"
                  variant="outlined"
                  sx={{ background: "white" }}
                  onClick={() => {
                    askQuestion("Are you sure?", ["Cancel", "Yes"], {
                      subtitle: (
                        <Typography>
                          All the data will be revert back to what is in the
                          initial Excel documents. Edits you have made will be
                          lost.
                        </Typography>
                      ),
                    }).then((e) => {
                      if (e) setRows(undefined);
                    });
                  }}
                  startDecorator={<i className="fa-solid fa-rotate-left"></i>}
                >
                  Reset Rows
                </Button>
                <Checkbox
                  checked={ignoreIt ?? false}
                  onChange={(e) => setIgnoreIt(e.target.checked)}
                  label="@ignore.it"
                />
                <Typography level="body2">
                  Address Book ({musicians.length} rows)
                </Typography>
                {errors.length ? (
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() =>
                      askQuestion("The following errors occured", ["OK"], {
                        subtitle: (
                          <Typography>
                            <ul>
                              {errors.map((e) => (
                                <li key={e}>{e}</li>
                              ))}
                            </ul>
                          </Typography>
                        ),
                      })
                    }
                  >
                    See Errors ({errors.length})
                  </Button>
                ) : (
                  []
                )}
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                {outOfSync?.length ? (
                  <Button
                    size="sm"
                    color="primary"
                    variant="soft"
                    endDecorator={
                      <i className="fa-solid fa-right-left-large"></i>
                    }
                    startDecorator={updating ? <CircularProgress /> : undefined}
                    onClick={patchMusicians}
                  >
                    Update {outOfSync.length}
                  </Button>
                ) : (
                  []
                )}
                <Button
                  size="sm"
                  color="neutral"
                  variant="outlined"
                  sx={{ background: "white" }}
                  endDecorator={<i className="fa-solid fa-file-import"></i>}
                  startDecorator={importing ? <CircularProgress /> : undefined}
                  onClick={importMusicians}
                >
                  Import {importing ? `(${importing} imported)` : "All"}
                </Button>
              </Box>
            </Box>
            {columns ? (
              <DataGridPremium
                sx={{ background: "white", width: "calc(100vw - 16px)" }}
                slots={{ toolbar: QuickSearchToolbar, footer: GridFooter }}
                apiRef={apiRef}
                density="compact"
                processRowUpdate={onRowUpdate}
                getCellClassName={getCellClassName}
                columns={columns}
                rows={rows ?? []}
                initialState={{ pinnedColumns: { left: ["action"] } }}
              />
            ) : (
              []
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

function reverseDict(obj: Dictionary<string>) {
  const reversed: Dictionary<string> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      reversed[obj[key]] = key;
    }
  }
  return reversed;
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        fullWidth
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
      />
    </Box>
  );
}

function GridFooter() {
  const api = useGridApiContext();
  return (
    <Box
      sx={{
        p: 1,
        borderTop: "solid 1px #E0E0E0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography level="body3">Legend:</Typography>
        <Tooltip
          variant="outlined"
          size="sm"
          arrow
          title={
            <span>
              Field is required in order to import the row.
              <br />
              Rows with red cells won't be imported.
            </span>
          }
        >
          <Chip sx={{ background: "#f4433628", color: "#f44336" }} size="sm">
            Required
          </Chip>
        </Tooltip>
        <Tooltip
          variant="outlined"
          size="sm"
          arrow
          title={
            <span>
              Cell needs attention.
              <br />
              Not critical but the info could be misformed.
            </span>
          }
        >
          <Chip sx={{ background: "#ff980028", color: "#ff9800" }} size="sm">
            Needs Attention
          </Chip>
        </Tooltip>
        <Tooltip
          variant="outlined"
          size="sm"
          arrow
          title={
            <span>
              The info in the Excel differs with what's in the Address Book.
              <br />
              An update will be applied to Musicians with blue cells.
            </span>
          }
        >
          <Chip sx={{ background: "#2196f328", color: "#2196f3" }} size="sm">
            Needs Update
          </Chip>
        </Tooltip>
      </Box>
      <Typography level="body2">
        Total Rows: {api?.current.getRowsCount()}
      </Typography>
    </Box>
  );
}
