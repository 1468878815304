import { Box, CircularProgress, Divider, Grid, Tab, Tabs } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/joy";
import Id from "atoms/Id/Id";
import EmailsDataGrid from "features/dataGrids/EmailsDataGrid";
import LinksDataGrid from "features/dataGrids/LinksDataGrid/LinksDataGrid";
import NotesDataGrid from "features/dataGrids/NotesDataGrid";
import ProjectsDataGrid from "features/dataGrids/ProjectsDataGrid";
import WorkSessionsDataGrid from "features/dataGrids/WorkSessionsDataGrid/WorkSessionsDataGrid";
import MusicianCommunications from "features/musicians/MusicianCommunications";
import MusicianGeneral from "features/musicians/MusicianGeneral";
import { MusicianReport } from "features/musicians/MusicianReport/MusicianReport";
import Calendar from "hooks/Calendar";
import { Helmet } from "react-helmet";
import { Link, Route, Routes } from "react-router-dom";
import {
  setSelectedMusicianID,
  setTabIndex,
  tabIndexSelector,
} from "reducers/rhapsody";
import { useMusician } from "redux/musician/musicianHooks";
import { useCurrentCompany } from "redux/company/companyHooks";

export default function MusicianPage({ id: musicianID, routerMode = false }) {
  const dispatch = useDispatch();
  const { musician } = useMusician(musicianID);
  const tabIndex = useSelector(tabIndexSelector("musician"));

  useEffect(() => {
    dispatch(setSelectedMusicianID(musicianID));

    return () => {
      dispatch(setSelectedMusicianID());
    };
  }, []);

  if (!musician) return <div />;

  const paths = [
    "general",
    "calendar",
    "projects",
    "templates",
    "work-sessions",
    "links",
    "report",
    "sent",
  ];

  const contents = [
    <Box key={paths[0]} sx={{ p: 2, pb: 8 }}>
      <MusicianGeneral id={musicianID} />
    </Box>,
    <Box
      key={paths[1]}
      sx={{ p: 2, flex: 1, height: "calc(100vh - 136px)", display: "flex" }}
    >
      <Calendar musicianID={musicianID} />
    </Box>,
    <Box
      key={paths[2]}
      sx={{ p: 2, height: "calc(100vh - 170px)", display: "flex" }}
    >
      <ProjectsDataGrid noHiring musicianID={musicianID} autoheight={false} />
    </Box>,
    <Box
      key={paths[2]}
      sx={{ p: 2, height: "calc(100vh - 170px)", display: "flex" }}
    >
      <ProjectsDataGrid
        noHiring
        template
        musicianID={musicianID}
        autoheight={false}
      />
    </Box>,
    <Box
      key={paths[3]}
      sx={{ p: 2, height: "calc(100vh - 170px)", display: "flex" }}
    >
      <WorkSessionsDataGrid musicianID={musicianID} autoheight={false} />
    </Box>,
    <Box
      key={paths[4]}
      sx={{ p: 2, height: "calc(100vh - 170px)", display: "flex" }}
    >
      <LinksDataGrid musicianID={musicianID} />
    </Box>,
    <Box
      key={paths[7]}
      sx={{ p: 2, background: "#eceff1", height: "calc(100vh - 170px)" }}
    >
      <MusicianReport musicianID={musicianID} />
    </Box>,
    <Box
      key={paths[8]}
      sx={{ p: 2, height: "calc(100vh - 170px)", display: "flex" }}
    >
      <EmailsDataGrid email={musician.email} />
    </Box>,
  ];

  if (!musician)
    return (
      <Grid
        item
        xs={12}
        style={{
          textAlign: "center",
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <Typography variant="h6" color="textPrimary">
            Please Wait
          </Typography>
        </div>
      </Grid>
    );

  return (
    <>
      <Box
        sx={{
          top: -65,
          position: "sticky",
          zIndex: 100,
          background: "white",
        }}
      >
        <Helmet>
          <title>Rhapsody | {musician.fullName() ?? ""}</title>
        </Helmet>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
          <Box>
            <Typography level="h6">{musician.fullName()}</Typography>
            <Typography level="body2" endDecorator={<Id>{musician.id}</Id>}>
              Musician
            </Typography>
          </Box>
        </Box>
        <MyTabs routerMode={routerMode} />
        <Divider />
      </Box>
      {routerMode ? (
        <Routes>
          {contents.map((e, i) => (
            <Route key={paths[i]} path={paths[i]} element={e}></Route>
          ))}
        </Routes>
      ) : (
        contents[tabIndex]
      )}
    </>
  );
}

function MyTabs({ routeMatch, routerMode }) {
  const currentTab = routeMatch?.pattern?.path;
  const dispatch = useDispatch();
  const tabIndex = useSelector(tabIndexSelector("musician"));
  const { company } = useCurrentCompany();

  return (
    <Tabs
      value={routerMode ? currentTab : tabIndex}
      onChange={
        !routerMode &&
        ((e, v) => dispatch(setTabIndex({ tabID: "musician", value: v })))
      }
    >
      <Tab
        label="General"
        value={routerMode ? "/address-book/:id/general" : 0}
        to={routerMode && "general"}
        component={Link}
      />
      <Tab
        label="Calendar"
        value={routerMode ? "/address-book/:id/calendar" : 1}
        to={routerMode && "calendar"}
        component={Link}
      />
      <Tab
        label="Projects"
        value={routerMode ? "/address-book/:id/projects" : 2}
        to={routerMode && "projects"}
        component={Link}
      />
      <Tab
        label="Templates"
        value={routerMode ? "/address-book/:id/templates" : 3}
        to={routerMode && "templates"}
        component={Link}
      />
      <Tab
        label="Work Sessions"
        value={routerMode ? "/address-book/:id/work-sessions" : 4}
        to={routerMode && "work-sessions"}
        component={Link}
      />
      <Tab
        label="Links"
        value={routerMode ? "/address-book/:id/links" : 5}
        to={routerMode && "links"}
        component={Link}
      />
      {/* {company?.defaultProjectVersion === 2 ? ( */}
      <Tab
        label=<Box sx={{ display: "flex", alignItems: "center" }}>
          <i
            style={{ marginRight: "8px" }}
            className="fa-regular fa-magnifying-glass-chart"
          ></i>
          Insights{" "}
        </Box>
        value={routerMode ? "/address-book/:id/report" : 6}
        to={routerMode && "report"}
        component={Link}
      />
      {/* ) : (
        []
      )} */}
      <Tab
        label=<Box sx={{ display: "flex", alignItems: "center" }}>Sent </Box>
        value={routerMode ? "/address-book/:id/sent" : 7}
        to={routerMode && "sent"}
        component={Link}
      />
    </Tabs>
  );
}
