/**
 * Link_Entity type guard.
 *
 * @param {any} linkJson Link object from API
 * @returns {boolean} Return true if type is Link_Entity
 */

import { LinkTie_Entity } from "entities/linkTie";

export default class Link_Entity {
  constructor(linkJson: Link_Entity) {
    try {
      this.id = linkJson.id;
      this.sessionID = linkJson.sessionID;
      this.projectID = linkJson.projectID;
      this.musicianID = linkJson.musicianID;
      this.name = linkJson.name;
      this.description = linkJson.description;
      this.url = linkJson.url;
      this.global = linkJson.global;
      this.ties = linkJson.ties;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id?: number;
  sessionID?: number;
  projectID?: number;
  musicianID?: number;
  name?: string;
  description?: string;
  url?: string;
  global?: boolean;
  ties: Array<LinkTie_Entity>;
}
