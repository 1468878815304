import { Chair } from "entities/chair";
import ProjectPieceChair_Entity from "./projectPieceChair";
export default class ProjectPieceChair
  extends ProjectPieceChair_Entity
  implements Chair
{
  static fromList(projectPieceChairsJSON: unknown): Array<ProjectPieceChair> {
    const projectPieceChairs: ProjectPieceChair[] = [];
    if (projectPieceChairsJSON)
      Array.isArray(projectPieceChairsJSON) &&
        projectPieceChairsJSON.forEach((projectPieceChairJSON) => {
          projectPieceChairs.push(new ProjectPieceChair(projectPieceChairJSON));
        });
    return projectPieceChairs;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  order(): number {
    return (this.sectionOrder ?? 0) + 1;
  }
}
