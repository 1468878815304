import { PdGroup } from "entities/pdGroup";
import PdProject_Entity from "./pdProject";
import { PdItem } from "entities/pdItem";
import { PdLabor } from "entities/pdLabor";
export default class PdProject extends PdProject_Entity {
  groups: Array<PdGroup>;
  items: Array<PdItem>;
  labors: Array<PdLabor>;

  constructor(json: PdProject_Entity) {
    super(json);
    this.groups = PdGroup.fromList(json.groups);
    this.items = PdItem.fromList(json.items);
    this.labors = this.groups.reduce((a, v) => {
      v.items.forEach((i) => {
        a.push(...i.labors);
      });
      return a;
    }, []);

    this.labors.sort((a, b) =>
      a.description.split(" ")[1]?.localeCompare(b.description.split(" ")[1])
    );
  }

  static fromList(pdProjectsJSON: unknown): Array<PdProject> {
    const pdProjects: PdProject[] = [];
    if (pdProjectsJSON)
      Array.isArray(pdProjectsJSON) &&
        pdProjectsJSON.forEach((pdProjectJSON) => {
          pdProjects.push(new PdProject(pdProjectJSON));
        });
    return pdProjects;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
