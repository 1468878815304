/**
 * Camel case to label with space
 *
 * @param {string} label Label to format
 * @returns {string} New label
 */
export function formatLabel(label: string) {
  return label.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export const genericIcons = {
  string: "fa-regular fa-text",
  date: "fa-solid fa-calendar-days",
  int: "fa-regular fa-hashtag",
  bool: "fa-regular fa-square-check",
};

/**
 * Get text width
 *
 * @param {string} text Text to measure
 * @returns {number} Text width
 */
export function getTextWidth(text: string): number {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context != null) {
    context.font = "600 14px Roboto";
  }

  return context?.measureText(text).width ?? 80;
}
