/**
 * MusicianNote_Entity type guard.
 *
 * @param {any} musicianNoteJson MusicianNote object from API
 * @returns {boolean} Return true if type is MusicianNote_Entity
 */

export default class MusicianNote_Entity {
  constructor(musicianNoteJson: MusicianNote_Entity) {
    try {
      this.id = musicianNoteJson.id;
      this.description = musicianNoteJson.description;
      this.musicianID = musicianNoteJson.musicianID;
      this.noteTypeID = musicianNoteJson.noteTypeID;
      this.value = musicianNoteJson.value;
      this.createdAt = musicianNoteJson.createdAt;
      this.updatedAt = musicianNoteJson.updatedAt;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  description: string;
  musicianID: number;
  noteTypeID: number;
  value: string;
  createdAt: string;
  updatedAt: string;
}
