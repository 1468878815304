// import PropTypes from 'prop-types';

// material-ui
import Typography from "@mui/material/Typography";

// styles
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Option, Select } from "@mui/joy";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
} from "@mui/material";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { isConciergeReadOnly } from "helpers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedWorkSessionIDSelector,
  setSelectedWorkSessionID,
} from "reducers/rhapsody";
import { useProjects } from "redux/project/projectHooks";
import {
  useDuplicateWorkSessionMutation,
  useUpdateWorkSessionMutation,
} from "redux/workSession/workSessionEndpoints";
import { useWorkSession } from "redux/workSession/workSessionHooks";
import { meSelector } from "selectors/me";

export default function WorkSessionDangerZone() {
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const { workSession } = useWorkSession(workSessionID);
  const me = useSelector(meSelector);
  const conciergeReadOnly = isConciergeReadOnly(workSession, me);
  const { projects } = useProjects();
  const dispatch = useDispatch();
  const [updateWorkSession] = useUpdateWorkSessionMutation();
  const [duplicateWorkSession, { data: duplicatedWorkSession }] =
    useDuplicateWorkSessionMutation();

  const askQuestion = useAskQuestion();

  useEffect(() => {
    if (duplicatedWorkSession?.id) {
      askQuestion("Success", ["Close", "Go to Duplicate"], {
        subtitle: <Typography>Work Session has been duplicated</Typography>,
      }).then((i) => {
        if (i == 1)
          dispatch(setSelectedWorkSessionID(duplicatedWorkSession?.id));
      });
    }
  }, [duplicatedWorkSession]);

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2} alignItems="start">
        <Grid item xs={12}>
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none",
              border: "solid 1px rgba(155,155,155,0.3)",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography variant="h6" color="textPrimary">
                  {workSession.archived ? "Archived" : "Archive"}
                </Typography>
                <Typography color="textSecondary">
                  {workSession.archived
                    ? "Your work session is archived."
                    : "If your Work Session is done, you can archive it. It will show in the archive tab on the main list."}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="soft"
                      disabled={conciergeReadOnly}
                      onClick={async () => {
                        updateWorkSession({
                          id: workSession.id,
                          body: {
                            archived: !workSession.archived,
                          },
                        });
                      }}
                    >
                      {!workSession.archived
                        ? "Archive Session"
                        : "Unarchive Session"}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
            <Divider />
          </Accordion>
        </Grid>
        {workSession.recorded ? (
          <Grid item xs={12}>
            <Accordion
              defaultExpanded
              sx={{
                boxShadow: "none",
                border: "solid 1px rgba(155,155,155,0.3)",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                  <Typography variant="h6" color="textPrimary">
                    Unlock Session
                  </Typography>
                  <Typography color="textSecondary">
                    Your session is locked.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    style={{ width: "100%" }}
                  >
                    <Grid item xs={12}>
                      <Button
                        variant="soft"
                        disabled={conciergeReadOnly}
                        onClick={async () => {
                          updateWorkSession({
                            id: workSession.id,
                            body: {
                              recorded: false,
                            },
                          });
                        }}
                      >
                        Unlock Session
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </AccordionDetails>
              <Divider />
            </Accordion>
          </Grid>
        ) : (
          []
        )}
        <Grid item xs={12}>
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none",
              border: "solid 1px rgba(155,155,155,0.3)",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography variant="h6" color="textPrimary">
                  Transfer to another Project
                </Typography>
                <Typography color="textSecondary">
                  {"Change the Work Session's project."}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={12} style={{ maxWidth: 300 }}>
                    <Select
                      value={workSession.projectID || ""}
                      onChange={(e, v) => {
                        updateWorkSession({
                          id: workSession.id,
                          body: {
                            projectID: v,
                          },
                        });
                      }}
                    >
                      {projects
                        .filter((p) => !p.archived)
                        .map((p) => (
                          <Option key={`project_${p.id}`} value={p.id}>
                            {p.name}
                          </Option>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
            <Divider />
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none",
              border: "solid 1px rgba(155,155,155,0.3)",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography variant="h6" color="textPrimary">
                  Duplicate Session
                </Typography>
                <Typography color="textSecondary">
                  Create a new work session that would be a copy of this one.
                  Musicians would be ready to invite.
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      disabled={conciergeReadOnly}
                      variant="soft"
                      onClick={() => {
                        duplicateWorkSession(workSession.id);
                      }}
                    >
                      Duplicate
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
            <Divider />
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none",
              border: "solid 1px rgba(155,155,155,0.3)",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography variant="h6" color="textPrimary">
                  Delete permanently
                </Typography>
                <Typography color="textSecondary">
                  You won't be able to recover anything from this session.
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="soft"
                      color="danger"
                      onClick={async () => {
                        askQuestion(
                          "You're about to delete this Work Session",
                          ["Cancel", "Delete"],
                          {
                            subtitle: (
                              <Typography>
                                You won't be able to recover anything. Make sure
                                your musicians are aware of this action.
                              </Typography>
                            ),
                          }
                        ).then((t) => {
                          if (t) {
                            updateWorkSession({
                              id: workSession.id,
                              body: {
                                deleted: true,
                              },
                            });
                          }
                        });
                      }}
                    >
                      Delete permanently
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
            <Divider />
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
}
