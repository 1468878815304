import { Button } from "@mui/joy";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormOpen, setSelectedProjectNoteID } from "reducers/rhapsody";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { selectedProjectIDSelector } from "reducers/rhapsody";

export default function ProjectNotesPicker({ onChange, value: selected }) {
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectNotes, isLoading, isFetching } = useProjectNotes(projectID);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState();

  const handleSelect = (n) => {
    const index = selected.indexOf(n.id);
    const _selected = [...selected];
    if (index >= 0) {
      _selected.splice(index, 1);
    } else {
      _selected.push(n.id);
    }

    onChange(_selected);
  };

  const handleRowOrderChange = async (params) => {
    const { oldIndex, targetIndex } = params;
    const _selected = [...selected];
    const row = _selected.splice(oldIndex, 1)[0];
    _selected.splice(targetIndex, 0, row);

    onChange(_selected);
  };

  if (isLoading || isFetching) return <Skeleton />;

  let rows = [];
  selected.forEach((s) => {
    const projectNote = projectNotes?.find((pn) => pn.id === s);
    if (projectNote) rows.push(projectNote);
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        {projectNotes?.length ? (
          <Button
            variant="soft"
            sx={{ flex: 1 }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            startDecorator={<i className="fa-solid fa-notes"></i>}
            endDecorator={<i className="fa-solid fa-chevron-down"></i>}
          >
            Include Notes
          </Button>
        ) : (
          []
        )}
        <Button
          size="sm"
          variant="soft"
          fullWidth
          color="neutral"
          onClick={() => {
            dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }));
          }}
        >
          New Project Note
        </Button>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <List style={{ overflow: "auto", width: 600 }} dense>
          {projectNotes.map((p) => (
            <ListItem
              key={p.id}
              button
              divider
              selected={selected.indexOf(p.id) >= 0}
              onClick={() => handleSelect(p)}
            >
              <ListItemAvatar>
                <Checkbox checked={selected.indexOf(p.id) >= 0} />
              </ListItemAvatar>
              <ListItemText primary={p.name || <i>Untitled Note</i>} />
            </ListItem>
          ))}
        </List>
      </Popover>
      {selected?.length ? (
        <Box>
          <DataGridPro
            autoHeight
            rowReordering
            disableSelectionOnClick
            onRowOrderChange={handleRowOrderChange}
            rows={rows}
            columns={[
              {
                field: "name",
                headerName: "Note",
                flex: 1,
                valueGetter: (p) => p.row?.name || "Untitled Note",
              },
              {
                field: "id",
                headerName: "",

                type: "actions",
                getActions: (p) => {
                  return [
                    <Tooltip title="Open Note" key="preview">
                      <GridActionsCellItem
                        icon={
                          <i class="fa-solid fa-arrow-up-right-from-square"></i>
                        }
                        label={`Preview`}
                        onClick={() => {
                          dispatch(setSelectedProjectNoteID(p.row.id));
                          dispatch(
                            setFormOpen({ isOpen: true, formID: "projectNote" })
                          );
                        }}
                        color="inherit"
                      />
                    </Tooltip>,
                    <Tooltip title="Remove from Email" key="delete">
                      <GridActionsCellItem
                        icon={<i className="fa-solid fa-xmark"></i>}
                        label={`Delete`}
                        onClick={() => handleSelect(p.row)}
                        color="inherit"
                      />
                    </Tooltip>,
                  ];
                },
              },
            ]}
            hideFooter
            density="compact"
          />
          <Typography variant="caption" color="textSecondary">
            Reorder the notes using the grab indicator{" "}
            <i class="fa-solid fa-grip-vertical"></i>
          </Typography>
        </Box>
      ) : (
        []
      )}
    </Box>
  );
}
