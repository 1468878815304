import { Box, Button, IconButton, Sheet, Textarea, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { ProjectContact_Entity } from "entities/projectContact";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { MusicianAutocomplete } from "features/dataGrids/EmailsDataGrid/musicianAutocomplete";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectContactIDSelector,
  selectedProjectIDSelector,
  setFormOpen,
  setSelectedProjectContactID,
} from "reducers/rhapsody";
import { useMusician } from "redux/musician/musicianHooks";
import {
  useCreateProjectContactMutation,
  useDeleteProjectContactMutation,
  useUpdateProjectContactMutation,
} from "redux/projectContact/projectContactEndpoints";
import { useProjectContact } from "redux/projectContact/projectContactHooks";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ProjectContactForm() {
  const open = useSelector(formOpenSelector("projectContact"));
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const projectContactID = useSelector(selectedProjectContactIDSelector);
  const { projectContact } = useProjectContact(projectContactID);
  const [createProjectContact] = useCreateProjectContactMutation();
  const [_projectContact, setProjectContact] = useState<
    Partial<ProjectContact_Entity>
  >({});
  const { musician } = useMusician(_projectContact?.musicianID);
  const [updateProjectContact] = useUpdateProjectContactMutation();
  const [deleteprojectContact] = useDeleteProjectContactMutation();

  useEffect(() => {
    if (projectContact) setProjectContact(projectContact);
  }, [projectContact]);

  useEffect(() => {
    if (!open) setProjectContact({});
  }, [open]);

  const askQuestion = useAskQuestion();

  const onClose = () => {
    dispatch(setSelectedProjectContactID(null));
    dispatch(setFormOpen({ isOpen: false, formID: "projectContact" }));
  };

  const save = () => {
    if (_projectContact.id) {
      updateProjectContact({ id: _projectContact.id, body: _projectContact });
    } else {
      createProjectContact({ ..._projectContact, projectID });
    }
    onClose();
  };

  const askDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>You are about to delete a link, please confirm</Typography>
      ),
    }).then((i) => {
      if (i == 1) confirmDelete();
    });
  };

  const confirmDelete = async () => {
    deleteprojectContact(projectContact?.id);

    onClose();
  };

  return (
    <DialogClose maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-solid fa-user"></i>}
          level="h6"
        >
          Contact
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        {_projectContact?.musicianID ? (
          <Sheet
            color="neutral"
            variant="soft"
            sx={{
              borderRadius: "8px",
              p: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography startDecorator={<MusicianAvatar musician={musician} />}>
              {musician?.fullName()}
            </Typography>
            <IconButton
              size="sm"
              color="neutral"
              onClick={() => {
                setProjectContact((p) => ({ ...p, musicianID: 0 }));
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          </Sheet>
        ) : (
          <Box sx={{ width: "100%", pt: 1 }}>
            <MusicianAutocomplete
              email=""
              onChange={(e) =>
                setProjectContact((p) => ({ ...p, musicianID: e.id }))
              }
              size="small"
              label="Pick from the Address Book..."
            />
          </Box>
        )}
        <Textarea
          sx={{ width: "100%" }}
          startDecorator={"Role for the Project"}
          minRows={2}
          maxRows={5}
          value={_projectContact.role}
          onChange={(e) =>
            setProjectContact((p) => ({ ...p, role: e.target.value }))
          }
          placeholder="Ex: Librarian. Ask for music sheets if needed."
          onBlur={() => {
            if (projectContact)
              updateProjectContact({
                id: projectContact.id,
                body: _projectContact,
              });
          }}
        />
      </DialogContent>
      <DialogActions>
        {_projectContact.id ? (
          <Button variant="soft" color="danger" onClick={askDelete}>
            Delete
          </Button>
        ) : (
          []
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
        <Button onClick={save} disabled={!_projectContact.musicianID}>
          {_projectContact.id ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
