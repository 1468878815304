import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  List,
  ListItemButton,
  Sheet,
  Textarea,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";

/**
 *
 * @returns {ReactElement} DefaultDressCodes page
 */
export function DefaultDressCodes() {
  const open = useSelector(formOpenSelector("defaultDressCodes"));
  const dispatch = useDispatch();
  const { company } = useCurrentCompany();
  const settings = useCompanySettings();
  const [updateCompany] = useUpdateCurrentCompanyMutation();
  const [defaultDressCodes, setDefaultDressCodes] = useState<
    { name: string; content: string }[]
  >([]);
  const [index, setIndex] = useState(0);

  const onClose = () => {
    dispatch(setFormOpen({ formID: "defaultDressCodes", isOpen: false }));
  };

  useEffect(() => {
    if (defaultDressCodes.length && !index) setIndex(0);
  }, [defaultDressCodes]);

  useEffect(() => {
    if (settings.defaultDressCodes)
      setDefaultDressCodes(settings.defaultDressCodes);
  }, [settings]);

  const save = () => {
    updateCompany({
      ...company,
      settings: JSON.stringify({
        ...settings,
        defaultDressCodes: defaultDressCodes,
      }),
    });
    onClose();
  };

  const selected = defaultDressCodes.length
    ? defaultDressCodes[index]
    : undefined;

  return (
    <DialogClose open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography level="h6">Dress Code Snippets</Typography>
        <Typography level="body2">
          Create dress code snippets ready to use on your Projects
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ gap: 1, display: "flex", flexDirection: "row", height: 400 }}
      >
        <Box sx={{ width: 200, flexShrink: 0 }}>
          <Button
            fullWidth
            size="sm"
            onClick={() => {
              setDefaultDressCodes((e) => [{ name: "", content: "" }, ...e]);
            }}
          >
            New Item
          </Button>
          <List>
            {defaultDressCodes.map((e, i) => (
              <ListItemButton
                onClick={() => setIndex(i)}
                key={e.content}
                selected={index === i}
                variant={index === i ? "soft" : undefined}
              >
                {e.name ? e.name : "Untitled"}
              </ListItemButton>
            ))}
          </List>
        </Box>
        <Divider orientation="vertical" />
        {selected ? (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              position: "relative",
              alignItems: "stretch",
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <Input
                sx={{ flexGrow: 1 }}
                variant="soft"
                autoFocus
                startDecorator="Name:"
                value={selected?.name ?? ""}
                onChange={(e) => {
                  setDefaultDressCodes((a) => {
                    a[index].name = e.target.value;
                    return [...a];
                  });
                }}
              />
              <IconButton
                sx={{ flexShrink: 0 }}
                color="danger"
                onClick={() => {
                  setIndex(undefined);
                  setDefaultDressCodes((a) => {
                    a.splice(index, 1);
                    return [...a];
                  });
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </IconButton>
            </Box>
            <ReactQuill
              key={index}
              theme="snow"
              style={{ flex: 1, flexShrink: 1, maxHeight: 250 }}
              value={selected?.content ?? ""}
              onChange={(e) => {
                setDefaultDressCodes((a) => {
                  a[index].content = e;
                  return [...a];
                });
              }}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
                "color",
                "background",
                "align",
                "direction",
                "code",
                "code-block",
              ]}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],

                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction

                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],

                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],

                  ["clean"], // remove formatting button
                ],
                clipboard: {
                  // toggle to add extra line breaks when pasting HTML:
                  matchVisual: false,
                },
              }}
            />
          </Box>
        ) : (
          <Box>
            <Typography
              startDecorator={<i className="fa-solid fa-arrow-left"></i>}
            >
              Click on the New Item Button to get started
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}
