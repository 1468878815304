/**
 * Autopilot_Entity type guard.
 *
 * @param {any} autopilotJson Autopilot object from API
 * @returns {boolean} Return true if type is Autopilot_Entity
 */

import { Hook_Entity } from "entities/hook";

export default class Autopilot_Entity {
  constructor(autopilotJson: Autopilot_Entity) {
    try {
      this.id = autopilotJson.id;
      this.projectID = autopilotJson.projectID;
      this.enabled = autopilotJson.enabled;
      this.userUUID = autopilotJson.userUUID;
      this.hooks = autopilotJson.hooks;
      this.updatedAt = autopilotJson.updatedAt;
      this.createdAt = autopilotJson.createdAt;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectID: number;
  enabled: boolean;
  userUUID: string;
  hooks: Hook_Entity[];
  updatedAt: string;
  createdAt: string;
}
