import { Box, IconButton, Input, List, ListItemButton } from "@mui/joy";
import { DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Project } from "entities/project";
import { useEffect, useState } from "react";
import { useTemplates } from "redux/project/projectHooks";

export function PickTemplates({
  open,
  onDismiss,
  onChange,
  values = [],
}: {
  open: boolean;
  onDismiss: () => void;
  onChange: (e: number[]) => void;
  values?: number[];
}) {
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState<Project[]>([]);
  const { templates, templatesMap } = useTemplates();

  useEffect(() => {
    if (values?.length) {
      const l = [];
      values.forEach((v) => l.push(templatesMap[v]));
      setList(l);
    }
  }, [values]);

  return (
    <DialogClose open={open} onClose={onDismiss}>
      <DialogTitle>{"Select a Template"}</DialogTitle>
      <DialogContent sx={{ display: "flex", gap: 1 }}>
        <Box sx={{ height: 600, width: 300, overflow: "auto" }}>
          <Input
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            size="sm"
            autoFocus
            color="neutral"
            variant="soft"
            placeholder="Search..."
            startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
            endDecorator={
              searchText ? (
                <IconButton
                  color="neutral"
                  variant="plain"
                  onClick={() => setSearchText("")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
              ) : (
                []
              )
            }
          />
          <List size="sm">
            {templates
              ?.filter((i) => {
                if (!searchText) return true;
                return (
                  i.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
                );
              })
              .map((m) => (
                <ListItemButton
                  key={m.id}
                  onClick={() => {
                    onChange([m.id]);
                  }}
                >
                  {m.name}
                </ListItemButton>
              ))}
          </List>
        </Box>
      </DialogContent>
    </DialogClose>
  );
}
