/**
 * @param  {Array<T>} elements
 * @returns Map
 */
export function arrayToMap<T extends { id: number }>(
  elements: Array<T>
): { [key: number]: T } {
  const ret = {};
  for (const key in elements) {
    if (Object.prototype.hasOwnProperty.call(elements, key)) {
      const element = elements[key];
      ret[element.id] = element;
    }
  }
  return ret;
}

/**
 * @param  {Map<number} elements
 * @returns Map
 */
export function mapToArray<T extends { id: number }>(elements: {
  [key: number]: T;
}): Array<T> {
  const ret = new Array<T>();
  for (const key in elements) {
    if (Object.prototype.hasOwnProperty.call(elements, key)) {
      const element = elements[key];
      ret.push(element);
    }
  }

  return ret;
}
