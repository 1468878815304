import {
  IconButton,
  Input,
  List,
  ListItemButton,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { Box } from "@mui/material";
import { useTour } from "@reactour/tour";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useMissionControlSelectAll } from "features/projects/ProjectMissionControl/utils";
import { mapToArray } from "helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";

/**
 *
 * @returns {ReactElement} SearchMusician page
 */
export function SearchMusician() {
  const open = useSelector(formOpenSelector("searchMusician"));
  const utils = useSelector(layoutUtilsSelector);
  const stagesMap = utils.stagesMap;
  const assignments = utils.assignments;
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const musicians = mapToArray(utils.musiciansMap).sort((a, b) =>
    a.lastName.localeCompare(b.lastName)
  );
  const { selectAll } = useMissionControlSelectAll();
  const askQuestion = useAskQuestion();
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  const [className, setClassName] = useState("");
  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "searchMusician" }));
    setClassName("");
  };

  useEffect(() => {
    if (className) showMusician();
  }, [className]);

  const showMusician = () => {
    const elemt = window.document.getElementsByClassName(className);
    if (elemt.length) {
      setSteps([
        {
          selector: `.${className}`,
          content: (
            <Box>
              <Typography level="h6" sx={{ color: "inherit" }}>
                Right there!
              </Typography>
              <Typography level="body2" sx={{ color: "inherit" }}>
                The musician you are looking for is here.
              </Typography>
            </Box>
          ),
        },
      ]);
      setIsOpen(false);
      setCurrentStep(0);
      setIsOpen(true);
      onClose();
    } else {
      notFound();
    }
  };

  const notFound = () => {
    askQuestion("Not visible", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>
          The musician is not visible with the current selection.
          <br />
          Do you want to select all and retry?
        </Typography>
      ),
    }).then((i) => {
      if (i) {
        const _className = `${className}`;
        setClassName("");
        selectAll();
        setTimeout(() => {
          setClassName(_className);
        }, 500);
      }
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <Typography
          level="h6"
          startDecorator={<i className="fa-solid fa-user-magnifying-glass"></i>}
        >
          Find Musician in Project
        </Typography>
        <Input
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          autoFocus
          placeholder="Search..."
          endDecorator={
            <IconButton
              tabIndex={-1}
              variant="plain"
              color="neutral"
              onClick={() => setSearchText("")}
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          }
        />
        <Box
          sx={{
            height: 600,
            overflow: "auto",
            alignItems: "start",
            justifyContent: "start",
            p: 0.5,
          }}
        >
          <List>
            {musicians
              .filter((e) => {
                if (searchText) {
                  return `${e.firstName} ${e.lastName} ${e.middleName} ${e.nickName}`
                    .toLowerCase()
                    .includes(searchText.toLowerCase());
                }
                return true;
              })
              .map((m) => {
                const assignment = assignments.find(
                  (a) => a.musicianID === m.id
                );
                const stage = stagesMap[assignment?.mercuryStageID];
                return (
                  <ListItemButton
                    key={m.id}
                    sx={{ justifyContent: "space-between" }}
                    onClick={() => {
                      setClassName(`musician-${m.id}`);
                      // onClose();
                    }}
                  >
                    <Typography
                      startDecorator={<MusicianAvatar size={28} musician={m} />}
                    >
                      {m.fullName()}
                    </Typography>
                    {stage ? (
                      <img style={{ height: 18 }} src={stage.icon} />
                    ) : (
                      []
                    )}
                  </ListItemButton>
                );
              })}
          </List>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
