import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { ProjectPieceSection_Entity } from "entities/projectPieceSection";
import { rhapsodyApi } from "../api/rhapsodyApi";

const projectPieceSectionsAdapter =
  createEntityAdapter<ProjectPieceSection_Entity>();
const initialState = projectPieceSectionsAdapter.getInitialState();

export const projectPieceSectionEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectPieceSectionsForProjectPiece: build.query<
      EntityState<ProjectPieceSection_Entity>,
      number
    >({
      query: (projectPieceID) => `projectPieces/${projectPieceID}/sections`,
      transformResponse: (responseData: ProjectPieceSection_Entity[]) => {
        return projectPieceSectionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["projectPieceSections"],
    }),
    getProjectPieceSections: build.query<
      EntityState<ProjectPieceSection_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `projectPieceSections`,
          params: args,
        };
      },
      transformResponse: (responseData: ProjectPieceSection_Entity[]) => {
        return projectPieceSectionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["projectPieceSections"],
    }),
    getProjectPieceSection: build.query<ProjectPieceSection_Entity, number>({
      query: (id) => `projectPieceSections/${id}`,
      providesTags: (result, error, id) => [
        { type: "projectPieceSections", id },
      ],
    }),
    createProjectPieceSection: build.mutation<
      ProjectPieceSection_Entity,
      Partial<ProjectPieceSection_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `projectPieceSections`,
      }),
      invalidatesTags: ["projectPieceSections"],
    }),
    updateProjectPieceSection: build.mutation<
      void,
      { id: number; body: Partial<ProjectPieceSection_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projectPieceSections/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "projectPieceSections", id },
        "projectPieceSections",
      ],
    }),
    deleteProjectPieceSection: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projectPieceSections/${id}`,
      }),
      invalidatesTags: ["projectPieceSections"],
    }),
  }),
});

export const {
  useGetProjectPieceSectionsQuery,
  useGetProjectPieceSectionQuery,
  useLazyGetProjectPieceSectionsQuery,
  useGetProjectPieceSectionsForProjectPieceQuery,
  useCreateProjectPieceSectionMutation,
  useDeleteProjectPieceSectionMutation,
  useUpdateProjectPieceSectionMutation,
} = projectPieceSectionEndpoints;

export default projectPieceSectionEndpoints;
