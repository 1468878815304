import { Grid } from "@mui/material";
import FormPersonalInfo from "features/forms/FormPersonalInfo";
import FormResetPassword from "features/forms/FormResetPassword";

export default function MyAccountCredentials() {
  return (
    <Grid container spacing={2} sx={{ maxWidth: 900, margin: "auto" }}>
      <Grid item sx={12} md={6}>
        <FormPersonalInfo />
      </Grid>
      <Grid item sx={12} md={6}>
        <FormResetPassword />
      </Grid>
    </Grid>
  );
}
