// import PropTypes from 'prop-types';

// material-ui
import Typography from "@mui/material/Typography";

// styles
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/joy";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { isConciergeReadOnly } from "helpers";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedProjectID } from "reducers/rhapsody";
import { useProject } from "redux/project/projectHooks";
import { meSelector } from "selectors/me";
import { selectedProjectIDSelector } from "reducers/rhapsody";

import { useUpdateProjectMutation } from "redux/project/projectEndpoints";

export default function ProjectDangerZone({ id, template = false }) {
  const projectID = id || useSelector(selectedProjectIDSelector);
  const { project } = useProject(projectID);
  const [updateProject] = useUpdateProjectMutation();
  // const [ state, setState ] = React.useState(initialValue);

  const me = useSelector(meSelector);
  const askQuestion = useAskQuestion();

  const conciergeReadOnly = isConciergeReadOnly(project, me);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleArchive = async () => {
    updateProject({
      id: project.id,
      body: {
        ...project,
        archived: !project.archived,
      },
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none",
              border: "solid 1px rgba(155,155,155,0.3)",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography variant="h6" color="textPrimary">
                  {project.archived ? "Archived" : "Archive"}
                </Typography>
                <Typography color="textSecondary">
                  {project.archived
                    ? `Your project is archived.`
                    : template
                    ? "If you no longer use this template, you can archive it."
                    : "If your Project is done, you can archive it. It will show in the archive tab on the main list. All related Work Session will also be archived."}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="soft"
                      disabled={conciergeReadOnly}
                      onClick={handleArchive}
                    >
                      {!project.archived
                        ? `Archive ${template ? "Template" : "Project"}`
                        : `Unarchive ${template ? "Template" : "Project"}`}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none",
              border: "solid 1px rgba(155,155,155,0.3)",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography variant="h6" color="textPrimary">
                  Delete permanently
                </Typography>
                <Typography color="textSecondary">
                  You won't be able to recover anything from this{" "}
                  {template ? "template" : "project"}.{" "}
                  {template
                    ? ""
                    : "Associated work sessions will be also deleted."}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="soft"
                      color="danger"
                      disabled={conciergeReadOnly}
                      onClick={async () => {
                        askQuestion(
                          template
                            ? "You're about to delete this Template"
                            : "You're about to delete this Project",
                          ["Cancel", "Delete"],
                          {
                            subtitle: (
                              <Typography>
                                You won't be able to recover anything.
                              </Typography>
                            ),
                          }
                        ).then((t) => {
                          if (t) {
                            updateProject({
                              id: project.id,
                              body: { ...project, deleted: true },
                            })
                              .unwrap()
                              .then(() => {
                                dispatch(setSelectedProjectID());
                                navigate(
                                  template
                                    ? RouterConfig.templates
                                    : RouterConfig.projects
                                );
                              });
                          }
                        });
                      }}
                    >
                      Delete permanently
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
}
