import { Stage } from "entities/stage";
import { readyStage } from "entities/stage/helper";
import { useMemo } from "react";
import { useGetStageQuery, useGetStagesQuery } from "./stageEndpoints";

export function useStage(
  stageID: number | undefined,
  opt?: { skip?: boolean }
) {
  const stageQuery = useGetStageQuery(stageID ?? 0, {
    skip: (stageID ?? 0) <= 0 || opt?.skip,
  });
  const stageEntity = stageQuery.data;

  return useMemo(() => {
    const ret: typeof stageQuery & { stage?: Stage | undefined } = {
      ...stageQuery,
    };
    if (stageEntity) {
      ret.stage = new Stage(stageEntity);
    } else {
      ret.stage = new Stage(readyStage);
    }
    return ret;
  }, [stageEntity]);
}

export function useStages(opt?: { skip: boolean }) {
  const stageQuery = useGetStagesQuery(null, opt);
  const stageEntitiesMap = stageQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof stageQuery & {
      stages?: Stage[];
      stagesMap?: { [id: number]: Stage };
    } = {
      ...stageQuery,
      stages: [],
      stagesMap: {},
    };
    if (stageEntitiesMap) {
      const stages = [];
      const stagesMap = {};

      for (const key in stageEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(stageEntitiesMap, key)) {
          const c = stageEntitiesMap[key];
          const stage = new Stage(c);
          stages.push(stage);
          stagesMap[stage.id] = stage;
        }
      }
      ret.stages = stages;
      ret.stagesMap = stagesMap;
    }

    return ret;
  }, [stageEntitiesMap]);
}
