import { Dictionary } from "@reduxjs/toolkit";

/**
 * InternalPosition_Entity type guard.
 *
 * @param {any} InternalPositionJson InternalPosition object from API
 * @returns {boolean} Return true if type is InternalPosition_Entity
 */

export default class InternalPosition_Entity {
  constructor(InternalPositionJson: InternalPosition_Entity) {
    try {
      this.id = InternalPositionJson.id;
      this.order = InternalPositionJson.order;
      this.chairIDs = InternalPositionJson.chairIDs;
      this.assignmentIDs = InternalPositionJson.assignmentIDs;
      this.sectionID = InternalPositionJson.sectionID;
      this.familyID = InternalPositionJson.familyID;
      this.musicianIDs = InternalPositionJson.musicianIDs;
      this.projectPieceIDs = InternalPositionJson.projectPieceIDs;
      this.visibilityForProjectPieces =
        InternalPositionJson.visibilityForProjectPieces;
      this.visibilityForWorkSessions =
        InternalPositionJson.visibilityForWorkSessions;
      this.forWorkSessionID = InternalPositionJson.forWorkSessionID;
      this.forProjectPieceID = InternalPositionJson.forProjectPieceID;
    } catch (e) {
      throw new Error(
        `Failed to create new instance of ${InternalPosition_Entity.name}`
      );
    }
  }

  id: string;
  order: number;
  sectionID: number;
  familyID: number;
  chairIDs: number[];
  assignmentIDs: number[];
  musicianIDs: number[];
  projectPieceIDs: number[];
  visibilityForProjectPieces: Dictionary<boolean>;
  visibilityForWorkSessions: Dictionary<boolean>;
  forWorkSessionID?: number;
  forProjectPieceID?: number;
}
