import JoyTypography from "@mui/joy/Typography";
import { Box, darken } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogClose from "atoms/DialogClose/DialogClose";
// material-ui
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  formOpenSelector,
  setMissionControlMode,
  setMissionControlViewMode,
} from "reducers/rhapsody";

// styles
import { Alert, Button, Checkbox, Input, Option, Select } from "@mui/joy";
import { ConfettiCenterContext } from "features/context/ConfettiCenter/ConfettiCenter";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { setFormOpen } from "reducers/rhapsody";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";
import { useCreateProjectMutation } from "redux/project/projectEndpoints";
import { useCreateProjectTagMutation } from "redux/projectTag/projectTagEndpoints";
import { useTags } from "redux/tag/tagHooks";

export default function FormProject({ template = false }) {
  const { tags } = useTags();
  const formID = template ? "template" : "project";
  const formOpen = useSelector(formOpenSelector(formID));
  const params = useParams();
  const [createProject] = useCreateProjectMutation();
  const { company } = useCurrentCompany();
  const settings = useCompanySettings();
  const [project, setProject] = useState({
    name: "",
    customerID: 0,
    dateFrom: "",
    dateTo: "",
    composer: "",
    agreement: "",
    contractor: "",
    template,
    templateID: 0,
    tagID: params?.tagID ? parseInt(params.tagID) : 0,
    version: 1,
    hideWhenNotPlaying: settings?.hideWhenNotPlaying,
  });
  const [createProjectTag] = useCreateProjectTagMutation();

  const dispatch = useDispatch();
  const ConfettiCenter = useContext(ConfettiCenterContext);

  const navigate = useNavigate();

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setProject((s) => ({ ...s, [name]: value }));
  };

  useEffect(() => {
    if (company) {
      setProject((p) => ({
        ...p,
        version: company.defaultProjectVersion ?? 1,
      }));
    }
  }, [company]);

  const save = async () => {
    createProject(project)
      .unwrap()
      .then((p) => {
        ConfettiCenter.show();
        if (project.tagID) {
          createProjectTag({
            projectID: p?.id,
            tagID: project.tagID,
          });
        }
        dispatch(setFormOpen({ isOpen: false, formID: formID }));
        let to = "seating";
        if (project.version === 2) to = "mission-control";
        dispatch(setMissionControlMode("edit"));
        dispatch(setMissionControlViewMode("grouped"));
        navigate(
          `${template ? RouterConfig.templates : RouterConfig.projects}/${
            p?.id
          }/${to}`
        );
      });
  };

  const disabled = project.name.length === 0;
  return (
    <>
      <DialogClose
        open={formOpen}
        scroll="paper"
        onClose={() =>
          dispatch(
            setFormOpen({
              isOpen: false,
              formID: template ? "template" : "project",
            })
          )
        }
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <div
            style={{
              background: "#e3f2fd",
              padding: 16,
              borderRadius: "16px",
              textAlign: "center",
            }}
          >
            <i
              style={{ fontSize: 80, color: "#001E3C" }}
              className={
                template ? "fa-duotone fa-square-dashed" : "fad fa-folder-open"
              }
            ></i>
            <Typography variant="h6" style={{ color: "#001E3C" }}>
              {template ? "New Template" : "New Project"}
            </Typography>
            <Typography
              style={{ color: "#001E3C", opacity: 0.8 }}
              variant="body2"
            >
              {template
                ? "A template is a call order you can use to autofill new projects"
                : "Create a new project to start the contracting process."}
            </Typography>
          </div>
          <Grid container spacing={2} style={{ marginTop: 8, maxWidth: 500 }}>
            <Grid item xs={12}>
              <Input
                id="name"
                type="text"
                autoFocus
                endDecorator="Required"
                autoComplete="off"
                startDecorator={template ? "Template Name:" : "Project Name:"}
                value={project.name}
                onChange={handleChange("name")}
              />
            </Grid>
            {tags?.length && !template ? (
              <Grid item xs={12}>
                <Select
                  slotProps={{
                    listbox: { sx: { zIndex: 9999 } },
                  }}
                  startDecorator="Season:"
                  endDecorator="Optional"
                  value={project.tagID || ""}
                  onChange={(e, v) =>
                    setProject((s) => ({ ...s, tagID: v as number }))
                  }
                >
                  {tags.map((t) => (
                    <Option key={t.id} value={t.id}>
                      <JoyTypography
                        startDecorator={
                          <img
                            src={t.image}
                            style={{
                              height: 40,
                              marginRight: 8,
                            }}
                          />
                        }
                      >
                        {t.name}
                      </JoyTypography>
                    </Option>
                  ))}
                </Select>
              </Grid>
            ) : (
              []
            )}
            {company?.defaultProjectVersion === 1 ? (
              <Grid item xs={12}>
                <Alert
                  onClick={() => {
                    setProject((s) => ({
                      ...s,
                      version: project.version === 2 ? 1 : 2,
                    }));
                  }}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      background: darken("#F2EAFD", 0.02),
                    },
                  }}
                  color="info"
                  endDecorator={
                    <Checkbox
                      checked={project.version === 2}
                      size="lg"
                      color="info"
                    />
                  }
                >
                  <Box>
                    <JoyTypography sx={{ fontWeight: 600 }}>
                      Try <JoyTypography>Maestro</JoyTypography>
                    </JoyTypography>
                    <JoyTypography
                      endDecorator={
                        <JoyTypography
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            window.open(
                              "https://helpdesk.rhapsody.la/whats-new/",
                              "_blank"
                            );
                          }}
                          sx={{
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          level="body3"
                        >
                          Learn more
                        </JoyTypography>
                      }
                      level="body2"
                      sx={{ color: "#1F1E21" }}
                    >
                      Next generation of {template ? "Template" : "Projects"}{" "}
                      with 50+ new features.
                    </JoyTypography>
                  </Box>
                </Alert>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="soft"
            color="neutral"
            onClick={() => dispatch(setFormOpen({ isOpen: false, formID }))}
          >
            Cancel
          </Button>
          <Button
            style={{
              background: "#2196f3",
              color: "white",
              opacity: disabled ? 0.5 : 1,
            }}
            onClick={save}
            disabled={disabled}
            endDecorator={<i className="far fa-chevron-right p-left"></i>}
          >
            Next
          </Button>
        </DialogActions>
      </DialogClose>
    </>
  );
}
