import { Payroll } from "entities/payroll";
import { useMemo } from "react";
import { useGetPayrollQuery, useGetPayrollsQuery } from "./payrollEndpoints";

export function usePayroll(payrollID: number | undefined) {
  const payrollQuery = useGetPayrollQuery(payrollID ?? 0, {
    skip: (payrollID ?? 0) <= 0,
  });
  const payrollEntity = payrollQuery.data;

  return useMemo(() => {
    const ret: typeof payrollQuery & { payroll?: Payroll | undefined } = {
      ...payrollQuery,
    };
    if (payrollEntity) ret.payroll = new Payroll(payrollEntity);
    return ret;
  }, [payrollEntity]);
}

export function usePayrolls() {
  const payrollQuery = useGetPayrollsQuery();
  const payrollEntitiesMap = payrollQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof payrollQuery & {
      payrolls?: Payroll[];
      payrollsMap?: { [id: number]: Payroll };
    } = {
      ...payrollQuery,
      payrolls: [],
      payrollsMap: {},
    };
    if (payrollEntitiesMap) {
      const payrolls = [];
      const payrollsMap = {};

      for (const key in payrollEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(payrollEntitiesMap, key)) {
          const c = payrollEntitiesMap[key];
          const payroll = new Payroll(c);
          payrolls.push(payroll);
          payrollsMap[payroll.id] = payroll;
        }
      }
      payrolls.sort((a, b) => a.name.localeCompare(b.name));
      ret.payrolls = payrolls;
      ret.payrollsMap = payrollsMap;
    }

    return ret;
  }, [payrollEntitiesMap]);
}
