import { Button } from "@mui/joy";
import { Box, Grid, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Id from "atoms/Id/Id";
import { useDispatch } from "react-redux";
import { setFormOpen, setSelectedProjectNoteID } from "reducers/rhapsody";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { useStages } from "redux/stage/stageHooks";

export default function ProjectNotesDataGrid({ projectID }) {
  const { stagesMap } = useStages();
  const { sectionRolesMap } = useSectionRoles();
  const { sectionsMap } = useSections();
  const { projectNotes, isLoading, isFetching } = useProjectNotes(projectID);

  const dispatch = useDispatch();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id startDecorator={<i className="fa-solid fa-notes"></i>}>
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1,
      valueGetter: (p) => p.row.name || "Untitled Note",
    },
    {
      field: "priority",
      headerName: "Priority",
      type: "number",
      flex: 1,
    },
    {
      field: "roles",
      headerName: "Roles",
      type: "string",
      flex: 1,
      valueGetter: (p) => {
        const r = [];
        p.value.split(",").map((e) => {
          const role = sectionRolesMap[parseInt(e ?? 0)];
          if (role) r.push(role.name);
        });
        return r.join(", ");
      },
    },
    {
      field: "sections",
      headerName: "Sections",
      type: "string",
      flex: 1,
      valueGetter: (p) => {
        const r = [];
        p.value.split(",").map((e) => {
          const section = sectionsMap[parseInt(e ?? 0)];
          if (section) r.push(section.name);
        });
        return r.join(", ");
      },
    },
    {
      field: "stages",
      headerName: "Stages",
      type: "string",
      flex: 1,
      valueGetter: (p) => {
        const r = [];
        p.value.split(",").map((e) => {
          const stage = stagesMap[parseInt(e ?? 0)];
          if (stage) r.push(stage.name);
        });
        return r.join(", ");
      },
    },
    {
      field: "autopilot",
      headerName: "Autopilot",
      type: "boolean",
      flex: 1,
    },
    {
      field: "include",
      headerName: "Include Auto",
      type: "boolean",
      flex: 1,
    },
  ];

  if (projectNotes.length === 0) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "50vh", textAlign: "center" }}
      >
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            {" "}
            <i style={{ fontSize: 70 }} class="fa-duotone fa-text-size"></i>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            Project Notes
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Create a rich text note that you can include in any of your call.
          </Typography>
          <br />
          <Button
            startDecorator={<i className="fa-solid fa-plus"></i>}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }))
            }
            sx={{ mt: 1 }}
          >
            Add Project Note
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <DataGridPro
      loading={isLoading || isFetching}
      onRowClick={(p) => {
        dispatch(setSelectedProjectNoteID(p.row.id));
      }}
      rows={projectNotes}
      columns={columns}
      disableSelectionOnClick
      onCellClick={(p) => {
        dispatch(setSelectedProjectNoteID(p.row));
        dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }));
      }}
      components={{
        Toolbar: CustomToolbar,
      }}
    />
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();

  return (
    <Box
      className="header"
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Box></Box>
      <Box sx={{ pt: 1, pr: 1, display: "flex", gap: 1 }}>
        <Button
          size="sm"
          variant="soft"
          color="neutral"
          onClick={() =>
            dispatch(
              setFormOpen({ isOpen: true, formID: "projectNotesFromProject" })
            )
          }
        >
          <i class="fa-solid fa-notes p-right"></i> Import from other Project...
        </Button>
        <Button
          size="sm"
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }))
          }
        >
          <i class="fa-solid fa-notes p-right"></i> New Project Note
        </Button>
      </Box>
    </Box>
  );
}
