import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { DanielsFragment_Entity } from "entities/danielsFragment";
import { librarianApi } from "redux/api/librarian";

const danielsFragmentsAdapter = createEntityAdapter<DanielsFragment_Entity>();
const initialState = danielsFragmentsAdapter.getInitialState();

export const danielsFragmentEndpoints = librarianApi.injectEndpoints({
  endpoints: (build) => ({
    danielsLookup: build.query<
      EntityState<DanielsFragment_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `pieces/daniels`,
          params: args,
        };
      },
      transformResponse: (responseData: DanielsFragment_Entity[]) => {
        return danielsFragmentsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["danielsFragments"],
    }),
  }),
});

export const { useDanielsLookupQuery, useLazyDanielsLookupQuery } =
  danielsFragmentEndpoints;

export default danielsFragmentEndpoints;
