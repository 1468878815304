import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Link_Entity } from "entities/link";
import { rhapsodyApi } from "../api/rhapsodyApi";

const linksAdapter = createEntityAdapter<Link_Entity>();
const initialState = linksAdapter.getInitialState();

export const linkEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getLinks: build.query<EntityState<Link_Entity>, void>({
      query: () => `links`,
      transformResponse: (responseData: Link_Entity[]) => {
        return linksAdapter.setAll(initialState, responseData);
      },
      providesTags: ["links"],
    }),
    getProjectLinks: build.query<EntityState<Link_Entity>, number>({
      query: (projectID) => {
        return {
          url: `links`,
          params: {
            filters: JSON.stringify([
              {
                name: "projectID",
                comparison: "eq",
                value: projectID,
              },
            ]),
          },
        };
      },
      transformResponse: (responseData: Link_Entity[]) => {
        return linksAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, id) => [
        { type: "links", id: `project_${id}` },
      ],
    }),
    getMusicianLinks: build.query<EntityState<Link_Entity>, number>({
      query: (musicianID) => {
        return {
          url: `links`,
          params: {
            filters: JSON.stringify([
              {
                name: "musicianID",
                comparison: "eq",
                value: musicianID,
              },
            ]),
          },
        };
      },
      transformResponse: (responseData: Link_Entity[]) => {
        return linksAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, id) => [
        { type: "links", id: `musician_${id}` },
      ],
    }),
    getTagLinks: build.query<EntityState<Link_Entity>, number>({
      query: (tagID) => {
        return {
          url: `links`,
          params: {
            filters: JSON.stringify([
              {
                name: "tagID",
                comparison: "eq",
                value: tagID,
              },
            ]),
          },
        };
      },
      transformResponse: (responseData: Link_Entity[]) => {
        return linksAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, id) => [
        { type: "links", id: `musician_${id}` },
      ],
    }),
    getWorkSessionLinks: build.query<EntityState<Link_Entity>, number>({
      query: (workSessionID) => {
        return {
          url: `links`,
          params: {
            filters: JSON.stringify([
              {
                name: "workSessionID",
                comparison: "eq",
                value: workSessionID,
              },
            ]),
          },
        };
      },
      transformResponse: (responseData: Link_Entity[]) => {
        return linksAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, id) => [
        { type: "links", id: `workSession_${id}` },
      ],
    }),
    getLink: build.query<Link_Entity, number>({
      query: (id) => `links/${id}`,
      providesTags: (result, error, id) => [{ type: "links", id }],
    }),
    createLink: build.mutation<Link_Entity, Partial<Link_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `links`,
      }),
      invalidatesTags: ["links"],
    }),
    updateLink: build.mutation<
      void,
      { id: number; body: Partial<Link_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `links/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "links", id },
        "links",
      ],
    }),
    deleteLink: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `links/${id}`,
      }),
      invalidatesTags: ["links"],
    }),
  }),
});

export const {
  useGetLinkQuery,
  useGetTagLinksQuery,
  useGetMusicianLinksQuery,
  useGetProjectLinksQuery,
  useGetWorkSessionLinksQuery,
  useGetLinksQuery,
  useCreateLinkMutation,
  useDeleteLinkMutation,
  useUpdateLinkMutation,
} = linkEndpoints;

export default linkEndpoints;
