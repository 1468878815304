import { ProjectPieceSection } from "entities/projectPieceSection";
import { useMemo } from "react";
import {
  useGetProjectPieceSectionQuery,
  useGetProjectPieceSectionsForProjectPieceQuery,
} from "./projectPieceSectionEndpoints";

export function useProjectPieceSection(
  projectPieceSectionID: number | undefined
) {
  const projectPieceSectionQuery = useGetProjectPieceSectionQuery(
    projectPieceSectionID ?? 0,
    {
      skip: (projectPieceSectionID ?? 0) <= 0,
    }
  );
  const projectPieceSectionEntity = projectPieceSectionQuery.data;

  return useMemo(() => {
    const ret: typeof projectPieceSectionQuery & {
      projectPieceSection?: ProjectPieceSection | undefined;
    } = {
      ...projectPieceSectionQuery,
    };
    if (projectPieceSectionEntity)
      ret.projectPieceSection = new ProjectPieceSection(
        projectPieceSectionEntity
      );
    return ret;
  }, [projectPieceSectionEntity]);
}

export function useProjectPieceSections(projectPieceID: number | undefined) {
  const projectPieceSectionQuery =
    useGetProjectPieceSectionsForProjectPieceQuery(projectPieceID ?? 0, {
      skip: (projectPieceID ?? 0) <= 0,
    });
  const projectPieceSectionEntitiesMap =
    projectPieceSectionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectPieceSectionQuery & {
      projectPieceSections?: ProjectPieceSection[];
      projectPieceSectionsMap?: { [id: number]: ProjectPieceSection };
    } = {
      ...projectPieceSectionQuery,
      projectPieceSections: [],
      projectPieceSectionsMap: {},
    };
    if (projectPieceSectionEntitiesMap) {
      const projectPieceSections = [];
      const projectPieceSectionsMap = {};

      for (const key in projectPieceSectionEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(
            projectPieceSectionEntitiesMap,
            key
          )
        ) {
          const c = projectPieceSectionEntitiesMap[key];
          const projectPieceSection = new ProjectPieceSection(c);
          projectPieceSections.push(projectPieceSection);
          projectPieceSectionsMap[projectPieceSection.id] = projectPieceSection;
        }
      }
      ret.projectPieceSections = projectPieceSections;
      ret.projectPieceSectionsMap = projectPieceSectionsMap;
    }

    return ret;
  }, [projectPieceSectionEntitiesMap]);
}
