import { InternalPosition_Entity } from "../InternalPosition";

/**
 * InternalSection_Entity type guard.
 *
 * @param {any} InternalSectionJson InternalSection object from API
 * @returns {boolean} Return true if type is InternalSection_Entity
 */

export default class InternalSection_Entity {
  constructor(InternalSectionJson: InternalSection_Entity) {
    try {
      this.sectionID = InternalSectionJson.sectionID;
      this.familyID = InternalSectionJson.familyID;
      this.sectionPos = InternalSectionJson.sectionPos;
      this.positions = InternalSectionJson.positions;
      this.forWorkSessionID = InternalSectionJson.forWorkSessionID;
      this.forProjectPieceID = InternalSectionJson.forProjectPieceID;
    } catch (e) {
      throw new Error(
        `Failed to create new instance of ${InternalSection_Entity.name}`
      );
    }
  }

  sectionID: number;
  familyID: number;
  sectionPos: number;
  positions: InternalPosition_Entity[];
  forWorkSessionID?: number;
  forProjectPieceID?: number;
  sameChairCountForAllProjectPieces?: boolean;
}
