import {
  Alert,
  Button,
  ButtonProps,
  Chip,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
} from "@mui/material";
import { alpha, Box } from "@mui/system";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Job } from "entities/job";
import { Musician } from "entities/musician";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { InstrumentPicker } from "features/musicians/InstrumentsPopover/InstrumentPicker";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { getCurrentStageName } from "helpers";
import { ReactElement, useEffect, useState } from "react";
import { useUpdateJobMutation } from "redux/job/jobEndpoints";
import { useUpdateMusicianInstrumentsMutation } from "redux/musician/musicianEndpoints";
import {
  useMusicians,
  useMusiciansForSection,
} from "redux/musician/musicianHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";

export function ChairAssignMusician({
  onSelect,
  label = "Click to assign a musician",
  disabled = false,
  job: _job,
  tooltip,
  button = false,
  iconButton = false,
  buttonProps = {},
}: {
  onSelect: (musicianID: number) => void;
  disabled?: boolean;
  label?: string | ReactElement;
  job: Job;
  tooltip?: string;
  button?: boolean;
  iconButton?: boolean;
  buttonProps?: ButtonProps;
}) {
  const job = _job;
  const { sectionsMap } = useSections();
  const { sectionRolesMap } = useSectionRoles();
  const role = job && sectionRolesMap[job.sectionRoleID];
  const [open, setOpen] = useState(false);
  const [updateJob] = useUpdateJobMutation();
  const [updateMusicianInstrument] = useUpdateMusicianInstrumentsMutation();
  const {
    musicians: musicianSuggestions,
    isLoading: l1,
    isFetching: f1,
  } = useMusiciansForSection(
    {
      sectionID: job?.sectionID,
      workSessionID: job?.sessionID,
      sectionRoleID: job?.sectionRoleID,
    },
    !open
  );

  const bestMatches = musicianSuggestions
    .filter((m) => m.onDeck && (!m.mercuryStage || !m.mercuryStage?.id))
    .sort((a, b) => a.order - b.order);
  const bestMatch = bestMatches.length ? bestMatches[0] : undefined;
  const onDeck = musicianSuggestions
    .filter((m) => m.onDeck)
    .sort((a, b) => a.order - b.order);
  const other = musicianSuggestions
    .filter((m) => !m.onDeck)
    .sort((a, b) => a.lastName?.localeCompare(b.lastName));
  const [searchText, setSearchText] = useState("");
  const {
    musicians: otherMusicians,
    isLoading: l2,
    isFetching: f2,
  } = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.firstName",
          comparison: "like",
          value: searchText.toLocaleLowerCase(),
          subFilters: [
            {
              name: "musicians.nickName",
              comparison: "like",
              value: searchText.toLocaleLowerCase(),
              orOperator: true,
            },
            {
              name: "musicians.lastName",
              comparison: "like",
              value: searchText.toLocaleLowerCase(),
              orOperator: true,
            },
          ],
        },
      ]),
    },
    { skip: searchText.length === 0 }
  );

  const askQuestion = useAskQuestion();

  const [colapseStatuses, setColapseStatuses] = useState({
    onDeck: onDeck.length > 0,
    other: onDeck.length === 0,
  });

  useEffect(() => {
    setColapseStatuses({
      onDeck: onDeck.length > 0,
      other: onDeck.length === 0,
    });
  }, [musicianSuggestions]);

  if (!job) return <Skeleton />;

  const handleInstrumentSelect = (
    instrumentIDs: number[],
    musician: Musician
  ) => {
    const musicianInstruments = musician.instruments.reduce((a, i) => {
      a.push(i.id);
      return a;
    }, []);
    instrumentIDs.forEach((instrumentID) => {
      if (musicianInstruments.indexOf(instrumentID) === -1)
        updateMusicianInstrument({ id: musician.id, body: { instrumentID } });
    });
  };

  const handleClose = () => {
    setOpen(false);
    setColapseStatuses({
      onDeck: false,
      other: false,
    });
    setSearchText("");
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleSelect = (musician: Musician, other?: boolean) => {
    handleClose();
    onSelect(musician.id);
    if (other && !job.instrumentIDs) {
      askQuestion("", [], {
        render: (onClose) => (
          <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Typography startDecorator={<MusicianAvatar musician={musician} />}>
              What instrument(s) will {musician.fullName()} be playing?
            </Typography>
            <Divider />
            <InstrumentPicker
              hideTitle={true}
              hideToolbar={true}
              sectionID={job.sectionID}
              onSelect={(instrumentIDs) => {
                onClose();
                updateJob({
                  id: job.id,
                  body: {
                    instrumentIDs: JSON.stringify(instrumentIDs),
                  },
                });
                handleInstrumentSelect(instrumentIDs, musician);
              }}
            />
            <Typography level="body3">
              Rhapsody will remember that {musician.fullName()} is also playing
              the selected instrument(s).
            </Typography>
          </Box>
        ),
      });
    } else {
      const instrumentIDs = JSON.parse(
        job.instrumentIDs ? job.instrumentIDs : "[]"
      );
      handleInstrumentSelect(instrumentIDs, musician);
    }
  };

  const musicianMenuItem = (m: Musician, other?: boolean) => {
    return (
      <ListItem key={m.id}>
        <ListItemButton onClick={() => handleSelect(m, other)}>
          <ListItemDecorator sx={{ alignSelf: "flex-start" }}>
            <MusicianAvatar noBadge size={25} musician={m} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography
              level="body3"
              startDecorator={
                m.star ? (
                  <i
                    className="fa-solid fa-heart"
                    style={{ color: "#e91e63" }}
                  ></i>
                ) : (
                  []
                )
              }
            >
              {m.fullName()}
            </Typography>
            <Sheet sx={{ background: "rgba(255,235,59,0.1)", width: "auto" }}>
              <Typography level="body3">{m.comments}</Typography>
            </Sheet>
          </ListItemContent>
          {m.order > 0 && m.onDeck ? (
            <Typography
              level="body3"
              sx={{ color: "#607d8b" }}
              startDecorator={
                <i className="fa-solid fa-arrow-right-to-line"></i>
              }
            >
              Sub {m.order}
            </Typography>
          ) : (
            []
          )}
          {m.mercuryStage?.id ? (
            <Chip
              size="sm"
              sx={{
                background: m.mercuryStage.color
                  ? alpha(m.mercuryStage.color, 0.1)
                  : undefined,
                color: m.mercuryStage.color,
              }}
            >
              {getCurrentStageName(m.mercuryStage)}
            </Chip>
          ) : (
            []
          )}
        </ListItemButton>
      </ListItem>
    );
  };

  const categories = [
    {
      key: "onDeck",
      data: onDeck,
      title: "On Deck",
      subtitle: "Musicians from your project's seating.",
    },
    // {
    //   key: "settled",
    //   data: settled,
    //   title: "Settled",
    //   subtitle: "Musicians who already answered.",
    // },
    {
      key: "other",
      data: other,
      title: "Other",
      subtitle: "Other compatible Musicians from your directory.",
    },
  ];

  return (
    <>
      {button ? (
        <Button
          disabled={disabled}
          onClick={!disabled && handleClick}
          {...buttonProps}
        >
          {label}
        </Button>
      ) : (
        []
      )}
      {iconButton ? (
        <Tooltip title={tooltip} size="sm">
          <IconButton
            disabled={disabled}
            onClick={!disabled && handleClick}
            sx={{ border: "solid 1px #bdbdbd" }}
            {...buttonProps}
          >
            {label}
          </IconButton>
        </Tooltip>
      ) : (
        []
      )}
      {!button && !iconButton ? (
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: alpha("#9e9e9e", 0.1),
            borderRadius: "8px",
            p: 0.5,
            flexGrow: 1,
            border: "dashed 1px rgba(155,155,155,0.3) ",
            cursor: !disabled ? "pointer" : undefined,
            "&:hover": !disabled
              ? {
                  background: alpha("#9e9e9e", 0.2),
                }
              : undefined,
            "&:active": !disabled
              ? {
                  background: alpha("#9e9e9e", 0.3),
                }
              : undefined,
          }}
          onClick={!disabled && handleClick}
        >
          <Typography level="body3">
            <i>{label}</i>
          </Typography>
        </Box>
      ) : (
        []
      )}
      <DialogClose
        open={open}
        onClose={handleClose}
        sx={{ background: "none" }}
      >
        <DialogTitle sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
          <Typography level="h6">Finding a Musician</Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography level="body3">
              {sectionsMap[job.sectionID]?.name}
            </Typography>
            <Typography
              level="body3"
              startDecorator={<i className="fa-solid fa-chair"></i>}
            >
              {job.order()}
            </Typography>
            <Typography level="body3">{role?.name}</Typography>
          </Box>
          <Input
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            size="sm"
            autoFocus
            color="neutral"
            variant="soft"
            placeholder="Search..."
            startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
            endDecorator={
              searchText ? (
                <IconButton
                  color="neutral"
                  variant="plain"
                  onClick={() => setSearchText("")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
              ) : (
                []
              )
            }
          />
          {bestMatch ? (
            <Alert
              onClick={() => handleSelect(bestMatch, false)}
              color="primary"
              variant="soft"
              sx={(t) => ({
                color: "white",
                cursor: "pointer",
                "&:hover": {
                  background: alpha(t.palette.primary[500], 0.4),
                },
                "&:active": {
                  background: alpha(t.palette.primary[500], 0.5),
                },
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  flex: 1,
                }}
              >
                <Box>
                  <Chip size="sm">Next in line</Chip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <MusicianAvatar noBadge size={36} musician={bestMatch} />
                    <Typography level="body2">
                      {bestMatch.fullName()}
                    </Typography>
                  </Box>
                  <Typography
                    level="body3"
                    sx={{ color: "#607d8b" }}
                    startDecorator={
                      <i className="fa-solid fa-arrow-right-to-line"></i>
                    }
                  >
                    Sub {bestMatch.order}
                  </Typography>
                </Box>
              </Box>
            </Alert>
          ) : (
            []
          )}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 500,
              display: "flex",
              gap: 1,
              flexDirection: "column",
            }}
          >
            {l1 || l2 || f1 || f2 ? (
              <>
                <Skeleton /> <Skeleton />
                <Skeleton />
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                  height: 500,
                }}
              >
                {!searchText ? (
                  categories
                    .filter((c) => c.data.length)
                    .map((c) => (
                      <Sheet
                        key={c.key}
                        variant="outlined"
                        sx={{
                          p: 1,
                          borderRadius: "8px",
                          "&:hover": {
                            background:
                              !colapseStatuses[c.key] && alpha("#9e9e9e", 0.1),
                          },
                        }}
                      >
                        <Box
                          onClick={() =>
                            setColapseStatuses((s) => ({
                              ...s,
                              [c.key]: !s[c.key],
                            }))
                          }
                          sx={{
                            display: "flex",
                            cursor: "pointer",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "baseline",
                              }}
                            >
                              <Typography
                                level="body2"
                                textColor="common.black"
                              >
                                {c.title}
                              </Typography>
                              <Chip
                                variant="outlined"
                                size="sm"
                                color="neutral"
                              >
                                {c.data.length} Musicians
                              </Chip>
                            </Box>
                            <Typography level="body3">{c.subtitle}</Typography>
                          </Box>
                          <IconButton
                            color="neutral"
                            variant="plain"
                            sx={{
                              rotate: colapseStatuses[c.key]
                                ? "180deg"
                                : "0deg",
                              transition: "rotate .3s",
                            }}
                          >
                            <i className="fa-solid fa-chevron-down"></i>
                          </IconButton>
                        </Box>

                        <Collapse in={colapseStatuses[c.key]}>
                          <List size="sm">
                            {c.data.map((m) => musicianMenuItem(m, false))}
                          </List>
                        </Collapse>
                      </Sheet>
                    ))
                ) : (
                  <List>
                    {musicianSuggestions
                      .filter(
                        (m) =>
                          m
                            .fullName()
                            .toLowerCase()
                            .indexOf(searchText.toLowerCase()) !== -1
                      )
                      .map((m) => musicianMenuItem(m, false))}
                    {searchText && otherMusicians.length ? (
                      <Divider>
                        Other musicians not playing this instrument
                      </Divider>
                    ) : (
                      []
                    )}
                    {searchText && otherMusicians.length
                      ? otherMusicians
                          ?.filter(
                            (m) =>
                              musicianSuggestions?.findIndex(
                                (ms) => ms.id === m.id
                              ) === -1
                          )
                          .map((m) => musicianMenuItem(m, true))
                      : []}
                  </List>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            fullWidth
            color="neutral"
            variant="soft"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogClose>
    </>
  );
}
