import { Box, Checkbox, Chip, Input, Textarea, Tooltip } from "@mui/joy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  formOpenSelector,
  pdModeSelector,
  selectedPdProjectIDSelector,
  selectedProjectIDSelector,
  setFormOpen,
  setSelectedPdProjectID,
} from "reducers/rhapsody";
import { useCreatePdGroupMutation } from "redux/pdGroup/pdGroupEndpoints";
import { usePdProject } from "redux/pdProject/pdProjectHooks";
import { PdFooter } from "./PdFooter";
import { PdGroupCard } from "./PdGroup";
import { PdHeader } from "./PdHeader";
import { PdLaborCard } from "./PdLabor";
import { PdProjectInfo } from "./PdProjectInfo";
import { useEffect, useState } from "react";
import { PdProject_Entity } from "entities/pdProject";
import { useUpdatePdProjectMutation } from "redux/pdProject/pdProjectEndpoints";

/**
 *
 * @returns {ReactElement} PayDayProject page
 */
export function PayDayProject() {
  const pdProjectID = useSelector(selectedPdProjectIDSelector);
  const rhapsodyProjectID = useSelector(selectedProjectIDSelector);
  const { pdProject } = usePdProject(pdProjectID);
  const open = useSelector(formOpenSelector("pdProject"));
  const [createPdGroup] = useCreatePdGroupMutation();
  const pdMode = useSelector(pdModeSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClose = () => {
    navigate(`${RouterConfig.projects}/${rhapsodyProjectID}/payrolls`);
    dispatch(setFormOpen({ formID: "pdProject", isOpen: false }));
    dispatch(setSelectedPdProjectID(null));
  };
  const [updatePdProject] = useUpdatePdProjectMutation();

  const [_pdProject, setPdProject] = useState<PdProject_Entity>(pdProject);

  useEffect(() => {
    setPdProject(pdProject);
  }, [pdProject]);

  const budget = (
    <>
      <PdProjectInfo />
      {pdProject?.groups.map((g) => (
        <PdGroupCard pdGroup={g} key={g.id}>
          <Tooltip
            size="sm"
            variant="outlined"
            arrow
            enterDelay={1000}
            enterNextDelay={1000}
            title="Separates Orchestra costs in 3 groups: Wages, Pension, and H&W"
          >
            <Checkbox
              size="sm"
              checked={_pdProject?.splitCosts ?? false}
              label="Split Costs"
              onChange={(e) => {
                setPdProject((p) => ({
                  ...p,
                  splitCosts: e.target.checked,
                }));
                updatePdProject({
                  id: _pdProject.id,
                  body: { ..._pdProject, splitCosts: e.target.checked },
                });
              }}
            />
          </Tooltip>
        </PdGroupCard>
      ))}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Chip
          onClick={() => createPdGroup({ projectID: pdProjectID })}
          color="neutral"
          variant="outlined"
          sx={{ background: "white" }}
        >
          + Group
        </Chip>
      </Box>
    </>
  );

  const payroll = (
    <>
      <Box />
      <PdLaborCard labors={pdProject?.labors} />
    </>
  );

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle sx={{ background: "#F7F9FC", p: 0 }}>
        <PdHeader onClose={onClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          background: "#F7F9FC",
        }}
      >
        {pdMode === "budget" ? budget : []}
        {pdMode === "payroll" ? payroll : []}
      </DialogContent>
      {pdMode === "budget" ? (
        <DialogActions sx={{ p: 0 }}>
          <PdFooter />
        </DialogActions>
      ) : (
        []
      )}
    </Dialog>
  );
}
