import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Trail_Entity } from "entities/trail";
import { postalApi } from "../api/postalApi";

const trailsAdapter = createEntityAdapter<Trail_Entity>();
const initialState = trailsAdapter.getInitialState();

export const trailEndpoints = postalApi.injectEndpoints({
  endpoints: (build) => ({
    getTrails: build.query<
      EntityState<Trail_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `trails`,
          params: args,
        };
      },
      transformResponse: (responseData: Trail_Entity[]) => {
        return trailsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["trails"],
    }),
    getTrail: build.query<Trail_Entity, number>({
      query: (id) => `trails/${id}`,
      providesTags: (result, error, id) => [{ type: "trails", id }],
    }),
    createTrail: build.mutation<Trail_Entity, Partial<Trail_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `trails`,
      }),
      invalidatesTags: ["trails"],
    }),
    updateTrail: build.mutation<
      void,
      { id: number; body: Partial<Trail_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `trails/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "trails", id },
        "trails",
      ],
    }),
    deleteTrail: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `trails/${id}`,
      }),
      invalidatesTags: ["trails"],
    }),
  }),
});

export const {
  useGetTrailQuery,
  useGetTrailsQuery,
  useCreateTrailMutation,
  useDeleteTrailMutation,
  useUpdateTrailMutation,
} = trailEndpoints;

export default trailEndpoints;
