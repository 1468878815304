import * as Sentry from "@sentry/react";
import AppRouter from "hooks/AppRouter";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

import { LicenseInfo } from "@mui/x-data-grid-pro";
import { AppConfig } from "environement";

LicenseInfo.setLicenseKey(
  "0b7d8832b2c146c6fa2ee663544550c0Tz05ODcwOCxFPTE3NTg3NTU4MTIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);

Sentry.init({
  dsn: "https://86e51c010f414b5aa4a9d76ae6c99a60@o1058683.ingest.sentry.io/4505484927959040",

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

const root = ReactDOM.createRoot(document.getElementById("root"));

if (
  AppConfig.api.endpoint.includes("demo") ||
  AppConfig.api.endpoint.includes("alpha")
) {
  root.render(
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
} else {
  root.render(
    <Sentry.ErrorBoundary showDialog={true}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
