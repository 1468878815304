import { Box, Divider, Hidden, Tab, Tabs } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/joy";
import Id from "atoms/Id/Id";
import PayrollDataGrid from "features/dataGrids/JobsDataGrid";
import LinksDataGrid from "features/dataGrids/LinksDataGrid/LinksDataGrid";
import WorkSessionDangerZone from "features/workSessions/WorkSessionDangerZone";
import { useRouteMatch } from "helpers";
import Hiring from "hooks/HiringRefactor/HiringRefactor";
import ReportForm from "hooks/ReportForm/ReportForm";
import Roster from "hooks/Roster";
import { Helmet } from "react-helmet";
import { Link, Route, Routes, useParams } from "react-router-dom";
import {
  selectedWorkSessionIDSelector,
  setSelectedWorkSessionID,
  setTabIndex,
  tabIndexSelector,
} from "reducers/rhapsody";
import { useWorkSession } from "redux/workSession/workSessionHooks";

export default function WorkSessionPage({ id, routerMode = false }) {
  const params = useParams();
  const dispatch = useDispatch();
  const workSessionID =
    id ||
    parseInt(params.workSessionID) ||
    useSelector(selectedWorkSessionIDSelector);
  const { workSession } = useWorkSession(workSessionID);
  const tabIndex = useSelector(tabIndexSelector("workSession"));

  useEffect(() => {
    dispatch(setSelectedWorkSessionID(workSessionID));
    return () => {
      dispatch(setSelectedWorkSessionID());
    };
  }, []);

  if (!workSession) return <div />;

  const paths = [
    "hiring",
    "links",
    "roster",
    "payroll",
    "report-form",
    "danger-zone",
  ];

  const contents = [
    <>
      <Hiring key={paths[0]} workSessionID={workSessionID} />
    </>,
    <Box
      key={paths[1]}
      sx={{
        width: "100%",
        display: "flex",
        p: 2,
        minHeight: "calc(100vh - 154px)",
      }}
    >
      <LinksDataGrid workSessionID={workSessionID} x />
    </Box>,
    <Roster key={paths[2]} id={workSessionID} />,
    <PayrollDataGrid key={paths[3]} id={workSessionID} />,
    <ReportForm key={paths[4]} id={workSessionID} />,
    <WorkSessionDangerZone key={paths[5]} id={workSessionID} />,
  ];

  return (
    <>
      <Box
        sx={{
          top: -65,
          position: "sticky",
          zIndex: 100,
          background: "white",
        }}
      >
        <Helmet>
          <title>Rhapsody | {workSession.title ?? ""}</title>
        </Helmet>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
          <Box>
            <Typography level="h6">{workSession.title}</Typography>
            <Typography level="body2" endDecorator={<Id>{workSession.id}</Id>}>
              Work Session
            </Typography>
          </Box>
          <Hidden smDown>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <Typography level="h6">{workSession.projectName}</Typography>
              <Typography
                level="body2"
                endDecorator={<Id>{workSession.projectID}</Id>}
              >
                Project
              </Typography>
            </Box>
          </Hidden>
        </Box>
        <MyTabs routerMode={routerMode} />
        <Divider />
      </Box>
      {routerMode ? (
        <Routes>
          {contents.map((e, i) => (
            <Route key={paths[i]} path={paths[i]} element={e}></Route>
          ))}
        </Routes>
      ) : (
        contents[tabIndex]
      )}
    </>
  );
}

function MyTabs({ routerMode }) {
  const routeMatch = useRouteMatch([
    "/work-sessions/:id/hiring",
    "/work-sessions/:id/links",
    "/work-sessions/:id/roster",
    "/work-sessions/:id/payroll",
    "/work-sessions/:id/report-form",
    "/work-sessions/:id/danger-zone",
  ]);
  const currentTab = routeMatch?.pattern?.path;
  const dispatch = useDispatch();
  const tabIndex = useSelector(tabIndexSelector("workSession"));

  return (
    <Tabs
      variant="scrollable"
      value={routerMode ? currentTab : tabIndex}
      onChange={
        !routerMode &&
        ((e, v) => dispatch(setTabIndex({ tabID: "workSession", value: v })))
      }
    >
      <Tab
        label="Hiring"
        value={routerMode ? "/work-sessions/:id/hiring" : 0}
        to={routerMode && "hiring"}
        component={routerMode && Link}
      />
      <Tab
        label="Links"
        value={routerMode ? "/work-sessions/:id/links" : 1}
        to={routerMode && "links"}
        component={routerMode && Link}
      />
      <Tab
        label="Roster"
        value={routerMode ? "/work-sessions/:id/roster" : 2}
        to={routerMode && "roster"}
        component={routerMode && Link}
      />
      <Tab
        label="Payroll"
        value={routerMode ? "/work-sessions/:id/payroll" : 3}
        to={routerMode && "payroll"}
        component={routerMode && Link}
      />
      <Tab
        label="Report Form"
        value={routerMode ? "/work-sessions/:id/report-form" : 4}
        to={routerMode && "report-form"}
        component={routerMode && Link}
      />
      <Tab
        label="Danger Zone"
        value={routerMode ? "/work-sessions/:id/danger-zone" : 5}
        to={routerMode && "danger-zone"}
        component={routerMode && Link}
      />
    </Tabs>
  );
}
