import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/joy";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { RosterOptions } from "hooks/projectRoster/projectRoster";
import { BoxWrapper } from "hooks/projectSettings/projectSettings";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setFormOpen, setSelectedProjectRosterID } from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";

/**
 *
 * @returns {ReactElement} ProjectRostersPicker page
 */
export function ProjectRostersPicker({
  onChange,
}: {
  onChange: (e: number[]) => void;
}) {
  const utils = useSelector(layoutUtilsSelector);
  const { projectRosters } = utils;
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  return (
    <BoxWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            startDecorator={<i className="fa-regular fa-users"></i>}
            level="h6"
          >
            Live Rosters
          </Typography>
          <Typography level="body3">
            Create a live PDF roster, always up-to-date using a public link.
            <br />
            Select the Roster you want to include
          </Typography>
        </Box>
        <Tooltip
          title="New Link"
          variant="outlined"
          arrow
          size="sm"
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <IconButton
            disabled={readOnly}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "roster" }))
            }
            variant="solid"
            size="sm"
            color="neutral"
          >
            <i className="fa-solid fa-plus"></i>
          </IconButton>
        </Tooltip>
      </Box>
      {projectRosters.length ? (
        <DataGridPremium
          sx={{ background: "white" }}
          checkboxSelection
          onRowSelectionModelChange={(e) => {
            onChange(e as number[]);
          }}
          hideFooter
          disableRowSelectionOnClick
          onRowClick={(r, e) => {
            dispatch(setSelectedProjectRosterID(r.row.id));
            dispatch(setFormOpen({ isOpen: true, formID: "roster" }));
          }}
          initialState={{
            pinnedColumns: {
              right: ["publicToken", "options"],
            },
          }}
          density="compact"
          rows={projectRosters}
          columns={[
            { field: "name", headerName: "Name", flex: 1 },
            {
              field: "publicToken",
              headerName: "URL",
              align: "right",
              renderCell: (p) => (
                <Tooltip
                  title={p.row.publicUrl()}
                  size="sm"
                  arrow
                  variant="outlined"
                >
                  <Chip
                    endDecorator={
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    }
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(p.row.publicUrl(), "_blank");
                    }}
                  >
                    Open link
                  </Chip>
                </Tooltip>
              ),
            },
            {
              field: "options",
              headerName: "Active",
              renderCell: (p) => {
                let options: RosterOptions;

                try {
                  options = JSON.parse(p.row.options);
                } catch (error) {}
                return (
                  <Typography color={options.active ? "success" : "danger"}>
                    <i
                      className={
                        options.active
                          ? "fa-duotone fa-solid fa-square-check"
                          : "fa-solid fa-xmark"
                      }
                    ></i>
                  </Typography>
                );
              },
            },
          ]}
        />
      ) : (
        []
      )}
    </BoxWrapper>
  );
}
