import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Input,
  Typography,
} from "@mui/joy";
import { ConfettiCenterContext } from "features/context/ConfettiCenter/ConfettiCenter";
import { useContext, useEffect, useState } from "react";
import { useUpdateCompanyMutation } from "redux/company/companyEndpoints";
import { useCurrentCompany } from "redux/company/companyHooks";
/**
 *
 * @returns {ReactElement} SyncStripe page
 */
export function SyncStripe() {
  const [stripeCustomerID, setStripeCustomerID] = useState("");
  const { company } = useCurrentCompany();
  const [companyID, setCompanyID] = useState(``);
  const [updateCompany, { isSuccess, isLoading }] = useUpdateCompanyMutation();
  const handleSync = () => {
    updateCompany({ id: parseInt(companyID), body: { stripeCustomerID } });
  };
  const ConfettiCenter = useContext(ConfettiCenterContext);

  useEffect(() => {
    if (company?.id) setCompanyID(`${company?.id}`);
  }, [company]);

  useEffect(() => {
    if (isSuccess) ConfettiCenter.show();
  }, [isSuccess]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        mt: 2,
        gap: 2,
        textAlign: "center",
      }}
    >
      <img
        style={{ height: 100 }}
        src={"https://storage.googleapis.com/rhapsody/sync.png"}
      />
      <Typography level="h6">Sync Stripe with Rhapsody</Typography>
      <Typography level="body2">
        For new Finale users only, the Stripe <b>CUSTOMER ID</b>
        <br />
        needs to be added to the user's organization.
      </Typography>
      {!isSuccess ? (
        <>
          <Input
            startDecorator="Customer ID:"
            variant="soft"
            placeholder="Copy/Paste from Stripe"
            value={stripeCustomerID}
            onChange={(e) => setStripeCustomerID(e.target.value)}
          />
          <Input
            startDecorator="Company ID:"
            variant="soft"
            type="tel"
            value={companyID}
            onChange={(e) => setCompanyID(e.target.value)}
          />
          <Button
            startDecorator={isLoading ? <CircularProgress /> : []}
            onClick={handleSync}
          >
            Sync Now
          </Button>
        </>
      ) : (
        <Alert color="success">
          Success! The user should now have access to his Finale plan
        </Alert>
      )}
    </Box>
  );
}
