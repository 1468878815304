import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCookie } from "helpers";
import { mezzo, PricingPlan } from "hooks/subscription/restrictionHooks";
import { RootState } from "store";

interface OnboardingState {
  pricingPlan: PricingPlan;
}

const initialState = {
  pricingPlan: getCookie("pricingPlan"),
} as OnboardingState;

export const onboardingSlice = createSlice({
  name: "onboardingSlice",
  initialState,
  reducers: {
    setPricingPlan(state, action: PayloadAction<Partial<PricingPlan>>) {
      state.pricingPlan = action.payload;
    },
  },
});

export const { setPricingPlan } = onboardingSlice.actions;

export const pricingPlanSelector = createSelector(
  (s: RootState) => s.onboardingSlice.pricingPlan,
  (s) => s
);
