import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { VerificationCode_Entity } from "entities/verificationCode";
import { accountApi } from "../api/accountApi";

const verificationCodesAdapter = createEntityAdapter<VerificationCode_Entity>();
const initialState = verificationCodesAdapter.getInitialState();

export const verificationCodeEndpoints = accountApi.injectEndpoints({
  endpoints: (build) => ({
    getVerificationCodes: build.query<
      EntityState<VerificationCode_Entity>,
      void
    >({
      query: () => `verificationCodes`,
      transformResponse: (responseData: VerificationCode_Entity[]) => {
        return verificationCodesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["verificationCodes"],
    }),
    getVerificationCode: build.query<VerificationCode_Entity, number>({
      query: (id) => `verificationCodes/${id}`,
      providesTags: (result, error, id) => [{ type: "verificationCodes", id }],
    }),
    createVerificationCode: build.mutation<
      VerificationCode_Entity,
      Partial<VerificationCode_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `verificationCodes`,
      }),
      invalidatesTags: ["verificationCodes"],
    }),
    updateVerificationCode: build.mutation<
      void,
      { id: number; body: Partial<VerificationCode_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `verificationCodes/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "verificationCodes", id },
        "verificationCodes",
      ],
    }),
    deleteVerificationCode: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `verificationCodes/${id}`,
      }),
      invalidatesTags: ["verificationCodes"],
    }),
    validateVerificationCode: build.mutation<
      VerificationCode_Entity,
      { code: string; value: string }
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `verificationCodes/validate`,
      }),
      invalidatesTags: ["verificationCodes"],
    }),
    resendVerificationCode: build.query<VerificationCode_Entity, number>({
      query: (id) => ({
        method: "GET",
        url: `verificationCodes/${id}/resend`,
      }),
    }),
  }),
});

export const {
  useGetVerificationCodeQuery,
  useGetVerificationCodesQuery,
  useCreateVerificationCodeMutation,
  useDeleteVerificationCodeMutation,
  useUpdateVerificationCodeMutation,
  useValidateVerificationCodeMutation,
  useLazyResendVerificationCodeQuery,
} = verificationCodeEndpoints;

export default verificationCodeEndpoints;
