// import PropTypes from 'prop-types';

// styles
import { Button, Typography } from "@mui/joy";
import { Divider, Hidden, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import WorkSessionsDataGrid from "features/dataGrids/WorkSessionsDataGrid/WorkSessionsDataGrid";
import { heightWithToolbar } from "global";
import { updateURLParameter } from "helpers";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { setFormOpen, setTabIndex, tabIndexSelector } from "reducers/rhapsody";

export default function WorkSessionsPage() {
  const params = useParams();
  const tabIndex = useSelector(tabIndexSelector("workSessions"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const readOnly = useReadOnly();

  if (params.workSessionID) return <Outlet />;
  return (
    <>
      <Helmet>
        <title>Rhapsody | Work Sessions</title>
      </Helmet>
      <Box
        sx={{
          p: 2,
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "stretch",
          margin: "auto",
          maxWidth: 1600,
          width: "100%",
          position: "relative",
          flex: 1,
          height: heightWithToolbar,
        }}
      >
        <Box sx={{ width: "100%", position: "relative" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography level="h5">Work Sessions</Typography>
            <Button
              onClick={() =>
                dispatch(setFormOpen({ isOpen: true, formID: "workSession" }))
              }
              disabled={readOnly}
              size="sm"
              startDecorator={<i className="fa-solid fa-plus"></i>}
            >
              <Hidden smDown>Add</Hidden> Work Session
            </Button>
          </Box>
          <Hidden smDown>
            <Tabs
              value={tabIndex}
              onChange={(e, v) => {
                dispatch(setTabIndex({ tabID: "workSessions", value: v }));
                navigate({
                  search: updateURLParameter("workSessions", v),
                });
              }}
            >
              <Tab label="Active" />
              <Tab label="Archived" />
            </Tabs>
          </Hidden>
          <Divider />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            height: 0,
            minHeight: "auto",
          }}
        >
          <WorkSessionsDataGrid
            bulkActions
            autoheight={false}
            archived={tabIndex === 1}
            active={tabIndex === 0}
          />
        </Box>
      </Box>
    </>
  );
}
