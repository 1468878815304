import { useMemo } from "react";
import {
  useGetProjectMercuryQuery,
  useGetSeasonUpcomingStagesQuery,
  useGetUpcomingStagesQuery,
} from "./projectMercuryEndpoints";
import { HProject } from "entities/hProject";
import { useSelector } from "react-redux";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { CompanySettings } from "redux/company/companyHooks";
import { seasonUtilsSelector } from "reducers/v2/seasonControl";

export function useProjectMercury2(
  args: { id: number; body: { musicianIDs?: number[]; nudge?: boolean } },
  opt?: { skip?: boolean },
  settings?: CompanySettings
) {
  const utils = useSelector(layoutUtilsSelector);
  const projectMercuryQuery = useGetUpcomingStagesQuery(args, {
    skip: opt?.skip,
    refetchOnMountOrArgChange: true,
  });

  let projectMercuryEntity = projectMercuryQuery.data;
  const assignmentsForSubs = settings?.assignmentsForSubs ?? true;
  const canContactArchivedMusicians =
    settings?.canContactArchivedMusicians ?? false;

  if (
    (!assignmentsForSubs || !canContactArchivedMusicians) &&
    projectMercuryEntity
  ) {
    const hProject = JSON.parse(JSON.stringify(projectMercuryEntity));

    hProject.jobs?.forEach((j) => {
      const musician = utils?.musiciansMap[j.refID];
      if (musician?.sub && !assignmentsForSubs) {
        j.validUpcomingStageIDs = j.validUpcomingStageIDs?.filter(
          (s) => s !== 24
        );
        j.validUpcomingStages = j.validUpcomingStages?.filter(
          (s) => s.id !== 24
        );
      }

      if (!canContactArchivedMusicians && !musician?.active) {
        j.validUpcomingStages = [];
        j.validUpcomingStageIDs = [];
      }
    });

    hProject.upcomingStages?.forEach((s, i) => {
      s.jobIDs.forEach((jID) => {
        const job = hProject?.jobs?.find((j) => j.id === jID);
        if (!assignmentsForSubs) {
          if (!job?.validUpcomingStageIDs?.includes(s.id) && s.jobIDs) {
            s.jobIDs = s.jobIDs.filter((j) => j !== jID);
          }
        }
      });
    });

    projectMercuryEntity = hProject;
  }

  return useMemo(() => {
    const ret: typeof projectMercuryQuery & {
      hProject?: HProject | undefined;
    } = {
      ...projectMercuryQuery,
    };
    if (projectMercuryEntity) ret.hProject = new HProject(projectMercuryEntity);
    return ret;
  }, [projectMercuryEntity]);
}

export function useSeasonMercury(
  args: { id: number; body: { musicianIDs?: number[]; nudge?: boolean } },
  opt?: { skip?: boolean },
  settings?: CompanySettings
) {
  const utils = useSelector(seasonUtilsSelector);
  const projectMercuryQuery = useGetSeasonUpcomingStagesQuery(args, {
    skip: opt?.skip,
    refetchOnMountOrArgChange: true,
  });

  let projectMercuryEntity = projectMercuryQuery.data;
  const assignmentsForSubs = settings?.assignmentsForSubs ?? true;
  const canContactArchivedMusicians =
    settings?.canContactArchivedMusicians ?? false;

  if (
    (!assignmentsForSubs || !canContactArchivedMusicians) &&
    projectMercuryEntity
  ) {
    const hProject = JSON.parse(JSON.stringify(projectMercuryEntity));

    hProject.jobs?.forEach((j) => {
      const musician = utils?.musiciansMap[j.refID];
      if (musician?.sub && !assignmentsForSubs) {
        j.validUpcomingStageIDs = j.validUpcomingStageIDs?.filter(
          (s) => s !== 24
        );
        j.validUpcomingStages = j.validUpcomingStages?.filter(
          (s) => s.id !== 24
        );
      }

      if (!canContactArchivedMusicians && !musician?.active) {
        j.validUpcomingStages = [];
        j.validUpcomingStageIDs = [];
      }
    });

    hProject.upcomingStages?.forEach((s, i) => {
      s.jobIDs.forEach((jID) => {
        const job = hProject?.jobs?.find((j) => j.id === jID);
        if (!assignmentsForSubs) {
          if (!job?.validUpcomingStageIDs.includes(s.id) && s.jobIDs) {
            s.jobIDs = s.jobIDs.filter((j) => j !== jID);
          }
        }
      });
    });

    projectMercuryEntity = hProject;
  }

  return useMemo(() => {
    const ret: typeof projectMercuryQuery & {
      hProject?: HProject | undefined;
    } = {
      ...projectMercuryQuery,
    };
    if (projectMercuryEntity) ret.hProject = new HProject(projectMercuryEntity);
    return ret;
  }, [projectMercuryEntity]);
}

export function useProjectMercury(
  projectID: number | undefined,
  opt?: { skip?: boolean }
) {
  const projectMercuryQuery = useGetProjectMercuryQuery(projectID ?? 0, {
    skip: (projectID ?? 0) <= 0 || opt?.skip,
  });
  const projectMercuryEntity = projectMercuryQuery.data;

  return useMemo(() => {
    const ret: typeof projectMercuryQuery & {
      projectMercury?: HProject | undefined;
    } = {
      ...projectMercuryQuery,
    };
    if (projectMercuryEntity)
      ret.projectMercury = new HProject(projectMercuryEntity);
    return ret;
  }, [projectMercuryEntity]);
}
