// import PropTypes from 'prop-types';

// styles
import {
  Button,
  Chip,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuItem,
  Typography,
} from "@mui/joy";
import { Divider, Hidden, Tab, Tabs, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { nanoid } from "@reduxjs/toolkit";
import { AppConfig } from "environement";
import MusiciansDataGrid from "features/dataGrids/MusiciansDataGrid";
import { useExporter } from "features/exporter/exporter";
import { mapToArray } from "helpers";
import {
  mezzo,
  overture,
  useMySubscription,
} from "hooks/subscription/restrictionHooks";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import {
  setFormOpen,
  setSelectedGroupID,
  setTabIndex,
  tabIndexSelector,
} from "reducers/rhapsody";
import { useGroups } from "redux/group/groupHooks";
import {
  useCreateMusicianMutation,
  useUpdateMusicianInstrumentsMutation,
  useUpdateMusicianMutation,
} from "redux/musician/musicianEndpoints";
import { useMusicians } from "redux/musician/musicianHooks";
import { useCreateProjectMutation } from "redux/project/projectEndpoints";
import {
  useCreateProjectAlternateMutation,
  useLazyGetProjectAlternatesQuery,
  useUpdateBatchProjectAlternateMutation,
} from "redux/projectAlternate/projectAlternateEndpoints";
import LAPhilJSON from "../../la-phil.json";
import firstNames from "./firstNames.json";
import lastNames from "./lastNames.json";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { layoutDebugSelector } from "reducers/v2/missionControl";
import { MusicianBirthdays } from "hooks/MusicianBirthdays/MusicianBirthdays";
import { PotentialMusicianDuplicates } from "features/musicians/PotentialMusicianDuplicates/PotentialMusicianDuplicates";
import { heightWithToolbar } from "global";

export default function MusiciansPage() {
  const param = useParams();
  const { groups } = useGroups();
  const [anchorEl, setAnchorEl] = useState();
  const tabIndex = useSelector(tabIndexSelector("musicians"));
  const { musicians } = useMusicians();
  const debug = useSelector(layoutDebugSelector);
  const [updateMusician] = useUpdateMusicianMutation();
  const [createMusician] = useCreateMusicianMutation();
  const [updateMusicianInstrument] = useUpdateMusicianInstrumentsMutation();
  const [createProject] = useCreateProjectMutation();
  const [createProjectAlternate] = useCreateProjectAlternateMutation();
  const [useLazyGetProjectAlternates] = useLazyGetProjectAlternatesQuery();
  const [updateBatchProjectAlternate] =
    useUpdateBatchProjectAlternateMutation();
  const dispatch = useDispatch();
  const subscription = useMySubscription();
  const readOnly = useReadOnly();

  const exporter = useExporter();

  const resetEmails = async () => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setAnchorEl(null);
    const proms = [];
    for (const key in musicians) {
      if (Object.hasOwnProperty.call(musicians, key)) {
        const m = musicians[key];
        proms.push(
          updateMusician({
            id: m.id,
            body: { email: `${nanoid(10)}@ignore.it` },
          }).unwrap()
        );
      }
    }

    await Promise.all(proms);

    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
  };

  const removePhones = async () => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setAnchorEl(null);
    const proms = [];
    for (const key in musicians) {
      if (Object.hasOwnProperty.call(musicians, key)) {
        const m = musicians[key];
        proms.push(
          updateMusician({
            id: m.id,
            body: { phone: "", contactBySMS: false },
          }).unwrap()
        );
      }
    }

    await Promise.all(proms);

    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
  };

  const randomIntBetween = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  const fakeMusicians = async () => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setAnchorEl(null);
    const proms = [];

    for (let index = 0; index < 25; index++) {
      const female = Math.random() < 0.5;
      proms.push(
        createMusician({
          sub: false,
          firstName: female
            ? firstNames.female[randomIntBetween(1, 100)]
            : firstNames.male[randomIntBetween(1, 100)],
          lastName: lastNames[randomIntBetween(1, 100)],
          email: `${nanoid(10)}@ignore.it`,
          avatar: `https://storage.googleapis.com/rhapsody/Avatars/${
            female ? "female" : "male"
          }/${randomIntBetween(1, 50)}.jpg`,
        }).unwrap()
      );
    }
    await Promise.all(proms);

    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
  };

  const laPhil = () => {
    setAnchorEl(null);
    const musiciansObjects = musicians.reduce((a, v) => {
      a[v.email] = v;
      return a;
    }, {});
    const musicianData = {};
    LAPhilJSON.musicians.forEach((i) => {
      if (
        musicians?.findIndex(
          (m) => m.email === `${i.firstName}.${i.lastName}@ignore.it`
        ) === -1
      )
        createMusician({
          firstName: i.firstName,
          lastName: i.lastName,
          email: `${i.firstName}.${i.lastName}@ignore.it`,
          avatar: i.avatar,
          sub: false,
        })
          .unwrap()
          .then((m) => {
            musicianData[`${i.firstName}.${i.lastName}@ignore.it`] = i;
            musiciansObjects[`${i.firstName}.${i.lastName}@ignore.it`] = m;
            updateMusicianInstrument({
              id: m.id,
              body: { instrumentID: i.instrumentID },
            });
          });
    });
    createProject({
      name: "La Phil",
      template: true,
    })
      .unwrap()
      .then((e) => {
        const projectID = e.id;
        const body = LAPhilJSON.instrumentation;
        body.forEach((c) => (c.projectID = projectID));
        createProjectAlternate({ projectID, body })
          .unwrap()
          .then(() => {
            useLazyGetProjectAlternates(projectID)
              .unwrap()
              .then((e) => {
                const body = [];
                const projectAlternates = mapToArray(e.entities);
                let order = -1;
                LAPhilJSON.musicians.forEach((m, i) => {
                  if (
                    LAPhilJSON.musicians[i - 1] &&
                    LAPhilJSON.musicians[i - 1]?.sectionID !== m.sectionID
                  ) {
                    order = 0;
                  } else {
                    order++;
                  }
                  const projectAlternate = projectAlternates.find((p) => {
                    return (
                      p.sectionID === m.sectionID && p.sectionOrder === order
                    );
                  });
                  if (projectAlternate) {
                    body.push({
                      ...projectAlternate,
                      sectionRoleID: m.sectionRoleID,
                      instrumentIDs: `[${m.instrumentID}]`,
                      musicianID:
                        musiciansObjects[
                          `${m.firstName}.${m.lastName}@ignore.it`
                        ]?.id,
                    });
                  } else {
                    console.log(projectAlternates, order, i, m);
                    debugger;
                  }
                });
                updateBatchProjectAlternate(body);
              });
          });
      });
  };

  const openSubscribe = () =>
    dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));

  let decorator;
  const musicianCount = musicians?.filter((m) => m.active)?.length;
  if (subscription === overture) {
    decorator = (
      <Tooltip title="Upgrade to get more Musicians in your Address Book" arrow>
        <Chip onClick={openSubscribe} size="sm" color="warning">
          {musicianCount}/30 Musicians
        </Chip>
      </Tooltip>
    );
  }

  if (subscription === mezzo) {
    decorator = (
      <Tooltip title="Upgrade to get more Musicians in your Address Book" arrow>
        <Chip onClick={openSubscribe} size="sm" color="warning">
          {musicianCount}/100 Musicians
        </Chip>
      </Tooltip>
    );
  }

  if (param.musicianID) return <Outlet />;

  return (
    <>
      <Helmet>
        <title>Rhapsody | Address Book</title>
      </Helmet>
      <Box
        sx={{
          p: 2,
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "stretch",
          margin: "auto",
          maxWidth: 1600,
          width: "100%",
          position: "relative",
          flex: 1,
          height: heightWithToolbar,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography endDecorator={decorator} level="h5">
                Address Book
              </Typography>
              <MusicianBirthdays />
            </Box>
            {debug ? (
              <Box>
                <Typography level="body3">
                  {musicians?.length} musicians,{" "}
                  {musicians.filter((m) => !m.active).length} archived
                  musicians, {musicians.filter((m) => m.userUUID).length} mobile
                  app users (
                  {musicians?.length
                    ? Math.round(
                        (musicians.filter((m) => m.userUUID).length /
                          musicians?.length) *
                          100 *
                          100
                      ) / 100
                    : 0}
                  % Adoption). <PotentialMusicianDuplicates />
                </Typography>
              </Box>
            ) : (
              []
            )}
            <Box sx={{ display: "flex", gap: 1 }}>
              <Hidden smDown>
                <IconButton
                  disabled={readOnly}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  size="sm"
                  variant="outlined"
                  color="neutral"
                >
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </IconButton>
              </Hidden>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                size="sm"
                sx={{ maxWidth: 250 }}
              >
                <MenuItem
                  onClick={() => {
                    exporter.musiciansDatabse();
                    setAnchorEl(null);
                  }}
                >
                  <ListItemDecorator>
                    <i className="fa-duotone fa-table"></i>
                  </ListItemDecorator>
                  Export Musician Database in Excel
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    exporter.musicianImportTemplate();
                    setAnchorEl(null);
                  }}
                >
                  <ListItemDecorator>
                    <i className="fa-solid fa-arrow-down-to-line"></i>
                  </ListItemDecorator>
                  <Box>
                    Bulk Import Template
                    <Typography level="body3">
                      Import multiple musicians at once by filling out this
                      template.
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    dispatch(
                      setFormOpen({
                        formID: "uploadMusicianTemplate",
                        isOpen: true,
                      })
                    );
                  }}
                >
                  <ListItemDecorator>
                    <i className="fa-solid fa-arrow-up-to-line"></i>
                  </ListItemDecorator>
                  <Box>
                    Upload Bulk Import Template
                    <Typography level="body3">
                      Re-upload the template filled out with musicians.
                    </Typography>
                  </Box>
                </MenuItem>
                {AppConfig.api.endpoint.indexOf("live") === -1 ? (
                  <>
                    <Divider />
                    <MenuItem onClick={resetEmails}>
                      <ListItemDecorator>
                        <i className="fa-solid fa-envelope"></i>
                      </ListItemDecorator>
                      <Box>
                        Reset all email
                        <Typography level="body3">using @ignore.it</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem onClick={removePhones}>
                      <ListItemDecorator>
                        <i className="fa-solid fa-phone-xmark"></i>
                      </ListItemDecorator>
                      <Box>
                        Remove all phones
                        <Typography level="body3">
                          To prevent sending SMS
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem onClick={laPhil}>
                      <ListItemDecorator>
                        <img
                          src="https://storage.googleapis.com/rhapsody/Avatars/laphil/laphil.png"
                          height="25"
                          style={{ opacity: 0.6 }}
                        />
                      </ListItemDecorator>
                      <Box>
                        LA Phil
                        <Typography level="body3">
                          Add LA Phil Musicians
                          <br />+ Offical Template
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem onClick={fakeMusicians}>
                      <ListItemDecorator>
                        <i className="fa-duotone fa-face-disguise"></i>
                      </ListItemDecorator>
                      <Box>
                        Demo Data
                        <Typography level="body3">
                          Add 25 fake musicians
                        </Typography>
                      </Box>
                    </MenuItem>
                  </>
                ) : (
                  []
                )}
              </Menu>
              <Button
                onClick={() =>
                  dispatch(setFormOpen({ isOpen: true, formID: "group" }))
                }
                disabled={readOnly}
                size="sm"
                variant="soft"
                color="neutral"
                startDecorator={<i className="fa-solid fa-plus"></i>}
              >
                <Hidden smDown>Add</Hidden> Group
              </Button>
              <Button
                disabled={readOnly}
                onClick={() =>
                  dispatch(setFormOpen({ formID: "musician", isOpen: true }))
                }
                size="sm"
                startDecorator={<i className="fa-solid fa-plus"></i>}
              >
                <Hidden smDown>Add</Hidden> Musician
              </Button>
            </Box>
          </Box>
          <Hidden smDown>
            <Tabs
              value={tabIndex}
              onChange={(e, v) => {
                console.log(v);
                dispatch(setTabIndex({ tabID: "musicians", value: v }));
              }}
            >
              <Tab label="All" value={-1} />
              <Tab label="Subs" value={0} />
              {groups.map((g) => (
                <Tab
                  onDoubleClick={() => {
                    dispatch(setSelectedGroupID(g.id));
                    dispatch(setFormOpen({ isOpen: true, formID: "group" }));
                  }}
                  label={
                    <Tooltip title="Double click to rename">
                      <Typography
                        sx={{
                          fontSize: "inherit",
                          fontWeight: "inherit",
                          color: "inherit",
                        }}
                      >
                        {g.name}
                      </Typography>
                    </Tooltip>
                  }
                  value={g.id}
                  key={g.id}
                />
              ))}
            </Tabs>
          </Hidden>
          <Divider />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            height: 0,
            minHeight: "auto",
          }}
        >
          <MusiciansDataGrid autoheight={false} groupID={tabIndex} />
        </Box>
      </Box>
    </>
  );
}
