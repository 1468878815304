import { CircularProgress } from "@mui/joy";
import { Box, Tab, Tabs } from "@mui/material";
import EmailsDataGrid from "features/dataGrids/EmailsDataGrid";
import LinksDataGrid from "features/dataGrids/LinksDataGrid/LinksDataGrid";
import ProjectNotesDataGrid from "features/dataGrids/ProjectNotesDataGrid";
import { MissionControl } from "features/projects/ProjectMissionControl/projectMissionControl";
import { Subs } from "features/subs/subs";
import { heightWithToolbar } from "global";
import { useRouteMatch } from "helpers";
import Calendar from "hooks/Calendar";
import { PayDayPayroll } from "hooks/PayDayPayroll/PayDayPayroll";
import { ProjectSettings } from "hooks/projectSettings/projectSettings";
import { ProjectSideBar } from "hooks/projectSideBar/v2";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Link as RouterLink, Routes } from "react-router-dom";
import {
  selectedProjectIDSelector,
  setSelectedProjectID,
} from "reducers/rhapsody";
import {
  internalInitSelector,
  layoutUnselectAll,
  layoutUtilsSelector,
  setInternalInit,
  setLayoutUtilsInit,
  setSelectedProjectPieceIDs,
  setSelectedWorkSessionIDs,
} from "reducers/v2/missionControl";
import { rhapsodyApi } from "redux/api";

function ProjectHelmet() {
  const utils = useSelector(layoutUtilsSelector);
  const project = utils?.project;

  return (
    <Helmet>
      <title>{project?.name}</title>
    </Helmet>
  );
}

export default function ProjectPagev2({ template = false }) {
  const projectID = useSelector(selectedProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const project = utils?.project;
  const dispatch = useDispatch();
  const internalInit = useSelector(internalInitSelector);

  const baseRoute = template ? "templates" : "projects";

  const routeMatch = useRouteMatch([
    `/${baseRoute}/:id/calendar`,
    `/${baseRoute}/:id/mission-control`,
    `/${baseRoute}/:id/notes`,
    `/${baseRoute}/:id/sent`,
    `/${baseRoute}/:id/payrolls`,
    `/${baseRoute}/:id/who-is-next`,
    `/${baseRoute}/:id/links`,
    `/${baseRoute}/:id/settings`,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedWorkSessionIDs({}));
      dispatch(setSelectedProjectID());
      dispatch(setSelectedProjectPieceIDs({}));
      dispatch(setLayoutUtilsInit(false));
      dispatch(setInternalInit(false));
      dispatch(layoutUnselectAll());
      rhapsodyApi.util.invalidateTags([
        "assignments",
        "projectPieces",
        "chairs",
        "workSessions",
      ]);
    };
  }, []);

  const loading = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        height: `calc(${heightWithToolbar} - 85px)`,
      }}
    >
      <CircularProgress />
    </Box>
  );

  const content = (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: "column",
        margin: "auto",
      }}
    >
      <ProjectHelmet />
      <Box
        sx={{
          display: "flex",
          height: heightWithToolbar,
          position: "relative",
        }}
      >
        <ProjectSideBar />
        <Box
          sx={{
            height: "100%",
            flexGrow: 1,
            display: "flex",
            position: "relative",
            flexDirection: "column",
            width: 0,
            minWidth: "auto",
          }}
        >
          <MyTabs
            routeMatch={routeMatch}
            baseRoute={baseRoute}
            template={template || project?.template}
          />
          <Box
            sx={{
              position: "relative",
              overflow: "auto",
              flexGrow: 1,
              display: "flex",
            }}
          >
            <Routes>
              <Route
                key={"calendar"}
                path={"calendar"}
                element={<Calendar projectID={projectID} />}
              ></Route>

              <Route
                key={"mission-control"}
                path={"mission-control"}
                element={<MissionControl />}
              ></Route>
              <Route
                key={"subs"}
                path={"who-is-next"}
                element={<Subs />}
              ></Route>
              <Route
                key={"sent"}
                path={"sent"}
                element={
                  <Box
                    sx={{
                      p: 1,
                      display: "flex",
                      flex: 1,
                      justifyContent: "stretch",
                    }}
                  >
                    <EmailsDataGrid projectID={projectID} />
                  </Box>
                }
              ></Route>
              <Route
                key={"notes"}
                path={"notes"}
                element={<ProjectNotesDataGrid projectID={projectID} />}
              ></Route>

              <Route
                key={"links"}
                path={"links"}
                element={<LinksDataGrid projectID={projectID} />}
              ></Route>
              <Route
                key={"payrolls"}
                path={"payrolls"}
                element={<PayDayPayroll />}
              >
                <Route path={":pdProjectID/*"} />
              </Route>
              <Route
                key={"settings"}
                path={"settings"}
                element={<ProjectSettings />}
              ></Route>
            </Routes>
          </Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );

  return <>{internalInit ? content : loading}</>;
}

function MyTabs({ routeMatch, baseRoute, template }) {
  const currentTab = routeMatch?.pattern?.path;

  let tabs = [
    <Tab
      label="Mission Control"
      key="mission-control"
      value={`/${baseRoute}/:id/mission-control`}
      to="mission-control"
      component={RouterLink}
    />,
    <Tab
      label="Who's Next"
      key="subs"
      value={`/${baseRoute}/:id/who-is-next`}
      to="who-is-next"
      component={RouterLink}
    />,
    <Tab
      label="Calendar"
      key="calendar"
      value={`/${baseRoute}/:id/calendar`}
      to="calendar"
      component={RouterLink}
    />,
    <Tab
      label="Sent"
      key="sent"
      value={`/${baseRoute}/:id/sent`}
      to="sent"
      component={RouterLink}
    />,
    <Tab
      label="Budget & Payroll"
      key="payrolls"
      value={`/${baseRoute}/:id/payrolls`}
      to="payrolls"
      component={RouterLink}
    />,
    <Tab
      label="Settings"
      key="settings"
      value={`/${baseRoute}/:id/settings`}
      to="settings"
      component={RouterLink}
    />,
  ];

  return (
    <Tabs value={currentTab} variant="scrollable" sx={{ width: "100%" }}>
      {template ? [tabs[0], tabs[1], tabs[5]] : tabs}
    </Tabs>
  );
}
