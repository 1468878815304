import { Section } from "entities/section";
import { useMemo } from "react";
import { useGetSectionQuery, useGetSectionsQuery } from "./sectionEndpoints";

export function useSection(sectionID: number | undefined) {
  const sectionQuery = useGetSectionQuery(sectionID ?? 0, {
    skip: (sectionID ?? 0) <= 0,
  });
  const sectionEntity = sectionQuery.data;

  return useMemo(() => {
    const ret: typeof sectionQuery & { section?: Section | undefined } = {
      ...sectionQuery,
    };
    if (sectionEntity) ret.section = new Section(sectionEntity);
    return ret;
  }, [sectionEntity]);
}

export function useSections(opt?: { skip: boolean }) {
  const sectionQuery = useGetSectionsQuery(null, opt);
  const sectionEntitiesMap = sectionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof sectionQuery & {
      sections?: Section[];
      sectionsMap?: { [id: number]: Section };
    } = {
      ...sectionQuery,
      sections: [],
      sectionsMap: {},
    };
    if (sectionEntitiesMap) {
      const sections = [];
      const sectionsMap = {};

      for (const key in sectionEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(sectionEntitiesMap, key)) {
          const c = sectionEntitiesMap[key];
          const section = new Section(c);
          sections.push(section);
          sectionsMap[section.id] = section;
        }
      }
      sections.sort((a, b) => {
        if (a.familyID === b.familyID) {
          return a.pos - b.pos;
        }
        return a.familyPos - b.familyPos;
      });
      ret.sections = sections;
      ret.sectionsMap = sectionsMap;
    }

    return ret;
  }, [sectionEntitiesMap]);
}
