import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { NoteType_Entity } from "entities/noteType";
import { rhapsodyApi } from "../api/rhapsodyApi";

const noteTypesAdapter = createEntityAdapter<NoteType_Entity>();
const initialState = noteTypesAdapter.getInitialState();

export const noteTypeEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getNoteTypes: build.query<EntityState<NoteType_Entity>, void>({
      query: () => `noteTypes`,
      transformResponse: (responseData: NoteType_Entity[]) => {
        return noteTypesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["noteTypes"],
    }),
    getNoteType: build.query<NoteType_Entity, number>({
      query: (id) => `noteTypes/${id}`,
      providesTags: (result, error, id) => [{ type: "noteTypes", id }],
    }),
    createNoteType: build.mutation<NoteType_Entity, Partial<NoteType_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `noteTypes`,
      }),
      invalidatesTags: ["noteTypes"],
    }),
    updateNoteType: build.mutation<
      void,
      { id: number; body: Partial<NoteType_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `noteTypes/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "noteTypes", id },
        "noteTypes",
      ],
    }),
    deleteNoteType: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `noteTypes/${id}`,
      }),
      invalidatesTags: ["noteTypes"],
    }),
  }),
});

export const {
  useGetNoteTypeQuery,
  useGetNoteTypesQuery,
  useCreateNoteTypeMutation,
  useDeleteNoteTypeMutation,
  useUpdateNoteTypeMutation,
} = noteTypeEndpoints;

export default noteTypeEndpoints;
