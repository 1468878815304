import { Typography, useMediaQuery, useTheme } from "@mui/material";

import SaveIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// material-ui

import DialogClose from "atoms/DialogClose/DialogClose";
import PhoneInput from "atoms/PhoneInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { useCreateCustomerMutation } from "redux/customer/customerEndpoints";

export default function FormCustomer() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("lg"));
  const formOpen = useSelector(formOpenSelector("customer"));
  const [createCustomer, { isLoading }] = useCreateCustomerMutation();
  const [customer, setCustomer] = useState({
    name: "",
    address: "",
    state: "",
    city: "",
    zipcode: "",
    contact: "",
    phone: "",
    email: "",
  });

  const dispatch = useDispatch();

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setCustomer((s) => ({ ...s, [name]: value }));
  };

  const save = async () => {
    createCustomer(customer);
    dispatch(setFormOpen({ isOpen: false, formID: "customer" }));
  };

  const disabled = name.length === 0;

  return (
    <DialogClose
      onClose={() =>
        dispatch(setFormOpen({ isOpen: false, formID: "customer" }))
      }
      open={formOpen}
      scroll="paper"
      aria-labelledby="responsive-dialog-title"
    >
      {isLoading ? (
        <div>
          <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogContent>
            <div
              style={{
                background: "#e3f2fd",
                padding: 16,
                borderRadius: "16px",
                textAlign: "center",
              }}
            >
              <i
                style={{ fontSize: 80, color: "#001E3C" }}
                class="fad fa-building"
              ></i>
              <Typography style={{ color: "#001E3C" }} variant="h6">
                New Customer
              </Typography>
              <Typography
                style={{ color: "#001E3C", opacity: 0.8 }}
                variant="body2"
              >
                A customer is an entity you are working with.
                <br />
                You do projects for them.
              </Typography>
            </div>
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  autoFocus
                  helperText="Required"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={customer.name}
                  onChange={handleChange("name")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address"
                  label="Address"
                  variant="outlined"
                  size="small"
                  value={customer.address}
                  onChange={handleChange("address")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="city"
                  label="City"
                  variant="outlined"
                  size="small"
                  value={customer.city}
                  onChange={handleChange("city")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="zipcode"
                  label="Zipcode"
                  variant="outlined"
                  size="small"
                  value={customer.zipcode}
                  onChange={handleChange("zipcode")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="state"
                  label="State"
                  variant="outlined"
                  size="small"
                  value={customer.state}
                  onChange={handleChange("state")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="contact"
                  label="Contact"
                  variant="outlined"
                  size="small"
                  value={customer.contact}
                  onChange={handleChange("contact")}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  id="phone"
                  label="Phone"
                  variant="outlined"
                  size="small"
                  value={customer.phone}
                  onChange={handleChange("phone")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  value={customer.email}
                  onChange={handleChange("email")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                dispatch(setFormOpen({ isOpen: false, formID: "customer" }))
              }
            >
              <CloseIcon />
              Cancel
            </Button>
            <Button
              style={{
                background: "#2196f3",
                color: "white",
                opacity: disabled ? 0.5 : 1,
              }}
              autoFocus
              variant="contained"
              disabled={customer.name.length === 0}
              onClick={save}
            >
              <SaveIcon />
              {"Create"}
            </Button>
          </DialogActions>
        </>
      )}
    </DialogClose>
  );
}
