import { Alert, Box, IconButton, LinearProgress } from "@mui/joy";
import { Snackbar } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorSelector, setError } from "reducers/error";
import { useCountUp } from "use-count-up";

/**
 *
 * @returns {ReactElement} ErrorMonitor page
 */
export function ErrorMonitor() {
  const error = useSelector(errorSelector);
  const dispatch = useDispatch();
  const { value, reset } = useCountUp({
    isCounting: true,
    duration: 5,
    easing: "linear",
    start: 0,
    end: 100,
    onComplete: () => ({
      shouldRepeat: false,
      delay: 2,
    }),
  });

  useEffect(() => {
    if (error) {
      reset();
      setTimeout(() => dispatch(setError()), 5000);
    }
  }, [error]);

  let color: "danger" | "warning" = "warning";
  if (`${error?.status}x`[0] === "5") color = "danger";

  return (
    <Snackbar
      open={error !== undefined}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <LinearProgress
          determinate
          value={Number(value)}
          color={color}
          size="sm"
        />
        <Alert
          variant="solid"
          color={color}
          size="sm"
          endDecorator={
            <IconButton
              onClick={() => dispatch(setError())}
              color="neutral"
              variant="plain"
              size="sm"
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          }
        >
          <b>{error?.status}</b>: {error?.message} {error?.description}
        </Alert>
      </Box>
    </Snackbar>
  );
}
