import { RhapsodyChair } from "entities/rhapsodyChair";
import { useMemo } from "react";
import { useGetChairQuery, useGetChairsQuery } from "./rhapsodyChairEndpoints";
import { useCompanySettings } from "redux/company/companyHooks";

export function useChair(chairID: number | undefined) {
  const chairQuery = useGetChairQuery(chairID ?? 0, {
    skip: (chairID ?? 0) <= 0,
  });
  const chairEntity = chairQuery.data;

  return useMemo(() => {
    const ret: typeof chairQuery & { chair?: RhapsodyChair | undefined } = {
      ...chairQuery,
    };
    if (chairEntity) ret.chair = new RhapsodyChair(chairEntity);
    return ret;
  }, [chairEntity]);
}

export function useChairs(projectID) {
  const { apiPreview } = useCompanySettings();
  const chairQuery = useGetChairsQuery(
    {
      options: !apiPreview
        ? JSON.stringify({
            hash: { cleanup: "true" },
          })
        : undefined,
      filters: JSON.stringify([
        {
          name: "chairs.projectID",
          comparison: "eq",
          value: projectID,
        },
      ]),
    },
    { skip: !projectID }
  );
  const chairEntitiesMap = chairQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof chairQuery & {
      chairs?: RhapsodyChair[];
      chairsMap?: { [id: number]: RhapsodyChair };
    } = {
      ...chairQuery,
      chairs: [],
      chairsMap: {},
    };
    if (chairEntitiesMap) {
      const chairs = [];
      const chairsMap = {};

      for (const key in chairEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(chairEntitiesMap, key)) {
          const c = chairEntitiesMap[key];
          const chair = new RhapsodyChair(c);
          chairs.push(chair);
          chairsMap[chair.id] = chair;
        }
      }
      ret.chairs = chairs;
      ret.chairsMap = chairsMap;
    }

    return ret;
  }, [chairEntitiesMap]);
}

export function useChairsFilter(params?: any, config?: any) {
  const chairQuery = useGetChairsQuery(params, config);
  const chairEntitiesMap = chairQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof chairQuery & {
      chairs?: RhapsodyChair[];
      chairsMap?: { [id: number]: RhapsodyChair };
    } = {
      ...chairQuery,
      chairs: [],
      chairsMap: {},
    };
    if (chairEntitiesMap) {
      const chairs = [];
      const chairsMap = {};

      for (const key in chairEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(chairEntitiesMap, key)) {
          const c = chairEntitiesMap[key];
          const chair = new RhapsodyChair(c);
          chairs.push(chair);
          chairsMap[chair.id] = chair;
        }
      }
      ret.chairs = chairs;
      ret.chairsMap = chairsMap;
    }

    return ret;
  }, [chairEntitiesMap]);
}
