import { CircularProgress, Input, Typography } from "@mui/joy";
import { Box, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import { useCurrentCompany } from "redux/company/companyHooks";

export default function CompanyInfo() {
  const [_company, setCompany] = useState();
  const { company } = useCurrentCompany();
  const navigate = useNavigate();
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();

  useEffect(() => {
    if (company && !_company) {
      setCompany(company);
    }
  }, [company]);

  const handleChange = (name) => (e) => {
    setCompany((c) => ({ ...c, [name]: e.target.value }));
  };

  const save = async () => {
    updateCurrentCompany({ ..._company });
  };

  if (!_company) return <CircularProgress />;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 600,
        margin: "auto",
      }}
    >
      <Paper
        variant="outlined"
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Typography level="h4" sx={{ fontWeight: 600 }}>
          Your Organization
        </Typography>
        <Typography level="body2">
          Now, let's talk about your organization.
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ textAlign: "left", alignItems: "center" }}
        >
          <Grid item xs={12}>
            <Input
              onBlur={save}
              startDecorator="Name:"
              size="lg"
              placeholder="What is the name of your Organization?"
              onChange={handleChange("name")}
              value={_company.name}
              color={_company.name ? "success" : "neutral"}
              label="Company Name"
            />
            <Typography level="body3">* Required</Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              onBlur={save}
              size="lg"
              placeholder="Where are you located?"
              startDecorator="Address:"
              onChange={handleChange("address")}
              value={_company.address}
              color={_company.address ? "success" : "neutral"}
              label="Address"
            />
            <Typography level="body3">* Required</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              onBlur={save}
              size="lg"
              onChange={handleChange("city")}
              startDecorator="City:"
              value={_company.city}
              color={_company.city ? "success" : "neutral"}
              label="City"
            />
            <Typography level="body3">* Required</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              onBlur={save}
              size="lg"
              onChange={handleChange("zipcode")}
              value={_company.zipcode}
              color={_company.zipcode ? "success" : "neutral"}
              label="Zipcode"
              startDecorator="Zip:"
            />
            <Typography level="body3">* Required</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              onBlur={save}
              size="lg"
              startDecorator="State:"
              onChange={handleChange("state")}
              value={_company.state}
              color={_company.state ? "success" : "neutral"}
              label="State"
            />
            <Typography level="body3">* Required</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
