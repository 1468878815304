import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Typography,
} from "@mui/joy";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
} from "@mui/material";
import { Box, alpha } from "@mui/system";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Chair_Entity } from "entities/chair";
import { Musician } from "entities/musician";
import {
  ProjectAlternate,
  ProjectAlternate_Entity,
} from "entities/projectAlternate";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { InstrumentPicker } from "features/musicians/InstrumentsPopover/InstrumentPicker";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { Section } from "hooks/Layout/types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  layoutInternalSelector,
  layoutSelectionSelector,
  layoutSetMoveToSectionID,
  layoutUnselectAll,
} from "reducers/layout";
import { selectedProjectIDSelector, setFormOpen } from "reducers/rhapsody";
import { useUpdateMusicianInstrumentsMutation } from "redux/musician/musicianEndpoints";
import {
  useMusicians,
  useMusiciansForProjectSection,
} from "redux/musician/musicianHooks";
import {
  useUpdateBatchProjectAlternateMutation,
  useUpdateProjectAlternateMutation,
} from "redux/projectAlternate/projectAlternateEndpoints";
import { useProjectAlternates } from "redux/projectAlternate/projectAlternateHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";

export function ChairAssignProjectMusician({
  onSelect,
  highlight,
  label = "Click to assign a musician",
  disabled = false,
  emptyChairIndex,
  projectAlternate,
}: {
  onSelect: (musicianID: number) => void;
  disabled?: boolean;
  highlight?: boolean;
  emptyChairIndex?: number;
  label?: string;
  projectAlternate: ProjectAlternate;
}) {
  const { sectionsMap } = useSections();
  const { sectionRolesMap } = useSectionRoles();
  const selecto = useSelector(layoutSelectionSelector);
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectAlternatesMap } = useProjectAlternates(projectID);
  const [updateBatchProjectAlternates] =
    useUpdateBatchProjectAlternateMutation();
  const internal = useSelector(layoutInternalSelector);
  const dispatch = useDispatch();
  const role =
    projectAlternate && sectionRolesMap[projectAlternate.sectionRoleID];
  const [open, setOpen] = useState(false);
  const askQuestion = useAskQuestion();
  const [updateProjectAlternate] = useUpdateProjectAlternateMutation();
  const [updateMusicianInstrument] = useUpdateMusicianInstrumentsMutation();
  const { musicians: musicianSuggestions, isLoading: loading } =
    useMusiciansForProjectSection(
      {
        sectionID: projectAlternate.sectionID,
        projectID: projectAlternate.projectID,
        sectionRoleID: projectAlternate.sectionRoleID,
      },
      !open
    );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!highlight) onMouseLeave();
  }, [highlight]);

  const { musicians: otherMusicians, isLoading: otherLoading } = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.firstName",
          comparison: "like",
          value: searchText.toLocaleLowerCase(),
          subFilters: [
            {
              name: "musicians.nickName",
              comparison: "like",
              value: searchText.toLocaleLowerCase(),
              orOperator: true,
            },
            {
              name: "musicians.lastName",
              comparison: "like",
              value: searchText.toLocaleLowerCase(),
              orOperator: true,
            },
          ],
        },
      ]),
    },
    { skip: searchText.length === 0 }
  );

  if (!projectAlternate) return <Skeleton />;

  const handleClose = () => {
    setOpen(false);
    setSearchText("");
  };

  const handleClick = () => {
    if (highlight) {
      moveMusicians();
    } else {
      setOpen(true);
    }
  };

  function moveMusicians() {
    // we set the musicians
    const emptyChairs: Chair_Entity[] = [];
    internal.forEach((f) => {
      f.sections.forEach((s) => {
        if (s.sectionID === projectAlternate.sectionID) {
          s.chairs.forEach((c) => {
            if (c.sectionOrder < projectAlternate.sectionOrder) return;
            if (c.emptyChairIndex >= 0 && emptyChairs.length < selecto.length)
              emptyChairs.push(c);
          });
        }
      });
    });

    const body: Partial<ProjectAlternate_Entity[]> = JSON.parse(
      JSON.stringify(emptyChairs)
    );

    for (const key in body) {
      if (Object.prototype.hasOwnProperty.call(body, key)) {
        body[key].musicianID =
          projectAlternatesMap[selecto[parseInt(key)]].musicianID;
      }
    }

    selecto.forEach((musicianID) => {
      body.push({ ...projectAlternatesMap[musicianID], musicianID: -1 });
    });
    updateBatchProjectAlternates(body);
    dispatch(layoutSetMoveToSectionID());
    dispatch(layoutUnselectAll());
  }

  const handleInstrumentSelect = (
    instrumentIDs: number[],
    musician: Musician
  ) => {
    const musicianInstruments = musician.instruments.reduce((a, i) => {
      a.push(i.id);
      return a;
    }, []);
    instrumentIDs.forEach((instrumentID) => {
      if (musicianInstruments.indexOf(instrumentID) === -1)
        updateMusicianInstrument({ id: musician.id, body: { instrumentID } });
    });
  };

  const handleSelect = (musician: Musician, other?: boolean) => {
    handleClose();
    onSelect(musician.id);
    if (other && !projectAlternate.instrumentIDs) {
      askQuestion("", [], {
        render: (onClose) => (
          <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Typography startDecorator={<MusicianAvatar musician={musician} />}>
              What instrument(s) will {musician.fullName()} be playing?
            </Typography>
            <Divider />
            <InstrumentPicker
              hideTitle={true}
              hideToolbar={true}
              sectionID={projectAlternate.sectionID}
              onSelect={(instrumentIDs) => {
                onClose();
                updateProjectAlternate({
                  id: projectAlternate.id,
                  body: {
                    instrumentIDs: JSON.stringify(instrumentIDs),
                  },
                });
                handleInstrumentSelect(instrumentIDs, musician);
              }}
            />
            <Typography level="body3">
              Rhapsody will remember that {musician.fullName()} is also playing
              the selected instrument(s).
            </Typography>
          </Box>
        ),
      });
    } else {
      const instrumentIDs = JSON.parse(projectAlternate.instrumentIDs);
      handleInstrumentSelect(instrumentIDs, musician);
    }
  };

  const musicianMenuItem = (m: Musician, other?: boolean) => {
    return (
      <ListItem key={m.id}>
        <ListItemButton onClick={() => handleSelect(m, other)}>
          <ListItemDecorator sx={{ alignSelf: "flex-start" }}>
            <MusicianAvatar noBadge size={25} musician={m} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography
              level="body3"
              startDecorator={
                m.star ? (
                  <i
                    className="fa-solid fa-heart"
                    style={{ color: "#e91e63" }}
                  ></i>
                ) : (
                  []
                )
              }
            >
              {m.fullName()}
            </Typography>
            <Typography startDecorator={[]} level="body4">
              {m.instrumentNames}
            </Typography>
            <Sheet sx={{ background: "rgba(255,235,59,0.1)", width: "auto" }}>
              <Typography level="body3">{m.comments}</Typography>
            </Sheet>
          </ListItemContent>
          {m.order > 0 ? (
            <Typography
              level="body3"
              sx={{ color: "#607d8b" }}
              startDecorator={
                <i className="fa-solid fa-arrow-right-to-line"></i>
              }
            >
              Sub {m.order}
            </Typography>
          ) : (
            []
          )}
        </ListItemButton>
      </ListItem>
    );
  };

  function onMouseEnter() {
    for (let index = 0; index < selecto.length; index++) {
      const elem = document.getElementById(
        `emptyChair_${projectAlternate?.sectionID}_${emptyChairIndex + index}`
      ) as any;
      elem.style.background = alpha("#4caf50", 0.3);
      elem.style.border = `solid 1px #4caf50`;
    }
  }

  function onMouseLeave() {
    const elements = document.getElementsByClassName(`emptyChair`);
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index] as any;
      element.style.background = alpha("#9e9e9e", 0.1);
      element.style.border = `dashed 1px rgba(155, 155, 155, 0.3)`;
    }
  }

  return (
    <div>
      <Box
        onMouseEnter={highlight ? onMouseEnter : undefined}
        onMouseLeave={highlight ? onMouseLeave : undefined}
        id={
          emptyChairIndex >= 0
            ? `emptyChair_${projectAlternate?.sectionID}_${emptyChairIndex}`
            : ""
        }
        className="emptyChair"
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
          background: alpha("#9e9e9e", 0.1),
          borderRadius: "8px",
          height: 31.81,
          p: 0.5,
          flexGrow: 1,
          border: `dashed 1px rgba(155, 155, 155, 0.3)`,
          cursor: !disabled ? "pointer" : undefined,
          "&:hover": !disabled
            ? {
                background: highlight
                  ? alpha("#2196f3", 0.4)
                  : alpha("#9e9e9e", 0.2),
              }
            : undefined,
          "&:active": !disabled
            ? {
                background: highlight
                  ? alpha("#2196f3", 0.5)
                  : alpha("#9e9e9e", 0.3),
              }
            : undefined,
        }}
        onClick={!disabled && handleClick}
      >
        <Typography level="body3">
          <i>{label}</i>
        </Typography>
      </Box>
      <DialogClose
        open={open}
        onClose={handleClose}
        sx={{ background: "none" }}
      >
        <DialogTitle sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
          <Typography level="h6">Finding a Musician</Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography level="body3">
              {sectionsMap[projectAlternate.sectionID]?.name}
            </Typography>
            <Typography
              level="body3"
              startDecorator={<i className="fa-solid fa-chair"></i>}
            >
              {projectAlternate.order()}
            </Typography>
            <Typography level="body3">{role?.name}</Typography>
          </Box>
          {!loading && (
            <Input
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              size="sm"
              autoFocus
              color="neutral"
              variant="soft"
              placeholder="Search..."
              startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
              endDecorator={
                searchText ? (
                  <IconButton
                    color="neutral"
                    variant="plain"
                    onClick={() => setSearchText("")}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </IconButton>
                ) : (
                  []
                )
              }
            />
          )}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 500,
              display: "flex",
              gap: 1,
              flexDirection: "column",
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                  height: 500,
                }}
              >
                <List>
                  {musicianSuggestions
                    .filter(
                      (m) =>
                        m
                          .fullName()
                          .toLowerCase()
                          .indexOf(searchText.toLowerCase()) !== -1
                    )
                    .map((m) => musicianMenuItem(m, false))}
                  {searchText && otherMusicians.length ? (
                    <Divider>
                      Other musicians not playing this instrument
                    </Divider>
                  ) : (
                    []
                  )}
                  {searchText && otherMusicians.length
                    ? otherMusicians
                        ?.filter(
                          (m) =>
                            musicianSuggestions?.findIndex(
                              (ms) => ms.id === m.id
                            ) === -1
                        )
                        .map((m) => musicianMenuItem(m, true))
                    : []}
                </List>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            fullWidth
            color="neutral"
            variant="soft"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogClose>
    </div>
  );
}
