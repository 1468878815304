import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedProjectIDSelector, setFormOpen } from "reducers/rhapsody";
import { useProject } from "redux/project/projectHooks";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";
import { Indexes, addWorksheet, createWorkbook, downloadExcel } from "../utils";
import { useMusicians } from "redux/musician/musicianHooks";
import { useDispatch } from "react-redux";

export function useDownloadProjectActiveMusiciansExcel() {
  const [trigger, setTrigger] = useState(false);
  const [projectID, setProjectID] = useState<number>();
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const { projectHiring, isSuccess: s1 } = useProjectHiring(
    projectID ?? selectedProjectID,
    {
      skip: !trigger,
    }
  );

  const { project, isSuccess: s2 } = useProject(
    projectID ?? selectedProjectID,
    {
      skip: !trigger,
    }
  );

  const { musiciansMap, isSuccess: s3 } = useMusicians(undefined, {
    skip: !trigger,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (trigger && s1 && s2 && s3) {
      setTrigger(false);
      download();
    }
  }, [trigger, s1, s2, s3]);

  function download() {
    const workbook = createWorkbook();
    const fileName = `${project.name} - Active Musicians`;
    const worksheet = addWorksheet(workbook, "Active Musicians");

    // list of stages where a musician is considered "Active": Accepted, Remind, Reminded, Confirmation Request
    const activeStage = [17, 2, 5, 6];

    const musicianIDs = projectHiring?.jobs?.reduce((a, j) => {
      const stageID = j.mercuryStageID;
      if (j.musicianID && activeStage.indexOf(stageID) >= 0) {
        a[j.musicianID] = true;
      }
      return a;
    }, {});

    const musicianIDsArray = Object.keys(musicianIDs);
    const musicians = [];
    musicianIDsArray.forEach((s) => {
      const musicianID = parseInt(s);
      const musician = musiciansMap[musicianID];

      if (musician) {
        musicians.push(musician);
      }
    });
    musicians.sort((a, b) => a.lastName?.localeCompare(b.lastName));
    const indexes = new Indexes(worksheet);

    indexes.text(`Active Musicians for Project: ${project.name}`);
    indexes.bold();
    indexes.fontSize(24);
    indexes.nextRow();
    indexes.text(
      "An active musician is someone who has accepted a call for this project and is not cancelled or released."
    );

    const columns = [
      "firstName",
      "middleName",
      "lastName",
      "instrumentNames",
      "email",
      "phone",
      "nickName",
      "companyName",
      "address",
      "city",
      "state",
      "zipcode",
      "birthdate",
    ];

    columns.forEach((c) => {
      indexes.width(25);
      indexes.text(c);
      indexes.header();
      indexes.nextColumn();
    });

    indexes.goToColumn(0);
    indexes.nextRow();

    const _musicians = [...musicians];
    _musicians.sort((a, b) => a.lastName?.localeCompare(b.lastName));

    _musicians.forEach((m) => {
      columns.forEach((c) => {
        indexes.text(m[c]);
        indexes.nextColumn();
      });
      indexes.goToColumn(0);
      indexes.nextRow();
    });
    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
    downloadExcel(workbook, fileName);
  }

  return (_projectID?: number) => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setProjectID(_projectID);
    setTrigger(true);
  };
}
