import { ProjectRoster } from "entities/projectRoster";
import { useMemo } from "react";
import {
  useGetProjectProjectRostersQuery,
  useGetProjectRosterQuery,
} from "./projectRosterEndpoints";

export function useProjectRoster(projectRosterID: number | undefined) {
  const projectRosterQuery = useGetProjectRosterQuery(projectRosterID ?? 0, {
    skip: (projectRosterID ?? 0) <= 0,
  });
  const projectRosterEntity = projectRosterQuery.data;

  return useMemo(() => {
    const ret: typeof projectRosterQuery & {
      projectRoster?: ProjectRoster | undefined;
    } = {
      ...projectRosterQuery,
    };
    if (projectRosterEntity)
      ret.projectRoster = new ProjectRoster(projectRosterEntity);
    return ret;
  }, [projectRosterEntity]);
}

export function useProjectRosters(projectID) {
  const projectRosterQuery = useGetProjectProjectRostersQuery(projectID, {
    skip: (projectID ?? 0) <= 0,
  });
  const projectRosterEntitiesMap = projectRosterQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectRosterQuery & {
      projectRosters?: ProjectRoster[];
      projectRostersMap?: { [id: number]: ProjectRoster };
    } = {
      ...projectRosterQuery,
      projectRosters: [],
      projectRostersMap: {},
    };
    if (projectRosterEntitiesMap) {
      const projectRosters = [];
      const projectRostersMap = {};

      for (const key in projectRosterEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(projectRosterEntitiesMap, key)
        ) {
          const c = projectRosterEntitiesMap[key];
          const projectRoster = new ProjectRoster(c);
          projectRosters.push(projectRoster);
          projectRostersMap[projectRoster.id] = projectRoster;
        }
      }
      ret.projectRosters = projectRosters;
      ret.projectRostersMap = projectRostersMap;
    }

    return ret;
  }, [projectRosterEntitiesMap]);
}
