import { Grid } from "@mui/joy";
import { Box, Typography } from "@mui/material";
import { heightWithToolbar } from "global";
import Calendar from "hooks/Calendar";
import { HelpCard } from "hooks/helpCard/helpCard";
import { Helmet } from "react-helmet";

export default function CalendarPage() {
  return (
    <Box>
      <Helmet>
        <title>Rhapsody | Calendar</title>
      </Helmet>
      <Box
        sx={{
          height: heightWithToolbar,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: 2,
        }}
      >
        <HelpCard title="Did you know?">
          <Typography>
            Drag and drop on the calendar while being on the Week view to create
            new work sessions. Try it out!
          </Typography>
        </HelpCard>
        <Calendar today />
      </Box>
    </Box>
  );
}
