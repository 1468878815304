const { _APP_CONFIG } = window;

// api
export const REACT_APP_API = _APP_CONFIG.api.endpoint;
export const REACT_APP_BAZAAR_CALLBACK = _APP_CONFIG.api.bazaarCallback;
export const REACT_APP_API_PREFIX = _APP_CONFIG.api.routePrefix;
export const REACT_APP_API_SIGNIN = _APP_CONFIG.api.signinRoute;
export const REACT_APP_API_SIGNOUT = _APP_CONFIG.api.signoutRoute;
export const REACT_APP_PROD = _APP_CONFIG.api.production;

// front
export const REACT_APP_FRONT_BASE = _APP_CONFIG.front.baseHostname;
export const REACT_APP_FRONT = _APP_CONFIG.front.hostname;

// app
export const REACT_APP_ROOT_BACKGROUND = _APP_CONFIG.app.rootBackground;
export const REACT_APP_LOGO = _APP_CONFIG.app.logo;
export const REACT_APP_ID = _APP_CONFIG.app.appID;
export const REACT_APP_FAVICON = _APP_CONFIG.app.favicon;
export const SIGN_UP_ENABLED = _APP_CONFIG.app.signupEnabled;
export const SIGN_UP_ORGANIZATION_TOKEN_ENABLED =
  _APP_CONFIG.app.signupOrganizationTokenEnabled;
export const RESET_PASSWORD_ENABLED = _APP_CONFIG.app.resetPasswordEnabled;
export const ENV_MESSAGE = _APP_CONFIG.app.message;
export const ORGANIZATION_ENABLED = _APP_CONFIG.app.organizationEnabled;
export const REFERRAL_SENTENCE = _APP_CONFIG.app.referralSentence;
export const MANAGE = _APP_CONFIG.app.manage;
export const CONNECT = _APP_CONFIG.app.connect;
export const WIDGET = _APP_CONFIG.app.widget;
export const INTAKE = _APP_CONFIG.app.intake;

// debug
export const CONSOLE_LOGGER_ENABLED = _APP_CONFIG.debug.consoleLogger;

// theme
export const REACT_APP_PRIMARY_COLOR = _APP_CONFIG.theme.primaryColor;
export const REACT_APP_SECONDARY_COLOR = _APP_CONFIG.theme.secondaryColor;

// client
export const REACT_APP_CLIENT_NAME = _APP_CONFIG.client.name;
export const REACT_APP_CLIENT_WEBSITE = _APP_CONFIG.client.website;
export const REACT_APP_CLIENT_EMAIL = _APP_CONFIG.client.contact;
export const REACT_APP_SUPPORT_EMAIL = _APP_CONFIG.client.support;
export const REACT_APP_CLIENT_COLOR = "#2196f3";

// centrifugo
export const CENTRIFUGO_URL = _APP_CONFIG.centrifugo.url;
export const CENTRIFUGO_TOKEN = _APP_CONFIG.centrifugo.token;

// stripe
export const STRIPE_MEZZO = _APP_CONFIG.stripe.mezzo;
export const STRIPE_PORTAL = _APP_CONFIG.stripe.portal;

// googleAnalytics
export const GOOGLE_MAPS_GEOCODING_KEY = _APP_CONFIG.googleMaps.geocoding;
export const GOOGLE_MAPS_TIMEZONE_KEY = _APP_CONFIG.googleMaps.timezone;
