import Action_Entity from "./action";
export default class Action extends Action_Entity {
  static fromList(actionsJSON: unknown): Array<Action> {
    const actions: Action[] = [];
    if (actionsJSON)
      Array.isArray(actionsJSON) &&
        actionsJSON.forEach((actionJSON) => {
          actions.push(new Action(actionJSON));
        });
    return actions;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
