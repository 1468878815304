import {
  Box,
  Button,
  Card,
  IconButton,
  Link,
  Sheet,
  Textarea,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { ProjectContact_Entity } from "entities/projectContact";
import { MusicianAutocomplete } from "features/dataGrids/EmailsDataGrid/musicianAutocomplete";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useMusician } from "redux/musician/musicianHooks";
import {
  useCreateProjectContactMutation,
  useDeleteProjectContactMutation,
  useUpdateProjectContactMutation,
} from "redux/projectContact/projectContactEndpoints";
import { useProjectContacts } from "redux/projectContact/projectContactHooks";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ProjectContacts() {
  const open = useSelector(formOpenSelector("projectContacts"));
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectContacts, isLoading: projectContactsLoading } =
    useProjectContacts(projectID);
  const [createProjectContact] = useCreateProjectContactMutation();
  const [_projectContacts, setProjectContacts] = useState<
    ProjectContact_Entity[]
  >([]);

  useEffect(() => {
    if (projectContacts) {
      setProjectContacts(projectContacts);
    }
  }, [projectContacts]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectContacts" }));
  };

  const save = () => {
    onClose();
  };

  return (
    <DialogClose maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-solid fa-user"></i>}
          level="h6"
        >
          Project's Contacts
        </Typography>
        <Typography level="body2">
          This info will be included in the call and communication.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        {_projectContacts.map((c) => (
          <Contact contact={c} key={c.id} />
        ))}
        <Button
          color="neutral"
          onClick={() => createProjectContact({ projectID })}
        >
          + Project Contact
        </Button>
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}

function Contact({ contact }: { contact: ProjectContact_Entity }) {
  const [role, setRole] = useState(contact.role);
  const [updateProjectContact] = useUpdateProjectContactMutation();
  const [deleteProjectContact] = useDeleteProjectContactMutation();
  const { musician } = useMusician(contact.musicianID);

  useEffect(() => {
    if (contact) setRole(contact.role);
  }, [contact]);

  return (
    <Card key={contact.id}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
          alignItems: "start",
          position: "relative",
        }}
      >
        {contact.musicianID ? (
          <Sheet
            color="neutral"
            variant="soft"
            sx={{
              borderRadius: "8px",
              p: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography startDecorator={<MusicianAvatar musician={musician} />}>
              {musician?.fullName()}
            </Typography>
            <IconButton
              size="sm"
              color="neutral"
              onBlur={() => {
                updateProjectContact({
                  id: contact.id,
                  body: { ...contact, musicianID: 0 },
                });
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          </Sheet>
        ) : (
          <Box sx={{ width: "100%" }}>
            <MusicianAutocomplete
              email=""
              onChange={(e) =>
                updateProjectContact({
                  id: contact.id,
                  body: { ...contact, musicianID: e.id },
                })
              }
              size="small"
              label="Pick from the Address Book..."
            />
          </Box>
        )}
        <Textarea
          sx={{ width: "100%" }}
          minRows={2}
          maxRows={5}
          value={role}
          onChange={(e) => setRole(e.target.value)}
          placeholder="Role description"
          onBlur={() => {
            updateProjectContact({
              id: contact.id,
              body: { ...contact, role },
            });
          }}
        />
        <Link
          startDecorator={<i className="fa-solid fa-trash"></i>}
          color="neutral"
          level="body3"
          onClick={() => deleteProjectContact(contact.id)}
        >
          Delete Project Contact
        </Link>
      </Box>
    </Card>
  );
}
