import { Button, Sheet, Typography } from "@mui/joy";
import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { PdGroup } from "entities/pdGroup";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedPdProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useUpdatePdGroupMutation } from "redux/pdGroup/pdGroupEndpoints";
import { usePdProject } from "redux/pdProject/pdProjectHooks";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

/**
 *
 * @returns {ReactElement} PdReorderGroups page
 */
export function PdReorderGroups() {
  const open = useSelector(formOpenSelector("pdReorderGroups"));
  const pdProjectID = useSelector(selectedPdProjectIDSelector);
  const { pdProject } = usePdProject(pdProjectID);
  const [list, setList] = useState<PdGroup[]>([]);
  const [updateGroup] = useUpdatePdGroupMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    setList(pdProject.groups);
  }, [pdProject]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "pdReorderGroups" }));
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    const newItems = [...list];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setList(newItems);

    updateGroup({
      id: newItems[result.destination.index].id,
      body: {
        ...newItems[result.destination.index],
        position: result.destination.index + 1,
      },
    });
  };

  const ITEM_HEIGHT = 50;

  return (
    <DialogClose
      classes={{
        paper: "dialog-paper",
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box>
          <Typography level="h6">Reorder Groups</Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ position: "relative", maxWidth: 450, width: "100vw" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"Box"}>
              {(provided) => {
                return (
                  <Box
                    ref={provided.innerRef}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {list.map((p, i) => {
                      return (
                        <Draggable key={p.id} draggableId={`${p.id}`} index={i}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Sheet
                                variant="soft"
                                sx={{
                                  height: ITEM_HEIGHT,
                                  mb: 0.5,
                                  mt: 0.5,
                                  display: "flex",
                                  gap: 0.5,
                                  width: "100%",
                                  borderRadius: "8px",
                                  p: 1,
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 0.5,
                                    alignItems: "center",
                                  }}
                                >
                                  {p.name ? (
                                    p.name
                                  ) : (
                                    <i style={{ opacity: 0.4 }}>Unamed Group</i>
                                  )}{" "}
                                  #{p.position}
                                </Box>
                                <i
                                  style={{ opacity: 0.5, marginRight: 8 }}
                                  className="fa-solid fa-grip-dots-vertical"
                                ></i>
                              </Sheet>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </DialogClose>
  );
}
