import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { InternalSection } from "../InternalSection";
import InternalFamily_Entity from "./InternalFamily";
import { InternalPosition } from "../InternalPosition";
import { Selection_Entity } from "entities/selection";
export default class InternalFamily extends InternalFamily_Entity {
  sections: InternalSection[];
  enabledPositionIDs: string[];
  visibleChairIDs: number[];
  chairIDs: number[];
  positionCount: number;
  enabledPositionCount: number;

  constructor(
    json: InternalFamily_Entity,
    utils: LayoutUtils,
    selection: Selection_Entity
  ) {
    super(json);
    this.sections = InternalSection.fromList(json.sections, utils, selection);
    this.enabledPositionIDs = this.sections.reduce((a, v) => {
      a.push(...v.enabledPositionIDs);
      return a;
    }, []);
    this.chairIDs = this.sections
      .reduce((a, v) => {
        a.push(...v.chairIDs);
        return a;
      }, [])
      .sort();
    this.visibleChairIDs = this.sections
      .reduce((a, v) => {
        a.push(...v.visibleChairIDs);
        return a;
      }, [])
      .sort();

    this.positionCount = this.sections.reduce((a, v) => {
      a += v.positionCount;
      return a;
    }, 0);
    this.enabledPositionCount = this.sections.reduce((a, v) => {
      a += v.enabledPositionCount;
      return a;
    }, 0);
  }

  static fromList(
    InternalFamilysJSON: unknown,
    utils: LayoutUtils,
    selection: Selection_Entity
  ): Array<InternalFamily> {
    const InternalFamilys: InternalFamily[] = [];
    if (InternalFamilysJSON)
      Array.isArray(InternalFamilysJSON) &&
        InternalFamilysJSON.forEach((InternalFamilyJSON) => {
          InternalFamilys.push(
            new InternalFamily(InternalFamilyJSON, utils, selection)
          );
        });
    return InternalFamilys;
  }

  getSection(sectionID: number) {
    return this.sections.find((s) => s.sectionID === sectionID);
  }

  getPosition(positionID: string) {
    const s = positionID.split("|");
    const sectionID = parseInt(s[1]);
    const section = this.getSection(sectionID);
    return section?.getPosition(positionID);
  }

  getPositions(positionIDs: string[]) {
    const ret: InternalPosition[] = [];

    positionIDs.forEach((positionID) => {
      const s = positionID.split("|");
      const sectionID = parseInt(s[1]);
      const section = this.getSection(sectionID);
      const position = section.getPosition(positionID);
      if (position) ret.push(position);
    });
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
