/**
 * Family_Entity type guard.
 *
 * @param {any} familyJson Family object from API
 * @returns {boolean} Return true if type is Family_Entity
 */

export default class Family_Entity {
  static requiredFields = [];

  constructor(familyJson: Family_Entity) {
    try {
      this.id = familyJson.id;
      this.name = familyJson.name;
      this.icon = familyJson.icon;
      this.pos = familyJson.pos;
      this.groupID = familyJson.groupID;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  icon?: string;
  pos?: number;
  groupID?: number;
}
