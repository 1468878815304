import { Email } from "entities/email";
import { useMemo } from "react";
import { useGetEmailQuery, useGetEmailsQuery } from "./emailEndpoints";

export function useEmail(emailID: number | undefined) {
  const emailQuery = useGetEmailQuery(emailID ?? 0, {
    skip: (emailID ?? 0) <= 0,
  });
  const emailEntity = emailQuery.data;

  return useMemo(() => {
    const ret: typeof emailQuery & { email?: Email | undefined } = {
      ...emailQuery,
    };
    if (emailEntity) ret.email = new Email(emailEntity);
    return ret;
  }, [emailEntity]);
}

export function useEmails(params, options?) {
  const emailQuery = useGetEmailsQuery(params, options);
  const emailEntitiesMap = emailQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof emailQuery & {
      emails?: Email[];
      emailsMap?: { [id: number]: Email };
    } = {
      ...emailQuery,
      emails: [],
      emailsMap: {},
    };
    if (emailEntitiesMap) {
      const emails = [];
      const emailsMap = {};

      for (const key in emailEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(emailEntitiesMap, key)) {
          const c = emailEntitiesMap[key];
          const email = new Email(c);
          emails.push(email);
          emailsMap[email.id] = email;
        }
      }
      ret.emails = emails;
      ret.emailsMap = emailsMap;
    }

    return ret;
  }, [emailEntitiesMap]);
}
