import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { Customer_Entity } from "entities/customer";
import { rhapsodyApi } from "../api/rhapsodyApi";

const customersAdapter = createEntityAdapter<Customer_Entity>();
const initialState = customersAdapter.getInitialState();

export const customerEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<EntityState<Customer_Entity>, void>({
      query: () => `customers`,
      transformResponse: (responseData: Customer_Entity[]) => {
        return customersAdapter.setAll(initialState, responseData);
      },
      providesTags: ["customers"],
    }),
    getCustomer: build.query<Customer_Entity, number>({
      query: (id) => `customers/${id}`,
      providesTags: (result, error, id) => [{ type: "customers", id }],
    }),
    createCustomer: build.mutation<Customer_Entity, Partial<Customer_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `customers`,
      }),
      invalidatesTags: ["customers"],
    }),
    updateCustomer: build.mutation<
      void,
      { id: number; body: Partial<Customer_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `customers/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "customers", id },
        "customers",
      ],
    }),
    deleteCustomer: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `customers/${id}`,
      }),
      invalidatesTags: ["customers"],
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useCreateCustomerMutation,
  useDeleteCustomerMutation,
  useUpdateCustomerMutation,
} = customerEndpoints;

export default customerEndpoints;

export const selectCustomersResult =
  customerEndpoints.endpoints.getCustomers.select();

const selectCustomersData = createSelector(
  selectCustomersResult,
  (customersResult) => customersResult.data
);

const store = configureStore({
  reducer: {
    [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllCustomers, selectById: selectCustomerById } =
  customersAdapter.getSelectors<RootState>(
    (state) => selectCustomersData(state) ?? initialState
  );
