import { Button, Checkbox, CircularProgress, IconButton } from "@mui/joy";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Icon,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGridPro,
  GridCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import DialogClose from "atoms/DialogClose/DialogClose";
import Id from "atoms/Id/Id";
import { META } from "config";
import { Job_Entity } from "entities/job";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { currencyFormatter, getterDivider, setterDivider } from "helpers";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedWorkSessionIDSelector,
  setDescription420,
  setFormOpen,
} from "reducers/rhapsody";
import { useWorkSessionActiveJobs } from "redux/job/jobHooks";
import { useWorkSessionMusicians } from "redux/musician/musicianHooks";
import { useUpdateWorkSessionMutation } from "redux/workSession/workSessionEndpoints";
import { useWorkSession } from "redux/workSession/workSessionHooks";
import { help } from "./constants";

import { useExporter } from "features/exporter/exporter";
import {
  overture,
  useMySubscription,
} from "hooks/subscription/restrictionHooks";
import { useUpdateBatchJobMutation } from "redux/job/jobEndpoints";
import {
  layoutSelect,
  layoutSelectionMapSelector,
  layoutSelectionSelector,
  layoutUnselect,
  layoutUnselectAll,
} from "reducers/layout";

export default function JobsDataGrid() {
  const dispatch = useDispatch();
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const { jobs, isLoading: l1 } = useWorkSessionActiveJobs(workSessionID);
  const { workSession, isLoading: l2 } = useWorkSession(workSessionID);
  const { musiciansMap, isLoading: l4 } =
    useWorkSessionMusicians(workSessionID);
  const [fullScreen, setFullScreen] = useState(false);
  const apiRef = useGridApiRef();
  const [selectedCell, setSelectedCell] = useState<GridCellParams>();
  const selecto = useSelector(layoutSelectionSelector);
  const [updateBatchJob] = useUpdateBatchJobMutation();
  const mySubscription = useMySubscription();

  if (mySubscription === overture) {
    return (
      <Box
        sx={{
          display: "flex",
          p: 2,
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <i style={{ fontSize: 48 }} className="fa-duotone fa-file-invoice"></i>
        <Typography variant="h6">
          Subscribe to generate Payroll Forms
        </Typography>
        <Typography variant="body2">
          This feature is exclusive to the Mezzo & Finale plan.
        </Typography>
        <Button
          onClick={() => {
            dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
            dispatch(
              setDescription420("Payroll is an exclusive to Mezzo & Finale.")
            );
          }}
        >
          Subscribe Now
        </Button>
      </Box>
    );
  }

  if (l1 || l2 || l4)
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ paddingTop: 32 }}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
        <Grid item>
          <Typography color="textSecondary">Please wait</Typography>
        </Grid>
      </Grid>
    );

  const sessionTypeCode = workSession?.sessionType?.code;
  const meta = {};
  for (const key in META[sessionTypeCode]) {
    if (Object.hasOwnProperty.call(META[sessionTypeCode], key)) {
      meta[key] = true;
    }
  }

  const onJobUpdate = async (_newJob, _oldJob) => {
    return new Promise((resolve) => {
      const changes = {};
      const batch = [];
      for (const key in _newJob) {
        if (Object.hasOwnProperty.call(_newJob, key)) {
          if (_newJob[key] != _oldJob[key]) changes[key] = _newJob[key];
        }
      }

      batch.push({ id: _newJob.id, ...changes });

      for (const key in selecto) {
        if (Object.hasOwnProperty.call(selecto, key)) {
          const jobID = parseInt(key);
          if (_newJob.id !== jobID) batch.push({ id: jobID, ...changes });
        }
      }

      updateBatchJob(batch);

      setTimeout(() => {
        resolve({ ..._newJob });
      }, 50);
    });
  };

  const getTotal = () => {
    const total = {
      id: -1,
      cartage: 0,
      doubles: 0,
      rehearsalCount: 0,
      performanceCount: 0,
      scaleWages: 0,
      workDues: 0,
      pension: 0,
      welfare: 0,
      nonPensionable: 0,
      otherPensionable: 0,
    };

    jobs.forEach((e) => {
      if (e.cartage) total.cartage += e.cartage;
      if (e.doubles) total.doubles += e.doubles;
      if (e.rehearsalCount) total.rehearsalCount += e.rehearsalCount;
      if (e.performanceCount) total.performanceCount += e.performanceCount;
      if (e.scaleWages) total.scaleWages += e.scaleWages;
      if (e.workDues) total.workDues += e.workDues;
      if (e.pension) total.pension += e.pension;
      if (e.welfare) total.welfare += e.welfare;
      if (e.nonPensionable) total.nonPensionable += e.nonPensionable;
      if (e.otherPensionable) total.otherPensionable += e.otherPensionable;
    });

    return total;
  };

  const datagrid = (
    <DataGridPro
      key={workSession.id}
      rows={[...jobs, getTotal()]}
      components={{
        Toolbar: Toolbar,
      }}
      disableSelectionOnClick
      pinnedRows={{
        bottom: [getTotal()],
      }}
      getCellClassName={(p) => {
        let classes = "";
        if (!p.isEditable || p.colDef.type === "actions")
          classes += "cellReadOnly ";
        if (selecto[p.id]) {
          classes += "projectHiringSelected ";
        }
        if (p.field === selectedCell?.field && selecto[p.id] && p.isEditable) {
          classes += "selectedField ";
        }
        return classes;
      }}
      componentsProps={{
        toolbar: { setFullScreen, fullScreen },
      }}
      apiRef={apiRef}
      processRowUpdate={onJobUpdate}
      // onProcessRowUpdateError={(e) => console.log(e)}
      experimentalFeatures={{ newEditingApi: true, rowPinning: true }}
      onCellClick={(e) => {
        setSelectedCell(e);
        if (apiRef.current.getCellMode(e.id, e.field) === "view")
          apiRef.current?.startCellEditMode({ id: e.id, field: e.field });
      }}
      columns={[
        {
          field: "id",
          headerName: "Job ID",
          type: "number",
          width: width.id,
          renderCell: (p) => {
            if (p.id === -1) return <Box />;
            return (
              <Id startDecorator={<i className="fa-solid fa-briefcase"></i>}>
                {p.id}
              </Id>
            );
          },
        },
        {
          field: "musicianID",
          headerName: "Musician",
          type: "string",
          editable: false,
          width: width.musicianID,
          renderHeader: (p) => {
            const allMusiciansSelected =
              Object.keys(selecto).length === jobs.length;
            return (
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                sx={{
                  width: 290,
                  display: "flex",
                  gap: 1,
                }}
              >
                <Tooltip
                  title={`${
                    allMusiciansSelected ? "Unselect" : "Select"
                  } all Musicians`}
                >
                  <IconButton
                    onClick={
                      allMusiciansSelected
                        ? () => dispatch(layoutUnselectAll())
                        : () =>
                            dispatch(
                              layoutSelect(
                                jobs.reduce((a, i) => {
                                  a.push(i.id);
                                  return a;
                                }, [])
                              )
                            )
                    }
                    color="primary"
                    size="sm"
                    variant={allMusiciansSelected ? "solid" : "plain"}
                  >
                    <Icon
                      style={{ fontSize: 14 }}
                      className="fa-solid fa-angles-down"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
          valueGetter: (p) => {
            const row = p.row as Job_Entity;
            const musician = musiciansMap[row.musicianID];
            return musician?.fullName();
          },
          renderCell: (p) => (
            <MusicianCell {...p} workSessionID={workSessionID} />
          ),
        },
        {
          field: "instrument",
          headerName: "Instrument",
          type: "string",
          width: width.instrument,
          valueGetter: (p) => {
            const row = p.row as Job_Entity;
            return row.instrument?.name;
          },
        },
        {
          field: "address",
          headerName: "Address",
          type: "string",
          width: width.address,
          valueGetter: (p) => {
            const row = p.row as Job_Entity;
            const musician = musiciansMap[row.musicianID];
            return `${musician?.address} ${musician?.city} ${musician?.state} ${musician?.zipcode}`;
          },
          renderCell: (p) => {
            const row = p.row as Job_Entity;
            const musician = musiciansMap[row.musicianID];
            return (
              <Box>
                <Typography variant="body2">{musician?.address}</Typography>
                <Typography variant="body2">
                  {musician?.city} {musician?.state} {musician?.zipcode}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "ssn",
          headerName: "SSN/EIN",
          type: "string",
          width: width.ssn,
          valueGetter: (p) => {
            const row = p.row as Job_Entity;
            const musician = musiciansMap[row.musicianID];
            return `${musician?.ssn} ${musician?.ein}`;
          },
          renderCell: (p) => {
            const row = p.row as Job_Entity;
            const musician = musiciansMap[row.musicianID];
            return (
              <Box>
                <Typography variant="body2">{musician?.ssn}</Typography>
                <Typography variant="body2">{musician?.ein}</Typography>
              </Box>
            );
          },
        },
        {
          field: "rehearsalCount",
          headerName: "# of Rehearsal",
          type: "number",
          width: width.rehearsalCount,
          editable: true,
          renderCell: (p) => {
            if (p.id === -1)
              return <Typography variant="body2">{p.value}</Typography>;
            return (
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <Typography variant="body2">{p.value}</Typography>
                <Typography variant="caption">
                  Scale:{" "}
                  {currencyFormatter.format(
                    (workSession.rehearsalScale ?? 0) / 100
                  )}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "performanceCount",
          headerName: "# of Performances",
          type: "number",
          width: width.performanceCount,
          editable: true,
          renderCell: (p) => {
            if (p.id === -1)
              return <Typography variant="body2">{p.value}</Typography>;
            return (
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <Typography variant="body2">{p.value}</Typography>
                <Typography variant="caption">
                  Scale:{" "}
                  {currencyFormatter.format(
                    (workSession.performanceScale ?? 0) / 100
                  )}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "hoursGuaranteed",
          headerName: "Hours Guar",
          type: "string",
          width: width.hoursGuaranteed,
          editable: true,
        },
        {
          field: "hoursWorked",
          headerName: "Hours Worked",
          type: "string",
          width: width.hoursWorked,
          editable: true,
        },
        {
          field: "tunes",
          headerName: "Tunes",
          type: "string",
          width: width.tunes,
          editable: true,
        },
        {
          field: "overscale",
          headerName: "Overscale",
          type: "boolean",
          width: width.overscale,
          editable: true,
          renderEditCell: (params) => (
            <Box
              onClick={() => {
                apiRef.current.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: !(params.value ?? false),
                });
                apiRef.current.stopCellEditMode({
                  id: params.id,
                  field: params.field,
                });
              }}
            >
              <Checkbox checked={params.value} />
            </Box>
          ),
        },
        {
          field: "benefitsOnly",
          headerName: "Benefits Only",
          type: "boolean",
          width: width.benefitsOnly,
          editable: true,
          renderEditCell: (params) => (
            <Box
              onClick={() => {
                apiRef.current.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: !(params.value ?? false),
                });
                apiRef.current.stopCellEditMode({
                  id: params.id,
                  field: params.field,
                });
              }}
            >
              <Checkbox checked={params.value} />
            </Box>
          ),
        },
        {
          field: "cartage",
          headerName: "Cartage",
          type: "number",
          width: width.cartage,
          editable: true,
          valueGetter: getterDivider("cartage", 100),
          valueSetter: setterDivider("cartage", 100),
          valueFormatter: (p) => `${currencyFormatter.format(p.value ?? 0)}`,
        },
        {
          field: "doubles",
          headerName: "Doubles",
          type: "number",
          width: width.doubles,
          editable: true,
          renderCell: (p) => {
            const row = p.row as Job_Entity;
            if (p.id === -1)
              return <Typography variant="body2">{p.value}</Typography>;
            return (
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <Typography variant="body2">{p.value}</Typography>
                {sessionTypeCode === "LV" ? (
                  <Typography variant="caption">
                    Pay:{" "}
                    {currencyFormatter.format((row.doublingPay ?? 0) / 100)}
                  </Typography>
                ) : (
                  []
                )}
              </Box>
            );
          },
        },
        {
          field: "premiumPercent",
          headerName: "% Premium",
          type: "number",
          width: width.premiumPercent,
          editable: true,
          valueGetter: getterDivider("premiumPercent", 100),
          valueSetter: setterDivider("premiumPercent", 100),
          renderCell: (p) => {
            const row = p.row as Job_Entity;
            if (p.id === -1)
              return <Typography variant="body2">{p.value}</Typography>;
            return (
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <Typography variant="body2">{p.value}%</Typography>
                <Typography variant="caption">
                  Scale:{" "}
                  {currencyFormatter.format((row.premiumScale ?? 0) / 100)}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "scaleWages",
          headerName: "Scale Wages",
          type: "number",
          width: width.scaleWages,
          editable: true,
          valueGetter: getterDivider("scaleWages", 100),
          valueSetter: setterDivider("scaleWages", 100),
          valueFormatter: (p) => `${currencyFormatter.format(p.value ?? 0)}`,
        },
        {
          field: "workDues",
          headerName: "Work Dues",
          type: "number",
          width: width.workDues,
          editable: false,
          valueGetter: getterDivider("workDues", 100),
          valueSetter: setterDivider("workDues", 100),
          valueFormatter: (p) => `${currencyFormatter.format(p.value ?? 0)}`,
        },
        {
          field: "pension",
          headerName: "Pension",
          type: "number",
          width: width.pension,
          editable: true,
          valueGetter: getterDivider("pension", 100),
          valueSetter: setterDivider("pension", 100),
          valueFormatter: (p) => `${currencyFormatter.format(p.value ?? 0)}`,
        },
        {
          field: "welfare",
          headerName: "H&W",
          type: "number",
          width: width.welfare,
          editable: true,
          valueGetter: getterDivider("welfare", 100),
          valueSetter: setterDivider("welfare", 100),
          valueFormatter: (p) => `${currencyFormatter.format(p.value ?? 0)}`,
        },
        {
          field: "nonPensionable",
          headerName: "Non Pensionable",
          type: "number",
          width: width.nonPensionable,
          valueGetter: getterDivider("nonPensionable", 100),
          valueSetter: setterDivider("nonPensionable", 100),
          valueFormatter: (p) => `${currencyFormatter.format(p.value ?? 0)}`,
          editable: true,
        },
        {
          field: "otherPensionable",
          headerName: "Other Pensionable",
          type: "number",
          width: width.otherPensionable,
          valueGetter: getterDivider("otherPensionable", 100),
          valueSetter: setterDivider("otherPensionable", 100),
          valueFormatter: (p) => `${currencyFormatter.format(p.value ?? 0)}`,
          editable: true,
        },
      ]}
      initialState={{
        pinnedColumns: {
          left: ["checkbox", "musicianID"],
        },
        columns: {
          columnVisibilityModel: { ...allFields, ...meta },
        },
      }}
    />
  );

  if (fullScreen) {
    return (
      <Dialog fullScreen open onClose={() => setFullScreen(false)}>
        <Box sx={{ display: "flex", height: "100vh", width: "100vw" }}>
          {datagrid}
        </Box>
      </Dialog>
    );
  }

  return (
    <Box
      sx={{
        p: 1,
        flex: 1,
        display: "flex",
        pb: 5,
        position: "relative",
        minHeight: "auto",
        height: 0,
      }}
    >
      {datagrid}
    </Box>
  );
}

function Toolbar({ setFullScreen, fullScreen }) {
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement>();
  const [helpOpen, setHelpOpen] = useState(false);
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const { workSession } = useWorkSession(workSessionID);
  const sessionTypeCode = workSession?.sessionType?.code;
  const exporter = useExporter();

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, justifyContent: "space-between" }}
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <Button
          startDecorator={<i className="fa-duotone fa-calculator-simple"></i>}
          size="sm"
          variant="soft"
          color="neutral"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          Scales, Pension & Welfares
        </Button>
        <Tooltip title="Help">
          <Button
            onClick={() => setHelpOpen(true)}
            size="sm"
            variant="soft"
            color="info"
          >
            <i className="fa-duotone fa-question"></i>
          </Button>
        </Tooltip>
      </Box>
      <PensionWelfare anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <Box sx={{ display: "flex", gap: 1 }}>
        {sessionTypeCode === "LV" ? (
          <Tooltip title="Download Steward Report in Excel format">
            <Button
              startDecorator={<i className="fa-solid fa-file-excel"></i>}
              size="sm"
              variant="soft"
              color="primary"
              onClick={() => exporter.workSessionSteward()}
            >
              Steward
            </Button>
          </Tooltip>
        ) : (
          []
        )}
        <Tooltip title="Download Report Form in Excel format">
          <Button
            startDecorator={<i className="fa-solid fa-file-excel"></i>}
            size="sm"
            variant="soft"
            color="primary"
            onClick={() => exporter.workSessionContinuationForm(workSession.id)}
          >
            Report
          </Button>
        </Tooltip>
        <Button
          startDecorator={
            fullScreen ? (
              <i className="fa-duotone fa-compress"></i>
            ) : (
              <i className="fa-duotone fa-expand"></i>
            )
          }
          size="sm"
          variant="soft"
          color="neutral"
          onClick={() => setFullScreen((e) => !e)}
        >
          {fullScreen ? "Exit Full Screen" : "Full Screen"}
        </Button>
      </Box>
      <DialogClose
        onClose={() => setHelpOpen(false)}
        open={helpOpen}
        maxWidth="md"
      >
        <DialogContent>
          {help.map((h) => (
            <Box key={h.title} sx={{ mb: 2 }}>
              <Typography variant="h6">{h.title}</Typography>
              <Typography variant="body2">{h.subtitle}</Typography>
              {h.info ? <Alert severity="info">{h.info}</Alert> : []}
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Keys</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {h.items.map((i) => (
                    <TableRow
                      key={i.description}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ display: "flex" }}
                      >
                        {i.keys.split("+").map((e) => (
                          <Box className="key" key={e}>
                            {e}
                          </Box>
                        ))}
                      </TableCell>
                      <TableCell>{i.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Divider />
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHelpOpen(false)}>Close</Button>
        </DialogActions>
      </DialogClose>
    </Box>
  );
}

function PensionWelfare({ anchorEl, setAnchorEl }) {
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const { workSession } = useWorkSession(workSessionID);
  const sessionTypeCode = workSession?.sessionType?.code;
  const [updateWorkSession] = useUpdateWorkSessionMutation();
  const [calculatePensionWelfare] = useUpdateWorkSessionMutation();

  const [pensionPercentage, setPensionPercentage] = useState(
    workSession?.pensionPercentage ? workSession?.pensionPercentage / 100 : ""
  );
  const [welfareValue, setWalefareValue] = useState(
    workSession?.welfareValue ? workSession?.welfareValue / 100 : ""
  );
  const [rehearsalScale, setRehearsalScale] = useState(
    workSession?.rehearsalScale ? workSession?.rehearsalScale / 100 : ""
  );
  const [performanceScale, setPerformanceScale] = useState(
    workSession?.performanceScale ? workSession?.performanceScale / 100 : ""
  );
  const [workDuesPercent, setWorkDuesPercent] = useState(
    workSession?.workDuesPercent ? workSession?.workDuesPercent / 100 : ""
  );
  const [welfarePercent, setWelfarePercent] = useState(
    workSession.welfarePercent
  );

  const multiply = (v) => Math.round(Number(v ?? 0) * 100);

  const save = async (apply) => {
    const body = {
      pensionPercentage: multiply(pensionPercentage),
      welfarePercent,
      welfareValue: multiply(welfareValue),
      workDuesPercent: multiply(workDuesPercent),
      performanceScale: multiply(performanceScale),
      rehearsalScale: multiply(rehearsalScale),
    };
    updateWorkSession({ id: workSessionID, body });
    if (apply) {
      calculatePensionWelfare({ id: workSessionID, body });
    }
    setAnchorEl();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl()}
    >
      <Box
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "stretch",
        }}
      >
        <Typography variant="subtitle2">Scales, Pensions & Welfares</Typography>
        {sessionTypeCode === "LV" ? (
          <Box>
            <Typography variant="body2">Rehearsal Scale</Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              value={rehearsalScale}
              size="small"
              placeholder="Rehearsal Scale"
              onChange={(e) => setRehearsalScale(e.target.value)}
              type="number"
            />
          </Box>
        ) : (
          []
        )}
        {sessionTypeCode === "LV" ? (
          <Box>
            <Typography variant="body2">Performance Scale</Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              value={performanceScale}
              size="small"
              placeholder="Performance Scale"
              onChange={(e) => setPerformanceScale(e.target.value)}
              type="number"
            />
          </Box>
        ) : (
          []
        )}
        {sessionTypeCode === "LV" ? (
          <Box>
            <Typography variant="body2">Work Dues</Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              value={workDuesPercent}
              size="small"
              placeholder="Work Dues (in %)"
              onChange={(e) => setWorkDuesPercent(e.target.value)}
              type="number"
            />
          </Box>
        ) : (
          []
        )}
        <Box>
          <Typography variant="body2">
            Pension Automatic percentage of the wage (except Overscale)
          </Typography>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            value={pensionPercentage}
            size="small"
            placeholder="Pension (in %)"
            onChange={(e) => setPensionPercentage(e.target.value)}
            type="number"
          />
        </Box>
        <Box>
          <Typography variant="body2">
            Welfare is the same per session
          </Typography>
          <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              value={welfareValue}
              size="small"
              placeholder="Welfare"
              onChange={(e) => setWalefareValue(e.target.value)}
              type="number"
            />
            <Tooltip title="Select wheter it is a percentage or a fixed amount">
              <Button
                onClick={() => setWelfarePercent((e) => !e)}
                variant="outlined"
                sx={{ width: 200 }}
                startDecorator={
                  <i
                    className={
                      welfarePercent
                        ? "fa-duotone fa-percent"
                        : "fa-solid fa-dollar-sign"
                    }
                  ></i>
                }
              >
                {welfarePercent ? "Pencentage" : "Fixed Amount"}
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Button onClick={() => save(false)} variant="soft">
            Save
          </Button>
          <Button onClick={() => save(true)}>Save & Apply</Button>
        </Box>
      </Box>
    </Popover>
  );
}

function MusicianCell({ row, id, workSessionID }) {
  const { musiciansMap, isLoading: l4 } =
    useWorkSessionMusicians(workSessionID);
  const musician = musiciansMap[row.musicianID];
  const selection = useSelector(layoutSelectionMapSelector);
  const dispatch = useDispatch();

  if (id === -1) return <Typography>Total:</Typography>;

  const selected = selection[row.id] != undefined;

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Checkbox
        onClick={(e) =>
          !selected
            ? dispatch(layoutSelect([row.id]))
            : dispatch(layoutUnselect([row.id]))
        }
        color="primary"
        variant="soft"
        checked={selected}
      />
      <MusicianAvatar noBadge size={30} musician={musician} />
      <Box>
        <Typography variant="body2">
          {musician?.fullName()}
          {!musician ? "Empty Position" : ""}
        </Typography>
      </Box>
    </Box>
  );
}

export const allFields = {
  id: true,
  musicianID: true,
  instrument: false,
  address: false,
  ssn: false,
  rehearsalCount: false,
  performanceCount: false,
  hoursWorked: false,
  hoursGuaranteed: false,
  tunes: false,
  overscale: false,
  benefitsOnly: false,
  cartage: false,
  doubles: false,
  premiumPercent: false,
  scaleWages: false,
  workDues: false,
  pension: false,
  welfare: false,
  nonPensionable: false,
  otherPensionable: false,
};

const width = {
  id: 70,
  musicianID: 200,
  instrument: 100,
  address: 200,
  ssn: 150,
  rehearsalCount: 150,
  performanceCount: 150,
  hoursWorked: 120,
  hoursGuaranteed: 120,
  tunes: 120,
  overscale: 80,
  benefitsOnly: 120,
  cartage: 100,
  doubles: 100,
  premiumPercent: 150,
  scaleWages: 100,
  workDues: 100,
  pension: 100,
  welfare: 100,
  nonPensionable: 150,
  otherPensionable: 150,
};
