import { NoteType } from "entities/noteType";
import { useMemo } from "react";
import { useGetNoteTypeQuery, useGetNoteTypesQuery } from "./noteTypeEndpoints";

export function useNoteType(noteTypeID: number | undefined) {
  const noteTypeQuery = useGetNoteTypeQuery(noteTypeID ?? 0, {
    skip: (noteTypeID ?? 0) <= 0,
  });
  const noteTypeEntity = noteTypeQuery.data;

  return useMemo(() => {
    const ret: typeof noteTypeQuery & { noteType?: NoteType | undefined } = {
      ...noteTypeQuery,
    };
    if (noteTypeEntity) ret.noteType = new NoteType(noteTypeEntity);
    return ret;
  }, [noteTypeEntity]);
}

export function useNoteTypes() {
  const noteTypeQuery = useGetNoteTypesQuery();
  const noteTypeEntitiesMap = noteTypeQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof noteTypeQuery & {
      noteTypes?: NoteType[];
      noteTypesMap?: { [id: number]: NoteType };
    } = {
      ...noteTypeQuery,
      noteTypes: [],
      noteTypesMap: {},
    };
    if (noteTypeEntitiesMap) {
      const noteTypes = [];
      const noteTypesMap = {};

      for (const key in noteTypeEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(noteTypeEntitiesMap, key)) {
          const c = noteTypeEntitiesMap[key];
          const noteType = new NoteType(c);
          noteTypes.push(noteType);
          noteTypesMap[noteType.id] = noteType;
        }
      }
      ret.noteTypes = noteTypes;
      ret.noteTypesMap = noteTypesMap;
    }

    return ret;
  }, [noteTypeEntitiesMap]);
}
