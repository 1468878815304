import { META } from "config";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedWorkSessionIDSelector, setFormOpen } from "reducers/rhapsody";
import { useWorkSessionActiveJobs } from "redux/job/jobHooks";
import { useMusicians } from "redux/musician/musicianHooks";
import { useWorkSession } from "redux/workSession/workSessionHooks";
import {
  Indexes,
  alphabet,
  alphabetArray,
  createWorkbook,
  downloadExcel,
} from "../utils";
import { useInstruments } from "redux/instrument/instrumentHooks";
import { Job } from "entities/job";
import { Workbook } from "exceljs";
import { useDispatch } from "react-redux";

export function useDownloadWorkSessionContinationFormExcel() {
  const [trigger, setTrigger] = useState(false);
  const [workSessionID, setWorkSessionID] = useState<number>();
  const selectedWorkSessionID = useSelector(selectedWorkSessionIDSelector);
  const { workSession, isSuccess: s1 } = useWorkSession(
    workSessionID ?? selectedWorkSessionID,
    { skip: !trigger }
  );
  const { jobs, isSuccess: s2 } = useWorkSessionActiveJobs(
    workSessionID ?? selectedWorkSessionID
  );
  const { musiciansMap, isSuccess: s3 } = useMusicians(undefined, {
    skip: !trigger,
  });
  const { instrumentsMap, isSuccess: s4 } = useInstruments();
  const dispatch = useDispatch();
  const sessionTypeCode = workSession?.sessionType?.code;

  useEffect(() => {
    if (trigger && s1 && s2 && s3 && s4) {
      setTrigger(false);
      download();
    }
  }, [trigger, s1, s2, s3, s4]);

  function download() {
    const workbook = createWorkbook();
    const fileName = `${workSession.title} - Continuation Form`;

    const lines = linesPerForm[sessionTypeCode];
    const pages = jobs.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / lines);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    pages.forEach((p, index) =>
      addContinuationPage(workbook, p, index + 1, pages.length)
    );
    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
    downloadExcel(workbook, fileName);
  }

  const addContinuationPage = (
    workbook: Workbook,
    jobs: Job[],
    index: number,
    total: number
  ) => {
    const worksheet = workbook.addWorksheet(`Page ${index}`, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    worksheet.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };
    const debug = false;
    const indexes = new Indexes(worksheet);
    for (const key in alphabetArray) {
      if (Object.hasOwnProperty.call(alphabetArray, key)) {
        const letter = alphabetArray[key];
        worksheet.getColumn(letter).font = {
          name: "Bookman Old Style",
        };
      }
    }

    indexes.text(reportTitles[workSession.sessionType.code]);
    indexes.nextRow();
    indexes.nextRow();
    indexes.nextRow();
    indexes.nextRow();

    // Header
    indexes.width(30);
    indexes.rectangle();
    indexes.height(30);
    indexes.nextRow();
    indexes.height(30);
    indexes.previousRow();

    indexes.text("LOCAL UNION NO.");
    indexes.height(35);
    indexes.width(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.rectangle();
    indexes.text("CARD NO.");
    indexes.height(35);
    indexes.centerText();
    let back = indexes.getRow();
    jobs.forEach((j) => {
      const musician = musiciansMap[j.musicianID];
      indexes.nextRow();
      indexes.rectangle();
      indexes.text(`${musician?.localNumber ?? ""}`);
      indexes.nextRow();
      indexes.rectangle();
      indexes.text(`${musician?.cardNumber ?? ""}`);
    });
    indexes.goToRow(back);
    indexes.previousRow();

    indexes.nextColumn();
    indexes.width(30);
    indexes.select(0, 1);
    indexes.merge();
    indexes.unselect();
    indexes.rectangle();
    indexes.text(
      "EMPLOYEE’S NAME\n(As on Social Security Card)\nLAST   FIRST   INIT.\n (Instrument(s))"
    );
    indexes.centerText();
    back = indexes.getRow();
    indexes.nextRow();
    jobs.sort((a, b) => {
      const musicianA = musiciansMap[a.musicianID];
      const musicianB = musiciansMap[b.musicianID];

      return musicianA?.lastName?.localeCompare(musicianB?.lastName);
    });
    jobs.forEach((j) => {
      const musician = musiciansMap[j.musicianID];
      if (musician) {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.centerText();
        const instruments = j.instrumentIDs
          ? JSON.parse(j.instrumentIDs)
              ?.map((i) => instrumentsMap[i]?.name)
              .join(", ")
          : instrumentsMap[j.instrumentID]?.name;
        if (musician.companyName) {
          indexes.text(
            `${musician.companyName}\n(FSO ${musician.fullName(
              false
            )})\n${instruments}`.toUpperCase()
          );
        } else {
          indexes.text(
            `${musician.fullName(false).toUpperCase()}\n${instruments}`
          );
        }
      }
    });
    indexes.goToRow(back);

    indexes.nextColumn();
    indexes.width(30);
    indexes.select(0, 1);
    indexes.merge();
    indexes.rectangle();
    indexes.unselect();
    indexes.text("HOME ADDRESS\n(Give Street, City & State) ");
    indexes.centerText();
    back = indexes.getRow();
    indexes.nextRow();
    jobs.forEach((j) => {
      const musician = musiciansMap[j.musicianID];
      indexes.nextRow();
      indexes.select(0, 1);
      indexes.merge();
      indexes.unselect();
      indexes.nextRow();
      indexes.rectangle();
      indexes.centerText();
      indexes.text(musician?.formattedAddress());
    });
    indexes.goToRow(back);

    indexes.nextColumn();
    indexes.width(15);
    indexes.text("SOCIAL SECURITY NUMBER");
    indexes.centerText();
    indexes.rectangle();
    indexes.nextRow();
    indexes.rectangle();
    if (sessionTypeCode === "4") {
      indexes.text("Martial Status & Exemptions ");
    } else {
      indexes.text("(EID as applicable)");
    }
    indexes.centerText();
    indexes.previousRow();
    back = indexes.getRow();
    indexes.nextRow();
    jobs.forEach((j) => {
      const musician = musiciansMap[j.musicianID];
      indexes.nextRow();
      indexes.rectangle();
      indexes.text(musician?.ssn ?? "");
      indexes.centerText();
      indexes.nextRow();
      indexes.rectangle();
      indexes.text(musician?.ein ?? "");
      indexes.centerText();
    });
    indexes.goToRow(back);

    if (META[sessionTypeCode].hoursGuaranteed || debug) {
      indexes.nextColumn();
      indexes.width(7);
      indexes.select(0, 1);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text("HRS. GUAR.");
      indexes.centerText();
      back = indexes.getRow();
      indexes.nextRow();
      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.text(j.hoursGuaranteed);
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    if (META[sessionTypeCode].hoursWorked || debug) {
      indexes.nextColumn();
      indexes.width(6);
      indexes.select(0, 1);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text("HRS. WK'D");
      indexes.centerText();
      back = indexes.getRow();
      indexes.nextRow();
      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.text(j.hoursWorked);
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    if (sessionTypeCode === "3" || debug) {
      indexes.nextColumn();
      indexes.width(7);
      indexes.select(0, 1);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text("NO. OF. DBLE PER SESS");
      indexes.centerText();
      back = indexes.getRow();
      indexes.nextRow();
      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    if (sessionTypeCode === "3" || debug) {
      indexes.nextColumn();
      indexes.width(7);
      indexes.select(0, 1);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text("SPOT ID by letter above");
      indexes.centerText();
      back = indexes.getRow();
      indexes.nextRow();
      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    if (sessionTypeCode === "3" || debug) {
      indexes.nextColumn();
      indexes.width(6);
      indexes.select(0, 1);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text("ID of SPOT PER DBL");
      indexes.centerText();
      back = indexes.getRow();
      indexes.nextRow();
      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    if (META[sessionTypeCode].tunes || debug) {
      indexes.nextColumn();
      indexes.width(6);
      indexes.select(0, 1);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text("ID of TUNES");
      indexes.centerText();
      back = indexes.getRow();
      indexes.nextRow();
      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.text(j.tunes);
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    if (
      META[sessionTypeCode].scaleWages ||
      META[sessionTypeCode].cartage ||
      debug
    ) {
      indexes.nextColumn();
      indexes.width(20);
      indexes.text("WAGES\n(1)");
      indexes.centerText();
      indexes.rectangle();
      indexes.nextRow();
      indexes.rectangle();
      indexes.text("CARTAGE");
      indexes.centerText();
      indexes.previousRow();

      back = indexes.getRow();
      indexes.nextRow();
      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.rectangle();
        if (j.benefitsOnly) {
          indexes.text(`BENEFITS ONLY`);
        } else {
          indexes.text(
            `$${(j.scaleWages ?? 0) / 100}${j.overscale ? " (OS)" : ""}`
          );
        }
        indexes.centerText();
        indexes.nextRow();
        indexes.rectangle();
        j.cartage && indexes.text(j.cartage / 100);
        j.cartage && indexes.dollar();
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    if (META[sessionTypeCode].doubles || debug) {
      indexes.nextColumn();
      indexes.width(6);
      indexes.select(0, 1);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text("NO. of DBLS");
      indexes.centerText();
      back = indexes.getRow();
      indexes.nextRow();
      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.text(j.doubles);
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    if (META[sessionTypeCode].pension || debug) {
      indexes.nextColumn();
      indexes.width(12);
      indexes.select(0, 1);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text("PENSION");
      indexes.centerText();
      back = indexes.getRow();
      indexes.nextRow();

      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.text((j.pension ?? 0) / 100);
        indexes.dollar();
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    if (META[sessionTypeCode].welfare || debug) {
      indexes.nextColumn();
      indexes.width(12);
      indexes.select(0, 1);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.richText([
        {
          text: "H&W\n",
        },
        {
          font: {
            size: 8,
          },
          text: "WHERE APPLICABLE ",
        },
      ]);
      indexes.centerText();
      indexes.nextRow();
      jobs.forEach((j) => {
        indexes.nextRow();
        indexes.select(0, 1);
        indexes.merge();
        indexes.unselect();
        indexes.nextRow();
        indexes.rectangle();
        indexes.text((j.welfare ?? 0) / 100);
        indexes.dollar();
        indexes.centerText();
      });
      indexes.goToRow(back);
    }

    const worksheetWidth = indexes.getColumn();

    indexes.rowRelativeMove((jobs.length + 1) * 2);
    indexes.columnRelativeMove(-4);
    indexes.select(4, 1);
    indexes.rectangle();
    indexes.unselect();
    indexes.text("TOTAL PENSION CONTRIBUTIONS:");
    indexes.nextRow();
    indexes.text("TOTAL H&W CONTRIBUTIONS:");
    indexes.previousRow();
    indexes.columnRelativeMove(3);
    indexes.formula(
      `SUM(${indexes.getColumn()}${
        indexes.getRow() - jobs.length * 2
      }:${indexes.getColumn()}${indexes.getRow() - 1})`
    );
    indexes.rectangle();
    indexes.dollar();
    indexes.nextColumn();
    indexes.nextRow();
    indexes.formula(
      `SUM(${indexes.getColumn()}${
        indexes.getRow() - 1 - jobs.length * 2
      }:${indexes.getColumn()}${indexes.getRow() - 2})`
    );
    indexes.dollar();
    indexes.previousRow();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.goToColumn(0);
    indexes.height(30);
    indexes.select(4, 0);
    indexes.merge();
    indexes.text(footerSentence[sessionTypeCode]);
    indexes.getCell().alignment = {
      wrapText: true,
      vertical: "middle",
    };

    indexes.nextRow();
    indexes.text("FOR FUND USE ONLY:");
    indexes.bold();
    indexes.fontSize(16);

    indexes.goToColumn(0);
    indexes.goToRow(1);
    indexes.select(alphabet.indexOf(worksheetWidth), 0);
    indexes.centerText();
    indexes.merge();
    worksheet.getCell(indexes.cellID()).font = {
      ...worksheet.getCell(indexes.cellID()).font,
      size: 18,
      bold: true,
    };
    indexes.height(70);

    indexes.goToColumn(alphabet.indexOf(worksheetWidth));
    indexes.goToRow(2);
    indexes.previousColumn();
    indexes.text("Report Form No.");
    indexes.nextRow();
    indexes.select(1, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text(`Page ${index} of ${total}`);
    indexes.centerText();
    indexes.previousRow();
    indexes.goToColumn(0);
    if (
      sessionTypeCode !== "9" &&
      sessionTypeCode !== "11" &&
      sessionTypeCode !== "12"
    ) {
      if (sessionTypeCode === "8" || sessionTypeCode === "10") {
        indexes.text("Program Name:");
        indexes.nextRow();
      }
      indexes.text(
        `Recording Date: ${moment(workSession.dateFromUTC).format("lll")}`
      );

      indexes.nextRow();
      if (sessionTypeCode === "7") {
        indexes.text(`Title of Picture: ${workSession.projectName}`);
        indexes.nextRow();
      } else {
        indexes.text("Leader's name:");
        indexes.nextRow();
      }
      if (sessionTypeCode === "4") {
        indexes.text("Artist/Picture:");
        indexes.nextRow();
      }
      if (sessionTypeCode === "5") {
        indexes.text("Name of Artist/Group: ");
        indexes.nextRow();
      }
      if (sessionTypeCode === "7") {
        indexes.text("Signator: ");
        indexes.nextRow();
      }
    }
  };

  return (_workSessionID?: number) => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setWorkSessionID(_workSessionID);
    setTrigger(true);
  };
}

export const reportTitles = {
  G: "REPORT FORM",
  R: "REPORT FORM",
  LV: "LIVE REPORT FORM",
  3: "REPORT FORM\nMADE AND PLAYED LOCAL COMMERCIAL ANNOUNCEMENTS\nContinuation Sheet",
  4: "REPORT FORM\nPHONOGRAPH RECORDS, SOUNDTRACK RELEASES, VIDEO PROMOS\nContinuation Sheet",
  5: "REPORT FORM\nFOR DEMONSTRATION RECORDING - AUDIO ONLY\nContinuation Sheet",
  6: "REPORT FORM\nTELEVISION AND RADIO COMMERCIAL ANNOUNCEMENTS\nContinuation Sheet",
  7: "REPORT FORM\nMOTION PICTURES -THEATRICAL & TELEVISION FILM (STANDARD, NON-STANDARD & BASIC CABLE),\nINDUSTRIAL (NON-THEATRICAL-NON-TV), MISCELLANEOUS, LOW BUDGET FILMS\nContinuation Sheet",
  8: "REPORT FORM\nFOR ALL VIDEOTAPE/LIVE TELEVISION/CABLE TV/PUBLIC TV\nContinuation Sheet",
  9: "LIMITED PRESSING RECORDING REPORT FORM\nContinuation Sheet",
  10: "REPORT FORM\nFOR COMMERCIAL (SYNDICATED), PUBLIC AND LOCAL RADIO\nAND NON-COMMERCIAL I.D.\nContinuation Sheet",
  11: "REPORT FORM\nFOR SYMPHONY, OPERA, BALLET AUDIO-VISUAL AGREEMENT\nContinuation Sheet",
  12: "RADIO TO NON-COMMERCIAL RECORDING REPORT FORM\nFOR SYMPHONIC USE ONLY\nContinuation Sheet",
};

export const footerSentence = {
  3: "(1) Insert X if wages being paid are overscale",
  4: "Include all music prep. info on this form or continuation sheet, with copies of invoices attached.",
  5: "(1) Insert overscale wages being paid.\nInclude all music prep. info on this form or continuation sheet, with copies of invoices attached.",
  6: "(1) Insert X if wages being paid are overscale.",
  7: "(1) Insert overscale wages being paid.\nInclude all music Prep, information on this form or continuation sheet, with copies of invoices attached.",
  8: "(1) Insert X if wages being paid are overscale.\nInclude all Music Preparation on this form along with attached copies of invoices.",
  9: "(1) Insert overscale wages being paid.\nInclude all music prep. info on this form or continuation sheet, with copies of invoices attached.",
  10: "(1) Insert overscale wages being paid.\nInclude all music prep. information on this form or a continuation sheet, with copies of invoices attached.",
  11: "Include all music prep. information on this form or a continuation sheet, with copies of invoices attached.",
  12: "Distribution of the 6 copies of this report form is as follows:\n1. Original page is to be sent to, AFM-EP Fund, 304 East 44th St., New York, NY 10017\nwith Pension contribution check made payable to the AFM-EP Fund\n2. One copy is to be retained by the Signatory of Records\n3. The remaining 4 copies are to be sent to the applicable AFM Local with the musicians’ checksThe AFM Local will:\n· retain one copy\n· send one copy to the American Federation of Musicians, 1501 Broadway, Suite 500, New York, NY 10036\n· send one copy to the Leader\n· send one copy to Health & Welfare Fund (where applicable)\n· send one photo copy to the Orchestra Committee Chairperson",
};

export const linesPerForm = {
  G: 25,
  R: 25,
  LV: 25,
  3: 34,
  4: 21,
  5: 22,
  6: 32,
  7: 19,
  8: 26,
  9: 22,
  10: 22,
  11: 23,
  12: 17,
};
