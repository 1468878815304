import Stage_Entity from "./stage";
export default class Stage extends Stage_Entity {
  static fromList(stagesJSON: unknown): Array<Stage> {
    const stages: Stage[] = [];
    if (stagesJSON)
      Array.isArray(stagesJSON) &&
        stagesJSON.forEach((stageJSON) => {
          stages.push(new Stage(stageJSON));
        });
    return stages;
  }

  withCheckmark(): boolean {
    const stageIDWithCheckmark = {
      6: true,
      9: true,
      20: true,
    };

    return stageIDWithCheckmark[this.id] ?? false;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  wording() {
    let ret = this.name?.toLowerCase() === "invite" ? "Invited" : this?.name;
    if (!ret) ret = "Ready";
    return ret;
  }
}

export const readyStage: Stage = new Stage({
  id: 0,
  name: "Ready",
  icon: "https://storage.googleapis.com/rhapsody/logos/ready.png",
  color: "#9e9e9e",
  terminus: true,
});
