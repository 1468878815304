/**
 * Section_Entity type guard.
 *
 * @param {any} sectionJson Section object from API
 * @returns {boolean} Return true if type is Section_Entity
 */

export default class Section_Entity {
  constructor(sectionJson: Section_Entity) {
    try {
      this.id = sectionJson.id;
      this.defaultInstrumentID = sectionJson.defaultInstrumentID;
      this.shorthand = sectionJson.shorthand;
      this.name = sectionJson.name;
      this.pos = sectionJson.pos;
      this.familyID = sectionJson.familyID;
      this.familyName = sectionJson.familyName;
      this.familyPos = sectionJson.familyPos;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  defaultInstrumentID?: number;
  shorthand?: string;
  name?: string;
  pos?: number;
  familyID?: number;
  familyName?: string;
  familyPos?: number;
}
