import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { PdGroup_Entity } from "entities/pdGroup";
import { paydayApi } from "redux/api/paydayApi";

const pdGroupsAdapter = createEntityAdapter<PdGroup_Entity>();
const initialState = pdGroupsAdapter.getInitialState();

export const pdGroupEndpoints = paydayApi.injectEndpoints({
  endpoints: (build) => ({
    getPdGroups: build.query<EntityState<PdGroup_Entity>, void>({
      query: () => `groups`,
      transformResponse: (responseData: PdGroup_Entity[]) => {
        return pdGroupsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["pdGroups"],
    }),
    getPdGroup: build.query<PdGroup_Entity, number>({
      query: (id) => `groups/${id}`,
      providesTags: (result, error, id) => [{ type: "pdGroups", id }],
    }),
    createPdGroup: build.mutation<PdGroup_Entity, Partial<PdGroup_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `groups`,
      }),
      invalidatesTags: ["pdGroups", "pdProjects"],
    }),
    updatePdGroup: build.mutation<
      void,
      { id: number; body: Partial<PdGroup_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `groups/${args.id}`,
      }),
      invalidatesTags: ["pdGroups", "pdProjects"],
    }),
    deletePdGroup: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `groups/${id}`,
      }),
      invalidatesTags: ["pdGroups", "pdProjects"],
    }),
  }),
});

export const {
  useGetPdGroupQuery,
  useGetPdGroupsQuery,
  useCreatePdGroupMutation,
  useDeletePdGroupMutation,
  useUpdatePdGroupMutation,
} = pdGroupEndpoints;

export default pdGroupEndpoints;
