import { Dictionary } from "@reduxjs/toolkit";
import RhapsodyChair_Entity from "./rhapsodyChair";
import { WorkSessionProjectPiece } from "entities/workSessionProjectPiece";
export default class RhapsodyChair extends RhapsodyChair_Entity {
  static fromList(rhapsodyChairsJSON: unknown): Array<RhapsodyChair> {
    const rhapsodyChairs: RhapsodyChair[] = [];
    if (rhapsodyChairsJSON)
      Array.isArray(rhapsodyChairsJSON) &&
        rhapsodyChairsJSON.forEach((rhapsodyChairJSON) => {
          rhapsodyChairs.push(new RhapsodyChair(rhapsodyChairJSON));
        });
    return rhapsodyChairs;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  order(): number {
    return this.sectionOrder ?? 1;
  }

  orderString(): string {
    if (this.order() % 10 === 1) return `${this.order()}st`;
    if (this.order() % 10 === 2) return `${this.order()}nd`;
    if (this.order() % 10 === 3) return `${this.order()}rd`;
    return `${this.order()}th`;
  }

  isSame(chair: RhapsodyChair) {
    // console.log(
    //   "compare",
    //   this,
    //   chair,
    //   this.sectionRoleID === chair.sectionRoleID ? "✅" : "❌",
    //   this.instrumentIDs === chair.instrumentIDs ? "✅" : "❌",
    //   this.memo === chair.memo ? "✅" : "❌",
    //   this.projectPieceID === chair.projectPieceID ? "✅" : "❌",
    //   this.musicianID ? "✅" : "❌",
    //   this.musicianID === chair.musicianID ? "✅" : "❌"
    // );
    if (
      this.sectionRoleID === chair.sectionRoleID &&
      this.sectionOrder === chair.sectionOrder &&
      this.instrumentIDs === chair.instrumentIDs &&
      this.memo === chair.memo &&
      this.projectPieceID === chair.projectPieceID &&
      this.musicianID &&
      this.musicianID === chair.musicianID &&
      this.hidden === chair.hidden
      // this.workSessionID === chair.workSessionID &&
      // this.workSessionIDs === chair.workSessionIDs
    ) {
      // return true;
      if (chair.workSessionID) {
        return this.workSessionIDs.includes(chair.workSessionID);
      } else {
        return true;
      }
    }

    return false;
  }

  isVisible({
    selectedProjectPieceIDs,
    selectedWorkSessionIDs,
    forProjectPieceID,
    forWorkSessionID,
    workSessionProjectPieces,
  }: {
    selectedProjectPieceIDs: Dictionary<boolean>;
    selectedWorkSessionIDs?: Dictionary<boolean>;
    forProjectPieceID?: number;
    forWorkSessionID?: number;
    workSessionProjectPieces?: WorkSessionProjectPiece[];
  }): boolean {
    const pieceSelected = selectedProjectPieceIDs[this.projectPieceID] ?? false;

    // if the chair is for a specific (unselected) project piece
    if (!selectedProjectPieceIDs[this.projectPieceID]) return false;

    // if the chair is for a specific (unselected) work session
    if (this.workSessionID && !selectedWorkSessionIDs[this.workSessionID])
      return false;

    // For View -> Pieces side by side
    if (forProjectPieceID)
      return pieceSelected && this.projectPieceID === forProjectPieceID;

    // For View -> Work Sessions side by side
    if (forWorkSessionID && workSessionProjectPieces) {
      const workSessionProjectPiece = workSessionProjectPieces.find(
        (w) =>
          w.projectPieceID === this.projectPieceID &&
          w.workSessionID === forWorkSessionID
      );

      if (this.workSessionID) {
        return (
          workSessionProjectPiece !== undefined &&
          this.workSessionID === forWorkSessionID
        );
      }

      return workSessionProjectPiece !== undefined;
    }

    return pieceSelected;
  }
}
