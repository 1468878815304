import { Trail } from "entities/trail";
import { useMemo } from "react";
import { useGetTrailQuery, useGetTrailsQuery } from "./trailEndpoints";

export function useTrail(trailID: number | undefined) {
  const trailQuery = useGetTrailQuery(trailID ?? 0, {
    skip: (trailID ?? 0) <= 0,
  });
  const trailEntity = trailQuery.data;

  return useMemo(() => {
    const ret: typeof trailQuery & { trail?: Trail | undefined } = {
      ...trailQuery,
    };
    if (trailEntity) ret.trail = new Trail(trailEntity);
    return ret;
  }, [trailEntity]);
}

export function useTrails(emailID) {
  const trailQuery = useGetTrailsQuery(
    {
      filters: JSON.stringify([
        {
          name: "emailID",
          comparison: "eq",
          value: emailID,
        },
      ]),
    },
    { skip: !emailID }
  );
  const trailEntitiesMap = trailQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof trailQuery & {
      trails?: Trail[];
      trailsMap?: { [id: number]: Trail };
    } = {
      ...trailQuery,
      trails: [],
      trailsMap: {},
    };
    if (trailEntitiesMap) {
      const trails = [];
      const trailsMap = {};

      for (const key in trailEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(trailEntitiesMap, key)) {
          const c = trailEntitiesMap[key];
          const trail = new Trail(c);
          trails.push(trail);
          trailsMap[trail.id] = trail;
        }
      }
      ret.trails = trails;
      ret.trailsMap = trailsMap;
    }

    return ret;
  }, [trailEntitiesMap]);
}
