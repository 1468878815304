import { Venue } from "entities/venue";
import { useMemo } from "react";
import { useGetVenueQuery, useGetVenuesQuery } from "./venueEndpoints";

export function useVenue(venueID: number | undefined) {
  const venueQuery = useGetVenueQuery(venueID ?? 0, {
    skip: (venueID ?? 0) <= 0,
  });
  const venueEntity = venueQuery.data;

  return useMemo(() => {
    const ret: typeof venueQuery & { venue?: Venue | undefined } = {
      ...venueQuery,
    };
    if (venueEntity) ret.venue = new Venue(venueEntity);
    return ret;
  }, [venueEntity]);
}

export function useVenues() {
  const venueQuery = useGetVenuesQuery();
  const venueEntitiesMap = venueQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof venueQuery & {
      venues?: Venue[];
      venuesMap?: { [id: number]: Venue };
    } = {
      ...venueQuery,
      venues: [],
      venuesMap: {},
    };
    if (venueEntitiesMap) {
      const venues = [];
      const venuesMap = {};

      for (const key in venueEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(venueEntitiesMap, key)) {
          const c = venueEntitiesMap[key];
          const venue = new Venue(c);
          venues.push(venue);
          venuesMap[venue.id] = venue;
        }
      }
      ret.venues = venues;
      ret.venuesMap = venuesMap;
    }

    return ret;
  }, [venueEntitiesMap]);
}
