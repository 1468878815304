import { Box, Button } from "@mui/joy";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Id from "atoms/Id/Id";
import { currencyFormatter, getterDivider, setterDivider } from "helpers";
import { FinaleChip } from "hooks/Branding/branding";
import { finale, useMySubscription } from "hooks/subscription/restrictionHooks";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  selectedPdProjectIDSelector,
  selectedProjectIDSelector,
  setDescription420,
  setFormOpen,
  setSelectedPdProjectID,
} from "reducers/rhapsody";
import { paydayApi } from "redux/api/paydayApi";
import { usePdProjects } from "redux/pdProject/pdProjectHooks";
import { usePaydaySyncMutation } from "redux/project/projectEndpoints";

/**
 *
 * @returns {ReactElement} PayDayPayroll page
 */
export function PayDayPayroll() {
  const rhapsodyProjectID = useSelector(selectedProjectIDSelector);
  const payDayProjectID = useSelector(selectedPdProjectIDSelector);
  const { pdProjects } = usePdProjects(rhapsodyProjectID);
  const readOnly = useReadOnly();
  const [syncPdProject] = usePaydaySyncMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mySubscription = useMySubscription();

  const params = useParams();

  useEffect(() => {
    if (params.pdProjectID && !payDayProjectID) {
      dispatch(setSelectedPdProjectID(parseInt(params.pdProjectID)));
      dispatch(setFormOpen({ formID: "pdProject", isOpen: true }));
    }
  }, [params]);

  return (
    <Box
      sx={{
        flex: 1,
        p: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: 1,
        position: "relative",
        flexShrink: 0,
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Button
          size="sm"
          onClick={() => {
            dispatch(setFormOpen({ isOpen: true, formID: "scales" }));
          }}
          color="neutral"
          variant="soft"
        >
          View Scales
        </Button>
        <Button
          size="sm"
          endDecorator={
            mySubscription !== finale ? <FinaleChip transparent /> : []
          }
          disabled={readOnly}
          onClick={() => {
            if (mySubscription !== finale) {
              dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
              dispatch(
                setDescription420(
                  "Budget & Payroll is an exclusive to the Finale plan."
                )
              );
            } else {
              syncPdProject({ id: rhapsodyProjectID, body: {} })
                .unwrap()
                .then(() => {
                  dispatch(paydayApi.util.invalidateTags(["pdProjects"]));
                });
            }
          }}
        >
          + Create Document
        </Button>
      </Box>
      <DataGridPremium
        sx={{ width: "100%" }}
        onRowClick={(e) => {
          dispatch(setSelectedPdProjectID(e.row.id));
          dispatch(setFormOpen({ formID: "pdProject", isOpen: true }));
          navigate(`${e.row.id}`);
        }}
        rows={pdProjects}
        columns={[
          {
            field: "id",
            headerName: "ID",
            renderCell: (p) => (
              <Id startDecorator={<i className="fa-solid fa-dollar-sign"></i>}>
                {p.row.id}
              </Id>
            ),
          },
          { field: "name", headerName: "Name", width: 220 },
          { field: "description", headerName: "Description", flex: 1 },
          { field: "revision", headerName: "Revision", width: 150 },
          {
            field: "total",
            headerName: "Total",
            valueGetter: getterDivider("total", 1000),
            valueSetter: setterDivider("total", 1000),
            valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
            width: 100,
          },
        ]}
        initialState={{ pinnedColumns: { right: ["total"] } }}
      />
    </Box>
  );
}
