import { Box, Button, Divider, Sheet, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { ProjectPiece_Entity } from "entities/projectPiece";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { PieceSelect } from "features/projects/projectPieces/projectPieceAdder";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  selectedProjectPieceIDSelector,
  setFormOpen,
  setRetrieveInstrumentationForProjectPieceID,
} from "reducers/rhapsody";
import { usePiece } from "redux/piece/pieceHooks";
import {
  useDeleteProjectPieceMutation,
  useLazyGetProjectPiecesQuery,
  useUpdateProjectPieceMutation,
} from "redux/projectPiece/projectPieceEndpoints";
import { useProjectPiece } from "redux/projectPiece/projectPieceHooks";

/**
 *
 * @returns {ReactElement} FormProjectPiece page
 */
export function FormProjectPiece() {
  const open = useSelector(formOpenSelector("projectPiece"));
  const selectedProjectPieceID = useSelector(selectedProjectPieceIDSelector);
  const { projectPiece, isLoading } = useProjectPiece(selectedProjectPieceID);
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const [updateProjectPiece] = useUpdateProjectPieceMutation();
  const [deleteProjectPiece] = useDeleteProjectPieceMutation();
  const [getProjectPieces] = useLazyGetProjectPiecesQuery();
  const [_projectPiece, setProjectPiece] = useState<ProjectPiece_Entity>();
  const { piece } = usePiece(_projectPiece?.pieceID);
  const askQuestion = useAskQuestion();

  useEffect(() => {
    setProjectPiece({ ...projectPiece, pieceID: null });
  }, [projectPiece]);

  const onClose = () => {
    dispatch(setFormOpen({ formID: "projectPiece", isOpen: false }));
  };

  const save = (body?: ProjectPiece_Entity) => {
    const shouldRetrieve = !_projectPiece.pieceID;
    updateProjectPiece({ id: _projectPiece.id, body: body ?? _projectPiece })
      .unwrap()
      .then(() => {
        if (shouldRetrieve) {
          getProjectPieces({
            filters: JSON.stringify([
              {
                value: _projectPiece.pieceID ?? body.pieceID,
                comparison: "eq",
                name: "project_pieces.pieceID",
              },
            ]),
          })
            .unwrap()
            .then((u) => {
              const ids = u.ids.reduce((a, v) => {
                if (parseInt(v.toString()) !== projectID)
                  a.push(parseInt(v.toString()));
                return a;
              }, []);
              if (ids.length > 1) {
                dispatch(
                  setRetrieveInstrumentationForProjectPieceID(_projectPiece.id)
                );
                dispatch(
                  setFormOpen({
                    isOpen: true,
                    formID: "retrieveInstrumentationForPiece",
                  })
                );
              }
            });
          onClose();
        }
      });
    if (_projectPiece.pieceID) onClose();
  };

  const askDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>
          You are about to delete a Project Piece. Chairs linked to this Project
          Piece will be deleted as well. .
        </Typography>
      ),
    }).then((i) => {
      if (i == 1) confirmDelete();
    });
  };

  const confirmDelete = async () => {
    deleteProjectPiece(projectPiece.id);
    onClose();
  };

  return (
    <DialogClose open={open && !isLoading} onClose={onClose}>
      <DialogTitle>Select the Piece you are playing:</DialogTitle>
      {_projectPiece?.pieceID ? (
        <DialogContent>
          <Sheet
            variant="soft"
            sx={{
              minWidth: 400,
              p: 1,
              borderRadius: "8px",
              display: "flex",
              gap: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: 600 }} level="body1">
                {piece?.name}
              </Typography>
              <Typography level="body2">{piece?.composer}</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Divider orientation="vertical" />
              <Button
                endDecorator={<i className="fa-solid fa-arrows-rotate"></i>}
                variant="outlined"
                sx={{ background: "white" }}
                size="sm"
                onClick={() =>
                  setProjectPiece((p) => ({ ...p, pieceID: null }))
                }
              >
                Change
              </Button>
            </Box>
          </Sheet>
        </DialogContent>
      ) : (
        <PieceSelect
          selectedPieceID={_projectPiece?.pieceID}
          onSelect={(p) => {
            save({ ..._projectPiece, pieceID: p.id });
          }}
        />
      )}

      <DialogActions>
        <Button onClick={askDelete} variant="soft" color="danger">
          Delete
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={onClose} variant="soft" color="neutral">
          Cancel
        </Button>
        {_projectPiece?.pieceID !== projectPiece?.pieceID &&
        _projectPiece?.pieceID !== null ? (
          <Button onClick={() => save()}>Save</Button>
        ) : (
          []
        )}
      </DialogActions>
    </DialogClose>
  );
}
