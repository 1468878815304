export const typeOptions = [
  {
    id: 1,
    title: "Rehearsal",
    icon: "fas fa-list-music", //"fas fa-comment-music", //"fas fa-ear", //"fas fa-user-music",
    sessionTypeID: 13,
  },
  {
    id: 2,
    title: "Dress Rehearsal",
    icon: "fas fa-user-tie",
    sessionTypeID: 14,
  },
  { id: 3, title: "Sound Check", icon: "fas fa-speaker", sessionTypeID: 15 },
  {
    id: 4,
    title: "Performance",
    icon: "fas fa-theater-masks",
    sessionTypeID: 2,
  },
  {
    id: 5,
    title: "Recording Session",
    icon: "fas fa-microphone",
    sessionTypeID: 1,
  },
  { id: 6, title: "Other", icon: "fad fa-ellipsis-h", sessionTypeID: 1 },
];

export const stageSentence = {
  1: "was invited",
  2: "accepted",
  3: "declined",
  4: "held",
  5: "has been reminded",
  6: "acknowledged the reminder",
  7: "has been canceled",
  8: "acknowledges the cancelation",
  16: "has been asked for availablility",
  17: "is available",
};
