import { ProjectTag } from "entities/projectTag";
import { useMemo } from "react";
import { useGetProjectTagQuery, useGetProjectTagsQuery } from "./projectTagEndpoints";

export function useProjectTag(projectTagID: number | undefined) {
  const projectTagQuery = useGetProjectTagQuery(projectTagID ?? 0, {
    skip: (projectTagID ?? 0) <= 0,
  });
  const projectTagEntity = projectTagQuery.data;

  return useMemo(() => {
    const ret: typeof projectTagQuery & { projectTag?: ProjectTag | undefined } = {
      ...projectTagQuery,
    };
    if (projectTagEntity) ret.projectTag = new ProjectTag(projectTagEntity);
    return ret;
  }, [projectTagEntity]);
}

export function useProjectTags() {
  const projectTagQuery = useGetProjectTagsQuery();
  const projectTagEntitiesMap = projectTagQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectTagQuery & {
      projectTags?: ProjectTag[];
      projectTagsMap?: { [id: number]: ProjectTag };
    } = {
      ...projectTagQuery,
      projectTags: [],
      projectTagsMap: {},
    };
    if (projectTagEntitiesMap) {
      const projectTags = [];
      const projectTagsMap = {};

      for (const key in projectTagEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(projectTagEntitiesMap, key)) {
          const c = projectTagEntitiesMap[key];
          const projectTag = new ProjectTag(c);
          projectTags.push(projectTag);
          projectTagsMap[projectTag.id] = projectTag;
        }
      }
      ret.projectTags = projectTags;
      ret.projectTagsMap = projectTagsMap;
    }

    return ret;
  }, [projectTagEntitiesMap]);
}
