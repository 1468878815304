import { Alert, Avatar, Button, Textarea, Typography } from "@mui/joy";
import {
  Box,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
} from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { DateTimePicker } from "hooks/dateTimePicker/dateTimePicker";
import VenueFinder from "hooks/VenueFinder";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedWorkSessionIDSelector, setFormOpen } from "reducers/rhapsody";
import { layoutInternalSelector } from "reducers/v2/missionControl";
import { useVenue } from "redux/venue/venueHooks";
import { useUpdateWorkSessionMutation } from "redux/workSession/workSessionEndpoints";
import { useWorkSession } from "redux/workSession/workSessionHooks";
import { useWorkSessionTypes } from "redux/workSessionType/workSessionTypeHooks";

export default function EditWorkSession() {
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const { workSession } = useWorkSession(workSessionID);
  const dispatch = useDispatch();
  const titleTextfield = useRef();
  const internal = useSelector(layoutInternalSelector);
  const callSent = internal?.callSent;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [memo, setMemo] = useState("");
  const [venueID, setVenueID] = useState("");
  const [tzName, setTzName] = useState("");
  const [sessionTypeID, setSessionTypeID] = useState("");
  const [updateWorkSession] = useUpdateWorkSessionMutation();
  const { workSessionTypes: sessionTypes } = useWorkSessionTypes();
  const [dateFromUTC, setDateFromUTC] = useState(null);
  const [dateToUTC, setDateToUTC] = useState(null);

  const closeForm = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "workSessionEdit" }));
  };

  const { venue } = useVenue(venueID);

  useEffect(() => {
    if (workSession?.id) {
      setTitle(workSession?.title);
      setDescription(workSession?.description);
      setMemo(workSession?.memo);
      setVenueID(workSession?.venueID);
      setSessionTypeID(workSession?.sessionTypeID);
      setDateFromUTC(workSession?.dateFromUTC);
      setDateToUTC(workSession?.dateToUTC);
      setTzName(workSession?.tzName);
    }
  }, [workSession]);

  useEffect(() => {
    if (venue?.id) {
      setTzName(venue?.tzName);
    }
  }, [venueID]);

  const save = async () => {
    updateWorkSession({
      id: workSessionID,
      body: {
        mainEngagement: {
          dateFromUTC,
          dateToUTC,
          tzName,
          venueID,
        },
        title,
        memo,
        description,
        sessionTypeID,
      },
    });
    closeForm();
  };

  const getWorkSessionTypeSelect = (compact) => (
    <TextField
      variant={compact ? undefined : "standard"}
      label={"Type"}
      inputProps={
        compact != true && {
          style: { fontSize: 24 },
        }
      }
      select
      value={sessionTypeID}
      placeholder={compact ? "Type" : "Select Type"}
      sx={{ width: compact ? 65 : 250 }}
      size={compact ? "small" : "medium"}
      onChange={(e) => setSessionTypeID(e.target.value)}
    >
      {sessionTypes.map((t) => (
        <MenuItem key={t.id} value={t.id}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Avatar
              color="primary"
              variant="soft"
              size="sm"
              sx={{ height: 24, width: 24 }}
            >
              {t.code}
            </Avatar>
            <Typography sx={{ pl: compact ? 3 : 0 }}>{t.name}</Typography>
          </Box>
        </MenuItem>
      ))}
    </TextField>
  );

  const getNameTextField = (compact) => (
    <TextField
      inputRef={titleTextfield}
      variant={compact ? undefined : "standard"}
      label={compact ? "Title" : "Work Session Title"}
      autoFocus
      fullWidth
      inputProps={
        compact != true && {
          style: { fontSize: 24 },
        }
      }
      value={title}
      placeholder={compact ? "Title" : "Add Title"}
      size={compact ? "small" : "medium"}
      onChange={(e) => setTitle(e.target.value)}
    />
  );

  const getVenueFinder = () => (
    <VenueFinder
      key={venueID}
      venueID={venueID}
      setVenue={(v) => {
        if (v) {
          setVenueID(v.id);
          setTzName(v.tzName);
        } else {
          setVenueID(undefined);
        }
      }}
    />
  );

  const getDateAndTime = (compact) => {
    let dateFrom = dateFromUTC ? DateTime.fromISO(dateFromUTC) : null;
    let dateTo = dateToUTC ? DateTime.fromISO(dateToUTC) : null;

    const diff = dateTo?.diff(dateFrom, [
      "years",
      "months",
      "days",
      "hours",
      "minutes",
    ]);

    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            width: 500,
          }}
        >
          <DateTimePicker
            dateFrom={dateFromUTC}
            dateTo={dateToUTC}
            tzName={tzName}
            onChange={(startDate, endDate) => {
              setDateFromUTC(startDate);
              setDateToUTC(endDate);
            }}
          />
        </Box>
        {diff ? (
          <Typography sx={{ pl: 5 }} level="body2">
            Total Duration:{" "}
            {diff?.toObject().days ? `${diff?.toObject().days}d ` : ""}
            {diff?.toObject().hours}h {diff?.toObject().minutes}m
          </Typography>
        ) : (
          []
        )}
      </Box>
    );
  };

  return (
    <DialogClose maxWidth={"lg"} open onClose={closeForm}>
      <DialogContent sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
          {getNameTextField()}
          {getWorkSessionTypeSelect()}
        </Box>
        <Box>
          <Textarea
            placeholder="Anything to write down about this Work Session?"
            sx={{ width: "100%", background: "rgba(255,235,59,0.1)" }}
            onChange={(e) => setDescription(e.target.value)}
            value={description ?? ""}
            size="sm"
            endDecorator={
              <Typography level="body4">Not visible by musicians</Typography>
            }
          />
        </Box>
        <Box>
          <Textarea
            size="sm"
            placeholder="Work Session Memo. Ex: Strings only"
            value={memo}
            minRows={2}
            maxRows={5}
            onChange={(e) => setMemo(e.target.value)}
            endDecorator={
              <Typography level="body4">Visible by musicians.</Typography>
            }
          />
        </Box>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Box sx={{ width: 20 }}>
            <i class="fa-light fa-location-dot"></i>
          </Box>
          <Box sx={{ width: 485 }}>{getVenueFinder(0)}</Box>
        </Box>
        <Box sx={{ display: "flex", gap: 4 }}>{getDateAndTime(0)}</Box>
        {callSent ? (
          <Alert color="danger" variant="solid">
            IMPORTANT: This action won't notify your musicians. You have to
            manually contact your musicians to let them know the change(s).
          </Alert>
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeForm} variant="soft" color="neutral">
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}
