import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { HProject_Entity } from "entities/hProject";
import { Stage_Entity } from "entities/stage";
import { RootState } from "store";

interface RhapsodyState {
  selectedWorkSessionID?: number;
  workSessionMusiciansID?: number;
  chairAccessorialVisibility: { [key: string]: boolean };
  selectedProjectID?: number;
  notifierSelectedStage?: Stage_Entity;
  missionControlMode: MissionControlMode;
  missionControlViewMode: MissionControlViewMode;
  selectedGroupID?: number;
  selectedLinkID?: number;
  selectedRosterID?: number;
  retrieveInstrumentationForProjectPieceID?: number;
  selectedMusicianID?: number;
  selectedPayrollID?: number;
  selectedPieceID?: number;
  selectedCustomerID?: number;
  selectedProjectContactID?: number;
  selectedVenueID?: number;
  selectedEmailID?: number;
  selectedProjectNoteID?: number;
  selectedMusicianNoteID?: number;
  selectedPdProjectID?: number;
  selectedTagID?: number;
  backToSeason?: string;
  selectedProjectPieceID?: number;
  tabIndexes: { [key: string]: number };
  formOpen: { [key: string]: boolean };
  tmpPieceName?: string;
  description420?: string;
  actionInitiator?: string;
  tourScenario?: TourScenario;
  notifier2?: Notifier2;
  notifier3?: Notifier2;
  notifierBody?: NotifierBody;
  mercuryJobIDForHistory?: string;
  viewChairMemos: boolean;
  pdMode: "budget" | "payroll";
  showMusiciansForChairs?: number[];
  showMusiciansForWorkSessionID?: number;
  hideApiErrors?: boolean;
}

export type NotifierBody = {
  greeting: string;
  messenger: string;
  projectNoteIDs: number[];
  projectRosterIDs: number[];
  notes: string;
  showRoster: boolean;
  showSessionDetails: boolean;
};

export type Notifier2 = {
  hProject: HProject_Entity;
  selectedStageID: number;
  nudge?: boolean;
};
export type TourScenario = "guided-tour" | "assign-musician";
export type MissionControlMode = "view" | "edit" | "invite" | "follow";
export type MissionControlViewMode =
  | "grouped"
  | "workSessions"
  | "pieces"
  | "assignments";

const defaultNotifierBody: NotifierBody = {
  greeting: "",
  messenger: "",
  notes: "",
  projectNoteIDs: [],
  projectRosterIDs: [],
  showRoster: false,
  showSessionDetails: true,
};

const initialState = {
  chairAccessorialVisibility: window.localStorage.getItem(
    "chairAccessorialVisibility"
  )
    ? JSON.parse(window.localStorage.getItem("chairAccessorialVisibility"))
    : {
        order: true,
        role: true,
        musicianName: true,
        musicianAvatar: true,
        chairMemo: true,
        instruments: true,
        musicianMemo: true,
        mercury: true,
        talkback: true,
        inspector: true,
      },
  missionControlViewMode: window.localStorage.getItem("missionControlViewMode")
    ? window.localStorage.getItem("missionControlViewMode")
    : "grouped",
  missionControlMode: window.localStorage.getItem("missionControlMode")
    ? window.localStorage.getItem("missionControlMode")
    : "view",
  tabIndexes: {},
  formOpen: {},
  selectedWorkSessionIDs: {},
  selectedProjectPieceIDs: {},
  notifierBody: defaultNotifierBody,
  viewChairMemos: false,
  pdMode: "budget",
} as RhapsodyState;

export const rhapsodySlice = createSlice({
  name: "rhapsody",
  initialState,
  reducers: {
    toggleViewChairMemos(state, action: PayloadAction<void>) {
      state.viewChairMemos = !state.viewChairMemos;
    },
    setBackToSeason(state, action: PayloadAction<string>) {
      state.backToSeason = action.payload;
    },
    setNotifierBody(state, action: PayloadAction<NotifierBody>) {
      state.notifierBody = action.payload;
    },
    setShowMusiciansForChairs(state, action: PayloadAction<number[]>) {
      state.showMusiciansForChairs = action.payload;
    },
    setHideApiErrors(state, action: PayloadAction<boolean>) {
      state.hideApiErrors = action.payload;
    },
    setShowMusiciansForWorkSessionID(state, action: PayloadAction<number>) {
      state.showMusiciansForWorkSessionID = action.payload;
    },
    resetNotifierBody(state) {
      state.notifierBody = defaultNotifierBody;
    },
    setTmpPieceName(state, action: PayloadAction<string>) {
      state.tmpPieceName = action.payload;
    },
    setMercuryJobIDForHistory(state, action: PayloadAction<string>) {
      state.mercuryJobIDForHistory = action.payload;
    },
    setSelectedProjectContactID(state, action: PayloadAction<number>) {
      state.selectedProjectContactID = action.payload;
    },
    setPdMode(state, action: PayloadAction<"budget" | "payroll">) {
      state.pdMode = action.payload;
    },
    setSelectedPdProjectID(state, action: PayloadAction<number>) {
      state.selectedPdProjectID = action.payload;
    },
    setTourSenario(state, action: PayloadAction<TourScenario>) {
      state.tourScenario = action.payload;
    },
    setActionInitiator(state, action: PayloadAction<string>) {
      state.actionInitiator = action.payload;
    },
    setMissionControlMode(state, action: PayloadAction<MissionControlMode>) {
      window.localStorage.setItem("missionControlMode", action.payload);
      state.missionControlMode = action.payload;
    },
    setMissionControlViewMode(
      state,
      action: PayloadAction<MissionControlViewMode>
    ) {
      window.localStorage.setItem("missionControlViewMode", action.payload);
      state.missionControlViewMode = action.payload;
    },
    setNotifierSelectedStage(state, action: PayloadAction<Stage_Entity>) {
      state.notifierSelectedStage = action.payload;
    },
    setNotifier2(state, action: PayloadAction<Notifier2>) {
      state.notifier2 = action.payload;
    },
    setNotifier3(state, action: PayloadAction<Notifier2>) {
      state.notifier3 = action.payload;
    },
    setRetrieveInstrumentationForProjectPieceID(
      state,
      action: PayloadAction<number | null>
    ) {
      state.retrieveInstrumentationForProjectPieceID = action.payload;
    },
    setSelectedWorkSessionID(state, action: PayloadAction<number | null>) {
      state.selectedWorkSessionID = action.payload;
    },
    setWorkSessionMusiciansID(state, action: PayloadAction<number | null>) {
      state.workSessionMusiciansID = action.payload;
    },
    setSelectedProjectPieceID(state, action: PayloadAction<number | null>) {
      state.selectedProjectPieceID = action.payload;
    },
    setSelectedProjectID(state, action: PayloadAction<number | null>) {
      state.selectedProjectID = action.payload;
    },
    setSelectedProjectNoteID(state, action: PayloadAction<number | null>) {
      state.selectedProjectNoteID = action.payload;
    },
    setSelectedPieceID(state, action: PayloadAction<number | null>) {
      state.selectedPieceID = action.payload;
    },
    setSelectedMusicianNoteID(state, action: PayloadAction<number | null>) {
      state.selectedMusicianNoteID = action.payload;
    },
    setSelectedLinkID(state, action: PayloadAction<number | null>) {
      state.selectedLinkID = action.payload;
    },
    setSelectedProjectRosterID(state, action: PayloadAction<number | null>) {
      state.selectedRosterID = action.payload;
    },
    setSelectedGroupID(state, action: PayloadAction<number | null>) {
      state.selectedGroupID = action.payload;
    },
    setSelectedMusicianID(state, action: PayloadAction<number | null>) {
      state.selectedMusicianID = action.payload;
    },
    setSelectedPayrollID(state, action: PayloadAction<number | null>) {
      state.selectedPayrollID = action.payload;
    },
    setSelectedCustomerID(state, action: PayloadAction<number | null>) {
      state.selectedCustomerID = action.payload;
    },
    setSelectedVenueID(state, action: PayloadAction<number | null>) {
      state.selectedVenueID = action.payload;
    },
    setSelectedEmailID(state, action: PayloadAction<number | null>) {
      state.selectedEmailID = action.payload;
    },
    setSelectedTagID(state, action: PayloadAction<number | null>) {
      state.selectedTagID = action.payload;
    },
    setFormOpen(
      state,
      action: PayloadAction<{ formID: FormID; isOpen: boolean }>
    ) {
      const { formID, isOpen } = action.payload;
      state.formOpen[formID] = isOpen;
      if (formID === "subscribe" && !isOpen) {
        state.description420 = null;
      }
    },
    setChairAccessorial(
      state,
      action: PayloadAction<{
        chairAccessorial: ChairAccessorial;
        visible: boolean;
      }>
    ) {
      const { chairAccessorial, visible } = action.payload;
      state.chairAccessorialVisibility[chairAccessorial] = visible;
      window.localStorage.setItem(
        "chairAccessorialVisibility",
        JSON.stringify(state.chairAccessorialVisibility)
      );
    },
    setTabIndex(state, action: PayloadAction<{ tabID: TabID; value: number }>) {
      const { tabID, value } = action.payload;
      state.tabIndexes[tabID] = value;
    },
    setDescription420(state, action: PayloadAction<string | null>) {
      state.description420 = action.payload;
    },
  },
});

export const {
  setPdMode,
  setHideApiErrors,
  toggleViewChairMemos,
  setSelectedProjectContactID,
  setMercuryJobIDForHistory,
  setTourSenario,
  setRetrieveInstrumentationForProjectPieceID,
  setActionInitiator,
  setMissionControlViewMode,
  setMissionControlMode,
  setChairAccessorial,
  setDescription420,
  setSelectedWorkSessionID,
  setWorkSessionMusiciansID,
  setNotifierSelectedStage,
  setSelectedProjectID,
  setSelectedGroupID,
  setSelectedPieceID,
  setSelectedMusicianID,
  setSelectedPayrollID,
  setSelectedProjectNoteID,
  setSelectedMusicianNoteID,
  setSelectedCustomerID,
  setSelectedVenueID,
  setSelectedEmailID,
  setSelectedTagID,
  setSelectedLinkID,
  setSelectedProjectRosterID,
  setSelectedProjectPieceID,
  setFormOpen,
  setTmpPieceName,
  setTabIndex,
  setNotifier2,
  setNotifier3,
  setNotifierBody,
  resetNotifierBody,
  setSelectedPdProjectID,
  setShowMusiciansForChairs,
  setShowMusiciansForWorkSessionID,
  setBackToSeason,
} = rhapsodySlice.actions;

export type FormID =
  | "workSession"
  | "project"
  | "musician"
  | "link"
  | "projectNote"
  | "musicianNote"
  | "customer"
  | "company"
  | "group"
  | "piece"
  | "payroll"
  | "tag"
  | "venue"
  | "emailFinder"
  | "projectSelect"
  | "notifier"
  | "notifier2"
  | "notifier3"
  | "workSessionEdit"
  | "forward"
  | "projectNotesFromProject"
  | "instrumentation"
  | "projectActionRequired"
  | "subscribe"
  | "overlay"
  | "shortcut"
  | "defaultDressCodes"
  | "importRule"
  | "uploadMusicianTemplate"
  | "workSessionMusicians"
  | "onboarding"
  | "musicianTalkback"
  | "projectSidebar"
  | "workSessionSidebard"
  | "organizationUser"
  | "autopilot"
  | "projectPiece"
  | "projectProgram"
  | "projectPieceAdded"
  | "projectPieceReorder"
  | "positionLookup"
  | "assignmentLookup"
  | "autofill"
  | "searchMusician"
  | "template"
  | "retrieveInstrumentationForPiece"
  | "mercuryHistory"
  | "projectPieceShorthand"
  | "workSessionMemos"
  | "projectWageInfo"
  | "projectPiece2"
  | "projectDressCode"
  | "projectContacts"
  | "projectContact"
  | "projectPreFlight"
  | "replaceMusician"
  | "roster"
  | "pdProject"
  | "scales"
  | "defaultStrings"
  | "pdReorderGroups"
  | "showMusiciansForChairs"
  | "releaseRequests"
  | "releaseAdmin"
  | "projectImport"
  | "formerAssignments"
  | "addProjectToSeason"
  | "revisionCallInfo"
  | "notifierProjectNotes"
  | "projectLinks"
  | "projectRosters"
  | "defaultProjectNotes"
  | "reorderInSection"
  | "stripeBalance";

type TabID =
  | "musician"
  | "project"
  | "projects"
  | "workSession"
  | "autopilot"
  | "workSessions"
  | "email"
  | "musicians";

export type ChairAccessorial =
  | "order"
  | "role"
  | "musicianName"
  | "chairMemo"
  | "musicianAvatar"
  | "instruments"
  | "musicianMemo"
  | "mercury"
  | "talkback"
  | "projectPieces"
  | "inspector";

export const formOpenSelector = (formID: FormID) => {
  return createSelector([(s) => s.rhapsody.formOpen], (s) => {
    if (formID === "projectSidebar") return s[formID];
    if (formID === "workSessionSidebard") return s[formID];
    return s[formID] ?? false;
  });
};

export const formOpenMapSelector = createSelector(
  [(s) => s.rhapsody.formOpen],
  (s) => s
);

export const tabIndexSelector = (tabID: TabID) => {
  return createSelector([(s) => s.rhapsody.tabIndexes], (s) => {
    if (tabID === "musicians" && s[tabID] === undefined) return -1;
    return s[tabID] ?? 0;
  });
};

export const description420Selector = createSelector(
  (s: RootState) => s.rhapsody.description420,
  (s) => s
);

export const pdModeSelector = createSelector(
  (s: RootState) => s.rhapsody.pdMode,
  (s) => s
);

export const mercuryIDForHistorySelector = createSelector(
  (s: RootState) => s.rhapsody.mercuryJobIDForHistory,
  (s) => s
);

export const notifier2Selector = createSelector(
  (s: RootState) => s.rhapsody.notifier2,
  (s) => s
);

export const notifier3Selector = createSelector(
  (s: RootState) => s.rhapsody.notifier3,
  (s) => s
);

export const tourScenarioSelector = createSelector(
  (s: RootState) => s.rhapsody.tourScenario,
  (s) => s
);

export const notifierBodySelector = createSelector(
  (s: RootState) => s.rhapsody.notifierBody,
  (s) => s
);

export const actionInitiatorSelector = createSelector(
  (s: RootState) => s.rhapsody.actionInitiator,
  (s) => s
);

export const backToSeasonSelector = createSelector(
  (s: RootState) => s.rhapsody.backToSeason,
  (s) => s
);

export const missionControlModeSelector = createSelector(
  (s: RootState) => s.rhapsody.missionControlMode,
  (s) => s
);

export const viewChairMemosSelector = createSelector(
  (s: RootState) => s.rhapsody.viewChairMemos,
  (s) => s
);

export const missionControlViewModeSelector = createSelector(
  (s: RootState) => s.rhapsody.missionControlViewMode,
  (s) => s
);

export const hideApiErrorsSelector = createSelector(
  (s: RootState) => s.rhapsody.hideApiErrors,
  (s) => s
);

export const notifierSelectedStageSelector = createSelector(
  (s: RootState) => s.rhapsody.notifierSelectedStage,
  (s) => s
);

export const chairAccessorialVisibilitySelector = createSelector(
  (s: RootState) => s.rhapsody.chairAccessorialVisibility,
  (s) => s
);

export const tmpPieceNameSelector = createSelector(
  (s: RootState) => s.rhapsody.tmpPieceName,
  (s) => s
);

export const selectedWorkSessionIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedWorkSessionID,
  (s) => s
);

export const selectedProjectContactIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedProjectContactID,
  (s) => s
);

export const workSessionMusiciansIDSelector = createSelector(
  (s: RootState) => s.rhapsody.workSessionMusiciansID,
  (s) => s
);

export const selectedProjectPieceIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedProjectPieceID,
  (s) => s
);

export const selectedProjectRosterIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedRosterID,
  (s) => s
);

export const selectedPdProjectIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedPdProjectID,
  (s) => s
);

export const retrieveInstrumentationForPieceIDSelector = createSelector(
  (s: RootState) => s.rhapsody.retrieveInstrumentationForProjectPieceID,
  (s) => s
);

export const selectedProjectNoteIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedProjectNoteID,
  (s) => s
);

export const selectedMusicianNoteIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedMusicianNoteID,
  (s) => s
);

export const selectedLinkDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedLinkID,
  (s) => s
);

export const selectedPieceIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedPieceID,
  (s) => s
);

export const selectedProjectIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedProjectID,
  (s) => s
);

export const selectedGroupIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedGroupID,
  (s) => s
);

export const musiciansForChairSelector = createSelector(
  (s: RootState) => s.rhapsody.showMusiciansForChairs,
  (s) => s
);

export const musiciansForWorkSessionSelector = createSelector(
  (s: RootState) => s.rhapsody.showMusiciansForWorkSessionID,
  (s) => s
);

export const selectedMusicianIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedMusicianID,
  (s) => s
);

export const selectedPayrollIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedPayrollID,
  (s) => s
);

export const selectedTagIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedTagID,
  (s) => s
);

export const selectedCustomerIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedCustomerID,
  (s) => s
);

export const selectedVenueIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedVenueID,
  (s) => s
);

export const selectedEmailIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedEmailID,
  (s) => s
);

export const apiEndpointSelector = createSelector(
  (s: RootState) => s.networkState.networkApiValues.endpoint,
  (s) => s
);
