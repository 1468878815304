import { Tooltip, Typography } from "@mui/joy";
import { Box, Icon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
// import PropTypes from 'prop-types';
// image
import { useDispatch } from "react-redux";
import { setSelectedMusicianID } from "reducers/rhapsody";
import { useMusician } from "redux/musician/musicianHooks";

export default function MusicianAvatar({
  musicianID = undefined,
  border = 0,
  musician: _musician = {},
  noBadge = false,
  noTooltip = false,
  size = 40,
}) {
  let { musician } = useMusician(musicianID);
  if (!musicianID) musician = _musician;
  const dispatch = useDispatch();

  if (!musician?.id)
    return (
      <div style={{ display: "inline-block" }}>
        <Avatar
          style={{
            border: `solid ${border}px white`,
            height: size,
            width: size,
            color: "grey",
            background: "white",
          }}
          src={musician?.avatar}
        >
          <Icon className="fad fa-chair" sx={{ fontSize: "inherit" }} />
        </Avatar>
      </div>
    );

  const sub = musician?.sub;

  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip
        size="sm"
        enterDelay={1000}
        enterNextDelay={1000}
        arrow
        title={
          !noTooltip ? (
            <Typography
              level="body4"
              sx={{ textAlign: "center", color: "inherit" }}
            >
              <b>
                {musician?.fullName()}
                {sub ? " (Sub)" : ""}
              </b>
              <br />
              <Typography
                level="body5"
                sx={{ textAlign: "center", color: "inherit" }}
              >
                Click to open profile <i class="fa-solid fa-arrow-up"></i>
              </Typography>
            </Typography>
          ) : undefined
        }
        style={{ cursor: !noTooltip ? "pointer" : undefined }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          dispatch(setSelectedMusicianID(musician?.id));
        }}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          badgeContent={
            !musician?.active ? (
              <Avatar
                sx={{
                  background: "#B29F85",
                  width: 14,
                  fontSize: 7,
                  height: 14,
                  textAlign: "center",
                  border: "solid 1px white",
                }}
              >
                <i class="fa-solid fa-box-archive"></i>
              </Avatar>
            ) : (
              []
            )
          }
        >
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              musician?.star ? (
                <Avatar
                  sx={{
                    background: "#e91e63",
                    width: 14,
                    fontSize: 7,
                    height: 14,
                    textAlign: "center",
                    border: "solid 1px white",
                  }}
                >
                  <i className="fa-solid fa-star"></i>
                </Avatar>
              ) : (
                []
              )
            }
          >
            <Box
              sx={{
                background: "transparent",
                border: sub ? `dashed 2px #607d8b` : "",
                borderRadius: "100%",
                padding: "1px",
                position: "relative",
              }}
            >
              <Avatar
                style={{
                  fontSize: size / 2.2,
                  fontWeight: 800,
                  height: sub ? size - 4 : size,
                  width: sub ? size - 4 : size,
                  border: `solid ${border}px white`,
                  boxShadow: border
                    ? "0px 2px 2px rgba(155,155,155,0.3)"
                    : undefined,
                }}
                src={musician?.avatar}
              >
                {musician.initials()}
              </Avatar>
            </Box>
          </Badge>
        </Badge>
      </Tooltip>
    </div>
  );
}
