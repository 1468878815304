import { Dictionary } from "@reduxjs/toolkit";
import { Project, Project_Entity } from "entities/project";
import { useMemo } from "react";
import {
  useGetActiveProjectsQuery,
  useGetAllProjectsQuery,
  useGetArchivedProjectsQuery,
  useGetCustomerProjectsQuery,
  useGetMusicianProjectsQuery,
  useGetPayrollProjectsQuery,
  useGetProjectEmailsQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetTagProjectsQuery,
  useGetVenueProjectsQuery,
} from "./projectEndpoints";
import { useProjectPiecesFilter } from "redux/projectPiece/projectPieceHooks";
import { Email } from "entities/email";

export function useProject(
  projectID: number | undefined,
  opt?: { skip?: boolean }
) {
  const projectQuery = useGetProjectQuery(projectID ?? 0, {
    skip: (projectID ?? 0) <= 0 || opt?.skip,
  });
  const projectEntity = projectQuery.data;

  return useMemo(() => {
    const ret: typeof projectQuery & { project?: Project | undefined } = {
      ...projectQuery,
    };
    if (projectEntity) ret.project = new Project(projectEntity);
    return ret;
  }, [projectEntity]);
}

export function useProjects(args?: any, opt?: any) {
  const projectQuery = useGetActiveProjectsQuery(args, opt);
  const projectEntitiesMap = projectQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectQuery & {
      projects?: Project[];
      projectsMap?: { [id: number]: Project };
    } = {
      ...projectQuery,
      projects: [],
      projectsMap: {},
    };
    if (projectEntitiesMap) {
      const projects = [];
      const projectsMap = {};

      for (const key in projectEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(projectEntitiesMap, key)) {
          const c = projectEntitiesMap[key];
          const project = new Project(c);
          if (!project.template) {
            projects.push(project);
            projectsMap[project.id] = project;
          }
        }
      }
      ret.projects = projects;
      ret.projectsMap = projectsMap;
    }

    return ret;
  }, [projectEntitiesMap]);
}

export function useProjectsFilter(args?: any, opt?: any) {
  const projectQuery = useGetProjectsQuery(args, opt);
  const projectEntitiesMap = projectQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectQuery & {
      projects?: Project[];
      projectsMap?: { [id: number]: Project };
    } = {
      ...projectQuery,
      projects: [],
      projectsMap: {},
    };
    if (projectEntitiesMap) {
      const projects = [];
      const projectsMap = {};

      for (const key in projectEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(projectEntitiesMap, key)) {
          const c = projectEntitiesMap[key];
          const project = new Project(c);
          if (!project.template) {
            projects.push(project);
            projectsMap[project.id] = project;
          }
        }
      }
      ret.projects = projects;
      ret.projectsMap = projectsMap;
    }

    return ret;
  }, [projectEntitiesMap]);
}

export function useArchivedProjects() {
  const projectQuery = useGetArchivedProjectsQuery();
  const projectEntitiesMap = projectQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectQuery & {
      projects?: Project[];
      projectsMap?: { [id: number]: Project };
    } = {
      ...projectQuery,
      projects: [],
      projectsMap: {},
    };
    if (projectEntitiesMap) {
      const projects = [];
      const projectsMap = {};

      for (const key in projectEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(projectEntitiesMap, key)) {
          const c = projectEntitiesMap[key];
          const project = new Project(c);
          if (!project.template) {
            projects.push(project);
            projectsMap[project.id] = project;
          }
        }
      }
      ret.projects = projects;
      ret.projectsMap = projectsMap;
    }

    return ret;
  }, [projectEntitiesMap]);
}

export function useTemplates(args?) {
  const templateQuery = useGetActiveProjectsQuery(args);
  const templateEntitiesMap = templateQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof templateQuery & {
      templates?: Project[];
      templatesMap?: { [id: number]: Project };
    } = {
      ...templateQuery,
      templates: [],
      templatesMap: {},
    };
    if (templateEntitiesMap) {
      const templates = [];
      const templatesMap = {};

      for (const key in templateEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(templateEntitiesMap, key)) {
          const c = templateEntitiesMap[key];
          const project = new Project(c);
          if (project.template) {
            templates.push(project);
            templatesMap[project.id] = project;
          }
        }
      }
      ret.templates = templates;
      ret.templatesMap = templatesMap;
    }

    return ret;
  }, [templateEntitiesMap]);
}

export function useArchivedTemplates(skip: boolean) {
  const templateQuery = useGetArchivedProjectsQuery(undefined, { skip });
  const templateEntitiesMap = templateQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof templateQuery & {
      templates?: Project[];
      templatesMap?: { [id: number]: Project };
    } = {
      ...templateQuery,
      templates: [],
      templatesMap: {},
    };
    if (templateEntitiesMap) {
      const templates = [];
      const templatesMap = {};

      for (const key in templateEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(templateEntitiesMap, key)) {
          const c = templateEntitiesMap[key];
          const project = new Project(c);
          if (project.template && project.archived) {
            templates.push(project);
            templatesMap[project.id] = project;
          }
        }
      }
      ret.templates = templates;
      ret.templatesMap = templatesMap;
    }

    return ret;
  }, [templateEntitiesMap]);
}

export function useCustomerProjects(customerID?: number | undefined) {
  const projectQuery = useGetCustomerProjectsQuery(customerID, {
    skip: !customerID,
  });
  const projectEntitiesMap = projectQuery.data?.entities;

  return useMemo(() => {
    const f = projectsFactory(projectEntitiesMap);
    return {
      ...projectQuery,
      customerProjects: f.array,
      customerProjectsMap: f.map,
    };
  }, [projectEntitiesMap]);
}

export function usePayrollProjects(payrollID?: number | undefined) {
  const projectQuery = useGetPayrollProjectsQuery(payrollID, {
    skip: !payrollID,
  });
  const projectEntitiesMap = projectQuery.data?.entities;

  return useMemo(() => {
    const f = projectsFactory(projectEntitiesMap);
    return {
      ...projectQuery,
      payrollProjects: f.array,
      payrollProjectsMap: f.map,
    };
  }, [projectEntitiesMap]);
}

export function useVenueProjects(venueID?: number | undefined) {
  const projectQuery = useGetVenueProjectsQuery(venueID, {
    skip: !venueID,
  });
  const projectEntitiesMap = projectQuery.data?.entities;

  return useMemo(() => {
    const f = projectsFactory(projectEntitiesMap);
    return {
      ...projectQuery,
      venueProjects: f.array,
      venueProjectsMap: f.map,
    };
  }, [projectEntitiesMap]);
}

export function useTagProjects(tagID?: number | undefined) {
  const projectQuery = useGetTagProjectsQuery(tagID, {
    skip: !tagID || tagID < 0,
  });
  const projectEntitiesMap = projectQuery.data?.entities;

  return useMemo(() => {
    const f = projectsFactory(projectEntitiesMap);
    return {
      ...projectQuery,
      tagProjects: f.array,
      tagProjectsMap: f.map,
    };
  }, [projectEntitiesMap]);
}

export function useMusicianProjects(musicianID?: number | undefined) {
  const projectQuery = useGetMusicianProjectsQuery(musicianID, {
    skip: !musicianID,
  });
  const projectEntitiesMap = projectQuery.data?.entities;

  return useMemo(() => {
    const f = projectsFactory(projectEntitiesMap);
    return {
      ...projectQuery,
      musicianProjects: f.array,
      musicianProjectsMap: f.map,
    };
  }, [projectEntitiesMap]);
}

function projectsFactory(map: Dictionary<Project_Entity>): {
  array: Project[];
  map: { [id: number]: Project };
} {
  const ret: {
    array: Project[];
    map: { [id: number]: Project };
  } = { array: [], map: {} };
  if (map) {
    const customerProjects = [];
    const customerProjectsMap = {};

    for (const key in map) {
      if (Object.prototype.hasOwnProperty.call(map, key)) {
        const c = map[key];
        const project = new Project(c);
        customerProjects.push(project);
        customerProjectsMap[project.id] = project;
      }
    }
    ret.array = customerProjects;
    ret.map = customerProjectsMap;
  }

  return ret;
}

export function useProjectsForPieceID(pieceID: number) {
  const { projectPieces } = useProjectPiecesFilter(
    {
      filters: JSON.stringify([
        {
          name: "project_pieces.pieceID",
          comparison: "eq",
          value: pieceID,
        },
      ]),
    },
    { skip: !pieceID }
  );

  const projectIDs = projectPieces.reduce((a, v) => {
    a.push(v.projectID);
    return a;
  }, []);

  const { projects } = useProjectsFilter(
    {
      filters: JSON.stringify([
        {
          name: "id",
          comparison: "in",
          value: projectIDs,
        },
      ]),
    },
    { skip: projectIDs?.length === 0 }
  );

  return { projects, projectPieces } ?? { projects: [], projectPieces: [] };
}

export function useProjectEmails(projectID: number, filters?: any) {
  const emailQuery = useGetProjectEmailsQuery(
    { projectID, filters },
    { skip: !projectID }
  );
  const emailEntitiesMap = emailQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof emailQuery & {
      emails?: Email[];
      emailsMap?: { [id: number]: Email };
    } = {
      ...emailQuery,
      emails: [],
      emailsMap: {},
    };
    if (emailEntitiesMap) {
      const emails = [];
      const emailsMap = {};

      for (const key in emailEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(emailEntitiesMap, key)) {
          const c = emailEntitiesMap[key];
          const email = new Email(c);
          emails.push(email);
          emailsMap[email.id] = email;
        }
      }
      ret.emails = emails;
      ret.emailsMap = emailsMap;
    }

    return ret;
  }, [emailEntitiesMap]);
}

export function useAllProjects(params, options?) {
  const projectQuery = useGetAllProjectsQuery(params, options);
  const projectEntitiesMap = projectQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectQuery & {
      projects?: Project[];
      projectsMap?: { [id: number]: Project };
    } = {
      ...projectQuery,
      projects: [],
      projectsMap: {},
    };
    if (projectEntitiesMap) {
      const projects = [];
      const projectsMap = {};

      for (const key in projectEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(projectEntitiesMap, key)) {
          const c = projectEntitiesMap[key];
          const project = new Project(c);
          projects.push(project);
          projectsMap[project.id] = project;
        }
      }
      ret.projects = projects;
      ret.projectsMap = projectsMap;
    }

    return ret;
  }, [projectEntitiesMap]);
}
