import WorkSessionSection_Entity from "./workSessionSection";
export default class WorkSessionSection extends WorkSessionSection_Entity {
  static fromList(workSessionSectionsJSON: unknown): Array<WorkSessionSection> {
    const workSessionSections: WorkSessionSection[] = [];
    if (workSessionSectionsJSON)
      Array.isArray(workSessionSectionsJSON) &&
        workSessionSectionsJSON.forEach((workSessionSectionJSON) => {
          workSessionSections.push(new WorkSessionSection(workSessionSectionJSON));
        });
    return workSessionSections;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
