import Tag_Entity from "./tag";
export default class Tag extends Tag_Entity {
  static fromList(tagsJSON: unknown): Array<Tag> {
    const tags: Tag[] = [];
    if (tagsJSON)
      Array.isArray(tagsJSON) &&
        tagsJSON.forEach((tagJSON) => {
          tags.push(new Tag(tagJSON));
        });
    return tags;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
