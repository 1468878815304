import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "config/methods";

const service = "unity";
const posts = `${REACT_APP_API_PREFIX}/${service}/posts`;

export function getPosts(filters) {
  const url = `${posts}${
    filters ? `?filters=${JSON.stringify(filters)}` : "?limit=100"
  }`;
  return {
    method: GET,
    url,
  };
}

export function getPostById(id) {
  const url = `${posts}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updatePost(id, body) {
  const url = `${posts}/${id}`;
  return {
    method: PUT,
    url,
    body,
    data: {
      postID: id,
    },
  };
}

export function deletePost(id) {
  const url = `${posts}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPost(body) {
  const url = `${posts}`;
  return {
    method: POST,
    url,
    body,
  };
}
