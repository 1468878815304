import { SectionInstrument } from "entities/sectionInstrument";
import { useMemo } from "react";
import {
  useGetSectionInstrumentQuery,
  useGetSectionInstrumentsQuery,
} from "./sectionInstrumentEndpoints";

export function useSectionInstrument(sectionInstrumentID: number | undefined) {
  const sectionInstrumentQuery = useGetSectionInstrumentQuery(
    sectionInstrumentID ?? 0,
    {
      skip: (sectionInstrumentID ?? 0) <= 0,
    }
  );
  const sectionInstrumentEntity = sectionInstrumentQuery.data;

  return useMemo(() => {
    const ret: typeof sectionInstrumentQuery & {
      sectionInstrument?: SectionInstrument | undefined;
    } = {
      ...sectionInstrumentQuery,
    };
    if (sectionInstrumentEntity)
      ret.sectionInstrument = new SectionInstrument(sectionInstrumentEntity);
    return ret;
  }, [sectionInstrumentEntity]);
}

export function useSectionInstruments() {
  const sectionInstrumentQuery = useGetSectionInstrumentsQuery();
  const sectionInstrumentEntitiesMap = sectionInstrumentQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof sectionInstrumentQuery & {
      sectionInstruments?: SectionInstrument[];
      sectionInstrumentsMap?: { [id: number]: SectionInstrument };
    } = {
      ...sectionInstrumentQuery,
      sectionInstruments: [],
      sectionInstrumentsMap: {},
    };
    if (sectionInstrumentEntitiesMap) {
      const sectionInstruments = [];
      const sectionInstrumentsMap = {};

      for (const key in sectionInstrumentEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(
            sectionInstrumentEntitiesMap,
            key
          )
        ) {
          const c = sectionInstrumentEntitiesMap[key];
          const sectionInstrument = new SectionInstrument(c);
          sectionInstruments.push(sectionInstrument);
          sectionInstrumentsMap[sectionInstrument.id] = sectionInstrument;
        }
      }
      ret.sectionInstruments = sectionInstruments;
      ret.sectionInstrumentsMap = sectionInstrumentsMap;
    }

    return ret;
  }, [sectionInstrumentEntitiesMap]);
}
