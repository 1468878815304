/**
 * ProjectSection_Entity type guard.
 *
 * @param {any} projectSectionJson ProjectSection object from API
 * @returns {boolean} Return true if type is ProjectSection_Entity
 */

import ProjectAlternate_Entity from "entities/projectAlternate/projectAlternate";

export default class ProjectSection_Entity {
  constructor(projectSectionJson: ProjectSection_Entity) {
    try {
      this.id = projectSectionJson.id;
      this.familyID = projectSectionJson.familyID;
      this.familyPos = projectSectionJson.familyPos;
      this.familyName = projectSectionJson.familyName;
      this.sectionID = projectSectionJson.sectionID;
      this.sectionPos = projectSectionJson.sectionPos;
      this.sectionName = projectSectionJson.sectionName;
      this.projectMusicians = projectSectionJson.projectMusicians;
      this.projectID = projectSectionJson.projectID;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  familyID: number;
  familyPos: number;
  familyName: string;
  sectionID: number;
  sectionName: string;
  sectionPos: number;
  projectID: number;
  projectMusicians: ProjectAlternate_Entity[];
}
