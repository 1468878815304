import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  layoutDebugSelector,
  layoutInternalSelector,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";
import {
  useCreateChairsMutation,
  useDeleteBatchChairsMutation,
} from "redux/rhapsodyChair/rhapsodyChairEndpoints";

/**
 *
 * @returns {ReactElement} Deletes useless work session chairs
 */
export function useSectionsExclusion(): () => void {
  const utils = useSelector(layoutUtilsSelector);
  const internal = useSelector(layoutInternalSelector);
  const { chairs, workSessions, sectionsMap, workSessionsMap } = utils;
  const [deleteChairs] = useDeleteBatchChairsMutation();
  const [createChairs] = useCreateChairsMutation();
  const debug = useSelector(layoutDebugSelector);

  useEffect(() => {
    sectionsExclusion();
  }, [internal]);

  const consoleDebug = (message?: any, ...optionalParams: any[]) => {
    if (debug) {
      console.log(message, ...optionalParams);
    }
  };

  const sectionsExclusion = () => {
    const toCreate = [];
    const toDelete = [];
    consoleDebug("🚪 Sections Exclusion");

    // We make sure all chairs in an excluded section are hidden.
    workSessions.forEach((w) => {
      const excludedSectionIDs = w.sectionIDs ? JSON.parse(w.sectionIDs) : [];
      excludedSectionIDs.forEach((sectionID) => {
        consoleDebug(`${sectionsMap[sectionID].name} is excluded`);
        const _chairs = chairs.filter(
          (c) => c.workSessionIDs.includes(w.id) && c.sectionID === sectionID
        );
        const hiddenChairs = _chairs.filter((c) => c.hidden);
        const visibleChairs = _chairs.filter((c) => !c.hidden);

        visibleChairs.forEach((c) => {
          // c needs to have a hidden chair at the same order.
          const match = hiddenChairs.find(
            (h) => h.sectionOrder === c.sectionOrder
          );
          if (!match) {
            const item = { ...c };
            delete item.id;
            delete item.assignmentID;
            delete item.workSessionIDs;
            item.chairCount = 1;
            item.musicianID = -1;
            item.workSessionID = w.id;
            item.hidden = true;

            toCreate.push(item);
            consoleDebug(
              `- ${sectionsMap[sectionID].name} is excluded on Work Session #${w.id}. Chair ${c.sectionOrder} needs a hidden chair.`
            );
          }
        });
      });
    });

    // We make sure hidden chairs belong to excluded sections.
    const hiddenChairs = chairs.filter((c) => c.hidden);
    hiddenChairs.forEach((c) => {
      const workSession = workSessionsMap[c.workSessionID];
      if (workSession) {
        const excludedSectionIDs = workSession.sectionIDs
          ? JSON.parse(workSession.sectionIDs)
          : [];
        if (!excludedSectionIDs.includes(c.sectionID) && c.memo !== "manual") {
          consoleDebug(
            `- Chair ${c.id} is hidden in section ${
              sectionsMap[c.sectionID].name
            } for Work Session #${workSession.id}, but should not be.`
          );
          toDelete.push(c);
        }
      }
    });

    if (toCreate.length) createChairs(toCreate);
    if (toDelete.length) deleteChairs(toDelete);
  };

  return sectionsExclusion;
}
