/**
 * Job_Entity type guard.
 *
 * @param {any} jobJson Job object from API
 * @returns {boolean} Return true if type is Job_Entity
 */

import { Instrument_Entity } from "entities/instrument";
import { Musician_Entity } from "entities/musician";

export default class Job_Entity {
  constructor(jobJson: Job_Entity) {
    try {
      this.id = jobJson.id;
      this.mercuryJobID = jobJson.mercuryJobID;
      this.mercuryStageID = jobJson.mercuryStageID;
      this.sessionID = jobJson.sessionID;
      this.musicianID = jobJson.musicianID;
      this.musician = jobJson.musician;
      this.instrumentID = jobJson.instrumentID;
      this.sectionID = jobJson.sectionID;
      this.sectionRoleID = jobJson.sectionRoleID;
      this.tunes = jobJson.tunes;
      this.scaleWages = jobJson.scaleWages;
      this.overscale = jobJson.overscale;
      this.benefitsOnly = jobJson.benefitsOnly;
      this.cartage = jobJson.cartage;
      this.doubles = jobJson.doubles;
      this.rehearsalCount = jobJson.rehearsalCount;
      this.performanceCount = jobJson.performanceCount;
      this.premiumPercent = jobJson.premiumPercent;
      this.nonPensionable = jobJson.nonPensionable;
      this.otherPensionable = jobJson.otherPensionable;
      this.rehearsalScale = jobJson.rehearsalScale;
      this.performanceScale = jobJson.performanceScale;
      this.doublingPay = jobJson.doublingPay;
      this.premiumScale = jobJson.premiumScale;
      this.workDues = jobJson.workDues;
      this.pension = jobJson.pension;
      this.welfare = jobJson.welfare;
      this.hoursGuaranteed = jobJson.hoursGuaranteed;
      this.hoursWorked = jobJson.hoursWorked;
      this.active = jobJson.active;
      this.excludedFromPayroll = jobJson.excludedFromPayroll;
      this.paidToMusician = jobJson.paidToMusician;
      this.publicToken = jobJson.publicToken;
      this.sectionOrder = jobJson.sectionOrder;
      this.memo = jobJson.memo;
      this.createCount = jobJson.createCount;
      this.relationID = jobJson.relationID;
      // this.instrument = jobJson.instrument;
      // this.session = jobJson.session;
      this.events = jobJson.events;
      // this.displayedEvent = jobJson.displayedEvent;
      this.projectID = jobJson.projectID;
      this.sectionOrderDiff = jobJson.sectionOrderDiff;
      this.instrumentIDs = jobJson.instrumentIDs;
      this.emptyChairIndex = jobJson.emptyChairIndex;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ JobStage_Entity.name }: ${e}"
      );
    }
  }

  id?: number;
  mercuryJobID?: string;
  musician?: Musician_Entity;
  mercuryStageID?: number;
  sessionID?: number;
  musicianID?: number;
  instrumentID?: number;
  sectionID: number;
  sectionRoleID?: number;
  tunes?: string;
  scaleWages?: number;
  overscale?: boolean;
  benefitsOnly?: boolean;
  cartage?: number;
  doubles?: number;
  rehearsalCount?: number;
  performanceCount?: number;
  premiumPercent?: number;
  nonPensionable?: number;
  otherPensionable?: number;
  rehearsalScale?: number;
  performanceScale?: number;
  doublingPay?: number;
  premiumScale?: number;
  workDues?: number;
  pension?: number;
  welfare?: number;
  hoursGuaranteed?: string;
  hoursWorked?: string;
  active?: boolean;
  excludedFromPayroll?: boolean;
  paidToMusician?: boolean;
  publicToken?: string;
  sectionOrder: number;
  memo?: string;
  createCount?: number;
  relationID?: number;
  instrument?: Instrument_Entity;
  // session?: <NotFound>;
  events: Array<Event>;
  // displayedEvent: <NotFound>;
  projectID?: number;
  sectionOrderDiff?: number;
  instrumentIDs?: string;
  emptyChairIndex?: number;
}
