// import PropTypes from 'prop-types';

// styles
import { Box } from "@mui/joy";
import NotFound from "atoms/NotFound";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import CalendarPage from "routes/calendar/calendar";
import { Checkout } from "routes/checkout/checkout";
import CustomerPage from "routes/customer/customer";
import CustomersPage from "routes/customers/customers";
import EmailPage from "routes/email/email";
import EmailsPage from "routes/emails/emails";
import { Importer } from "routes/importer/importer";
import MusicianPage from "routes/musician/musician";
import MusiciansPage from "routes/musicians/musicians";
import MyAccount from "routes/my-account/my-account";
import AditionalUsers from "routes/onboarding/additionalUsers";
import AddressBook from "routes/onboarding/addressBook";
import OnboardingCheckout from "routes/onboarding/checkout";
import CompanyInfo from "routes/onboarding/companyInfo";
import ContractorProfile from "routes/onboarding/contractorProfile";
import Onboarding from "routes/onboarding/onboarding";
import PricingPlan from "routes/onboarding/pricingPlan";
import Venues from "routes/onboarding/venues";
import PayrollPage from "routes/payroll/payroll";
import PayrollsPage from "routes/payrolls/payrolls";
import PiecePage from "routes/piece/piece";
import PiecesPage from "routes/pieces/pieces";
import { ProjectLoader } from "routes/project/loader";
import ProjectPage from "routes/project/project";
import ProjectsPage from "routes/projects/projects";
import Root from "routes/root/root";
import SeasonPage from "routes/season/season";
import { SyncStripe } from "routes/syncStripe/syncStripe";
import VenuePage from "routes/venue/venue";
import VenuesPage from "routes/venues/venues";
import WorkSessionPage from "routes/workSession/workSession";
import WorkSessionsPage from "routes/workSessions/workSessions";

export const RouterConfig = {
  root: "/",
  workSessions: "/work-sessions",
  onboarding: "/onboarding",
  projects: "/projects",
  customers: "/customers",
  payrolls: "/payrolls",
  checkout: "/checkout",
  pieces: "/pieces",
  syncStripe: "/sync-stripe",
  venues: "/venues",
  emails: "/emails",
  templates: "/templates",
  seasons: "/seasons",
  addressBook: "/address-book",
  myAccount: "/account",
  calendar: "/calendar",
  importer: "/importer",
};

export const breadcrumbNameMap = {
  [RouterConfig.root]: "Home",
  [RouterConfig.onboarding]: "Onboarding",
  [RouterConfig.workSessions]: "Work Sessions",
  [RouterConfig.projects]: "Projects",
  [RouterConfig.templates]: "Templates",
  [RouterConfig.customers]: "Customers",
  [RouterConfig.payrolls]: "Payrolls",
  [RouterConfig.checkout]: "Checkout",
  [RouterConfig.pieces]: "Pieces",
  [RouterConfig.calendar]: "Calendar",
  [RouterConfig.emails]: "Emails",
  [RouterConfig.templates]: "Templates",
  [RouterConfig.venues]: "Venues",
  [RouterConfig.addressBook]: "Address Book",
};

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RouterConfig.root} element={<Root />}>
          <Route
            path={RouterConfig.syncStripe}
            element={<SyncStripe />}
          ></Route>
          <Route path={RouterConfig.onboarding} element={<Onboarding />}>
            <Route path={"pricing-plan"} element={<PricingPlan />}></Route>
            <Route path={"profile"} element={<ContractorProfile />}></Route>
            <Route path={"company"} element={<CompanyInfo />}></Route>
            <Route path={"venues"} element={<Venues />}></Route>
            <Route path={"checkout"} element={<OnboardingCheckout />}></Route>
            <Route
              path={"additional-users"}
              element={<AditionalUsers />}
            ></Route>
            <Route path={"address-book"} element={<AddressBook />}></Route>
          </Route>
          <Route path={"*"} element={<NotFound />}></Route>
          <Route
            path={`${RouterConfig.workSessions}/*`}
            element={<WorkSessionsPage />}
          >
            <Route
              path={`:workSessionID/*`}
              element={<WorkSessionPage routerMode />}
            ></Route>
          </Route>
          <Route path={RouterConfig.pieces} element={<PiecesPage />}>
            <Route path={":pieceID/*"} element={<PiecePage />} />
          </Route>
          <Route path={RouterConfig.projects} element={<ProjectsPage />}>
            <Route
              path={":projectID/*"}
              element={
                <ProjectLoader>
                  <ProjectPage />
                </ProjectLoader>
              }
            />
          </Route>
          <Route path={RouterConfig.checkout} element={<Outlet />}>
            <Route path={":checkoutID/*"} element={<Checkout />} />
          </Route>
          <Route path={RouterConfig.customers} element={<CustomersPage />}>
            <Route path={":customerID/*"} element={<CustomerPage />} />
          </Route>
          <Route path={RouterConfig.payrolls} element={<PayrollsPage />}>
            <Route path={":payrollID/*"} element={<PayrollPage />} />
          </Route>
          <Route path={RouterConfig.venues} element={<VenuesPage />}>
            <Route path={":venueID/*"} element={<VenuePage />} />
          </Route>
          <Route path={RouterConfig.addressBook} element={<MusiciansPage />}>
            <Route
              path={":musicianID/*"}
              element={<MusicianPage routerMode />}
            />
          </Route>
          <Route
            path={RouterConfig.calendar}
            element={<CalendarPage />}
          ></Route>
          <Route
            path={RouterConfig.templates}
            element={<ProjectsPage template />}
          >
            <Route
              path={":projectID/*"}
              element={
                <ProjectLoader>
                  <ProjectPage template />
                </ProjectLoader>
              }
            />
          </Route>
          <Route path={RouterConfig.emails} element={<EmailsPage />}>
            <Route path={":emailID/*"} element={<EmailPage />} />
          </Route>
          <Route path={RouterConfig.seasons} element={<Outlet />}>
            <Route path={":tagID/*"} element={<SeasonPage />} />
          </Route>
          <Route
            path={`${RouterConfig.myAccount}/*`}
            element={<MyAccount />}
          ></Route>
          <Route
            path={`${RouterConfig.importer}/*`}
            element={<Importer />}
          ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
