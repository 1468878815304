import {
  ListItemContent,
  ListItemDecorator,
  Menu,
  MenuItem,
  Typography,
} from "@mui/joy";
import { Box, alpha } from "@mui/system";
import { Chair } from "entities/chair";
import { useState } from "react";
import {
  ChairOption,
  ChairType,
  actionIcons,
  actionLabel,
  useChairOptions,
} from "./useChairActions";

export function ChairAccessorials({
  visible,
  onSelect,
  chair,
  chairType,
  stageID,
}: {
  visible: boolean;
  onSelect: (s: ChairOption) => void;
  chair: Chair;
  chairType: ChairType;
  stageID?: number;
}) {
  const options = useChairOptions(chairType, stageID);
  const [anchorEl, setAnchorEl] = useState();
  const open = anchorEl ? true : false;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box
        sx={{
          opacity: visible ? 1 : 0,
          height: 16,
          marginTop: "-2px",
          width: 16,
          textAlign: "center",
          color: alpha("#9e9e9e", 0.8),
          cursor: "pointer",
          "&:hover": {
            color: alpha("#9e9e9e", 0.9),
          },
          "&:active": {
            color: alpha("#9e9e9e", 1),
          },
        }}
        onClick={handleClick}
      >
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </Box>
      <Menu
        variant="outlined"
        size="sm"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 2000 }}
      >
        {options.indexOf("chair-memo") >= 0 ? (
          <MenuItem
            onClick={() => {
              onSelect("chair-memo");
              setAnchorEl(null);
            }}
          >
            <ListItemDecorator sx={{ color: "#424242" }}>
              <i className={actionIcons["chair-memo"]}></i>
            </ListItemDecorator>
            <ListItemContent>
              <Typography level="body2" textColor="common.black">
                {actionLabel["chair-memo"]}
              </Typography>
            </ListItemContent>
          </MenuItem>
        ) : (
          []
        )}
        {options.indexOf("musician-memo") >= 0 ? (
          <MenuItem
            onClick={() => {
              onSelect("musician-memo");
              setAnchorEl(null);
            }}
          >
            <ListItemDecorator sx={{ color: "#424242" }}>
              <i className={actionIcons["musician-memo"]}></i>
            </ListItemDecorator>
            <ListItemContent>
              <Typography level="body2" textColor="common.black">
                {actionLabel["musician-memo"]}
              </Typography>
            </ListItemContent>
          </MenuItem>
        ) : (
          []
        )}
        {options.indexOf("remove-musician") >= 0 ? (
          <MenuItem
            onClick={() => {
              onSelect("remove-musician");
              setAnchorEl(null);
            }}
          >
            <ListItemDecorator sx={{ color: "#424242" }}>
              <i className={actionIcons["remove-musician"]}></i>
            </ListItemDecorator>
            <ListItemContent>
              <Typography level="body2" textColor="common.black">
                {actionLabel["remove-musician"]}
              </Typography>
            </ListItemContent>
          </MenuItem>
        ) : (
          []
        )}
        {options.indexOf("delete-chair") >= 0 &&
        (!chair.musicianID || chair.musicianID === -1) ? (
          <MenuItem
            onClick={() => {
              onSelect("delete-chair");
              setAnchorEl(null);
            }}
          >
            <ListItemDecorator sx={{ color: "#424242" }}>
              <i className={actionIcons["delete-chair"]}></i>
            </ListItemDecorator>
            <ListItemContent>
              <Typography level="body2" textColor="common.black">
                {actionLabel["delete-chair"]}
              </Typography>
            </ListItemContent>
          </MenuItem>
        ) : (
          []
        )}
      </Menu>
    </>
  );
}
