import { Typography } from "@mui/joy";
import { Box, Paper } from "@mui/material";
import { Plans } from "hooks/subscription/subscribe";

export default function PricingPlan() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography level="h4">
        <b>Select your Rhapsody Plan</b>
      </Typography>
      <Paper
        variant="outlined"
        sx={{ borderRadius: "16px", overflow: "hidden" }}
      >
        <Plans select />
      </Paper>
    </Box>
  );
}
