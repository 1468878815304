import LibrarianPiece_Entity from "./librarianPiece";
export default class LibrarianPiece extends LibrarianPiece_Entity {
  static fromList(librarianPiecesJSON: unknown): Array<LibrarianPiece> {
    const librarianPieces: LibrarianPiece[] = [];
    if (librarianPiecesJSON)
      Array.isArray(librarianPiecesJSON) &&
        librarianPiecesJSON.forEach((librarianPieceJSON) => {
          librarianPieces.push(new LibrarianPiece(librarianPieceJSON));
        });
    return librarianPieces;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
