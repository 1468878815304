/**
 * Venue_Entity type guard.
 *
 * @param {any} venueJson Venue object from API
 * @returns {boolean} Return true if type is Venue_Entity
 */

export default class Venue_Entity {
  constructor(venueJson: Venue_Entity) {
    try {
      this.id = venueJson.id;
      this.name = venueJson.name;
      this.address = venueJson.address;
      this.city = venueJson.city;
      this.state = venueJson.state;
      this.zipcode = venueJson.zipcode;
      this.contact = venueJson.contact;
      this.phone = venueJson.phone;
      this.email = venueJson.email;
      this.latitude = venueJson.latitude;
      this.longitude = venueJson.longitude;
      this.tzName = venueJson.tzName;
      this.placeID = venueJson.placeID;
      this.liveVenue = venueJson.liveVenue;
      this.organizationID = venueJson.organizationID;
      this.deleted = venueJson.deleted;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id?: number;
  name?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  contact?: string;
  phone?: string;
  email?: string;
  latitude?: string;
  longitude?: string;
  tzName?: string;
  placeID?: string;
  liveVenue?: boolean;
  organizationID?: number;
  deleted?: boolean;
}
