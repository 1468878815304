import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Action_Entity } from "entities/action";
import { autopilotApi } from "../api/autopilotApi";

const actionsAdapter = createEntityAdapter<Action_Entity>();
const initialState = actionsAdapter.getInitialState();

export const actionEndpoints = autopilotApi.injectEndpoints({
  endpoints: (build) => ({
    getActions: build.query<EntityState<Action_Entity>, void>({
      query: () => `actions`,
      transformResponse: (responseData: Action_Entity[]) => {
        return actionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["actions"],
    }),
    getAction: build.query<Action_Entity, number>({
      query: (id) => `actions/${id}`,
      providesTags: (result, error, id) => [{ type: "actions", id }],
    }),
    createAction: build.mutation<Action_Entity, Partial<Action_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `actions`,
      }),
      invalidatesTags: ["actions"],
    }),
    updateAction: build.mutation<
      void,
      { id: number; body: Partial<Action_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `actions/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "actions", id },
        "actions",
      ],
    }),
    deleteAction: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `actions/${id}`,
      }),
      invalidatesTags: ["actions"],
    }),
  }),
});

export const {
  useGetActionQuery,
  useGetActionsQuery,
  useCreateActionMutation,
  useDeleteActionMutation,
  useUpdateActionMutation,
} = actionEndpoints;

export default actionEndpoints;
