// import PropTypes from 'prop-types';

// material-ui
import Typography from "@mui/material/Typography";

// styles
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Typography as JoyTypography,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Sheet,
  Table,
} from "@mui/joy";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogActions,
  Divider,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectedProjectIDSelector,
  setDescription420,
  setFormOpen,
} from "reducers/rhapsody";

import DialogClose from "atoms/DialogClose/DialogClose";
import { REACT_APP_API } from "config";
import { useExporter } from "features/exporter/exporter";
import { downloadBlob } from "hooks/Roster/Roster";
import {
  overture,
  useMySubscription,
} from "hooks/subscription/restrictionHooks";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useProject } from "redux/project/projectHooks";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";

type DownloadType =
  | "project-seating"
  | "active-musician"
  | "removed-musicians"
  | "roster"
  | "report-form";

export default function ProjectExport({ id, template = false }) {
  const projectID = id || useSelector(selectedProjectIDSelector);
  const { projectHiring } = useProjectHiring(projectID);
  const mySubscription = useMySubscription();
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>();
  const [open, setOpen] = useState(false);
  const [workSessionID, setWorkSessionID] = useState<number | null>();
  const [mode, setMode] = useState<"excel" | "pdf">();
  const [downloadType, setDownloadType] = useState<DownloadType>();
  const { project } = useProject(projectID);
  const pdfAvailableFor: DownloadType[] = [
    "project-seating",
    "roster",
    "report-form",
  ];
  const dispatch = useDispatch();
  const exporter = useExporter();

  useEffect(() => {
    if (workSessionID) {
      if (mode === "excel") handleExcelDownload(workSessionID);
      if (mode === "pdf") handlePDFDownload(workSessionID);
      setWorkSessionID(null);
      setOpen(false);
    }
  }, [workSessionID]);

  useEffect(() => {
    if (mode === "excel") handleExcelDownload();
    if (mode === "pdf") handlePDFDownload();
  }, [mode]);

  const download = (type: DownloadType) => (
    <Button
      endDecorator={<i className="fa-solid fa-arrow-down-to-line"></i>}
      onClick={(e) => {
        setAnchorEl(e.currentTarget);
        handleDownloadRequest(type);
      }}
      variant="outlined"
      size="sm"
      color="neutral"
    >
      Download...
    </Button>
  );

  function handleDownloadRequest(type: DownloadType) {
    setDownloadType(type);
    if (mySubscription === overture) {
      setAnchorEl(null);
      dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
      dispatch(
        setDescription420(
          "PDF & Excel Export features are exclusive to Mezzo & Finale."
        )
      );
    }
  }

  function handleExcelDownload(workSessionID?: number) {
    switch (downloadType) {
      case "project-seating":
        exporter.projectSeating();
        setWorkSessionID(null);
        setMode(null);
        break;
      case "active-musician":
        exporter.projectActiveMusicians();
        setWorkSessionID(null);
        setMode(null);
        break;
      case "removed-musicians":
        exporter.projectRemovedMusicians();
        setWorkSessionID(null);
        setMode(null);
        break;
      case "roster":
        if (!workSessionID) {
          setOpen(true);
        } else {
          exporter.workSessionRoster(workSessionID);
          setWorkSessionID(null);
          setMode(null);
        }
        break;
      case "report-form":
        if (!workSessionID) {
          setOpen(true);
        } else {
          exporter.workSessionContinuationForm(workSessionID);
          setWorkSessionID(null);
          setMode(null);
        }
        break;
      default:
        break;
    }
  }

  const downloadPDF = async (url, fileName, config) => {
    await fetch(url, {
      body: config,
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      method: "PUT",
    })
      .then((response) => response.blob())
      .then((d) => {
        downloadBlob(d, fileName);
      });
  };

  function handlePDFDownload(workSessionID?: number) {
    const workSession = projectHiring.sessions.find(
      (s) => s.id === workSessionID
    );
    switch (downloadType) {
      case "project-seating":
        window.open(
          `${REACT_APP_API}/rhapsody/projects/roster?token=${project.publicToken}`,
          "_blank"
        );
        break;
      case "roster":
        if (!workSessionID) {
          setOpen(true);
        } else {
          downloadPDF(
            `${REACT_APP_API}/rhapsody/workSessions/${workSessionID}/roster`,
            `${workSession.title}_${moment(workSession.dateFromUTC).format(
              "MM/DD/YYYY"
            )}.pdf`,
            JSON.stringify({
              font: "helvetica",
              orientation: "portrait",
              options: { fontSizeZoom: "0" },
              disposition: "attachment",
            })
          );
          setWorkSessionID(null);
          setMode(null);
        }
        break;
      case "report-form":
        if (!workSessionID) {
          setOpen(true);
        } else {
          downloadPDF(
            `${REACT_APP_API}/rhapsody/workSessions/${workSessionID}/reportForm`,
            `${workSession.title}_${moment(workSession.dateFromUTC).format(
              "MM/DD/YYYY"
            )}_Report_Form.pdf`,
            JSON.stringify({
              font: "helvetica",
              orientation: "portrait",
              options: {
                formTitle: "AMERICAN FEDERATION OF MUSICIANS REPORT FORM",
                formID: "13",
                formSubtitle: "Continuation Sheet",
                fontSizeZoom: "0",
              },
              disposition: "attachment",
            })
          );
          setWorkSessionID(null);
          setMode(null);
        }
        break;
      default:
        break;
    }
  }

  const handleClose = () => {
    setOpen(false);
    setWorkSessionID(null);
    setMode(null);
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none",
              border: "solid 1px rgba(155,155,155,0.3)",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography variant="h6" color="textPrimary">
                  Export
                </Typography>
                <Typography color="textSecondary">
                  Download project's data in PDF or Excel
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "40%" }}>Name</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Project Seating</td>
                      <td>
                        All your musicians and subs from your Project seating
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {download("project-seating")}
                      </td>
                    </tr>
                    <tr>
                      <td>Active Musicians</td>
                      <td>
                        All your musicians who accepted to work on this project
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {download("active-musician")}
                      </td>
                    </tr>
                    <tr>
                      <td>Removed Musicians</td>
                      <td>
                        All your musicians who have declined, have been released
                        or cancelled.
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {download("removed-musicians")}
                      </td>
                    </tr>
                    <tr>
                      <td>Roster for Work Session...</td>
                      <td>
                        Up-to-date Roster for a specific Work Session. Content
                        can change if the call is still running.
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {download("roster")}
                      </td>
                    </tr>
                    <tr>
                      <td>Report Form for Work Session...</td>
                      <td>
                        Union's continuation form for a specific Work Session.
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {download("report-form")}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {pdfAvailableFor.indexOf(downloadType) >= 0 ? (
          <MenuItem onClick={() => setMode("pdf")}>PDF</MenuItem>
        ) : (
          []
        )}
        <MenuItem onClick={() => setMode("excel")}>Excel</MenuItem>
      </Menu>
      <DialogClose open={open} onClose={handleClose}>
        <Sheet sx={{ p: 2, minWidth: 400 }} variant="soft" color="neutral">
          <JoyTypography level="h6">Which Work Session?</JoyTypography>
        </Sheet>
        <Divider></Divider>
        <List size="sm" sx={{ maxHeight: 600, overflow: "auto" }}>
          {projectHiring?.sessions.map((s) => (
            <ListItemButton onClick={() => setWorkSessionID(s.id)} key={s.id}>
              <Box>
                <JoyTypography level="body2" sx={{ color: "black" }}>
                  {s.title}
                </JoyTypography>
                <JoyTypography level="body3">
                  {s.formatDateRange()}
                </JoyTypography>
              </Box>
            </ListItemButton>
          ))}
        </List>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="soft"
            color="neutral"
            fullWidth
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogClose>
    </div>
  );
}
