import { Box } from "@mui/material";
import { DataGridPro, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import Id from "atoms/Id/Id";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useNavigate } from "react-router-dom";
import { usePayrolls } from "redux/payroll/payrollHooks";

export default function PayrollsDataGrid() {
  const { payrolls, isLoading } = usePayrolls();
  const readOnly = useReadOnly();

  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id startDecorator={<i className="fa-solid fa-building-columns"></i>}>
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1,
    },
  ];

  return (
    <DataGridPro
      autoHeight
      pagination
      pageSize={25}
      onRowClick={(p) => {
        if (!readOnly) navigate(`${RouterConfig.payrolls}/${p.row.id}/general`);
      }}
      loading={isLoading}
      rows={payrolls}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: Toolbar,
      }}
    />
  );
}

function Toolbar() {
  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        gap: 2,
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
