import { removableStages } from "hooks/Rosters/rosters";
import { useSelector } from "react-redux";
import {
  selectedProjectIDSelector,
  selectedWorkSessionIDSelector,
} from "reducers/rhapsody";
import { useProjectAlternates } from "redux/projectAlternate/projectAlternateHooks";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useWorkSession } from "redux/workSession/workSessionHooks";

export const actionIcons = {
  "chair-memo": "fa-solid fa-memo",
  "piece-memo": "fa-solid fa-memo",
  "musician-memo": "fa-solid fa-comment",
  instruments: "fa-solid fa-trumpet",
  role: "fa-solid fa-hat-cowboy",
  order: "fa-solid fa-sort",
  exclude: "fa-solid fa-hexagon-minus",
  section: "fa-solid fa-turn-down-right",
  "remove-musician": "fa-solid fa-user-minus",
  "delete-chair": "fa-solid fa-trash",
  admin: "fa-solid fa-lock",
  "add-chair": "fa-solid fa-chair",
};

export const actionLabel = {
  "chair-memo": "Chair Memo",
  "piece-memo": "Piece Memo",
  "musician-memo": "Musician Memo",
  instruments: "Instrument(s)",
  role: "Role",
  order: "Order",
  section: "Move",
  exclude: "Exclude",
  "remove-musician": "Remove",
  "delete-chair": "Delete",
  "add-chair": "Add",
  admin: "Admin Actions",
};

export type ChairType = "workSession" | "project" | "projectPiece" | "template";
export type ChairOption =
  | "chair-memo"
  | "piece-memo"
  | "musician-memo"
  | "instruments"
  | "role"
  | "order"
  | "section"
  | "remove-musician"
  | "delete-chair"
  | "add-chair";

export function useChairOptions(
  chairType: ChairType,
  stageID?: number
): ChairOption[] {
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const { workSession } = useWorkSession(workSessionID);
  const projectID =
    chairType === "workSession" ? workSession?.projectID : selectedProjectID;
  const { projectPieces } = useProjectPieces(projectID);
  const { projectAlternates } = useProjectAlternates(projectID);

  const hasProjectPieces = projectPieces?.length > 0;
  const hasProjectPersonnel = projectAlternates?.length > 0;
  let actions: ChairOption[] = [];

  switch (chairType) {
    case "template":
      actions = [
        // "musician-memo",
        "instruments",
        "role",
        "order",
        "section",
        "remove-musician",
        "delete-chair",
        "add-chair",
      ];
      break;
    case "projectPiece":
      actions = [
        "chair-memo",
        "instruments",
        "role",
        "order",
        "section",
        "delete-chair",
        "delete-chair",
        "add-chair",
      ];
      break;
    case "project":
      actions = [
        // "musician-memo",
        "remove-musician",
      ];
      if (!hasProjectPieces) {
        actions.push(
          "chair-memo",
          "instruments",
          "role",
          "order",
          "section",
          "delete-chair",
          "add-chair"
        );
      }
      break;
    case "workSession":
      if (hasProjectPersonnel) {
        actions = ["chair-memo", "role", "delete-chair"];
      } else {
        actions = [
          // "musician-memo",
          "chair-memo",
          "instruments",
          "role",
          "order",
          "section",
          "delete-chair",
          "add-chair",
        ];
      }
      if (removableStages[stageID]) {
        actions.push("remove-musician");
      }
      break;
    default:
      break;
  }

  return actions;
}
