import { Mission } from "entities/mission";
import { useMemo } from "react";
import { useGetMissionQuery, useGetMissionsQuery } from "./missionEndpoints";

export function useMission(missionID: number | undefined) {
  const missionQuery = useGetMissionQuery(missionID ?? 0, {
    skip: (missionID ?? 0) <= 0,
  });
  const missionEntity = missionQuery.data;

  return useMemo(() => {
    const ret: typeof missionQuery & { mission?: Mission | undefined } = {
      ...missionQuery,
    };
    if (missionEntity) ret.mission = new Mission(missionEntity);
    return ret;
  }, [missionEntity]);
}

export function useMissions(params, options?) {
  const missionQuery = useGetMissionsQuery(params, options);
  const missionEntitiesMap = missionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof missionQuery & {
      missions?: Mission[];
      missionsMap?: { [id: number]: Mission };
    } = {
      ...missionQuery,
      missions: [],
      missionsMap: {},
    };
    if (missionEntitiesMap) {
      const missions = [];
      const missionsMap = {};

      for (const key in missionEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(missionEntitiesMap, key)) {
          const c = missionEntitiesMap[key];
          const mission = new Mission(c);
          missions.push(mission);
          missionsMap[mission.id] = mission;
        }
      }
      ret.missions = missions;
      ret.missionsMap = missionsMap;
    }

    return ret;
  }, [missionEntitiesMap]);
}
