import { Autopilot } from "entities/autopilot";
import { useMemo } from "react";
import { useGetProjectAutopilotQuery } from "redux/project/projectEndpoints";

export function useProjectAutopilot(projectID: number | undefined) {
  const autopilotQuery = useGetProjectAutopilotQuery(projectID ?? 0, {
    skip: (projectID ?? 0) <= 0,
  });
  const autopilotEntity = autopilotQuery.data;

  return useMemo(() => {
    const ret: typeof autopilotQuery & { autopilot?: Autopilot | undefined } = {
      ...autopilotQuery,
    };
    if (autopilotEntity) ret.autopilot = new Autopilot(autopilotEntity);
    return ret;
  }, [autopilotEntity]);
}
