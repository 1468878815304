/**
 * WorkSession_Entity type guard.
 *
 * @param {any} workSessionJson WorkSession object from API
 * @returns {boolean} Return true if type is WorkSession_Entity
 */

import { Venue_Entity } from "entities/venue";
import { WorkSessionType_Entity } from "entities/workSessionType";

export default class WorkSession_Entity {
  constructor(workSessionJson: WorkSession_Entity) {
    try {
      this.id = workSessionJson.id;
      this.title = workSessionJson.title;
      this.projectVersion = workSessionJson.projectVersion;
      this.description = workSessionJson.description;
      this.publicToken = workSessionJson.publicToken;
      this.sessionTypeID = workSessionJson.sessionTypeID;
      this.productionTypeID = workSessionJson.productionTypeID;
      this.sessionMediumID = workSessionJson.sessionMediumID;
      this.sessionRateID = workSessionJson.sessionRateID;
      this.date = workSessionJson.date;
      this.dateFromUTC = workSessionJson.dateFromUTC;
      this.dateToUTC = workSessionJson.dateToUTC;
      this.noTimeRange = workSessionJson.noTimeRange;
      this.releaseDate = workSessionJson.releaseDate;
      this.projectID = workSessionJson.projectID;
      this.projectName = workSessionJson.projectName;
      this.projectColor = workSessionJson.projectColor;
      this.wageAgreement = workSessionJson.wageAgreement;
      this.organizationID = workSessionJson.organizationID;
      this.musicianCount = workSessionJson.musicianCount;
      this.studioName = workSessionJson.studioName;
      this.producer = workSessionJson.producer;
      this.producerAddress = workSessionJson.producerAddress;
      this.engineer = workSessionJson.engineer;
      this.conductor = workSessionJson.conductor;
      this.hoursOfEmployment = workSessionJson.hoursOfEmployment;
      this.totalSessionHours = workSessionJson.totalSessionHours;
      this.memo = workSessionJson.memo;
      this.payrollNumber = workSessionJson.payrollNumber;
      this.agreementOnFile = workSessionJson.agreementOnFile;
      this.rehearsalScale = workSessionJson.rehearsalScale;
      this.performanceScale = workSessionJson.performanceScale;
      this.pensionPercentage = workSessionJson.pensionPercentage;
      this.welfareValue = workSessionJson.welfareValue;
      this.welfarePercent = workSessionJson.welfarePercent;
      this.workDuesPercent = workSessionJson.workDuesPercent;
      this.archived = workSessionJson.archived;
      this.deleted = workSessionJson.deleted;
      this.recorded = workSessionJson.recorded;
      this.publicRoster = workSessionJson.publicRoster;
      this.employerOfRecord = workSessionJson.employerOfRecord;
      this.sessionTypeCode = workSessionJson.sessionTypeCode;
      this.emailGreetingMessage = workSessionJson.emailGreetingMessage;
      this.emailCommentsMessage = workSessionJson.emailCommentsMessage;
      this.tzName = workSessionJson.tzName;
      this.venueID = workSessionJson.venueID;
      this.whosPlayingMeta = workSessionJson.whosPlayingMeta;
      this.mercuryEnabled = workSessionJson.mercuryEnabled;
      this.concierge = workSessionJson.concierge;
      this.standalone = workSessionJson.standalone;
      this.musicianWages = workSessionJson.musicianWages;
      this.paidToMusician = workSessionJson.paidToMusician;
      this.sessionMusicianID = workSessionJson.sessionMusicianID;
      // this.mainEngagement = workSessionJson.mainEngagement;
      this.totalDoublingWages = workSessionJson.totalDoublingWages;
      this.totalPremiumWages = workSessionJson.totalPremiumWages;
      this.totalWorkDues = workSessionJson.totalWorkDues;
      this.totalWages = workSessionJson.totalWages;
      this.totalPensionContributions =
        workSessionJson.totalPensionContributions;
      this.totalHWContributions = workSessionJson.totalHWContributions;
      this.totalRehearsalWages = workSessionJson.totalRehearsalWages;
      this.totalPerformanceWages = workSessionJson.totalPerformanceWages;
      this.totalNonPensionable = workSessionJson.totalNonPensionable;
      this.totalOtherPensionable = workSessionJson.totalOtherPensionable;
      this.sessionType = workSessionJson.sessionType;
      // this.project = workSessionJson.project;
      // this.customer = workSessionJson.customer;
      this.studio = workSessionJson.studio;
      // this.categories = workSessionJson.categories;
      this.events = workSessionJson.events;
      this.statusID = workSessionJson.statusID;
      this.musicianToken = workSessionJson.musicianToken;
      this.hasAlternates = workSessionJson.hasAlternates;
      this.noVenue = workSessionJson.noVenue;
      this.city = workSessionJson.city;
      this.state = workSessionJson.state;
      this.type = workSessionJson.type;
      this.typeOptionID = workSessionJson.typeOptionID;
      this.layoutHash = workSessionJson.layoutHash;
      this.projectPieceIDs = workSessionJson.projectPieceIDs;
      this.sectionIDs = workSessionJson.sectionIDs;
      this.projectPiecesOrder = workSessionJson.projectPiecesOrder;
      // this.addons = workSessionJson.addons;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectVersion?: number;
  title?: string;
  description?: string;
  publicToken?: string;
  sessionTypeID?: number;
  productionTypeID?: number;
  sessionMediumID?: number;
  sessionRateID?: number;
  date?: string;
  dateFromUTC?: string;
  dateToUTC?: string;
  noTimeRange?: boolean;
  releaseDate?: string;
  projectID?: number;
  projectName?: string;
  projectColor?: string;
  wageAgreement?: string;
  organizationID?: number;
  musicianCount?: number;
  studioName?: string;
  producer?: string;
  producerAddress?: string;
  engineer?: string;
  conductor?: string;
  hoursOfEmployment?: string;
  totalSessionHours?: string;
  memo?: string;
  payrollNumber?: string;
  agreementOnFile?: boolean;
  rehearsalScale?: number;
  performanceScale?: number;
  pensionPercentage?: number;
  welfareValue?: number;
  welfarePercent?: boolean;
  workDuesPercent?: number;
  archived?: boolean;
  deleted?: boolean;
  recorded?: boolean;
  publicRoster?: boolean;
  employerOfRecord?: string;
  sessionTypeCode?: string;
  emailGreetingMessage?: string;
  emailCommentsMessage?: string;
  tzName?: string;
  venueID?: number;
  whosPlayingMeta?: string;
  mercuryEnabled?: boolean;
  concierge?: boolean;
  standalone?: boolean;
  musicianWages?: string;
  paidToMusician?: boolean;
  sessionMusicianID?: number;
  // mainEngagement: <NotFound>;
  totalDoublingWages?: number;
  totalPremiumWages?: number;
  totalWorkDues?: number;
  totalWages?: number;
  totalPensionContributions?: number;
  totalHWContributions?: number;
  totalRehearsalWages?: number;
  totalPerformanceWages?: number;
  totalNonPensionable?: number;
  totalOtherPensionable?: number;
  sessionType: WorkSessionType_Entity;
  // project: <NotFound>;
  // customer: <NotFound>;
  studio?: Venue_Entity;
  // categories: Array<SessionCategory>;
  events: Array<Event>;
  statusID?: number;
  musicianToken?: string;
  hasAlternates: boolean;
  noVenue?: boolean;
  city?: string;
  state?: string;
  type?: string;
  typeOptionID?: number;
  layoutHash?: string;
  // addons: Array<Addon>;
  projectPieceIDs?: number[];
  sectionIDs?: string;
  projectPiecesOrder?: string;
}
