import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Company_Entity } from "entities/company";
import { rhapsodyApi } from "../api/rhapsodyApi";

const companiesAdapter = createEntityAdapter<Company_Entity>();
const initialState = companiesAdapter.getInitialState();

export const companyEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentCompany: build.query<Company_Entity, void>({
      query: () => `companies/current`,
      providesTags: ["companies"],
    }),

    getCompanies: build.query<EntityState<Company_Entity>, void>({
      query: () => `companies`,
      transformResponse: (responseData: Company_Entity[]) => {
        return companiesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["companies"],
    }),
    getCompany: build.query<Company_Entity, number>({
      query: (id) => `companies/${id}`,
      providesTags: (result, error, id) => [{ type: "companies", id }],
    }),
    createCompany: build.mutation<Company_Entity, Partial<Company_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `companies`,
      }),
      invalidatesTags: ["companies"],
    }),
    updateCompany: build.mutation<void, { id: number; body: any }>({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `companies/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "companies", id },
        "companies",
      ],
    }),
    updateCurrentCompany: build.mutation<void, Partial<Company_Entity>>({
      query: (body) => ({
        method: "PUT",
        body: body,
        url: `companies/current`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "companies", id },
        "companies",
        "subscriptions",
      ],
    }),
    deleteCompany: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `companies/${id}`,
      }),
      invalidatesTags: ["companies"],
    }),
  }),
});

export const {
  useGetCurrentCompanyQuery,
  useGetCompanyQuery,
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
  useUpdateCurrentCompanyMutation,
} = companyEndpoints;

export default companyEndpoints;
