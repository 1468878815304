import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { MercuryJob_Entity } from "entities/mercuryJob";
import { rhapsodyApi } from "../api/rhapsodyApi";

const mercuryJobsAdapter = createEntityAdapter<MercuryJob_Entity>();
const initialState = mercuryJobsAdapter.getInitialState();

export const mercuryJobEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getMercuryJobs: build.query<EntityState<MercuryJob_Entity>, void>({
      query: () => `mercuryJobs`,
      transformResponse: (responseData: MercuryJob_Entity[]) => {
        return mercuryJobsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["mercuryJobs"],
    }),
    getMusicianMercuryJobs: build.query<EntityState<MercuryJob_Entity>, number>(
      {
        query: (musicianID) => `musicians/${musicianID}/mercury/jobs`,
        transformResponse: (responseData: MercuryJob_Entity[]) => {
          return mercuryJobsAdapter.setAll(initialState, responseData);
        },
        providesTags: ["mercuryJobs"],
      }
    ),
    getMercuryJob: build.query<MercuryJob_Entity, string>({
      query: (id) => `missions/mercuryJob/${id}`,
      providesTags: (result, error, id) => [{ type: "mercuryJobs", id }],
    }),
    getWorkSessionMercuryJobs: build.query<
      EntityState<MercuryJob_Entity>,
      number
    >({
      query: (id) => `workSessions/${id}/mercuryJobs`,
      transformResponse: (responseData: MercuryJob_Entity[]) => {
        return mercuryJobsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["jobs"],
    }),
    createMercuryJob: build.mutation<
      MercuryJob_Entity,
      Partial<MercuryJob_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `mercuryJobs`,
      }),
      invalidatesTags: ["mercuryJobs"],
    }),
    updateMercuryJob: build.mutation<
      void,
      { id: number; body: Partial<MercuryJob_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `mercuryJobs/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "mercuryJobs", id },
        "mercuryJobs",
      ],
    }),
    deleteMercuryJob: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `mercuryJobs/${id}`,
      }),
      invalidatesTags: ["mercuryJobs"],
    }),
  }),
});

export const {
  useGetMusicianMercuryJobsQuery,
  useGetMercuryJobQuery,
  useGetWorkSessionMercuryJobsQuery,
  useGetMercuryJobsQuery,
  useCreateMercuryJobMutation,
  useDeleteMercuryJobMutation,
  useUpdateMercuryJobMutation,
} = mercuryJobEndpoints;

export default mercuryJobEndpoints;

export const selectMercuryJobsResult =
  mercuryJobEndpoints.endpoints.getMercuryJobs.select();

const selectMercuryJobsData = createSelector(
  selectMercuryJobsResult,
  (mercuryJobsResult) => mercuryJobsResult.data
);

const store = configureStore({
  reducer: {
    [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const {
  selectAll: selectAllMercuryJobs,
  selectById: selectMercuryJobById,
} = mercuryJobsAdapter.getSelectors<RootState>(
  (state) => selectMercuryJobsData(state) ?? initialState
);
