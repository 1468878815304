import { ProjectAlternate } from "entities/projectAlternate";
import { useMemo } from "react";
import {
  useGetProjectAlternateQuery,
  useGetProjectAlternatesQuery,
} from "./projectAlternateEndpoints";

export function useProjectAlternate(projectAlternateID: number | undefined) {
  const projectAlternateQuery = useGetProjectAlternateQuery(
    projectAlternateID ?? 0,
    {
      skip: (projectAlternateID ?? 0) <= 0,
    }
  );
  const projectAlternateEntity = projectAlternateQuery.data;

  return useMemo(() => {
    const ret: typeof projectAlternateQuery & {
      projectAlternate?: ProjectAlternate | undefined;
    } = {
      ...projectAlternateQuery,
    };
    if (projectAlternateEntity)
      ret.projectAlternate = new ProjectAlternate(projectAlternateEntity);
    return ret;
  }, [projectAlternateEntity]);
}

export function useProjectAlternates(projectID: number | undefined) {
  const projectAlternateQuery = useGetProjectAlternatesQuery(projectID, {
    skip: (projectID ?? 0) <= 0,
  });
  const projectAlternateEntitiesMap = projectAlternateQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectAlternateQuery & {
      projectAlternates?: ProjectAlternate[];
      projectAlternatesMap?: { [id: number]: ProjectAlternate };
    } = {
      ...projectAlternateQuery,
      projectAlternates: [],
      projectAlternatesMap: {},
    };
    if (projectAlternateEntitiesMap) {
      const projectAlternates = [];
      const projectAlternatesMap = {};

      for (const key in projectAlternateEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(projectAlternateEntitiesMap, key)
        ) {
          const c = projectAlternateEntitiesMap[key];
          const projectAlternate = new ProjectAlternate(c);
          projectAlternates.push(projectAlternate);
          projectAlternatesMap[projectAlternate.id] = projectAlternate;
        }
      }
      ret.projectAlternates = projectAlternates;
      ret.projectAlternatesMap = projectAlternatesMap;
    }

    return ret;
  }, [projectAlternateEntitiesMap]);
}
