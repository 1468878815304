import { Internal_Entity } from "entities/internal";
import { InternalPosition } from "entities/internal/InternalPosition";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import Selecto_Entity from "./selecto";
import { RhapsodyChair } from "entities/rhapsodyChair";
import { Dictionary } from "@reduxjs/toolkit";
import { Assignment } from "entities/assignment";
import { Musician } from "entities/musician";
import { mapToArray } from "helpers";
import { Selection_Entity } from "entities/selection";
export default class Selecto extends Selecto_Entity {
  positions?: InternalPosition[];
  emptyPositions?: InternalPosition[];
  positionsWithEmptyChairs?: InternalPosition[];
  visibleChairIDs: number[];
  chairsMap: Dictionary<RhapsodyChair>;
  chairs: RhapsodyChair[];
  chairIDs: number[];
  visibleChairs: RhapsodyChair[];
  emptyChairs: RhapsodyChair[];
  assignedChairs: RhapsodyChair[];
  visibleAssignments: Assignment[];
  visibleAssignmentIDs: number[];
  terminusAssignmentIDs: number[];
  visibleMusicians: Musician[];
  visibleMusicianIDs: number[];
  removableMusicians: Musician[];
  releasableMusicians: Musician[];
  resetMercuryJobIDs: number[];
  callSent: boolean;
  chairMemos?: string; // if all selected chairs share the same memo, its value is available here
  assignmentMemos?: string; // if all selected musicians share the same memo, its value is available here

  constructor(
    json: Selecto_Entity,
    internal: Internal_Entity,
    utils: LayoutUtils,
    selection: Selection_Entity
  ) {
    super(json);
    const internalPositions = [];
    const assignments = mapToArray(utils.assignmentsMap);
    internal.families.forEach((f) => {
      f.sections.forEach((s) => {
        internalPositions.push(...s.positions);
      });
    });
    this.callSent = assignments.reduce((a, v) => {
      if (v.mercuryStageID) a = true;
      return a;
    }, false);
    const internalPositionsMap = internalPositions.reduce((a, v) => {
      a[v.id] = v;
      return a;
    }, {});
    this.positions = this.positionIDs.reduce((a, v) => {
      const s = v.split("|");
      const forWorkSessionID = parseInt(s[3]);
      const forProjectPieceID = parseInt(s[4]);
      const internalPosition = {
        ...internalPositionsMap[`${s[0]}|${s[1]}|${s[2]}|0|0`],
      };
      if (forWorkSessionID) {
        internalPosition.forWorkSessionID = forWorkSessionID;
      }
      if (forProjectPieceID) {
        internalPosition.forProjectPieceID = forProjectPieceID;
      }
      a.push(new InternalPosition(internalPosition, utils, selection));
      return a;
    }, []);

    this.positionsWithEmptyChairs = this.positions.filter((p) => {
      let hasEmptyChairs = false;
      p.chairIDs.forEach((c) => {
        const _chair = utils.chairsMap[c];
        if (!_chair.assignmentID) hasEmptyChairs = true;
      });

      return hasEmptyChairs;
    });

    this.visibleChairIDs = this.positions.reduce((a, v) => {
      v.visibleChairIDs.forEach((id) => {
        if (!a.includes(id)) a.push(id);
      });
      return a;
    }, []);

    this.chairsMap = this.positions.reduce((a, v) => {
      v.chairIDs.forEach((c) => {
        const _chair = utils.chairsMap[c];
        a[c] = _chair;
      });
      return a;
    }, {});

    this.chairs = [];
    this.visibleChairs = [];
    this.emptyChairs = [];
    this.assignedChairs = [];
    this.chairIDs = [];
    for (const k in this.chairsMap) {
      if (Object.prototype.hasOwnProperty.call(this.chairsMap, k)) {
        const chair = this.chairsMap[k];
        this.chairs.push(chair);
        this.chairIDs.push(chair.id);
        if (this.visibleChairIDs.includes(chair.id))
          this.visibleChairs.push(chair);
        if (!chair.assignmentID) this.emptyChairs.push(chair);
        if (chair.assignmentID) this.assignedChairs.push(chair);
      }
    }
    const allChairMemos = this.visibleChairs.reduce((a, v) => {
      a.push(v.memo);
      return a;
    }, []);
    this.chairMemos =
      allChairMemos.length &&
      allChairMemos.every((val, i, arr) => val === arr[0])
        ? allChairMemos[0]
        : "";
    this.visibleAssignments = this.visibleChairs.reduce((a, v) => {
      const assignment = utils.assignmentsMap[v.assignmentID];
      const index = a?.findIndex((i) => i.id === v.assignmentID);
      if (assignment && index === -1) a.push(assignment);
      return a;
    }, []);

    const allAssignmentMemos = this.visibleAssignments.reduce((a, v) => {
      a.push(v.memo);
      return a;
    }, []);
    this.assignmentMemos =
      allAssignmentMemos.length &&
      allAssignmentMemos.every((val, i, arr) => val === arr[0])
        ? allAssignmentMemos[0]
        : "";
    this.visibleAssignmentIDs = this.visibleAssignments.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []);

    this.visibleMusicians = this.visibleAssignments.reduce((a, v) => {
      const musician = utils.musiciansMap[v.musicianID];
      const index = a?.findIndex((i) => i.id === v.musicianID);
      if (musician && index === -1) a.push(musician);
      return a;
    }, []);

    this.releasableMusicians = this.visibleAssignments.reduce((a, v) => {
      const musician = utils.musiciansMap[v.musicianID];
      const index = a?.findIndex((i) => i.id === v.musicianID);
      if (musician && index === -1 && v.mercuryStageID) a.push(musician);
      return a;
    }, []);

    this.removableMusicians = this.visibleMusicians.reduce((a, v) => {
      const assignment = this.visibleAssignments.find(
        (a) => a.musicianID === v.id
      );
      const terminusStage =
        utils.stagesMap[assignment?.mercuryStageID]?.terminus;
      if (!assignment.mercuryStageID || terminusStage) a.push(v);
      return a;
    }, []);

    this.visibleMusicianIDs = this.visibleMusicians.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []);

    this.terminusAssignmentIDs = this.visibleAssignments.reduce((a, v) => {
      const stage = utils.stagesMap[v.mercuryStageID];
      if (stage?.terminus) a.push(v.id);
      return a;
    }, []);

    this.resetMercuryJobIDs = this.visibleAssignments.reduce((a, v) => {
      const stage = utils.stagesMap[v.mercuryStageID];
      if (stage?.terminus || stage?.primary) {
        a.push(v.mercuryJobID);
      }
      return a;
    }, []);

    this.emptyPositions = this.positions.reduce((a, v) => {
      let empty = true;
      v.visibleChairIDs.forEach((c) => {
        const _chair = utils.chairsMap[c];
        if (_chair?.assignmentID || _chair?.hidden) {
          empty = false;
        } // we can't delete workSession chairs after the call is sent
      });
      if (empty) a.push(v);
      return a;
    }, []);
  }

  checkboxStatus(ids: string[]) {
    return ids.reduce(
      (a, v) => {
        const index = this.positionIDs?.findIndex((p) => p === v);
        if (index === -1) a.checked = false;
        if (index >= 0) a.intermediate = true;
        return a;
      },
      {
        checked: ids?.length === 0 ? false : true,
        intermediate: false,
      }
    );
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
