import { Box, Button, Sheet, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { ProjectPiece, ProjectPiece_Entity } from "entities/projectPiece";
import { PieceInfo } from "features/ProjectPieces/PieceInfo";
import { ReactElement, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useUpdateBatchProjectPiecesMutation } from "redux/projectPiece/projectPieceEndpoints";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

const ITEM_HEIGHT = 50;

/**
 *
 * @returns {ReactElement} FormProjectPieceReorder page
 */
export function FormProjectPieceReorder() {
  const open = useSelector(formOpenSelector("projectPieceReorder"));
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectPieces } = useProjectPieces(projectID);
  const dispatch = useDispatch();
  const [_projectPieces, setProjectPieces] = useState<ProjectPiece[]>([]);
  const [updateProjectPieces] = useUpdateBatchProjectPiecesMutation();

  useEffect(() => {
    if (projectPieces) {
      setProjectPieces(projectPieces);
    }
  }, [projectPieces]);

  const onClose = () => {
    dispatch(setFormOpen({ formID: "projectPieceReorder", isOpen: false }));
  };

  function onDragEnd(result) {
    const newItems = [..._projectPieces];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setProjectPieces(newItems);
  }

  function save() {
    onClose();
    const body: ProjectPiece_Entity[] = [];
    _projectPieces.forEach((p, i) => {
      body.push({ ...p, position: i + 1 });
    });
    updateProjectPieces(body);
  }

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle>
        <Sheet>Reorder Project Pieces</Sheet>
      </DialogTitle>
      <DialogContent
        sx={{ position: "relative", maxWidth: 450, width: "100vw" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {projectPieces.map((p) => (
            <Box
              sx={{
                height: ITEM_HEIGHT,
                display: "flex",
                alignItems: "center",
              }}
              key={p.id}
            >
              <Typography level="body2">{p.position}.</Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            maxWidth: "calc(100% - 48px)",
            display: "flex",
            gap: 1,
            flexDirection: "column",
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"Box"}>
              {(provided) => {
                return (
                  <Box
                    ref={provided.innerRef}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {_projectPieces.map((projectPiece, i) => {
                      return (
                        <Draggable
                          key={projectPiece.id}
                          draggableId={`${projectPiece.id}`}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Sheet
                                variant="soft"
                                sx={{
                                  height: ITEM_HEIGHT,
                                  ml: 4,
                                  mb: 0.5,
                                  mt: 0.5,
                                  display: "flex",
                                  gap: 0.5,
                                  borderRadius: "8px",
                                  p: 1,
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <PieceInfo projectPieceID={projectPiece.id} />
                                <i
                                  style={{ opacity: 0.5, marginRight: 8 }}
                                  className="fa-solid fa-grip-dots-vertical"
                                ></i>
                              </Sheet>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="soft" color="neutral">
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}
