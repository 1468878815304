/**
 * Group_Entity type guard.
 *
 * @param {any} groupJson Group object from API
 * @returns {boolean} Return true if type is Group_Entity
 */

import { Musician_Entity } from "entities/musician";

export default class Group_Entity {
  constructor(groupJson: Group_Entity) {
    try {
      this.id = groupJson.id;
      this.name = groupJson.name;
      this.image = groupJson.image;
      this.organizationID = groupJson.organizationID;
      this.musicianRelationID = groupJson.musicianRelationID;
      this.musicians = groupJson.musicians;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }
  id: number;
  name: string;
  image: string;
  organizationID: number;
  musicianRelationID?: number;
  musicians: Musician_Entity[];
}
