import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Mission_Entity } from "entities/mission";
import { rhapsodyApi } from "../api/rhapsodyApi";

const missionsAdapter = createEntityAdapter<Mission_Entity>();
const initialState = missionsAdapter.getInitialState();

export const missionEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getMissions: build.query<
      EntityState<Mission_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `missions`,
          params: args,
        };
      },
      transformResponse: (responseData: Mission_Entity[]) => {
        return missionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["missions"],
    }),
    getMission: build.query<Mission_Entity, number>({
      query: (id) => `missions/${id}`,
      providesTags: (result, error, id) => [{ type: "missions", id }],
    }),
    createMission: build.mutation<Mission_Entity, Partial<Mission_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `missions`,
      }),
      invalidatesTags: ["missions", "seasonMercury"],
    }),
    resetMercuryJob: build.mutation<void, { id: number }>({
      query: (args) => ({
        method: "PUT",
        url: `missions/mercuryJob/${args.id}/setReady`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "mercuryJobs", id },
        "mercuryJobs",
        "assignments",
      ],
    }),
    updateMission: build.mutation<
      void,
      { id: number; body: Partial<Mission_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `missions/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "missions", id },
        "missions",
      ],
    }),
    missionReplaceMusician: build.mutation<
      void,
      { id: number; body: { musicianID: number } }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `missions/${args.id}/replaceMusician`,
      }),
      invalidatesTags: [
        "missions",
        "seasonMusicians",
        "seasonMercury",
        "assignments",
      ],
    }),
    deleteMission: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `missions/${id}`,
      }),
      invalidatesTags: ["missions"],
    }),
  }),
});

export const {
  useMissionReplaceMusicianMutation,
  useResetMercuryJobMutation,
  useGetMissionQuery,
  useGetMissionsQuery,
  useCreateMissionMutation,
  useDeleteMissionMutation,
  useUpdateMissionMutation,
} = missionEndpoints;

export default missionEndpoints;
