import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const librarianApi = createApi({
  reducerPath: "librarianApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "librarian" }),
  endpoints: () => ({}),
  tagTypes: ["pieces", "danielsFragments", "danielsWorks"],
});
