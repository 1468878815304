import { Alert, Button, Chip, Sheet } from "@mui/joy";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { DateTimePicker } from "hooks/dateTimePicker/dateTimePicker";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { layoutInternalSelector } from "reducers/v2/missionControl";
import {
  rescheduleEventSelector,
  setRescheduleEvent,
} from "reducers/workSessions";
import { useUpdateWorkSessionMutation } from "redux/workSession/workSessionEndpoints";
import { useWorkSession } from "redux/workSession/workSessionHooks";

export default function RescheduleWorkSession() {
  const rescheduleWorkSession = useSelector(rescheduleEventSelector);
  const workSessionID = parseInt(rescheduleWorkSession.event.id);
  const { workSession } = useWorkSession(workSessionID);
  const [updateWorkSession] = useUpdateWorkSessionMutation();
  const internal = useSelector(layoutInternalSelector);
  const callSent = internal?.callSent;
  const [dateFromUTC, setDateFromUTC] = useState(
    moment(rescheduleWorkSession.event.start).utc().format()
  );
  const [dateToUTC, setDateToUTC] = useState(
    moment(rescheduleWorkSession.event.end).utc().format()
  );

  const dispatch = useDispatch();

  const revert = () => {
    rescheduleWorkSession.event.setStart(workSession.dateFromUTC);
    rescheduleWorkSession.event.setEnd(workSession.dateToUTC);
    closeForm();
  };

  const closeForm = () => {
    dispatch(setRescheduleEvent(null));
  };

  const reschedule = () => {
    updateWorkSession({
      id: workSessionID,
      body: {
        mainEngagement: {
          dateFromUTC,
          dateToUTC,
        },
      },
    });
    closeForm();
  };

  if (!workSession) return <Box />;
  return (
    <DialogClose open onClose={revert}>
      <DialogTitle>
        Reschedule {workSession.title}
        <br />
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Tooltip title="Project">
            <Chip
              color="neutral"
              variant="soft"
              startDecorator={<i className="fa-duotone fa-folder-open"></i>}
            >
              {workSession.projectName}
            </Chip>
          </Tooltip>
          <Tooltip title="venue">
            <Chip
              color="neutral"
              variant="soft"
              startDecorator={<i className="fa-duotone fa-location-dot"></i>}
            >
              {workSession.studioName}
            </Chip>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Sheet
          variant="soft"
          color="danger"
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "16px",
            alignItems: "center",
          }}
        >
          <Typography>
            <b>Old Date:</b>
          </Typography>
          <Typography variant="body2">
            {workSession?.formatDateRange()}
          </Typography>
        </Sheet>
        <Sheet
          color="success"
          variant="soft"
          sx={{
            p: 1,
            display: "flex",
            gap: 2,
            flexDirection: "column",
            borderRadius: "16px",
          }}
        >
          <Typography>
            <b>New Date:</b>
          </Typography>
          <DateTimePicker
            dateFrom={dateFromUTC}
            dateTo={dateToUTC}
            tzName={workSession?.tzName}
            onChange={(startDate, endDate) => {
              setDateFromUTC(startDate);
              setDateToUTC(endDate);
            }}
          />
        </Sheet>
        {callSent ? (
          <Alert color="danger" variant="solid">
            IMPORTANT: This action won't notify your musicians. You have to
            manually contact your musicians to let them know the change.
          </Alert>
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={revert} variant="soft" color="neutral">
          Cancel
        </Button>
        <Button onClick={reschedule}>Reschedule</Button>
      </DialogActions>
    </DialogClose>
  );
}
