import { Avatar } from "@mui/material";
// import PropTypes from 'prop-types';
// material-ui
import Typography from "@mui/material/Typography";
import React from "react";

export default function ListButton({
  title,
  subtitle,
  icon,
  onClick,
  context,
}) {
  const [hover, setHover] = React.useState(false);

  const mouseEnter = () => {
    setHover(true);
  };

  const mouseLeave = () => {
    setHover(false);
  };
  let background = hover ? "rgba(155,155,155,0.1)" : "rgba(155,155,155,0)";

  if (context) {
    background = hover ? "#ffb300" : "#ffc107";
  }
  return (
    <div
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={onClick}
      style={{
        display: "flex",
        cursor: "pointer",
        padding: 6,
        background: background,
        transition: "background .2s",
        borderRadius: "16px",
        alignItems: "center",
      }}
    >
      <Avatar size="small" style={{ background: "#E4E6EB" }}>
        {icon}
      </Avatar>
      <div style={{ flexGrow: 1, marginLeft: 8 }}>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="caption" color="textSecondary">
          {subtitle}
        </Typography>
      </div>
    </div>
  );
}
