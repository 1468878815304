import { Scale } from "entities/scale";
import { useMemo } from "react";
import {
  useGetProjectScalesQuery,
  useGetScaleQuery,
  useGetScalesQuery,
  useGetTagScalesQuery,
} from "./scaleEndpoints";

export function useScale(scaleID: number | undefined) {
  const scaleQuery = useGetScaleQuery(scaleID ?? 0, {
    skip: (scaleID ?? 0) <= 0,
  });
  const scaleEntity = scaleQuery.data;

  return useMemo(() => {
    const ret: typeof scaleQuery & { scale?: Scale | undefined } = {
      ...scaleQuery,
    };
    if (scaleEntity) ret.scale = new Scale(scaleEntity);
    return ret;
  }, [scaleEntity]);
}

export function useScales() {
  const scaleQuery = useGetScalesQuery();
  const scaleEntitiesMap = scaleQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof scaleQuery & {
      scales?: Scale[];
      scalesMap?: { [id: number]: Scale };
    } = {
      ...scaleQuery,
      scales: [],
      scalesMap: {},
    };
    if (scaleEntitiesMap) {
      const scales = [];
      const scalesMap = {};

      for (const key in scaleEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(scaleEntitiesMap, key)) {
          const c = scaleEntitiesMap[key];
          const scale = new Scale(c);
          scales.push(scale);
          scalesMap[scale.id] = scale;
        }
      }
      ret.scales = scales;
      ret.scalesMap = scalesMap;
    }

    return ret;
  }, [scaleEntitiesMap]);
}

export function useTagScales(tagID?: number) {
  const scaleQuery = useGetTagScalesQuery(tagID, { skip: !tagID });
  const scaleEntitiesMap = scaleQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof scaleQuery & {
      scales?: Scale[];
      scalesMap?: { [id: number]: Scale };
    } = {
      ...scaleQuery,
      scales: [],
      scalesMap: {},
    };
    if (scaleEntitiesMap) {
      const scales = [];
      const scalesMap = {};

      for (const key in scaleEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(scaleEntitiesMap, key)) {
          const c = scaleEntitiesMap[key];
          const scale = new Scale(c);
          scales.push(scale);
          scalesMap[scale.id] = scale;
        }
      }
      ret.scales = scales;
      ret.scalesMap = scalesMap;
    }

    return ret;
  }, [scaleEntitiesMap]);
}

export function useProjectScales(projectID?: number) {
  const scaleQuery = useGetProjectScalesQuery(projectID, { skip: !projectID });
  const scaleEntitiesMap = scaleQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof scaleQuery & {
      scales?: Scale[];
      scalesMap?: { [id: number]: Scale };
    } = {
      ...scaleQuery,
      scales: [],
      scalesMap: {},
    };
    if (scaleEntitiesMap) {
      const scales = [];
      const scalesMap = {};

      for (const key in scaleEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(scaleEntitiesMap, key)) {
          const c = scaleEntitiesMap[key];
          const scale = new Scale(c);
          scales.push(scale);
          scalesMap[scale.id] = scale;
        }
      }
      ret.scales = scales;
      ret.scalesMap = scalesMap;
    }

    return ret;
  }, [scaleEntitiesMap]);
}
