import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Link,
  Typography,
} from "@mui/joy";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  alpha,
} from "@mui/material";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import DialogClose from "atoms/DialogClose/DialogClose";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  formOpenSelector,
  musiciansForChairSelector,
  musiciansForWorkSessionSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useAssignmentsFilter } from "redux/assignment/assignmentHooks";
import { useMusicians } from "redux/musician/musicianHooks";
import { useChairsFilter } from "redux/rhapsodyChair/rhapsodyChairHooks";
import { useSections } from "redux/section/sectionHooks";
import { useStages } from "redux/stage/stageHooks";
import { useWorkSession } from "redux/workSession/workSessionHooks";

/**
 *
 * @returns {ReactElement} MusiciansForChairs page
 */
export function MusiciansForChairs() {
  const dispatch = useDispatch();
  const open = useSelector(formOpenSelector("showMusiciansForChairs"));
  const musiciansForChairIDs = useSelector(musiciansForChairSelector);
  const { sectionsMap } = useSections();
  const { stagesMap } = useStages();
  const api = useGridApiRef();
  const workSessionID = useSelector(musiciansForWorkSessionSelector);
  const { chairs, isLoading: chairsLoading } = useChairsFilter({
    filters: JSON.stringify([
      {
        name: "chairs.id",
        comparison: "in",
        value: musiciansForChairIDs,
      },
    ]),
  });

  const { workSession, isLoading: workSessionLoading } =
    useWorkSession(workSessionID);

  const assignmentIDs = chairs?.reduce((a, v) => {
    if (!a.includes(v.assignmentID)) a.push(v.assignmentID);
    return a;
  }, []);

  const { assignments, isLoading: assignmentLoading } = useAssignmentsFilter(
    {
      filters: JSON.stringify([
        {
          name: "assignments.id",
          comparison: "in",
          value: assignmentIDs,
        },
        {
          name: "assignments.workSessionID",
          comparison: "eq",
          value: workSessionID,
        },
      ]),
    },
    { skip: assignmentIDs.length === 0 || !workSessionID }
  );

  const musicianIDs = assignments?.reduce((a, v) => {
    if (!a.includes(v.musicianID)) a.push(v.musicianID);
    return a;
  }, []);

  const { musicians, isLoading: musiciansLoading } = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.id",
          comparison: "in",
          value: musicianIDs,
        },
      ]),
    },
    { skip: musicianIDs.length === 0 }
  );

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "showMusiciansForChairs" }));
  };

  const rows = musicians?.reduce((a, v) => {
    const assignment = assignments.find((a) => a.musicianID === v.id);
    const _chairs = chairs.filter((c) => c.assignmentID === assignment?.id);
    a.push({ ...v, ...assignment, chairs: _chairs, musician: v });

    return a;
  }, []);

  const loading =
    musiciansLoading ||
    assignmentLoading ||
    workSessionLoading ||
    chairsLoading;

  return (
    <DialogClose fullwidth maxWidth="lg" onClose={onClose} open={open}>
      {!loading ? (
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box>
            <Typography level="h6">
              Musicians for {workSession?.title}
            </Typography>
            <Typography level="body2">
              <Link
                startDecorator={<i className="fa-solid fa-folder-open"></i>}
                level="body2"
                color="neutral"
                component={RouterLink}
                to={`${RouterConfig.projects}/${workSession?.projectID}`}
              >
                {workSession?.projectName}
              </Link>
              , {workSession?.formatDateRange()}, @{workSession?.studioName},{" "}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              startDecorator={<i className="fa-solid fa-file-excel"></i>}
              variant="soft"
              color="neutral"
              onClick={() => api.current.exportDataAsExcel()}
            >
              Excel Export
            </Button>
            <Button
              startDecorator={<i className="fa-solid fa-print"></i>}
              variant="soft"
              color="neutral"
              onClick={() => api.current.exportDataAsPrint()}
            >
              Print
            </Button>
          </Box>
        </DialogTitle>
      ) : (
        []
      )}
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <DataGridPremium
            apiRef={api}
            experimentalFeatures={{ clipboardPaste: true }}
            unstable_cellSelection
            density="compact"
            initialState={{
              pinnedColumns: { right: ["mercuryStageID"] },
            }}
            rows={rows}
            columns={[
              {
                field: "avatar",
                headerName: "",
                width: 28,
                renderCell: (p) => (
                  <MusicianAvatar musician={p.row.musician} size={28} />
                ),
              },
              { field: "firstName", headerName: "First Name" },
              { field: "lastName", headerName: "Last Name" },
              {
                field: "chairs",
                headerName: "Section",
                valueGetter: (v) => v.value[0]?.sectionID,
                valueFormatter: (v) => sectionsMap[v.value]?.name,
              },
              { field: "email", headerName: "Email", width: 200 },
              { field: "phone", headerName: "Phone", width: 200 },
              { field: "memo", headerName: "Assignment Memo", width: 200 },
              {
                field: "mercuryStageID",
                headerName: "Status",
                renderCell: (e) =>
                  e.value ? (
                    <Chip
                      startDecorator={
                        stagesMap[e.value]?.icon ? (
                          <img
                            src={stagesMap[e.value]?.icon}
                            style={{ height: 14 }}
                          />
                        ) : undefined
                      }
                      sx={{
                        color: stagesMap[e.value]?.color,
                        background: alpha(
                          stagesMap[e.value]?.color ?? "#000000",
                          0.1
                        ),
                      }}
                      size="sm"
                    >
                      {stagesMap[e.value]?.wording()}
                    </Chip>
                  ) : (
                    <Box />
                  ),
              },
            ]}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
