/**
 * SubRule_Entity type guard.
 *
 * @param {any} subRuleJson SubRule object from API
 * @returns {boolean} Return true if type is SubRule_Entity
 */

export default class SubRule_Entity {
  constructor(subRuleJson: SubRule_Entity) {
    try {
      this.id = subRuleJson.id;
      this.projectID = subRuleJson.projectID;
      this.targetIDs = subRuleJson.targetIDs;
      this.sectionIDs = subRuleJson.sectionIDs;
      this.sectionRoleIDs = subRuleJson.sectionRoleIDs;
      this.orders = subRuleJson.orders;
      this.projectPieceIDs = subRuleJson.projectPieceIDs;
      this.replaceIDs = subRuleJson.replaceIDs;
      this.templateID = subRuleJson.templateID;
      this.createdAt = subRuleJson.createdAt;
      this.updatedAt = subRuleJson.updatedAt;
      this.position = subRuleJson.position;
    } catch (e) {
      console.log(`Failed to create new instance of ${SubRule_Entity.name}`);
    }
  }

  id: number;
  projectID: number;
  targetIDs: string;
  sectionIDs: string;
  sectionRoleIDs: string;
  orders: string;
  projectPieceIDs: string;
  replaceIDs: string;
  templateID: number;
  createdAt: string;
  updatedAt: string;
  position: number;
}
