import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Layout_Entity } from "entities/layout";
import { rhapsodyApi } from "../api/rhapsodyApi";

export const layoutEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectLayout: build.query<Layout_Entity[], number>({
      query: (projectID) => {
        return `projects/${projectID}/layout`;
      },
      transformResponse: (responseData: { layout: Layout_Entity[] }) => {
        return responseData.layout;
      },
      providesTags: (r, e, projectID) => [{ type: "layouts", id: projectID }],
    }),
  }),
});

export const { useGetProjectLayoutQuery } = layoutEndpoints;

export default layoutEndpoints;
