/**
 * LibrarianPiece_Entity type guard.
 *
 * @param {any} librarianPieceJson LibrarianPiece object from API
 * @returns {boolean} Return true if type is LibrarianPiece_Entity
 */

export default class LibrarianPiece_Entity {
  constructor(pieceJson: LibrarianPiece_Entity) {
    this.id = pieceJson.id;
    this.shorthand = pieceJson.shorthand;
    this.duration = pieceJson.duration;
    this.title = pieceJson.title;
    this.description = pieceJson.description;
    this.comments = pieceJson.comments;
    this.composerID = pieceJson.composerID;
    this.composer = pieceJson.composer;
    this.composedFrom = pieceJson.composedFrom;
    this.composedTo = pieceJson.composedTo;
    this.percussionInfo = pieceJson.percussionInfo;
    this.link = pieceJson.link;
    this.source = pieceJson.source;
    this.refID = pieceJson.refID;
    this.createdAt = pieceJson.createdAt;
    this.updatedAt = pieceJson.updatedAt;
    this.fetchID = pieceJson.fetchID;
  }

  id: number;
  shorthand: string;
  duration: string;
  title: string;
  description: string;
  comments: string;
  composerID: string;
  composer: string;
  composedFrom: string;
  composedTo: string;
  percussionInfo: string;
  link: string;
  source: string;
  refID: string;
  createdAt: string;
  updatedAt: string;
  fetchID: string;
}
