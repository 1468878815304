import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Organization_Entity } from "entities/organization";
import { accountApi } from "../api/accountApi";

const organizationsAdapter = createEntityAdapter<Organization_Entity>();
const initialState = organizationsAdapter.getInitialState();

export const organizationEndpoints = accountApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizations: build.query<EntityState<Organization_Entity>, void>({
      query: () => `organizations`,
      transformResponse: (responseData: Organization_Entity[]) => {
        return organizationsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["organizations"],
    }),
    getOrganization: build.query<Organization_Entity, number>({
      query: (id) => `organizations/${id}`,
      providesTags: (result, error, id) => [{ type: "organizations", id }],
    }),
    inviteMember: build.mutation<
      Organization_Entity,
      { inviteEmail: string; organizationID: number }
    >({
      query: (body) => ({
        method: "POST",
        body: [body],
        url: `organizationUsers`,
      }),
      invalidatesTags: ["organizations"],
    }),
    createOrganization: build.mutation<
      Organization_Entity,
      Partial<Organization_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `organizations`,
      }),
      invalidatesTags: ["organizations"],
    }),
    updateOrganization: build.mutation<
      void,
      { id: number; body: Partial<Organization_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `organizations/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "organizations", id },
        "organizations",
      ],
    }),
    deleteOrganization: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `organizations/${id}`,
      }),
      invalidatesTags: ["organizations"],
    }),
  }),
});

export const {
  useInviteMemberMutation,
  useGetOrganizationQuery,
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useUpdateOrganizationMutation,
} = organizationEndpoints;

export default organizationEndpoints;
