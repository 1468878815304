import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { accountApi } from "../api/accountApi";
import { User_Entity } from "@nerdjs/account-kit";

const usersAdapter = createEntityAdapter<User_Entity>();
const initialState = usersAdapter.getInitialState();

export const userEndpoints = accountApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<EntityState<User_Entity>, void>({
      query: () => `users`,
      transformResponse: (responseData: User_Entity[]) => {
        return usersAdapter.setAll(initialState, responseData);
      },
      providesTags: ["users"],
    }),
    getOrganizationUsers: build.query<User_Entity[], number>({
      query: (id) => {
        return `organizations/${id}/users`;
      },
      providesTags: (result, error, id) => [{ type: "organizations", id }],
    }),
    getUser: build.query<User_Entity, number>({
      query: (id) => `users/${id}`,
      providesTags: (result, error, id) => [{ type: "users", id }],
    }),
    getCurrentUser: build.query<User_Entity, void>({
      query: () => `users/current`,
      providesTags: ["users"],
    }),
    createUser: build.mutation<User_Entity, Partial<User_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `users`,
      }),
      invalidatesTags: ["users"],
    }),
    updateCurrentUser: build.mutation<void, { body: Partial<User_Entity> }>({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `users/current`,
      }),
      invalidatesTags: ["users", "verificationCodes"],
    }),
    updateUserPassword: build.mutation<void, { body: { password: string } }>({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `users/current/password`,
      }),
      invalidatesTags: ["users"],
    }),
    deleteUser: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `users/${id}`,
      }),
      invalidatesTags: ["users"],
    }),
    deleteOrganizationUser: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `organizationUsers/${id}`,
      }),
      invalidatesTags: ["organizations"],
    }),
  }),
});

export const {
  useDeleteOrganizationUserMutation,
  useGetOrganizationUsersQuery,
  useGetCurrentUserQuery,
  useGetUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateCurrentUserMutation,
  useUpdateUserPasswordMutation,
} = userEndpoints;

export default userEndpoints;
