import { DanielsWork } from "entities/danielsWork";
import { useMemo } from "react";
import { useDanielsWorkQuery } from "./danielsWorkEndpoints";

export function useDanielsWork(danielsWorkID: number | undefined) {
  const danielsWorkQuery = useDanielsWorkQuery(danielsWorkID ?? 0, {
    skip: !danielsWorkID || (danielsWorkID ?? 0) <= 0,
  });
  const danielsWorkEntity = danielsWorkQuery.data;

  return useMemo(() => {
    const ret: typeof danielsWorkQuery & {
      danielsWork?: DanielsWork | undefined;
    } = {
      ...danielsWorkQuery,
    };
    if (danielsWorkEntity) ret.danielsWork = new DanielsWork(danielsWorkEntity);
    return ret;
  }, [danielsWorkEntity]);
}
