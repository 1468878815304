/**
 * Customer_Entity type guard.
 *
 * @param {any} customerJson Customer object from API
 * @returns {boolean} Return true if type is Customer_Entity
 */

export default class Customer_Entity {
  static requiredFields = [];

  constructor(customerJson: Customer_Entity) {
    try {
      this.id = customerJson.id;
      this.name = customerJson.name;
      this.address = customerJson.address;
      this.city = customerJson.city;
      this.state = customerJson.state;
      this.zipcode = customerJson.zipcode;
      this.contact = customerJson.contact;
      this.phone = customerJson.phone;
      this.email = customerJson.email;
      this.workDocument = customerJson.workDocument;
      this.organizationID = customerJson.organizationID;
      this.deleted = customerJson.deleted;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id?: number;
  name?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  contact?: string;
  phone?: string;
  email?: string;
  workDocument?: string;
  organizationID?: number;
  deleted?: boolean;
}
