import SaveIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Input } from "@mui/joy";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DialogClose from "atoms/DialogClose/DialogClose";
// material-ui
// helpers
import { GooglePlaceSuggestions } from "hooks/googlePlaceSuggestions/googlePlaceSuggestions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, selectedVenueIDSelector } from "reducers/rhapsody";
// styles
import { setFormOpen, setSelectedVenueID } from "reducers/rhapsody";
import { useCreateVenueMutation } from "redux/venue/venueEndpoints";
import { useVenue } from "redux/venue/venueHooks";

export default function FormVenue() {
  const [createVenue, { isLoading: createLoading }] = useCreateVenueMutation();
  const [updateVenue, { isLoading: updateLoading }] = useCreateVenueMutation();
  const formOpen = useSelector(formOpenSelector("venue"));
  const selectedVenueID = useSelector(selectedVenueIDSelector);
  const { venue: selectedVenue } = useVenue(selectedVenueID);
  const [venue, setVenue] = useState({
    name: "",
    email: "",
    phone: "",
    contact: "",
    address: "",
    zipcode: "",
    city: "",
    state: "",
    latitude: "",
    longitude: "",
    tzName: "",
  });

  useEffect(() => {
    if (selectedVenue) setVenue(selectedVenue);
    return () => dispatch(setSelectedVenueID());
  }, []);

  const dispatch = useDispatch();

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setVenue((s) => ({ ...s, [name]: value }));
  };

  const save = async () => {
    if (selectedVenue && venue.tzName && venue.latitude && venue.longitude) {
      updateVenue({ id: selectedVenue.id, body: venue });
    } else {
      createVenue(venue);
    }

    dispatch(setFormOpen({ isOpen: false, formID: "venue" }));
  };

  const disabled =
    venue.name.length === 0 ||
    venue.city.length === 0 ||
    venue.state.length === 0;

  return (
    <DialogClose
      onClose={() => dispatch(setFormOpen({ isOpen: false, formID: "venue" }))}
      open={formOpen}
      scroll="paper"
      aria-labelledby="responsive-dialog-title"
    >
      {createLoading || updateLoading ? (
        <div>
          <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogContent>
            <div
              style={{
                background: "#e3f2fd",
                padding: 16,
                borderRadius: "16px",
                textAlign: "center",
              }}
            >
              <i
                style={{ fontSize: 80, color: "#001E3C" }}
                class="fad fa-location-dot"
              ></i>
              <Typography variant="h6" style={{ color: "#001E3C" }}>
                New Venue
              </Typography>
              <Typography
                style={{ color: "#001E3C", opacity: 0.8 }}
                color="textSecondary"
                variant="body2"
              >
                This is the location your work sessions take place.
              </Typography>
            </div>
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={12}>
                <Input
                  startDecorator="Name:"
                  placeholder="Studio Name"
                  autoFocus
                  variant="outlined"
                  fullWidth
                  value={venue.name}
                  onChange={handleChange("name")}
                />
              </Grid>
              <Grid item xs={12}>
                <GooglePlaceSuggestions
                  onSelect={(e) => {
                    setVenue((v) => ({
                      ...v,
                      address: e.address ? e.address : "",
                      city: e.city ? e.city : "",
                      state: e.state ? e.state : "",
                      zipcode: e.zipcode ? e.zipcode : "",
                      tzName: e.tzName ? e.tzName : "",
                      longitude: `${e.longitude}`,
                      latitude: `${e.latitude}`,
                    }));
                  }}
                />
                {venue.tzName ? (
                  <Typography variant="caption" color="textSecondary">
                    {venue.latitude},{venue.longitude} - {venue.tzName}
                  </Typography>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                dispatch(setFormOpen({ isOpen: false, formID: "venue" }))
              }
            >
              <CloseIcon />
              &nbsp;Cancel
            </Button>
            <Button
              style={{
                background: "#2196f3",
                color: "white",
                opacity: disabled ? 0.5 : 1,
              }}
              autoFocus
              disabled={disabled}
              onClick={async () => {
                save();
              }}
            >
              <SaveIcon />
              {selectedVenue ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </>
      )}
    </DialogClose>
  );
}
