/**
 * EventStatus_Entity type guard.
 *
 * @param {any} eventStatusJson EventStatus object from API
 * @returns {boolean} Return true if type is EventStatus_Entity
 */

export default class EventStatus_Entity {
  constructor(eventStatusJson: EventStatus_Entity) {
    this.id = eventStatusJson.id;
    this.name = eventStatusJson.name;
    this.icon = eventStatusJson.icon;
    this.mercuryStageID = eventStatusJson.mercuryStageID;
    this.color = eventStatusJson.color;
    this.colorRead = eventStatusJson.colorRead;
    this.bodyRead = eventStatusJson.bodyRead;
    this.showDetails = eventStatusJson.showDetails;
    this.title = eventStatusJson.title;
    this.actionName = eventStatusJson.actionName;
    this.actionMessage = eventStatusJson.actionMessage;
    this.actionText = eventStatusJson.actionText;
    this.actionLink = eventStatusJson.actionLink;
    this.defaultGreeting = eventStatusJson.defaultGreeting;
    this.canNotify = eventStatusJson.canNotify;
    this.canSelect = eventStatusJson.canSelect;
    this.clearImportantChanges = eventStatusJson.clearImportantChanges;
    this.clearCriticalChanges = eventStatusJson.clearCriticalChanges;
    this.position = eventStatusJson.position;
    this.willNotify = eventStatusJson.willNotify;
    this.subject = eventStatusJson.subject;
    this.karmaPressure = eventStatusJson.karmaPressure;
  }

  id?: number;
  name?: string;
  icon?: string;
  mercuryStageID?: number;
  color?: string;
  colorRead?: string;
  bodyRead?: string;
  showDetails?: boolean;
  title?: string;
  actionName?: string;
  actionMessage?: string;
  actionText?: string;
  actionLink?: string;
  defaultGreeting?: string;
  canNotify?: boolean;
  canSelect?: boolean;
  clearImportantChanges?: boolean;
  clearCriticalChanges?: boolean;
  position?: number;
  willNotify: string[];
  subject: string;
  karmaPressure: number;
}
