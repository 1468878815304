/**
 * Layout_Entity type guard.
 *
 * @param {any} layoutJson Layout object from API
 * @returns {boolean} Return true if type is Layout_Entity
 */

export default class Layout_Entity {
  constructor(layoutJson: Layout_Entity) {
    try {
      this.id = layoutJson.id;
      this.parentID = layoutJson.parentID;
      this.sectionID = layoutJson.sectionID;
      this.familyID = layoutJson.familyID;
      this.createCount = layoutJson.createCount;
      this.altCount = layoutJson.altCount;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ JobStage_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  parentID: number;
  sectionID: number;
  familyID: number;
  createCount: number;
  altCount: number;
}
