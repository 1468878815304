import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Textarea,
  Typography,
} from "@mui/joy";
import { Box, Drawer, Grow, Hidden, Paper, Snackbar } from "@mui/material";
import {
  ChairType,
  actionIcons,
  actionLabel,
  useChairOptions,
} from "features/chair/useChairActions";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { InstrumentsTooltip } from "features/musicians/InstrumentsPopover/InstrumentsTooltip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  layoutMoveToSectionIDSelector,
  layoutSectionsWithEmptyChairs,
  layoutSelectionSelector,
  layoutSetMoveToSectionID,
  layoutUnselectAll,
} from "reducers/layout";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  selectedProjectPieceIDSelector,
  selectedWorkSessionIDSelector,
} from "reducers/rhapsody";
import {
  useDeleteJobMutation,
  useUpdateBatchJobMutation,
} from "redux/job/jobEndpoints";
import { useWorkSessionActiveJobs } from "redux/job/jobHooks";
import {
  useDeleteProjectAlternateMutation,
  useUpdateBatchProjectAlternateMutation,
} from "redux/projectAlternate/projectAlternateEndpoints";
import { useProjectAlternates } from "redux/projectAlternate/projectAlternateHooks";
import {
  useDeleteProjectPieceChairMutation,
  useUpdateBatchProjectPieceChairMutation,
} from "redux/projectPieceChair/projectPieceChairEndpoints";
import { useProjectPieceChairs } from "redux/projectPieceChair/projectPieceChairHooks";
import { useProjectSections } from "redux/projectSection/projectSectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { useWorkSessionSections } from "redux/workSessionSection/workSessionSectionHooks";

export default function GroupActions({ chairType }: { chairType: ChairType }) {
  const options = useChairOptions(chairType);
  const selecto = useSelector(layoutSelectionSelector);
  const [roleAnchorEl, setRoleAnchorEl] = useState<HTMLAnchorElement | null>();
  const sectionsWithEmptyChairs = useSelector(layoutSectionsWithEmptyChairs);
  const [moveToAnchorEl, setMoveToAnchorEl] =
    useState<HTMLAnchorElement | null>();
  const [chairMemoAnchorEl, setChairMemoAnchorEl] =
    useState<HTMLAnchorElement | null>();
  const [chairMemo, setChairMemo] = useState("");
  const selectedWorkSessionID = useSelector(selectedWorkSessionIDSelector);
  const selectedProjectPieceID = useSelector(selectedProjectPieceIDSelector);
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const { projectPieceChairsMap } = useProjectPieceChairs(
    selectedProjectPieceID
  );
  const moveToSectionID = useSelector(layoutMoveToSectionIDSelector);
  const { projectAlternatesMap } = useProjectAlternates(selectedProjectID);
  const { jobsMap } = useWorkSessionActiveJobs(selectedWorkSessionID);
  const { sectionRoles } = useSectionRoles();
  const projectSideBarOpen = useSelector(formOpenSelector("projectSidebar"));
  const {
    workSessionSections,
    isFetching: f2,
    isLoading: l2,
  } = useWorkSessionSections(selectedWorkSessionID);
  const {
    projectSections,
    isFetching: f1,
    isLoading: l1,
  } = useProjectSections(selectedProjectID);
  const [updateBatchProjectAlternate, { isLoading: l4 }] =
    useUpdateBatchProjectAlternateMutation();
  const [deleteProjectAlternate] = useDeleteProjectAlternateMutation();

  const [updateBatchJob, { isLoading: l3 }] = useUpdateBatchJobMutation();
  const [deleteJob] = useDeleteJobMutation();

  const [updateBatchProjectPieceChair, { isLoading: l5 }] =
    useUpdateBatchProjectPieceChairMutation();
  const [deleteProjectPieceChair] = useDeleteProjectPieceChairMutation();
  const askQuestion = useAskQuestion();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(layoutUnselectAll());
      dispatch(layoutSetMoveToSectionID());
    };
  }, []);

  useEffect(() => {
    if (selecto.length === 0) {
      setChairMemoAnchorEl(null);
      setMoveToAnchorEl(null);
      setRoleAnchorEl(null);
    }
  }, [selecto]);

  function getChair(chairID: number | string) {
    if (chairType === "project") return projectAlternatesMap[chairID];
    if (chairType === "workSession") return jobsMap[chairID];
    if (chairType === "projectPiece") return projectPieceChairsMap[chairID];
  }

  const handleRemoveMusicians = () => {
    setRoleAnchorEl(null);
    const body = [];
    selecto.forEach((id) => {
      body.push({
        ...getChair(id),
        musicianID: -1,
      });
    });
    if (chairType === "project") updateBatchProjectAlternate(body);
    if (chairType === "workSession") updateBatchJob(body);
    if (chairType === "projectPiece") updateBatchProjectPieceChair(body);
  };

  const handleInstruments = (instrumentIDs) => {
    setChairMemoAnchorEl(null);
    const body = [];
    selecto.forEach((id) => {
      body.push({
        ...getChair(id),
        instrumentIDs: JSON.stringify(instrumentIDs),
      });
    });
    if (chairType === "project") updateBatchProjectAlternate(body);
    if (chairType === "workSession") updateBatchJob(body);
    if (chairType === "projectPiece") updateBatchProjectPieceChair(body);
  };

  const handleChairMemo = () => {
    setChairMemoAnchorEl(null);
    const body = [];
    selecto.forEach((id) => {
      body.push({
        ...getChair(id),
        notes: chairMemo,
        memo: chairMemo,
      });
    });
    if (chairType === "project") updateBatchProjectAlternate(body);
    if (chairType === "workSession") updateBatchJob(body);
    if (chairType === "projectPiece") updateBatchProjectPieceChair(body);
  };

  const handleRemoveChair = () => {
    setRoleAnchorEl(null);
    selecto.forEach((id) => {
      if (chairType === "project") deleteProjectAlternate(parseInt(id));
      if (chairType === "template") deleteProjectAlternate(parseInt(id));
      if (chairType === "workSession") deleteJob(parseInt(id));
      if (chairType === "projectPiece") deleteProjectPieceChair(parseInt(id));
    });
  };

  const handleRoleChange = (sectionRoleID) => {
    setRoleAnchorEl(null);
    const body = [];
    selecto.forEach((id) => {
      body.push({
        ...getChair(id),
        sectionRoleID,
      });
    });
    if (chairType === "project") updateBatchProjectAlternate(body);
    if (chairType === "workSession") updateBatchJob(body);
    if (chairType === "projectPiece") updateBatchProjectPieceChair(body);
  };

  const handleMoveToChange = (sectionID) => {
    setMoveToAnchorEl(null);
    if (sectionsWithEmptyChairs[sectionID]) {
      dispatch(layoutSetMoveToSectionID(sectionID));
    } else {
      askQuestion("Missing Empty Chairs", ["OK"], {
        subtitle: (
          <Typography>
            The section you have selected does not contain any empty chairs to
            place your musicians. Please make sure there are available spots
            before you start moving musicians to a new section.
          </Typography>
        ),
      });
    }
    // const body = [];
    // selecto.forEach((id) => {
    //   body.push({
    //     ...getChair(id),
    //     sectionID,
    //   });
    // });
    // if (chairType === "project") updateBatchProjectAlternate(body);
    // if (chairType === "workSession") updateBatchJob(body);
    // if (chairType === "projectPiece") updateBatchProjectPieceChair(body);
  };

  const content = (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 1, flexShrink: 0 }}
      >
        <Avatar color="primary" variant="solid">
          {f1 || f2 || l1 || l2 || l3 || l4 ? (
            <CircularProgress />
          ) : (
            selecto.length
          )}
        </Avatar>
        <Box>
          <Typography level="h6" noWrap>
            Chairs Selected
          </Typography>
          <Typography level="body4" noWrap>
            Hold <b>SHIFT</b> to select multiple chairs.
          </Typography>
          <Typography level="body4" noWrap>
            Or <b>click and drag</b> to select a group.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          overflow: "auto",
        }}
      >
        {options.indexOf("instruments") >= 0 ? (
          <InstrumentsTooltip
            height={600}
            hideTitle
            onSelect={handleInstruments}
          >
            <IconButton
              variant="plain"
              color="neutral"
              sx={{ flexDirection: "column", flexShrink: 0 }}
            >
              <i
                style={{ fontSize: 18 }}
                className={actionIcons["instruments"]}
              ></i>
              <Typography level="body4">
                {actionLabel["instruments"]}
              </Typography>
            </IconButton>
          </InstrumentsTooltip>
        ) : (
          []
        )}
        {options.indexOf("role") >= 0 ? (
          <>
            <IconButton
              onClick={(e) => setRoleAnchorEl(e.currentTarget)}
              variant="plain"
              color="neutral"
              sx={{ flexDirection: "column", flexShrink: 0 }}
            >
              <i style={{ fontSize: 18 }} className={actionIcons["role"]}></i>
              <Typography level="body4">{actionLabel["role"]}</Typography>
            </IconButton>
            <Menu
              placement="top"
              onClose={() => setRoleAnchorEl(null)}
              size="sm"
              disablePortal
              anchorEl={roleAnchorEl}
              open={Boolean(roleAnchorEl)}
            >
              {sectionRoles?.map((role) => (
                <MenuItem
                  onClick={() => handleRoleChange(role.id)}
                  key={role.id}
                >
                  {role.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          []
        )}
        {options.indexOf("section") >= 0 ? (
          <>
            <IconButton
              variant="plain"
              color="neutral"
              sx={{ flexDirection: "column", flexShrink: 0 }}
              onClick={(e) => setMoveToAnchorEl(e.currentTarget)}
            >
              <i
                style={{ fontSize: 18 }}
                className={actionIcons["section"]}
              ></i>
              <Typography level="body4">{actionLabel["section"]}</Typography>
            </IconButton>
            <Menu
              placement="top"
              onClose={() => setMoveToAnchorEl(null)}
              size="sm"
              disablePortal
              anchorEl={moveToAnchorEl}
              open={Boolean(moveToAnchorEl)}
            >
              {(chairType === "workSession"
                ? workSessionSections
                : projectSections
              )
                ?.sort((a, b) => {
                  if (a.familyPos === b.familyPos)
                    return a.sectionPos - b.sectionPos;
                  return a.familyPos - b.familyPos;
                })
                ?.map((section) => (
                  <MenuItem
                    onClick={() => handleMoveToChange(section.sectionID)}
                    key={section.id}
                  >
                    {section.sectionName}
                  </MenuItem>
                ))}
            </Menu>
          </>
        ) : (
          []
        )}
        {options.indexOf("chair-memo") >= 0 ? (
          <>
            <IconButton
              variant="plain"
              color="neutral"
              sx={{ flexDirection: "column", flexShrink: 0 }}
              onClick={(e) => setChairMemoAnchorEl(e.currentTarget)}
            >
              <i
                style={{ fontSize: 18 }}
                className={actionIcons["chair-memo"]}
              ></i>
              <Typography level="body4">{actionLabel["chair-memo"]}</Typography>
            </IconButton>
            <Menu
              placement="top"
              sx={{ p: 1, gap: 1 }}
              disablePortal
              anchorEl={chairMemoAnchorEl}
              open={Boolean(chairMemoAnchorEl)}
              onClose={() => setChairMemoAnchorEl(null)}
            >
              <Textarea
                maxRows={4}
                autoFocus
                placeholder="Anything to say to whoever is in the chair?"
                value={chairMemo}
                onChange={(e) => setChairMemo(e.target.value)}
                size="md"
                variant="outlined"
              />
              <Button size="sm" onClick={handleChairMemo}>
                Apply
              </Button>
            </Menu>
          </>
        ) : (
          []
        )}
        {options.indexOf("remove-musician") >= 0 ? (
          <IconButton
            variant="plain"
            color="neutral"
            onClick={handleRemoveMusicians}
            sx={{ flexDirection: "column", flexShrink: 0 }}
          >
            <i
              style={{ fontSize: 18 }}
              className={actionIcons["remove-musician"]}
            ></i>
            <Typography level="body4">
              {actionLabel["remove-musician"]}
            </Typography>
          </IconButton>
        ) : (
          []
        )}
        {options.indexOf("delete-chair") >= 0 ? (
          <>
            <IconButton
              variant="plain"
              color="neutral"
              onClick={handleRemoveChair}
              sx={{ flexDirection: "column", flexShrink: 0 }}
            >
              <i
                style={{ fontSize: 18 }}
                className={actionIcons["delete-chair"]}
              ></i>
              <Typography level="body4">
                {actionLabel["delete-chair"]}
              </Typography>
            </IconButton>
            <Divider orientation="vertical" />
            <IconButton
              onClick={() => {
                dispatch(layoutUnselectAll());
                dispatch(layoutSetMoveToSectionID());
              }}
              size="lg"
              variant="plain"
              color="neutral"
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          </>
        ) : (
          []
        )}
      </Box>
    </>
  );

  return (
    <>
      <Hidden mdDown>
        <Grow in={selecto.length > 0 && !moveToSectionID}>
          <Paper
            sx={{
              position: "fixed",
              bottom: 32,
              left: !projectSideBarOpen ? "18%" : "30%",
              display: "flex",
              alignItems: "center",
              gap: 1,
              maxWidth: 920,
              width: "calc(100vw - 400px)",
              zIndex: 999,
              borderRadius: 50,
              justifyContent: "space-between",
              p: 2,
              boxShadow: "0px 15px 50px rgba(0, 0, 0, 0.3)",
            }}
          >
            {content}
          </Paper>
        </Grow>
      </Hidden>
      <Hidden mdUp>
        <Grow in={selecto.length > 0}>
          <Drawer anchor="bottom" variant="persistent" open>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
              }}
            >
              {content}
            </Box>
          </Drawer>
        </Grow>
      </Hidden>
    </>
  );
}
