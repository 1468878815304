import { Button, Textarea, Typography } from "@mui/joy";
import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useUpdateWorkSessionMutation } from "redux/workSession/workSessionEndpoints";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function WorkSessionMemos() {
  const open = useSelector(formOpenSelector("workSessionMemos"));
  const dispatch = useDispatch();
  const { workSessions } = useSelector(layoutUtilsSelector);
  const [updateWorkSession] = useUpdateWorkSessionMutation();
  const [memos, setMemos] = useState([]);

  useEffect(() => {
    if (workSessions.length)
      setMemos(
        workSessions.reduce((a, v) => {
          a.push(v.memo);
          return a;
        }, [])
      );
  }, [workSessions]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "workSessionMemos" }));
  };

  const save = () => {
    memos.forEach((d, i) => {
      updateWorkSession({
        id: workSessions[i].id,
        body: { ...workSessions[i], memo: d },
      });
    });
    onClose();
  };

  return (
    <DialogClose maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-sharp fa-solid fa-music"></i>}
          level="h6"
        >
          Work Session Memos
        </Typography>
        <Typography level="body2">
          This info will be included in the call and communication.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        {memos?.map((d, i) => {
          const workSession = workSessions[i];
          return (
            <Box key={i}>
              <Typography level="body2">
                {workSession?.title}, {workSession?.formatDateRange()}
              </Typography>
              <Textarea
                size="sm"
                placeholder="Ex: Strings only"
                value={d}
                variant="soft"
                minRows={2}
                maxRows={5}
                onChange={(e) =>
                  setMemos((r) => {
                    r[i] = e.target.value;
                    return [...r];
                  })
                }
                endDecorator={
                  <Typography level="body4">Visible by musicians.</Typography>
                }
              />
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}
