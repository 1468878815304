import { Button, Chip, Sheet, Textarea, Typography } from "@mui/joy";
import {
  Box,
  Grow,
  Paper,
  Skeleton,
  Tooltip,
  alpha,
  darken,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Chair } from "entities/chair";
import { Job } from "entities/job";
import { MercuryJob } from "entities/mercuryJob";
import { ProjectAlternate } from "entities/projectAlternate";
import { InstrumentsTooltip } from "features/musicians/InstrumentsPopover/InstrumentsTooltip";
import MusicianAvatar from "features/musicians/MusicianAvatar/MusicianAvatar";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useInstrument } from "redux/instrument/instrumentHooks";
import { useMusician } from "redux/musician/musicianHooks";
import { useStage } from "redux/stage/stageHooks";
import { ChairAccessorials } from "./chairAccessorials";
import { ChairAssignMusician } from "./chairAssignMusician";
import { ChairAssignProjectMusician } from "./chairAssignProjectMusician";
import { ChairMercuryHistory } from "./chairMercuryHistory";
import { ChairSectionRole } from "./chairSectionRole/v1";
import { ChairOption, ChairType, useChairOptions } from "./useChairActions";
import {
  useDeleteProjectAlternateMutation,
  useUpdateProjectAlternateMutation,
} from "redux/projectAlternate/projectAlternateEndpoints";
import {
  useDeleteJobMutation,
  useUpdateJobMutation,
} from "redux/job/jobEndpoints";
import { useDeleteProjectPieceMutation } from "redux/projectPiece/projectPieceEndpoints";
import { useUpdateProjectPieceChairMutation } from "redux/projectPieceChair/projectPieceChairEndpoints";
import { useSelector } from "react-redux";
import {
  layoutMoveToSectionIDSelector,
  layoutReorderEnabledForSectionSelector,
  layoutReorderingSelector,
} from "reducers/layout";
import { Musician } from "entities/musician";
import { Dictionary } from "@reduxjs/toolkit";
import { Stage } from "entities/stage";

export default function RhapsodyChair({
  chair,
  layer = -1,
  chairType,
  mercuryJob,
  sub = false,
  emptyChairIndex,
  musician: _musician,
  stagesMap,
}: {
  chair: Chair;
  mercuryJob?: MercuryJob;
  layer?: number;
  chairType: ChairType;
  sub?: boolean;
  emptyChairIndex?: number;
  musician?: Musician;
  stagesMap?: Dictionary<Stage>;
}) {
  const [hover, setHover] = useState(false);
  const isEmpty = !chair?.musicianID || chair.musicianID === -1;
  const [chairMemo, setChairMemo] = useState<string>(
    chair?.memo ?? chair?.notes ?? undefined
  );
  const reorderEnabled = useSelector(
    layoutReorderEnabledForSectionSelector(chair?.sectionID)
  );
  const moveToSectionID = useSelector(layoutMoveToSectionIDSelector);
  let stage = stagesMap ? stagesMap[mercuryJob?.currentStageID] : undefined;
  const stageData = useStage(mercuryJob?.currentStageID, {
    skip: stagesMap != undefined,
  });
  if (!stage) stage = stageData.stage;
  const chairOptions = useChairOptions(chairType, stage?.id);
  const [musicianMemo, setMusicianMemo] = useState<string>();
  const [autofocus, setAutofocus] = useState<boolean>(false);
  const reorder = useSelector(layoutReorderingSelector);

  const [deleteProjectAlternate] = useDeleteProjectAlternateMutation();
  const [deleteJob] = useDeleteJobMutation();
  const [deleteProjectPiece] = useDeleteProjectPieceMutation();

  const [updateJob] = useUpdateJobMutation();
  const [updateProjectAlternate] = useUpdateProjectAlternateMutation();
  const [updateProjectPieceChair] = useUpdateProjectPieceChairMutation();
  let musician = _musician;
  const musicianData = useMusician(chair?.musicianID, {
    skip: musician?.id > 0,
  });
  if (!_musician) musician = musicianData.musician;

  let updateChair;
  let deleteChair;

  const chairMemoRef = useRef();

  useEffect(() => {
    if (chair) {
      let chairMemo = chair?.memo ?? chair?.notes;
      if (!chairMemo) chairMemo = undefined;
      setChairMemo(chairMemo);
    }
  }, [chair]);

  switch (chairType) {
    case "template":
    case "project":
      deleteChair = deleteProjectAlternate;
      updateChair = updateProjectAlternate;
      break;
    case "projectPiece":
      deleteChair = deleteProjectPiece;
      updateChair = updateProjectPieceChair;
      break;
    case "workSession":
      deleteChair = deleteJob;
      updateChair = updateJob;
      break;

    default:
      break;
  }

  const handleChairAccessorialSelection = (key: ChairOption) => {
    switch (key) {
      case "remove-musician":
        updateChair({ id: chair.id, body: { ...chair, musicianID: -1 } });
        break;
      case "delete-chair":
        deleteChair(chair.id);
        break;
      case "chair-memo":
        setChairMemo("");
        setAutofocus(true);
        break;
      case "musician-memo":
        setMusicianMemo("");
        setAutofocus(true);
        break;
      default:
        break;
    }
  };

  const instrumentIDs = chair?.instrumentIDs
    ? JSON.parse(chair?.instrumentIDs ?? "[]")
    : [];

  const handleSectionRoleIDChange = async (sectionRoleID: number) => {
    updateChair({
      id: chair.id,
      body: {
        sectionRoleID,
      },
    });
  };

  const instruments = (
    <Grid2 container spacing={1}>
      {instrumentIDs.map((i, index) => (
        <Grid2 key={i}>
          <Instrument
            instrumentID={i}
            onDelete={
              chairOptions.indexOf("instruments")
                ? () => {
                    const _instrumentIDs = [...instrumentIDs];
                    _instrumentIDs.splice(index, 1);
                    updateChair({
                      id: chair.id,
                      body: { instrumentIDs: JSON.stringify(_instrumentIDs) },
                    });
                  }
                : undefined
            }
          />
        </Grid2>
      ))}
      {chairOptions.indexOf("instruments") >= 0 && hover ? (
        <Grow in>
          <Grid2>
            <InstrumentsTooltip
              hideToolbar
              sectionID={chair?.sectionID}
              onSelect={(instrumentIDs) => {
                updateChair({
                  id: chair.id,
                  body: { instrumentIDs: JSON.stringify(instrumentIDs) },
                });
              }}
            >
              <Sheet
                variant="outlined"
                sx={{
                  pl: 0.3,
                  background: "none",
                  pr: 0.3,
                  border: "dashed 1px rgba(155,155,155,0.3)",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <Typography level="body3">+ Instrument(s)</Typography>
              </Sheet>
            </InstrumentsTooltip>
          </Grid2>
        </Grow>
      ) : (
        []
      )}
    </Grid2>
  );

  const layerVisibility = {
    order: layer <= 0,
    role: layer <= 0 && !sub,
    sub: sub && layer <= 0,
    instrument: layer <= 0,
    musician: layer === 1 || layer === -1,
    chairAccessorials: layer === 1 || layer === -1,
    musicianMemo: layer <= 0,
    chairMemo: layer <= 0,
  };

  if (!chair) return <Box />;

  const removable =
    (stagesMap &&
      mercuryJob?.currentStageID &&
      stagesMap[mercuryJob?.currentStageID]?.terminus) ??
    false;
  let opacity = 1;
  if (moveToSectionID) {
    opacity = moveToSectionID === chair.sectionID && isEmpty ? 1 : 0.2;
  }

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        gap: 0.5,
        pointerEvents: opacity === 0.2 ? "none" : undefined,
        opacity,
      }}
    >
      <Paper
        variant="outlined"
        id={`${chair.id}`}
        className={`selecto`}
        sx={{
          p: "4px",
          background: sub || layer === 1 ? "none" : undefined,
          borderColor: layer === 1 ? "transparent" : undefined,
          borderStyle: sub || layer === 1 ? "dashed" : "solid",
          display: "flex",
          boxShadow:
            reorder && layer !== 1
              ? "0px 2px 8px rgba(155,155,155,0.2)"
              : "0px 0px 2px 2px rgba(155,155,155,0)",
          transition: "box-shadow .3s",
          flexDirection: "column",
          gap: 0.5,
          width: 200,
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              startDecorator={
                sub ? <i className="fa-solid fa-arrow-turn-down-right"></i> : []
              }
              level="body3"
              sx={{ fontWeight: 600, opacity: layerVisibility.order ? 1 : 0 }}
            >
              {chair.order()}
            </Typography>
            {layerVisibility.sub ? (
              <Typography level="body3">Substitute</Typography>
            ) : (
              []
            )}
            {layerVisibility.role ? (
              <ChairSectionRole
                disabled={chairOptions.indexOf("role") === -1}
                value={chair.sectionRoleID}
                onChange={handleSectionRoleIDChange}
              />
            ) : (
              []
            )}
          </Box>
        </Box>
        <Box
          className={reorderEnabled ? "jiggle" : undefined}
          sx={{
            opacity: layerVisibility.musician ? 1 : 0,
            animationDelay: `-${Math.random()}s`,
            cursor: reorder ? "grab" : "pointer",
            animationDuration: `.${Math.floor(Math.random() * 11) + 20}s`,
          }}
        >
          {isEmpty ? (
            <>
              {chairType === "project" ? (
                <ChairAssignProjectMusician
                  highlight={moveToSectionID === chair.sectionID}
                  emptyChairIndex={emptyChairIndex}
                  projectAlternate={chair as ProjectAlternate}
                  onSelect={(musicianID) => {
                    updateChair({
                      id: chair.id,
                      body: {
                        ...chair,
                        musicianID,
                      },
                    });
                  }}
                />
              ) : (
                []
              )}
              {chairType === "workSession" ? (
                <ChairAssignMusician
                  job={chair as Job}
                  onSelect={(musicianID) => {
                    updateChair({
                      id: chair.id,
                      body: {
                        ...chair,
                        musicianID,
                      },
                    });
                  }}
                />
              ) : (
                []
              )}
            </>
          ) : (
            <>
              {chairType === "project" ? (
                <ProjectMusicianCard
                  projectAlternate={chair as ProjectAlternate}
                  musician={musician}
                >
                  {!reorder &&
                  layerVisibility.chairMemo &&
                  musicianMemo !== undefined ? (
                    <Textarea
                      autoFocus={autofocus}
                      maxRows={4}
                      sx={{
                        fontSize: 11,
                        p: 0.5,
                      }}
                      endDecorator={
                        <Box
                          sx={{
                            pl: 0.5,
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography level="body4">Musician Memo</Typography>
                          <i
                            onClick={() => setMusicianMemo(undefined)}
                            style={{ cursor: "pointer" }}
                            className="fa-solid fa-trash"
                          ></i>
                        </Box>
                      }
                      ref={chairMemoRef}
                      placeholder={`Anything to say to ${musician?.firstName}?`}
                      value={musicianMemo}
                      onChange={(e) => setMusicianMemo(e.target.value)}
                      size="md"
                      variant="outlined"
                    />
                  ) : (
                    <></>
                  )}
                </ProjectMusicianCard>
              ) : (
                []
              )}
              {chairType === "workSession" ? (
                <WorkSessionMusicianCard
                  job={chair as Job}
                  mercuryJob={mercuryJob!}
                  reoder={reorder}
                  musician={musician}
                  stagesMap={stagesMap}
                ></WorkSessionMusicianCard>
              ) : (
                []
              )}
            </>
          )}
        </Box>
        {!reorder && layerVisibility.chairMemo && chairMemo !== undefined ? (
          <Textarea
            autoFocus={autofocus}
            maxRows={4}
            sx={{
              fontSize: 11,
              p: 0.5,
            }}
            endDecorator={
              <Box
                sx={{
                  pl: 0.5,
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography level="body4">Chair Memo</Typography>
                <i
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setChairMemo(undefined);
                    updateChair({
                      id: chair?.id,
                      body: {
                        ...chair,
                        notes: "",
                        memo: "",
                      },
                    });
                  }}
                  className="fa-solid fa-trash"
                ></i>
              </Box>
            }
            onBlur={() =>
              updateChair({
                id: chair?.id,
                body: {
                  ...chair,
                  notes: chairMemo,
                  memo: chairMemo,
                },
              })
            }
            ref={chairMemoRef}
            placeholder="Anything to say to whoever is in the chair?"
            value={chairMemo}
            onChange={(e) => setChairMemo(e.target.value)}
            size="md"
            variant="outlined"
          />
        ) : (
          []
        )}
        {!reorder ? (
          <Box sx={{ minHeight: 20, position: "relative" }}>
            {layerVisibility.instrument ? instruments : []}
          </Box>
        ) : (
          []
        )}
        {!reorder && removable ? (
          <Button
            onClick={() => handleChairAccessorialSelection("remove-musician")}
            size="sm"
            sx={{
              color: "white",
              background: stage.color,
              "&:hover": {
                background: darken(stage.color, 0.1),
              },
              "&:active": {
                background: darken(stage.color, 0.2),
              },
            }}
          >
            Remove Musician
          </Button>
        ) : (
          []
        )}
      </Paper>
      <Box sx={{ width: 20, height: 20, position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            zIndex: 999,
            right: 0,
          }}
        >
          {layerVisibility.chairAccessorials ? (
            <ChairAccessorials
              chair={chair}
              onSelect={handleChairAccessorialSelection}
              chairType={chairType}
              visible={hover}
              stageID={stage?.id}
            />
          ) : (
            []
          )}
        </Box>
      </Box>
    </Box>
  );
}

function WorkSessionMusicianCard({
  job,
  mercuryJob,
  reoder,
  stagesMap,
  musician: _musician,
}: {
  job: Job;
  mercuryJob: MercuryJob;
  reoder?: boolean;
  stagesMap?: Dictionary<Stage>;
  musician?: Musician;
}) {
  let musician = _musician;
  const musicianData = useMusician(job?.musicianID, { skip: musician?.id > 0 });
  if (!musician) musician = musicianData?.musician;

  let stage = stagesMap ? stagesMap[job?.mercuryStageID] : undefined;
  const stageData = useStage(job?.mercuryStageID, {
    skip: stagesMap !== undefined,
  });

  if (!stage) stage = stageData.stage;

  const musicianFeedback = mercuryJob?.getMusicianFeedback();
  const [mercuryHistoryOpen, setMercuryHistoryOpen] = useState(false);
  return (
    <>
      <Box
        onClick={() => setMercuryHistoryOpen(true)}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          background: alpha(stage.color, 0.1),
          borderRadius: "8px",
          alignItems: "stretch",
          p: 0.5,
          gap: 1,
          border: `solid 1px ${alpha(stage.color, 0.3)}`,
          cursor: "pointer",
          "&:hover": {
            background: alpha(stage.color, 0.2),
          },
          "&:active": {
            background: alpha(stage.color, 0.3),
          },
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <MusicianAvatar musician={musician} size={20} noBadge />
          <Box sx={{ flex: 1 }}>
            <Typography sx={{ flexGrow: 1 }} level={"body3"}>
              {musician?.fullName()}
            </Typography>
            {!reoder ? (
              <Typography
                level="body4"
                sx={{ opacity: 0.6 }}
                startDecorator={
                  <i className={mercuryJob?.visibleInspector()?.icon} />
                }
              >
                <i>{mercuryJob?.visibleInspector()?.message}</i>
              </Typography>
            ) : (
              []
            )}
          </Box>
          {mercuryJob?.currentJobStage?.nudgeCount ?? 0 ? (
            <Tooltip
              title={`Nudged ${mercuryJob?.currentJobStage.nudgeCount} times`}
            >
              <Chip
                size="sm"
                color="neutral"
                sx={{ background: "white", color: stage.color }}
                variant="outlined"
                startDecorator={<img src={stage.icon} style={{ height: 14 }} />}
              >
                {mercuryJob?.currentJobStage.nudgeCount}
              </Chip>
            </Tooltip>
          ) : (
            <Tooltip title={stage.name}>
              {stage.withCheckmark() ? (
                <Chip
                  size="sm"
                  color="neutral"
                  sx={{ background: "white", color: stage.color }}
                  variant="outlined"
                  startDecorator={
                    <img src={stage.icon} style={{ height: 14 }} />
                  }
                >
                  <i className="fa-solid fa-check"></i>
                </Chip>
              ) : (
                <img src={stage.icon} style={{ height: 14 }} />
              )}
            </Tooltip>
          )}
        </Box>
        {musicianFeedback && !reoder ? (
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                color: "white",
                background: stage.color,
                p: "2px",
                pl: "4px",
                pr: "4px",
                borderRadius: "8px",
              }}
              level="body3"
            >
              {musicianFeedback}
            </Typography>
          </Box>
        ) : (
          []
        )}
      </Box>
      <ChairMercuryHistory
        job={job}
        musician={musician}
        open={mercuryHistoryOpen}
        onClose={() => setMercuryHistoryOpen(false)}
      />
    </>
  );
}

function ProjectMusicianCard({
  projectAlternate,
  children,
  musician: _musician,
}: {
  projectAlternate: ProjectAlternate;
  children?: ReactElement;
  musician?: Musician;
}) {
  let musician = _musician;
  const musicianData = useMusician(projectAlternate?.musicianID, {
    skip: musician?.id > 0,
  });

  if (!musician) musician = musicianData.musician;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        background: alpha(projectAlternate.color(), 0.1),
        borderRadius: "8px",
        alignItems: "stretch",
        p: 0.5,
        gap: 1,
        border: `solid 1px ${alpha(projectAlternate.color(), 0.8)}`,
        "&:hover": {
          background: alpha(projectAlternate.color(), 0.2),
        },
        "&:active": {
          background: alpha(projectAlternate.color(), 0.3),
        },
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <MusicianAvatar musician={musician} size={20} noBadge />
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ flexGrow: 1 }} level={"body3"}>
            {musician?.fullName()}
          </Typography>
        </Box>
      </Box>
      {children}
    </Box>
  );
}

function Instrument({
  instrumentID,
  onDelete,
}: {
  instrumentID: number;
  onDelete: () => void;
}) {
  const { instrument, isLoading } = useInstrument(instrumentID);

  if (isLoading) return <Skeleton />;

  return (
    <Sheet
      variant="soft"
      sx={{
        pl: 0.3,
        pr: 0.3,
        border: "solid 1px rgba(155,155,155,0.1)",
        borderRadius: "4px",
      }}
    >
      <Typography
        endDecorator={
          onDelete ? (
            <i onClick={onDelete} className="fa-solid fa-xmark"></i>
          ) : (
            []
          )
        }
        level="body3"
      >
        {instrument?.name}
      </Typography>
    </Sheet>
  );
}
