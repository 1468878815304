import { InternalSection_Entity } from "../InternalSection";

/**
 * InternalFamily_Entity type guard.
 *
 * @param {any} InternalFamilyJson InternalFamily object from API
 * @returns {boolean} Return true if type is InternalFamily_Entity
 */

export default class InternalFamily_Entity {
  constructor(InternalFamilyJson: InternalFamily_Entity) {
    try {
      this.familyID = InternalFamilyJson.familyID;
      this.familyPos = InternalFamilyJson.familyPos;
      this.sections = InternalFamilyJson.sections;
      this.forWorkSessionID = InternalFamilyJson.forWorkSessionID;
      this.forProjectPieceID = InternalFamilyJson.forProjectPieceID;
    } catch (e) {
      throw new Error(
        `Failed to create new instance of ${InternalFamily_Entity.name}`
      );
    }
  }

  familyID: number;
  familyPos: number;
  sections: InternalSection_Entity[];
  forWorkSessionID?: number;
  forProjectPieceID?: number;
}
