import { Box } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectedProjectIDSelector,
  setSelectedProjectID,
} from "reducers/rhapsody";
import { useProject } from "redux/project/projectHooks";
import ProjectPagev1 from "./v1";
import ProjectPagev2 from "./v2";

export default function ProjectPage({ template = false }) {
  const params = useParams();
  const projectID = parseInt(params.projectID);
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const { project } = useProject(projectID);
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectID && !selectedProjectID) {
      dispatch(setSelectedProjectID(projectID));
    }
  }, [projectID, selectedProjectID]);

  const projectV2 = useMemo(() => {
    return <ProjectPagev2 template={template} />;
  }, [selectedProjectID]);

  if (!project || projectID !== selectedProjectID) return <Box></Box>;

  if (project?.version === 2) {
    return projectV2;
  }

  return <ProjectPagev1 template={template} />;
}
