export const reportTitles = {
  G: "REPORT FORM",
  LV: "LIVE REPORT FORM",
  3: "REPORT FORM\nMADE AND PLAYED LOCAL COMMERCIAL ANNOUNCEMENTS\nContinuation Sheet",
  4: "REPORT FORM\nPHONOGRAPH RECORDS, SOUNDTRACK RELEASES, VIDEO PROMOS\nContinuation Sheet",
  5: "REPORT FORM\nFOR DEMONSTRATION RECORDING - AUDIO ONLY\nContinuation Sheet",
  6: "REPORT FORM\nTELEVISION AND RADIO COMMERCIAL ANNOUNCEMENTS\nContinuation Sheet",
  7: "REPORT FORM\nMOTION PICTURES -THEATRICAL & TELEVISION FILM (STANDARD, NON-STANDARD & BASIC CABLE),\nINDUSTRIAL (NON-THEATRICAL-NON-TV), MISCELLANEOUS, LOW BUDGET FILMS\nContinuation Sheet",
  8: "REPORT FORM\nFOR ALL VIDEOTAPE/LIVE TELEVISION/CABLE TV/PUBLIC TV\nContinuation Sheet",
  9: "LIMITED PRESSING RECORDING REPORT FORM\nContinuation Sheet",
  10: "REPORT FORM\nFOR COMMERCIAL (SYNDICATED), PUBLIC AND LOCAL RADIO\nAND NON-COMMERCIAL I.D.\nContinuation Sheet",
  11: "REPORT FORM\nFOR SYMPHONY, OPERA, BALLET AUDIO-VISUAL AGREEMENT\nContinuation Sheet",
  12: "RADIO TO NON-COMMERCIAL RECORDING REPORT FORM\nFOR SYMPHONIC USE ONLY\nContinuation Sheet",
};

export const footerSentence = {
  3: "(1) Insert X if wages being paid are overscale",
  4: "Include all music prep. info on this form or continuation sheet, with copies of invoices attached.",
  5: "(1) Insert overscale wages being paid.\nInclude all music prep. info on this form or continuation sheet, with copies of invoices attached.",
  6: "(1) Insert X if wages being paid are overscale.",
  7: "(1) Insert overscale wages being paid.\nInclude all music Prep, information on this form or continuation sheet, with copies of invoices attached.",
  8: "(1) Insert X if wages being paid are overscale.\nInclude all Music Preparation on this form along with attached copies of invoices.",
  9: "(1) Insert overscale wages being paid.\nInclude all music prep. info on this form or continuation sheet, with copies of invoices attached.",
  10: "(1) Insert overscale wages being paid.\nInclude all music prep. information on this form or a continuation sheet, with copies of invoices attached.",
  11: "Include all music prep. information on this form or a continuation sheet, with copies of invoices attached.",
  12: "Distribution of the 6 copies of this report form is as follows:\n1. Original page is to be sent to, AFM-EP Fund, 304 East 44th St., New York, NY 10017\nwith Pension contribution check made payable to the AFM-EP Fund\n2. One copy is to be retained by the Signatory of Records\n3. The remaining 4 copies are to be sent to the applicable AFM Local with the musicians’ checksThe AFM Local will:\n· retain one copy\n· send one copy to the American Federation of Musicians, 1501 Broadway, Suite 500, New York, NY 10036\n· send one copy to the Leader\n· send one copy to Health & Welfare Fund (where applicable)\n· send one photo copy to the Orchestra Committee Chairperson",
};

export const linesPerForm = {
  G: 25,
  LV: 25,
  3: 34,
  4: 21,
  5: 22,
  6: 32,
  7: 19,
  8: 26,
  9: 22,
  10: 22,
  11: 23,
  12: 17,
};

export const help = [
  {
    title: "Navigation",
    subtitle: "Use the arrow keys to move the focus.",
    items: [
      { keys: "Arrow Left", description: "Navigate between cell elements" },
      { keys: "Arrow Bottom", description: "Navigate between cell elements" },
      { keys: "Arrow Right", description: "Navigate between cell elements" },
      { keys: "Arrow Up", description: "	Navigate between cell elements" },
      {
        keys: "Home",
        description: "	Navigate to the first cell of the current row",
      },
      {
        keys: "End",
        description: "	Navigate to the last cell of the current row",
      },
      {
        keys: "Ctrl+Home",
        description: "Navigate to the first cell of the first row",
      },
      {
        keys: "Ctrl+End",
        description: "Navigate to the last cell of the last row",
      },
      { keys: "Space", description: "	Navigate to the next scrollable page" },
      {
        keys: "Page Up",
        description: "Navigate to the previous scrollable page",
      },
      {
        keys: "Page Down",
        description: "	Navigate to the next scrollable page",
      },
      {
        keys: "Space",
        description:
          "Toggle row children expansion when grouping cell is focused }",
      },
    ],
  },
  {
    title: "Sorting",
    items: [
      { keys: "Ctrl+ Click on header", description: "Enable multi-sorting" },
      { keys: "Shift+ Click on header", description: "Enable multi-sorting" },
      {
        keys: "Shift+Enter",
        description: "	Enable multi-sorting when column header is focused",
      },
      {
        keys: "Enter",
        description: "Sort column when column header is focused",
      },
      {
        keys: "Ctrl+Enter",
        description: "Open column menu when column header is focused",
      },
    ],
  },
];
