import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { WorkSessionProjectPiece_Entity } from "entities/workSessionProjectPiece";
import { rhapsodyApi } from "../api/rhapsodyApi";

const workSessionProjectPiecesAdapter =
  createEntityAdapter<WorkSessionProjectPiece_Entity>();
const initialState = workSessionProjectPiecesAdapter.getInitialState();

export const workSessionProjectPieceEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkSessionProjectPieces: build.query<
      EntityState<WorkSessionProjectPiece_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `workSessionProjectPieces`,
          params: args,
        };
      },
      transformResponse: (responseData: WorkSessionProjectPiece_Entity[]) => {
        return workSessionProjectPiecesAdapter.setAll(
          initialState,
          responseData
        );
      },
      providesTags: ["workSessionProjectPieces"],
    }),
    getWorkSessionProjectPiece: build.query<
      WorkSessionProjectPiece_Entity,
      number
    >({
      query: (id) => `workSessionProjectPieces/${id}`,
      providesTags: (result, error, id) => [
        { type: "workSessionProjectPieces", id },
      ],
    }),
    createWorkSessionProjectPiece: build.mutation<
      WorkSessionProjectPiece_Entity,
      Partial<WorkSessionProjectPiece_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `workSessionProjectPieces`,
      }),
      invalidatesTags: [
        "workSessionProjectPieces",
        "chairs",
        "assignments",
        "workSessions",
      ],
    }),
    createWorkSessionProjectPiece2: build.mutation<
      WorkSessionProjectPiece_Entity,
      Partial<WorkSessionProjectPiece_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `workSessionProjectPieces`,
      }),
      invalidatesTags: [],
    }),
    updateWorkSessionProjectPiece: build.mutation<
      void,
      { id: number; body: Partial<WorkSessionProjectPiece_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `workSessionProjectPieces/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "workSessionProjectPieces", id },
        "workSessionProjectPieces",
        "chairs",
        "assignments",
        "workSessions",
      ],
    }),
    deleteWorkSessionProjectPiece: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `workSessionProjectPieces/${id}`,
      }),
      invalidatesTags: [
        "workSessionProjectPieces",
        "chairs",
        "assignments",
        "workSessions",
      ],
    }),
  }),
});

export const {
  useCreateWorkSessionProjectPiece2Mutation,
  useGetWorkSessionProjectPieceQuery,
  useGetWorkSessionProjectPiecesQuery,
  useCreateWorkSessionProjectPieceMutation,
  useDeleteWorkSessionProjectPieceMutation,
  useUpdateWorkSessionProjectPieceMutation,
} = workSessionProjectPieceEndpoints;

export default workSessionProjectPieceEndpoints;
