import { Dictionary } from "@reduxjs/toolkit";
import { Job, Job_Entity } from "entities/job";
import { Musician } from "entities/musician";
import { Stage } from "entities/stage";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { piecesEnabled } from "global";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { removableStages } from "hooks/Rosters/rosters";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectedProjectIDSelector, setFormOpen } from "reducers/rhapsody";
import { useProject } from "redux/project/projectHooks";
import { useProjectAlternates } from "redux/projectAlternate/projectAlternateHooks";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useStages } from "redux/stage/stageHooks";

export function useAddProjectChairRequest() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { project } = useProject(projectID);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const askQuestion = useAskQuestion();
  const { projectAlternates, isLoading: projectAlternatesLoading } =
    useProjectAlternates(projectID);
  const { projectPieces, isLoading: projectPiecesLoading } =
    useProjectPieces(projectID);

  if (projectPiecesLoading)
    return () => {
      console.log("Still Loading");
    };

  const baseURL = project?.template
    ? RouterConfig.templates
    : RouterConfig.projects;

  if (
    projectPieces.length === 0 &&
    projectAlternates.length === 0 &&
    !project?.template
  )
    return () => {
      if (piecesEnabled) {
        askQuestion(
          "Warning",
          ["Cancel", "Add Pieces Instead", "Proceed Anyway"],
          {
            subtitle:
              "You won't be able to add Pieces if you proceed. The instrumentation needed for this project can be driven by its Pieces. By adding Chairs manually, you won't be able to add Pieces to this project.",
          }
        ).then((i) => {
          if (i === 2) {
            navigate(`${baseURL}/${projectID}/seating`);
            dispatch(setFormOpen({ isOpen: true, formID: "instrumentation" }));
          }
          if (i === 1) {
            navigate(`${baseURL}/${projectID}/pieces`);
          }
        });
      } else {
        navigate(`${baseURL}/${projectID}/seating`);
        dispatch(setFormOpen({ isOpen: true, formID: "instrumentation" }));
      }
    };

  return () => {
    navigate(`${baseURL}/${projectID}/seating`);
    dispatch(setFormOpen({ isOpen: true, formID: "instrumentation" }));
  };
}

export function useProjectActionsRequired(_projectID?: number): {
  isLoading: boolean;
  hasActions: boolean;
  musicianJobs: {
    musicianID: number;
    musician: Musician;
    mercuryStageID: number;
    stage: Stage;
    jobs: Job[];
  }[];
} {
  let selectedProjectID = useSelector(selectedProjectIDSelector);
  if (_projectID) selectedProjectID = _projectID;
  const { projectHiring, isLoading } = useProjectHiring(selectedProjectID);
  const jobs = projectHiring?.jobs;
  const { stagesMap } = useStages();
  const jobsWithActionRequired = jobs?.filter(
    (j) => j.mercuryStageID && removableStages[j.mercuryStageID]
  );

  const musicianJobs = [];

  for (const key in jobsWithActionRequired) {
    if (Object.prototype.hasOwnProperty.call(jobsWithActionRequired, key)) {
      const job = jobsWithActionRequired[key];
      const index = musicianJobs?.findIndex(
        (j) =>
          j.musicianID === job.musicianID &&
          job.mercuryStageID === j.mercuryStageID
      );

      if (index >= 0) {
        musicianJobs[index].jobs.push(job);
      } else {
        musicianJobs.push({
          mercuryStageID: job.mercuryStageID,
          musicianID: job.musicianID,
          musician: new Musician(job.musician),
          stage: stagesMap[job.mercuryStageID],
          jobs: [job],
        });
      }
    }
  }

  return {
    isLoading,
    musicianJobs,
    hasActions: Object.keys(musicianJobs).length > 0,
  };
}
