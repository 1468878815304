/**
 * WorkSessionProjectPiece_Entity type guard.
 *
 * @param {any} workSessionProjectPieceJson WorkSessionProjectPiece object from API
 * @returns {boolean} Return true if type is WorkSessionProjectPiece_Entity
 */

export default class WorkSessionProjectPiece_Entity {
  constructor(workSessionProjectPieceJson: WorkSessionProjectPiece_Entity) {
    try {
      this.id = workSessionProjectPieceJson.id;
      this.workSessionID = workSessionProjectPieceJson.workSessionID;
      this.projectPieceID = workSessionProjectPieceJson.projectPieceID;
      this.projectID = workSessionProjectPieceJson.projectID;
      this.createdAt = workSessionProjectPieceJson.createdAt;
      this.updatedAt = workSessionProjectPieceJson.updatedAt;
    } catch {
      throw new Error(
        `Failed to create new instance of ${WorkSessionProjectPiece_Entity.name}`
      );
    }
  }

  id: number;
  workSessionID: number;
  projectPieceID: number;
  projectID: number;
  createdAt: string;
  updatedAt: string;
}
