/**
 * WorkSessionType_Entity type guard.
 *
 * @param {any} workSessionTypeJson WorkSessionType object from API
 * @returns {boolean} Return true if type is WorkSessionType_Entity
 */

export default class WorkSessionType_Entity {
  constructor(workSessionTypeJson: WorkSessionType_Entity) {
    try {
      this.id = workSessionTypeJson.id;
      this.code = workSessionTypeJson.code;
      this.description = workSessionTypeJson.description;
      this.version = workSessionTypeJson.version;
      this.name = workSessionTypeJson.name;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }
  id: number;
  code: string;
  name: string;
  description: string;
  version: string;
}
