import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { InternalPosition } from "../InternalPosition";
import InternalSection_Entity from "./InternalSection";
import { Selection_Entity } from "entities/selection";
import { Dictionary } from "@reduxjs/toolkit";
export default class InternalSection extends InternalSection_Entity {
  positions: InternalPosition[];
  otherPositionIDs: string[];
  visibleChairIDs: number[];
  visibleAssignmentIDs: number[];
  enabledPositionIDs: string[];
  chairIDs: number[];
  positionCount: number;
  enabledPositionCount: number;

  constructor(
    json: InternalSection_Entity,
    utils: LayoutUtils,
    selection: Selection_Entity
  ) {
    super(json);
    this.positions = InternalPosition.fromList(
      json.positions,
      utils,
      selection
    );
    this.enabledPositionIDs = this.positions.reduce((a, v) => {
      if (v.enabled) a.push(v.id);
      return a;
    }, []);
    this.otherPositionIDs = this.positions.reduce((a, v) => {
      if (!v.enabled) a.push(v.id);
      return a;
    }, []);
    this.chairIDs = this.positions
      .reduce((a, v) => {
        a.push(...v.chairIDs);
        return a;
      }, [])
      .sort();
    this.visibleChairIDs = this.positions
      .reduce((a, v) => {
        a.push(...v.visibleChairIDs);
        return a;
      }, [])
      .sort();
    this.visibleAssignmentIDs = this.positions.reduce((a, v) => {
      a.push(...v.visibleAssignmentIDs);
      return a;
    }, []);
    this.positionCount = this.positions.length;
    this.enabledPositionCount = this.positions.reduce((a, v) => {
      if (v.enabled) a += 1;
      return a;
    }, 0);
  }

  static fromList(
    InternalSectionsJSON: unknown,
    utils: LayoutUtils,
    selection: Selection_Entity
  ): Array<InternalSection> {
    const InternalSections: InternalSection[] = [];
    if (InternalSectionsJSON)
      Array.isArray(InternalSectionsJSON) &&
        InternalSectionsJSON.forEach((InternalSectionJSON) => {
          InternalSections.push(
            new InternalSection(InternalSectionJSON, utils, selection)
          );
        });
    return InternalSections;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  getPosition(positionID: string) {
    return this.positions.find((p) => p.id === positionID);
  }

  getPositions(positionIDs: string[]) {
    const ret: InternalPosition[] = [];
    positionIDs.forEach((positionID) => {
      const position = this.getPosition(positionID);
      if (position) ret.push(position);
    });

    return ret;
  }

  getSnapshot(utils: LayoutUtils) {
    const ret: SectionSnapshot = [];
    this.positions.forEach((p) => {
      const item: PositionSnapshot = {};
      p.chairIDs.forEach((chairID) => {
        const _chair = utils.chairsMap[chairID];
        if (!_chair?.workSessionID) {
          item[_chair?.projectPieceID] = {
            instrumentIDs: _chair?.instrumentIDs,
            memo: _chair?.memo,
            sectionRoleID: _chair?.sectionRoleID,
          };
        }
      });
      ret.push(item);
    });

    return ret;
  }
}

type SectionSnapshot = PositionSnapshot[];

type PositionSnapshot = Dictionary<PiecePositionSnapshot>;

type PiecePositionSnapshot = {
  instrumentIDs: string;
  memo: string;
  sectionRoleID: number;
};
