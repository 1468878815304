import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Tag_Entity } from "entities/tag";
import { rhapsodyApi } from "../api/rhapsodyApi";

const tagsAdapter = createEntityAdapter<Tag_Entity>();
const initialState = tagsAdapter.getInitialState();

export const tagEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getTags: build.query<EntityState<Tag_Entity>, void>({
      query: () => `tags`,
      transformResponse: (responseData: Tag_Entity[]) => {
        return tagsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["tags"],
    }),
    getTag: build.query<Tag_Entity, number>({
      query: (id) => `tags/${id}`,
      providesTags: (result, error, id) => [{ type: "tags", id }],
    }),
    createTag: build.mutation<Tag_Entity, Partial<Tag_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `tags`,
      }),
      invalidatesTags: ["tags"],
    }),
    updateTag: build.mutation<void, { id: number; body: Partial<Tag_Entity> }>({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `tags/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "tags", id },
        "tags",
      ],
    }),
    deleteTag: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `tags/${id}`,
      }),
      invalidatesTags: ["tags"],
    }),
  }),
});

export const {
  useGetTagQuery,
  useGetTagsQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useUpdateTagMutation,
} = tagEndpoints;

export default tagEndpoints;
