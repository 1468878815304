import { CircularProgress, Input } from "@mui/joy";
import { Box } from "@mui/material";
import { NerdPhoneField } from "@nerdjs/nerd-ui";
import { Payroll_Entity } from "entities/payroll";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectedPayrollIDSelector } from "reducers/rhapsody";
import { useUpdatePayrollMutation } from "redux/payroll/payrollEndpoints";
import { usePayroll } from "redux/payroll/payrollHooks";

export default function PayrollGeneral({ id }) {
  const params = useParams();
  const payrollID =
    id || parseInt(params.payrollID) || useSelector(selectedPayrollIDSelector);
  const { payroll, isLoading } = usePayroll(payrollID);
  const [updatePayroll, { isLoading: isUpdating }] = useUpdatePayrollMutation();

  const [_payroll, _setPayroll] = useState<Payroll_Entity | undefined>();

  useEffect(() => {
    _setPayroll(new Payroll_Entity(payroll));
  }, [payroll]);

  const save = async (name: string, numeric?: boolean) => {
    if (payroll[name] === _payroll[name]) return;
    updatePayroll({
      id: payroll.id,
      body: {
        [name]: numeric ? Number(_payroll[name]) : _payroll[name],
      },
    });
  };

  if (!_payroll) return <CircularProgress />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        maxWidth: 640,
      }}
    >
      <Input
        startDecorator="Name"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("name");
        }}
        fullWidth
        onBlur={() => save("name")}
        value={_payroll.name || ""}
        onChange={(e) =>
          _setPayroll((p) => {
            p.name = e.target.value;
            return { ...p };
          })
        }
      />
      <Input
        startDecorator="Contact"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("contact");
        }}
        fullWidth
        onBlur={() => save("contact")}
        value={_payroll.contact || ""}
        onChange={(e) =>
          _setPayroll((p) => {
            p.contact = e.target.value;
            return { ...p };
          })
        }
      />
      <Input
        startDecorator="Email"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("email");
        }}
        type="email"
        fullWidth
        onBlur={() => save("email")}
        value={_payroll.email || ""}
        onChange={(e) =>
          _setPayroll((p) => {
            p.email = e.target.value;
            return { ...p };
          })
        }
      />
      <NerdPhoneField
        value={_payroll.phone}
        onAccept={(e) =>
          _setPayroll((c) => {
            c.phone = e;
            return { ...c };
          })
        }
        textFieldProps={{
          label: "Phone Number",
          fullWidth: true,
          disabled: isUpdating,
          size: "small",
          onBlur: () => save("phone"),
        }}
      />
      <Input
        startDecorator="Address"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("address");
        }}
        fullWidth
        onBlur={() => save("address")}
        value={_payroll.address || ""}
        onChange={(e) =>
          _setPayroll((p) => {
            p.address = e.target.value;
            return { ...p };
          })
        }
      />
      <Input
        startDecorator="Zip"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("zipcode");
        }}
        fullWidth
        onBlur={() => save("zipcode")}
        value={_payroll.zipcode || ""}
        onChange={(e) =>
          _setPayroll((p) => {
            p.zipcode = e.target.value;
            return { ...p };
          })
        }
      />
      <Input
        startDecorator="City"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("city");
        }}
        fullWidth
        onBlur={() => save("city")}
        value={_payroll.city || ""}
        onChange={(e) =>
          _setPayroll((p) => {
            p.city = e.target.value;
            return { ...p };
          })
        }
      />
      <Input
        startDecorator="State"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("state");
        }}
        fullWidth
        onBlur={() => save("state")}
        value={_payroll.state || ""}
        onChange={(e) =>
          _setPayroll((p) => {
            p.state = e.target.value;
            return { ...p };
          })
        }
      />
    </Box>
  );
}
