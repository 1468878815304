import { EntityState } from "@reduxjs/toolkit";
import { DanielsWork_Entity } from "entities/danielsWork";
import { librarianApi } from "redux/api/librarian";

export const danielsWorkEndpoints = librarianApi.injectEndpoints({
  endpoints: (build) => ({
    danielsWork: build.query<DanielsWork_Entity, number>({
      query: (fetchID) => {
        return {
          url: `pieces/danielsFetch`,
          params: {
            filters: JSON.stringify([
              { name: "fetchID", comparison: "eq", value: fetchID },
            ]),
          },
        };
      },
      providesTags: (result, error, id) => [{ type: "danielsWorks", id }],
    }),
  }),
});

export const { useDanielsWorkQuery } = danielsWorkEndpoints;

export default danielsWorkEndpoints;
