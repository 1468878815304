/**
 * @param noteTypeJson
 */

export default class NoteType_Entity {
  constructor(noteTypeJson: NoteType_Entity) {
    this.id = noteTypeJson.id;
    this.name = noteTypeJson.name;
    this.kind = noteTypeJson.kind;
    this.data = noteTypeJson.data;
    this.organizationID = noteTypeJson.organizationID;
    this.createdAt = noteTypeJson.createdAt;
    this.updatedAt = noteTypeJson.updatedAt;
  }

  id: number;
  name?: string;
  kind?: string;
  data?: string;
  organizationID?: number;
  createdAt: string;
  updatedAt: string;
}
