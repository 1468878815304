import SubRule_Entity from "./subRule";
export default class SubRule extends SubRule_Entity {
  static fromList(subRulesJSON: unknown): Array<SubRule> {
    const subRules: SubRule[] = [];
    if (subRulesJSON)
      Array.isArray(subRulesJSON) &&
        subRulesJSON.forEach((subRuleJSON) => {
          subRules.push(new SubRule(subRuleJSON));
        });
    return subRules;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
