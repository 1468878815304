export type AppConfigType = {
  api: AppConfigApiType;
  app: AppConfigAppType;
  companyInfo: AppConfigCompanyInfoType;
  theme: AppConfigThemeType;
  front: AppConfigFrontType;
  notification: AppConfigNotificationType;
  stripe: AppConfigStripeType;
};

type AppConfigStripeType = {
  mezzo: string;
  portal: string;
};

type AppConfigFrontType = {
  baseHostname: string;
  hostname: string;
};

type AppConfigApiType = {
  endpoint: string;
  port: number;
  production: boolean;
  routePrefix: string;
  signinRoute: string;
  signoutRoute: string;
  currentUser: string;
};

type AppConfigAppType = {
  piecesEnabled: boolean;
  alphaWatermark: boolean;
  favicon: string;
  logo: string;
  logoDarkMode: string;
  notFoundImage: string;
  showPLSignin: boolean;
  showForgotPassword: boolean;
  showSignup: boolean;
  rootBackground: string;
  title: string;
  serviceLevelLogo: { economy: string; premium: string; guaranteed: string };
  loadStatusLogo: {
    enRoute: string;
    processing: string;
    dropped: string;
    toDelivery: string;
    toPickup: string;
    delivered: string;
  };
  reeferLogo: string;
};

type AppConfigCompanyInfoType = {
  contact: string;
  name: string;
  support: string;
  website: string;
};

type AppConfigThemeType = {
  primaryColor: string;
  secondaryColor: string;
};

type AppConfigNotificationType = {
  success?: {
    variant?: "intrusive" | "non-intrusive";
    autohide?: boolean;
    autoHideDuration?: number;
  };
  error?: {
    variant?: "intrusive" | "non-intrusive";
    autohide?: boolean;
    autoHideDuration?: number;
  };
};

export const AppConfig = window._APP_CONFIG;
