import { Button, Textarea, Typography } from "@mui/joy";
import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { usePieces } from "redux/piece/pieceHooks";
import { useUpdateProjectPieceMutation } from "redux/projectPiece/projectPieceEndpoints";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ProjectPieceShorthand() {
  const open = useSelector(formOpenSelector("projectPieceShorthand"));
  const dispatch = useDispatch();
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const { projectPieces } = useProjectPieces(selectedProjectID);
  const [updateProjectPiece] = useUpdateProjectPieceMutation();

  const [descriptions, setDescriptions] = useState([]);

  const pieceIDs =
    projectPieces?.reduce((a, v) => {
      a.push(v.pieceID);
      return a;
    }, []) ?? [];

  const { piecesMap } = usePieces(
    {
      filters: JSON.stringify([
        {
          name: "pieces.id",
          value: pieceIDs,
          comparison: "in",
        },
      ]),
    },
    { skip: pieceIDs.length === 0 }
  );

  useEffect(() => {
    if (projectPieces.length)
      setDescriptions(
        projectPieces.reduce((a, v) => {
          a.push(v.description);
          return a;
        }, [])
      );
  }, [projectPieces]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectPieceShorthand" }));
  };

  const save = () => {
    descriptions.forEach((d, i) => {
      updateProjectPiece({
        id: projectPieces[i].id,
        body: { ...projectPieces[i], description: d },
      });
    });
    onClose();
  };

  return (
    <DialogClose maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-sharp fa-solid fa-music"></i>}
          level="h6"
        >
          Shorthand Description
        </Typography>
        <Typography level="body2">
          This info will be included in the call and communication.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        {descriptions?.map((d, i) => {
          const piece = piecesMap[projectPieces[i]?.pieceID];
          return (
            <Box key={i}>
              <Typography level="body2">
                {piece?.name ?? "Seating Arrangement"}, {piece?.composer}
              </Typography>
              <Textarea
                size="sm"
                placeholder="Ex: 4(3pic)3(1ca)3(1bcl)+bcl3+cbn / 4432 / timp.4perc / 2hp.pf.cel / str"
                value={d}
                variant="soft"
                minRows={2}
                maxRows={5}
                onChange={(e) =>
                  setDescriptions((r) => {
                    r[i] = e.target.value;
                    return [...r];
                  })
                }
                endDecorator={
                  <Typography level="body4">Visible by musicians.</Typography>
                }
              />
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}
