import { SET_CENTRIFUGO } from "constants/centrifugo";

export default (state = null, action) => {
  switch (action.type) {
    case SET_CENTRIFUGO:
      return action.centrifugo;

    default:
      return state;
  }
};
