import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { WorkSessionType_Entity } from "entities/workSessionType";
import { rhapsodyApi } from "../api/rhapsodyApi";

const workSessionTypesAdapter = createEntityAdapter<WorkSessionType_Entity>();
const initialState = workSessionTypesAdapter.getInitialState();

export const workSessionTypeEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkSessionTypes: build.query<EntityState<WorkSessionType_Entity>, void>(
      {
        query: () => `sessionTypes`,
        transformResponse: (responseData: WorkSessionType_Entity[]) => {
          return workSessionTypesAdapter.setAll(initialState, responseData);
        },
        providesTags: ["workSessionTypes"],
      }
    ),
    getWorkSessionType: build.query<WorkSessionType_Entity, number>({
      query: (id) => `workSessionTypes/${id}`,
      providesTags: (result, error, id) => [{ type: "workSessionTypes", id }],
    }),
    createWorkSessionType: build.mutation<
      WorkSessionType_Entity,
      Partial<WorkSessionType_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `sessionTypes`,
      }),
      invalidatesTags: ["workSessionTypes"],
    }),
    updateWorkSessionType: build.mutation<
      void,
      { id: number; body: Partial<WorkSessionType_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `sessionTypes/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "workSessionTypes", id },
        "workSessionTypes",
      ],
    }),
    deleteWorkSessionType: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `sessionTypes/${id}`,
      }),
      invalidatesTags: ["workSessionTypes"],
    }),
  }),
});

export const {
  useGetWorkSessionTypeQuery,
  useGetWorkSessionTypesQuery,
  useCreateWorkSessionTypeMutation,
  useDeleteWorkSessionTypeMutation,
  useUpdateWorkSessionTypeMutation,
} = workSessionTypeEndpoints;

export default workSessionTypeEndpoints;
