import { useSelector } from "react-redux";
import { meSelector } from "selectors/me";

export function useReadOnly(): boolean {
  const me = useSelector(meSelector);
  // return true;
  const readOnly = me?.services?.rhapsody?.indexOf("readOnly") >= 0;

  return readOnly;
}
