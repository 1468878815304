import { Link, Typography } from "@mui/joy";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import PiecesDataGrid from "features/dataGrids/PiecesDataGrid";
import ProjectsDataGrid from "features/dataGrids/ProjectsDataGrid";
import { SeasonUtils } from "features/seasons/SeasonUtils";
import { useRouteMatch } from "helpers";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import Calendar from "hooks/Calendar";
import { Missions } from "hooks/Missions/Missions";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Route, Link as RouterLink, Routes, useParams } from "react-router-dom";
import {
  selectedTagIDSelector,
  setSelectedProjectID,
  setSelectedTagID,
} from "reducers/rhapsody";
import { seasonUtilsSelector } from "reducers/v2/seasonControl";
import { useTag } from "redux/tag/tagHooks";
import { SeasonSettings } from "./seasonSettings";
import { AppConfig } from "environement";

export default function SeasonPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const tagID = parseInt(params.tagID);
  const selectedTagID = useSelector(selectedTagIDSelector);
  const { tag } = useTag(tagID);
  const seasonUtils = useSelector(seasonUtilsSelector);

  useEffect(() => {
    if (tagID && !selectedTagID) {
      dispatch(setSelectedTagID(tagID));
    }
    return () => {
      dispatch(setSelectedTagID());
      dispatch(setSelectedProjectID());
    };
  }, [tagID]);

  if (!tag) return <div />;

  const routeMatch = useRouteMatch([
    "/seasons/:id/projects",
    "/seasons/:id/calendar",
    "/seasons/:id/missions",
    // "/seasons/:id/links",
    "/seasons/:id/pieces",
    "/seasons/:id/settings",
  ]);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        flexDirection: "column",
        margin: "auto",
        maxWidth: 1600,
        height: "calc(100vh - 86px)",
      }}
    >
      <Helmet>
        <title>Rhapsody | {tag.name}</title>
      </Helmet>
      <SeasonUtils />
      <Box>
        <Link
          component={RouterLink}
          to={RouterConfig.projects}
          level="body1"
          underline="none"
          startDecorator={<i className="fa-solid fa-chevron-left"></i>}
        >
          Projects
        </Link>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography level="h5">{tag.name}</Typography>
        </Box>
        <MyTabs routeMatch={routeMatch} />
        <Divider />
      </Box>
      {seasonUtils ? (
        <Routes>
          <Route
            path={"projects"}
            element={<ProjectsDataGrid tagID={tagID} />}
          ></Route>
          <Route
            path={"calendar"}
            element={
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flex: 1,
                }}
              >
                <Calendar tagID={tagID} />
              </Box>
            }
          ></Route>
          <Route
            path={"pieces"}
            element={
              <Box
                sx={{ p: 2, display: "flex", gap: 2, flexDirection: "column" }}
              >
                <PiecesDataGrid tagID={tag.id} />
              </Box>
            }
          ></Route>
          {/* <Route
            path={"links"}
            element={
              <Box
                sx={{ p: 2, display: "flex", gap: 2, flexDirection: "column" }}
              >
                <LinksDataGrid tagID={tagID} />
              </Box>
            }
          ></Route> */}
          <Route path={"missions"} element={<Missions />}></Route>
          {}
          <Route path={"settings"} element={<SeasonSettings />}></Route>
        </Routes>
      ) : (
        []
      )}
    </Box>
  );
}

function MyTabs({ routeMatch }) {
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Tabs value={currentTab}>
      <Tab
        label="Projects"
        value="/seasons/:id/projects"
        to="projects"
        component={RouterLink}
      />
      <Tab
        label="Calendar"
        value="/seasons/:id/calendar"
        to="calendar"
        component={RouterLink}
      />
      <Tab
        label="Pieces"
        value="/seasons/:id/pieces"
        to="pieces"
        component={RouterLink}
      />
      {/* <Tab
        label="Links"
        value="/seasons/:id/links"
        to="links"
        component={RouterLink}
      /> */}
      {!AppConfig.api.endpoint.includes("live") ? (
        <Tab
          label="Missions"
          value="/seasons/:id/missions"
          to="missions"
          component={RouterLink}
        />
      ) : (
        []
      )}
      <Tab
        label="Settings"
        value="/seasons/:id/settings"
        to="settings"
        component={RouterLink}
      />
    </Tabs>
  );
}
