import { Button, Typography } from "@mui/joy";
import { Box, Paper } from "@mui/material";

import VenuesDataGrid from "features/dataGrids/VenuesDataGrid";
import { useDispatch } from "react-redux";
import { setFormOpen } from "reducers/rhapsody";
import { useVenues } from "redux/venue/venueHooks";

export default function Venues() {
  const dispatch = useDispatch();
  const { venues } = useVenues();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography level="h4" sx={{ fontWeight: 600 }}>
          Venues
        </Typography>
        <Typography level="body2">
          Now, let's add the venues you usually use for your work.
          <br />
          Could be a Studio, a Church, anything!
        </Typography>
        <Button
          variant="soft"
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "venue" }))
          }
        >
          + New Venue
        </Button>
        <Box
          sx={{
            height: "calc(100vh - 400px)",
            position: "relative",
            display: "flex",
          }}
        >
          {venues.length ? <VenuesDataGrid variant="simple" /> : []}
        </Box>
      </Paper>
    </Box>
  );
}
