import {
  Button,
  Card,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
} from "@mui/material";
import { Box } from "@mui/system";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Job } from "entities/job";
import { Musician } from "entities/musician";
import { Stage } from "entities/stage";
import { ChairAssignMusician } from "features/chair/chairAssignMusician";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useMercuryJob } from "redux/mercuryJob/mercuryJobHooks";
import { useMusician } from "redux/musician/musicianHooks";
import { useUpdateProjectHiringMutation } from "redux/projectHiring/projectHiringEndpoints";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { useWorkSession } from "redux/workSession/workSessionHooks";
import { useProjectActionsRequired } from "../useProjectHooks";
import { stageSentence } from "constants/workSessionTypeOptions";

/**
 *
 * @returns {ReactElement} ProjectActionRequired page
 */
export function ProjectActionRequired() {
  const { isLoading, hasActions, musicianJobs } = useProjectActionsRequired();
  const dispatch = useDispatch();
  const open = useSelector(formOpenSelector("projectActionRequired"));

  const onClose = () => {
    dispatch(setFormOpen({ formID: "projectActionRequired", isOpen: false }));
  };
  return (
    <DialogClose open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {musicianJobs.length} Action{musicianJobs.length > 1 ? "s" : ""}{" "}
        Required
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ background: "#F6F8FA" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {musicianJobs.map((j) => (
            <ActionCard j={j} key={j.musicianID} />
          ))}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}

function ActionCard({
  j,
}: {
  j: {
    musicianID: number;
    musician: Musician;
    mercuryStageID: number;
    stage: Stage;
    jobs: Job[];
  };
}) {
  const { mercuryJob, isLoading } = useMercuryJob(j.jobs[0]?.mercuryJobID);
  const { sectionsMap } = useSections();
  const { sectionRolesMap } = useSectionRoles();
  const musicianFeedback = mercuryJob?.getMusicianFeedback();

  return (
    <Card key={j.musicianID}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <MusicianAvatar musician={j.musician} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            flex: 1,
          }}
        >
          <Typography level="body2" noWrap>
            <b>{j.musician?.fullName()}</b>{" "}
            <Typography
              endDecorator={<img src={j.stage?.icon} height={12} />}
              level="body2"
              style={{ color: j?.stage?.color }}
            >
              <b>{stageSentence[j.mercuryStageID]}</b>
            </Typography>
            <br />
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography level="body4">
                {sectionsMap[j.jobs[0].sectionID]?.name}
              </Typography>
              <Typography
                startDecorator={<i className="fa-solid fa-chair"></i>}
                level="body4"
              >
                {j.jobs[0].order()}
              </Typography>
              <Typography level="body4">
                {sectionRolesMap[j.jobs[0].sectionRoleID]?.name}
              </Typography>
            </Box>
          </Typography>
          {isLoading ? <Skeleton height={30} /> : []}
          {musicianFeedback ? (
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  color: "white",
                  background: j?.stage?.color,
                  p: "2px",
                  pl: "4px",
                  pr: "4px",
                  borderRadius: "8px",
                }}
                level="body3"
              >
                {musicianFeedback}
              </Typography>
            </Box>
          ) : (
            []
          )}
          <RepaceWith jobs={j.jobs} />
        </Box>
      </Box>
    </Card>
  );
}

export function RepaceWith({
  jobs,
  size = "lg",
}: {
  jobs: Job[];
  size?: "sm" | "lg";
}) {
  const projectID = useSelector(selectedProjectIDSelector);
  const [updateProjectHiring, { isLoading }] = useUpdateProjectHiringMutation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [requestedMusicianID, setRequestedMusicianID] = useState<number>(null);
  const { musician: requestedMusician } = useMusician(requestedMusicianID);
  const [sessionIDs, setSessionIDs] = useState<{ [k: number]: boolean }>({});

  useEffect(() => {
    if (jobs.length) {
      setSessionIDs(
        jobs.reduce((a, j) => {
          a[j.sessionID] = true;
          return a;
        }, {})
      );
    }
  }, [jobs, dialogOpen]);

  const firstJob = jobs[0];
  let musician: Musician;
  if (firstJob?.musician) musician = new Musician(firstJob?.musician);

  useEffect(() => {
    if (!dialogOpen) {
      setSessionIDs({});
    }
  }, [dialogOpen]);

  const confirm = () => {
    const body = {
      sessionIDs: Object.keys(sessionIDs)
        .map((sessionID) =>
          sessionIDs[sessionID] ? parseInt(sessionID) : null
        )
        .filter((s) => s !== null),
      musicianIDs: [firstJob.musicianID],
      requestedMusicianID,
    };
    setDialogOpen(true);
    updateProjectHiring({ projectID, body });
  };

  return (
    <>
      <ChairAssignMusician
        onSelect={(e) => {
          setRequestedMusicianID(e);
          setDialogOpen(true);
        }}
        label={
          size === "lg" ? (
            "Replace"
          ) : (
            <i className="fa-solid fa-arrow-turn-down-right"></i>
          )
        }
        job={jobs[0]}
        iconButton={size == "sm"}
        button={size == "lg"}
        tooltip="Replace Musician"
        buttonProps={{
          size: "sm",
          color: "neutral",
          variant: "soft",
          startDecorator: <i className="fa-solid fa-arrow-turn-down-right"></i>,
        }}
      />
      <DialogClose
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {requestedMusician ? (
          <DialogContent>
            <Typography level="h6">You are about to replace:</Typography>
            <br />
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{
                border: "solid 1px rgba(155,155,155,0.3)",
                borderRadius: "16px",
                padding: 8,
              }}
            >
              <Grid item style={{ textAlign: "center" }}>
                <MusicianAvatar size={60} musician={musician} />
                <br />
                <Typography>{musician.fullName()}</Typography>
              </Grid>
              <Grid item style={{ textAlign: "center", padding: 16 }}>
                <Typography level="body2">
                  <b>BY</b>
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                <MusicianAvatar size={60} musician={requestedMusician} />
                <br />
                <Typography>
                  {requestedMusician.nickName ||
                    `${requestedMusician.firstName} ${requestedMusician.lastName}`}
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Typography level="body2">
              On the sessions{" "}
              {musician.nickName ||
                `${musician?.firstName} ${musician?.lastName}`}{" "}
              declined:
            </Typography>
            <br />
            <List
              style={{
                border: "solid 1px rgba(155,155,155,0.3)",
                borderRadius: "16px",
              }}
            >
              {jobs.map((j) => (
                <WorkSessionListItem
                  checked={sessionIDs[j.sessionID]}
                  onCheck={(e) => {
                    setSessionIDs((_s) => ({ ..._s, [j.sessionID]: e }));
                  }}
                  key={j.id}
                  workSessionID={j.sessionID}
                />
              ))}
            </List>
          </DialogContent>
        ) : (
          []
        )}
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="neutral"
            variant="soft"
          >
            Cancel
          </Button>
          <Button
            disabled={Object.keys(sessionIDs).length === 0}
            onClick={confirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </DialogClose>
    </>
  );
}

function WorkSessionListItem({
  workSessionID,
  checked,
  onCheck,
}: {
  workSessionID: number;
  checked: boolean;
  onCheck: (e: boolean) => void;
}) {
  const { workSession } = useWorkSession(workSessionID);
  return (
    <ListItem>
      <ListItemDecorator>
        <Checkbox
          variant="solid"
          checked={checked ?? false}
          onChange={(e) => onCheck(e.target.checked)}
        />
      </ListItemDecorator>
      <ListItemContent>{workSession?.title}</ListItemContent>
      <ListItemDecorator>
        <Typography level={"body3"}>
          {workSession?.formatDateRange()}
        </Typography>
      </ListItemDecorator>
    </ListItem>
  );
}
