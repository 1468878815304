import Assignment_Entity from "./assignment";
export default class Assignment extends Assignment_Entity {
  static fromList(assignmentsJSON: unknown): Array<Assignment> {
    const assignments: Assignment[] = [];
    if (assignmentsJSON)
      Array.isArray(assignmentsJSON) &&
        assignmentsJSON.forEach((assignmentJSON) => {
          assignments.push(new Assignment(assignmentJSON));
        });
    return assignments;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
