import { Assignment } from "entities/assignment";
import { useMemo } from "react";
import {
  useGetAssignmentQuery,
  useGetAssignmentsQuery,
  useGetTagAssignmentsQuery,
} from "./assignmentEndpoints";

export function useAssignment(assignmentID: number | undefined) {
  const assignmentQuery = useGetAssignmentQuery(assignmentID ?? 0, {
    skip: (assignmentID ?? 0) <= 0,
  });
  const assignmentEntity = assignmentQuery.data;

  return useMemo(() => {
    const ret: typeof assignmentQuery & {
      assignment?: Assignment | undefined;
    } = {
      ...assignmentQuery,
    };
    if (assignmentEntity) ret.assignment = new Assignment(assignmentEntity);
    return ret;
  }, [assignmentEntity]);
}

export function useTagAssignments(tagID) {
  const assignmentQuery = useGetTagAssignmentsQuery(tagID, { skip: !tagID });
  const assignmentEntitiesMap = assignmentQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof assignmentQuery & {
      assignments?: Assignment[];
      assignmentsMap?: { [id: number]: Assignment };
    } = {
      ...assignmentQuery,
      assignments: [],
      assignmentsMap: {},
    };
    if (assignmentEntitiesMap) {
      const assignments = [];
      const assignmentsMap = {};

      for (const key in assignmentEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(assignmentEntitiesMap, key)) {
          const c = assignmentEntitiesMap[key];
          const assignment = new Assignment(c);
          assignments.push(assignment);
          assignmentsMap[assignment.id] = assignment;
        }
      }
      ret.assignments = assignments;
      ret.assignmentsMap = assignmentsMap;
    }

    return ret;
  }, [assignmentEntitiesMap]);
}

export function useAssignments(projectID) {
  const assignmentQuery = useGetAssignmentsQuery(
    {
      filters: JSON.stringify([
        {
          name: "assignments.projectID",
          comparison: "eq",
          value: projectID,
        },
      ]),
    },
    { skip: !projectID }
  );
  const assignmentEntitiesMap = assignmentQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof assignmentQuery & {
      assignments?: Assignment[];
      assignmentsMap?: { [id: number]: Assignment };
    } = {
      ...assignmentQuery,
      assignments: [],
      assignmentsMap: {},
    };
    if (assignmentEntitiesMap) {
      const assignments = [];
      const assignmentsMap = {};

      for (const key in assignmentEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(assignmentEntitiesMap, key)) {
          const c = assignmentEntitiesMap[key];
          const assignment = new Assignment(c);
          assignments.push(assignment);
          assignmentsMap[assignment.id] = assignment;
        }
      }
      ret.assignments = assignments;
      ret.assignmentsMap = assignmentsMap;
    }

    return ret;
  }, [assignmentEntitiesMap]);
}

export function useAssignmentsFilter(params?: any, options?: any) {
  const assignmentQuery = useGetAssignmentsQuery(params, options);
  const assignmentEntitiesMap = assignmentQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof assignmentQuery & {
      assignments?: Assignment[];
      assignmentsMap?: { [id: number]: Assignment };
    } = {
      ...assignmentQuery,
      assignments: [],
      assignmentsMap: {},
    };
    if (assignmentEntitiesMap) {
      const assignments = [];
      const assignmentsMap = {};

      for (const key in assignmentEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(assignmentEntitiesMap, key)) {
          const c = assignmentEntitiesMap[key];
          const assignment = new Assignment(c);
          assignments.push(assignment);
          assignmentsMap[assignment.id] = assignment;
        }
      }
      ret.assignments = assignments;
      ret.assignmentsMap = assignmentsMap;
    }

    return ret;
  }, [assignmentEntitiesMap]);
}

export function useMusicianAssignments(musicianID) {
  const assignmentQuery = useGetAssignmentsQuery(
    {
      filters: JSON.stringify([
        {
          name: "assignments.musicianID",
          comparison: "eq",
          value: musicianID,
        },
      ]),
    },
    { skip: !musicianID }
  );
  const assignmentEntitiesMap = assignmentQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof assignmentQuery & {
      assignments?: Assignment[];
      assignmentsMap?: { [id: number]: Assignment };
    } = {
      ...assignmentQuery,
      assignments: [],
      assignmentsMap: {},
    };
    if (assignmentEntitiesMap) {
      const assignments = [];
      const assignmentsMap = {};

      for (const key in assignmentEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(assignmentEntitiesMap, key)) {
          const c = assignmentEntitiesMap[key];
          const assignment = new Assignment(c);
          assignments.push(assignment);
          assignmentsMap[assignment.id] = assignment;
        }
      }
      ret.assignments = assignments;
      ret.assignmentsMap = assignmentsMap;
    }

    return ret;
  }, [assignmentEntitiesMap]);
}
