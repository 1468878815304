import {
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  List,
  ListItemButton,
  Typography,
} from "@mui/joy";
import { Box } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useFamilies } from "redux/family/familyHooks";
import { useInstruments } from "redux/instrument/instrumentHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionInstruments } from "redux/sectionInstrument/sectionInstrumentHooks";

export function InstrumentManager({
  onSelect,
  sectionID,
  familyID,
  hideTitle,
  hideToolbar = false,
  instrumentIDs,
  readOnly,
}: {
  onSelect: (i: number[]) => void;
  sectionID?: number;
  familyID?: number;
  hideTitle?: boolean;
  hideToolbar?: boolean;
  instrumentIDs?: number[];
  readOnly: boolean;
}) {
  const { families, familiesMap } = useFamilies();
  const { sectionsMap, sections } = useSections();
  const { sectionInstruments } = useSectionInstruments();
  const { instruments } = useInstruments();
  const [searchText, setSearchText] = useState("");
  const [selectedFamilyID, setSelectedFamilyID] = useState<number>();
  const [selectedSectionID, setSelectedSectionID] = useState<number>();
  const [selectedInstruments, setSelectedInstruments] = useState<
    Dictionary<boolean>
  >({});

  useEffect(() => {
    if (instrumentIDs?.length) {
      const s = {};
      instrumentIDs.forEach((i) => {
        s[i] = true;
      });
      setSelectedInstruments(s);
    }
  }, [instrumentIDs]);

  useEffect(() => {
    {
      setSelectedSectionID(sectionID);
      const section = sectionsMap[sectionID];
      if (section?.familyID) setSelectedFamilyID(section?.familyID);
    }
  }, [sectionID, sectionsMap]);

  useEffect(() => {
    if (familyID) setSelectedFamilyID(familyID);
  }, [familyID]);

  const instrumentsIds = Object.keys(selectedInstruments)
    .filter((i) => selectedInstruments[i])
    .map((str) => parseInt(str));

  const handleOnSelect = () => {
    onSelect(instrumentsIds);
  };

  return (
    <>
      {!hideTitle ? <Typography level="h6">Instruments</Typography> : []}
      <Box>
        {/* {JSON.stringify(selectedInstruments)} */}
        {!readOnly ? (
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
            variant="soft"
            size="sm"
            autoFocus
            placeholder="Search"
            endDecorator={
              <IconButton
                tabIndex={-1}
                onClick={() => setSearchText("")}
                variant="plain"
                color="neutral"
              >
                <i className="fa-solid fa-xmark"></i>
              </IconButton>
            }
          />
        ) : (
          []
        )}
      </Box>
      {!hideToolbar ? (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pl: 1,
              pb: 1,
              overflow: "auto",
              flexShrink: 0,
            }}
          >
            {families.map((f) => (
              <Button
                tabIndex={-1}
                size="sm"
                onClick={() => setSelectedFamilyID(f.id)}
                sx={{ flexShrink: 0 }}
                color={selectedFamilyID === f.id ? "primary" : "neutral"}
                variant={selectedFamilyID === f.id ? "solid" : "soft"}
                key={f.id}
              >
                {f.name}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pl: 1,
              pb: 1,
              overflow: "auto",
              flexShrink: 0,
            }}
          >
            {sections
              .filter((s) => selectedFamilyID === s.familyID)
              .map((s) => (
                <Chip
                  tabIndex={-1}
                  size="sm"
                  onClick={() => setSelectedSectionID(s.id)}
                  sx={{ flexShrink: 0 }}
                  color={selectedSectionID === s.id ? "primary" : "neutral"}
                  variant={selectedSectionID === s.id ? "solid" : "soft"}
                  key={s.id}
                >
                  {s.name}
                </Chip>
              ))}
          </Box>
          <Divider />
        </>
      ) : (
        []
      )}
      <Box sx={{ flexGrow: 1, overflow: "auto", maxHeight: 600 }}>
        <List
          size="sm"
          sx={{
            position: "sticky",
            maxHeight: 150,
            overflow: "auto",
            top: -4,
            zIndex: 99,
          }}
        >
          {instruments
            .filter((i) => selectedInstruments[i.id])
            .map((i) => (
              <ListItemButton
                key={i.id}
                variant={selectedInstruments[i.id] ? "soft" : "plain"}
                color={selectedInstruments[i.id] ? "primary" : "neutral"}
                onClick={
                  !readOnly
                    ? () =>
                        setSelectedInstruments((_s) => ({
                          ..._s,
                          [i.id]: !(_s[i.id] ?? false),
                        }))
                    : undefined
                }
              >
                {`${i.name}`}
              </ListItemButton>
            ))}
        </List>
        <List size="sm">
          {instruments
            .filter((i) =>
              selectedFamilyID ? i.familyID === selectedFamilyID : true
            )
            .filter((i) => {
              if (!selectedSectionID) return true;
              const _sectionInstruments = sectionInstruments.filter(
                (si) => si.instrumentID === i.id
              );
              for (const key in _sectionInstruments) {
                if (
                  Object.prototype.hasOwnProperty.call(_sectionInstruments, key)
                ) {
                  const sectionInstrument = _sectionInstruments[key];
                  if (selectedSectionID === sectionInstrument.sectionID)
                    return true;
                }
              }
              return false;
            })
            .filter((i) =>
              searchText
                ? `${i.name} ${
                    familiesMap[i.familyID]?.name
                  } ${sectionInstruments
                    .filter((si) => si.instrumentID === i.id)
                    .reduce((a, i) => {
                      a.push(sectionsMap[i.sectionID]?.name);
                      return a;
                    }, [])
                    .join(" ")}`
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) >= 0
                : true
            )
            .filter((a) => !selectedInstruments[a.id])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((i) => {
              return (
                <ListItemButton
                  key={i.id}
                  variant={selectedInstruments[i.id] ? "soft" : "plain"}
                  color={selectedInstruments[i.id] ? "primary" : "neutral"}
                  onClick={
                    !readOnly
                      ? () =>
                          setSelectedInstruments((_s) => ({
                            ..._s,
                            [i.id]: !(_s[i.id] ?? false),
                          }))
                      : undefined
                  }
                >
                  {`${i.name}`}
                </ListItemButton>
              );
            })}
        </List>
      </Box>
      {!readOnly ? (
        <Box sx={{ mt: 1 }}>
          <Button size="sm" onClick={handleOnSelect} fullWidth>
            Save
          </Button>
        </Box>
      ) : (
        []
      )}
    </>
  );
}
