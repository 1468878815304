import { Menu, MenuItem, Typography } from "@mui/joy";
import { alpha } from "@mui/system";
import { useState } from "react";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";

export function ChairSectionRole({
  value,
  onChange,
  disabled,
}: {
  value: number;
  onChange: (id: number) => void;
  disabled?: boolean;
}) {
  const { sectionRolesMap, sectionRoles } = useSectionRoles();
  const [anchorEl, setAnchorEl] = useState();
  const open = anchorEl ? true : false;
  const role = sectionRolesMap[value];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (sectionRoleID: number) => {
    onChange(sectionRoleID);
    handleClose();
  };

  return (
    <>
      <Typography
        onClick={handleClick}
        level="body3"
        endDecorator={
          !disabled ? (
            <i style={{ fontSize: 8 }} className="fa-solid fa-chevron-down"></i>
          ) : (
            []
          )
        }
        sx={{
          pl: "2px",
          pr: "2px",
          borderRadius: "4px",
          cursor: !disabled ? "pointer" : undefined,
          "&:hover": {
            background: !disabled ? alpha("#9e9e9e", 0.2) : undefined,
          },
          "&:active": {
            background: !disabled ? alpha("#9e9e9e", 0.3) : undefined,
          },
        }}
      >
        {role?.name}
      </Typography>
      {!disabled ? (
        <Menu
          variant="outlined"
          size="sm"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ zIndex: 2000 }}
        >
          {sectionRoles.map((sectionRole) => (
            <MenuItem
              key={sectionRole.id}
              onClick={() => handleChange(sectionRole.id)}
            >
              {sectionRole.name}
            </MenuItem>
          ))}
        </Menu>
      ) : (
        []
      )}
    </>
  );
}
