import { Skeleton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useWorkSessionMercury } from "redux/workSessionMercury/workSessionMercuryHooks";

/**
 *
 * @returns {ReactElement} MercuryWorkSessionGlance page
 */
export function MercuryWorkSessionGlance({ workSessionID }) {
  const { workSessionMercury, isFetching, isLoading } =
    useWorkSessionMercury(workSessionID);

  if (isLoading || isFetching) return <Skeleton sx={{ width: 100 }} />;

  return (
    <Box style={{ display: "flex" }}>
      {workSessionMercury?.currentStages
        .filter((s) => s.jobCount)
        .map((s) => (
          <Tooltip title={`${s.jobCount} ${s.name}`} key={s.id}>
            <div
              key={s.id}
              style={{
                width: 10,
                height: 10,
                borderRadius: 10,
                marginRight: 4,
                background: s.color,
              }}
            />
          </Tooltip>
        ))}
    </Box>
  );
}
