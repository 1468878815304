import { Button, Typography } from "@mui/joy";
import { Box } from "@mui/system";

import PiecesDataGrid from "features/dataGrids/PiecesDataGrid";
import { heightWithToolbar } from "global";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { setFormOpen } from "reducers/rhapsody";

export default function PiecesPage() {
  const param = useParams();
  const dispatch = useDispatch();
  const readOnly = useReadOnly();
  if (param.pieceID) return <Outlet />;

  return (
    <>
      <Helmet>
        <title>Rhapsody | Pieces</title>
      </Helmet>
      <Box
        sx={{
          p: 2,
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "stretch",
          margin: "auto",
          maxWidth: 1600,
          width: "100%",
          position: "relative",
          flex: 1,
          height: heightWithToolbar,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography level="h5">Pieces</Typography>
          <Button
            disabled={readOnly}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "piece" }))
            }
            size="sm"
            startDecorator={<i className="fa-solid fa-plus"></i>}
          >
            Add Piece
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            height: 0,
            minHeight: "auto",
          }}
        >
          <PiecesDataGrid autoheight={false} />
        </Box>
      </Box>
    </>
  );
}
