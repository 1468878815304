import React from "react";
// import PropTypes from 'prop-types';

// styles
import { ArrowDropDown, PowerSettingsNew, Public } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import {
  Alert,
  Typography as JoyTypography,
  ListItemDecorator,
} from "@mui/joy";
import { setOrganizationByDefault, signout, stopImpersonate } from "actions";
import { REACT_APP_FRONT_BASE } from "config";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { meSelector } from "selectors/me";
import { AppConfig } from "environement";
import { Subscription } from "hooks/subscription/subscription";
import { useCurrentSubscription } from "redux/subscription/subscriptionHooks";
import { useMySubscription } from "hooks/subscription/restrictionHooks";

export default function MyAccount() {
  const [anchorEl, setAnchorEl] = React.useState();
  const [anchorOrganization, setAnchorOrganization] = React.useState();
  const me = useSelector(meSelector);
  const { subscription } = useCurrentSubscription();
  const sub = useMySubscription();
  const dispatch = useDispatch();

  let currentOrganization =
    me.organizations?.find((o) => o.id === me.organizationID)?.name ?? "None";

  return (
    <>
      <Chip
        size="small"
        style={{
          color: "white",
          background: me && me.impersonate ? "red" : "rgba(255,255,255,0.1)",
        }}
        onDelete={
          me && me.impersonate
            ? async () => {
                await dispatch(stopImpersonate());
                window.location.reload();
              }
            : undefined
        }
        onClick={(e) => setAnchorEl(e.currentTarget)}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 20,
            }}
          >
            {me && me.impersonate ? (
              <i
                class="fas fa-eye p-right"
                style={{
                  fontSize: 20,
                  marginTop: 2.8,
                  marginLeft: -4,
                }}
              ></i>
            ) : (
              <i
                class="fad fa-user-circle p-right"
                style={{
                  fontSize: 20,
                  marginTop: 2.8,
                  marginLeft: -4,
                }}
              ></i>
            )}

            <div style={{ lineHeight: 0.8 }}>
              <Typography
                display="block"
                variant="caption"
                style={{ lineHeight: 0.6, fontSize: 11 }}
              >
                <b>{`${me.firstName} ${me.lastName}`}</b>
              </Typography>
              {me.organizations?.length > 0 ? (
                <Typography
                  variant="caption"
                  style={{
                    fontSize: 9,
                    lineHeight: 0.6,
                    padding: 0,
                  }}
                >{`${currentOrganization}`}</Typography>
              ) : (
                []
              )}
            </div>
            {me && me.impersonate ? (
              []
            ) : (
              <i class="fas fa-caret-down p-left"></i>
            )}
          </div>
        }
      />
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl()}
      >
        <div style={{ width: 300, padding: 15, textAlign: "center" }}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              <Avatar style={{ width: 75, height: 75, margin: "auto" }} />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {me.firstName} {me.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {me.email}
              </Typography>
              {me && me.impersonate ? (
                <Chip
                  label={
                    <span>
                      <i class="fas fa-eye-slash p-right"></i>Stop Impersonate
                    </span>
                  }
                  size="small"
                  onClick={async () => {
                    await dispatch(stopImpersonate());
                    window.location.reload();
                  }}
                  style={{ background: "red", color: "white" }}
                />
              ) : (
                []
              )}
              <Grid item xs={12}>
                <Alert>
                  <JoyTypography
                    level="body2"
                    sx={{ alignItems: "center" }}
                    endDecorator={<Subscription dark />}
                  >
                    My Pricing Plan:
                  </JoyTypography>
                </Alert>
              </Grid>
              {me && me.organizations && me.organizations.length > 1 ? (
                <Grid item sx={{ mt: 1 }}>
                  <Chip
                    label={
                      me.organizations?.find((o) => o.id === me.organizationID)
                        ?.name ?? "None"
                    }
                    style={{
                      background: "rgba(155,155,155,0.3)",
                    }}
                    avatar={
                      <Avatar
                        style={{
                          background: "none",
                        }}
                      >
                        <Public />
                      </Avatar>
                    }
                    deleteIcon={<ArrowDropDown />}
                    onDelete={(e) => setAnchorOrganization(e.currentTarget)}
                    onClick={(e) => setAnchorOrganization(e.currentTarget)}
                  />
                  <Menu
                    id="simple-menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    anchorEl={anchorOrganization}
                    open={Boolean(anchorOrganization)}
                    style={{ maxHeight: 300 }}
                    onClose={() => setAnchorOrganization()}
                  >
                    {me &&
                      me.organizations &&
                      me.organizations.map((o) => (
                        <MenuItem
                          key={o.id}
                          dense
                          onClick={async () => {
                            await dispatch(setOrganizationByDefault(o.id));
                            window.location.reload();
                          }}
                          selected={o.id === me.organizationID}
                        >
                          {o.name}
                        </MenuItem>
                      ))}
                  </Menu>
                </Grid>
              ) : (
                []
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <List dense>
                <ListItem
                  dense
                  button
                  onClick={() => setAnchorEl()}
                  component={Link}
                  to={`${RouterConfig.myAccount}/credentials`}
                >
                  <ListItemIcon>
                    <i style={{ fontSize: 22 }} class="fad fa-key" />
                  </ListItemIcon>
                  <ListItemText primary={"Credentials"} />
                </ListItem>
                <ListItem
                  dense
                  button
                  component={Link}
                  onClick={() => setAnchorEl()}
                  to={`${RouterConfig.myAccount}/contractor-settings`}
                >
                  <ListItemIcon>
                    <i style={{ fontSize: 22 }} class="fad fa-id-badge" />
                  </ListItemIcon>
                  <ListItemText primary={"Contractor Settings"} />
                </ListItem>
                <ListItem
                  dense
                  button
                  component={Link}
                  onClick={() => setAnchorEl()}
                  to={`${RouterConfig.myAccount}/branding`}
                >
                  <ListItemIcon>
                    <i style={{ fontSize: 22 }} class="fa-duotone fa-palette" />
                  </ListItemIcon>
                  <ListItemText primary={"Branding"} />
                  {sub !== "finale" ? (
                    <ListItemDecorator>
                      <JoyTypography
                        sx={{
                          background: "#9c27b0",
                          color: "white",
                          pl: 0.5,
                          pr: 0.5,
                          borderRadius: 4,
                        }}
                        level="body4"
                      >
                        FINALE
                      </JoyTypography>
                    </ListItemDecorator>
                  ) : (
                    []
                  )}
                </ListItem>
                <ListItem
                  dense
                  button
                  component={Link}
                  onClick={() => {
                    setAnchorEl();
                  }}
                  to={`${RouterConfig.myAccount}/preferences`}
                >
                  <ListItemIcon>
                    <i style={{ fontSize: 22 }} class="fa-duotone fa-gear"></i>
                  </ListItemIcon>
                  <ListItemText primary={"Preferences"} />
                </ListItem>
                {/* <ListItem
                  dense
                  button
                  component={Link}
                  to={`${RouterConfig.myAccount}/statements`}
                >
                  <ListItemIcon>
                    <i style={{ fontSize: 22 }} class="fad fa-analytics" />
                  </ListItemIcon>
                  <ListItemText primary={"Statements"} />
                </ListItem>
                <ListItem
                  dense
                  button
                  component={Link}
                  to={`${RouterConfig.myAccount}/invoices`}
                >
                  <ListItemIcon>
                    <i
                      style={{ fontSize: 22 }}
                      class="fad fa-file-invoice-dollar"
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Invoices"} />
                </ListItem>
                <ListItem
                  dense
                  button
                  component={Link}
                  to={`${RouterConfig.myAccount}/payment-options`}
                >
                  <ListItemIcon>
                    <i
                      style={{ fontSize: 22 }}
                      class="fad fa-credit-card-front"
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Payment Options"} />
                </ListItem>
                <ListItem
                  dense
                  button
                  component={Link}
                  to={`${RouterConfig.myAccount}/imports`}
                >
                  <ListItemIcon>
                    <i style={{ fontSize: 22 }} class="fad fa-file-import" />
                  </ListItemIcon>
                  <ListItemText primary={"Imports"} />
                </ListItem> */}
                <ListItem
                  dense
                  button
                  component={Link}
                  to={`${RouterConfig.myAccount}/users`}
                >
                  <ListItemIcon>
                    <i style={{ fontSize: 22 }} class="fad fa-users" />
                  </ListItemIcon>
                  <ListItemText primary={"Team Members"} />
                </ListItem>
                <ListItem
                  dense
                  button
                  onClick={() => {
                    setAnchorEl();
                    window.open(
                      `${AppConfig.stripe.portal}?prefilled_email=${subscription.email}`,
                      "_blank"
                    );
                  }}
                >
                  <ListItemIcon>
                    <i style={{ fontSize: 22 }} class="fa-duotone fa-tags"></i>
                  </ListItemIcon>
                  <ListItemText primary={"Billing"} />
                </ListItem>
              </List>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                }}
                onClick={async () => {
                  await dispatch(signout());
                  window.location.replace(`${REACT_APP_FRONT_BASE}/signin`);
                }}
              >
                <PowerSettingsNew style={{ marginRight: 8, fontSize: 18 }} />{" "}
                Sign Out
              </Button>
              <br />
              {/* <Link
                component="button"
                variant="caption"
                color="textSecondary"
                href="www.rhapsody.la/policies"
              >
                Terms of Service
              </Link> */}
              <Typography color="textSecondary" variant="caption">
                v{window.app_info?.tag}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  );
}
