/**
 * Project_Entity type guard.
 *
 * @param {any} projectJson Project object from API
 * @returns {boolean} Return true if type is Project_Entity
 */

import { Tag_Entity } from "entities/tag";

export default class Project_Entity {
  constructor(projectJson: Project_Entity) {
    try {
      this.id = projectJson.id;
      this.name = projectJson.name;
      this.hideWhenNotPlaying = projectJson.hideWhenNotPlaying;
      this.description = projectJson.description;
      this.customerID = projectJson.customerID;
      this.organizationID = projectJson.organizationID;
      this.industryProjectNumber = projectJson.industryProjectNumber;
      this.label = projectJson.label;
      this.composer = projectJson.composer;
      this.contractor = projectJson.contractor;
      this.leader = projectJson.leader;
      this.agreement = projectJson.agreement;
      this.payrollID = projectJson.payrollID;
      this.archived = projectJson.archived;
      this.deleted = projectJson.deleted;
      this.ready = projectJson.ready;
      this.active = projectJson.active;
      this.logoURL = projectJson.logoURL;
      this.emailNotes = projectJson.emailNotes;
      this.generalNotes = projectJson.generalNotes;
      this.publicToken = projectJson.publicToken;
      this.configurationCode = projectJson.configurationCode;
      this.hideEmailLogo = projectJson.hideEmailLogo;
      this.hideRosterLogo = projectJson.hideRosterLogo;
      this.color = projectJson.color;
      this.mercuryEnabled = projectJson.mercuryEnabled;
      this.concierge = projectJson.concierge;
      this.template = projectJson.template;
      this.pipelineID = projectJson.pipelineID;
      this.tagRelationID = projectJson.tagRelationID;
      this.version = projectJson.version;
      this.wageAgreement = projectJson.agreement;
      this.wageUnit = projectJson.wageUnit;
      this.wageDescription = projectJson.wageDescription;
      // this.tags = projectJson.tags;
      // this.tagIDs = projectJson.tagIDs;
      this.autopilot = projectJson.autopilot;
      this.defaultTab = projectJson.defaultTab;
      this.customerName = projectJson.customerName;
      this.dressCode = projectJson.dressCode;
      // this.payroll = projectJson.payroll;
      this.createdAt = projectJson.createdAt;
      this.updatedAt = projectJson.updatedAt;
      this.tags = projectJson.tags;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id?: number;
  name?: string;
  description?: string;
  dressCode?: string;
  customerID?: number;
  hideWhenNotPlaying: boolean;
  organizationID?: number;
  industryProjectNumber?: string;
  wageAgreement: string;
  wageUnit: string;
  wageDescription: string;
  label?: string;
  composer?: string;
  contractor?: string;
  leader?: string;
  agreement?: string;
  payrollID?: number;
  archived?: boolean;
  deleted?: boolean;
  ready: boolean;
  active: boolean;
  logoURL?: string;
  emailNotes?: string;
  generalNotes?: string;
  publicToken?: string;
  configurationCode?: string;
  hideEmailLogo?: boolean;
  hideRosterLogo?: boolean;
  color?: string;
  mercuryEnabled?: boolean;
  concierge?: boolean;
  template?: boolean;
  pipelineID?: number;
  tagRelationID?: number;
  version?: number;
  // tags: Array<Tag>;
  // tagIDs: Array<int64>;
  autopilot: boolean;
  defaultTab: string;
  customerName?: string;
  createdAt: string;
  updatedAt: string;
  tags?: Tag_Entity[];
  // payroll: <NotFound>;
}
