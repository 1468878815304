/**
 * Payroll_Entity type guard.
 *
 * @param {any} payrollJson Payroll object from API
 * @returns {boolean} Return true if type is Payroll_Entity
 */

export default class Payroll_Entity {
  constructor(payrollJson: Payroll_Entity) {
    try {
      this.id = payrollJson.id;
      this.name = payrollJson.name;
      this.address = payrollJson.address;
      this.city = payrollJson.city;
      this.state = payrollJson.state;
      this.zipcode = payrollJson.zipcode;
      this.contact = payrollJson.contact;
      this.phone = payrollJson.phone;
      this.email = payrollJson.email;
      this.organizationID = payrollJson.organizationID;
      this.deleted = payrollJson.deleted;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id?: number;
  name?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  contact?: string;
  phone?: string;
  email?: string;
  organizationID?: number;
  deleted?: boolean;
}
