import { CircularProgress } from "@mui/joy";
import { Box } from "@mui/material";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHideApiErrors } from "reducers/rhapsody";
import { useCompanySettings } from "redux/company/companyHooks";

/**
 *
 * @returns {ReactElement} Authentication page
 */
export function Authentication() {
  const { isLoading } = useGetCurrentUserQuery();
  const settings = useCompanySettings();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHideApiErrors(settings.hideApiErrors ?? false));
  }, [settings]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        background: isLoading ? "rgba(255,255,255,0.3)" : "rgba(255,255,255,0)",
        backdropFilter: isLoading ? "blur(10px)" : "blur(0px)",
        transition: "backdrop-filter .3s, opacity .3s, background .3s",
        opacity: isLoading ? 1 : 0,
        zIndex: 999,
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
