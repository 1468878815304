import {
  Button,
  Checkbox,
  List,
  ListItemButton,
  Sheet,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Box } from "@mui/system";
import DialogClose from "atoms/DialogClose/DialogClose";
import Id from "atoms/Id/Id";
import { ProjectPiece_Entity } from "entities/projectPiece";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  retrieveInstrumentationForPieceIDSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useProjectsFilter } from "redux/project/projectHooks";
import {
  useProjectPiece,
  useProjectPiecesFilter,
} from "redux/projectPiece/projectPieceHooks";
import { useCreateChairsMutation } from "redux/rhapsodyChair/rhapsodyChairEndpoints";
import { useChairs } from "redux/rhapsodyChair/rhapsodyChairHooks";

export function RetrievePieceInstrumentation() {
  const [musicianOption, setMusicianOption] = useState(true);
  const [roleOption, setRoleOption] = useState(false);
  const [instrumentOption, setInstrumentOption] = useState(false);
  const [chairMemoOption, setChairMemoOption] = useState(false);
  const { workSessions } = useSelector(layoutUtilsSelector);
  const [retrieveProjectPiece, setRetrieveProjectPiece] =
    useState<ProjectPiece_Entity>();
  const { chairs } = useChairs(retrieveProjectPiece?.projectID);
  const chairsForPiece = chairs?.filter(
    (c) => c.projectPieceID === retrieveProjectPiece.id
  );
  const projectID = useSelector(selectedProjectIDSelector);
  const projectPieceID = useSelector(retrieveInstrumentationForPieceIDSelector);
  const { projectPiece } = useProjectPiece(projectPieceID);
  const open = useSelector(formOpenSelector("retrieveInstrumentationForPiece"));
  const dispatch = useDispatch();
  const pieceID = projectPiece?.pieceID;
  const [createChairs] = useCreateChairsMutation();
  const { projectPieces } = useProjectPiecesFilter(
    {
      filters: JSON.stringify([
        {
          name: "project_pieces.pieceID",
          value: pieceID,
          comparison: "eq",
        },
      ]),
    },
    { skip: !pieceID }
  );

  const { projectsMap } = useProjectsFilter(
    {
      filters: JSON.stringify([
        {
          name: "id",
          value: projectPieces.reduce((a, v) => {
            a.push(v.projectID);
            return a;
          }, []),
          comparison: "in",
        },
      ]),
    },
    { skip: !pieceID }
  );

  const retrieve = () => {
    const body = [];
    chairsForPiece.forEach((c) => {
      if (c.workSessionID) return;
      const item: RhapsodyChair_Entity = {
        ...c,
        chairCount: 1,
        projectPieceID: projectPiece.id,
        projectID,
      };
      delete item.id;
      delete item.assignmentID;
      if (!musicianOption) delete item.musicianID;
      if (!roleOption) delete item.sectionRoleID;
      if (!instrumentOption) delete item.instrumentIDs;
      if (!chairMemoOption) delete item.memo;
      body.push(item);
    });

    createChairs(body);
    openProgram();
  };

  const openProgram = () => {
    if (workSessions.length)
      dispatch(setFormOpen({ isOpen: true, formID: "projectProgram" }));
    onClose();
  };

  const onClose = () => {
    dispatch(
      setFormOpen({ formID: "retrieveInstrumentationForPiece", isOpen: false })
    );
  };

  const pp = projectPieces.filter((p) => p.projectID !== projectID);

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle>Retrieve Instrumentation</DialogTitle>
      <DialogContent>
        {pp.length === 0 ? (
          <Box>
            Looks like you have not used this Piece in a previous Project.
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography>
              Looks like you have already used this piece on the past. Would you
              like to retrieve the instrumentation?
            </Typography>
            <List variant="outlined" sx={{ maxHeight: 600, overflowY: "auto" }}>
              {pp.map((pp) => (
                <ListItemButton
                  variant={
                    retrieveProjectPiece?.projectID === pp.projectID
                      ? "soft"
                      : "plain"
                  }
                  selected={retrieveProjectPiece?.projectID === pp.projectID}
                  key={pp.id}
                  onClick={() => setRetrieveProjectPiece(pp)}
                >
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Id
                      startDecorator={
                        <i className="fa-solid fa-folder-open"></i>
                      }
                    >
                      {pp.projectID}
                    </Id>
                    <Box>
                      <Typography
                        endDecorator={
                          <Typography sx={{ color: "inherit" }} level="body4">
                            {moment(
                              projectsMap[pp.projectID]?.createdAt
                            ).format("ll")}
                          </Typography>
                        }
                        sx={{ color: "inherit" }}
                      >
                        {projectsMap[pp.projectID]?.name}
                      </Typography>
                      <Typography level="body3">{pp.description}</Typography>
                    </Box>
                  </Box>
                </ListItemButton>
              ))}
            </List>
            <Sheet variant="soft" sx={{ p: 1 }}>
              <Typography level="body4">Retrieve Options:</Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Checkbox
                    onChange={(e) => setMusicianOption(e.target.checked)}
                    checked={musicianOption}
                    size="sm"
                    label={<Typography level="body4">Musician</Typography>}
                  />
                </Grid>
                <Grid item>
                  <Checkbox
                    onChange={(e) => setRoleOption(e.target.checked)}
                    checked={roleOption}
                    size="sm"
                    label={<Typography level="body4">Role</Typography>}
                  />
                </Grid>
                <Grid item>
                  <Checkbox
                    onChange={(e) => setInstrumentOption(e.target.checked)}
                    checked={instrumentOption}
                    size="sm"
                    label={<Typography level="body4">Instruments</Typography>}
                  />
                </Grid>
                <Grid item>
                  <Checkbox
                    onChange={(e) => setChairMemoOption(e.target.checked)}
                    checked={chairMemoOption}
                    size="sm"
                    label={<Typography level="body4">Piece Memo</Typography>}
                  />
                </Grid>
              </Grid>
            </Sheet>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={pp.length ? openProgram : close}
          variant="soft"
          color="neutral"
        >
          {pp.length ? "No Thanks" : "Close"}
        </Button>
        {pp.length ? (
          <Button onClick={retrieve} disabled={!retrieveProjectPiece}>
            Retrieve{" "}
            {chairsForPiece.length
              ? `${chairsForPiece.length} chair${
                  chairsForPiece.length > 1 ? "s" : ""
                }`
              : ""}
          </Button>
        ) : (
          []
        )}
      </DialogActions>
    </DialogClose>
  );
}
