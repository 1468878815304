import {
  Box,
  Button,
  IconButton,
  Input,
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Musician } from "entities/musician";
import MusicianAvatar from "features/musicians/MusicianAvatar/MusicianAvatar";
import { ReactElement, useEffect, useState } from "react";
import { useMusicians } from "redux/musician/musicianHooks";
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function PickMusicians({
  open,
  onDismiss,
  multiple,
  onChange,
  values = [],
}: {
  open: boolean;
  onDismiss: () => void;
  multiple: boolean;
  onChange: (e: number[]) => void;
  values?: number[];
}) {
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState<Musician[]>([]);
  const { musicians, musiciansMap } = useMusicians({
    filters: JSON.stringify([
      {
        name: "musicians.firstName",
        comparison: "like",
        value: searchText.toLocaleLowerCase(),
        subFilters: [
          {
            name: "musicians.nickName",
            comparison: "like",
            value: searchText.toLocaleLowerCase(),
            orOperator: true,
          },
          {
            name: "musicians.lastName",
            comparison: "like",
            value: searchText.toLocaleLowerCase(),
            orOperator: true,
          },
        ],
      },
    ]),
  });

  const {
    musicians: existingMusicians,
    musiciansMap: existingMusiciansMap,
    requestId,
  } = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.id",
          comparison: "in",
          value: values,
        },
      ]),
    },
    { skip: values?.length === 0 }
  );

  useEffect(() => {
    if (existingMusicians.length) {
      const l = [];
      values.forEach((v) => l.push(existingMusiciansMap[v]));
      setList(l);
    }
  }, [existingMusicians]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(e: DragEndEvent) {
    const { active, over } = e;
    const activeID = parseInt(active.id.toString());
    const overID = parseInt(over.id.toString());
    const oldIndex = list?.findIndex((i) => i.id === activeID);
    const newIndex = list?.findIndex((i) => i.id === overID);
    setList(arrayMove([...list], oldIndex, newIndex));
  }

  return (
    <DialogClose open={open} onClose={onDismiss} disablePortal>
      <DialogTitle>
        {multiple ? "Pick Musicians" : "Select a Musician"}
      </DialogTitle>
      <DialogContent sx={{ display: "flex", gap: 1 }}>
        <Box sx={{ height: 600, width: 300, overflow: "auto" }}>
          <Input
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            size="sm"
            autoFocus
            color="neutral"
            variant="soft"
            placeholder="Search..."
            startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
            endDecorator={
              searchText ? (
                <IconButton
                  color="neutral"
                  variant="plain"
                  onClick={() => setSearchText("")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
              ) : (
                []
              )
            }
          />
          <List size="sm">
            {musicians
              ?.filter((m) => list?.findIndex((i) => i.id === m.id) === -1)
              .map((m) => (
                <Box
                  onClick={() => {
                    if (!multiple) {
                      onChange([m.id]);
                    } else {
                      const index = list?.findIndex((i) => i.id === m.id);
                      if (index === -1) {
                        setList((l) => [...l, m]);
                      }
                    }
                  }}
                  key={m.id}
                >
                  <MusicianMenuItem m={m} />
                </Box>
              ))}
          </List>
        </Box>
        {multiple ? (
          <Sheet
            variant="soft"
            sx={{ height: 600, width: 300, overflow: "auto" }}
          >
            <List size="sm">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={list ?? []}
                  strategy={verticalListSortingStrategy}
                >
                  {list?.map((m, i) => (
                    <SortableItem key={m.id} id={m.id}>
                      <Box
                        key={m.id}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography level="body2">{i + 1}.</Typography>

                        <MusicianMenuItem m={musiciansMap[m.id]} />
                        <IconButton
                          onClick={() => {
                            const index = list?.findIndex((i) => i.id === m.id);
                            const _list = [...list];
                            _list.splice(index, 1);
                            setList(_list);
                          }}
                          size="sm"
                          color="neutral"
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </IconButton>
                      </Box>
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
            </List>
          </Sheet>
        ) : (
          []
        )}
      </DialogContent>
      {multiple ? (
        <DialogActions>
          <Button
            onClick={() => {
              onChange(
                list.reduce((a, v) => {
                  a.push(v.id);
                  return a;
                }, [])
              );
            }}
          >
            Save
          </Button>
        </DialogActions>
      ) : (
        []
      )}
    </DialogClose>
  );
}

function MusicianMenuItem({ m }: { m: Musician }) {
  return (
    <ListItemButton
      key={m?.id}
      onClick={() => {
        // onSelect(m.id);
        // setOpen(false);
      }}
    >
      <ListItemDecorator sx={{ alignSelf: "flex-start" }}>
        <MusicianAvatar noBadge size={25} musician={m} />
      </ListItemDecorator>
      <ListItemContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            level="body3"
            startDecorator={
              m?.star ? (
                <i
                  className="fa-solid fa-heart"
                  style={{ color: "#e91e63" }}
                ></i>
              ) : (
                []
              )
            }
          >
            {m?.fullName()}
          </Typography>
          <Typography startDecorator={[]} level="body4">
            {m?.instrumentNames}
          </Typography>
        </Box>
        <Sheet sx={{ background: "rgba(255,235,59,0.1)", width: "auto" }}>
          <Typography level="body3">{m?.comments}</Typography>
        </Sheet>
      </ListItemContent>
    </ListItemButton>
  );
}

function SortableItem({
  id,
  children,
}: {
  id: number;
  children: ReactElement;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    cursor: "grab",
    transition,
  };

  return (
    <Box
      sx={{
        position: "relative",
        cursor: "default",
        alignItems: "center",
        display: "flex",
      }}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <i
        className="fa-solid fa-grip-dots-vertical"
        id="grab"
        style={{
          cursor: "grab",
          padding: 8,
          opacity: 0.5,
          fontSize: 14,
        }}
        {...listeners}
      ></i>
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Box>
  );
}
