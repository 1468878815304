import { show } from "@intercom/messenger-js-sdk";
import { Alert, Button, CircularProgress, Typography } from "@mui/joy";
import { Box } from "@mui/material";
import { ConfettiCenterContext } from "features/context/ConfettiCenter/ConfettiCenter";
import { heightWithToolbar } from "global";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useCreateSubscriptionMutation } from "redux/subscription/subscriptionEndpoints";

/**
 *
 * @returns {ReactElement} Checkout page
 */
export function Checkout() {
  const params = useParams();
  const checkoutID = params.checkoutID;
  const [createSubscription, { isLoading, isError, isSuccess, data }] =
    useCreateSubscriptionMutation();

  const confettiCenter = useContext(ConfettiCenterContext);

  useEffect(() => {
    if (checkoutID) {
      createSubscription({ id: checkoutID });
    }
  }, [checkoutID]);

  useEffect(() => {
    if (isSuccess) {
      confettiCenter.show();
    }
  }, [isSuccess]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: heightWithToolbar,
      }}
    >
      {isLoading ? <CircularProgress /> : []}
      {isError ? (
        <Alert color="danger">
          <div>
            <Typography fontWeight="lg" mt={0.25} color="danger">
              Something went wrong
            </Typography>
            <Typography
              fontSize="sm"
              level="body2"
              sx={{ opacity: 0.8 }}
              color="danger"
            >
              Looks like the operation didn't go through. Don't worry, we are
              here to assist you.
            </Typography>
            <Button
              sx={{ mt: 2 }}
              color="danger"
              onClick={() => {
                show();
              }}
            >
              Chat with us
            </Button>
          </div>
        </Alert>
      ) : (
        []
      )}
      {isSuccess && data?.id ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography level="display2">You are all set!</Typography>
          <Typography level="h6">
            Payment received! You've been upgraded to {data.productName}
          </Typography>
          <Button sx={{ mt: 2 }} component={Link} to={RouterConfig.root}>
            Continue
          </Button>
        </Box>
      ) : (
        []
      )}
    </Box>
  );
}
