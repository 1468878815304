import ProjectNote_Entity from "./projectNote";
export default class ProjectNote extends ProjectNote_Entity {
  static fromList(projectNotesJSON: unknown): Array<ProjectNote> {
    const projectNotes: ProjectNote[] = [];
    if (projectNotesJSON)
      Array.isArray(projectNotesJSON) &&
        projectNotesJSON.forEach((projectNoteJSON) => {
          projectNotes.push(new ProjectNote(projectNoteJSON));
        });
    return projectNotes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
