import {
  Button,
  ButtonProps,
  Divider,
  IconButton,
  Input,
  List,
  ListItemButton,
  ListItemContent,
  Tooltip,
  Typography,
} from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { Box } from "@mui/system";
import { Filters } from "@nerdjs/nerd-core";
import { Job_Entity } from "entities/job";
import { ProjectAlternate_Entity } from "entities/projectAlternate";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedProjectIDSelector,
  selectedWorkSessionIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  useCreateJobMutation,
  useUpdateBatchJobMutation,
} from "redux/job/jobEndpoints";
import { useWorkSessionActiveJobs } from "redux/job/jobHooks";
import { useProjectLayout } from "redux/layout/layoutHooks";
import { useProject, useTemplates } from "redux/project/projectHooks";
import {
  useCreateProjectAlternateMutation,
  useUpdateBatchProjectAlternateMutation,
} from "redux/projectAlternate/projectAlternateEndpoints";
import { useProjectAlternates } from "redux/projectAlternate/projectAlternateHooks";
import { useWorkSession } from "redux/workSession/workSessionHooks";

/**
 *
 * @returns {ReactElement} ProjectPieceAdder page
 */
export function Autofill({
  type = "project",
  onStatusChange,
  label,
  buttonProps,
}: {
  type?: "workSession" | "project";
  onStatusChange?: (s: "pending" | "success") => void;
  label?: string;
  buttonProps?: ButtonProps;
}) {
  const [open, setOpen] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selection, setSelection] = useState<number>();
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const { workSession } = useWorkSession(workSessionID);
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  let projectID;
  if (type === "project") {
    projectID = selectedProjectID;
  } else {
    projectID = workSession?.projectID;
  }

  const { project } = useProject(workSession?.projectID ?? projectID);
  const { layouts } = useProjectLayout(selection);

  const { projectAlternates: projectAlternatesForSelection } =
    useProjectAlternates(selection);

  const { projectAlternates } = useProjectAlternates(
    type === "project" ? projectID : undefined
  );

  const { jobs } = useWorkSessionActiveJobs(
    type === "workSession" ? workSessionID : undefined
  );

  const [createProjectAlternate, { isSuccess: s1 }] =
    useCreateProjectAlternateMutation();
  const [updateBatchProjectAlternate] =
    useUpdateBatchProjectAlternateMutation();

  const [createJob, { isSuccess: s2 }] = useCreateJobMutation();
  const [updateBatchJob] = useUpdateBatchJobMutation();

  const askQuestion = useAskQuestion();

  const filters: Filters = [
    {
      name: "template",
      comparison: "eq",
      value: true,
    },
  ];

  if (searchText) {
    filters.push({
      name: "name",
      comparison: "like",
      value: searchText.toLocaleLowerCase(),
    });
  }

  const { templates, templatesMap } = useTemplates({ filters });

  const dispatch = useDispatch();
  const proceedAutofill = s1 || s2 || proceed;

  useEffect(() => {
    if (selection) {
      setOpen(false);
      if (projectAlternates?.length === 0 && onStatusChange)
        onStatusChange("pending");
      if (type === "workSession" && jobs.length > 0) setProceed(true);
      if (type === "project" && projectAlternates.length > 0) {
        setProceed(true);
      }
    }
  }, [selection]);

  useEffect(() => {
    if (layouts?.length && selection) {
      if (type === "project" && projectAlternates.length === 0) createLayout();
      if (type === "workSession" && jobs.length === 0) createLayout();
    }
  }, [layouts, selection, projectAlternates, jobs]);

  useEffect(() => {
    if (
      proceedAutofill &&
      projectAlternates?.length &&
      projectAlternatesForSelection?.length
    ) {
      autofillProject();
      setProceed(false);
    }
  }, [proceedAutofill, projectAlternates, projectAlternatesForSelection]);

  useEffect(() => {
    if (
      proceedAutofill &&
      jobs?.length &&
      projectAlternatesForSelection?.length
    ) {
      autofillWorkSession();
      setProceed(false);
    }
  }, [proceedAutofill, jobs, projectAlternatesForSelection]);

  function createLayout() {
    const template = templatesMap[selection] ?? project;
    askQuestion("Confirmation", ["Cancel", "Confirm"], {
      subtitle: (
        <Typography>
          You are about to fill the empty Chairs based on{" "}
          <b>{template?.name}</b>.
        </Typography>
      ),
    }).then((i) => {
      if (i) {
        const body = [...layouts] as any;
        body.forEach((s) => (s.projectID = projectID));
        switch (type) {
          case "project":
            if (projectAlternates.length === 0) {
              body.forEach((s) => (s.projectID = projectID));
              createProjectAlternate({
                projectID,
                body: layouts,
              });
            }
            break;
          case "workSession":
            if (jobs.length === 0) {
              body.forEach((s) => (s.sessionID = workSessionID));
              createJob(body);
            }
            break;
          default:
            break;
        }
      } else {
        setSelection(null);
      }
    });
  }

  function autofillProject() {
    const body: Partial<ProjectAlternate_Entity>[] = [];
    projectAlternates.forEach((p) => {
      const model = projectAlternatesForSelection.find(
        (a) => a.sectionID === p.sectionID && a.sectionOrder === p.sectionOrder
      );
      if (model && !p.musicianID && (type === "workSession" ? p.prime : true)) {
        body.push({
          id: p.id,
          instrumentIDs: model.instrumentIDs,
          musicianID: model.musicianID,
          notes: model.notes,
          sectionRoleID: model.sectionRoleID,
        });
      }
    });
    updateBatchProjectAlternate(body);
    if (onStatusChange) onStatusChange("success");
    setSelection(null);
  }

  function autofillWorkSession() {
    const body: Partial<Job_Entity>[] = [];
    jobs.forEach((j) => {
      const model = projectAlternatesForSelection.find(
        (a) => a.sectionID === j.sectionID && a.sectionOrder === j.sectionOrder
      );
      if (model && !j.musicianID) {
        body.push({
          id: j.id,
          instrumentIDs: model.instrumentIDs,
          musicianID: model.musicianID,
          sectionRoleID: model.sectionRoleID,
          memo: model.notes,
        });
      }
    });
    updateBatchJob(body);
    if (onStatusChange) onStatusChange("success");
    setSelection(null);
  }

  return (
    <Tooltip
      arrow
      open={open}
      color="neutral"
      variant="outlined"
      title={
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Box
            sx={{
              width: 300,
              height: 400,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {projectAlternates && type === "workSession" ? (
              <>
                <Button
                  size="sm"
                  onClick={() => {
                    setSelection(projectID);
                  }}
                >
                  With Project's Seating
                </Button>
                <Divider>OR</Divider>
              </>
            ) : (
              []
            )}
            <Input
              value={searchText}
              autoFocus
              onChange={(e) => setSearchText(e.target.value)}
              startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
              endDecorator={
                <IconButton
                  color="neutral"
                  variant="plain"
                  onClick={(e) => setSearchText("")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
              }
              size="sm"
              variant="soft"
              placeholder="Search a Template..."
            />
            <Box
              sx={{
                flexGrow: 1,
                overflow: "auto",
                minHeight: "auto",
                height: 0,
                display: "flex",
                alignItems: "stretch",
              }}
            >
              {templates.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    No Templates
                  </Typography>
                  <Typography level="body3">
                    Autofill the empty chairs with a pre-filled instrumentation.
                    Would you like to create a Template?
                  </Typography>
                  <br />
                  <Button
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    startDecorator={<i className="fa-solid fa-plus"></i>}
                    onClick={() => {
                      setOpen(false);
                      dispatch(
                        setFormOpen({ isOpen: true, formID: "template" })
                      );
                    }}
                  >
                    Add Template
                  </Button>
                </Box>
              ) : (
                <Box sx={{ flex: 1 }}>
                  <List size="sm">
                    {templates
                      .filter((t) => t.version !== 2)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((p) => (
                        <ListItemButton
                          onClick={() => {
                            setSelection(p.id);
                            setOpen(false);
                          }}
                          key={p.id}
                        >
                          <ListItemContent>
                            <Typography sx={{ fontWeight: 600 }} level="body2">
                              {p.name}
                            </Typography>
                            <TemplateStructure templateID={p.id} />
                          </ListItemContent>
                        </ListItemButton>
                      ))}
                  </List>
                </Box>
              )}
            </Box>
          </Box>
        </ClickAwayListener>
      }
    >
      <Button
        size="sm"
        color="neutral"
        variant="soft"
        onClick={() => setOpen((s) => !s)}
        endDecorator={<i className="fa-solid fa-wand-magic-sparkles"></i>}
        startDecorator={<i className="fa-solid fa-plus"></i>}
        {...buttonProps}
      >
        {label ?? "Autofill"}
      </Button>
    </Tooltip>
  );
}

function TemplateStructure({ templateID }) {
  const { projectAlternates, isLoading } = useProjectAlternates(templateID);
  const prime = projectAlternates?.filter((a) => a.prime);
  const sub = projectAlternates?.filter((a) => !a.prime);
  if (isLoading) return <span />;

  return (
    <Typography level="body3">
      {prime.length} Musician{prime.length > 1 || prime.length === 0 ? "s" : ""}
      , {sub.length} Sub
      {sub.length > 1 || sub.length === 0 ? "s" : ""}
    </Typography>
  );
}
