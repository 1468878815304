export default class ProjectContact_Entity {
  constructor(projectContactJson: ProjectContact_Entity) {
    try {
      this.id = projectContactJson.id;
      this.projectID = projectContactJson.projectID;
      this.musicianID = projectContactJson.musicianID;
      this.role = projectContactJson.role;
      this.createdAt = projectContactJson.createdAt;
      this.updatedAt = projectContactJson.updatedAt;
    } catch (e) {
      console.log(
        "Failed to create new instance of ${ ProjectContact_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectID: number;
  musicianID: number;
  role: string;
  createdAt: string;
  updatedAt: string;
}
