import { MercuryJob } from "entities/mercuryJob";
import { useMemo } from "react";
import {
  useGetMercuryJobQuery,
  useGetMercuryJobsQuery,
  useGetMusicianMercuryJobsQuery,
  useGetWorkSessionMercuryJobsQuery,
} from "./mercuryJobEndpoints";

export function useMercuryJob(
  mercuryJobID: string | undefined,
  opt?: { skip: boolean }
) {
  const mercuryJobQuery = useGetMercuryJobQuery(mercuryJobID, {
    skip: !mercuryJobID || opt?.skip,
    refetchOnMountOrArgChange: true,
  });
  const mercuryJobEntity = mercuryJobQuery.data;

  return useMemo(() => {
    const ret: typeof mercuryJobQuery & {
      mercuryJob?: MercuryJob | undefined;
    } = {
      ...mercuryJobQuery,
    };
    if (mercuryJobEntity) ret.mercuryJob = new MercuryJob(mercuryJobEntity);
    return ret;
  }, [mercuryJobEntity]);
}

export function useMusicianMercuryJobs(musicianID: number | undefined) {
  const jobQuery = useGetMusicianMercuryJobsQuery(musicianID, {
    skip: (musicianID ?? 0) <= 0,
  });
  const jobEntitiesMap = jobQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof jobQuery & {
      mercuryJobs?: MercuryJob[];
      mercuryJobsMap?: { [uuid: string]: MercuryJob };
    } = {
      ...jobQuery,
      mercuryJobs: [],
      mercuryJobsMap: {},
    };
    if (jobEntitiesMap) {
      const mercuryJob = [];
      const mercuryJobMap = {};

      for (const key in jobEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(jobEntitiesMap, key)) {
          const c = jobEntitiesMap[key];
          const job = new MercuryJob(c);
          mercuryJob.push(job);
          mercuryJobMap[job.uuid] = job;
        }
      }
      ret.mercuryJobs = mercuryJob;
      ret.mercuryJobsMap = mercuryJobMap;
    }

    return ret;
  }, [jobEntitiesMap]);
}

export function useWorkSessionMercuryJobs(workSessionID: number | undefined) {
  const jobQuery = useGetWorkSessionMercuryJobsQuery(workSessionID, {
    skip: (workSessionID ?? 0) <= 0,
  });
  const jobEntitiesMap = jobQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof jobQuery & {
      mercuryJob?: MercuryJob[];
      mercuryJobMap?: { [uuid: string]: MercuryJob };
    } = {
      ...jobQuery,
      mercuryJob: [],
      mercuryJobMap: {},
    };
    if (jobEntitiesMap) {
      const mercuryJob = [];
      const mercuryJobMap = {};

      for (const key in jobEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(jobEntitiesMap, key)) {
          const c = jobEntitiesMap[key];
          const job = new MercuryJob(c);
          mercuryJob.push(job);
          mercuryJobMap[job.uuid] = job;
        }
      }
      ret.mercuryJob = mercuryJob;
      ret.mercuryJobMap = mercuryJobMap;
    }

    return ret;
  }, [jobEntitiesMap]);
}

export function useMercuryJobs() {
  const mercuryJobQuery = useGetMercuryJobsQuery();
  const mercuryJobEntitiesMap = mercuryJobQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof mercuryJobQuery & {
      mercuryJobs?: MercuryJob[];
      mercuryJobsMap?: { [id: number]: MercuryJob };
    } = {
      ...mercuryJobQuery,
      mercuryJobs: [],
      mercuryJobsMap: {},
    };
    if (mercuryJobEntitiesMap) {
      const mercuryJobs = [];
      const mercuryJobsMap = {};

      for (const key in mercuryJobEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(mercuryJobEntitiesMap, key)) {
          const c = mercuryJobEntitiesMap[key];
          const mercuryJob = new MercuryJob(c);
          mercuryJobs.push(mercuryJob);
          mercuryJobsMap[mercuryJob.id] = mercuryJob;
        }
      }
      ret.mercuryJobs = mercuryJobs;
      ret.mercuryJobsMap = mercuryJobsMap;
    }

    return ret;
  }, [mercuryJobEntitiesMap]);
}
