import { Button } from "@mui/joy";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import Id from "atoms/Id/Id";
import { useDispatch } from "react-redux";
import { setFormOpen, setSelectedLinkID } from "reducers/rhapsody";
import { useFamilies } from "redux/family/familyHooks";
import {
  useMusicianLinks,
  useProjectLinks,
  useTagLinks,
  useWorkSessionLinks,
} from "redux/link/linkHooks";
import { useSections } from "redux/section/sectionHooks";

export default function LinksDataGrid({
  projectID,
  workSessionID,
  musicianID,
  tagID,
}: {
  projectID?: number;
  workSessionID?: number;
  musicianID?: number;
  tagID?: number;
}) {
  const { links: projectLinks } = useProjectLinks(projectID);
  const { links: workSessionLinks } = useWorkSessionLinks(workSessionID);
  const { links: musicianLinks } = useMusicianLinks(musicianID);
  const { links: tagLinks } = useTagLinks(tagID);
  const { familiesMap: fMap } = useFamilies();
  const { sectionsMap: sMap } = useSections();

  let rows = [];
  if (projectID) rows = projectLinks;
  if (workSessionID) rows = workSessionLinks;
  if (musicianID) rows = musicianLinks;
  if (tagID) rows = tagLinks;

  const dispatch = useDispatch();

  const columns: any = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id startDecorator={<i className="fa-solid fa-paperclip"></i>}>
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      flex: 1,
    },
    {
      field: "url",
      headerName: "Download",
      align: "right",
      renderCell: (p) => (
        <Button
          size="sm"
          variant="soft"
          color="neutral"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.open(
              p.value.indexOf("http") >= 0 ? p.value : `https://${p.value}`
            );
          }}
          endDecorator={
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          }
        >
          Open Link
        </Button>
      ),
      type: "string",
      flex: 1,
    },
  ];

  if (!musicianID) {
    columns.push({
      field: "ties",
      headerName: "Who gets it",
      type: "string",
      flex: 1,
      valueGetter: getTieString(fMap, sMap),
    });
  }

  if (projectID && rows.length === 0) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "50vh", textAlign: "center" }}
      >
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            {" "}
            <i style={{ fontSize: 70 }} className="fa-solid fa-paperclip"></i>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            Links
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Add an external link or upload a file to share with your musicians.
            <br />
            Links can be included in your calls.
          </Typography>
          <br />
          <Button
            startDecorator={<i className="fa-solid fa-plus"></i>}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "link" }))
            }
            sx={{ mt: 1 }}
          >
            Add Link
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <DataGridPro
        autoHeight={tagID != undefined}
        onRowClick={(p) => {
          dispatch(setSelectedLinkID(p.row.id));
          dispatch(setFormOpen({ isOpen: true, formID: "link" }));
        }}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          noRowsOverlay: {
            icon: <i className="fa-solid fa-paperclip"></i>,
            title: "No Links",
            subtitle: musicianID
              ? "Save files for this musician (ex: 1099)"
              : "Attach links to your call",
            buttonText: "New Link",
            callback: () =>
              dispatch(setFormOpen({ isOpen: true, formID: "link" })),
          },
        }}
      />
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();

  return (
    <Box
      className="header"
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Box>
        <GridToolbarFilterButton />
      </Box>
      <Box sx={{ pt: 1, pr: 1 }}>
        <Button
          size="sm"
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "link" }))
          }
        >
          <i className="fa-solid fa-paperclip p-right"></i> New Link
        </Button>
      </Box>
    </Box>
  );
}

const getTieString = (fMap, sMap) => (p) => {
  if (p.row.global) return "Everybody";
  const ret = [];
  const _f = p.value.filter((e) => e.modelName === "family");
  const _s = p.value.filter((e) => e.modelName === "section");

  _f.forEach((e) => ret.push(fMap[e.modelID]?.name));
  _s.forEach((e) => ret.push(sMap[e.modelID]?.name));

  return ret.join(", ");
};
