import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { PdLabor_Entity } from "entities/pdLabor";
import { paydayApi } from "redux/api/paydayApi";

const pdLaborsAdapter = createEntityAdapter<PdLabor_Entity>();
const initialState = pdLaborsAdapter.getInitialState();

export const pdLaborEndpoints = paydayApi.injectEndpoints({
  endpoints: (build) => ({
    getPdLabors: build.query<EntityState<PdLabor_Entity>, void>({
      query: () => `pdLabors`,
      transformResponse: (responseData: PdLabor_Entity[]) => {
        return pdLaborsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["pdLabors"],
    }),
    getPdLabor: build.query<PdLabor_Entity, number>({
      query: (id) => `labors/${id}`,
      providesTags: (result, error, id) => [{ type: "pdLabors", id }],
    }),
    createPdLabor: build.mutation<PdLabor_Entity, Partial<PdLabor_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `labors`,
      }),
      invalidatesTags: ["pdLabors"],
    }),
    updatePdLabor: build.mutation<
      void,
      { id: number; body: Partial<PdLabor_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `labors/${args.id}`,
      }),
      invalidatesTags: ["pdLabors", "pdProjects"],
    }),
    deletePdLabor: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `labors/${id}`,
      }),
      invalidatesTags: ["pdLabors"],
    }),
  }),
});

export const {
  useGetPdLaborQuery,
  useGetPdLaborsQuery,
  useCreatePdLaborMutation,
  useDeletePdLaborMutation,
  useUpdatePdLaborMutation,
} = pdLaborEndpoints;

export default pdLaborEndpoints;
