import { WorkSessionProjectPiece } from "entities/workSessionProjectPiece";
import ProjectPiece_Entity from "./projectPiece";
export default class ProjectPiece extends ProjectPiece_Entity {
  static fromList(projectPiecesJSON: unknown): Array<ProjectPiece> {
    const projectPieces: ProjectPiece[] = [];
    if (projectPiecesJSON)
      Array.isArray(projectPiecesJSON) &&
        projectPiecesJSON.forEach((projectPieceJSON) => {
          projectPieces.push(new ProjectPiece(projectPieceJSON));
        });
    return projectPieces;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  workSessionProjectPieces(
    workSessionProjectPieces: WorkSessionProjectPiece[]
  ) {
    return workSessionProjectPieces.filter(
      (wpp) => wpp.projectPieceID === this.id
    );
  }
}
