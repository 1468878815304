import { Place } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { GOOGLE_MAPS_GEOCODING_KEY } from "config";
import { WorkSession } from "entities/workSession";
import GoogleMapReact from "google-map-react";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setFormOpen,
  setSelectedWorkSessionID,
  setShowMusiciansForChairs,
  setShowMusiciansForWorkSessionID,
} from "reducers/rhapsody";
import { usePieces } from "redux/piece/pieceHooks";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useChairs } from "redux/rhapsodyChair/rhapsodyChairHooks";
import { useVenue } from "redux/venue/venueHooks";
import { useWorkSessionProjectPieces } from "redux/workSessionProjectPiece/workSessionProjectPieceHooks";

/**
 *
 * @returns {ReactElement} JournalWorkSession page
 */
export function JournalWorkSession({
  workSession,
}: {
  workSession: WorkSession;
}) {
  const { venue } = useVenue(workSession.venueID);
  const finished = moment(workSession.dateToUTC).isBefore(moment());
  const started = moment(workSession.dateFromUTC).isBefore(moment());
  const progressing = started && !finished;
  const scheduled = !started && !finished;
  const { chairs } = useChairs(
    workSession?.projectVersion === 2 ? workSession.projectID : undefined
  );
  const dispatch = useDispatch();
  const { projectPiecesMap } = useProjectPieces(workSession.projectID);
  const { workSessionProjectPieces } = useWorkSessionProjectPieces(
    workSession.projectID
  );

  const pieceIDs =
    workSessionProjectPieces.reduce((a, v) => {
      if (v.workSessionID === workSession.id) {
        const projectPiece = projectPiecesMap[v.projectPieceID];
        if (!projectPiece) return;
        if (!a?.includes(projectPiece.pieceID)) {
          a?.push(projectPiece.pieceID);
        }
      }
      return a;
    }, []) ?? [];

  const { pieces } = usePieces(
    {
      filters: JSON.stringify([
        {
          name: "pieces.id",
          comparison: "in",
          value: pieceIDs,
        },
      ]),
    },
    { skip: workSession?.projectVersion !== 2 || pieceIDs?.length === 0 }
  );

  const pieceNames = pieces.reduce((a, v) => {
    a.push(`${v.name} ${v.composer}`);
    return a;
  }, []);

  let status = "";
  let color: "neutral" | "primary" | "danger" = "neutral";
  if (scheduled) {
    status = "Begins soon";
    color = "primary";
  }
  if (finished) {
    status = "Finished";
    color = "neutral";
  }
  if (progressing) {
    status = "In progress";
    color = "danger";
  }

  const chairsForWorkSession = chairs?.filter((c) =>
    c.workSessionIDs.includes(workSession.id)
  );

  const chairIDs = chairsForWorkSession?.reduce((a, v) => {
    if (!a.includes(v.id)) a.push(v.id);
    return a;
  }, []);

  const musicianIDs = chairsForWorkSession?.reduce((a, v) => {
    if (v.musicianID && !a.includes(v.musicianID)) a.push(v.musicianID);
    return a;
  }, []);

  const navigate = useNavigate();

  return (
    <Sheet
      variant="soft"
      color={color}
      sx={{
        borderRadius: "16px",
        height: "100%",
        p: 2,
        gap: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
          <Chip sx={{ background: "white" }} color={color} variant="outlined">
            {status}
          </Chip>
          {finished ? moment(workSession.dateToUTC).fromNow() : []}
          {progressing ? `ends ${moment(workSession.dateToUTC).fromNow()}` : []}
          {scheduled ? moment(workSession.dateFromUTC).fromNow() : []}
        </Box>
        <Typography level="body2">{workSession.formatDateRange()}</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        {venue ? (
          <Box sx={{ height: 150, width: 150 }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_MAPS_GEOCODING_KEY }}
              center={{
                lat: Number(venue?.latitude),
                lng: Number(venue?.longitude),
              }}
              defaultZoom={14}
            >
              <Place />
            </GoogleMapReact>
          </Box>
        ) : (
          []
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            level="body1"
            sx={{
              fontWeight: 600,
            }}
          >
            {workSession.title}
          </Typography>
          <Typography
            level="body3"
            startDecorator={<i className="fa-solid fa-folder-open"></i>}
          >
            {workSession.projectName}
          </Typography>

          <Typography
            level="body3"
            startDecorator={<i className="fa-solid fa-location-dot"></i>}
          >
            {venue?.name}
          </Typography>
          <Typography level="body3">{venue?.address}</Typography>
          <Typography level="body3">
            {venue?.zipcode}, {venue?.city} {venue?.state}
          </Typography>
          {pieces?.length ? (
            <>
              <Divider />
              <Box sx={{ display: "flex" }}>
                <Tooltip
                  title={
                    <>
                      {pieceNames.map((e) => (
                        <li key={e}>{e}</li>
                      ))}
                    </>
                  }
                  arrow
                  variant="outlined"
                  size="sm"
                >
                  <Typography
                    level="body2"
                    sx={{
                      fontWeight: 600,
                    }}
                    color={color}
                  >
                    Playing {pieces.length} Pieces
                  </Typography>
                </Tooltip>
              </Box>
            </>
          ) : (
            []
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          <Button
            color={color}
            fullWidth
            onClick={() => {
              dispatch(setShowMusiciansForChairs(chairIDs));
              dispatch(setShowMusiciansForWorkSessionID(workSession.id));
              dispatch(
                setFormOpen({ isOpen: true, formID: "showMusiciansForChairs" })
              );
            }}
          >
            {workSession?.projectVersion === 2
              ? `${musicianIDs?.length} Musicians`
              : `View Musicians`}
          </Button>
          <Button
            variant="outlined"
            sx={{ background: "white" }}
            color={color}
            fullWidth
            endDecorator={<i className="fa-solid fa-chevron-right"></i>}
            onClick={() => {
              if (workSession?.projectVersion === 2) {
                navigate(
                  `${RouterConfig.projects}/${workSession.projectID}/mission-control?workSessionID=${workSession?.id}`
                );
              } else {
                dispatch(setSelectedWorkSessionID(workSession.id));
              }
            }}
          >
            Open
          </Button>
        </Box>
      </Box>
    </Sheet>
  );
}
