import {
  Alert,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Button as JoyButton,
  Typography as JoyTypography,
  ListItemDecorator,
  Menu,
  MenuItem,
} from "@mui/joy";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";

import {
  ProjectAlternate,
  ProjectAlternate_Entity,
} from "entities/projectAlternate";
import RhapsodyChair from "features/chair/v1";
import { getInstrumentationsFromSections } from "helpers/section/section";
import { AddSection } from "hooks/AddSection/addSection";
import { Autofill } from "hooks/Autofill/Autofill";
import {
  Instrumentation,
  InstrumentationSelection,
} from "hooks/Instrumentation/v1";
import { Layout } from "hooks/Layout/v1";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedProjectIDSelector } from "reducers/rhapsody";
import { usePiece } from "redux/piece/pieceHooks";
import { useProject, useTemplates } from "redux/project/projectHooks";
import {
  useCreateProjectAlternateMutation,
  useUpdateBatchProjectAlternateMutation,
} from "redux/projectAlternate/projectAlternateEndpoints";
import { useProjectAlternates } from "redux/projectAlternate/projectAlternateHooks";
import {
  useProjectPiece,
  useProjectPieces,
} from "redux/projectPiece/projectPieceHooks";
import { useProjectPieceSections } from "redux/projectPieceSection/projectPieceSectionHooks";
import {
  useCreateProjectSectionMutation,
  useDeleteProjectSectionMutation,
} from "redux/projectSection/projectSectionEndpoints";
import { useProjectSections } from "redux/projectSection/projectSectionHooks";
import { useProjectWorkSessions } from "redux/workSession/workSessionHooks";
import { useCallSentForProject } from "../ProjectHiring/useProjectHiringDataGrid";
import { useAddProjectChairRequest } from "../useProjectHooks";
import { Section } from "hooks/Layout/types";
import { layoutSetReordering } from "reducers/layout";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";
import { useProjectHiringMusicians } from "redux/musician/musicianHooks";
import { Musician } from "entities/musician";
import { arrayToMap } from "helpers";

export default function ProjectAlternates({ id, template }) {
  const callSent = useCallSentForProject(id);
  const [templateStatus, setTemplateStatus] = useState<"pending" | "success">();
  const [createProjectAlternate, { isLoading }] =
    useCreateProjectAlternateMutation();
  const { templates } = useTemplates();

  const projectID = id || useSelector(selectedProjectIDSelector);
  const { projectHiring } = useProjectHiring(projectID);
  const { projectPieces, isLoading: projectPiecesLoading } =
    useProjectPieces(projectID);
  const { project } = useProject(projectID);
  const {
    projectSections,
    isLoading: l1,
    requestId,
  } = useProjectSections(projectID);
  const [deleteProjectSection] = useDeleteProjectSectionMutation();
  const {
    projectAlternates,
    projectAlternatesMap,
    isLoading: l2,
  } = useProjectAlternates(projectID);
  const {
    musiciansMap: projectHiringMusiciansMap,
    isLoading: musiciansMapLoading,
  } = useProjectHiringMusicians(projectID);
  const [createProjectSection] = useCreateProjectSectionMutation();
  const [updateBatchProjectAlternate] =
    useUpdateBatchProjectAlternateMutation();
  const handleAddChairs = useAddProjectChairRequest();
  const { projectWorkSessions: workSessions } =
    useProjectWorkSessions(projectID);

  const createProjectAlternates = (selection: InstrumentationSelection) => {
    const body = [];
    for (const sectionID in selection) {
      if (selection.hasOwnProperty(sectionID)) {
        const chairs = selection[sectionID];
        body.push({
          projectID,
          sectionID: parseInt(sectionID),
          ...chairs,
        });
      }
    }

    createProjectAlternate({ projectID, body });
  };

  function handleOrderChange(
    chairID: number,
    sectionID: number,
    newSectionOrder: number
  ) {
    const projectAltenatesForSection = JSON.parse(
      JSON.stringify(projectAlternates)
    )
      .filter((p) => p.sectionID === sectionID)
      .sort((a, b) => a.sectionOrder - b.sectionOrder);
    const draggedChair = projectAltenatesForSection.find(
      (p) => p.id === chairID
    );
    const oldSectionOrder = draggedChair.sectionOrder;

    const bodyArr: Partial<ProjectAlternate>[] = [];

    if (newSectionOrder > oldSectionOrder) {
      // Go Down
      const chairMap: Dictionary<ProjectAlternate_Entity> =
        projectAltenatesForSection.reduce((a, i) => {
          if (
            i.sectionOrder >= oldSectionOrder &&
            i.sectionOrder <= newSectionOrder
          ) {
            a[i.sectionOrder] = i;
          }
          return a;
        }, {});

      for (let index = oldSectionOrder; index <= newSectionOrder; index++) {
        const chair = chairMap[index];
        if (chair.id === chairID) {
          bodyArr.push({
            id: chairMap[newSectionOrder].id,
            musicianID: chair.musicianID,
          });
        } else {
          bodyArr.push({
            id: chairMap[index - 1].id,
            musicianID: chair.musicianID ?? -1,
          });
        }
      }

      // for (let index = oldSectionOrder; index < newSectionOrder; index++) {
      //   const element = array[index];
      // }
    } else {
      // Go UP
      const chairMap: Dictionary<ProjectAlternate_Entity> =
        projectAltenatesForSection.reduce((a, i) => {
          if (
            i.sectionOrder <= oldSectionOrder &&
            i.sectionOrder >= newSectionOrder
          ) {
            a[i.sectionOrder] = i;
          }
          return a;
        }, {});

      for (let index = newSectionOrder; index <= oldSectionOrder; index++) {
        const chair = chairMap[index];
        if (chair.id === chairID) {
          bodyArr.push({
            id: chairMap[newSectionOrder].id,
            musicianID: chair.musicianID,
          });
        } else {
          bodyArr.push({
            id: chairMap[index + 1].id,
            musicianID: chair.musicianID ?? -1,
          });
        }
      }
    }

    updateBatchProjectAlternate(bodyArr);
  }
  if (musiciansMapLoading) return <div />;
  if (!project) return <div />;

  if (projectPiecesLoading || l2)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
        <CircularProgress />
      </Box>
    );
  if (projectAlternates.length === 0 && !l2 && !projectPieces.length)
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "50vh", textAlign: "center" }}
      >
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            <i style={{ fontSize: 70 }} className="fad fa-chair"></i>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            Seating
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Edit your Seating by assigning Positions, Chairs, Alternates, Memos,
            and Call order.
          </Typography>
          <br />
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              startDecorator={<i className="fa-solid fa-plus"></i>}
              endDecorator={<i className="fa-duotone fa-chair"></i>}
              onClick={handleAddChairs}
              sx={{ mt: 1 }}
            >
              {projectPieces.length ? "Add Subs" : "Add Chairs"}
            </Button>
            <Instrumentation
              chairs={projectPieces?.length === 0}
              subs
              onAdd={createProjectAlternates}
            />
            <Box>
              {templates?.length > 0 ? (
                <>
                  <Divider>OR</Divider>
                  <Autofill
                    label="Use Template"
                    onStatusChange={(s) => setTemplateStatus(s)}
                  />
                </>
              ) : (
                []
              )}
            </Box>
          </Box>
          {projectHiring?.jobs?.length > 0 ? (
            <Alert color="warning">
              <Box>
                You are no longer able to add Chairs because you already added
                Work Sessions.
              </Box>
            </Alert>
          ) : (
            []
          )}
        </Grid>
      </Grid>
    );

  if (projectAlternates.length === 0 && !l2 && projectPieces.length)
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "50vh", textAlign: "center" }}
      >
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            <i style={{ fontSize: 70 }} className="fad fa-chair"></i>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            Seating
          </Typography>
          <Typography variant="body2" color="textSecondary">
            All done with your Pieces?
          </Typography>
          <BuildSeating />
          <Instrumentation subs onAdd={createProjectAlternates} />
        </Grid>
      </Grid>
    );

  const projectAlternateMusiciansMap = projectAlternates?.reduce((a, i) => {
    if (i.musician?.id) a[i.musician?.id] = new Musician(i.musician);
    return a;
  }, {});

  const musiciansMap = {
    ...projectHiringMusiciansMap,
    ...projectAlternateMusiciansMap,
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        flexDirection: "column",
        display: "flex",
        gap: 1,
        pr: 2,
      }}
    >
      {projectAlternates.length ? (
        <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <JoyButton
              startDecorator={<i className="fa-solid fa-plus"></i>}
              endDecorator={<i className="fa-duotone fa-chair"></i>}
              size="sm"
              onClick={handleAddChairs}
            >
              {projectPieces.length ? "Add Subs" : "Add Chairs"}
            </JoyButton>
            {projectPieces?.length === 0 ? (
              <AddSection
                existingSectionIDs={projectSections.reduce((a, i) => {
                  a.push(i.sectionID);
                  return a;
                }, [])}
                onSelect={(sectionID) =>
                  createProjectSection({ sectionID, projectID })
                }
              />
            ) : (
              []
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <MoreOptions />
            <Autofill onStatusChange={(s) => setTemplateStatus(s)} />
          </Box>
        </Box>
      ) : (
        []
      )}
      {callSent ? (
        <Alert
          startDecorator={
            <i className="fa-regular fa-triangle-exclamation"></i>
          }
          color="warning"
          sx={{ width: "100%" }}
        >
          The call for {project.name} has been sent. Edits to this page will NOT
          be reflected in the Hiring and Rosters tab, and will require
          additional communication to be sent to musicians.
        </Alert>
      ) : (
        []
      )}
      <Layout
        requestId={requestId}
        chairType={template ? "template" : "project"}
        layers={2}
        onCreateChairs={createProjectAlternates}
        enableCreateAltChairs
        onOrderChange={handleOrderChange}
        onDeleteSection={(id) => deleteProjectSection(id)}
        sections={projectSections}
        renderChair={({ chair, layer, emptyChairIndex, stagesMap }) => {
          const projectAlternate = chair as ProjectAlternate;
          return (
            <RhapsodyChair
              emptyChairIndex={emptyChairIndex}
              musician={musiciansMap[chair.musicianID]}
              layer={layer}
              stagesMap={stagesMap}
              chair={projectAlternatesMap[projectAlternate.id]}
              chairType="project"
              sub={!projectAlternatesMap[projectAlternate.id]?.prime}
            />
          );
        }}
      />
      <Instrumentation
        chairs={projectPieces?.length === 0}
        subs
        onAdd={createProjectAlternates}
      />
    </Box>
  );
}

function BuildSeating() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectPieces } = useProjectPieces(projectID);
  const [createProjectAlternate, { isLoading }] =
    useCreateProjectAlternateMutation();

  const [section, setSection] = useState<Dictionary<Section[]>>({});

  const handleBuildInstrumentation = () => {
    const selection = getInstrumentationsFromSections(section);

    const body = [];
    for (const sectionID in selection) {
      if (selection.hasOwnProperty(sectionID)) {
        const chairs = selection[sectionID];
        body.push({
          projectID,
          sectionID: parseInt(sectionID),
          ...chairs,
        });
      }
    }

    createProjectAlternate({ projectID, body });
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, m: 1 }}>
        {projectPieces.map((pp) => (
          <ProjectPiece
            key={pp.id}
            projectPieceID={pp.id}
            setSection={(s) => setSection((a) => ({ ...a, [pp.id]: s }))}
          />
        ))}
      </Box>
      <Button
        startDecorator={isLoading ? <CircularProgress /> : []}
        endDecorator={<i className="fa-solid fa-wand-magic-sparkles"></i>}
        onClick={handleBuildInstrumentation}
        sx={{ mt: 1 }}
      >
        Build Seating
      </Button>
    </Box>
  );
}

export function ProjectPiece({
  projectPieceID,
  setSection,
}: {
  projectPieceID: number;
  setSection: (s: Section[]) => void;
}) {
  const { projectPiece, isLoading: l1 } = useProjectPiece(projectPieceID);
  const { piece, isLoading: l2 } = usePiece(projectPiece?.pieceID);
  const { projectPieceSections, isLoading: l3 } = useProjectPieceSections(
    projectPiece?.id
  );

  useEffect(() => {
    setSection(projectPieceSections);
  }, [projectPieceSections]);

  if (l1 || l2 || l3) return <Skeleton height={80} />;

  let count = 0;
  projectPieceSections?.forEach((s) => (count += s.chairs?.length));

  return (
    <Card size="sm">
      <Box
        sx={{
          minWidth: 320,
          display: "flex",
          justifyContent: "space-between",
          textAlign: "left",
        }}
      >
        <Box>
          <JoyTypography sx={{ color: "black" }} level="body2">
            <b>{piece?.name}</b>
          </JoyTypography>
          <JoyTypography level="body3">{piece?.composer}</JoyTypography>
        </Box>
        <Chip
          color="neutral"
          variant="soft"
          startDecorator={<i className="fa-solid fa-chair"></i>}
          size="lg"
        >
          {count}
        </Chip>
      </Box>
    </Card>
  );
}

function MoreOptions() {
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement>();
  const projectID = useSelector(selectedProjectIDSelector);
  const {
    projectSections,
    projectSectionsMap,
    isLoading: l1,
    requestId,
  } = useProjectSections(projectID);
  const { project } = useProject(projectID);
  const dispatch = useDispatch();

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="sm"
        variant="outlined"
        color="neutral"
      >
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        size="sm"
        sx={{ maxWidth: 250 }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch(layoutSetReordering(true));
          }}
        >
          <ListItemDecorator>
            <i className="fa-solid fa-shuffle"></i>
          </ListItemDecorator>
          Reorder Musicians
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            downloadJSON(projectSections, project.name);
          }}
        >
          <ListItemDecorator>
            <i className="fa-duotone fa-file-export"></i>
          </ListItemDecorator>
          Export
        </MenuItem>
      </Menu>
    </>
  );
}

function downloadJSON(object, filename) {
  // Convert object to JSON
  const jsonStr = JSON.stringify(object, null, 2);

  // Create a blob object representing the data as a JSON
  const blob = new Blob([jsonStr], { type: "application/json" });

  // Create a link element
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");

  // Set the download name for the JSON and link it to the blob
  downloadLink.href = url;
  downloadLink.download = filename;

  // Trigger the download by simulating a click
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Cleanup by removing the link and revoking the object URL
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
}
