import { ProjectPiece } from "entities/projectPiece";
import { useMemo } from "react";
import {
  useGetProjectPieceQuery,
  useGetProjectPiecesQuery,
} from "./projectPieceEndpoints";

export function useProjectPiece(projectPieceID: number | undefined) {
  const projectPieceQuery = useGetProjectPieceQuery(projectPieceID ?? 0, {
    skip: (projectPieceID ?? 0) <= 0,
  });
  const projectPieceEntity = projectPieceQuery.data;

  return useMemo(() => {
    const ret: typeof projectPieceQuery & {
      projectPiece?: ProjectPiece | undefined;
    } = {
      ...projectPieceQuery,
    };
    if (projectPieceEntity)
      ret.projectPiece = new ProjectPiece(projectPieceEntity);
    return ret;
  }, [projectPieceEntity]);
}

export function useProjectPieces(projectID) {
  const projectPieceQuery = useGetProjectPiecesQuery(
    {
      filters: JSON.stringify([
        {
          name: "project_pieces.projectID",
          comparison: "eq",
          value: projectID,
        },
      ]),
    },
    { skip: !projectID }
  );
  const projectPieceEntitiesMap = projectPieceQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectPieceQuery & {
      projectPieces?: ProjectPiece[];
      projectPiecesMap?: { [id: number]: ProjectPiece };
    } = {
      ...projectPieceQuery,
      projectPieces: [],
      projectPiecesMap: {},
    };
    if (projectPieceEntitiesMap) {
      const projectPieces = [];
      const projectPiecesMap = {};

      for (const key in projectPieceEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(projectPieceEntitiesMap, key)
        ) {
          const c = projectPieceEntitiesMap[key];
          const projectPiece = new ProjectPiece(c);
          projectPieces.push(projectPiece);
          projectPiecesMap[projectPiece.id] = projectPiece;
        }
      }
      projectPieces.sort((a, b) => a.position - b.position);
      ret.projectPieces = projectPieces;
      ret.projectPiecesMap = projectPiecesMap;
    }

    return ret;
  }, [projectPieceEntitiesMap]);
}

export function useProjectPiecesFilter(args, opts) {
  const projectPieceQuery = useGetProjectPiecesQuery(args, opts);
  const projectPieceEntitiesMap = projectPieceQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectPieceQuery & {
      projectPieces?: ProjectPiece[];
      projectPiecesMap?: { [id: number]: ProjectPiece };
    } = {
      ...projectPieceQuery,
      projectPieces: [],
      projectPiecesMap: {},
    };
    if (projectPieceEntitiesMap) {
      const projectPieces = [];
      const projectPiecesMap = {};

      for (const key in projectPieceEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(projectPieceEntitiesMap, key)
        ) {
          const c = projectPieceEntitiesMap[key];
          const projectPiece = new ProjectPiece(c);
          projectPieces.push(projectPiece);
          projectPiecesMap[projectPiece.id] = projectPiece;
        }
      }
      projectPieces.sort((a, b) => a.position - b.position);
      ret.projectPieces = projectPieces;
      ret.projectPiecesMap = projectPiecesMap;
    }

    return ret;
  }, [projectPieceEntitiesMap]);
}
