import AppsIcon from "@mui/icons-material/Apps";
import { Popover } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
// material-ui
// custom
import App from "atoms/App";
import withRouter from "hooks/withRouter";
import PropTypes from "prop-types";
import { PureComponent } from "react";

class Apps extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    darkMode: PropTypes.bool,
    userApps: PropTypes.array,
    baseHostname: PropTypes.string,
  };

  state = {
    open: false,
  };

  handleOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { darkMode, userApps, baseHostname, navigate } = this.props;
    const { anchorEl } = this.state;

    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <IconButton onClick={this.handleOpen.bind(this)} size="large">
          {darkMode ? <AppsIcon /> : <AppsIcon style={{ color: "white" }} />}
        </IconButton>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorEl)}
          onClose={this.handleClose.bind(this)}
          anchorEl={anchorEl}
        >
          <div
            style={{
              width: 300,
              padding: 15,
            }}
          >
            <Grid container>
              {userApps?.map((a) => (
                <Grid key={a.id} item xs={3}>
                  <App {...a} key={a.id} size={50} withName />
                </Grid>
              ))}
            </Grid>
          </div>
        </Popover>
      </div>
    );
  }
}

export default withRouter(Apps);
