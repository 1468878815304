import {
  Alert,
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  List,
  ListItemButton,
  ListItemContent,
  Menu,
  MenuItem,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  ClickAwayListener,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import { Dictionary } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import Internal, { getInternal } from "entities/internal/helper";
import { ProjectPiece } from "entities/projectPiece";
import { RhapsodyChair, RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { ChairSectionRole } from "features/chair/chairSectionRole/v2";
import {
  CHAIR_WIDTH,
  ChairInstruments,
  PositionBase,
} from "features/chair/v2/position";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { AnimatedList } from "helpers/animation/animatedList";
import { useMySubscription } from "hooks/subscription/restrictionHooks";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setDescription420,
  setFormOpen,
} from "reducers/rhapsody";
import {
  instrumentationProjectPieceIDsSelector,
  instrumentationWorkSessionIDsSelector,
  layoutInternalSelector,
  layoutUtilsSelector,
  selectionSelector,
  setInstrumentationProjectPieceIDs,
  setInstrumentationWorkSessionIDs,
} from "reducers/v2/missionControl";
import { useCompanySettings } from "redux/company/companyHooks";
import { useFamilies } from "redux/family/familyHooks";
import {
  useCreateChairsMutation,
  useLazyGetChairsQuery,
} from "redux/rhapsodyChair/rhapsodyChairEndpoints";
import { useChairs } from "redux/rhapsodyChair/rhapsodyChairHooks";
import { useSections } from "redux/section/sectionHooks";
import {
  chairsForFamily,
  chairsForSection,
  templateChairsAll,
  templateChairsForFamily,
  templateChairsForSection,
  templateInputsAll,
  templateInputsForFamily,
  templateInputsForSection,
  UseTemplate,
  UseTemplateButton,
} from "./UseTemplate";
import { ImportFromDaniels } from "hooks/ImportFromDaniels/ImportFromDaniels";

/**
 *
 * @returns {ReactElement} Instrumentation page
 */

export function Instrumentation({ header }: { header?: ReactElement }) {
  const { families } = useFamilies();
  const { sections, sectionsMap } = useSections();
  const internal = useSelector(layoutInternalSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>();
  const [chairs, setChairs] = useState<
    Dictionary<Partial<RhapsodyChair_Entity>[]>
  >({});
  const settings = useCompanySettings();
  const [sectionInputs, setSectionInputs] = useState<Dictionary<number>>({});
  const [initailSectionInputs] = useState<Dictionary<number>>({});
  const open = useSelector(formOpenSelector("instrumentation"));
  const dispatch = useDispatch();
  const _instrumentationProjectPieceIDs = useSelector(
    instrumentationProjectPieceIDsSelector
  );
  const _instrumentationWorkSessionIDs = useSelector(
    instrumentationWorkSessionIDsSelector
  );
  const addChairs = useAddChairs();
  const [templateID, setTemplateID] = useState<number>();
  const utils = useSelector(layoutUtilsSelector);
  const {
    instrumentsMap,
    projectPiecesMap,
    projectPieces,
    sectionRoles,
    sectionRolesMap,
    piecesMap,
    workSessions,
  } = utils;
  const projectID = useSelector(selectedProjectIDSelector);
  const [selectedFamilyID, setSelectedFamilyID] = useState<number>();
  const [selectedSectionID, setSelectedSectionID] = useState<number>();
  const [searchText, setSearchText] = useState("");
  const total = [];
  const [getChairs] = useLazyGetChairsQuery();
  for (const key in chairs) {
    if (Object.hasOwnProperty.call(chairs, key)) {
      total.push(...chairs[key]);
    }
  }

  let instrumentationProjectPieceIDs = _instrumentationProjectPieceIDs;
  if (!instrumentationProjectPieceIDs) {
    instrumentationProjectPieceIDs = projectPieces.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []);
  }

  let instrumentationWorkSessionIDs = _instrumentationWorkSessionIDs;
  if (!instrumentationWorkSessionIDs) {
    instrumentationWorkSessionIDs = workSessions.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []);
  }

  const allWorkSessionsSelected =
    instrumentationWorkSessionIDs.length === workSessions.length;

  useEffect(() => {
    if (!open) {
      setChairs({});
      setSearchText("");
      setSectionInputs({});
    }
  }, [open]);

  useEffect(() => {
    if (families.length) {
      setSelectedFamilyID(families[0].id);
    }
  }, [families]);

  const exceptionWarning = (
    <Box
      sx={{
        position: "absolute",
        top: -26,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Alert
        variant="solid"
        color="danger"
        size="sm"
        sx={{
          p: 0.5,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Typography level="body3" sx={{ color: "white" }}>
          You are about to add chairs to the selected Project Pieces for only{" "}
          {instrumentationWorkSessionIDs.length} Work Session
          {instrumentationWorkSessionIDs.length > 1 ? "s" : ""}
        </Typography>
      </Alert>
    </Box>
  );

  // to display shorthand summary bellow the family name
  const getSummaryForFamily = (familyID: number) => {
    if (!internal) return "";
    const internalSections = internal?.sections.filter(
      (s) => s.familyID === familyID
    );
    const sectionNumberMap: Dictionary<number> = {};
    const sectionNumberArray = [];
    let ret = [];
    internalSections.forEach(
      (s) => (sectionNumberMap[s.sectionID] = s.visibleChairIDs.length)
    );

    for (const key in sectionInputs) {
      if (Object.hasOwnProperty.call(sectionInputs, key)) {
        if (sectionsMap[parseInt(key)]?.familyID !== familyID) continue;
        if (sectionNumberMap[key]) {
          sectionNumberMap[key] += sectionInputs[key];
        } else {
          sectionNumberMap[key] = sectionInputs[key];
        }
      }
    }

    for (const key in sectionNumberMap) {
      if (Object.hasOwnProperty.call(sectionNumberMap, key)) {
        if (sectionNumberMap[key] > 0)
          sectionNumberArray.push({
            pos: sectionsMap[parseInt(key)]?.pos,
            value: sectionNumberMap[key],
            sectionID: key,
          });
      }
    }

    sectionNumberArray.sort((a, b) => a.pos - b.pos);
    ret = sectionNumberArray.reduce((a, v) => {
      a.push(
        `${v.value}${
          sectionsMap[v.sectionID]?.shorthand ?? sectionsMap[v.sectionID]?.name
        }`
      );
      return a;
    }, []);

    return ret.join(", ");
  };

  const handleChange =
    (sectionID: number) =>
    (e: React.ChangeEvent<HTMLInputElement> | "plus" | "minus") => {
      let value = sectionInputs[sectionID] ?? 0;
      let diff = 0;
      if (typeof e !== "string") {
        let parsed = parseInt(e.target.value);
        if (parsed > 99) parsed = 99;
        if (isNaN(parsed)) parsed = 0;
        if (parsed >= (initailSectionInputs[sectionID] ?? 0)) {
          value = parsed;
          if (initailSectionInputs[sectionID]) {
            diff = parsed - (sectionInputs[sectionID] ?? 0);
          } else {
            diff =
              parsed -
              (initailSectionInputs[sectionID] ?? 0) -
              (sectionInputs[sectionID] ?? 0);
          }
        }
      } else {
        switch (e) {
          case "minus":
            value--;
            diff = -1;
            if (value < 0) value = 0;
            break;
          case "plus":
            diff = +1;
            value++;
            break;
        }
      }
      setChairs((e) => {
        if (!e[sectionID]) e[sectionID] = [];
        if (diff > 0) {
          for (let index = 0; index < diff; index++) {
            e[sectionID].push({
              sectionID,
              sectionRoleID: 4,
              workSessionIDs: [],
              id: -1,
              projectID,
              instrumentIDs: sectionsMap[sectionID].defaultInstrumentID
                ? JSON.stringify([sectionsMap[sectionID].defaultInstrumentID])
                : "[]",
            });
          }
        }
        if (diff < 0) {
          e[sectionID].splice(e[sectionID].length + diff, -diff);
        }
        return e;
      });
      setSectionInputs((e) => ({ ...e, [sectionID]: value }));
    };

  const onClose = () => {
    dispatch(setFormOpen({ formID: "instrumentation", isOpen: false }));
    dispatch(setInstrumentationProjectPieceIDs(null));
    dispatch(setInstrumentationWorkSessionIDs(null));
  };

  const notDefaultStrings =
    settings.defaultStrings?.reduce((a, v, i) => {
      if (sectionInputs[i + 1] !== v) a = true;
      return a;
    }, false) ?? false;

  const sameAs = (projectPiece: ProjectPiece) => {
    setAnchorEl(null);
    const _chairs: RhapsodyChair_Entity[] = JSON.parse(
      JSON.stringify(
        utils.chairs.filter(
          (c) => c.projectPieceID == projectPiece.id && !c.workSessionID
        )
      )
    ).sort((a, b) => a.sectionOrder - b.sectionOrder);
    const _sectionInputs: Dictionary<number> = {};
    const ret: Dictionary<Partial<RhapsodyChair_Entity>[]> = {};

    _chairs.forEach((c) => {
      const item = {
        sectionID: c.sectionID,
        instrumentIDs: c.instrumentIDs,
        sectionRoleID: c.sectionRoleID,
        sectionOrder: c.sectionOrder,
        projectID,
        workSessionIDs: [],
      };
      if (!ret[c.sectionID]) {
        _sectionInputs[c.sectionID] = 1;
        ret[c.sectionID] = [item];
      } else {
        ret[c.sectionID].push(item);
        _sectionInputs[c.sectionID]++;
      }
    });

    setChairs(ret);
    setSectionInputs(_sectionInputs);
  };

  return (
    <DialogClose
      open={open}
      maxWidth="md"
      fullWidth
      classes={{
        paper: "dialog-paper",
      }}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 0,
          position: "relative",
        }}
      >
        {!allWorkSessionsSelected ? exceptionWarning : []}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            alignItems: "start",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}
          >
            <Box>
              <Typography level="h6">Instrumentation</Typography>
              {instrumentationProjectPieceIDs.length ===
              projectPieces.length ? (
                <Typography color="primary" level="body2">
                  For the full Ensemble (all Pieces)
                </Typography>
              ) : (
                <>
                  {instrumentationProjectPieceIDs.length > 4 ? (
                    <Typography color="primary" level="body2">
                      For {instrumentationProjectPieceIDs.length} Piece
                      {instrumentationProjectPieceIDs.length > 1 ? "s" : ""}
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        overflow: "auto",
                        flex: 1,
                        maxWidth: 620,
                      }}
                    >
                      {instrumentationProjectPieceIDs.map((pp) => {
                        const piece = piecesMap[projectPiecesMap[pp].pieceID];
                        return (
                          <Chip
                            endDecorator={
                              piece?.composer ? (
                                <Typography
                                  sx={{ ml: "2px", borderRadius: 12 }}
                                  color="primary"
                                  variant="soft"
                                  level="body4"
                                >
                                  {piece?.composer}
                                </Typography>
                              ) : undefined
                            }
                            size="sm"
                            variant="outlined"
                            color="primary"
                            key={pp}
                          >
                            {piece?.name ?? "Seating assignment"}
                          </Chip>
                        );
                      })}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            {projectPieces.length > 1 ? (
              <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                size="sm"
                color="neutral"
                variant="plain"
                endDecorator={<i className="fa-solid fa-chevron-down"></i>}
              >
                Same Instrumentation as
              </Button>
            ) : (
              []
            )}
            <Menu
              disablePortal
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {projectPieces.map((pp) => (
                <MenuItem key={pp.id} onClick={() => sameAs(pp)}>
                  <Box>
                    <Typography sx={{ fontWeight: 600 }} level="body1">
                      {piecesMap[pp.pieceID]?.name ?? "Seating Assignment"}
                    </Typography>
                    <Typography level="body2">
                      {piecesMap[pp.pieceID]?.composer ?? "Seating Assignment"}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
        {header}
        <Box sx={{ pl: 1, pr: 1, pb: 1 }}>
          <Input
            value={searchText}
            startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
            endDecorator={
              <IconButton
                onClick={() => setSearchText("")}
                color="neutral"
                variant="plain"
              >
                <i className="fa-solid fa-xmark"></i>
              </IconButton>
            }
            onChange={(e) => setSearchText(e.target.value)}
            size="sm"
            variant="soft"
            placeholder="Search..."
          />
        </Box>
        {!searchText ? (
          <Box>
            <Divider />
            <Box
              sx={{
                pl: 2,
                pr: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography level="body3" sx={{ width: 160 }}>
                Families
              </Typography>
              <Divider orientation="vertical" />
              <Typography level="body3" sx={{ flexGrow: 1, ml: 2 }}>
                Sections
              </Typography>
              <Divider orientation="vertical" />
              <Typography
                level="body3"
                sx={{
                  ml: 2,
                  width: CHAIR_WIDTH,
                }}
              >
                Chairs
              </Typography>
            </Box>
          </Box>
        ) : (
          []
        )}
        <Divider sx={{ m: 0 }} />
      </DialogTitle>
      <DialogContent sx={{ p: 0, height: 600, display: "flex", mt: 0 }}>
        {!searchText ? (
          <Box
            sx={{
              minHeight: "auto",
              height: "100%",
              overflowY: "auto",
              width: 176,
            }}
          >
            <List sx={{ pt: 0 }}>
              {families.map((f) => {
                return (
                  <ListItemButton
                    color={"neutral"}
                    selected={selectedFamilyID === f.id}
                    variant={selectedFamilyID === f.id ? "soft" : "plain"}
                    onClick={() => setSelectedFamilyID(f.id)}
                    key={f.id}
                  >
                    <ListItemContent
                      sx={{
                        minHeight: 32,
                        fontWeight: selectedFamilyID === f.id ? 700 : undefined,
                        color:
                          selectedFamilyID === f.id ? "#4186DE" : undefined,
                      }}
                    >
                      <Typography
                        // startDecorator={
                        //   templateID && count.length > 0 ? (
                        //     <UseTemplateButton
                        //       onClick={() => {
                        //         setSectionInputs((e) => ({
                        //           ...e,
                        //           ...templateInputsForFamily(
                        //             f,
                        //             sections,
                        //             templateChairs
                        //           ),
                        //         }));
                        //         setChairs((e) => ({
                        //           ...e,
                        //           ...templateChairsForFamily(
                        //             f,
                        //             sections,
                        //             templateChairs
                        //           ),
                        //         }));
                        //       }}
                        //     />
                        //   ) : (
                        //     []
                        //   )
                        // }
                        level="body1"
                      >
                        {f.name}
                      </Typography>
                      <Typography sx={{ color: "inherit" }} level="body4">
                        {getSummaryForFamily(f.id)}
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
        ) : (
          []
        )}
        <Divider orientation="vertical" />
        <Box
          sx={{
            flexGrow: 1,
            minHeight: "auto",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <List sx={{ pt: 0 }}>
            {(searchText
              ? sections.filter(
                  (s) =>
                    s.name.toLowerCase().indexOf(searchText.toLowerCase()) !==
                    -1
                )
              : sections.filter((s) => s.familyID === selectedFamilyID)
            ).map((s) => {
              let variant: "soft" | "solid" = "soft";
              let color: "neutral" | "primary" = "neutral";

              if (initailSectionInputs[s.id] || sectionInputs[s.id])
                color = "primary";
              if (
                sectionInputs[s.id] &&
                initailSectionInputs[s.id] !== sectionInputs[s.id]
              )
                variant = "solid";
              return (
                <ListItemButton
                  key={s.id}
                  color={"neutral"}
                  selected={selectedSectionID === s.id}
                  variant={selectedSectionID === s.id ? "soft" : "plain"}
                  onClick={() => setSelectedSectionID(s.id)}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <ListItemContent
                      sx={{
                        fontWeight:
                          selectedSectionID === s.id ? 700 : undefined,
                        color:
                          selectedSectionID === s.id ? "#4186DE" : undefined,
                      }}
                    >
                      <Typography
                        // startDecorator={
                        //   templateID && count.length > 0 ? (
                        //     <UseTemplateButton
                        //       onClick={() => {
                        //         setSectionInputs((e) => ({
                        //           ...e,
                        //           ...templateInputsForSection(
                        //             s,
                        //             templateChairs
                        //           ),
                        //         }));
                        //         setChairs((e) => ({
                        //           ...e,
                        //           ...templateChairsForSection(
                        //             s,
                        //             templateChairs
                        //           ),
                        //         }));
                        //       }}
                        //     />
                        //   ) : (
                        //     []
                        //   )
                        // }
                        level="body1"
                      >
                        {s.name}
                      </Typography>

                      {searchText ? (
                        <Typography level="body4">{s.familyName}</Typography>
                      ) : (
                        []
                      )}
                    </ListItemContent>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Input
                      value={sectionInputs[s.id] ?? "0"}
                      slotProps={{
                        input: {
                          style: { textAlign: "center" },
                        },
                      }}
                      onChange={handleChange(s.id)}
                      onFocus={(event) => {
                        event.target.select();
                        setTimeout(() => {
                          event.target.select();
                        }, 200);
                      }}
                      type="tel"
                      startDecorator={
                        <IconButton
                          color="neutral"
                          disabled={
                            (sectionInputs[s.id] ?? 0) <=
                            (initailSectionInputs[s.id] ?? 0)
                          }
                          onClick={() => handleChange(s.id)("minus")}
                        >
                          <i className="fa-solid fa-minus"></i>
                        </IconButton>
                      }
                      endDecorator={
                        <IconButton
                          color="neutral"
                          onClick={() => handleChange(s.id)("plus")}
                        >
                          <i className="fa-solid fa-plus"></i>
                        </IconButton>
                      }
                      size="sm"
                      variant={variant}
                      color={color}
                      sx={{
                        width: 92,
                        textAlign: "center",
                        appearance: "none",
                      }}
                    />
                  </Box>
                </ListItemButton>
              );
            })}
          </List>
        </Box>
        <Divider orientation="vertical" />
        <Sheet
          variant="soft"
          sx={{
            width: CHAIR_WIDTH + 2 * 16,
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            minHeight: "auto",
            height: "100%",
            overflowY: "auto",
          }}
        >
          {chairs[selectedSectionID]?.length ? (
            <>
              <Typography
                endDecorator={
                  <Typography variant="soft" color="primary" level="body4">
                    +{chairs[selectedSectionID]?.length}
                  </Typography>
                }
                level="body2"
              >
                New Chairs:
              </Typography>
              <AnimatedList animation={"grow"} key={selectedSectionID}>
                {chairs[selectedSectionID].map((c, i) => (
                  <PositionBase
                    height={60}
                    key={c.sectionOrder}
                    leading={
                      <>
                        <ChairSectionRole
                          sectionRoles={sectionRoles}
                          sectionRolesMap={sectionRolesMap}
                          sectionRoleIDs={c.sectionID ? [c.sectionRoleID] : []}
                          onChange={(e) => {
                            const c = { ...chairs };
                            c[selectedSectionID][i] = {
                              ...c[selectedSectionID][i],
                              sectionRoleID: e,
                            };
                            setChairs(c);
                          }}
                        />
                      </>
                    }
                    bottom={
                      <ChairInstruments
                        instrumentsMap={instrumentsMap}
                        sectionID={selectedSectionID}
                        onChange={(e) => {
                          const c = { ...chairs };
                          c[selectedSectionID][i] = {
                            ...c[selectedSectionID][i],
                            instrumentIDs: JSON.stringify(e),
                          };
                          setChairs(c);
                        }}
                        instrumentIDs={JSON.parse(
                          c.instrumentIDs ? c.instrumentIDs : "[]"
                        )}
                      />
                    }
                  />
                ))}
              </AnimatedList>
            </>
          ) : (
            []
          )}
        </Sheet>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            onClick={() => {
              setSectionInputs({});
              setChairs({});
              setTemplateID(undefined);
            }}
            endDecorator={<i className="fa-regular fa-delete-left"></i>}
            size="sm"
            color="neutral"
            variant="solid"
          >
            Reset
          </Button>
          <UseTemplate
            templateID={templateID}
            onChange={(e) => {
              getChairs({
                filters: JSON.stringify([
                  {
                    name: "chairs.projectID",
                    comparison: "eq",
                    value: e,
                  },
                ]),
              })
                .unwrap()
                .then((e) => {
                  const templateChairs = e.ids.reduce((a, v) => {
                    a.push(e.entities[v]);
                    return a;
                  }, []);

                  setSectionInputs((e) => ({
                    ...e,
                    ...templateInputsAll(templateChairs),
                  }));
                  setChairs((e) => ({
                    ...e,
                    ...templateChairsAll(templateChairs),
                  }));
                });
              setTemplateID(e);
            }}
          />
          {settings.defaultStrings && notDefaultStrings ? (
            <Button
              startDecorator={<i className="fa-light fa-violin"></i>}
              onClick={() => {
                setSectionInputs((e) => ({
                  ...e,
                  1: settings.defaultStrings[0],
                  2: settings.defaultStrings[1],
                  3: settings.defaultStrings[2],
                  4: settings.defaultStrings[3],
                  5: settings.defaultStrings[4],
                }));
                setChairs((c) => {
                  settings.defaultStrings.forEach((s, i) => {
                    c[i + 1] = Array.from({ length: s }, (v, i) => i).reduce(
                      (a) => {
                        const sectionID = i + 1;
                        a.push({
                          sectionID,
                          workSessionIDs: [],
                          sectionRoleID: 4,
                          projectID,
                          instrumentIDs: sectionsMap[sectionID]
                            .defaultInstrumentID
                            ? JSON.stringify([
                                sectionsMap[sectionID].defaultInstrumentID,
                              ])
                            : "[]",
                        });
                        return a;
                      },
                      []
                    );
                  });
                  return { ...c };
                });
              }}
              size="sm"
              color="neutral"
              variant="soft"
            >
              Use default Strings
            </Button>
          ) : (
            []
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button variant="soft" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={total.length === 0}
            onClick={() => {
              addChairs(total);
              onClose();
            }}
          >
            Add {total.length > 0 ? total.length : ""} Chair
            {total.length > 1 ? "s" : ""}
          </Button>
        </Box>
      </DialogActions>
    </DialogClose>
  );
}

export function AddChairs({
  sectionID,
  forWorkSessionID,
  forProjectPieceID,
}: {
  sectionID: number;
  forWorkSessionID?: number;
  forProjectPieceID?: number;
}) {
  const projectID = useSelector(selectedProjectIDSelector);
  const addChairs = useAddChairs();
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [sectionInput, setSectionInput] = useState<number>(0);
  const { instrumentsMap, sectionRolesMap, sectionRoles, sectionsMap } =
    useSelector(layoutUtilsSelector);
  const selection = useSelector(selectionSelector);
  const [chairs, setChairs] = useState<
    Dictionary<Partial<RhapsodyChair_Entity>[]>
  >({ [sectionID]: [] });
  const { selectedProjectPieceIDs, selectedWorkSessionIDs } =
    useSelector(selectionSelector);
  const selectedWorkSessionIDsArray = [];
  for (const key in selectedWorkSessionIDs) {
    if (Object.hasOwnProperty.call(selectedWorkSessionIDs, key)) {
      if (selectedWorkSessionIDs[key])
        selectedWorkSessionIDsArray.push(parseInt(key));
    }
  }

  const selectedProjectPieceIDsArray = [];
  for (const key in selectedProjectPieceIDs) {
    if (Object.hasOwnProperty.call(selectedProjectPieceIDs, key)) {
      if (selectedProjectPieceIDs[key])
        selectedProjectPieceIDsArray.push(parseInt(key));
    }
  }

  useEffect(() => {
    if (!open) {
      setChairs({ [sectionID]: [] });
      setSectionInput(0);
    }
  }, [open]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | "plus" | "minus"
  ) => {
    let value = sectionInput ?? 0;
    let diff = 0;
    if (typeof e !== "string") {
      let parsed = parseInt(e.target.value);
      if (parsed > 99) parsed = 99;
      if (parsed >= 0) {
        value = parsed;
        if (value > 99) value = 99;
        diff = parsed - (sectionInput ?? 0);
      }
    } else {
      switch (e) {
        case "minus":
          value--;
          diff = -1;
          if (value < 0) value = 0;
          break;
        case "plus":
          diff = +1;
          value++;
          break;
      }
    }
    setChairs((e) => {
      if (!e[sectionID]) e[sectionID] = [];
      if (diff > 0) {
        for (let index = 0; index < diff; index++) {
          e[sectionID].push({
            sectionID,
            workSessionIDs: [],
            sectionRoleID: 4,
            projectID,
            instrumentIDs: sectionsMap[sectionID].defaultInstrumentID
              ? JSON.stringify([sectionsMap[sectionID].defaultInstrumentID])
              : "[]",
          });
        }
      }
      if (diff < 0) {
        e[sectionID].splice(e[sectionID].length + diff, -diff);
      }
      return e;
    });
    setSectionInput(value);
  };

  const total = [];
  for (const key in chairs) {
    if (Object.hasOwnProperty.call(chairs, key)) {
      total.push(...chairs[key]);
    }
  }

  const configureInstrumentation = () => {
    if (forProjectPieceID) {
      dispatch(
        setInstrumentationProjectPieceIDs(
          forProjectPieceID ? [forProjectPieceID] : null
        )
      );
      dispatch(setInstrumentationWorkSessionIDs(selectedWorkSessionIDsArray));
    } else if (forWorkSessionID) {
      dispatch(
        setInstrumentationWorkSessionIDs(
          forWorkSessionID ? [forWorkSessionID] : null
        )
      );
      dispatch(setInstrumentationProjectPieceIDs(selectedProjectPieceIDsArray));
    } else {
      dispatch(setInstrumentationWorkSessionIDs(selectedWorkSessionIDsArray));
      dispatch(setInstrumentationProjectPieceIDs(selectedProjectPieceIDsArray));
    }
  };

  return (
    <Tooltip
      arrow
      open={open}
      color="neutral"
      variant="outlined"
      title={
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Box
            sx={{
              width: CHAIR_WIDTH,
              maxHeight: 400,
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {!selection.allWorkSessionsSelected || forWorkSessionID ? (
              <Alert
                variant="solid"
                color="danger"
                size="sm"
                sx={{
                  p: 0.5,
                }}
              >
                {forWorkSessionID ? (
                  <Typography level="body3" sx={{ color: "white" }}>
                    You are about to add chairs to the selected pieces for only
                    this Work Session.
                  </Typography>
                ) : (
                  <Typography level="body3" sx={{ color: "white" }}>
                    You are about to add chairs to the selected pieces for only{" "}
                    {selection.selectedWorkSessions.length} Work Session
                    {selection.selectedWorkSessions.length > 1 ? "s" : ""}
                  </Typography>
                )}
              </Alert>
            ) : (
              []
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "sticky",
                zIndex: 99,
                top: 0,
                background: "white",
              }}
            >
              <Typography
                startDecorator={<i className="fa-solid fa-chair"></i>}
                level="body2"
              >
                Chairs:
              </Typography>
              <Input
                value={sectionInput ?? 0}
                slotProps={{
                  input: { style: { textAlign: "center" } },
                }}
                onChange={handleChange}
                onFocus={(event) => {
                  event.target.select();
                }}
                type="tel"
                startDecorator={
                  <IconButton
                    color="neutral"
                    disabled={sectionInput <= 0}
                    onClick={() => handleChange("minus")}
                  >
                    <i className="fa-solid fa-minus"></i>
                  </IconButton>
                }
                endDecorator={
                  <IconButton
                    color="neutral"
                    onClick={() => handleChange("plus")}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </IconButton>
                }
                size="sm"
                variant={!sectionInput ? "soft" : "solid"}
                color={sectionInput ? "primary" : "neutral"}
                sx={{ width: 92, textAlign: "center" }}
              />
            </Box>
            <AnimatedList animation={"grow"}>
              {chairs[sectionID].map((c, i) => (
                <PositionBase
                  height={60}
                  key={c.sectionOrder}
                  leading={
                    <>
                      <ChairSectionRole
                        sectionRoles={sectionRoles}
                        sectionRolesMap={sectionRolesMap}
                        sectionRoleIDs={c.sectionID ? [c.sectionRoleID] : []}
                        onChange={(e) => {
                          const c = { ...chairs };
                          c[sectionID][i] = {
                            ...c[sectionID][i],
                            sectionRoleID: e,
                          };
                          setChairs(c);
                        }}
                      />
                    </>
                  }
                  bottom={
                    <ChairInstruments
                      instrumentsMap={instrumentsMap}
                      sectionID={sectionID}
                      onChange={(e) => {
                        const c = { ...chairs };
                        c[sectionID][i] = {
                          ...c[sectionID][i],
                          instrumentIDs: JSON.stringify(e),
                        };
                        setChairs(c);
                      }}
                      instrumentIDs={JSON.parse(
                        c.instrumentIDs ? c.instrumentIDs : "[]"
                      )}
                    />
                  }
                />
              ))}
            </AnimatedList>
            <Button
              onClick={() => {
                setSectionInput(0);
                setChairs({ [sectionID]: [] });
                setOpen(false);
                addChairs(total, forWorkSessionID, forProjectPieceID);
              }}
              sx={{ position: "sticky", bottom: 0 }}
              type="submit"
              disabled={total.length === 0 || readOnly}
            >
              Add Chairs
            </Button>
          </Box>
        </ClickAwayListener>
      }
    >
      <Box
        onClick={() => {
          configureInstrumentation();
          setOpen((s) => !s);
        }}
        sx={{
          textAlign: "center",
        }}
      >
        <Tooltip
          title={
            sectionID
              ? `Add ${sectionsMap[sectionID]?.name} Chairs`
              : `Add Chairs`
          }
          size="sm"
          variant="outlined"
          arrow
        >
          <IconButton
            disabled={readOnly}
            size="sm"
            variant="plain"
            color="neutral"
          >
            <i className="fa-kit fa-solid-chair-circle-plus" />
          </IconButton>
        </Tooltip>
      </Box>
    </Tooltip>
  );
}

export function useAddChairs() {
  const [createChairs] = useCreateChairsMutation();
  const subscription = useMySubscription();
  const utils = useSelector(layoutUtilsSelector);
  const selection = useSelector(selectionSelector);
  const dispatch = useDispatch();
  const _instrumentationProjectPieceIDs = useSelector(
    instrumentationProjectPieceIDsSelector
  );
  const _instrumentationWorkSessionIDs = useSelector(
    instrumentationWorkSessionIDsSelector
  );

  const { projectPieces, workSessions } = utils;

  let instrumentationProjectPieceIDs = _instrumentationProjectPieceIDs;
  if (!instrumentationProjectPieceIDs) {
    instrumentationProjectPieceIDs = projectPieces.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []);
  }

  let instrumentationWorkSessionIDs = _instrumentationWorkSessionIDs;
  if (!instrumentationWorkSessionIDs) {
    instrumentationWorkSessionIDs = workSessions.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []);
  }

  const addChairs = (
    chairs: RhapsodyChair_Entity[],
    forWorkSessionID?: number,
    forProjectPieceID?: number
  ) => {
    const body: Partial<RhapsodyChair>[] = [];
    const _utils: LayoutUtils = {
      ...utils,
      chairs: [...utils.chairs, ...RhapsodyChair.fromList(chairs)],
    };
    console.log("---- addChairs");
    const _internal = getInternal(_utils, undefined, undefined);
    const newInternal = new Internal(_internal, _utils, selection);

    const { workSessions } = _utils;

    const allWorkSessionsSelected =
      instrumentationWorkSessionIDs.length === workSessions.length;

    if (subscription === "overture" && newInternal.positionCount > 10) {
      dispatch(
        setDescription420("You plan only allows 10 chairs in a project.")
      );
      dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
      return;
    }

    if (subscription === "mezzo" && newInternal.positionCount > 40) {
      dispatch(
        setDescription420("You plan only allows 40 chairs in a project.")
      );
      dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
      return;
    }

    console.log(forProjectPieceID, instrumentationProjectPieceIDs);

    if (forProjectPieceID) {
      chairs.forEach((c) => {
        body.push({
          ...c,
          projectPieceID: forProjectPieceID,
          chairCount: 1,
        });
      });
    } else {
      instrumentationProjectPieceIDs.forEach((projectPieceID) => {
        console.log(projectPieceID);
        // case where all work sessions are selected => No Exceptions Chairs;
        if (allWorkSessionsSelected && !forWorkSessionID) {
          // console.log(
          //   "case where all work sessions are selected => No Exceptions Chairs"
          // );
          chairs.forEach((c) => {
            body.push({
              ...c,
              projectPieceID,
              chairCount: 1,
            });
          });
          return;
        }

        // case where chair is for only 1 work session:
        if (forWorkSessionID) {
          // console.log("case where chair is for only 1 work session:");
          // we make sure the work session plays this piece
          const wppPlayingThePiece = utils.workSessionProjectPieces.filter(
            (wpp) => wpp.projectPieceID === projectPieceID
          );

          const wsPlayingThePiece = wppPlayingThePiece.find(
            (e) => e.workSessionID === forWorkSessionID
          );

          // console.log(
          //   "piece is playing in",
          //   wppPlayingThePiece.length,
          //   "workSession"
          // );

          if (wsPlayingThePiece)
            chairs.forEach((c) => {
              body.push({
                ...c,
                projectPieceID,
                workSessionID:
                  wppPlayingThePiece.length === 1 // if only the piece is playing on this ws, no need to create an exception
                    ? undefined
                    : forWorkSessionID,
                chairCount: 1,
              });
            });

          return;
        }

        // case where only few work sessions are selected;
        if (!allWorkSessionsSelected) {
          // console.log("case where only few work sessions are selected;");
          instrumentationWorkSessionIDs.forEach((wID) => {
            // we make sure the work session plays this piece
            const wppPlayingThePiece = utils.workSessionProjectPieces.filter(
              (wpp) => wpp.projectPieceID === projectPieceID
            );

            const wsPlayingThePiece = wppPlayingThePiece.find(
              (e) => e.workSessionID === wID
            );

            // console.log(
            //   "piece ",
            //   projectPieceID,
            //   "is playing in",
            //   wppPlayingThePiece.length,
            //   "workSession"
            // );

            if (wsPlayingThePiece)
              chairs.forEach((c) => {
                body.push({
                  ...c,
                  projectPieceID,
                  workSessionID:
                    wppPlayingThePiece.length === 1 // if only the piece is playing on this ws, no need to create an exception
                      ? undefined
                      : wID,
                  chairCount: 1,
                });
              });
          });
          return;
        }
      });
    }
    console.log(body);
    createChairs(body);
  };

  return addChairs;
}
