import { Button, Chip, IconButton, Typography, Tooltip, Box } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import DialogClose from "atoms/DialogClose/DialogClose";
import { RosterOptions } from "hooks/projectRoster/projectRoster";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
  setSelectedProjectRosterID,
} from "reducers/rhapsody";
import { useProjectRosters } from "redux/projectRoster/projectRosterHooks";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ProjectRosters() {
  const open = useSelector(formOpenSelector("projectRosters"));
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectRosters } = useProjectRosters(projectID);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectRosters" }));
  };

  return (
    <DialogClose maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-regular fa-users"></i>}
          level="h6"
        >
          Live Rosters
        </Typography>
        <Typography level="body3">
          Create a live PDF roster, always up-to-date using a public link.
          <br />
          They can be included in the call and communication.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Tooltip
            title="New Roster"
            variant="outlined"
            arrow
            size="sm"
            enterDelay={1000}
            enterNextDelay={1000}
          >
            <IconButton
              onClick={() =>
                dispatch(setFormOpen({ isOpen: true, formID: "roster" }))
              }
              variant="solid"
              size="sm"
              color="neutral"
            >
              <i className="fa-solid fa-plus"></i>
            </IconButton>
          </Tooltip>
        </Box>
        {projectRosters.length ? (
          <DataGridPremium
            sx={{ background: "white" }}
            hideFooter
            onRowClick={(r) => {
              dispatch(setSelectedProjectRosterID(r.row.id));
              dispatch(setFormOpen({ isOpen: true, formID: "roster" }));
            }}
            initialState={{
              pinnedColumns: {
                right: ["publicToken", "options"],
              },
            }}
            density="compact"
            rows={projectRosters}
            columns={[
              { field: "name", headerName: "Name", flex: 1 },
              {
                field: "publicToken",
                headerName: "URL",
                align: "right",
                renderCell: (p) => (
                  <Tooltip
                    title={p.row.publicUrl()}
                    size="sm"
                    arrow
                    variant="outlined"
                  >
                    <Chip
                      endDecorator={
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                      }
                      size="sm"
                      variant="outlined"
                      color="neutral"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        window.open(p.row.publicUrl(), "_blank");
                      }}
                    >
                      Open link
                    </Chip>
                  </Tooltip>
                ),
              },
              {
                field: "options",
                headerName: "Active",
                renderCell: (p) => {
                  let options: RosterOptions;

                  try {
                    options = JSON.parse(p.row.options);
                  } catch (error) {}
                  return (
                    <Typography color={options.active ? "success" : "danger"}>
                      <i
                        className={
                          options.active
                            ? "fa-duotone fa-solid fa-square-check"
                            : "fa-solid fa-xmark"
                        }
                      ></i>
                    </Typography>
                  );
                },
              },
            ]}
          />
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
