import {
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Sheet,
  Tab,
  TabList,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Box, Grow } from "@mui/material";
import { useTour } from "@reactour/tour";
import { useExporter } from "features/exporter/exporter";
import { editModeTour, tourScenarios } from "features/tour/tour";
import { Assignments } from "hooks/Assignments/assignments";
import { FinaleChip } from "hooks/Branding/branding";
import { Instrumentation } from "hooks/Instrumentation/v2";
import GroupActions from "hooks/Layout/GroupActions/v2";
import GroupNotify from "hooks/Layout/GroupNotify/GroupNotify";
import { LayoutGroup } from "hooks/Layout/v2";
import { finale, useMySubscription } from "hooks/subscription/restrictionHooks";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  MissionControlMode,
  MissionControlViewMode,
  actionInitiatorSelector,
  formOpenSelector,
  missionControlModeSelector,
  missionControlViewModeSelector,
  selectedProjectIDSelector,
  setDescription420,
  setFormOpen,
  setMissionControlMode,
  setMissionControlViewMode,
  setSelectedProjectPieceID,
  setSelectedWorkSessionID,
  toggleViewChairMemos,
  viewChairMemosSelector,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutInternalSelector,
  layoutUnselectAll,
  layoutUtilsSelector,
  selectionSelector,
  setSelectedProjectPieceIDs,
  setSelectedWorkSessionIDs,
} from "reducers/v2/missionControl";
import { setWorkSessionMode } from "reducers/workSessions";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useCleanChairsMutation } from "redux/rhapsodyChair/rhapsodyChairEndpoints";
import { useProjectWorkSessions } from "redux/workSession/workSessionHooks";
// import { useHouseCleaning } from "./houseCleaning";
import { usePreviewChanges } from "./LayoutUtils";
import { ProjectPiecesSelect } from "./projectPiecesSelect";
import { useSectionsExclusion } from "./sectionsExclusion";
import { useMissionControlSelectAll, useSoloWorkSession } from "./utils";
import { WorkSessionsSelect } from "./workSessionsSelect";
import { useCompanySettings } from "redux/company/companyHooks";

const SIDEBAR_WIDTH = 290;

/**
 *
 * @returns {ReactElement} MissionControl page
 */
export function MissionControl() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSelectedWorkSessionID());
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flex: 1,
        mt: 1,
        minWidth: "auto",
        width: 0,
        pl: 2,
      }}
    >
      <Sheet
        sx={{
          flex: 1,
          display: "flex",
          gap: 1,
          flexDirection: "column",
          pr: 2,
        }}
      >
        <Toolbar />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            overflowX: "auto",
            gap: 1,
          }}
        >
          <Content />
          <Sidebar />
        </Box>
      </Sheet>
    </Box>
  );
}

function Content() {
  const missionControlMode = useSelector(missionControlModeSelector);
  const missionControlViewMode = useSelector(missionControlViewModeSelector);

  const utils = useSelector(layoutUtilsSelector);
  const internal = useSelector(layoutInternalSelector);
  const selection = useSelector(selectionSelector);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        position: "relative",
      }}
      className="tour-layout"
    >
      {missionControlViewMode === "assignments" ? (
        <>
          <Assignments />
          {missionControlMode === "edit" ? <GroupActions /> : []}
          {missionControlMode === "invite" ? <GroupNotify /> : []}
        </>
      ) : (
        <LayoutGroup utils={utils} internal={internal} selection={selection} />
      )}
    </Box>
  );
}

function Toolbar() {
  const dispatch = useDispatch();
  const readOnly = useReadOnly();
  const { setIsOpen, setSteps, setCurrentStep } = useTour();
  const missionControlMode = useSelector(missionControlModeSelector);
  const missionControlViewMode = useSelector(missionControlViewModeSelector);
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectPieces, workSessions, project } =
    useSelector(layoutUtilsSelector);
  const { projectWorkSessions } = useProjectWorkSessions(projectID);
  const open = useSelector(formOpenSelector("instrumentation"));
  const exporter = useExporter();
  const debugMode = useSelector(layoutDebugSelector);
  const [cleanChairs] = useCleanChairsMutation();
  const { apiPreview } = useCompanySettings();
  const previewChanges = usePreviewChanges();
  // const houseCleaning = useHouseCleaning();
  const sectionsExclusion = useSectionsExclusion();
  const viewChairMemos = useSelector(viewChairMemosSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>();
  const subscription = useMySubscription();

  useEffect(() => {
    if (projectPieces.length < 2 && missionControlViewMode === "pieces") {
      dispatch(setMissionControlViewMode("grouped"));
    }
    if (workSessions.length < 2 && missionControlViewMode === "workSessions") {
      dispatch(setMissionControlViewMode("grouped"));
    }
  }, [projectPieces, workSessions]);

  const [observer, setObserver] = useState<{
    mode: "edit" | "invite";
    callback: () => void;
  } | null>();

  useEffect(() => {
    if (missionControlMode === "edit" && observer?.mode === "edit") {
      observer.callback();
      setObserver(null);
    }
    if (missionControlMode === "invite" && observer?.mode === "invite") {
      observer.callback();
      setObserver(null);
    }
  }, [missionControlMode]);

  const cleaning = async () => {
    const query: any = await cleanChairs({
      id: 0,
      body: {
        projectID: projectID,
        preview: apiPreview,
      },
    });

    previewChanges(query.data);
  };

  const exportButton = (
    <>
      <Tooltip
        enterDelay={1000}
        enterNextDelay={1000}
        variant="outlined"
        arrow
        size="sm"
        title={"Export"}
      >
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ borderRadius: 16 }}
          color="neutral"
        >
          <i className="fa-solid fa-arrow-down-to-line"></i>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {!project.template ? (
          <MenuItem
            onClick={() => {
              dispatch(setFormOpen({ formID: "roster", isOpen: true }));
              setAnchorEl(null);
            }}
          >
            PDF Roster
          </MenuItem>
        ) : (
          []
        )}
        <MenuItem
          onClick={() => {
            if (subscription !== "overture") {
              exporter.projectV2();
            } else {
              dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
              dispatch(
                setDescription420(
                  "Mission Control Excel Export is an exclusive feature to Finale"
                )
              );
            }
            setAnchorEl(null);
          }}
        >
          Excel{" "}
          {subscription !== finale ? (
            <Box sx={{ ml: 1 }}>
              <FinaleChip />
            </Box>
          ) : (
            []
          )}
        </MenuItem>
      </Menu>
    </>
  );

  if (project.template)
    return (
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {open && <Instrumentation />}
        {exportButton}
      </Box>
    );
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        position: "sticky",
        top: 0,
        zIndex: 99,
        gap: 1,
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        {!readOnly ? (
          <Box sx={{ display: "flex" }} className="tour-mode">
            <Sheet
              sx={{
                background: "#f5f5f5",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                display: "flex",
                alignItems: "center",
                pl: 1,
                pr: 1,
              }}
            >
              <Typography
                sx={{
                  color: "rgb(66, 66, 66)",
                  fontSize: 12,
                  fontWeight: 450,
                }}
              >
                Mode:
              </Typography>
            </Sheet>
            {/* <Divider orientation="vertical" /> */}
            <Tabs
              onChange={(e, v) => {
                // tour.setCurrentStep(tour.currentStep + 1);
                dispatch(setMissionControlMode(v as MissionControlMode));
                if (
                  (v === "invite" &&
                    missionControlViewMode === "workSessions") ||
                  missionControlViewMode === "pieces"
                ) {
                  dispatch(setMissionControlViewMode("grouped"));
                }
              }}
              value={missionControlMode}
              size="sm"
              variant="soft"
              sx={{
                width: 150,
                borderRadius: "16px",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <TabList color="neutral">
                <Tooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  variant="outlined"
                  arrow
                  size="sm"
                  title="Read only mode"
                >
                  <Tab color="neutral" value="view">
                    <Typography level="body3">View</Typography>
                  </Tab>
                </Tooltip>
                <Tooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  variant="outlined"
                  arrow
                  size="sm"
                  title="Change Structure, Manage musicians"
                >
                  <Tab value="edit">
                    <Typography level="body3">Edit</Typography>
                  </Tab>
                </Tooltip>
                <Tooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  variant="outlined"
                  arrow
                  size="sm"
                  title="Contact Musicians"
                >
                  <Tab value="invite">
                    <Typography level="body3">Notify</Typography>
                  </Tab>
                </Tooltip>
              </TabList>
            </Tabs>
          </Box>
        ) : (
          []
        )}
        <Box sx={{ display: "flex" }} className="tour-view">
          <Sheet
            sx={{
              background: "#f5f5f5",
              borderTopLeftRadius: "16px",
              borderBottomLeftRadius: "16px",
              display: "flex",
              alignItems: "center",
              pl: 1,
              pr: 1,
            }}
          >
            <Typography
              sx={{
                color: "rgb(66, 66, 66)",
                fontSize: 12,
                fontWeight: 450,
              }}
            >
              View:
            </Typography>
          </Sheet>
          <Tabs
            onChange={(e, v) => {
              dispatch(setMissionControlViewMode(v as MissionControlViewMode));
              dispatch(layoutUnselectAll());
              if (v === "assignments") {
                dispatch(
                  setSelectedProjectPieceIDs(
                    projectPieces?.reduce((a, v) => {
                      a[v.id] = true;
                      return a;
                    }, {}) ?? {}
                  )
                );
                dispatch(
                  setSelectedWorkSessionIDs(
                    workSessions?.reduce((a, v) => {
                      a[v.id] = true;
                      return a;
                    }, {}) ?? {}
                  )
                );
              }
              if (v === "pieces") {
                dispatch(
                  setSelectedProjectPieceIDs(
                    projectPieces?.reduce((a, v) => {
                      a[v.id] = true;
                      return a;
                    }, {}) ?? {}
                  )
                );
              }
              if (v === "workSessions") {
                dispatch(
                  setSelectedWorkSessionIDs(
                    workSessions?.reduce((a, v) => {
                      a[v.id] = true;
                      return a;
                    }, {}) ?? {}
                  )
                );
              }
            }}
            value={missionControlViewMode}
            size="sm"
            variant="soft"
            sx={{
              width: 330,
              borderRadius: "16px",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <TabList color="neutral">
              <Tooltip
                enterDelay={1000}
                enterNextDelay={1000}
                variant="outlined"
                arrow
                size="sm"
                title="Full Ensemble based on Selection"
              >
                <Tab value="grouped">
                  <Typography level="body3">Genius</Typography>
                </Tab>
              </Tooltip>
              <Tooltip
                enterDelay={1000}
                enterNextDelay={1000}
                variant="outlined"
                arrow
                size="sm"
                title="Listing of Musician's work"
              >
                <Tab value="assignments">
                  <Typography level="body3">Assignments</Typography>
                </Tab>
              </Tooltip>
              <Divider orientation="vertical" />
              <Tooltip
                enterDelay={1000}
                enterNextDelay={1000}
                variant="outlined"
                arrow
                size="sm"
                title={
                  projectPieces.length <= 1
                    ? "Pieces side by side. Need at least 2 Pieces to access this view."
                    : "Pieces side by side"
                }
              >
                <Box
                  sx={{
                    opacity:
                      projectPieces.length <= 1 ||
                      missionControlMode === "invite"
                        ? 0.2
                        : 1,
                    cursor:
                      projectPieces.length <= 1 ? "not-allowed" : undefined,
                  }}
                >
                  <Tab
                    disabled={
                      projectPieces.length <= 1 ||
                      missionControlMode === "invite"
                    }
                    value="pieces"
                  >
                    <Typography level="body3">Pieces</Typography>
                  </Tab>
                </Box>
              </Tooltip>
              <Tooltip
                enterDelay={1000}
                enterNextDelay={1000}
                variant="outlined"
                arrow
                size="sm"
                title={
                  workSessions.length <= 1
                    ? "Work Sessions side by side. Need at least 2 Work Sessions to access this view."
                    : "Work Sessions side by side"
                }
              >
                <Box
                  sx={{
                    opacity:
                      workSessions.length <= 1 ||
                      missionControlMode === "invite"
                        ? 0.2
                        : 1,
                    cursor:
                      workSessions.length <= 1 ? "not-allowed" : undefined,
                  }}
                >
                  <Tab
                    disabled={
                      workSessions.length <= 1 ||
                      missionControlMode === "invite"
                    }
                    value="workSessions"
                  >
                    <Typography level="body3">Work Sessions</Typography>
                  </Tab>
                </Box>
              </Tooltip>
            </TabList>
          </Tabs>
        </Box>
        {debugMode ? (
          <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            variant="outlined"
            arrow
            size="sm"
            title={"Clean Project"}
          >
            <IconButton
              onClick={cleaning}
              sx={{ borderRadius: 16 }}
              color={"neutral"}
            >
              <i className="fa-light fa-broom"></i>
            </IconButton>
          </Tooltip>
        ) : (
          []
        )}
        {/* {debugMode ? (
          <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            variant="outlined"
            arrow
            size="sm"
            title={"Section Exclusion"}
          >
            <IconButton
              onClick={sectionsExclusion}
              sx={{ borderRadius: 16 }}
              color={"neutral"}
            >
              <i className="fa-solid fa-door-open"></i>
            </IconButton>
          </Tooltip>
        ) : (
          []
        )} */}
        {missionControlViewMode !== "assignments" ? (
          <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            variant="outlined"
            arrow
            size="sm"
            title={
              !viewChairMemos ? "Edit Piece Memos" : "Done Editing Piece Memos"
            }
          >
            <IconButton
              onClick={() => dispatch(toggleViewChairMemos())}
              sx={{ borderRadius: 16 }}
              color={viewChairMemos ? "primary" : "neutral"}
            >
              <i className="fa-regular fa-memo"></i>
            </IconButton>
          </Tooltip>
        ) : (
          []
        )}
        {missionControlViewMode !== "assignments" ? (
          <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            variant="outlined"
            arrow
            size="sm"
            title={"Find Musician in Project"}
          >
            <IconButton
              onClick={() =>
                dispatch(
                  setFormOpen({ isOpen: true, formID: "searchMusician" })
                )
              }
              color="neutral"
              sx={{ borderRadius: 16 }}
            >
              <i className="fa-solid fa-user-magnifying-glass"></i>
            </IconButton>
          </Tooltip>
        ) : (
          []
        )}
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Grow in>
          <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            variant="outlined"
            arrow
            size="sm"
            title={"Which Pieces on which Work Sessions"}
          >
            <Chip
              onClick={() =>
                dispatch(
                  setFormOpen({ isOpen: true, formID: "projectProgram" })
                )
              }
              size="sm"
              sx={{
                borderRadius: "16px",
                opacity: projectWorkSessions.length === 0 ? 0.4 : 1,
              }}
              color="neutral"
              variant="soft"
              endDecorator={<i className="fa-solid fa-list-music"></i>}
            >
              Program
            </Chip>
          </Tooltip>
        </Grow>
        {open && <Instrumentation />}
        {missionControlViewMode !== "assignments" ? (
          <Tooltip
            title="Guided Tour"
            size="sm"
            color="neutral"
            arrow
            variant="outlined"
            enterDelay={1000}
            enterNextDelay={1000}
          >
            <IconButton
              sx={{ borderRadius: 16 }}
              color="neutral"
              onClick={() => {
                setSteps(tourScenarios["guided-tour"]);
                setCurrentStep(0);
                setIsOpen(true);
              }}
            >
              <i className="fa-solid fa-signs-post"></i>
            </IconButton>
          </Tooltip>
        ) : (
          []
        )}
        {exportButton}
      </Box>
    </Box>
  );
}

function Sidebar() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { selectedWorkSessionIDs, projectPieceIDs, workSessionIDs } =
    useSelector(selectionSelector);

  const missionControlMode = useSelector(missionControlModeSelector);
  const missionControlViewMode = useSelector(missionControlViewModeSelector);
  const debug = useSelector(layoutDebugSelector);
  const actionInitiator = useSelector(actionInitiatorSelector);
  const { projectPieces } = useProjectPieces(projectID);
  const { workSessions, project } = useSelector(layoutUtilsSelector);
  const { hasValidProjectPieces } = useSelector(layoutInternalSelector);
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  const [observer, setObserver] = useState<{
    mode: "edit" | "invite";
    callback: () => void;
  } | null>();
  const { projectWorkSessions } = useProjectWorkSessions(projectID);
  const dispatch = useDispatch();
  const handleSoloWorkSession = useSoloWorkSession();
  const [params] = useSearchParams();
  const { selectAll, selectAllWorkSessions, selectAllProjectPieces } =
    useMissionControlSelectAll();
  const readOnly = useReadOnly();

  const workSessionID = params.get("workSessionID");

  useEffect(() => {
    if (workSessionID) {
      const id = parseInt(workSessionID);
      handleSoloWorkSession(id);
    }
  }, [workSessionID]);

  useEffect(() => {
    if (missionControlMode === "edit" && observer?.mode === "edit") {
      observer.callback();
      setObserver(null);
    }
    if (missionControlMode === "invite" && observer?.mode === "invite") {
      observer.callback();
      setObserver(null);
    }
  }, [missionControlMode]);

  // Selects all work sessions by default
  useEffect(() => {
    if (
      !workSessionID &&
      projectWorkSessions?.length &&
      Object.keys(selectedWorkSessionIDs).length === 0
    ) {
      selectAll();
    }
  }, [projectWorkSessions]);

  const newProjectPiece = () => {
    dispatch(
      setFormOpen({
        isOpen: true,
        formID: "projectPiece2",
      })
    );
  };

  if (missionControlViewMode === "assignments") return <Box />;
  if (project.template) return <Box />;
  return (
    <>
      {!project?.template ? <Divider orientation="vertical" /> : []}
      <Sheet
        sx={{
          width: SIDEBAR_WIDTH,
          borderRadius: "8px",
          position: "sticky",
          top: 0,
          overflowY: "auto",
        }}
        className="tour-sidebar"
        // variant="outlined"
      >
        {debug ? (
          <>
            <Typography level="body5">
              Action Initiator: {actionInitiator}
            </Typography>
            <Typography level="body5">
              {JSON.stringify(workSessionIDs)}
            </Typography>
            <Typography level="body5">
              {JSON.stringify(projectPieceIDs)}
            </Typography>
          </>
        ) : (
          []
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pl: 1,
            pr: 1,
          }}
        >
          <Typography
            sx={{ height: 28 }}
            endDecorator={
              missionControlMode === "edit" ? (
                <Tooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  variant="outlined"
                  arrow
                  size="sm"
                  title="New Work Session"
                >
                  <IconButton
                    color="neutral"
                    onClick={() => {
                      dispatch(setWorkSessionMode("datagrid"));
                      dispatch(
                        setFormOpen({ isOpen: true, formID: "workSession" })
                      );
                    }}
                    size="sm"
                    variant="plain"
                  >
                    <i className="fa-solid fa-plus"></i>
                  </IconButton>
                </Tooltip>
              ) : (
                <Box sx={{ height: 28 }} />
              )
            }
          >
            Work Sessions
          </Typography>
          {workSessions.length > 1 ? (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={selectAllWorkSessions}
              level="body3"
            >
              Select All
            </Typography>
          ) : (
            []
          )}
        </Box>
        <Divider />
        {workSessions.length === 0 ? (
          <Chip
            disabled={readOnly}
            onClick={() => {
              const callback = () => {
                setIsOpen(false);
                dispatch(setWorkSessionMode("datagrid"));
                dispatch(setFormOpen({ isOpen: true, formID: "workSession" }));
              };
              if (missionControlMode === "edit") {
                callback();
              } else {
                setSteps([editModeTour]);
                setCurrentStep(0);
                setIsOpen(true);
                setObserver({ mode: "edit", callback });
              }
            }}
            sx={{ mt: 1, ml: 1 }}
            size="sm"
            color="neutral"
            variant="outlined"
          >
            Add Work Sessions
          </Chip>
        ) : (
          []
        )}
        <WorkSessionsSelect />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pl: 1,
            pr: 1,
          }}
        >
          <Typography
            sx={{ height: 28 }}
            endDecorator={
              missionControlMode === "edit" ? (
                <>
                  <Tooltip
                    enterDelay={1000}
                    enterNextDelay={1000}
                    variant="outlined"
                    arrow
                    size="sm"
                    title="New Project Piece"
                  >
                    <IconButton
                      color="neutral"
                      onClick={newProjectPiece}
                      size="sm"
                      variant="plain"
                    >
                      <i className="fa-solid fa-plus"></i>
                    </IconButton>
                  </Tooltip>
                  {projectPieces.length > 1 ? (
                    <Tooltip
                      enterDelay={1000}
                      enterNextDelay={1000}
                      variant="outlined"
                      arrow
                      size="sm"
                      title="Reorder Project Piece"
                    >
                      <IconButton
                        color="neutral"
                        onClick={() =>
                          dispatch(
                            setFormOpen({
                              isOpen: true,
                              formID: "projectPieceReorder",
                            })
                          )
                        }
                        size="sm"
                        variant="plain"
                      >
                        <i className="fa-solid fa-shuffle"></i>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    []
                  )}
                </>
              ) : (
                <Box sx={{ height: 28 }} />
              )
            }
          >
            Project Pieces
          </Typography>
          {projectPieces.length > 1 ? (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={selectAllProjectPieces}
              level="body3"
            >
              Select All
            </Typography>
          ) : (
            []
          )}
        </Box>
        <Divider />
        {hasValidProjectPieces ? (
          <ProjectPiecesSelect />
        ) : (
          <Chip
            endDecorator={<Typography level="body4">Optional</Typography>}
            sx={{ mt: 1, ml: 1 }}
            size="sm"
            color="neutral"
            variant="outlined"
            onClick={() => {
              const callback = () => {
                setIsOpen(false);
                dispatch(setSelectedProjectPieceID(projectPieces[0].id));
                dispatch(
                  setFormOpen({
                    formID: "projectPiece2",
                    isOpen: true,
                  })
                );
              };
              if (missionControlMode === "edit") {
                callback();
              } else {
                setSteps([editModeTour]);
                setCurrentStep(0);
                setIsOpen(true);
                setObserver({ mode: "edit", callback });
              }
            }}
            disabled={readOnly}
          >
            Add Project Pieces
          </Chip>
        )}
      </Sheet>
    </>
  );
}
