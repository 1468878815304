import { Box, Sheet, Textarea, Typography } from "@mui/joy";
import { PdProject_Entity } from "entities/pdProject";
import { currencyFormatter } from "helpers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedPdProjectIDSelector } from "reducers/rhapsody";
import { useUpdatePdProjectMutation } from "redux/pdProject/pdProjectEndpoints";
import { usePdProject } from "redux/pdProject/pdProjectHooks";

/**
 *
 * @returns {ReactElement} PdFooter page
 */
export function PdFooter() {
  const pdProjectID = useSelector(selectedPdProjectIDSelector);
  const { pdProject } = usePdProject(pdProjectID);
  const [updatePdProject] = useUpdatePdProjectMutation();

  const [_pdProject, setPdProject] = useState<PdProject_Entity>(pdProject);

  useEffect(() => {
    setPdProject(pdProject);
  }, [pdProject]);

  return (
    <Sheet
      sx={{
        p: 1,
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        background: "white",
        borderTop: "solid 1px #D1D8DB",
        gap: 2,
      }}
    >
      <Textarea
        minRows={2}
        maxRows={5}
        startDecorator="Footnote:"
        value={_pdProject?.footnote}
        onChange={(e) =>
          setPdProject((p) => ({ ...p, footnote: e.target.value }))
        }
        onBlur={() => updatePdProject({ id: _pdProject.id, body: _pdProject })}
        sx={{
          flex: 1,
        }}
        size="sm"
        placeholder="Footnote"
      ></Textarea>
      <Box>
        <Typography
          level="h6"
          startDecorator="Total:"
          sx={{ color: "inherit", fontWeight: 600 }}
        >
          {currencyFormatter.format(pdProject?.total / 1000)}
        </Typography>
      </Box>
    </Sheet>
  );
}
