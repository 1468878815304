import { Search } from "@mui/icons-material";
import { Box, Chip, Input, List, ListItem, ListItemButton } from "@mui/joy";
import { DialogTitle, Grid, Icon, Typography } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import ProjectsDataGrid from "features/dataGrids/ProjectsDataGrid";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectedProjectIDSelector,
  setFormOpen,
  setSelectedProjectID,
} from "reducers/rhapsody";
import { useProjects } from "redux/project/projectHooks";

/**
 *
 * @returns {ReactElement} ProjectNeeded page
 */
export function ProjectNeeded({ children }: { children: ReactElement }) {
  const projectID = useSelector(selectedProjectIDSelector);
  const { projects } = useProjects();
  const [open, setOpen] = useState(!selectedProjectIDSelector);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "workSession" }));
    setOpen(false);
  };

  if (projectID) return children;

  if (projects.filter((p) => !p.archived).length === 0) {
    return (
      <DialogClose
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          <Grid container justifyContent="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Icon className="fad fa-folder-open" style={{ fontSize: 80 }} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              You need a project to create a work session.
              <Typography variant="body2" color="textSecondary">
                Why don't you create one now?
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                onClick={() =>
                  dispatch(setFormOpen({ isOpen: true, formID: "project" }))
                }
              >
                Let's do it!
              </Chip>
            </Grid>
          </Grid>
        </DialogTitle>
      </DialogClose>
    );
  }

  return (
    <DialogClose
      fullWidth
      maxWidth={"md"}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open
    >
      <DialogTitle id="simple-dialog-title">
        <Grid container justifyContent="space-between">
          <Grid item>For which project?</Grid>
          <Grid item>
            <Chip
              onClick={() =>
                dispatch(setFormOpen({ isOpen: true, formID: "project" }))
              }
            >
              + Project
            </Chip>
          </Grid>
        </Grid>
      </DialogTitle>
      <Box sx={{ height: 600, display: "flex" }}>
        <ProjectsDataGrid
          noCheckboxes
          autoheight={false}
          onSelect={(p) => {
            if (p.version === 2) {
              navigate(`${RouterConfig.projects}/${p.id}/mission-control`);
            } else {
              navigate(
                `${RouterConfig.projects}/${p.id}/${
                  p.defaultTab.length === 0 ? "seating" : p.defaultTab
                }`
              );
            }
            dispatch(setSelectedProjectID(p.id));
          }}
        />
      </Box>
    </DialogClose>
  );
}
