import { AppConfig } from "environement";
import ProjectRoster_Entity from "./projectRoster";
export default class ProjectRoster extends ProjectRoster_Entity {
  static fromList(projectRostersJSON: unknown): Array<ProjectRoster> {
    const projectRosters: ProjectRoster[] = [];
    if (projectRostersJSON)
      Array.isArray(projectRostersJSON) &&
        projectRostersJSON.forEach((projectRosterJSON) => {
          projectRosters.push(new ProjectRoster(projectRosterJSON));
        });
    return projectRosters;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  publicUrl(): string {
    const apiEndpoint = AppConfig.api.endpoint;
    let endpoint = "live";
    if (apiEndpoint.includes("alpha")) endpoint = "alpha";
    if (apiEndpoint.includes("demo")) endpoint = "demo";

    return `https://roster.${endpoint}.rhapsody.la?token=${this.publicToken}`;
  }
}
