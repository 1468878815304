import { Box, Tab, Tabs } from "@mui/material";
import LinksDataGrid from "features/dataGrids/LinksDataGrid/LinksDataGrid";
import ProjectNotesDataGrid from "features/dataGrids/ProjectNotesDataGrid";
import WorkSessionsDataGrid from "features/dataGrids/WorkSessionsDataGrid/WorkSessionsDataGrid";
import ProjectAlternates from "features/projects/ProjectAlternates/ProjectAlternates";
import ProjectDangerZone from "features/projects/ProjectDangerZone";
import ProjectDescription from "features/projects/ProjectDescription";
import ProjectExport from "features/projects/ProjectExport/ProjectExport";
import ProjectHiring from "features/projects/ProjectHiring";
import {
  useProjectHiringSelectAll,
  useProjectHiringUnselectAll,
} from "features/projects/ProjectHiring/useProjectHiringDataGrid";
import { MissionControl } from "features/projects/ProjectMissionControl/projectMissionControl";
import { heightWithToolbar, piecesEnabled } from "global";
import { useRouteMatch } from "helpers";
import Calendar from "hooks/Calendar";
import { ProjectSideBar } from "hooks/projectSideBar/v1";
import Rosters from "hooks/Rosters";
import { useProjectRostersSelectAll } from "hooks/Rosters/useRosterData";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import {
  Outlet,
  Route,
  Link as RouterLink,
  Routes,
  useParams,
} from "react-router-dom";
import { setSelectedProjectID } from "reducers/rhapsody";
import { useProject } from "redux/project/projectHooks";
import { useProjectWorkSessions } from "redux/workSession/workSessionHooks";

export default function ProjectPagev1({ template = false }) {
  const params = useParams();
  const projectID = parseInt(params.projectID);
  const { project } = useProject(projectID);
  const { workSessions } = useProjectWorkSessions(projectID);
  const dispatch = useDispatch();
  const hasWorkSessions = workSessions?.length > 0 ?? false;
  const unselectProjectHiring = useProjectHiringUnselectAll();
  const selectProjectHiring = useProjectHiringSelectAll();
  const selectRosters = useProjectRostersSelectAll();

  useEffect(() => {
    dispatch(setSelectedProjectID(parseInt(params.projectID)));
    selectProjectHiring();
    selectRosters();

    return () => {
      dispatch(setSelectedProjectID());
      unselectProjectHiring();
    };
  }, [projectID]);

  const baseRoute = template ? "templates" : "projects";

  const routeMatch = useRouteMatch([
    `/${baseRoute}/:id/seating`,
    `/${baseRoute}/:id/calendar`,
    `/${baseRoute}/:id/work-sessions`,
    `/${baseRoute}/:id/mission-control`,
    `/${baseRoute}/:id/rosters`,
    `/${baseRoute}/:id/hiring`,
    `/${baseRoute}/:id/notes`,
    `/${baseRoute}/:id/links`,
    `/${baseRoute}/:id/description`,
    `/${baseRoute}/:id/danger-zone`,
  ]);

  if (!project) return <div />;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: "column",
        margin: "auto",
      }}
    >
      <Helmet>
        <title>Rhapsody | {project?.name ?? ""}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          height: heightWithToolbar,
          position: "relative",
        }}
      >
        <ProjectSideBar />
        <Box
          sx={{
            height: "100%",
            flexGrow: 1,
            display: "flex",
            position: "relative",
            flexDirection: "column",
            width: 0,
            minWidth: "auto",
          }}
        >
          <MyTabs
            routeMatch={routeMatch}
            baseRoute={baseRoute}
            hasWorkSessions={hasWorkSessions}
            template={template}
          />
          <Box
            sx={{
              position: "relative",
              overflow: "auto",
              flexGrow: 1,
              display: "flex",
              p: 2,
            }}
          >
            <Routes>
              <Route
                path={"seating"}
                element={
                  <>
                    <ProjectAlternates template={template} id={project.id} />
                  </>
                }
              ></Route>
              {!template
                ? [
                    <Route
                      key={"calendar"}
                      path={"calendar"}
                      element={<Calendar projectID={project.id} />}
                    ></Route>,
                    <Route
                      key={"work-sessions"}
                      path={"work-sessions"}
                      element={
                        <WorkSessionsDataGrid
                          createButton
                          autoheight={false}
                          projectID={project.id}
                        />
                      }
                    ></Route>,

                    <Route
                      key={"rosters"}
                      path={"rosters"}
                      element={<Rosters />}
                    ></Route>,
                    <Route
                      key={"notes"}
                      path={"notes"}
                      element={<ProjectNotesDataGrid projectID={project.id} />}
                    ></Route>,
                    <Route
                      key={"links"}
                      path={"links"}
                      element={<LinksDataGrid projectID={project.id} />}
                    ></Route>,
                    <Route
                      key={"hiring"}
                      path={"hiring"}
                      element={<ProjectHiring id={project.id} />}
                    ></Route>,
                  ]
                : []}
              <Route
                key={"description"}
                path={"description"}
                element={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    {!project.template ? (
                      <>
                        <ProjectDescription id={project.id} />
                        <ProjectExport id={project.id} />
                      </>
                    ) : (
                      []
                    )}
                    <ProjectDangerZone template={template} id={project.id} />
                  </Box>
                }
              ></Route>
            </Routes>
          </Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

function MyTabs({ routeMatch, baseRoute, template }) {
  const currentTab = routeMatch?.pattern?.path;

  let tabs = [
    <Tab
      label="Hiring"
      key="hiring"
      value={`/${baseRoute}/:id/hiring`}
      to="hiring"
      component={RouterLink}
    />,
    <Tab
      label="Rosters"
      key="rosters"
      value={`/${baseRoute}/:id/rosters`}
      to="rosters"
      component={RouterLink}
    />,
    <Tab
      label="Calendar"
      key="calendar"
      value={`/${baseRoute}/:id/calendar`}
      to="calendar"
      component={RouterLink}
    />,
    <Tab
      label="Work Sessions"
      key="work-sessions"
      value={`/${baseRoute}/:id/work-sessions`}
      to="work-sessions"
      component={RouterLink}
    />,
    <Tab
      key="seating"
      label="Seating"
      value={`/${baseRoute}/:id/seating`}
      to="seating"
      component={RouterLink}
    />,
    <Tab
      label="Notes"
      key="notes"
      value={`/${baseRoute}/:id/notes`}
      to="notes"
      component={RouterLink}
    />,
    <Tab
      label="Links"
      key="links"
      value={`/${baseRoute}/:id/links`}
      to="links"
      component={RouterLink}
    />,
    <Tab
      label="Settings"
      key="description"
      value={`/${baseRoute}/:id/description`}
      to="description"
      component={RouterLink}
    />,
  ];

  if (template)
    tabs = [
      <Tab
        key="seating"
        label="Seating"
        value={`/${baseRoute}/:id/seating`}
        to="seating"
        component={RouterLink}
      />,
      <Tab
        label="Settings"
        key="description"
        value={`/${baseRoute}/:id/description`}
        to="description"
        component={RouterLink}
      />,
    ];

  return (
    <Tabs value={currentTab} variant="scrollable" sx={{ width: "100%" }}>
      {tabs}
    </Tabs>
  );
}
