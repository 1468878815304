import Payroll_Entity from "./payroll";
export default class Payroll extends Payroll_Entity {
  static fromList(payrollsJSON: unknown): Array<Payroll> {
    const payrolls: Payroll[] = [];
    if (payrollsJSON)
      Array.isArray(payrollsJSON) &&
        payrollsJSON.forEach((payrollJSON) => {
          payrolls.push(new Payroll(payrollJSON));
        });
    return payrolls;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
