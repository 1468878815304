import styled from "styled-components";

export default styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease;
  backface-visibility: hidden;
  overflow: hidden;
`;
