import {
  alpha,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
// import PropTypes from 'prop-types';
// material-ui
import { Email } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { getCurrentStageName } from "helpers";
// styles
import { Alert, Button, Chip, Divider, Typography } from "@mui/joy";
import { Inspector_Entity } from "entities/inspector";
import { Job } from "entities/job";
import { Stage_Entity } from "entities/stage";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import * as moment from "moment";
import { useEffect, useState } from "react";
import { useMercuryJob } from "redux/mercuryJob/mercuryJobHooks";
import { useMusician } from "redux/musician/musicianHooks";
import { Musician } from "entities/musician";

/**
 *
 * @returns {ReactElement} ChairMercuryHistory page
 */
export function ChairMercuryHistory({
  open,
  onClose,
  job,
  musician: _musician,
}: {
  job: Job;
  open?: boolean;
  defaultOpen?: boolean;
  musician?: Musician;
  onClose: () => void;
}) {
  let musician = _musician;
  const musicianData = useMusician(job?.musicianID, {
    skip: _musician?.id > 0,
  });
  const { mercuryJob } = useMercuryJob(job.mercuryJobID, { skip: !open });
  if (!musician) musician = musicianData?.musician;

  if (!mercuryJob || !job) return <div></div>;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <MusicianAvatar musician={musician} size={48} noBadge />
          <Box sx={{ flex: 1 }}>
            <Typography level={"h6"}>{musician?.fullName()}</Typography>
            {musician.email ? (
              <Typography
                startDecorator={<i className="fa-solid fa-envelope"></i>}
                level={"body2"}
                onClick={() => {
                  window.open(`mailTo:${musician?.email}`, "_blank");
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: alpha("#9e9e9e", 0.2),
                  },
                  "&:active": {
                    background: alpha("#9e9e9e", 0.3),
                  },
                }}
              >
                {musician.email}
              </Typography>
            ) : (
              []
            )}
            {musician.phone ? (
              <Typography
                onClick={() => {
                  window.open(`tel:${musician?.phone}`, "_blank");
                }}
                startDecorator={<i className="fa-solid fa-phone"></i>}
                level={"body2"}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: alpha("#9e9e9e", 0.2),
                  },
                  "&:active": {
                    background: alpha("#9e9e9e", 0.3),
                  },
                }}
              >
                {musician.phone}
              </Typography>
            ) : (
              []
            )}
          </Box>
        </Box>
        <Divider sx={{ mt: 1 }}>
          <Typography level="body2">Events History</Typography>
        </Divider>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{ width: 400, display: "flex", gap: 1, flexDirection: "column" }}
        >
          <>
            {!mercuryJob?.stages ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Alert color="neutral">Nothing has happened yet!</Alert>
              </Box>
            ) : (
              <>
                {mercuryJob?.stages?.map((s) => (
                  <MercuryJobStage
                    key={s.id}
                    stage={s}
                    current={s.id === mercuryJob?.currentStage?.id}
                  />
                ))}
              </>
            )}
          </>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function MercuryJobStage({ stage, current }) {
  const inspectors = {
    pre: [],
    post: [],
  };

  for (const key in stage.inspectors) {
    if (Object.hasOwnProperty.call(stage.inspectors, key)) {
      const inspector = stage.inspectors[key];
      if (moment(inspector.createdAt).isAfter(moment(stage.createdAt))) {
        inspectors.post.push(inspector);
      }
      if (moment(inspector.createdAt).isBefore(moment(stage.createdAt))) {
        inspectors.pre.push(inspector);
      }

      if (moment(inspector.createdAt).isSame(moment(stage.createdAt))) {
        inspectors.post.push(inspector);
      }
    }
  }

  inspectors.pre = inspectors.pre.sort((a, b) => a.id - b.id);
  inspectors.post = inspectors.post.sort((a, b) => b.id - a.id);

  function getInspectors(pre: boolean) {
    const data = pre ? inspectors.pre : inspectors.post;
    return data?.reverse().map((i, index) => {
      let previousI;
      if (Number(index) > 0 && Number(index) <= inspectors.post.length) {
        previousI = inspectors.post[Number(index) - 1];
      }
      return (
        <Inspector
          key={i.id}
          pre
          meta={
            !previousI ||
            moment(previousI.createdAt).format("YYYY/MM/DD") !==
              moment(i.createdAt).format("YYYY/MM/DD")
          }
          stage={stage}
          current={current}
          i={i}
        />
      );
    });
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      {getInspectors(true)}
      <Paper
        variant="outlined"
        sx={{
          border: `solid 1px ${stage.color}`,
          background: current && stage.color,
          color: current && "white",
          p: 1,
          borderRadius: "4px",
          display: "flex",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <img
              src={stage.icon}
              alt={`${stage.name}-icon`}
              style={{
                height: 20,
                filter: current && "brightness(0) invert(1)",
              }}
            />
            <Typography
              level="body2"
              style={{ lineHeight: 0.8, color: "inherit" }}
            >
              <b>{getCurrentStageName(stage)}</b>
            </Typography>
          </Box>
          <Tooltip title="View related emails">
            <IconButton
              size="small"
              onClick={() => {
                window.open(
                  `/emails?filters=${encodeURIComponent(
                    JSON.stringify([
                      {
                        name: "refID",
                        comparison: "eq",
                        value: stage.relationID,
                      },
                    ])
                  )}`
                );
              }}
            >
              <Email
                style={{
                  color: "black",
                  fontSize: "inherit",
                  filter: current && "brightness(0) invert(1)",
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Paper>
      <Meta
        date={stage.createdAt}
        author={stage.createdBy}
        align="right"
      ></Meta>
      {getInspectors(false)}
    </Box>
  );
}

function Inspector({
  i,
  stage,
  current,
  post,
  pre,
  meta,
}: {
  i: Inspector_Entity;
  stage: Stage_Entity;
  current?: boolean;
  post?: boolean;
  pre?: boolean;
  meta?: boolean;
}) {
  switch (i.kind) {
    case "checkbox":
      return (
        <div
          style={{
            flex: 1,
            textAlign: i.stageTriggered ? "right" : "left",
          }}
        >
          <Tooltip title={moment(i.createdAt).format("lll")}>
            <Chip
              startDecorator={
                <i
                  className={`${i.icon} p-right` || "fad fa-comment p-right"}
                ></i>
              }
              endDecorator={<i className="fas fa-check-square p-left"></i>}
              size="sm"
            >
              {i.title}
            </Chip>
          </Tooltip>
        </div>
      );
    case "signature":
      return (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Tooltip title={moment(i.createdAt).format("lll")}>
            <div style={{ borderRadius: 8, padding: 6 }}>
              <Typography level="body2">{i.title}</Typography>
              <img src={i.message} alt="signature" style={{ width: "100%" }} />
            </div>
          </Tooltip>
        </div>
      );
    case "file":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              textAlign: "left",
            }}
          >
            <Tooltip title={moment(i.createdAt).format("lll")}>
              <div
                style={{
                  borderRadius: 8,
                  padding: 8,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => window.open(i.message)}
              >
                <Typography level="h3">
                  <i className={i.icon}></i>
                </Typography>
                <div style={{ marginLeft: 6 }}>
                  <Typography level="body2">{i.title}</Typography>
                  <Typography level="body4" style={{ opacity: 0.58 }}>
                    Click to Download
                  </Typography>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      );
    default:
      return (
        <div
          style={{
            flex: 1,
            textAlign: i.stageTriggered ? "right" : "left",
          }}
        >
          <Tooltip title={moment(i.createdAt).format("lll")}>
            <Chip
              size="sm"
              variant="soft"
              color="neutral"
              startDecorator={
                <i
                  className={`${i.icon} p-right` || "fad fa-comment p-right"}
                ></i>
              }
            >
              {i.message}
            </Chip>
          </Tooltip>
        </div>
      );
  }
}

function Meta({ date, author, align }) {
  return (
    <Tooltip title={moment(date).format("lll")}>
      <div
        style={{
          textAlign: align || "right",
        }}
      >
        <Typography level="body3">
          {author}, {moment(date).fromNow()}
        </Typography>
      </div>
    </Tooltip>
  );
}
