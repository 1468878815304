/**
 * ProjectPieceChair_Entity type guard.
 *
 * @param {any} projectPieceChairJson ProjectPieceChair object from API
 * @returns {boolean} Return true if type is ProjectPieceChair_Entity
 */

export default class ProjectPieceChair_Entity {
  constructor(projectPieceChairJson: ProjectPieceChair_Entity) {
    try {
      this.id = projectPieceChairJson.id;
      this.projectPieceID = projectPieceChairJson.projectPieceID;
      this.sectionID = projectPieceChairJson.sectionID;
      this.sectionRoleID = projectPieceChairJson.sectionRoleID;
      this.sectionOrder = projectPieceChairJson.sectionOrder;
      this.chairCount = projectPieceChairJson.chairCount;
      this.instrumentIDs = projectPieceChairJson.instrumentIDs;
      this.createdAt = projectPieceChairJson.createdAt;
      this.updatedAt = projectPieceChairJson.updatedAt;
      this.emptyChairIndex = projectPieceChairJson.emptyChairIndex;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectPieceID: number;
  sectionID: number;
  sectionRoleID: number;
  sectionOrder: number;
  chairCount: number;
  instrumentIDs?: string;
  createdAt: string;
  updatedAt: string;
  emptyChairIndex?: number;
}
