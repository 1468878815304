import Pipeline_Entity from "./pipeline";
export default class Pipeline extends Pipeline_Entity {
  static fromList(pipelinesJSON: unknown): Array<Pipeline> {
    const pipelines: Pipeline[] = [];
    if (pipelinesJSON)
      Array.isArray(pipelinesJSON) &&
        pipelinesJSON.forEach((pipelineJSON) => {
          pipelines.push(new Pipeline(pipelineJSON));
        });
    return pipelines;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
