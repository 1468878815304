/**
 * HProject_Entity type guard.
 *
 * @param {any} hProjectJson HProject object from API
 * @returns {boolean} Return true if type is HProject_Entity
 */

import { HJob_Entity } from "entities/hJob";
import { MercuryJob_Entity } from "entities/mercuryJob";
import { Stage_Entity } from "entities/stage";

export default class HProject_Entity {
  constructor(hProjectJson: HProject_Entity) {
    try {
      this.id = hProjectJson.id;
      this.pipelineID = hProjectJson.pipelineID;
      this.jobs = hProjectJson.jobs;
      this.currentStages = hProjectJson.currentStages;
      this.upcomingStages = hProjectJson.upcomingStages;
      this.formerStages = hProjectJson.formerStages;
      this.formerJobs = hProjectJson.formerJobs;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ HProject_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  pipelineID: number;
  jobs: Array<MercuryJob_Entity>;
  currentStages: Array<Stage_Entity>;
  upcomingStages: Array<Stage_Entity>;
  formerStages: Array<Stage_Entity>;
  formerJobs: Array<HJob_Entity>;
}
