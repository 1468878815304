import { WorkSessionSection } from "entities/workSessionSection";
import { useMemo } from "react";
import {
  useGetWorkSessionSectionQuery,
  useGetWorkSessionSectionsQuery,
} from "./workSessionSectionEndpoints";

export function useWorkSessionSection(
  workSessionSectionID: number | undefined
) {
  const workSessionSectionQuery = useGetWorkSessionSectionQuery(
    workSessionSectionID ?? 0,
    {
      skip: (workSessionSectionID ?? 0) <= 0,
    }
  );
  const workSessionSectionEntity = workSessionSectionQuery.data;

  return useMemo(() => {
    const ret: typeof workSessionSectionQuery & {
      workSessionSection?: WorkSessionSection | undefined;
    } = {
      ...workSessionSectionQuery,
    };
    if (workSessionSectionEntity)
      ret.workSessionSection = new WorkSessionSection(workSessionSectionEntity);
    return ret;
  }, [workSessionSectionEntity]);
}

export function useWorkSessionSections(workSessionID: number | undefined) {
  const workSessionSectionQuery = useGetWorkSessionSectionsQuery(
    workSessionID ?? 0,
    {
      skip: (workSessionID ?? 0) <= 0,
    }
  );
  const workSessionSectionEntitiesMap = workSessionSectionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof workSessionSectionQuery & {
      workSessionSections?: WorkSessionSection[];
      workSessionSectionsMap?: { [id: number]: WorkSessionSection };
    } = {
      ...workSessionSectionQuery,
      workSessionSections: [],
      workSessionSectionsMap: {},
    };
    if (workSessionSectionEntitiesMap) {
      const workSessionSections = [];
      const workSessionSectionsMap = {};

      for (const key in workSessionSectionEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(
            workSessionSectionEntitiesMap,
            key
          )
        ) {
          const c = workSessionSectionEntitiesMap[key];
          if (c.sessionID !== workSessionID) continue;
          const workSessionSection = new WorkSessionSection(c);
          workSessionSections.push(workSessionSection);
          workSessionSectionsMap[workSessionSection.id] = workSessionSection;
        }
      }
      workSessionSections.sort((a, b) => a.sectionPos - b.sectionPos);
      ret.workSessionSections = workSessionSections;
      ret.workSessionSectionsMap = workSessionSectionsMap;
    }

    return ret;
  }, [workSessionSectionEntitiesMap]);
}
