import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { show } from "@intercom/messenger-js-sdk";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Input,
  Input as JoyTextField,
  List,
  Tab,
  TabList,
  Tabs,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowId,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dictionary } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import { typeOptions } from "constants/workSessionTypeOptions";
import { Piece } from "entities/piece";
import { ProjectPiece } from "entities/projectPiece";
import { WorkSession } from "entities/workSession";
import { ProjectPieceListItemButton } from "features/projects/ProjectMissionControl/projectPiecesSelect";
import { useMissionControlSelectAll } from "features/projects/ProjectMissionControl/utils";
import { RenderEventContent } from "hooks/Calendar/Calendar";
import SlidingDialog from "hooks/SlidingDialog";
import VenueFinder from "hooks/VenueFinder";
import WhosPlaying from "hooks/WhosPlaying";
import { DateTimePicker } from "hooks/dateTimePicker/dateTimePicker";
import { DateTime } from "luxon";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";
import {
  setTmpWorkSession,
  setTmpWorkSessions,
  setWorkSessionMode,
  setWorkSessionNeeds,
  tempWorkSessionsSelector,
  workSessionModeSelector,
  workSessionNeedsSelector,
} from "reducers/workSessions";
import { useAssignments } from "redux/assignment/assignmentHooks";
import { usePieces } from "redux/piece/pieceHooks";
import { useProject, useProjects } from "redux/project/projectHooks";
import { useProjectAlternates } from "redux/projectAlternate/projectAlternateHooks";
import { useVenues } from "redux/venue/venueHooks";
import { useCreateWorkSessionMutation } from "redux/workSession/workSessionEndpoints";
import { useWorkSessionTypes } from "redux/workSessionType/workSessionTypeHooks";
import * as yup from "yup";

export default function FormWorkSession() {
  const [errors, setErrors] = React.useState<YupError[] | null>();
  const mode = useSelector(workSessionModeSelector);
  const { venuesMap } = useVenues();
  const [workSessionID, setWorkSessionID] = useState<number>();
  const { selectWorkSession } = useMissionControlSelectAll();
  const [selectedDate, setSelectedDate] = useState(moment());
  const debug = useSelector(layoutDebugSelector);
  const [createWorkSession] = useCreateWorkSessionMutation();
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const { project } = useProject(projectID);
  const workSessions = useSelector(tempWorkSessionsSelector);
  const open = useSelector(formOpenSelector("workSession"));
  const workSessionNeeds = useSelector(workSessionNeedsSelector);
  const { projects, projectsMap } = useProjects();
  const { workSessionTypes: sessionTypes } = useWorkSessionTypes();
  const utils = useSelector(layoutUtilsSelector);
  const projectPieces = utils?.projectPieces;
  const projectPiecesMap = utils?.projectPiecesMap;
  const [defaultDate, setDefaultDate] = useState({
    dateFromUTC: "",
    dateToUTC: "",
  });
  const { projectAlternates } = useProjectAlternates(
    workSessions[0]?.projectID
  );
  const { assignments } = useAssignments(projectID);

  const callSent = assignments.reduce((a, v) => {
    if (v.mercuryStageID && v.job?.version !== 3) a = true;
    return a;
  }, false);
  const apiRef = useGridApiRef();
  const calendarRef = useRef();

  const workSession = workSessions?.find((w) => w.id === workSessionID);

  const pieceIDs =
    projectPieces?.reduce((a, v) => {
      a.push(v.pieceID);
      return a;
    }, []) ?? [];

  const { piecesMap } = usePieces({
    filters: JSON.stringify([
      {
        name: "pieces.id",
        value: pieceIDs,
        comparison: "in",
      },
    ]),
  });

  useEffect(() => {
    if (
      projectPieces?.length === 1 &&
      workSessions?.length === 1 &&
      workSessions[0].projectPieceIDs?.length === 0
    ) {
      dispatch(
        setTmpWorkSession({
          workSession: {
            ...workSessions[0],
            projectPieceIDs: projectPieces?.reduce((a, v) => {
              a.push(v.id);
              return a;
            }, []),
          },
          index: 0,
        })
      );
    }
  }, [workSessions]);

  useEffect(() => {
    if (projectID && workSessions.length) {
      if (workSessions[0].projectID !== projectID) {
        dispatch(setTmpWorkSessions([]));
        dispatch(setWorkSessionNeeds({}));
      }
    }
  }, [workSessions, projectID]);

  React.useEffect(() => {
    if (apiRef.current?.subscribeEvent) {
      const handleRowClick: GridEventListener<"cellFocusIn"> = (params) => {
        setWorkSessionID(params.row.id);
      };
      return apiRef.current.subscribeEvent("cellFocusIn", handleRowClick);
    }
  }, [apiRef]);

  useEffect(() => {
    if (
      mode === "datagrid" &&
      workSessions.length === 1 &&
      !workSessions[0].type
    ) {
      dispatch(setTmpWorkSessions([]));
    }
  }, [mode]);

  useEffect(() => {
    if (workSessionID) {
      const w = workSessions.find((e) => e.id === workSessionID);
      if (w && w.dateFromUTC) {
        setSelectedDate(moment(w.dateFromUTC));
      }
    }

    if (calendarRef?.current) {
      const _calendarRef: any = calendarRef.current;
      const calendarApi = _calendarRef?.getApi();
      console.log(calendarApi);
    }
  }, [workSessionID]);

  useEffect(() => {
    if (calendarRef?.current) {
      const _calendarRef: any = calendarRef.current;
      const calendarApi = _calendarRef?.getApi();
      calendarApi?.gotoDate(selectedDate.clone().toDate());
    }
  }, [selectedDate]);

  useEffect(() => {
    if (projectID && mode === "single" && workSessions.length === 0) {
      dispatch(
        setTmpWorkSession({
          workSession: {
            id: getRandomArbitrary(0, 9999999),
            ...workSessions[0],
            projectID,
            projectPieceIDs: projectPieces?.reduce((a, v) => {
              a.push(v.id);
              return a;
            }, []),
          },
          index: 0,
        })
      );
    }
  }, [projectID, mode]);

  useEffect(() => {
    if (projectPieces?.length) {
      const _workSessions = JSON.parse(JSON.stringify(workSessions ?? "[]"));
      _workSessions.forEach(
        (w) =>
          (w.projectPieceIDs = w.projectPieceIDs
            ? w.projectPieceIDs
            : projectPieces?.reduce((a, v) => {
                a.push(v.id);
                return a;
              }, []))
      );
      dispatch(setTmpWorkSessions(_workSessions));
    }
  }, [projectPieces]);

  const getEventsFromWorkSession = () => {
    const events = [];
    for (const key in workSessions) {
      if (Object.hasOwnProperty.call(workSessions, key)) {
        const workSession = workSessions[key];
        events.push({
          id: workSession.id,
          title: `${workSession.title}. ${
            venuesMap[workSession.venueID]?.name ?? "No Venue"
          }.`,
          start: workSession.dateFromUTC,
          end: workSession.dateToUTC,
          color: workSession.projectColor,
          allDay: false,
          editable: true,
        });
      }
    }
    return events;
  };

  const processRowUpdate = async (_new: any, _old: any) => {
    const index = workSessions?.findIndex((w) => w.id === _new.id);
    const type = typeOptions[_new.typeOptionID - 1];
    const body: any = {
      ..._new,
      type: type?.title,
      typeOptionID: type?.id,
      sessionTypeID: type?.sessionTypeID,
      venueID: _new.venueID ? parseInt(`${_new.venueID}`) : undefined,
    };

    if (body.venueID) {
      body.tzName = venuesMap[body.venueID]?.tzName;
    } else {
      body.tzName = "";
    }

    if (_old.dateAndTime !== _new.dateAndTime) {
      const split = body.dateAndTime.split("|");
      const dateFromUTC = split[0];
      const dateToUTC = split[1];
      const tzName = split[2];
      body.dateFromUTC = dateFromUTC;
      body.dateToUTC = dateToUTC;
      body.tzName = tzName;
      delete body.dateAndTime;
    }

    dispatch(setTmpWorkSession({ index, workSession: body }));
    return body;
  };

  const saveWorkSessionNeeds = (_workSessionNeeds) => {
    if (_workSessionNeeds) {
      const _workSessions = [];
      for (const key in _workSessionNeeds) {
        if (Object.hasOwnProperty.call(_workSessionNeeds, key)) {
          const workSessionNeed = _workSessionNeeds[key];
          const typeOption = typeOptions.find((t) => t.id === Number(key));
          for (let index = 0; index < Number(workSessionNeed); index++) {
            const workSession = workSessions.filter(
              (w) => w.type === typeOption.title
            )[index];
            _workSessions.push({
              id: getRandomArbitrary(0, 9999999),
              title: `${typeOption.title}`,
              sessionTypeID: typeOption.sessionTypeID,
              dateFromUTC: workSession?.dateFromUTC,
              dateToUTC: workSession?.dateToUTC,
              venueID: workSession?.venueID,
              tzName: workSession?.tzName,
              description: workSession?.description,
              memo: workSession?.memo,
              whosPlayingMeta: workSession?.whosPlayingMeta,
              icon: typeOption.icon,
              projectID: workSession?.projectID ?? projectID,
              projectPieceIDs: workSession?.projectPieceIDs?.length
                ? workSession.projectPieceIDs
                : projectPieces?.reduce((a, v) => {
                    a.push(v.id);
                    return a;
                  }, []),
              type: typeOption.title,
              typeOptionID: typeOption?.id,
            });
          }
        }
      }
      dispatch(setTmpWorkSessions(_workSessions));
    }
  };

  function getBody(workSession) {
    if (!workSession) return {};
    const body = {
      _id: workSession.id,
      _typeOptionID: workSession.typeOptionID,
      title: workSession.title,
      type: workSession.type,
      whosPlayingMeta: workSession.whosPlayingMeta,
      sessionTypeID: workSession.sessionTypeID ?? 1,
      projectID: workSession.projectID,
      description: workSession.description,
      memo: workSession.memo,
      projectPieceIDs: workSession.projectPieceIDs,
      projectPiecesOrder: JSON.stringify(workSession.projectPieceIDs ?? []),
      mainEngagement: {
        tzName: workSession.tzName,
        venueID: workSession.venueID,
        dateFromUTC: workSession.dateFromUTC,
        dateToUTC: workSession.dateToUTC,
        noTimeRange: workSession.noTimeRange,
      },
    };

    return body;
  }

  const onWorkSessionNeedsChange = (id) => (e) => {
    if (e.target.value.length && e.target.value[0] === "-") {
      dispatch(setWorkSessionNeeds({ ...workSessionNeeds, [id]: "0" }));
      saveWorkSessionNeeds({ ...workSessionNeeds, [id]: "0" });
      return;
    }
    dispatch(
      setWorkSessionNeeds({ ...workSessionNeeds, [id]: e.target.value })
    );
    saveWorkSessionNeeds({ ...workSessionNeeds, [id]: e.target.value });
  };

  const schema = yup.object().shape({
    sessionTypeID: yup.number().required("Select a session type"),
    title: yup.string().required("Missing session title"),
    projectID: yup.number().required("Select a project"),
    _typeOptionID: yup.number().required("Select a type"),
    type: yup.string(),
    standalone: yup.bool(),
    mainEngagement: yup
      .object()
      .shape({
        tzName: yup.string(),
        venueID: yup.number().required("Select a venue"),
        noTimeRange: yup.bool(),
        dateFromUTC: yup.string().required("Date From is Required"),
        dateToUTC: yup
          .string()
          .required("Date To is Required")
          .when("dateFromUTC", (dateFromUTC, schema) => {
            return schema.test(
              "is-greater",
              "Work session needs a Duration",
              (dateToUTC, ctx) => {
                const { path, createError } = ctx;
                if (!dateFromUTC || !dateToUTC) return true;
                const start = moment(dateFromUTC);
                const end = moment(dateToUTC);

                if (end.isSameOrBefore(start)) {
                  return createError({
                    path,
                    message: "Work session needs a Duration",
                  });
                }

                return true;
              }
            );
          }),
      })
      .required(),
    projectPieceIDs:
      projectPieces?.length > 0
        ? yup.array().of(yup.number()).min(1, "Select some Pieces")
        : undefined,
    // whosPlayingMeta:
    //   projectPieces?.length === 0
    //     ? yup.string().test("", "Instrumentation is missing", (value) => {
    //         if (!value) return false;
    //         try {
    //           const parse = JSON.parse(value);
    //           if (Object.keys(parse).length > 0) return true;
    //         } catch (error) {}
    //         return false;
    //       })
    //     : yup.string(),
  });

  const create = async () => {
    setErrors(null);
    let hasErrors = false;
    let ws = workSessions;
    if (mode === "single") ws = [workSessions[0]];
    setErrors([]);

    for (const key in ws) {
      if (Object.hasOwnProperty.call(ws, key)) {
        const workSession = ws[key];
        schema
          .validate(getBody(workSession), {
            abortEarly: false,
          })
          .catch(function (err: YupError) {
            setErrors((e) => [...e, err]);
            hasErrors = true;
          });
      }
    }

    setTimeout(async () => {
      if (!hasErrors) {
        for (const key in ws) {
          if (Object.hasOwnProperty.call(ws, key)) {
            const workSession = ws[key];
            const body = getBody(workSession);
            createWorkSession(body)
              .unwrap()
              .then((e) => {
                selectWorkSession(e.id);
              });
          }
        }

        dispatch(setFormOpen({ isOpen: false, formID: "workSession" }));
        dispatch(setTmpWorkSessions([]));
        dispatch(setWorkSessionNeeds({}));
      }
    }, 400);
  };

  const getHeader = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        pr: 2,
        pt: 2,
        pl: 4,
        alignItems: "center",
      }}
    >
      <Box>
        <Typography level="h5" sx={{ fontWeight: 600 }}>
          New Work Session{mode !== "single" ? "s" : ""}
        </Typography>
      </Box>
      <Box>
        <Tabs
          onChange={(e, v) => {
            dispatch(setWorkSessionMode(v));
            setErrors(null);
          }}
          value={mode}
          size="sm"
          variant="soft"
          sx={{ width: 200, borderRadius: 3 }}
        >
          <TabList variant="outlined" color="neutral">
            <Tab value="single">Single</Tab>
            <Tab value="datagrid">Multiple</Tab>
          </TabList>
        </Tabs>
      </Box>
    </Box>
  );

  const getWorkSessionTypeSelect = (index, compact) => (
    <TextField
      variant={compact ? undefined : "standard"}
      label={"Type"}
      inputProps={
        compact != true && {
          style: { fontSize: 24 },
        }
      }
      select
      value={workSessions[index]?.sessionTypeID ?? 1}
      placeholder={compact ? "Type" : "Select Type"}
      sx={{ width: compact ? 65 : 250 }}
      size={compact ? "small" : "medium"}
      onChange={(e) => {
        const sessionTypeID = e.target.value;
        dispatch(
          setTmpWorkSession({
            workSession: {
              ...workSessions[index],
              sessionTypeID: parseInt(e.target.value),
              type:
                typeOptions.find(
                  (to) => to.sessionTypeID === parseInt(sessionTypeID)
                )?.title ?? "Other",
            },
            index,
          })
        );
      }}
    >
      {sessionTypes.map((t) => (
        <MenuItem key={t.id} value={t.id}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Avatar
              color="primary"
              variant="soft"
              size="sm"
              sx={{ height: 24, width: 24 }}
            >
              {t.code}
            </Avatar>
            <Typography sx={{ pl: compact ? 3 : 0 }}>{t.name}</Typography>
          </Box>
        </MenuItem>
      ))}
    </TextField>
  );

  const getNameTextField = (index, compact) => (
    <JoyTextField
      fullWidth
      variant="soft"
      value={workSessions[index]?.title ?? ""}
      placeholder={compact ? "Title" : "Add Title"}
      size={compact ? "sm" : "lg"}
      onChange={(e) =>
        dispatch(
          setTmpWorkSession({
            workSession: {
              ...workSessions[index],
              title: e.target.value,
            },
            index,
          })
        )
      }
    />
  );

  const getProjectSelect = (index) => (
    <Autocomplete
      fullWidth
      size="small"
      id="combo-box-demo"
      value={workSessions[index]?.projectID}
      inputValue={
        projects?.find((p) => p.id === workSessions[index]?.projectID)?.name ??
        ""
      }
      options={getOptions(projects)}
      onChange={(e, v) => {
        if (v) {
          dispatch(
            setTmpWorkSession({
              workSession: {
                ...workSessions[index],
                whosPlayingMeta: "",
                projectID: v.id,
              },
              index,
            })
          );
        } else {
          dispatch(
            setTmpWorkSession({
              workSession: {
                ...workSessions[index],
                projectID: 0,
              },
              index,
            })
          );
        }
      }}
      renderInput={(params) => <TextField {...params} label="Project" />}
    />
  );

  const getVenueFinder = (index) => (
    <VenueFinder
      key={workSessions[index]?.venueID}
      venueID={workSessions[index]?.venueID}
      setVenue={(v) => {
        if (v) {
          dispatch(
            setTmpWorkSession({
              workSession: {
                ...workSessions[index],
                venueID: v.id,
                tzName: v.tzName,
              },
              index,
            })
          );
        } else {
          dispatch(
            setTmpWorkSession({
              workSession: {
                ...workSessions[index],
                venueID: undefined,
                tzName: "",
              },
              index,
            })
          );
        }
      }}
    />
  );

  const getDateAndTime = (index) => {
    const dateFrom = workSessions[index]?.dateFromUTC
      ? DateTime.fromISO(workSessions[index]?.dateFromUTC)
      : null;
    const dateTo = workSessions[index]?.dateToUTC
      ? DateTime.fromISO(workSessions[index]?.dateToUTC)
      : null;

    const diff = dateTo?.diff(dateFrom, [
      "years",
      "months",
      "days",
      "hours",
      "minutes",
    ]);

    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            flexDirection: "column",
            width: 500,
          }}
        >
          <DateTimePicker
            tzName={workSessions[index]?.tzName ?? moment.tz.guess()}
            dateFrom={workSessions[index]?.dateFromUTC}
            dateTo={workSessions[index]?.dateToUTC}
            onChange={(dateFromUTC, dateToUTC) => {
              dispatch(
                setTmpWorkSession({
                  workSession: {
                    ...workSessions[index],
                    dateFromUTC,
                    dateToUTC,
                  },
                  index,
                })
              );
            }}
          />
        </Box>
        {diff ? (
          <Typography sx={{ pl: 5 }} level="body2">
            Total Duration:{" "}
            {diff?.toObject().days ? `${diff?.toObject().days}d ` : ""}
            {diff?.toObject().hours}h {diff?.toObject().minutes}m
          </Typography>
        ) : (
          []
        )}
      </Box>
    );
  };

  const getWhosPlaying = (index: number, defaultOpen: boolean) => (
    <WhosPlaying
      defaultOpen={defaultOpen}
      projectID={workSessions[index]?.projectID}
      whosPlayingMeta={workSessions[index]?.whosPlayingMeta ?? ""}
      onSubmit={(e) => {
        dispatch(
          setTmpWorkSession({
            workSession: {
              ...workSessions[index],
              whosPlayingMeta: JSON.stringify(e),
            },
            index,
          })
        );
      }}
    />
  );

  const getSingleDate = () => {
    const workSession = workSessions[0];
    return (
      <Box sx={{ display: "flex", gap: 2, flexDirection: "column", p: 1 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "end", height: 30 }}>
          {typeOptions
            .filter((e) => (project?.version === 2 ? e.id !== 6 : true))
            .map((t) => {
              const selected = t.id === workSession?.typeOptionID;
              return (
                <Button
                  key={t.id}
                  size="sm"
                  variant={selected ? "solid" : "soft"}
                  onClick={() => {
                    dispatch(
                      setTmpWorkSession({
                        workSession: {
                          ...workSession,
                          title:
                            workSession?.title &&
                            !typeOptions?.find(
                              (o) => o.title === workSession.title
                            )
                              ? workSession.title
                              : t.title,
                          type: t.title,
                          typeOptionID: t.id,
                          sessionTypeID: t.sessionTypeID,
                        },
                        index: 0,
                      })
                    );
                    // titleTextfield?.current?.focus();
                  }}
                >
                  <i className={`${t.icon} p-right`}></i>
                  {t.title}
                </Button>
              );
            })}
          {workSession?.typeOptionID === 6
            ? getWorkSessionTypeSelect(0, false)
            : []}
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "end" }}>
          {getNameTextField(0, false)}
        </Box>
        <Textarea
          placeholder="Anything to write down about this Work Session?"
          sx={{ width: "100%", background: "rgba(255,235,59,0.1)" }}
          onChange={(e) => {
            dispatch(
              setTmpWorkSession({
                workSession: {
                  ...workSession,
                  description: e.target.value,
                },
                index: 0,
              })
            );
          }}
          value={workSession?.description ?? ""}
          size="sm"
          endDecorator={
            <Typography level="body4">Not visible by musicians</Typography>
          }
        />
        {projectsMap[projectID]?.version === 2 ? (
          <Textarea
            placeholder="Work Session Memo. Ex: Strings only"
            sx={{ width: "100%" }}
            onChange={(e) => {
              dispatch(
                setTmpWorkSession({
                  workSession: {
                    ...workSession,
                    memo: e.target.value,
                  },
                  index: 0,
                })
              );
            }}
            value={workSession?.memo ?? ""}
            size="sm"
            endDecorator={
              <Typography level="body4">Visible by musicians</Typography>
            }
          />
        ) : (
          []
        )}
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={{ width: 20 }}>
            <i className="fa-light fa-folder-open"></i>
          </Box>
          <Box sx={{ width: 485 }}>{getProjectSelect(0)}</Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={{ width: 20 }}>
            <i className="fa-light fa-location-dot"></i>
          </Box>
          <Box sx={{ width: 485 }}>{getVenueFinder(0)}</Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>{getDateAndTime(0)}</Box>
        {projectAlternates.length > 0 || projectPieces?.length > 0 ? (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box sx={{ width: 20 }}>
              {projectPieces?.length ? (
                []
              ) : (
                <i className="fa-light fa-users"></i>
              )}
            </Box>
            {projectPieces?.length
              ? getProjectPieces(0)
              : getWhosPlaying(0, false)}
          </Box>
        ) : (
          []
        )}
        {errors?.length ? (
          <Alert color="danger">
            {errors.map((e) => (
              <Box key={e.value._id}>
                <span>For {e.value.title}</span>
                <ul>
                  {e.errors.map((d) => (
                    <li key={d}>{d}</li>
                  ))}
                </ul>
              </Box>
            ))}
          </Alert>
        ) : (
          []
        )}
      </Box>
    );
  };

  const getProjectPieces = (index) => {
    const selectAllProjectPieces = () => {
      dispatch(
        setTmpWorkSession({
          workSession: {
            ...workSessions[index],
            projectPieceIDs: projectPieces?.reduce((a, v) => {
              a.push(v.id);
              return a;
            }, []),
          },
          index,
        })
      );
    };

    if (projectPieces?.length > 1)
      return (
        <Box sx={{ width: "100%", maxWidth: 486 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontWeight: 600 }}>Pieces:</Typography>
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={selectAllProjectPieces}
              level="body3"
            >
              Select All
            </Typography>
          </Box>
          <List>
            {projectPieces?.map((p, i) => {
              const projectPieceIDs = workSessions[index]?.projectPieceIDs;
              const selected = workSessions[index]?.projectPieceIDs?.includes(
                p.id
              );
              const add = () => {
                return [...projectPieceIDs, p.id];
              };

              const remove = (i: number) => {
                const _projectPieceIDs = [...projectPieceIDs];
                _projectPieceIDs.splice(projectPieceIDs.indexOf(i), 1);
                return _projectPieceIDs;
              };

              return (
                <ProjectPieceListItemButton
                  key={p.id}
                  id={p.pieceID}
                  disableMenu
                  disableCheck={
                    workSessions[index]?.projectPieceIDs?.length === 1 &&
                    workSessions[index]?.projectPieceIDs[0] === p.id
                  }
                  color="primary"
                  selected={selected}
                  onClick={() =>
                    dispatch(
                      setTmpWorkSession({
                        workSession: {
                          ...workSessions[index],
                          projectPieceIDs: selected ? remove(p.id) : add(),
                        },
                        index,
                      })
                    )
                  }
                />
              );
            })}
          </List>
        </Box>
      );
  };

  const getActions = () => {
    const workSession = workSessions[0] ?? {};
    const disabledForMultipleStep0 = !workSession.projectID;
    const disabledForSingle =
      !workSession.venueID ||
      !workSession.projectID ||
      !workSession.dateToUTC ||
      !workSession.title ||
      !workSession.dateFromUTC;

    const disabled =
      mode !== "single" ? disabledForMultipleStep0 : disabledForSingle;

    return (
      <DialogActions>
        <Button
          variant="soft"
          color="neutral"
          onClick={() => {
            dispatch(setFormOpen({ isOpen: false, formID: "workSession" }));
          }}
        >
          Cancel
        </Button>
        {!callSent || debug ? (
          <Button onClick={create} sx={{ opacity: disabled ? 0.3 : 1 }}>
            Create
          </Button>
        ) : (
          []
        )}
      </DialogActions>
    );
  };

  const handleCalendarChange = (index: number, start: Date, end: Date) => {
    const w = workSessions[index];
    dispatch(
      setTmpWorkSession({
        index,
        workSession: {
          ...w,
          dateFromUTC: moment(start).utc().format(),
          dateToUTC: moment(end).utc().format(),
          tzName: w.tzName ?? moment.tz.guess(),
        },
      })
    );
  };

  const handleDateSelect = (selectInfo) => {
    const index = workSessions?.findIndex((w) => w.id === workSessionID);
    const w = workSessions[index];
    dispatch(
      setTmpWorkSession({
        workSession: {
          ...w,
          dateFromUTC: moment(selectInfo.startStr).utc().format(),
          dateToUTC: moment(selectInfo.endStr).utc().format(),
          tzName: w.tzName ?? moment.tz.guess(),
        },
        index: index,
      })
    );
    dispatch(setFormOpen({ isOpen: true, formID: "workSession" }));
  };

  const columns: GridColDef<any>[] = [
    {
      field: "typeOptionID",
      editable: true,
      headerName: "Type",
      type: "singleSelect",
      getOptionValue: (value: any) => value.id,
      getOptionLabel: (value: any) => value.title,
      valueOptions: typeOptions,
      renderCell: (c) => (
        <Typography
          level="body2"
          sx={{
            color: "inherit",
            fontStyle: !c.value ? "italic" : undefined,
            opacity: !c.value ? 0.5 : 1,
          }}
          startDecorator={<i className={typeOptions[c.value - 1]?.icon}></i>}
        >
          {typeOptions[c.value - 1]?.title
            ? typeOptions[c.value - 1]?.title
            : "Not set"}
        </Typography>
      ),
      width: 160,
    },
    {
      field: "title",
      headerName: "Title",
      type: "string",
      editable: true,
      renderCell: (e) => (
        <span
          style={{
            fontStyle: !e.value ? "italic" : undefined,
            opacity: !e.value ? 0.5 : 1,
          }}
        >
          {e.value ? e.value : "Not set"}
        </span>
      ),
      width: 180,
    },
    {
      field: "venueID",
      headerName: "Venue",
      type: "string",
      editable: true,
      groupingValueGetter: (e) => e.value,

      width: 200,
      valueFormatter: (e) => venuesMap[e.value]?.name,
      renderCell: (e) => (
        <span
          style={{
            fontStyle: !e.formattedValue ? "italic" : undefined,
            opacity: !e.row.venueID || !e.value ? 0.5 : 1,
          }}
        >
          {e.formattedValue ? e.formattedValue : "Not set"}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <FindVenue {...params} />
      ),
    },
    {
      field: "dateAndTime",
      headerName: "Date & Time",
      aggregable: false,
      editable: true,
      width: 220,
      valueGetter: (e) =>
        `${e.row.dateFromUTC ?? ""}|${e.row.dateToUTC ?? ""}|${
          e.row.tzName ?? moment.tz.guess()
        }`,
      valueFormatter: (e) => formatDateRange(e.value),
      renderCell: (e) => (
        <span
          style={{
            opacity: !e.formattedValue ? 0.5 : 1,
            fontStyle: !e.formattedValue ? "italic" : undefined,
          }}
        >
          {!e.formattedValue ? "Not set" : e.formattedValue}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <DateAndTime
          {...params}
          setDefaultDate={setDefaultDate}
          defaultDate={defaultDate}
        />
      ),
    },
    {
      field: "description",
      headerName: "Internal Memo",
      renderHeader: () => (
        <Box sx={{ lineHeight: 1 }}>
          <span style={{ fontWeight: 500 }}>Internal Memo</span>
          <Typography level="body4">Not visible by musicians</Typography>
        </Box>
      ),
      renderCell: (e) => (
        <span
          style={{
            fontStyle: !e.value ? "italic" : undefined,
            opacity: !e.value ? 0.5 : 1,
          }}
        >
          {e.value ? e.value : "Not set"}
        </span>
      ),
      type: "string",
      editable: true,
      width: 180,
    },
    {
      field: "memo",
      headerName: "Memo",
      renderHeader: () => (
        <Box sx={{ lineHeight: 1 }}>
          <span style={{ fontWeight: 500 }}>Memo</span>
          <Typography level="body4">Visible by musicians</Typography>
        </Box>
      ),
      renderCell: (e) => (
        <span
          style={{
            fontStyle: !e.value ? "italic" : undefined,
            opacity: !e.value ? 0.5 : 1,
          }}
        >
          {e.value ? e.value : "Not set"}
        </span>
      ),
      type: "string",
      editable: true,
      width: 200,
    },
  ];

  if (projectPieces?.length > 1) {
    columns.splice(4, 0, {
      field: "projectPieceIDs",
      headerName: "Plays Pieces",
      aggregable: false,
      type: "string",
      editable: false,
      width: 40 + projectPieces?.length * 40,
      renderCell: (e) => (
        <PiecesCell
          id={e.id}
          processRowUpdate={processRowUpdate}
          projectPieces={projectPieces}
          projectPiecesMap={projectPiecesMap}
          piecesMap={piecesMap}
          projectPieceIDs={e.value}
        />
      ),
    });
  }

  if (project?.version !== 2) {
    columns[4] = {
      field: "whosPlayingMeta",
      headerName: "Who's Playing?",
      aggregable: false,
      type: "string",
      editable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (e) =>
        getWhosPlaying(
          workSessions?.findIndex((w) => w.id === e.row.id),
          false
        ),
    };
  }

  const getDatagrid = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          position: "relative",
          width: "100%",
          height: "calc(100vh - 180px)",
          overflow: "auto",
          // minWidth: 1100,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: 1,
            flex: 1,
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                width: 0,
                minWidth: "auto",
                overflowX: "auto",
                flex: 1,
              }}
            >
              {typeOptions
                .filter((e) => (project?.version === 2 ? e.id !== 6 : true))
                .map((t) => (
                  <Input
                    variant="soft"
                    color={workSessionNeeds[t.id] > 0 ? "primary" : "neutral"}
                    onFocus={(e) => {
                      e.target.select();
                      setTimeout(() => {
                        e.target.select();
                      }, 200);
                    }}
                    size="md"
                    key={t.id}
                    slotProps={{
                      input: {
                        style: { textAlign: "right" },
                      },
                    }}
                    onChange={onWorkSessionNeedsChange(t.id)}
                    value={workSessionNeeds[t.id] ?? "0"}
                    startDecorator={
                      <Typography
                        level="body2"
                        noWrap
                        sx={{ color: "inherit" }}
                        startDecorator={<i className={t.icon} />}
                      >
                        {t.title}:
                      </Typography>
                    }
                    placeholder="0"
                    type="number"
                    sx={{ flexShrink: 0, minWidth: 170, flex: 1 }}
                  />
                ))}
              <Button
                onClick={() => {
                  dispatch(setTmpWorkSessions([]));
                  dispatch(setWorkSessionNeeds({}));
                }}
                endDecorator={<i className="fa-regular fa-delete-left"></i>}
                variant="solid"
                color="neutral"
              >
                Reset
              </Button>
            </Box>
          </Box>
          {workSessions.length ? (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flex: 1,
                width: "100%",
                position: "relative",
              }}
            >
              {debug ? (
                <pre style={{ fontSize: 12 }}>
                  {JSON.stringify(workSessions, null, 4)}
                </pre>
              ) : (
                []
              )}
              <Box
                sx={{
                  width: 320,
                  height: "100%",
                  display: "flex",
                  position: "relative",
                  flexDirection: "column",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e)}
                    slots={{
                      day: CustomDay,
                    }}
                    slotProps={{
                      day: {
                        workSessions: workSessions,
                      } as any,
                    }}
                  />
                </LocalizationProvider>
                <Box sx={{ flexGrow: 1, position: "relative" }}>
                  <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      left: "",
                      center: "",
                      right: "",
                    }}
                    expandRows={true}
                    slotDuration={"00:30:00"}
                    // slot
                    key={workSessionID}
                    scrollTimeReset={true}
                    scrollTime={
                      workSession
                        ? moment(workSession.dateFromUTC)
                            .subtract(1, "hour")
                            .format("HH:mm:ss")
                        : "8:00:00"
                    }
                    allDaySlot={false}
                    selectable={workSessionID !== undefined} // Enable selecting ranges
                    select={handleDateSelect} // Attach the select callback
                    events={getEventsFromWorkSession()}
                    height={"100%"}
                    initialView="timeGridDay"
                    eventChange={(e) => {
                      const index = workSessions?.findIndex(
                        (w) => `${w.id}` === e.event.id
                      );
                      handleCalendarChange(index, e.event.start, e.event.end);
                    }}
                    eventContent={(e) => <RenderEventContent eventInfo={e} />}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  flexGrow: 1,
                  minWidth: "auto",
                  width: 0,
                }}
              >
                {errors?.length ? (
                  <Alert variant="solid" color="danger">
                    Cells in red are required. Please fill them out and retry.
                  </Alert>
                ) : (
                  []
                )}
                <DataGridPremium
                  getRowClassName={(r) =>
                    r.row.id === workSessionID ? "row-selected" : undefined
                  }
                  onCellModesModelChange={(e) =>
                    console.log("onCellModesModelChange")
                  }
                  onCellClick={(e) => {
                    if (e.isEditable && e.field !== "typeOptionID") {
                      apiRef.current?.startCellEditMode({
                        id: e.id,
                        field: e.field,
                      });
                    }
                  }}
                  // onCellKeyDown={(e) => setWorkSessionID(e.row.id)}
                  getCellClassName={(c) => {
                    if (
                      (!c.value &&
                        errors?.find(
                          (e) =>
                            e.value._id === c.id &&
                            e.inner.find((i) => i.path.indexOf(c.field) >= 0)
                        )) ||
                      (errors &&
                        c.field === "dateAndTime" &&
                        (!c.row.dateFromUTC || !c.row.dateToUTC)) ||
                      (errors &&
                        c.field === "projectPieceIDs" &&
                        c.value.length === 0)
                    ) {
                      return "cell-issue";
                    }
                  }}
                  onRowClick={(p) => setWorkSessionID(p.row.id)}
                  density="compact"
                  onProcessRowUpdateError={(e) => console.log(e)}
                  apiRef={apiRef}
                  autoHeight
                  hideFooter
                  rowSelection={false}
                  unstable_cellSelection
                  processRowUpdate={processRowUpdate}
                  experimentalFeatures={{ clipboardPaste: true }}
                  unstable_ignoreValueFormatterDuringExport
                  columns={columns}
                  rows={workSessions ?? []}
                />
              </Box>
            </Box>
          ) : (
            <Box sx={{ mt: 2 }}>
              <Typography
                startDecorator={
                  <i
                    style={{ transform: "scaleX(-1)" }}
                    className="fa-solid fa-turn-up"
                  ></i>
                }
                level="h6"
              >
                Select the number of Work Sessions for your Project.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const Dialog: any = mode === "datagrid" ? SlidingDialog : DialogClose;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      fullScreen={mode === "datagrid"}
      maxWidth={callSent && !debug ? "sm" : "lg"}
      onClose={() => {
        dispatch(setFormOpen({ isOpen: false, formID: "workSession" }));
      }}
    >
      {callSent && !debug ? (
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography level="display2" color="danger">
            <i className="fa-solid fa-triangle-exclamation"></i>
          </Typography>
          <Typography level="h6" color="danger">
            You can't add a new Work Session
          </Typography>
          <Typography level="body2" color="danger">
            The call has already been sent (Musicians have been contacted).
            <br />
            You are no longer allowed to add a new Work Session for this
            Project.
            <br />
            For more assistance, chat with us.
          </Typography>
          <Button
            sx={{ mt: 2 }}
            color="danger"
            startDecorator={<i className="fa-solid fa-comment"></i>}
            onClick={() => {
              show();
            }}
          >
            Chat with us
          </Button>
        </Box>
      ) : (
        []
      )}
      {(!callSent || debug) && getHeader()}
      {mode === "single" && (!callSent || debug) && (
        <DialogContent>{getSingleDate()}</DialogContent>
      )}
      {mode === "datagrid" && (!callSent || debug) && getDatagrid()}

      {getActions()}
    </Dialog>
  );
}

function getOptions(items) {
  const res = [];
  for (const key in items) {
    if (Object.prototype.hasOwnProperty.call(items, key)) {
      const item = items[key];
      res.push({
        id: item.id,
        label: `${item.name}`,
      });
    }
  }

  return res;
}

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

function FindVenue(props: GridRenderEditCellParams) {
  const { id, value, field, hasFocus } = props;
  const [v, setV] = useState(value);
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    if (hasFocus) {
      const _r = ref as any;
      _r?.current?.focus();
    }
  }, [hasFocus]);

  const handleValueChange = (venue) => {
    if (venue) {
      setV(venue.id);
      apiRef.current.setEditCellValue({ id, field, value: venue.id });
    } else {
      setV(undefined);
      apiRef.current.setEditCellValue({ id, field, value: undefined });
    }
  };

  return (
    <VenueFinder
      simple
      key={v}
      venueID={v}
      setVenue={handleValueChange}
      autofocus
    />
  );
}

function PiecesCell({
  id,
  projectPieceIDs,
  projectPieces,
  projectPiecesMap,
  piecesMap,
  processRowUpdate,
}: {
  id: GridRowId;
  projectPieceIDs: number[];
  projectPieces: ProjectPiece[];
  projectPiecesMap: Dictionary<ProjectPiece>;
  piecesMap: Dictionary<Piece>;
  processRowUpdate: (_new: any, _old: any) => Promise<any>;
}) {
  const apiRef = useGridApiContext();
  const [_value, setValue] = useState<number[]>(projectPieceIDs);

  return (
    <Box sx={{ display: "flex", gap: 0.5 }}>
      {projectPieces?.map((v, i) => {
        const projectPiece = projectPiecesMap[v.id];
        const piece = piecesMap[projectPiece?.pieceID];
        return (
          <Tooltip
            key={v.id}
            title={`${piece?.name}, ${piece?.composer}`}
            variant="outlined"
            size="sm"
            arrow
            placement="top"
          >
            <Avatar
              onClick={(e) => {
                let val = _value;
                if (_value?.includes(v.id)) {
                  val = val.filter((s) => s !== v.id);
                } else {
                  val = [...val, v.id];
                }
                setValue(val);

                const row = apiRef.current.getRow(id);
                processRowUpdate({ ...row, projectPieceIDs: val }, row);
              }}
              sx={
                !_value?.includes(v.id)
                  ? {
                      background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='black' stroke-width='1'/></svg>")`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "100% 100%, auto;",
                      cursor: "pointer",
                    }
                  : { cursor: "pointer" }
              }
              size="sm"
            >
              {v.position}
            </Avatar>
          </Tooltip>
        );
      })}
    </Box>
  );
}

function Pieces(
  props: GridRenderEditCellParams & {
    projectPieces: ProjectPiece[];
    projectPiecesMap: Dictionary<ProjectPiece>;
    piecesMap: Dictionary<Piece>;
  }
) {
  const { id, value, field, hasFocus, projectPieces } = props;
  const [_value, setValue] = useState<number[]>(value);
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    if (hasFocus) {
      const _r = ref as any;
      _r?.current?.focus();
    }
  }, [hasFocus]);

  const save = () => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: _value,
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <DialogClose
      open
      maxWidth="sm"
      fullWidth
      onClose={() => apiRef.current.stopCellEditMode({ id, field })}
    >
      <DialogTitle>Select the piece played on this Work Session</DialogTitle>
      <DialogContent>
        <List>
          {projectPieces?.map((p, i) => {
            const selected = _value?.includes(p.id);
            return (
              <ProjectPieceListItemButton
                key={p.id}
                id={p.pieceID}
                disableMenu
                disableCheck={_value?.length === 1 && _value[0] === p.id}
                color="primary"
                selected={selected}
                onClick={() => {
                  if (selected) {
                    setValue((e) => e?.filter((i) => i !== p.id));
                  } else {
                    setValue((e) => [...e, p.id]);
                  }
                }}
              />
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="soft"
          color="neutral"
          onClick={() => apiRef.current.stopCellEditMode({ id, field })}
        >
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}

function DateAndTime(
  props: GridRenderEditCellParams & {
    defaultDate: { dateFromUTC: string | null; dateToUTC: string | null };
    setDefaultDate: (e: { dateFromUTC: string; dateToUTC: string }) => void;
  }
) {
  const { id, value, field, hasFocus, setDefaultDate, defaultDate } = props;
  const split = value.split("|");
  const dateFromUTC = split[0];
  const dateToUTC = split[1];
  const tzName = split[2];

  const [_dateFromUTC, setDateFromUTC] = useState(
    dateFromUTC ? dateFromUTC : defaultDate.dateFromUTC
  );
  const [_dateToUTC, setDateToUTC] = useState(
    dateToUTC ? dateToUTC : defaultDate.dateToUTC
  );

  const apiRef = useGridApiContext();
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    if (hasFocus) {
      const _r = ref as any;
      _r?.current?.focus();
    }
  }, [hasFocus]);

  const dateFrom = _dateFromUTC ? DateTime.fromISO(_dateFromUTC) : null;
  const dateTo = _dateToUTC ? DateTime.fromISO(_dateToUTC) : null;

  const diff = dateTo?.diff(dateFrom, [
    "years",
    "months",
    "days",
    "hours",
    "minutes",
  ]);
  const save = () => {
    const value = `${_dateFromUTC}|${_dateToUTC}|${tzName}`;
    apiRef.current.setEditCellValue({
      id,
      field,
      value,
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <DialogClose
      open
      maxWidth="sm"
      fullWidth
      onClose={() => apiRef.current.stopCellEditMode({ id, field })}
    >
      <DialogTitle>Date & Time</DialogTitle>
      <DialogContent>
        <DateTimePicker
          dateFrom={_dateFromUTC}
          dateTo={_dateToUTC}
          tzName={tzName ?? moment.tz.guess()}
          onChange={(startDate, endDate) => {
            setDateFromUTC(startDate);
            setDateToUTC(endDate);
            setDefaultDate({
              dateFromUTC: startDate,
              dateToUTC: endDate,
            });
          }}
        />
        {diff ? (
          <Typography sx={{ pl: 5 }} level="body2">
            Total Duration:{" "}
            {diff?.toObject().days ? `${diff?.toObject().days}d ` : ""}{" "}
            {diff?.toObject().hours}h {diff?.toObject().minutes}m
          </Typography>
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="soft"
          color="neutral"
          onClick={() => apiRef.current.stopCellEditMode({ id, field })}
        >
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}

function formatDateRange(value: string) {
  const split = value.split("|");
  const dateFromUTC = split[0];
  const dateToUTC = split[1];
  const tzName = split[2];
  if (!dateFromUTC || !dateToUTC) return "";
  if (dateFromUTC === dateToUTC) {
    return moment.tz(dateFromUTC, tzName).format("ll");
  }
  return `${moment.tz(dateFromUTC, tzName).format("MM/DD/YY h:mm A")} - ${moment
    .tz(dateToUTC, tzName)
    .format("h:mm A")}`;
}

function CustomDay(
  props: PickersDayProps<Moment> & { workSessions?: Partial<WorkSession>[] }
) {
  const { workSessions = [], day, outsideCurrentMonth, ...other } = props;
  const count = workSessions.filter(
    (w) => w.dateFromUTC && moment(w.dateFromUTC).isSame(day, "day")
  ).length;

  return (
    <Badge
      key={props.day.toString()}
      color="danger"
      size="sm"
      badgeContent={!outsideCurrentMonth && count > 0 ? count : null}
    >
      <PickersDay
        sx={{
          background: count ? "#bbdefb" : undefined,
        }}
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

type YupError = {
  value: {
    _id: number;
    title: string;
    type: string;
    sessionTypeID: number;
    projectID: number;
    projectPieceIDs: number[];
    mainEngagement: any;
  };
  errors: string[];
  inner: {
    path: string;
  }[];
};
