import { PdLabor } from "entities/pdLabor";
import PdItem_Entity from "./pdItem";
export default class PdItem extends PdItem_Entity {
  labors: Array<PdLabor>;

  constructor(json: PdItem_Entity) {
    super(json);
    this.labors = PdLabor.fromList(json.labors);
  }

  static fromList(pdItemsJSON: unknown): Array<PdItem> {
    const pdItems: PdItem[] = [];
    if (pdItemsJSON)
      Array.isArray(pdItemsJSON) &&
        pdItemsJSON.forEach((pdItemJSON) => {
          pdItems.push(new PdItem(pdItemJSON));
        });
    return pdItems;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
