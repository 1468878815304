import { useFormerAssignments } from "./models/formerAssignments";
import { useDownloadMusicianImportTemplate } from "./models/musicianImportTemplate";
import { useDownloadMusiciansDatabaseExcel } from "./models/musiciansDatabase";
import { useDownloadPaydayExcel } from "./models/paydayTemplate";
import { useDownloadProjectActiveMusiciansExcel } from "./models/projectActiveMusicians";
import { useDownloadProjectRemovedMusiciansExcel } from "./models/projectRemovedMusicians";
import { useDownloadProjectSeatingExcel } from "./models/projectSeating";
import { useProjectV2Export } from "./models/projectV2";
import { useDownloadWorkSessionContinationFormExcel } from "./models/workSessionContinuationForm";
import { useDownloadWorkSessionRosterExcel } from "./models/workSessionRoster";
import { useDownloadWorkSessionStewardExcel } from "./models/workSessionSteward";

export function useExporter() {
  const projectV2 = useProjectV2Export();
  const projectActiveMusicians = useDownloadProjectActiveMusiciansExcel();
  const projectRemovedMusicians = useDownloadProjectRemovedMusiciansExcel();
  const workSessionRoster = useDownloadWorkSessionRosterExcel();
  const workSessionContinuationForm =
    useDownloadWorkSessionContinationFormExcel();
  const musicianImportTemplate = useDownloadMusicianImportTemplate();
  const workSessionSteward = useDownloadWorkSessionStewardExcel();
  const projectSeating = useDownloadProjectSeatingExcel();
  const musiciansDatabse = useDownloadMusiciansDatabaseExcel();
  const payday = useDownloadPaydayExcel();
  const formerAssignments = useFormerAssignments();

  return {
    projectV2,
    projectActiveMusicians,
    projectRemovedMusicians,
    workSessionRoster,
    workSessionContinuationForm,
    musicianImportTemplate,
    workSessionSteward,
    projectSeating,
    musiciansDatabse,
    payday,
    formerAssignments,
  };
}
