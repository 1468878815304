import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { PdItem_Entity } from "entities/pdItem";
import { paydayApi } from "redux/api/paydayApi";

const pdItemsAdapter = createEntityAdapter<PdItem_Entity>();
const initialState = pdItemsAdapter.getInitialState();

export const pdItemEndpoints = paydayApi.injectEndpoints({
  endpoints: (build) => ({
    getPdItems: build.query<EntityState<PdItem_Entity>, void>({
      query: () => `items`,
      transformResponse: (responseData: PdItem_Entity[]) => {
        return pdItemsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["pdItems"],
    }),
    getPdItem: build.query<PdItem_Entity, number>({
      query: (id) => `pdItems/${id}`,
      providesTags: (result, error, id) => [{ type: "pdItems", id }],
    }),
    createPdItem: build.mutation<PdItem_Entity, Partial<PdItem_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `items`,
      }),
      invalidatesTags: ["pdItems", "pdProjects"],
    }),
    updatePdItem: build.mutation<
      void,
      { id: number; body: Partial<PdItem_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `items/${args.id}`,
      }),
      invalidatesTags: ["pdItems", "pdProjects"],
    }),
    deletePdItem: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `items/${id}`,
      }),
      invalidatesTags: ["pdItems", "pdProjects"],
    }),
  }),
});

export const {
  useGetPdItemQuery,
  useGetPdItemsQuery,
  useCreatePdItemMutation,
  useDeletePdItemMutation,
  useUpdatePdItemMutation,
} = pdItemEndpoints;

export default pdItemEndpoints;
