import { Box, Button, IconButton, Tooltip, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import DialogClose from "atoms/DialogClose/DialogClose";
import { WhoGetsNote } from "hooks/projectSettings/projectSettings";
import { convert } from "html-to-text";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
  setSelectedProjectNoteID,
} from "reducers/rhapsody";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function NotifierProjectNotes() {
  const open = useSelector(formOpenSelector("notifierProjectNotes"));
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectNotes } = useProjectNotes(projectID);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "notifierProjectNotes" }));
  };

  return (
    <DialogClose maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-solid fa-pen-nib"></i>}
          level="h6"
        >
          Notes
        </Typography>
        <Typography level="body2">Add freeform notes to the call.</Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Tooltip
            title="New Link"
            variant="outlined"
            arrow
            size="sm"
            enterDelay={1000}
            enterNextDelay={1000}
          >
            <IconButton
              onClick={() =>
                dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }))
              }
              variant="solid"
              size="sm"
              color="neutral"
            >
              <i className="fa-solid fa-plus"></i>
            </IconButton>
          </Tooltip>
        </Box>
        {projectNotes.length ? (
          <DataGridPremium
            sx={{ background: "white" }}
            hideFooter
            onRowClick={(r) => {
              dispatch(setSelectedProjectNoteID(r.row.id));
              dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }));
            }}
            density="compact"
            initialState={{
              pinnedColumns: {
                right: ["target", "url"],
              },
            }}
            rows={projectNotes}
            columns={[
              { field: "name", headerName: "Name" },
              {
                field: "content",
                headerName: "Content",
                flex: 1,
                renderCell: (p) => {
                  return convert(p.row.value, {});
                },
              },
              {
                field: "target",
                headerName: "Who gets it?",
                width: 200,
                renderCell: (p) => {
                  return <WhoGetsNote note={p.row} />;
                },
              },
              {
                field: "url",
                headerName: "Auto Include",
                width: 100,
                align: "right",
                renderCell: (p) => (
                  <Typography color="success">
                    {p.row.include ? (
                      <i className="fa-duotone fa-solid fa-square-check"></i>
                    ) : (
                      []
                    )}
                  </Typography>
                ),
              },
            ]}
          />
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
