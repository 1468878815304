import { Button, Menu, MenuItem } from "@mui/joy";
import { Hidden } from "@mui/material";
import { Section } from "entities/section";
import { useState } from "react";
import { useSections } from "redux/section/sectionHooks";

/**
 *
 * @returns {ReactElement} AddSection page
 */
export function AddSection({
  onSelect,
  existingSectionIDs,
}: {
  onSelect: (sectionID: number) => void;
  existingSectionIDs?: number[];
}) {
  const { sections: _sections } = useSections();
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>();
  const [searchText, setSearchText] = useState("");

  const sections = existingSectionIDs
    ? _sections.filter((s) => existingSectionIDs.indexOf(s.id) === -1)
    : _sections;

  return (
    <>
      <Button
        startDecorator={<i className="fa-solid fa-plus"></i>}
        variant="soft"
        color="neutral"
        size="sm"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Hidden smDown>Add</Hidden> Section
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ maxHeight: 600, zIndex: 9999 }}
      >
        {/* <Box
          sx={{
            background: "white",
            position: "sticky",
            top: -8,
            zIndex: 999,
          }}
        >
          <Input
            value={searchText}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setSearchText(e.target.value);
            }}
            variant="soft"
            sx={{ m: 1 }}
            size="sm"
            startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
            placeholder="Search"
          />
        </Box> */}
        {sections
          .filter(
            (s) => s.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          )
          .map((s) => (
            <MenuItem
              onClick={() => {
                onSelect(s.id);
                setAnchorEl(null);
              }}
              key={s.id}
            >
              {s.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
