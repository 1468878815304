import SaveIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// material-ui

import PhoneInput from "atoms/PhoneInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector } from "reducers/rhapsody";
// styles
import DialogClose from "atoms/DialogClose/DialogClose";
import { setFormOpen } from "reducers/rhapsody";
import { useCreatePayrollMutation } from "redux/payroll/payrollEndpoints";

export default function FormPayroll() {
  const theme = useTheme();
  const formOpen = useSelector(formOpenSelector("payroll"));
  const [createPayroll, { isLoading }] = useCreatePayrollMutation();
  const sm = useMediaQuery(theme.breakpoints.down("lg"));
  const [payroll, setPayroll] = useState({
    name: "",
    email: "",
    phone: "",
    contact: "",
    address: "",
    zipcode: "",
    city: "",
    state: "",
  });

  const dispatch = useDispatch();

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setPayroll((s) => ({ ...s, [name]: value }));
  };

  const save = async () => {
    createPayroll(payroll);
    dispatch(setFormOpen({ isOpen: false, formID: "payroll" }));
  };

  const disabled = payroll.name.length === 0;

  return (
    <DialogClose
      open={formOpen}
      scroll="paper"
      onClose={() =>
        dispatch(setFormOpen({ isOpen: false, formID: "payroll" }))
      }
      aria-labelledby="responsive-dialog-title"
    >
      {isLoading ? (
        <div>
          <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogContent>
            <div
              style={{
                background: "#e3f2fd",
                padding: 16,
                borderRadius: "16px",
                textAlign: "center",
              }}
            >
              <i
                style={{ fontSize: 80, color: "#001E3C" }}
                class="fad fa-university"
              ></i>
              <Typography variant="h6" style={{ color: "#001E3C" }}>
                New Payroll
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                style={{ color: "#001E3C", opacity: 0.8 }}
              >
                This is the entity that handles your payroll.
              </Typography>
            </div>
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  id="name"
                  helperText="Required"
                  label="Name"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={payroll.name}
                  onChange={handleChange("name")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={payroll.email}
                  onChange={handleChange("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  id="phone"
                  label="Phone"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={payroll.phone}
                  onChange={handleChange("phone")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="contact"
                  label="Contact"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={payroll.contact}
                  onChange={handleChange("contact")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address"
                  label="Address"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={payroll.address}
                  onChange={handleChange("address")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="zipcode"
                  label="Zipcode"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={payroll.zipcode}
                  onChange={handleChange("zipcode")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="city"
                  label="City"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={payroll.city}
                  onChange={handleChange("city")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="state"
                  label="State"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={payroll.state}
                  onChange={handleChange("state")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                dispatch(setFormOpen({ isOpen: false, formID: "payroll" }))
              }
            >
              <CloseIcon />
              &nbsp;Cancel
            </Button>
            <Button
              style={{
                background: "#2196f3",
                color: "white",
                opacity: disabled ? 0.5 : 1,
              }}
              autoFocus
              disabled={disabled}
              onClick={save}
            >
              <SaveIcon />
              {"Create"}
            </Button>
          </DialogActions>
        </>
      )}
    </DialogClose>
  );
}
