import {
  Box,
  Button,
  Chip,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridActionsCellItem,
  GridEditInputCell,
  GridRowOrderChangeParams,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { PdGroup, PdGroup_Entity } from "entities/pdGroup";
import { PdItem, PdItem_Entity } from "entities/pdItem";
import { currencyFormatter, getterDivider, setterDivider } from "helpers";
import { ReactElement, useCallback, useEffect, useState } from "react";
import {
  useDeletePdGroupMutation,
  useUpdatePdGroupMutation,
} from "redux/pdGroup/pdGroupEndpoints";
import {
  useCreatePdItemMutation,
  useDeletePdItemMutation,
  useUpdatePdItemMutation,
} from "redux/pdItem/pdItemEndpoints";
import { PdLaborCard } from "./PdLabor";
import ColorPicker from "atoms/ColorPicker";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  alpha,
} from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";

/**
 *
 * @returns {ReactElement} PdGroupCard page
 */
export function PdGroupCard({
  pdGroup,
  subgroup,
  children,
  parent,
}: {
  pdGroup: PdGroup;
  subgroup?: boolean;
  children?: ReactElement;
  parent?: PdGroup;
}) {
  const apiRef = useGridApiRef();
  const [_pdGroup, setPdGroup] = useState<PdGroup_Entity>(pdGroup);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>();
  const [updatePdGroup] = useUpdatePdGroupMutation();
  const [deletePdGroup] = useDeletePdGroupMutation();
  const [updatePdItem] = useUpdatePdItemMutation();
  const [createPdItem] = useCreatePdItemMutation();
  const [deletePdItem] = useDeletePdItemMutation();

  useEffect(() => {
    setPdGroup;
  }, [pdGroup]);

  const onGroupUpdate = async (
    newPdItem: PdItem_Entity,
    oldPdItem: PdItem_Entity
  ) => {
    return new Promise<PdItem_Entity>((resolve) => {
      if (
        newPdItem.reservedKey === "discount" ||
        newPdItem.reservedKey === "extra"
      ) {
        delete newPdItem.rate;
      }

      if (!newPdItem.reservedKey) {
        newPdItem.scale = newPdItem.rate;
      }

      updatePdItem({ id: newPdItem.id, body: newPdItem });
      resolve(newPdItem);
    });
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    console.log({ ...params.row, position: params.targetIndex });
    updatePdItem({
      id: params.row.id,
      body: { ...params.row, position: params.targetIndex + 1 },
    });
  };

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps["getDetailPanelContent"]>
  >(
    ({ row }) => (
      <Sheet sx={{ p: 1, borderLeft: "solid 4px #3f51b5" }}>
        <PdLaborCard labors={row.labors} />
      </Sheet>
    ),
    []
  );

  return (
    <Box
      key={pdGroup.id}
      sx={{
        p: 1,
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        background:
          _pdGroup.color && !subgroup ? alpha(_pdGroup.color, 0.05) : "white",
        border: !subgroup
          ? `solid 1px ${_pdGroup.color ?? "#cfd8dc88"}`
          : undefined,
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <Input
          value={_pdGroup.name}
          size="sm"
          placeholder="Group Name"
          startDecorator="Name:"
          onChange={(e) => setPdGroup((g) => ({ ...g, name: e.target.value }))}
          onBlur={() => updatePdGroup({ id: _pdGroup.id, body: _pdGroup })}
        />
        {!subgroup ? (
          <ColorPicker
            label="Color:"
            color={_pdGroup.color}
            onChange={(c) => {
              setPdGroup((g) => ({ ...g, color: c }));
              updatePdGroup({
                id: _pdGroup.id,
                body: { ...pdGroup, color: c },
              });
            }}
          />
        ) : (
          []
        )}
        <Box sx={{ flexGrow: 1 }} />
        {pdGroup.reservedKey !== "orchestra" &&
        pdGroup.reservedKey !== "wages" &&
        pdGroup.reservedKey !== "pension" &&
        pdGroup.reservedKey !== "welfare" ? (
          <>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              variant="outlined"
              color="neutral"
              size="sm"
            >
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </IconButton>
            <Menu
              disablePortal
              size="sm"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                color="danger"
                onClick={() => {
                  setAnchorEl(null);
                  deletePdGroup(_pdGroup.id);
                }}
              >
                <i
                  className="fa-sharp fa-solid fa-trash"
                  style={{ paddingRight: 8 }}
                ></i>
                Delete Group
              </MenuItem>
            </Menu>
          </>
        ) : (
          children
        )}
      </Box>
      <Box
        sx={{
          background: "white",
          borderRadius: 4,
        }}
      >
        {pdGroup.subgroups?.length === 0 ? (
          <DataGridPremium
            apiRef={apiRef}
            experimentalFeatures={{ clipboardPaste: true }}
            unstable_cellSelection
            density="compact"
            rows={pdGroup.items}
            processRowUpdate={onGroupUpdate}
            onRowOrderChange={handleRowOrderChange}
            autoHeight
            hideFooter
            rowReordering
            getCellClassName={(p) => {
              let classes = "";
              if (!p.isEditable && p.colDef.type !== "actions")
                classes += "cellReadOnly ";
              return classes;
            }}
            slots={{
              detailPanelExpandIcon: MusicianIcon,
              detailPanelCollapseIcon: MusicianActiveIcon,
            }}
            getDetailPanelContent={
              pdGroup.reservedKey === "orchestra"
                ? getDetailPanelContent
                : undefined
            }
            getDetailPanelHeight={() => "auto"}
            columns={getPdColumnColumns(pdGroup, deletePdItem, parent)}
            initialState={{ pinnedColumns: { right: ["subtotal", "actions"] } }}
          />
        ) : (
          []
        )}
        {pdGroup.subgroups.length ? (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "column",
              mt: 1,
            }}
          >
            {pdGroup.subgroups?.map((g) => (
              <PdGroupCard subgroup key={g.id} pdGroup={g} parent={pdGroup} />
            ))}
          </Box>
        ) : (
          []
        )}
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        {pdGroup.reservedKey !== "orchestra" &&
        pdGroup.reservedKey !== "wages" &&
        pdGroup.reservedKey !== "pension" &&
        pdGroup.reservedKey !== "welfare" ? (
          <Chip
            size="sm"
            variant="outlined"
            color="neutral"
            sx={{ background: "white" }}
            onClick={() => {
              createPdItem({
                groupID: _pdGroup.id,
                projectID: _pdGroup.projectID,
              });
            }}
          >
            + Item
          </Chip>
        ) : (
          <Box />
        )}
        {pdGroup.reservedKey === "production" ? (
          <>
            <Chip
              size="sm"
              variant="outlined"
              color="info"
              sx={{ background: "white" }}
              onClick={() => {
                createPdItem({
                  groupID: _pdGroup.id,
                  projectID: _pdGroup.projectID,
                  reservedKey: "discount",
                })
                  .unwrap()
                  .then((e) => {
                    setTimeout(() => {
                      apiRef.current?.startCellEditMode({
                        id: e.id,
                        field: "rate",
                      });
                    }, 500);
                  });
              }}
            >
              + Discount
            </Chip>
            <Chip
              size="sm"
              variant="outlined"
              color="info"
              sx={{ background: "white" }}
              onClick={() => {
                createPdItem({
                  groupID: _pdGroup.id,
                  projectID: _pdGroup.projectID,
                  reservedKey: "extra",
                })
                  .unwrap()
                  .then((e) => {
                    setTimeout(() => {
                      apiRef.current?.startCellEditMode({
                        id: e.id,
                        field: "rate",
                      });
                    }, 500);
                  });
              }}
            >
              + Extra
            </Chip>
          </>
        ) : (
          []
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Typography level="body2">
          Subtotal: {currencyFormatter.format(pdGroup.subtotal / 1000)}
        </Typography>
      </Box>
    </Box>
  );
}

function MusicianIcon() {
  return (
    <Tooltip
      arrow
      size="sm"
      variant="outlined"
      placement="right"
      title="View Musicians"
    >
      <Box
        sx={{
          width: 20,
          height: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 12,
        }}
      >
        <i className="fa-solid fa-user-music"></i>
      </Box>
    </Tooltip>
  );
}

function MusicianActiveIcon() {
  return (
    <Tooltip
      placement="right"
      arrow
      size="sm"
      variant="outlined"
      title="Hide Musicians"
    >
      <Box
        sx={{
          width: 20,
          height: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#3f51b5",
          borderRadius: 20,
          color: "white",
          fontSize: 12,
        }}
      >
        <i className="fa-solid fa-user-music"></i>
      </Box>
    </Tooltip>
  );
}

export function getPdColumnColumns(
  pdGroup: PdGroup,
  deletePdItem?: any,
  parent?: PdGroup
) {
  const ret: any[] = [
    { field: "name", headerName: "Name", editable: true, width: 220 },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      width: 220,
      flex: pdGroup.reservedKey !== "orchestra" ? 1 : undefined,
    },
  ];

  if (pdGroup.reservedKey === "orchestra" || pdGroup.reservedKey === "wages") {
    ret.push(
      ...[
        {
          field: "scale",
          headerName: "Base Scale",
          editable:
            pdGroup.reservedKey !== "orchestra" &&
            parent?.reservedKey !== "orchestra",
          // editable: false,
          valueGetter: getterDivider("scale", 1000),
          valueSetter: setterDivider("scale", 1000),
          excelFormat: (v) => v / 1000,
          currency: true,
          valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
        },
        {
          field: "overscale",
          headerName: "Overscale",
          valueGetter: getterDivider("overscale", 1000),
          valueSetter: setterDivider("overscale", 1000),
          excelFormat: (v) => v / 100000,
          percentage: true,
          valueFormatter: (p) => `${p.value.toFixed(2)}%`,
          editable:
            pdGroup.reservedKey !== "orchestra" &&
            parent?.reservedKey !== "orchestra",
          // editable: false,
        },
      ]
    );
  }

  ret.push({
    field: "rate",
    headerName:
      pdGroup.reservedKey !== "orchestra" && pdGroup.reservedKey !== "wages"
        ? "Amount"
        : "Rate",
    valueGetter: getterDivider("rate", 1000),
    valueSetter: setterDivider("rate", 1000),
    excelFormat: (v) => v / 1000,
    currency: true,
    renderEditCell: (e) => {
      switch (e.row.reservedKey) {
        case "discount":
          return <Discount row={e.row} />;
        case "extra":
          return <Extra row={e.row} />;
        default:
          return <GridEditInputCell {...e} />;
      }
    },
    valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
    editable:
      pdGroup.reservedKey !== "orchestra" &&
      pdGroup.reservedKey !== "wages" &&
      parent?.reservedKey !== "orchestra",
  });

  if (pdGroup.reservedKey === "orchestra" || pdGroup.reservedKey === "wages") {
    ret.push({
      field: "extra",
      headerName: "Extra",
      valueGetter: getterDivider("extra", 1000),
      valueSetter: setterDivider("extra", 1000),
      excelFormat: (v) => v / 1000,
      currency: true,
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      editable:
        pdGroup.reservedKey !== "orchestra" && pdGroup.reservedKey !== "wages",
    });
  }

  ret.push(
    ...[
      {
        field: "quantity",
        headerName: "Quantity",
        editable:
          pdGroup.reservedKey !== "orchestra" &&
          parent?.reservedKey !== "orchestra",
        valueGetter: getterDivider("quantity", 1),
        valueSetter: setterDivider("quantity", 1),
      },
      {
        field: "subtotal",
        headerName: "Subtotal",
        valueGetter: getterDivider("subtotal", 1000),
        valueSetter: setterDivider("subtotal", 1000),
        excelFormat: (v) => v / 1000,
        currency: true,
        valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
        width: 100,
      },
    ]
  );
  if (
    pdGroup.reservedKey !== "orchestra" &&
    pdGroup.reservedKey !== "wages" &&
    pdGroup.reservedKey !== "pension" &&
    pdGroup.reservedKey !== "welfare"
  ) {
    ret.push({
      field: "actions",
      headerName: "",
      width: 50,
      type: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          showInMenu
          key="delete"
          icon={
            <i
              style={{ color: "#94222a" }}
              className="fa-sharp fa-solid fa-trash"
            ></i>
          }
          label={`Delete Item`}
          onClick={() => {
            deletePdItem(props.id);
          }}
          sx={{ color: "#94222a" }}
        />,
      ],
    });
  }

  return ret;
}

function Discount({ row }: { row: PdItem }) {
  const api = useGridApiContext();
  const [discount, setDiscount] = useState(`${Math.abs(row.overscale / 1000)}`);
  const [updatePdItem] = useUpdatePdItemMutation();

  const onClose = () => {
    api.current.stopCellEditMode({
      id: row.id,
      field: "rate",
      ignoreModifications: true,
    });
  };

  const save = () => {
    updatePdItem({
      id: row.id,
      body: {
        overscale: parseInt(discount) * 1000,
      },
    });
    onClose();
  };

  return (
    <DialogClose open onClose={onClose}>
      <DialogTitle>Discount</DialogTitle>
      <DialogContent>
        <Input
          autoFocus
          onFocus={(e) => {
            e.target.select();
            setTimeout(() => {
              e.target.select();
            }, 200);
          }}
          endDecorator="% off"
          startDecorator="Discount:"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          size="lg"
          type="tel"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={save}>Apply</Button>
      </DialogActions>
    </DialogClose>
  );
}

function Extra({ row }: { row: PdItem }) {
  const api = useGridApiContext();
  const [discount, setDiscount] = useState(`${Math.abs(row.overscale / 1000)}`);
  const [updatePdItem] = useUpdatePdItemMutation();

  const onClose = () => {
    api.current.stopCellEditMode({
      id: row.id,
      field: "rate",
      ignoreModifications: true,
    });
  };

  const save = () => {
    updatePdItem({
      id: row.id,
      body: {
        overscale: parseInt(discount) * 1000,
      },
    });
    onClose();
  };

  return (
    <DialogClose open onClose={onClose}>
      <DialogTitle>Extra</DialogTitle>
      <DialogContent>
        <Input
          onFocus={(e) => {
            e.target.select();
            setTimeout(() => {
              e.target.select();
            }, 200);
          }}
          autoFocus
          endDecorator="% extra"
          startDecorator="Extra:"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          size="lg"
          type="tel"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={save}>Apply</Button>
      </DialogActions>
    </DialogClose>
  );
}
