import { MusicianNote } from "entities/musicianNote";
import { useMemo } from "react";
import {
  useGetMusicianNoteQuery,
  useGetMusicianNotesQuery,
} from "./musicianNoteEndpoints";

export function useMusicianNote(musicianNoteID: number | undefined) {
  const musicianNoteQuery = useGetMusicianNoteQuery(musicianNoteID ?? 0, {
    skip: (musicianNoteID ?? 0) <= 0,
  });
  const musicianNoteEntity = musicianNoteQuery.data;

  return useMemo(() => {
    const ret: typeof musicianNoteQuery & {
      musicianNote?: MusicianNote | undefined;
    } = {
      ...musicianNoteQuery,
    };
    if (musicianNoteEntity)
      ret.musicianNote = new MusicianNote(musicianNoteEntity);
    return ret;
  }, [musicianNoteEntity]);
}

export function useMusicianNotes(args?: any, options?: any) {
  const musicianNoteQuery = useGetMusicianNotesQuery(args, options);
  const musicianNoteEntitiesMap = musicianNoteQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianNoteQuery & {
      musicianNotes?: MusicianNote[];
      musicianNotesMap?: { [id: number]: MusicianNote };
    } = {
      ...musicianNoteQuery,
      musicianNotes: [],
      musicianNotesMap: {},
    };
    if (musicianNoteEntitiesMap) {
      const musicianNotes = [];
      const musicianNotesMap = {};

      for (const key in musicianNoteEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(musicianNoteEntitiesMap, key)
        ) {
          const c = musicianNoteEntitiesMap[key];
          const musicianNote = new MusicianNote(c);
          musicianNotes.push(musicianNote);
          musicianNotesMap[musicianNote.id] = musicianNote;
        }
      }
      ret.musicianNotes = musicianNotes;
      ret.musicianNotesMap = musicianNotesMap;
    }

    return ret;
  }, [musicianNoteEntitiesMap]);
}
