import PdLabor_Entity from "./pdLabor";
export default class PdLabor extends PdLabor_Entity {
  static fromList(pdLaborsJSON: unknown): Array<PdLabor> {
    const pdLabors: PdLabor[] = [];
    if (pdLaborsJSON)
      Array.isArray(pdLaborsJSON) &&
        pdLaborsJSON.forEach((pdLaborJSON) => {
          pdLabors.push(new PdLabor(pdLaborJSON));
        });
    return pdLabors;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
