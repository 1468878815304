import {
  SELECTED_CALENDAR_DAY,
  SET_CALENDAR_OPEN_TO,
  SET_DARK_MODE,
  SET_GOOGLE_CONTACT,
  SET_PREFERED_WORK_SESSION_PRESENTATION,
  SET_PROJECT_HIRING_SORT_BY,
  STORE_RESET_PASSWORD_TOKEN,
  WS_MESSAGE,
  SET_TAB_INDEX,
  SET_FORM_OPEN,
  SET_DRAWER_OPEN,
  SET_CALENDAR_OPTIONS,
} from "constants/app";

export const setCalendarOptions = (options) => async (dispatch) =>
  dispatch({
    type: SET_CALENDAR_OPTIONS,
    open,
    options,
  });

export const storeResetPasswordToken = (token) => async (dispatch) =>
  dispatch({
    type: STORE_RESET_PASSWORD_TOKEN,
    token,
  });

export const setDarkMode = (token) => async (dispatch) =>
  dispatch({
    type: SET_DARK_MODE,
    token,
  });

export const setDrawerOpen = (drawerOpen) => async (dispatch) =>
  dispatch({
    type: SET_DRAWER_OPEN,
    drawerOpen,
  });

export const setCalendarOpenTo = (calendarOpenTo) => async (dispatch) =>
  dispatch({
    type: SET_CALENDAR_OPEN_TO,
    calendarOpenTo,
  });

export const websocketMessageReceived = (token) => async (dispatch) =>
  dispatch({
    type: WS_MESSAGE,
    token,
  });

export const setSelectedCalendarDay = (body) => async (dispatch) =>
  dispatch({
    type: SELECTED_CALENDAR_DAY,
    body,
  });

export const setPreferedWorkSessionPresentation =
  (presentation) => async (dispatch) =>
    dispatch({
      type: SET_PREFERED_WORK_SESSION_PRESENTATION,
      presentation,
    });

export const setGoogleContacts = (googleContacts) => async (dispatch) =>
  dispatch({
    type: SET_GOOGLE_CONTACT,
    googleContacts,
  });

export const setProjectHiringSortBy = (sortBy) => async (dispatch) =>
  dispatch({
    type: SET_PROJECT_HIRING_SORT_BY,
    sortBy,
  });
