/**
 * Trail_Entity type guard.
 *
 * @param {any} trailJson Trail object from API
 * @returns {boolean} Return true if type is Trail_Entity
 */

export default class Trail_Entity {
  constructor(trailJson: Trail_Entity) {
    try {
      this.id = trailJson.id;
      this.userAgent = trailJson.userAgent;
      this.remoteIP = trailJson.remoteIP;
      this.createdAt = trailJson.createdAt;
      this.updatedAt = trailJson.updatedAt;
    } catch (e) {
      throw new Error(`Failed to create new instance of ${Trail_Entity.name}`);
    }
  }

  id?: number;
  remoteIP: string;
  userAgent: string;
  createdAt: string;
  updatedAt: string;
}
