import { show } from "@intercom/messenger-js-sdk";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Input,
  Link,
  List,
  ListItem,
  ListItemButton,
  Sheet,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from "@mui/material";
import { Section } from "entities/section";
import { WorkSession } from "entities/workSession";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import SlidingDialog from "hooks/SlidingDialog/SlidingDialog";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  missionControlModeSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutInternalSelector,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useUpdateWorkSessionMutation } from "redux/workSession/workSessionEndpoints";
import { useProjectWorkSessions } from "redux/workSession/workSessionHooks";
import {
  useCreateWorkSessionProjectPieceMutation,
  useDeleteWorkSessionProjectPieceMutation,
} from "redux/workSessionProjectPiece/workSessionProjectPieceEndpoints";
import { useWorkSessionProjectPieces } from "redux/workSessionProjectPiece/workSessionProjectPieceHooks";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

/**
 *
 * @returns {ReactElement} FormProjectProgram page
 */
export function FormProjectProgram() {
  const open = useSelector(formOpenSelector("projectProgram"));
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectWorkSessions } = useProjectWorkSessions(projectID);
  const missionControlMode = useSelector(missionControlModeSelector);
  const onClose = () => {
    dispatch(setFormOpen({ formID: "projectProgram", isOpen: false }));
  };

  return (
    <SlidingDialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            level="h6"
            startDecorator={<i className="fa-solid fa-list-music"></i>}
          >
            Project's Program
          </Typography>
          <Typography level="body2">
            Select the Pieces and the Sections playing on Work Sessions.
          </Typography>
          {missionControlMode !== "edit" ? (
            <Alert size="sm" color="warning">
              Switch to the Edit mode to make changes.
            </Alert>
          ) : (
            []
          )}
        </Box>
      </DialogTitle>
      <Divider />
      <Box
        sx={{
          display: "flex",
          gap: 2,
          height: "calc(100vh - 155px)",
          overflow: "auto",
          p: 2,
        }}
      >
        {projectWorkSessions.length === 0 ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography level="body2">
              You don't have any Work Sessions
            </Typography>
          </Box>
        ) : (
          <>
            {projectWorkSessions?.map((w) => (
              <WorkSessionProgram key={w.id} workSession={w} />
            ))}
          </>
        )}
      </Box>
    </SlidingDialog>
  );
}

function WorkSessionProgram({ workSession }: { workSession: WorkSession }) {
  const w = workSession;
  const [createWorkSessionProjectPiece] =
    useCreateWorkSessionProjectPieceMutation();
  const [deleteWorkSessionProjectPiece] =
    useDeleteWorkSessionProjectPieceMutation();
  const [updateWorkSession] = useUpdateWorkSessionMutation();
  const internal = useSelector(layoutInternalSelector);
  const utils = useSelector(layoutUtilsSelector);
  const callSent = internal?.callSent ?? false;
  const debug = useSelector(layoutDebugSelector);
  const askQuestion = useAskQuestion();

  console.log(utils.workSessionProjectPieces);

  const [list, setList] = useState<number[]>(
    JSON.parse(
      workSession.projectPiecesOrder ? workSession.projectPiecesOrder : "[]"
    )
  );

  useEffect(() => {
    setList(
      JSON.parse(
        workSession.projectPiecesOrder ? workSession.projectPiecesOrder : "[]"
      )
    );
  }, [workSession]);

  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement>();

  const handleAddWorkSessionProjectPiece = (projectPieceID: number) => {
    setAnchorEl(undefined);
    createWorkSessionProjectPiece({
      workSessionID: workSession.id,
      projectPieceID,
    });
  };

  const handleAddBreak = () => {
    setAnchorEl(undefined);
    updateWorkSession({
      id: workSession.id,
      body: {
        ...workSession,
        projectPiecesOrder: JSON.stringify([...list, 0]),
      },
    });
  };

  const handleRemoveWorkSessionProjectPiece = (
    workSessionProjectPieceID: number
  ) => {
    if (callSent && !debug) {
      askQuestion(undefined, [], {
        subtitle: (
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography level="display2" color="danger">
              <i className="fa-solid fa-triangle-exclamation"></i>
            </Typography>
            <Typography level="h6" color="danger">
              You can't remove a Project Piece
            </Typography>
            <Typography level="body2" color="danger">
              The call has already been sent (Musicians have been contacted).
              <br />
              You are no longer allowed to remove a Project Piece from a Work
              Session.
              <br />
              For more assistance, chat with us.
            </Typography>
            <Button
              sx={{ mt: 2 }}
              color="danger"
              startDecorator={<i className="fa-solid fa-comment"></i>}
              onClick={() => {
                show();
              }}
            >
              Chat with us
            </Button>
          </Box>
        ),
      });
    } else {
      deleteWorkSessionProjectPiece(workSessionProjectPieceID);
    }
  };

  function onDragEnd(result) {
    const newItems = [...list];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    updateWorkSession({
      id: workSession.id,
      body: { ...workSession, projectPiecesOrder: JSON.stringify(newItems) },
    });
    setList(newItems);
  }

  const otherProjectPieces = utils.projectPieces.filter(
    (e) => !list.includes(e.id)
  );

  return (
    <Box
      key={w.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        flexShrink: 0,
      }}
    >
      <Box>
        <WorkSessionName id={w.id} />
        <Typography sx={{ fontWeight: "normal" }} level="body3">
          {w.formatDateRange()}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <WorkSessionMemo id={w.id} />
          <WorkSessionInternalMemo id={w.id} />
        </Box>
      </Box>
      <Divider>Schedule</Divider>
      <Box>
        <Tooltip
          open={Boolean(anchorEl)}
          arrow
          variant="outlined"
          title={
            <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
              <Box sx={{ width: 300 }}>
                <Typography>
                  <b>What would you like to add?</b>
                </Typography>
                <List>
                  {debug ? (
                    <ListItemButton
                      variant="soft"
                      color="info"
                      onClick={() => handleAddBreak()}
                    >
                      <Box>
                        <Typography
                          startDecorator={<i className="fa-solid fa-pause"></i>}
                          level="body2"
                          sx={{ color: "black" }}
                        >
                          Break
                        </Typography>
                        <Typography level="body4">Musician pause</Typography>
                      </Box>
                    </ListItemButton>
                  ) : (
                    []
                  )}
                  {otherProjectPieces.map((pp) => {
                    const piece = utils.piecesMap[pp.pieceID];
                    return (
                      <ListItemButton
                        key={pp.id}
                        onClick={() => handleAddWorkSessionProjectPiece(pp.id)}
                      >
                        <Box>
                          <Typography level="body2" sx={{ color: "black" }}>
                            {piece?.name}
                          </Typography>
                          <Typography level="body4">
                            {piece?.composer}
                          </Typography>
                        </Box>
                      </ListItemButton>
                    );
                  })}
                </List>
              </Box>
            </ClickAwayListener>
          }
        >
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            variant="soft"
            size="sm"
            fullWidth
          >
            + Add
          </Button>
        </Tooltip>
        <List>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"Box"}>
              {(provided) => {
                return (
                  <Box
                    ref={provided.innerRef}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {list.map((projectPieceID, i) => {
                      const projectPiece =
                        utils.projectPiecesMap[projectPieceID];
                      const piece = utils.piecesMap[projectPiece?.pieceID];
                      const musicianBreak = projectPieceID === 0;
                      const workSessionProjectPiece =
                        utils.workSessionProjectPieces.find(
                          (e) =>
                            e.workSessionID === workSession.id &&
                            e.projectPieceID === projectPieceID
                        );

                      // if (!workSessionProjectPiece) return <Box />;
                      return (
                        <Draggable
                          key={projectPieceID}
                          draggableId={`${projectPieceID}`}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ListItem
                                color={musicianBreak ? "info" : undefined}
                                variant={musicianBreak ? "soft" : undefined}
                                sx={{ gap: 1 }}
                              >
                                <i
                                  style={{ cursor: "grab" }}
                                  className="fa-regular fa-grip-dots-vertical"
                                ></i>
                                {musicianBreak ? (
                                  <Box>
                                    <Typography
                                      startDecorator={
                                        <i className="fa-solid fa-pause"></i>
                                      }
                                      level="body2"
                                      sx={{ color: "black" }}
                                    >
                                      Break
                                    </Typography>
                                    <Typography level="body4">
                                      Musician pause
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box>
                                    <Typography
                                      level="body2"
                                      sx={{ color: "black" }}
                                    >
                                      {piece?.name}
                                    </Typography>
                                    <Typography level="body4">
                                      {piece?.composer}
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ flexGrow: 1 }} />
                                <Tooltip
                                  arrow
                                  size="sm"
                                  variant="outlined"
                                  color="neutral"
                                  title="Remove Piece from Work Session"
                                  enterDelay={1000}
                                  enterNextDelay={1000}
                                >
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveWorkSessionProjectPiece(
                                        workSessionProjectPiece.id
                                      )
                                    }
                                    size="sm"
                                    variant="plain"
                                    color="neutral"
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </IconButton>
                                </Tooltip>
                              </ListItem>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                );
              }}
            </Droppable>
          </DragDropContext>
        </List>
      </Box>
      <Divider>Sections</Divider>
      <WorkSessionSections id={w.id} />
    </Box>
  );
}

function WorkSessionMemo({ id }: { id: number }) {
  const [memo, setMemo] = useState("");
  const missionControlMode = useSelector(missionControlModeSelector);
  const { workSessionsMap } = useSelector(layoutUtilsSelector);
  const [updateWorkSession] = useUpdateWorkSessionMutation();

  useEffect(() => {
    setMemo(workSessionsMap[id].memo);
  }, [workSessionsMap]);

  const save = () => {
    updateWorkSession({ id, body: { memo } });
  };

  return (
    <Textarea
      startDecorator="Memo"
      disabled={missionControlMode !== "edit"}
      size="sm"
      placeholder="Ex: Strings only"
      value={memo}
      variant="soft"
      minRows={3}
      maxRows={5}
      onChange={(e) => setMemo(e.target.value)}
      onBlur={save}
      endDecorator={
        <Typography level="body4">Visible by musicians.</Typography>
      }
    />
  );
}

function WorkSessionName({ id }: { id: number }) {
  const [title, setTitle] = useState("");
  const missionControlMode = useSelector(missionControlModeSelector);
  const { workSessionsMap } = useSelector(layoutUtilsSelector);
  const { callSent } = useSelector(layoutInternalSelector);
  const [updateWorkSession] = useUpdateWorkSessionMutation();

  useEffect(() => {
    setTitle(workSessionsMap[id].title);
  }, [workSessionsMap]);

  const save = () => {
    updateWorkSession({ id, body: { title } });
  };

  return (
    <Typography level="body1" sx={{ fontWeight: 600 }}>
      <Input
        readOnly={missionControlMode !== "edit" || callSent}
        size="sm"
        placeholder="Title"
        value={title}
        variant="plain"
        onChange={(e) => setTitle(e.target.value)}
        onBlur={save}
      />
    </Typography>
  );
}

function WorkSessionInternalMemo({ id }: { id: number }) {
  const [description, setDescription] = useState("");
  const missionControlMode = useSelector(missionControlModeSelector);
  const { workSessionsMap } = useSelector(layoutUtilsSelector);
  const [updateWorkSession] = useUpdateWorkSessionMutation();

  useEffect(() => {
    setDescription(workSessionsMap[id].description);
  }, [workSessionsMap]);

  const save = () => {
    updateWorkSession({ id, body: { description } });
  };

  return (
    <Textarea
      startDecorator="Internal Memo"
      disabled={missionControlMode !== "edit"}
      size="sm"
      placeholder={`Anything to write down about this Work Session?`}
      value={description}
      variant="soft"
      minRows={3}
      maxRows={5}
      onChange={(e) => setDescription(e.target.value)}
      sx={{ width: "100%", background: "#FFFDEF" }}
      onBlur={save}
      endDecorator={
        <Typography level="body4">Not visible by musicians.</Typography>
      }
    />
  );
}

function WorkSessionSections({ id }: { id: number }) {
  const [sectionIDs, setSectionIDs] = useState<string>();
  const missionControlMode = useSelector(missionControlModeSelector);
  const { workSessionsMap, sectionsMap, familiesMap } =
    useSelector(layoutUtilsSelector);
  const { sectionIDs: projectSectionIDs } = useSelector(layoutInternalSelector);
  const [updateWorkSession] = useUpdateWorkSessionMutation();
  const [open, setOpen] = useState(false);
  const debug = useSelector(layoutDebugSelector);
  const { callSent } = useSelector(layoutInternalSelector);
  const askQuestion = useAskQuestion();

  useEffect(() => {
    setSectionIDs(
      workSessionsMap[id].sectionIDs ? workSessionsMap[id].sectionIDs : ""
    );
  }, [workSessionsMap]);

  let sectionIDsArray = sectionIDs ? JSON.parse(sectionIDs) : [];
  sectionIDsArray = sectionIDsArray.filter(
    (item, index) => sectionIDsArray.indexOf(item) === index
  );

  const save = () => {
    updateWorkSession({
      id,
      body: {
        sectionIDs,
      },
    });
    setOpen(false);
  };

  const callSentWarning = () =>
    askQuestion(undefined, [], {
      subtitle: (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography level="display2" color="danger">
            <i className="fa-solid fa-triangle-exclamation"></i>
          </Typography>
          <Typography level="h6" color="danger">
            You can't edit Sections
          </Typography>
          <Typography level="body2" color="danger">
            The call has already been sent (Musicians have been contacted).
            <br />
            You are no longer allowed to select the sections that are playing.
            <br />
            For more assistance, chat with us.
          </Typography>
          <Button
            sx={{ mt: 2 }}
            color="danger"
            startDecorator={<i className="fa-solid fa-comment"></i>}
            onClick={() => {
              show();
            }}
          >
            Chat with us
          </Button>
        </Box>
      ),
    });

  const families = projectSectionIDs.reduce<
    { id: number; name: string; sections: Section[] }[]
  >((a, v) => {
    const section = sectionsMap[v];
    const family = familiesMap[section.familyID];
    const index = a?.findIndex((e) => e.id === section.familyID);

    if (index >= 0) {
      a[index].sections.push(section);
    } else {
      a.push({ name: family.name, id: family.id, sections: [section] });
    }
    return a;
  }, []);

  const familyStatus = (f) => {
    return projectSectionIDs.reduce(
      (a, v) => {
        const section = sectionsMap[v];
        if (section.familyID === f.id) {
          if (sectionIDsArray.includes(section.id)) {
            a.checked = false;
          } else {
            a.indeterminate = true;
          }
        }
        return a;
      },
      { checked: true, indeterminate: false }
    );
  };

  const getDescription = () => {
    if (sectionIDs === "" || sectionIDs === "[]") return "Tutti";
    const ret = [];
    families.forEach((f) => {
      const { checked } = familyStatus(f);
      if (checked) {
        ret.push(f.name);
      } else {
        f.sections.forEach((s) => {
          if (!sectionIDsArray.includes(s.id)) ret.push(s.name);
        });
      }
    });

    // if (sectionIDs === "" || sectionIDs === "[]") return "Tutti";
    // const ret = projectSectionIDs.reduce((a, v) => {
    //   if (!sectionIDsArray.includes(v)) a.push(sectionsMap[v].name);
    //   return a;
    // }, []);

    return ret.join(", ");
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          fullWidth
          disabled={
            projectSectionIDs.length === 0 || missionControlMode !== "edit"
          }
          variant="soft"
          color={"neutral"}
          sx={{ width: 320 }}
          onClick={debug || !callSent ? () => setOpen(true) : callSentWarning}
        >
          <Box sx={{ lineHeight: 0.8 }}>
            <Typography level="body2" sx={{ color: "black", fontWeight: 600 }}>
              {getDescription()}
            </Typography>
            {debug || !callSent ? (
              <Typography level="body4">Click to configure</Typography>
            ) : (
              []
            )}
          </Box>
        </Button>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography level="h6">Which Sections are playing?</Typography>
          <Typography level="body2">
            For {workSessionsMap[id].title}.{" "}
            {workSessionsMap[id].formatDateRange()}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 0 }}>
          <Sheet variant="soft" sx={{ p: 2 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Link
                onClick={() => setSectionIDs("")}
                color="neutral"
                level="body3"
              >
                Select all
              </Link>
              <Link
                onClick={() => setSectionIDs(JSON.stringify(projectSectionIDs))}
                color="neutral"
                level="body3"
              >
                Unselect
              </Link>
            </Box>
            <List size="sm">
              {families.map((f) => {
                const { checked, indeterminate } = familyStatus(f);
                return (
                  <>
                    <ListItem key={f.id} sx={{ alignItems: "center" }}>
                      <Checkbox
                        checked={checked}
                        indeterminate={!checked && indeterminate}
                        onChange={(e) => {
                          setSectionIDs(
                            JSON.stringify(
                              e.target.checked
                                ? sectionIDsArray.filter(
                                    (sectionID) =>
                                      sectionsMap[sectionID].familyID !== f.id
                                  )
                                : [
                                    ...sectionIDsArray,
                                    ...projectSectionIDs.filter(
                                      (sectionID) =>
                                        sectionsMap[sectionID].familyID === f.id
                                    ),
                                  ]
                            )
                          );
                        }}
                        label={f.name}
                      />
                    </ListItem>
                    {f.sections.map((s) => (
                      <ListItem key={f.id} sx={{ alignItems: "center", ml: 3 }}>
                        <Checkbox
                          size="sm"
                          onChange={(e) => {
                            setSectionIDs(
                              JSON.stringify(
                                e.target.checked
                                  ? sectionIDsArray.filter((e) => e !== s.id)
                                  : [...sectionIDsArray, s.id]
                              )
                            );
                          }}
                          checked={!sectionIDsArray.includes(s.id)}
                          label={s.name}
                        />
                      </ListItem>
                    ))}
                  </>
                );
              })}
            </List>
          </Sheet>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="soft" color="neutral">
            Cancel
          </Button>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>
      {sectionIDs !== "" ? <></> : []}
    </Box>
  );
}
