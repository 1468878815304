import { SubRule } from "entities/subRule";
import { useMemo } from "react";
import { useGetSubRuleQuery, useGetSubRulesQuery } from "./subRuleEndpoints";

export function useSubRule(subRuleID: number | undefined) {
  const subRuleQuery = useGetSubRuleQuery(subRuleID ?? 0, {
    skip: (subRuleID ?? 0) <= 0,
  });
  const subRuleEntity = subRuleQuery.data;

  return useMemo(() => {
    const ret: typeof subRuleQuery & { subRule?: SubRule | undefined } = {
      ...subRuleQuery,
    };
    if (subRuleEntity) ret.subRule = new SubRule(subRuleEntity);
    return ret;
  }, [subRuleEntity]);
}

export function useSubRules(params: any, options?: any) {
  const subRuleQuery = useGetSubRulesQuery(params, options);
  const subRuleEntitiesMap = subRuleQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof subRuleQuery & {
      subRules?: SubRule[];
      subRulesMap?: { [id: number]: SubRule };
    } = {
      ...subRuleQuery,
      subRules: [],
      subRulesMap: {},
    };
    if (subRuleEntitiesMap) {
      const subRules = [];
      const subRulesMap = {};

      for (const key in subRuleEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(subRuleEntitiesMap, key)) {
          const c = subRuleEntitiesMap[key];
          const subRule = new SubRule(c);
          subRules.push(subRule);
          subRulesMap[subRule.id] = subRule;
        }
      }
      subRules.sort((a, b) => a.position - b.position);
      ret.subRules = subRules;
      ret.subRulesMap = subRulesMap;
    }

    return ret;
  }, [subRuleEntitiesMap]);
}
