import { accountKitApi } from "@nerdjs/account-kit";
import { networkReducer } from "@nerdjs/nerd-network";
import { combineReducers } from "redux"; //eslint-disable-line
import { rhapsodyApi } from "redux/api";
import { autopilotApi } from "redux/api/autopilotApi";
import { mercuryApi } from "redux/api/mercuryApi";
import { museApi } from "redux/api/museApi";
import { postalApi } from "redux/api/postalApi";
import app from "./app";
import centrifugo from "./centrifugo";
import errors from "./errors";
import { layoutSlice } from "./layout";
import layoutPresets from "./layoutPresets";
import { notifierSlice } from "./notifier";
import { projectHiringSlice } from "./projectHiring";
import { projectRostersSlice } from "./projectRosters";
import { rhapsodySlice } from "./rhapsody";
import searchPresets from "./searchPresets";
import user from "./user";
import userApps from "./userApps";
import users from "./users";
import { workSessionsSlice } from "./workSessions";
import { onboardingSlice } from "./onboarding";
import { unityApi } from "redux/api/unity";
import { accountApi } from "redux/api/accountApi";
import { missionControlSlice } from "./v2/missionControl";
import { paydayApi } from "redux/api/paydayApi";
import { errorSlice } from "./error";
import { librarianApi } from "redux/api/librarian";
import { seasonControlSlice } from "./v2/seasonControl";

export default combineReducers({
  [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  [museApi.reducerPath]: museApi.reducer,
  [librarianApi.reducerPath]: librarianApi.reducer,
  [mercuryApi.reducerPath]: mercuryApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [unityApi.reducerPath]: unityApi.reducer,
  [paydayApi.reducerPath]: paydayApi.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [missionControlSlice.name]: missionControlSlice.reducer,
  [seasonControlSlice.name]: seasonControlSlice.reducer,
  [rhapsodySlice.name]: rhapsodySlice.reducer,
  [notifierSlice.name]: notifierSlice.reducer,
  [errorSlice.name]: errorSlice.reducer,
  [autopilotApi.reducerPath]: autopilotApi.reducer,
  [workSessionsSlice.name]: workSessionsSlice.reducer,
  [postalApi.reducerPath]: postalApi.reducer,
  [projectHiringSlice.name]: projectHiringSlice.reducer,
  [projectRostersSlice.name]: projectRostersSlice.reducer,
  [accountKitApi.reducerPath]: accountKitApi.reducer,
  networkState: networkReducer,
  errors,
  centrifugo,
  user,
  app,
  searchPresets,
  users,
  userApps,
  layoutPresets,
});
