import Button from "@mui/joy/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
// material-ui
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
// constants
import {
  AT_LEAST_1_ALPHABETICAL_CHAR,
  AT_LEAST_1_NUMERICAL_CHAR,
  AT_LEAST_8_CHAR,
} from "config/regexp";
import { useState } from "react";
// styles
import { Chip, IconButton, Input, Sheet } from "@mui/joy";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { useUpdateUserPasswordMutation } from "redux/user/userEndpoint";

export default function FormResetPassword() {
  const [password, setPassword] = useState("");
  const [passwordRetype, setPasswordRetype] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRetype, setShowPasswordRetype] = useState(false);
  const [focused, setFocused] = useState(false);
  const askQuestion = useAskQuestion();
  const [updateUserPassword] = useUpdateUserPasswordMutation();

  let eightChar = false;
  let numerical = false;
  let alphabetical = false;
  let match = false;

  if (AT_LEAST_8_CHAR.test(password)) {
    eightChar = true;
  }

  if (AT_LEAST_1_NUMERICAL_CHAR.test(password)) {
    numerical = true;
  }

  if (AT_LEAST_1_ALPHABETICAL_CHAR.test(password)) {
    alphabetical = true;
  }

  if (password === passwordRetype) {
    match = true;
  }

  const isValid = match && alphabetical && numerical && eightChar;

  const requestUpdateUserPassword = async () => {
    updateUserPassword({ body: { password } });
    askQuestion("Success", ["OK"], {
      subtitle: <Typography>Password has been changed.</Typography>,
    });

    setPassword("");
    setPasswordRetype("");
  };

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <Sheet sx={{ p: 2, borderRadius: "8px" }} variant="soft">
        <Typography variant="h6" gutterBottom>
          Change your Password
        </Typography>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ display: "flex" }}>
            <Input
              fullWidth
              sx={{ flexGrow: 1 }}
              id="adornment-password"
              onFocus={() => setFocused(true)}
              startDecorator="Password *"
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endDecorator={
                <IconButton
                  variant="plain"
                  color="neutral"
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword((e) => !e)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex" }}>
            <Input
              fullWidth
              sx={{ flexGrow: 1 }}
              autoComplete="off"
              id="adornment-password"
              onFocus={() => setFocused(true)}
              startDecorator="Retype Password *"
              type={showPasswordRetype ? "text" : "password"}
              value={passwordRetype}
              onChange={(e) => setPasswordRetype(e.target.value)}
              endDecorator={
                <IconButton
                  variant="plain"
                  color="neutral"
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPasswordRetype((e) => !e)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {showPasswordRetype ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
          </Grid>
        </Grid>
        <br />
        {focused && (
          <Grid container spacing={1}>
            <Grid item>
              <Chip
                size="sm"
                color={eightChar ? "success" : "danger"}
                variant={"soft"}
              >
                8 chars min
              </Chip>
            </Grid>
            <Grid item>
              <Chip
                size="sm"
                color={numerical ? "success" : "danger"}
                variant={"soft"}
              >
                has 1 digit
              </Chip>
            </Grid>
            <Grid item>
              <Chip
                size="sm"
                color={numerical ? "success" : "danger"}
                variant={"soft"}
              >
                has 1 letter
              </Chip>
            </Grid>
            <Grid item>
              <Chip
                size="sm"
                color={match ? "success" : "danger"}
                variant={"soft"}
              >
                passwords match
              </Chip>
            </Grid>
          </Grid>
        )}
        <Button
          sx={{ mt: 2 }}
          fullWidth
          disabled={!isValid}
          onClick={requestUpdateUserPassword}
        >
          Change Password
        </Button>
      </Sheet>
    </ClickAwayListener>
  );
}
