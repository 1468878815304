import { CircularProgress, Input, Typography } from "@mui/joy";
import { Box, Grid, Paper } from "@mui/material";
import { Company_Entity } from "entities/company";
import { useEffect, useState } from "react";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import { useCurrentCompany } from "redux/company/companyHooks";

export default function ContractorProfile() {
  const [_company, setCompany] = useState<Company_Entity>();
  const { company } = useCurrentCompany();
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();

  useEffect(() => {
    if (company && !_company) {
      setCompany(company);
    }
  }, [company]);

  const handleChange = (name) => (e) => {
    setCompany((c) => ({ ...c, [name]: e.target.value }));
  };

  const save = async () => {
    updateCurrentCompany({ ..._company });
  };

  if (!_company) return <CircularProgress />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 600,
        margin: "auto",
      }}
    >
      <Paper
        variant="outlined"
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Typography level="h4" sx={{ fontWeight: 600 }}>
          Contractor Profile
        </Typography>
        <Typography level="body2">
          These information are important.
          <br />
          They will be included in your email work calls.
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ textAlign: "left", alignItems: "start" }}
        >
          <Grid item xs={12}>
            <Input
              onBlur={save}
              startDecorator="Contractor Name:"
              onChange={handleChange("contact")}
              size="lg"
              value={_company.contact}
              color={_company.contact ? "success" : "neutral"}
            />
            <Typography level="body3">* Required</Typography>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Input
              onChange={handleChange("username")}
              value={_company.username}
              color={_company.username ? "success" : "neutral"}
              size="lg"
              placeholder="studio"
              label="Sender"
              endDecorator={<b>@rhapsody.la</b>}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography level="body2">
              <Chip size="sm" color="primary" level="soft" sx={{ mr: 1 }}>
                Finale Plan
              </Chip>
              All you communications will be sent using this email address.
            </Typography>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Input
              onBlur={save}
              startDecorator="Reply-to:"
              onChange={handleChange("email")}
              value={_company.email}
              color={_company.email ? "success" : "neutral"}
              size="lg"
              type="email"
            />
            <Typography level="body3">* Required</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography level="body2">
              On which email would you like your musicians to contact you?
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              onBlur={save}
              startDecorator="Phone:"
              onChange={handleChange("phone")}
              value={_company.phone}
              color={_company.phone ? "success" : "neutral"}
              size="lg"
              type="email"
            />
            <Typography level="body3">Optional</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography level="body2">
              On which phone would you like your musicians to contact you?
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <JoyTypography
              level="body2"
              sx={{ color: "#212121", fontWeight: 400 }}
            >
              <b>Logo</b>
            </JoyTypography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FileUpload
                cropDimension={{ width: 460, height: 90 }}
                onUpload={(f) => {
                  setCompany((c) => ({ ...c, logoURL: f }));
                }}
              />
              {company.logoURL ? (
                <i
                  style={{ color: "#1C7134" }}
                  class="fa-sharp fa-solid fa-circle-check"
                ></i>
              ) : (
                []
              )}
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} md={6}>
            <Typography level="body2">
              Logo displayed on emails and roster. Dimension should be 460 x
              90px
            </Typography>
          </Grid> */}
          {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Paper level="outlined" sx={{ width: 460, height: 90 }}>
              <img style={{ width: 460, height: 90 }} src={company.logoURL} />
            </Paper>
          </Grid> */}
        </Grid>
      </Paper>
    </Box>
  );
}
