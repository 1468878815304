import { WorkSessionType } from "entities/workSessionType";
import { useMemo } from "react";
import { useGetWorkSessionTypeQuery, useGetWorkSessionTypesQuery } from "./workSessionTypeEndpoints";

export function useWorkSessionType(workSessionTypeID: number | undefined) {
  const workSessionTypeQuery = useGetWorkSessionTypeQuery(workSessionTypeID ?? 0, {
    skip: (workSessionTypeID ?? 0) <= 0,
  });
  const workSessionTypeEntity = workSessionTypeQuery.data;

  return useMemo(() => {
    const ret: typeof workSessionTypeQuery & { workSessionType?: WorkSessionType | undefined } = {
      ...workSessionTypeQuery,
    };
    if (workSessionTypeEntity) ret.workSessionType = new WorkSessionType(workSessionTypeEntity);
    return ret;
  }, [workSessionTypeEntity]);
}

export function useWorkSessionTypes() {
  const workSessionTypeQuery = useGetWorkSessionTypesQuery();
  const workSessionTypeEntitiesMap = workSessionTypeQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof workSessionTypeQuery & {
      workSessionTypes?: WorkSessionType[];
      workSessionTypesMap?: { [id: number]: WorkSessionType };
    } = {
      ...workSessionTypeQuery,
      workSessionTypes: [],
      workSessionTypesMap: {},
    };
    if (workSessionTypeEntitiesMap) {
      const workSessionTypes = [];
      const workSessionTypesMap = {};

      for (const key in workSessionTypeEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(workSessionTypeEntitiesMap, key)) {
          const c = workSessionTypeEntitiesMap[key];
          const workSessionType = new WorkSessionType(c);
          workSessionTypes.push(workSessionType);
          workSessionTypesMap[workSessionType.id] = workSessionType;
        }
      }
      ret.workSessionTypes = workSessionTypes;
      ret.workSessionTypesMap = workSessionTypesMap;
    }

    return ret;
  }, [workSessionTypeEntitiesMap]);
}
