import { setError } from "reducers/error";
import { Middleware, MiddlewareAPI } from "redux";
import { store } from "store";

export const errorMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const dispatch = store.dispatch;
    if (
      action?.type.includes("rejected") &&
      action?.payload?.originalStatus !== 404 &&
      action?.payload?.originalStatus !== 401 &&
      action?.payload?.data?.status !== 404
    ) {
      const error = action?.payload?.data;
      const state = store.getState();
      if (!state.rhapsody.hideApiErrors) {
        if (error?.status) dispatch(setError(action.payload.data));
      }
    }
    return next(action);
  };
