import { Dictionary } from "@reduxjs/toolkit";
import { Job } from "entities/job";
import { Section_Entity } from "entities/section";
import { WorkSession_Entity } from "entities/workSession";
import { mapToArray } from "helpers";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectRosters_Select_WorkSessions } from "reducers/projectRosters";
import { selectedProjectIDSelector } from "reducers/rhapsody";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";
import { useSections } from "redux/section/sectionHooks";

type Layout = { [k: number]: number };

type WorkSessionExtended = WorkSession_Entity & {
  sections?: WorkSessionSectionExtended[];
  sectionMap?: Dictionary<WorkSessionSectionExtended>;
};

type WorkSessionSectionExtended = Section_Entity & {
  jobs?: Job[];
};

type RosterData = {
  data: WorkSessionExtended[];
  key: string;
  layout: Layout;
  isLoading: boolean;
};

export function useRosterData(): RosterData {
  const projectID = useSelector(selectedProjectIDSelector);
  const {
    projectHiring: hiring,
    isLoading: l1,
    requestId,
  } = useProjectHiring(projectID);
  const { sectionsMap, isLoading: l2 } = useSections();
  const ret: RosterData = {
    data: [],
    layout: {},
    key: requestId,
    isLoading: l1 || l2,
  };

  const memo = useMemo(() => {
    const dataMap: Dictionary<WorkSessionExtended> = {};
    const layout: Layout = {};
    const musicianSectionOrderInWorkSessions = {};
    const allSectionsInProjectMap: Dictionary<WorkSessionSectionExtended> = {};

    hiring?.sessions.forEach((session) => {
      dataMap[session.id] = {
        ...session,
        sectionMap: {},
        sections: [],
      };
    });

    hiring?.jobs.forEach((job) => {
      if (musicianSectionOrderInWorkSessions[job.musicianID]) {
        if (musicianSectionOrderInWorkSessions[job.musicianID][job.sessionID]) {
          musicianSectionOrderInWorkSessions[job.musicianID][job.sessionID][
            job.sectionID
          ] = job.sectionOrder;
        } else {
          musicianSectionOrderInWorkSessions[job.musicianID][job.sessionID] = {
            [job.sectionID]: job.sectionOrder,
          };
        }
      } else {
        musicianSectionOrderInWorkSessions[job.musicianID] = {
          [job.sessionID]: { [job.sectionID]: job.sectionOrder },
        };
      }
      if (dataMap[job.sessionID].sections[job.sectionID]) {
        dataMap[job.sessionID].sections[job.sectionID].jobs.push(job);
      } else {
        const section = sectionsMap[job.sectionID];
        if (section) {
          allSectionsInProjectMap[section.id] = {
            ...section,
            jobs: [],
          };
          dataMap[job.sessionID].sections[job.sectionID] = {
            ...section,
            jobs: [job],
          };
        }
      }
    });

    const allSectionsInProject = mapToArray(allSectionsInProjectMap);

    const dataArray = Object.values(dataMap);
    dataArray.sort(
      (a, b) =>
        moment(a.dateFromUTC).valueOf() - moment(b.dateFromUTC).valueOf()
    );

    dataArray.forEach((ws, i) => {
      let prevSessionID: number;
      if (Number(i) > 0) {
        prevSessionID = dataArray[Number(i) - 1]?.id;
      }
      const _wsSections = ws.sections;
      ws.sections = JSON.parse(JSON.stringify(allSectionsInProject));
      ws.sections.forEach((section) => {
        section.jobs = _wsSections[section.id]?.jobs ?? [];
        section.jobs.sort((a, b) => a.sectionOrder - b.sectionOrder);
        for (const key in section.jobs) {
          if (Object.hasOwnProperty.call(section.jobs, key)) {
            const job = { ...section.jobs[key] };
            if (prevSessionID && job.musicianID && job.sessionID) {
              const prev =
                musicianSectionOrderInWorkSessions[job.musicianID][
                  prevSessionID
                ];
              const prevPos = prev ? prev[job.sectionID] : -1;
              const current =
                musicianSectionOrderInWorkSessions[job.musicianID][
                  job.sessionID
                ];
              const currentPos = current ? current[job.sectionID] : -1;
              if (prevPos >= 0 && currentPos >= 0) {
                job.sectionOrderDiff = prevPos - currentPos;
              }
            }

            section.jobs[key] = new Job(job);
          }
        }
        if (
          layout[section.id] === undefined ||
          (layout[section.id] ?? 0) < section.jobs.length
        ) {
          layout[section.id] = section.jobs.length;
        }
      });

      ws.sections.sort((a, b) => {
        if (a.familyPos == b.familyPos) {
          return a.pos - b.pos;
        }
        return a.familyPos - b.familyPos;
      });
    });

    ret.data = dataArray;
    ret.layout = layout;

    return ret;
  }, [requestId, l1, l2]);

  if (ret.isLoading) return ret;

  return memo;
}

export function useProjectRostersSelectAll() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectHiring, isLoading: l1 } = useProjectHiring(projectID);
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  const [requested, setRequested] = useState(false);
  const workSessions = projectHiring?.sessions ?? [];

  const ret = () => {
    setRequested(true);
  };

  useEffect(() => {
    if (!l1) {
      setInit(true);
    }
  }, [l1]);

  useEffect(() => {
    if (init && requested && workSessions.length) {
      dispatch(projectRosters_Select_WorkSessions(workSessions));
      setRequested(false);
    }
  }, [init, requested, workSessions]);

  return ret;
}
