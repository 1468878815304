import { Box, Sheet, Textarea, Typography } from "@mui/joy";
import { Skeleton } from "@mui/material";
import { WIDGET } from "config";
import { WorkSession_Entity } from "entities/workSession";

import { Avatar, Button as JoyButton } from "@mui/joy";
import { GOOGLE_MAPS_GEOCODING_KEY } from "config";
import IframeResizer from "iframe-resizer-react";
import { useEffect, useState } from "react";
import { useUpdateWorkSessionMutation } from "redux/workSession/workSessionEndpoints";
import { useWorkSession } from "redux/workSession/workSessionHooks";
// styles

import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { useVenue } from "redux/venue/venueHooks";
import moment from "moment";
import { setFormOpen } from "reducers/rhapsody";
import { MercuryWidget } from "hooks/MercuryWidget/mercuryWidget";

/**
 *
 * @returns {ReactElement} WorkSessionSideBar page
 */
export function WorkSessionSideBar({
  workSessionID,
}: {
  workSessionID: number;
}) {
  const { workSession: _workSession } = useWorkSession(workSessionID);
  const [workSession, setWorkSession] = useState<WorkSession_Entity>();
  const [updateWorkSession] = useUpdateWorkSessionMutation();
  const { venue } = useVenue(workSession?.venueID);
  const dispatch = useDispatch();

  useEffect(() => {
    setWorkSession(_workSession);
  }, [_workSession]);

  if (!workSession?.id) return <Skeleton sx={{ width: 360, p: 1 }} />;

  return (
    <Box
      sx={{
        width: 360,
        flexShrink: 0,
        display: "flex",
        gap: 2,
        flexDirection: "column",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: "8px",
          p: 1,
          display: "flex",
          gap: 1,
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Avatar color="primary" variant="soft" size="sm">
            {workSession.sessionTypeCode}
          </Avatar>
          <Typography level="h6">{workSession.title}</Typography>
        </Box>
        <Typography level="body2">{_workSession?.formatDateRange()}</Typography>
        <Typography level="body2" color="primary">
          {moment(workSession.dateFromUTC).fromNow()}
        </Typography>
        <Box sx={{ mt: 1, display: "flex" }}>
          <img
            alt="google-maps"
            style={{
              width: 80,
              height: 80,
            }}
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${venue?.latitude},${venue?.longitude}&zoom=15&size=200x230&maptype=roadmap&markers=color:red%7C${venue?.latitude},${venue?.longitude}&key=${GOOGLE_MAPS_GEOCODING_KEY}`}
          />
          <Typography level="body2" sx={{ ml: 1 }}>
            {venue?.name}
            <br />
            {venue?.address}
            <br />
            {venue?.zipcode} {venue?.city} {venue?.state}
          </Typography>
        </Box>
        <JoyButton
          startDecorator={<i className="fa-duotone fa-pen-to-square"></i>}
          fullWidth
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "workSessionEdit" }))
          }
          size="sm"
          sx={{ mt: 1 }}
          variant="soft"
        >
          Edit Details
        </JoyButton>
      </Sheet>
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: "8px",
          p: 1,
          display: "flex",
          gap: 1,
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography level="body2">Internal Memo:</Typography>
        <Textarea
          placeholder="Anything to write down about this work session?"
          sx={{ width: "100%", background: "rgba(255,235,59,0.1)" }}
          onChange={(e) =>
            setWorkSession((p) => ({ ...p, description: e.target.value }))
          }
          value={workSession.description ?? ""}
          onBlur={() => {
            updateWorkSession({
              id: workSession.id,
              body: { description: workSession.description },
            });
          }}
          size="sm"
          endDecorator={
            <Typography level="body4">Not visible by musicians</Typography>
          }
        />
      </Sheet>
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: "8px",
          p: 1,
        }}
      >
        <Typography level="body2">Hiring Progress:</Typography>
        <MercuryWidget workSessionID={workSessionID} />
      </Sheet>
    </Box>
  );
}
