import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedProjectIDSelector, setFormOpen } from "reducers/rhapsody";
import { useLazyGetProjectPiecesQuery } from "redux/projectPiece/projectPieceEndpoints";
import { useGetCurrentSubscriptionQuery } from "redux/subscription/subscriptionEndpoints";
import { meSelector } from "selectors/me";

export type PricingPlan = Overture | Mezzo | Finale;

export const overture = "overture";
export type Overture = "overture";

export const mezzo = "mezzo";
export type Mezzo = "mezzo";

export const finale = "finale";
export type Finale = "finale";

export function useMySubscription(): Overture | Mezzo | Finale | "" {
  const {
    data: subscription,
    isError,
    isLoading,
  } = useGetCurrentSubscriptionQuery();
  // return overture;
  const me = useSelector(meSelector);
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;
  if (superadmin) return finale;
  if (isError) return overture;
  if (isLoading) return "";
  if (subscription?.productName.toLowerCase().indexOf("finale") >= 0)
    return finale;
  if (subscription?.id) return mezzo;

  return overture;
}

let _callback: () => void;
export function useAddProjectPieceRequest() {
  const projectID = useSelector(selectedProjectIDSelector);
  const [getProjectPieces, { data, requestId }] = useLazyGetProjectPiecesQuery({
    refetchOnFocus: true,
  });
  const mySubscription = useMySubscription();
  const dispatch = useDispatch();
  const openSubscribe = () =>
    dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));

  useEffect(() => {
    if (data?.ids?.length !== undefined && _callback) {
      const count = data.ids.length ?? 0;
      switch (mySubscription) {
        case overture:
          openSubscribe();
          break;
        case mezzo:
          if (count < 4) {
            _callback();
          } else {
            openSubscribe();
          }
          break;
        default:
          break;
      }
      _callback = undefined;
    }
  }, [requestId, data, _callback]);

  return (callback: () => void) => {
    _callback = callback;
    if (mySubscription === overture) openSubscribe();
    if (mySubscription === finale) {
      callback();
      return;
    }
    getProjectPieces({
      filters: JSON.stringify([
        {
          name: "project_pieces.projectID",
          comparison: "eq",
          value: projectID,
        },
      ]),
    });
  };
}
