import {
  Box,
  Chip,
  List,
  ListDivider,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Dictionary } from "@reduxjs/toolkit";
import { Musician } from "entities/musician";
import { useMusicians } from "redux/musician/musicianHooks";
import MusicianAvatar from "../MusicianAvatar";

type Issue = {
  musicians: Musician[];
  reason: string;
};

/**
 *
 * @returns {ReactElement} PotentialMusicianDuplicates page
 */
export function PotentialMusicianDuplicates() {
  const { musicians } = useMusicians();
  const issues: Issue[] = [];

  const phoneMap = musicians.reduce<Dictionary<Musician[]>>((a, v) => {
    if (v.phone) {
      if (a[v.phone]) {
        a[v.phone].push(v);
      } else {
        a[v.phone] = [v];
      }
    }
    return a;
  }, {});

  const nicknameMap = musicians.reduce<Dictionary<Musician[]>>((a, v) => {
    if (v.nickName) {
      if (a[v.nickName]) {
        a[v.nickName].push(v);
      } else {
        a[v.nickName] = [v];
      }
    }
    return a;
  }, {});

  const fullNameMap = musicians.reduce<Dictionary<Musician[]>>((a, v) => {
    const fullName = `${v.firstName} ${v.lastName}`.toLowerCase();
    if (fullName) {
      if (a[fullName]) {
        a[fullName].push(v);
      } else {
        a[fullName] = [v];
      }
    }
    return a;
  }, {});

  for (const k in phoneMap) {
    if (Object.prototype.hasOwnProperty.call(phoneMap, k)) {
      const item = phoneMap[k];
      if (item.length >= 2) {
        issues.push({
          musicians: item,
          reason: `Same phone number: ${item[0].phone}`,
        });
      }
    }
  }

  for (const k in nicknameMap) {
    if (Object.prototype.hasOwnProperty.call(nicknameMap, k)) {
      const item = nicknameMap[k];
      if (item.length >= 2) {
        issues.push({
          musicians: item,
          reason: `Same nickname: ${item[0].nickName}`,
        });
      }
    }
  }

  for (const k in fullNameMap) {
    if (Object.prototype.hasOwnProperty.call(fullNameMap, k)) {
      const item = fullNameMap[k];
      if (item.length >= 2) {
        issues.push({
          musicians: item,
          reason: `Same name: ${item[0].firstName} ${item[0].lastName}`,
        });
      }
    }
  }

  const label = (
    <Typography
      color="danger"
      endDecorator={
        issues.length > 0 ? <i className="fa-solid fa-chevron-down"></i> : []
      }
    >
      {issues.length} Potential Duplicates
    </Typography>
  );

  if (issues.length === 0) return label;

  return (
    <Tooltip
      variant="outlined"
      arrow
      title={
        <Box>
          <List sx={{ maxHeight: 400, overflow: "auto" }}>
            {issues.map((i) => (
              <>
                <ListItem key={i.reason}>
                  <Box>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      {i.musicians.map((m) => (
                        <Chip
                          color="neutral"
                          variant="soft"
                          startDecorator={
                            <MusicianAvatar size={18} musician={m} />
                          }
                          key={m.id}
                          size="sm"
                        >
                          {m.fullName()}
                        </Chip>
                      ))}
                    </Box>
                    <Typography
                      startDecorator={
                        <i className="fa-solid fa-arrow-right"></i>
                      }
                      level="body2"
                    >
                      {i.reason}
                    </Typography>
                  </Box>
                </ListItem>
                <ListDivider inset="gutter" />
              </>
            ))}
          </List>
        </Box>
      }
    >
      {label}
    </Tooltip>
  );
}
