import { Button, Divider, List, ListItemButton, Typography } from "@mui/joy";
import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useExporter } from "features/exporter/exporter";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import {
  overture,
  useMySubscription,
} from "hooks/subscription/restrictionHooks";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  setDescription420,
  setFormOpen,
  setMercuryJobIDForHistory,
} from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";

/**
 *
 * @returns {ReactElement} FormerAssignments page
 */
export function FormerAssignments() {
  const open = useSelector(formOpenSelector("formerAssignments"));
  const dispatch = useDispatch();
  const subscription = useMySubscription();
  const exporter = useExporter();
  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "formerAssignments" }));
  };
  const utils = useSelector(layoutUtilsSelector);
  const { assignments, musiciansMap, hProject, stagesMap } = utils;

  const formerAssignments = assignments.filter((a) => !(a.active ?? true));

  const download = () => {
    if (subscription === overture) {
      dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));
      dispatch(
        setDescription420(
          "Excel Export is an exclusive feature to the Mezzo & Finale plan."
        )
      );
    } else {
      exporter.formerAssignments();
    }
  };

  return (
    <DialogClose open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography level="h6">Removed</Typography>
        <Typography level="body2">
          Musicians no longer in the Project.
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Button onClick={download}>Export in Excel</Button>
        <List size="sm">
          {formerAssignments.map((a) => {
            const musician = musiciansMap[a.musicianID];
            const stage = stagesMap[a.mercuryStageID];
            const job = hProject.jobs.find((j) => j.uuid === a.mercuryJobID);

            if (!stage) return <></>;

            return (
              <ListItemButton
                className={`musician-${a.musicianID}`}
                onClick={() => {
                  dispatch(setMercuryJobIDForHistory(a.mercuryJobID));
                  dispatch(
                    setFormOpen({
                      isOpen: true,
                      formID: "mercuryHistory",
                    })
                  );
                }}
                key={a.id}
                sx={{ gap: 0.5 }}
              >
                <MusicianAvatar musician={musician} size={25} />
                <Box>
                  <Typography level="body2">{musician?.fullName()}</Typography>
                  <Typography level="body4">
                    {stage?.wording()},{" "}
                    {moment(job?.currentStage?.createdAt).fromNow()}
                  </Typography>
                </Box>
              </ListItemButton>
            );
          })}
        </List>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="neutral" variant="soft">
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
