import {
  MenuItem,
  Rating,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedMusicianIDSelector,
  selectedMusicianNoteIDSelector,
  setSelectedMusicianNoteID,
  setTabIndex,
} from "reducers/rhapsody";
// styles
import { Box } from "@mui/system";
import {
  LocalizationProvider,
  StaticDatePicker,
  StaticTimePicker,
} from "@mui/x-date-pickers";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SketchPicker } from "react-color";

import { setFormOpen } from "reducers/rhapsody";
import { useMusicianNote } from "redux/musicianNote/musicianNoteHooks";
import { MusicianNote_Entity } from "entities/musicianNote";
import moment from "moment";
import {
  useCreateMusicianNoteMutation,
  useDeleteMusicianNoteMutation,
  useUpdateMusicianNoteMutation,
} from "redux/musicianNote/musicianNoteEndpoints";
import { Button } from "@mui/joy";
import { useNoteTypes } from "redux/noteType/noteTypeHooks";

export default function FormMusicianNote() {
  const [loading, setLoading] = useState(false);
  const selectedMusicianNoteID = useSelector(selectedMusicianNoteIDSelector);
  const selectedMusicianID = useSelector(selectedMusicianIDSelector);
  const { musicianNote: _musicianNote } = useMusicianNote(
    selectedMusicianNoteID
  );

  const [updateMusicianNote] = useUpdateMusicianNoteMutation();
  const [createMusicianNote] = useCreateMusicianNoteMutation();
  const [deleteMusicianNote] = useDeleteMusicianNoteMutation();

  const { noteTypes } = useNoteTypes();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("lg"));
  const formOpen = useSelector(formOpenSelector("musicianNote"));
  const [musicianNote, setMusicianNote] = useState<
    Partial<MusicianNote_Entity>
  >({
    description: "",
    musicianID: selectedMusicianID,
    value: "",
  });

  useEffect(() => {
    if (_musicianNote?.id) {
      setMusicianNote(_musicianNote);
    }
  }, [_musicianNote]);

  const dispatch = useDispatch();

  const getValueInput = () => {
    const noteType = noteTypes?.find((t) => t.id == musicianNote.noteTypeID);
    switch (noteType?.kind) {
      case "date":
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker
              openTo="day"
              value={musicianNote.value}
              onChange={(newValue) => {
                setMusicianNote((s) => ({
                  ...s,
                  value: moment(newValue).utc().format(),
                }));
              }}
            />
          </LocalizationProvider>
        );
      case "time":
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticTimePicker
              displayStaticWrapperAs="mobile"
              value={musicianNote.value}
              onChange={(newValue) => {
                setMusicianNote((s) => ({
                  ...s,
                  value: moment(newValue).utc().format(),
                }));
              }}
            />
          </LocalizationProvider>
        );
      case "boolean":
        return (
          <Switch
            size="medium"
            checked={musicianNote.value === "true" ? true : false}
            onChange={(e) => {
              setMusicianNote((s) => ({ ...s, value: `${e.target.checked}` }));
            }}
          />
        );
      case "color":
        return (
          <SketchPicker
            color={musicianNote.value}
            onChangeComplete={(color) => {
              setMusicianNote((s) => ({ ...s, value: color.hex }));
            }}
          />
        );
      case "dateRange":
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              value={
                musicianNote.value
                  ? JSON.parse(musicianNote.value)
                  : [null, null]
              }
              onChange={(newValue) => {
                const v = [];
                newValue?.forEach((t) => v.push(moment(t).utc().format()));
                setMusicianNote((s) => ({ ...s, value: JSON.stringify(v) }));
              }}
            />
          </LocalizationProvider>
        );
      case "select":
        break;
      case "textfield":
        return (
          <TextField
            multiline
            maxRows={10}
            fullWidth
            placeholder={noteType.name}
            rows={5}
            value={musicianNote.value}
            onChange={(e) =>
              setMusicianNote((s) => ({ ...s, value: e.target.value }))
            }
          />
        );
      case "number":
        break;
      case "rating":
        return (
          <Rating
            size="large"
            name="simple-controlled"
            value={parseInt(musicianNote.value)}
            onChange={(event, newValue) => {
              setMusicianNote((s) => ({ ...s, value: `${newValue}` }));
            }}
          />
        );
        break;
      case "slider":
        break;
      default:
        break;
    }
  };

  const save = async () => {
    if (musicianNote?.id) {
      updateMusicianNote({ id: musicianNote.id, body: { ...musicianNote } });
    } else {
      createMusicianNote(musicianNote);
    }
    onClose();
  };

  const disabled = musicianNote.value.length === 0;

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "musicianNote" }));
    dispatch(setSelectedMusicianNoteID());
  };

  const onDelete = async () => {
    deleteMusicianNote(musicianNote.id);
    dispatch(setFormOpen({ isOpen: false, formID: "musicianNote" }));
    dispatch(setTabIndex({ tabID: "musicians", value: 0 }));
  };

  return (
    <DialogClose
      open={formOpen}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="responsive-dialog-title"
    >
      {loading ? (
        <div>
          <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogContent>
            <div
              style={{
                background: "#e3f2fd",
                padding: 16,
                borderRadius: "16px",
                textAlign: "center",
              }}
            >
              <i
                style={{ fontSize: 80, color: "#001E3C" }}
                className="fa-duotone fa-notes"
              ></i>
              <Typography style={{ color: "#001E3C" }} variant="h6">
                {musicianNote?.id ? "Edit" : "New"} Musician Note
              </Typography>
              <Typography
                style={{ color: "#001E3C", opacity: 0.8 }}
                variant="body2"
              >
                Only you see this note
              </Typography>
            </div>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 8 }}
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  id="noteTypeID"
                  label="Type"
                  autoFocus
                  helperText="Required"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={musicianNote.noteTypeID}
                  onChange={(e) => {
                    setMusicianNote((s) => ({
                      ...s,
                      noteTypeID: parseInt(e.target.value),
                      value: "",
                    }));
                  }}
                  select
                >
                  {noteTypes?.map((t) => (
                    <MenuItem value={t.id} key={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                {getValueInput()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {musicianNote?.id ? (
              <Button variant="soft" color="danger" onClick={onDelete}>
                Delete
              </Button>
            ) : (
              []
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button onClick={onClose} variant="soft" color="neutral">
              Cancel
            </Button>
            <Button
              autoFocus
              disabled={musicianNote.value.length === 0}
              onClick={save}
            >
              {musicianNote?.id ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </>
      )}
    </DialogClose>
  );
}
