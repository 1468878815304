import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { ProjectPiece_Entity } from "entities/projectPiece";
import { rhapsodyApi } from "../api/rhapsodyApi";

const projectPiecesAdapter = createEntityAdapter<ProjectPiece_Entity>();
const initialState = projectPiecesAdapter.getInitialState();

export const projectPieceEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectPieces: build.query<
      EntityState<ProjectPiece_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `projectPieces`,
          params: args,
        };
      },
      transformResponse: (responseData: ProjectPiece_Entity[]) => {
        return projectPiecesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["projectPieces"],
    }),
    getProjectPiece: build.query<ProjectPiece_Entity, number>({
      query: (id) => `projectPieces/${id}`,
      providesTags: (result, error, id) => [{ type: "projectPieces", id }],
    }),
    createProjectPiece: build.mutation<
      ProjectPiece_Entity,
      Partial<ProjectPiece_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `projectPieces`,
      }),
      invalidatesTags: ["projectPieces"],
    }),
    updateProjectPiece: build.mutation<
      void,
      { id: number; body: Partial<ProjectPiece_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projectPieces/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "projectPieces", id },
        "projectPieces",
      ],
    }),
    updateBatchProjectPieces: build.mutation<
      ProjectPiece_Entity,
      Partial<ProjectPiece_Entity>[]
    >({
      query: (body) => ({
        method: "PUT",
        body: body,
        url: `projectPieces/batch`,
      }),
      invalidatesTags: () => ["projectPieces"],
    }),
    deleteProjectPiece: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projectPieces/${id}`,
      }),
      invalidatesTags: ["projectPieces", "projectPieceSections"],
    }),
  }),
});

export const {
  useLazyGetProjectPieceQuery,
  useGetProjectPieceQuery,
  useUpdateBatchProjectPiecesMutation,
  useLazyGetProjectPiecesQuery,
  useGetProjectPiecesQuery,
  useCreateProjectPieceMutation,
  useDeleteProjectPieceMutation,
  useUpdateProjectPieceMutation,
} = projectPieceEndpoints;

export default projectPieceEndpoints;
