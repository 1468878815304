import { ProjectContact } from "entities/projectContact";
import { useMemo } from "react";
import {
  useGetProjectContactQuery,
  useGetProjectContactsQuery,
} from "./projectContactEndpoints";

export function useProjectContact(projectContactID: number | undefined) {
  const projectContactQuery = useGetProjectContactQuery(projectContactID ?? 0, {
    skip: (projectContactID ?? 0) <= 0,
  });
  const projectContactEntity = projectContactQuery.data;

  return useMemo(() => {
    const ret: typeof projectContactQuery & {
      projectContact?: ProjectContact | undefined;
    } = {
      ...projectContactQuery,
    };
    if (projectContactEntity)
      ret.projectContact = new ProjectContact(projectContactEntity);
    return ret;
  }, [projectContactEntity]);
}

export function useProjectContacts(projectID: number) {
  const projectContactQuery = useGetProjectContactsQuery({
    filters: JSON.stringify([
      {
        name: "project_contacts.projectID",
        comparison: "eq",
        value: projectID,
      },
    ]),
  });
  const projectContactEntitiesMap = projectContactQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectContactQuery & {
      projectContacts?: ProjectContact[];
      projectContactsMap?: { [id: number]: ProjectContact };
    } = {
      ...projectContactQuery,
      projectContacts: [],
      projectContactsMap: {},
    };
    if (projectContactEntitiesMap) {
      const projectContacts = [];
      const projectContactsMap = {};

      for (const key in projectContactEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(projectContactEntitiesMap, key)
        ) {
          const c = projectContactEntitiesMap[key];
          const projectContact = new ProjectContact(c);
          projectContacts.push(projectContact);
          projectContactsMap[projectContact.id] = projectContact;
        }
      }
      ret.projectContacts = projectContacts;
      ret.projectContactsMap = projectContactsMap;
    }

    return ret;
  }, [projectContactEntitiesMap]);
}
