import { Musician } from "entities/musician";
import { useMemo } from "react";
import {
  useGetMusicianQuery,
  useGetMusiciansForGroupQuery,
  useGetMusiciansForProjectSectionQuery,
  useGetMusiciansForSectionQuery,
  useGetMusiciansForWorkSessionQuery,
  useGetMusiciansQuery,
  useGetMusiciansSuggestionsQuery,
  useGetProjectHiringMusiciansQuery,
  useGetSeasonMusiciansQuery,
} from "./musicianEndpoints";
import { Assignment } from "entities/assignment";

export function useMusician(
  musicianID: number | undefined,
  opt?: { skip?: boolean }
) {
  const musicianQuery = useGetMusicianQuery(musicianID ?? 0, {
    skip: (musicianID ?? 0) <= 0 || opt?.skip,
  });
  const musicianEntity = musicianQuery.data;

  return useMemo(() => {
    const ret: typeof musicianQuery & { musician?: Musician | undefined } = {
      ...musicianQuery,
    };
    if (musicianEntity) ret.musician = new Musician(musicianEntity);
    return ret;
  }, [musicianEntity]);
}

export function useProjectHiringMusicians(
  projectID: number,
  opt?: { skip?: boolean }
) {
  const musicianQuery = useGetProjectHiringMusiciansQuery(projectID, {
    skip: (opt?.skip ?? false) || !projectID,
  });
  const musicianEntitiesMap = musicianQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianQuery & {
      musicians?: Musician[];
      musiciansMap?: { [id: number]: Musician };
    } = {
      ...musicianQuery,
      musicians: [],
      musiciansMap: {},
    };
    if (musicianEntitiesMap) {
      const musicians = [];
      const musiciansMap = {};

      for (const key in musicianEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(musicianEntitiesMap, key)) {
          const c = musicianEntitiesMap[key];
          const musician = new Musician(c);
          musicians.push(musician);
          musiciansMap[musician.id] = musician;
        }
      }
      ret.musicians = musicians;
      ret.musiciansMap = musiciansMap;
    }

    return ret;
  }, [musicianEntitiesMap]);
}

export function useWorkSessionMusicians(workSessionID: number) {
  const musicianQuery = useGetMusiciansForWorkSessionQuery(workSessionID, {
    skip: !workSessionID,
  });
  const musicianEntitiesMap = musicianQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianQuery & {
      musicians?: Musician[];
      musiciansMap?: { [id: number]: Musician };
    } = {
      ...musicianQuery,
      musicians: [],
      musiciansMap: {},
    };
    if (musicianEntitiesMap) {
      const musicians = [];
      const musiciansMap = {};

      for (const key in musicianEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(musicianEntitiesMap, key)) {
          const c = musicianEntitiesMap[key];
          const musician = new Musician(c);
          musicians.push(musician);
          musiciansMap[musician.id] = musician;
        }
      }
      ret.musicians = musicians;
      ret.musiciansMap = musiciansMap;
    }

    return ret;
  }, [musicianEntitiesMap]);
}

export function useMusiciansForGroup(groupID: number, skip: boolean) {
  const musicianQuery = useGetMusiciansForGroupQuery(groupID, {
    skip: skip || groupID <= 0,
  });
  const musicianEntitiesMap = musicianQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianQuery & {
      musicians?: Musician[];
      musiciansMap?: { [id: number]: Musician };
    } = {
      ...musicianQuery,
      musicians: [],
      musiciansMap: {},
    };
    if (musicianEntitiesMap) {
      const musicians = [];
      const musiciansMap = {};

      for (const key in musicianEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(musicianEntitiesMap, key)) {
          const c = musicianEntitiesMap[key];
          const musician = new Musician(c);
          musicians.push(musician);
          musiciansMap[musician.id] = musician;
        }
      }
      ret.musicians = musicians;
      ret.musiciansMap = musiciansMap;
    }

    return ret;
  }, [musicianEntitiesMap]);
}

export function useMusiciansForSection(
  body: {
    sectionID: number;
    workSessionID: number;
    sectionRoleID?: number;
  },
  skip: boolean
) {
  const musicianQuery = useGetMusiciansForSectionQuery(body, { skip });
  const musicianEntitiesMap = musicianQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianQuery & {
      musicians?: Musician[];
      musiciansMap?: { [id: number]: Musician };
    } = {
      ...musicianQuery,
      musicians: [],
      musiciansMap: {},
    };
    if (musicianEntitiesMap) {
      const musicians = [];
      const musiciansMap = {};

      for (const key in musicianEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(musicianEntitiesMap, key)) {
          const c = musicianEntitiesMap[key];
          const musician = new Musician(c);
          musicians.push(musician);
          musiciansMap[musician.id] = musician;
        }
      }
      ret.musicians = musicians;
      ret.musiciansMap = musiciansMap;
    }

    return ret;
  }, [musicianEntitiesMap]);
}

export function useMusiciansForProjectSection(
  body: {
    sectionID: number;
    projectID: number;
    sectionRoleID?: number;
  },
  skip: boolean
) {
  const musicianQuery = useGetMusiciansForProjectSectionQuery(body, { skip });
  const musicianEntitiesMap = musicianQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianQuery & {
      musicians?: Musician[];
      musiciansMap?: { [id: number]: Musician };
    } = {
      ...musicianQuery,
      musicians: [],
      musiciansMap: {},
    };
    if (musicianEntitiesMap) {
      const musicians = [];
      const musiciansMap = {};

      for (const key in musicianEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(musicianEntitiesMap, key)) {
          const c = musicianEntitiesMap[key];
          const musician = new Musician(c);
          musicians.push(musician);
          musiciansMap[musician.id] = musician;
        }
      }
      ret.musicians = musicians;
      ret.musiciansMap = musiciansMap;
    }

    return ret;
  }, [musicianEntitiesMap]);
}

export function useMusicians(params?, opt?: { skip?: boolean }) {
  const musicianQuery = useGetMusiciansQuery(params, { skip: opt?.skip });
  const musicianEntitiesMap = musicianQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianQuery & {
      musicians?: Musician[];
      musiciansMap?: { [id: number]: Musician };
    } = {
      ...musicianQuery,
      musicians: [],
      musiciansMap: {},
    };
    if (musicianEntitiesMap) {
      const musicians = [];
      const musiciansMap = {};

      for (const key in musicianEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(musicianEntitiesMap, key)) {
          const c = musicianEntitiesMap[key];
          const musician = new Musician(c);
          musicians.push(musician);
          musiciansMap[musician.id] = musician;
        }
      }
      ret.musicians = musicians;
      ret.musiciansMap = musiciansMap;
    }

    ret.musicians.sort((a, b) => a.lastName?.localeCompare(b.lastName));

    return ret;
  }, [musicianEntitiesMap]);
}

export function useSeasonMusicians(seasonID?: number) {
  const musicianQuery = useGetSeasonMusiciansQuery(seasonID, {
    skip: !seasonID,
    refetchOnMountOrArgChange: true,
  });
  const musicianEntitiesMap = musicianQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianQuery & {
      musicians?: Musician[];
      musiciansMap?: { [id: number]: Musician };
    } = {
      ...musicianQuery,
      musicians: [],
      musiciansMap: {},
    };
    if (musicianEntitiesMap) {
      const musicians = [];
      const musiciansMap = {};

      for (const key in musicianEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(musicianEntitiesMap, key)) {
          const c = musicianEntitiesMap[key];
          const musician = new Musician(c);
          musicians.push(musician);
          musiciansMap[musician.id] = musician;
        }
      }
      ret.musicians = musicians;
      ret.musiciansMap = musiciansMap;
    }

    ret.musicians.sort((a, b) => a.lastName?.localeCompare(b.lastName));

    return ret;
  }, [musicianEntitiesMap]);
}

export function useMusicianSuggestions(chairID: number, skip: boolean) {
  const musicianQuery = useGetMusiciansSuggestionsQuery(chairID, {
    skip,
    refetchOnMountOrArgChange: true,
  });
  const musicianEntitiesMap = musicianQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianQuery & {
      musicians?: Musician[];
      musiciansMap?: { [id: number]: Musician };
    } = {
      ...musicianQuery,
      musicians: [],
      musiciansMap: {},
    };
    if (musicianEntitiesMap) {
      const musicians = [];
      const musiciansMap = {};

      for (const key in musicianEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(musicianEntitiesMap, key)) {
          const c = musicianEntitiesMap[key];
          const musician = new Musician(c);
          musicians.push(musician);
          musiciansMap[musician.id] = musician;
        }
      }
      ret.musicians = musicians;
      ret.musiciansMap = musiciansMap;
    }

    ret.musicians.sort((a, b) => a.position - b.position);

    return ret;
  }, [musicianEntitiesMap]);
}

export function useMusiciansForAssignments(assignments: Assignment[]) {
  const ret = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.id",
          comparison: "in",
          value: assignments.reduce((a, v) => {
            a.push(v.musicianID);
            return a;
          }, []),
        },
      ]),
    },
    { skip: !assignments || assignments?.length === 0 }
  );

  return ret;
}

export function useSearchMusicians(searchText: string) {
  const ret = useMusicians({
    limit: 50,
    filters: JSON.stringify([
      {
        name: "musicians.firstName",
        comparison: "like",
        value: searchText.toLocaleLowerCase(),
        subFilters: [
          {
            name: "musicians.nickName",
            comparison: "like",
            value: searchText.toLocaleLowerCase(),
            orOperator: true,
          },
          {
            name: "musicians.lastName",
            comparison: "like",
            value: searchText.toLocaleLowerCase(),
            orOperator: true,
          },
        ],
      },
    ]),
  });

  return ret;
}
