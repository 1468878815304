import { nanoid } from "@reduxjs/toolkit";
import { ProjectHiring_Entity } from "entities/projectHiring";
import { rhapsodyApi } from "../api/rhapsodyApi";

export const projectHiringEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectHiring: build.query<ProjectHiring_Entity, number>({
      query: (id) => `projects/${id}/hiring2`,
      providesTags: (result, error, id) => [{ type: "projectHirings", id }],
      transformResponse: (responseData: ProjectHiring_Entity, meta, arg) => {
        return { ...responseData, id: arg, key: nanoid() };
      },
    }),
    updateProjectHiring: build.mutation<
      void,
      {
        projectID;
        body: {
          sessionIDs: number[];
          musicianIDs: number[];
          requestedMusicianID: number;
        };
      }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projects/${args.projectID}/hiring`,
      }),
      invalidatesTags: ["projectHirings", "workSessionSections"],
    }),
  }),
});

export const { useGetProjectHiringQuery, useUpdateProjectHiringMutation } =
  projectHiringEndpoints;

export default projectHiringEndpoints;
