import { Place } from "@mui/icons-material";
import { Box, Card, CircularProgress, Input } from "@mui/joy";
import { Grid } from "@mui/material";
import { NerdPhoneField } from "@nerdjs/nerd-ui";
import { Venue_Entity } from "entities/venue";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAPS_GEOCODING_KEY } from "config";
import { getLatLgn, getTimezone } from "helpers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectedVenueIDSelector } from "reducers/rhapsody";
import { useUpdateVenueMutation } from "redux/venue/venueEndpoints";
import { useVenue } from "redux/venue/venueHooks";

export default function VenueGeneral({ id }) {
  const params = useParams();
  const venueID =
    id || parseInt(params.venueID) || useSelector(selectedVenueIDSelector);
  const { venue } = useVenue(venueID);
  const [updateVenue, { isLoading: isUpdating }] = useUpdateVenueMutation();

  const [_venue, _setVenue] = useState<Venue_Entity | undefined>();

  useEffect(() => {
    _setVenue(new Venue_Entity(venue));
  }, [venue]);

  const getVenueMapsInfo = async () => {
    let tzName;

    const a = `${_venue.address} ${_venue.zipcode} ${_venue.city} ${_venue.state}`;
    const coordinates = await getLatLgn(a);

    if (coordinates.lat && coordinates.lng) {
      tzName = await getTimezone(coordinates.lat, coordinates.lng);
    }

    updateVenue({
      id: venue.id,
      body: {
        latitude: coordinates ? `${coordinates.lat}` : undefined,
        longitude: coordinates ? `${coordinates.lng}` : undefined,
        tzName,
      },
    });
  };

  const save = async (name: string, numeric?: boolean) => {
    if (venue[name] === _venue[name]) return;
    updateVenue({
      id: venue.id,
      body: {
        [name]: numeric ? Number(_venue[name]) : _venue[name],
      },
    });
  };

  if (!_venue) return <CircularProgress />;
  return (
    <Box>
      <Grid container alignItems="stretch" spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", gap: 1, flexDirection: "column" }}
        >
          <Input
            startDecorator="Name"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("name");
            }}
            fullWidth
            onBlur={() => save("name")}
            className="inputBase"
            value={_venue.name || ""}
            onChange={(e) =>
              _setVenue((v) => {
                v.name = e.target.value;
                return { ...v };
              })
            }
          />
          <Input
            startDecorator="Contact"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("contact");
            }}
            fullWidth
            onBlur={() => save("contact")}
            className="inputBase"
            value={_venue.contact || ""}
            onChange={(e) =>
              _setVenue((v) => {
                v.contact = e.target.value;
                return { ...v };
              })
            }
          />
          <Input
            startDecorator="Email"
            type="email"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("email");
            }}
            fullWidth
            onBlur={() => save("email")}
            className="inputBase"
            value={_venue.email || ""}
            onChange={(e) =>
              _setVenue((v) => {
                v.email = e.target.value;
                return { ...v };
              })
            }
          />
          <NerdPhoneField
            value={_venue.phone}
            onAccept={(e) =>
              _setVenue((v) => {
                v.phone = e;
                return { ...v };
              })
            }
            textFieldProps={{
              label: "Phone Number",
              fullWidth: true,
              disabled: isUpdating,
              size: "small",
              onBlur: () => save("phone"),
            }}
          />
          <Input
            startDecorator="Address"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("address");
            }}
            fullWidth
            onBlur={() => {
              save("address");
              getVenueMapsInfo();
            }}
            className="inputBase"
            value={_venue.address || ""}
            onChange={(e) =>
              _setVenue((v) => {
                v.address = e.target.value;
                return { ...v };
              })
            }
          />
          <Input
            startDecorator="Zip"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("zipcode");
            }}
            fullWidth
            onBlur={() => {
              save("zipcode");
              getVenueMapsInfo();
            }}
            className="inputBase"
            value={_venue.zipcode || ""}
            onChange={(e) =>
              _setVenue((v) => {
                v.zipcode = e.target.value;
                return { ...v };
              })
            }
          />
          <Input
            startDecorator="City"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("city");
            }}
            fullWidth
            onBlur={() => {
              save("city");
              getVenueMapsInfo();
            }}
            className="inputBase"
            value={_venue.city || ""}
            onChange={(e) =>
              _setVenue((v) => {
                v.city = e.target.value;
                return { ...v };
              })
            }
          />
          <Input
            startDecorator="State"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("state");
            }}
            fullWidth
            onBlur={() => {
              save("state");
              getVenueMapsInfo();
            }}
            className="inputBase"
            value={_venue.state || ""}
            onChange={(e) =>
              _setVenue((v) => {
                v.state = e.target.value;
                return { ...v };
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%", p: 0, overflow: "hidden" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_MAPS_GEOCODING_KEY }}
              center={{
                lat: Number(venue.latitude),
                lng: Number(venue.longitude),
              }}
              defaultZoom={14}
            >
              <Place />
            </GoogleMapReact>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
