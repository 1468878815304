import { ProjectNotify_Entity } from "entities/projectNotify";
import { rhapsodyApi } from "redux/api";

export const projectNotifyEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectNotify: build.query<
      ProjectNotify_Entity,
      {
        projectID: number;
        body: Partial<ProjectNotify_Entity>;
      }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projects/${args.projectID}/mercury`,
      }),
    }),
    getTagNotify: build.query<
      ProjectNotify_Entity,
      {
        tagID: number;
        body: Partial<ProjectNotify_Entity>;
      }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `tags/${args.tagID}/mercury`,
      }),
    }),
  }),
});

export const {
  useGetProjectNotifyQuery,
  useLazyGetProjectNotifyQuery,
  useGetTagNotifyQuery,
  useLazyGetTagNotifyQuery,
} = projectNotifyEndpoints;

export default projectNotifyEndpoints;
