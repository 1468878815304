import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Payroll_Entity } from "entities/payroll";
import { rhapsodyApi } from "../api/rhapsodyApi";

const payrollsAdapter = createEntityAdapter<Payroll_Entity>();
const initialState = payrollsAdapter.getInitialState();

export const payrollEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getPayrolls: build.query<EntityState<Payroll_Entity>, void>({
      query: () => `payrolls`,
      transformResponse: (responseData: Payroll_Entity[]) => {
        return payrollsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["payrolls"],
    }),
    getPayroll: build.query<Payroll_Entity, number>({
      query: (id) => `payrolls/${id}`,
      providesTags: (result, error, id) => [{ type: "payrolls", id }],
    }),
    createPayroll: build.mutation<Payroll_Entity, Partial<Payroll_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `payrolls`,
      }),
      invalidatesTags: ["payrolls"],
    }),
    updatePayroll: build.mutation<
      void,
      { id: number; body: Partial<Payroll_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `payrolls/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "payrolls", id },
        "payrolls",
      ],
    }),
    deletePayroll: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `payrolls/${id}`,
      }),
      invalidatesTags: ["payrolls"],
    }),
  }),
});

export const {
  useGetPayrollQuery,
  useGetPayrollsQuery,
  useCreatePayrollMutation,
  useDeletePayrollMutation,
  useUpdatePayrollMutation,
} = payrollEndpoints;

export default payrollEndpoints;
