import { Subscription_Entity } from "entities/subscription";
import { rhapsodyApi } from "../api/rhapsodyApi";

export const subscriptionEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentSubscription: build.query<Subscription_Entity, void>({
      query: () => `subscriptions/current`,
      providesTags: ["subscriptions"],
    }),
    createSubscription: build.mutation<
      Subscription_Entity,
      Partial<Subscription_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `subscriptions`,
      }),
      invalidatesTags: ["subscriptions"],
    }),
  }),
});

export const { useGetCurrentSubscriptionQuery, useCreateSubscriptionMutation } =
  subscriptionEndpoints;

export default subscriptionEndpoints;
