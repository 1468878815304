import { useMemo } from "react";
import {
  useGetLibrarianPieceQuery,
  useGetLibrarianPiecesQuery,
} from "./librarianPieceEndpoints";
import { LibrarianPiece } from "entities/librarianPiece";

export function useLibrarianPiece(librarianPieceID: number | undefined) {
  const librarianPieceQuery = useGetLibrarianPieceQuery(librarianPieceID ?? 0, {
    skip: (librarianPieceID ?? 0) <= 0,
  });
  const librarianPieceEntity = librarianPieceQuery.data;

  return useMemo(() => {
    const ret: typeof librarianPieceQuery & {
      librarianPiece?: LibrarianPiece | undefined;
    } = {
      ...librarianPieceQuery,
    };
    if (librarianPieceEntity)
      ret.librarianPiece = new LibrarianPiece(librarianPieceEntity);
    return ret;
  }, [librarianPieceEntity]);
}

export function useLibrarianPieces(args, opt) {
  const librarianPieceQuery = useGetLibrarianPiecesQuery(args, opt);
  const librarianPieceEntitiesMap = librarianPieceQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof librarianPieceQuery & {
      librarianPieces?: LibrarianPiece[];
      librarianPiecesMap?: { [id: number]: LibrarianPiece };
    } = {
      ...librarianPieceQuery,
      librarianPieces: [],
      librarianPiecesMap: {},
    };
    if (librarianPieceEntitiesMap) {
      const librarianPieces: LibrarianPiece[] = [];
      const librarianPiecesMap: { [id: number]: LibrarianPiece } = {};

      for (const key in librarianPieceEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(librarianPieceEntitiesMap, key)
        ) {
          const c = librarianPieceEntitiesMap[key];
          const librarianPiece = new LibrarianPiece(c);
          librarianPieces.push(librarianPiece);
          librarianPiecesMap[librarianPiece.id] = librarianPiece;
        }
      }
      ret.librarianPieces = librarianPieces;
      ret.librarianPiecesMap = librarianPiecesMap;
    }

    return ret;
  }, [librarianPieceEntitiesMap]);
}
