import {
  Autocomplete,
  Icon,
  InputBase,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import { Musician_Entity } from "entities/musician";
import { useEffect, useState } from "react";
import { useMusicians } from "redux/musician/musicianHooks";

/**
 * @param {musicianAutocompleteProps} musicianAutocompleteProps PriorityLevel object from API
 * @returns {ReactElement} Musician Autocomplete
 */
export function MusicianAutocomplete({
  email,
  onChange,
  variant,
  size,
  helperText,
  disabled,
  error,
  filter,
  autoFocus,
  label,
}: musicianAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const { musicians } = useMusicians();

  const musician = email ? musicians.find((e) => e.email === email) : undefined;

  useEffect(() => {
    if (musician) {
      setInputValue(musician.fullName());
    }
  }, [musician]);

  return (
    <Autocomplete
      value={musician ?? null}
      onChange={(_e, newValue) => {
        onChange(newValue);
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      size={size}
      renderOption={(props, o) => (
        <MenuItem {...props}>
          <ListItemText primary={o.fullName()} secondary={o.email} />
        </MenuItem>
      )}
      sx={{ flex: 1 }}
      getOptionLabel={(option) => `${option.fullName()} (${option.email})`}
      options={filter ? musicians.filter(filter) : musicians}
      clearIcon={<Icon sx={{ fontSize: 15 }} className="fa-solid fa-xmark" />}
      renderInput={(params) =>
        variant === "inputBase" ? (
          <div ref={params.InputProps.ref}>
            <InputBase
              inputProps={{ ...params.inputProps }}
              fullWidth
              sx={{ fontSize: 14 }}
              placeholder="Select a Musician..."
              autoFocus={autoFocus}
            />
          </div>
        ) : (
          <TextField
            variant={variant}
            {...params}
            error={error}
            fullWidth
            sx={{ fontSize: 14 }}
            placeholder="Select a Musician..."
            autoFocus={autoFocus}
            label={label ?? "Musician"}
            helperText={helperText}
          />
        )
      }
    />
  );
}

type musicianAutocompleteProps = {
  email?: string;
  filter?: (s: Musician_Entity) => boolean;
  onChange: (musician: Musician_Entity | null) => void;
  variant?: "standard" | "filled" | "outlined" | "inputBase" | undefined;
  size?: "small" | "medium" | undefined;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  label?: string;
};
