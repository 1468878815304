import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { WorkSession_Entity } from "entities/workSession";
import { rhapsodyApi as _rhapsodyApi } from "../api/rhapsodyApi";

const workSessionsAdapter = createEntityAdapter<WorkSession_Entity>();
const initialState = workSessionsAdapter.getInitialState();

const rhapsodyApi = _rhapsodyApi.enhanceEndpoints({
  addTagTypes: ["workSessions"],
});

export const workSessionEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkSessions: build.query<
      EntityState<WorkSession_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `workSessions`,
          params: args,
        };
      },
      transformResponse: (responseData: WorkSession_Entity[]) => {
        return workSessionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["workSessions"],
    }),
    getActiveWorkSessions: build.query<EntityState<WorkSession_Entity>, void>({
      query: () => {
        return {
          url: `workSessions`,
          params: {
            filters: JSON.stringify([
              {
                name: "studio_sessions.archived",
                comparison: "eq",
                value: false,
              },
            ]),
          },
        };
      },
      transformResponse: (responseData: WorkSession_Entity[]) => {
        return workSessionsAdapter.setAll(initialState, responseData);
      },
      providesTags: [{ type: "workSessions", id: "active" }],
    }),
    getArchivedWorkSessions: build.query<EntityState<WorkSession_Entity>, void>(
      {
        query: () => {
          return {
            url: `workSessions`,
            params: {
              filters: JSON.stringify([
                {
                  name: "studio_sessions.archived",
                  comparison: "eq",
                  value: true,
                },
              ]),
            },
          };
        },
        transformResponse: (responseData: WorkSession_Entity[]) => {
          return workSessionsAdapter.setAll(initialState, responseData);
        },
        providesTags: [{ type: "workSessions", id: "archived" }],
      }
    ),
    getCustomerWorkSessions: build.query<
      EntityState<WorkSession_Entity>,
      number
    >({
      query: (customerID) => `customers/${customerID}/workSessions`,
      transformResponse: (responseData: WorkSession_Entity[]) => {
        return workSessionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["workSessions"],
    }),
    getPayrollWorkSessions: build.query<
      EntityState<WorkSession_Entity>,
      number
    >({
      query: (payrollID) => `payrolls/${payrollID}/workSessions`,
      transformResponse: (responseData: WorkSession_Entity[]) => {
        return workSessionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["workSessions"],
    }),
    getVenueWorkSessions: build.query<EntityState<WorkSession_Entity>, number>({
      query: (venueID) => `studios/${venueID}/workSessions`,
      transformResponse: (responseData: WorkSession_Entity[]) => {
        return workSessionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["workSessions"],
    }),
    getProjectWorkSessions: build.query<
      EntityState<WorkSession_Entity>,
      number
    >({
      query: (projectID) => `projects/${projectID}/workSessions`,
      transformResponse: (responseData: WorkSession_Entity[]) => {
        return workSessionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["workSessions"],
    }),
    getMusicianWorkSessions: build.query<
      EntityState<WorkSession_Entity>,
      number
    >({
      query: (musicianID) => `musicians/${musicianID}/workSessions`,
      transformResponse: (responseData: WorkSession_Entity[]) => {
        return workSessionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["workSessions"],
    }),
    getWorkSession: build.query<WorkSession_Entity, number>({
      query: (id) => `workSessions/${id}`,
      providesTags: (result, error, id) => [{ type: "workSessions", id }],
    }),
    duplicateWorkSession: build.mutation<WorkSession_Entity, number>({
      query: (id) => `workSessions/${id}/duplicate`,
      invalidatesTags: ["workSessions"],
    }),
    createWorkSession: build.mutation<WorkSession_Entity, any>({
      query: (body) => ({
        method: "POST",
        body,
        url: `workSessions`,
      }),
      invalidatesTags: [
        "workSessions",
        "projectHirings",
        "musiciansForProjectSection",
        "musiciansForWorkSessionSection",
        "workSessionProjectPieces",
        "chairs",
        "assignments",
      ],
    }),
    createWorkSession2: build.mutation<WorkSession_Entity, any>({
      query: (body) => ({
        method: "POST",
        body,
        url: `workSessions`,
      }),
      invalidatesTags: [],
    }),
    updateWorkSession: build.mutation<
      void,
      { id: number; body: Partial<WorkSession_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `workSessions/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "workSessions", id },
        "workSessions",
        "assignments",
      ],
    }),
    calculatePensionWelfare: build.mutation<
      void,
      { id: number; body: Partial<WorkSession_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `workSessions/${args.id}/calculatePensionWelfare`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "workSessions", id },
        "workSessions",
      ],
    }),
    deleteWorkSession: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `workSessions/${id}`,
      }),
      invalidatesTags: ["workSessions", "assignments"],
    }),
  }),
});

export const {
  useGetWorkSessionsQuery,
  useCreateWorkSession2Mutation,
  useGetWorkSessionQuery,
  useGetCustomerWorkSessionsQuery,
  useGetProjectWorkSessionsQuery,
  useGetVenueWorkSessionsQuery,
  useGetPayrollWorkSessionsQuery,
  useDuplicateWorkSessionMutation,
  useGetMusicianWorkSessionsQuery,
  useGetActiveWorkSessionsQuery,
  useCalculatePensionWelfareMutation,
  useGetArchivedWorkSessionsQuery,
  useCreateWorkSessionMutation,
  useDeleteWorkSessionMutation,
  useUpdateWorkSessionMutation,
} = workSessionEndpoints;

export default workSessionEndpoints;

export const selectWorkSessionsResult =
  workSessionEndpoints.endpoints.getActiveWorkSessions.select();

const selectWorkSessionsData = createSelector(
  selectWorkSessionsResult,
  (workSessionsResult) => workSessionsResult.data
);

const store = configureStore({
  reducer: {
    [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const {
  selectAll: selectAllWorkSessions,
  selectById: selectWorkSessionById,
} = workSessionsAdapter.getSelectors<RootState>(
  (state) => selectWorkSessionsData(state) ?? initialState
);
