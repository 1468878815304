// import PropTypes from 'prop-types';
// material-ui
import {
  Button,
  Chip,
  Switch,
  Tab,
  TabList,
  Tabs,
  Tooltip,
  Typography as JoyTypography,
  CircularProgress,
} from "@mui/joy";
import { Box, Grid, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { HelpCard } from "hooks/helpCard/helpCard";
import { useDispatch, useSelector } from "react-redux";
import {
  projectHiringDisplaySelector,
  projectHiringGroupedSelector,
  projectHiring_UnselectAll_WorkSessions,
  setProjectHiringGrouped,
  setProjectHiringSelection,
} from "reducers/projectHiring";
import {
  useProjectHiringDataGrid,
  useProjectHiringSelectAll,
  useProjectHiringUnselectAll,
} from "./useProjectHiringDataGrid";
import ProjectHiringGroupActions from "../ProjectHiringGroupActions/ProjectHiringGroupActions";
import { selectedProjectIDSelector, setFormOpen } from "reducers/rhapsody";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";
import { ChairMercuryHistory } from "features/chair/chairMercuryHistory";
import { useState } from "react";
import { Job } from "entities/job";

export default function ProjectHiring() {
  const display = useSelector(projectHiringDisplaySelector);
  const { rows, columns } = useProjectHiringDataGrid();
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectHiring, isLoading } = useProjectHiring(projectID);
  const { isLoading: l2 } = useProjectHiring(projectID);
  const dispatch = useDispatch();
  const [mercuryHistoryOpen, setMercuryHistoryOpen] = useState(false);
  const [job, setJob] = useState<Job>();

  if (isLoading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
        <CircularProgress />
      </Box>
    );

  if (
    (rows.length === 0 || columns.length === 0) &&
    projectHiring?.jobs?.length === 0
  ) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "50vh", textAlign: "center" }}
      >
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            {" "}
            <i style={{ fontSize: 70 }} className="fa-solid fa-paper-plane"></i>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            Hiring
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Track the hiring status of your musicians across all Work Sessions
            and easily
            <br />
            communicate with them by selecting a specific musician and work
            session combination.
          </Typography>
          <br />
          <Button
            startDecorator={<i className="fa-solid fa-plus"></i>}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "workSession" }))
            }
            sx={{ mt: 1 }}
          >
            Add Work Session
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
      <HelpCard title="Ready to send the call?">
        <Typography>
          Build your call using the checkboxes. Select the musicians and the
          work sessions.
          <br />
          Click on the{" "}
          <Chip
            endDecorator={<i className="fa-solid fa-paper-plane"></i>}
            size="sm"
          >
            Notify
          </Chip>{" "}
          button from the top App Bar whenever you are ready.
        </Typography>
      </HelpCard>
      <DataGridPro
        components={{
          Toolbar: CustomToolbar,
        }}
        className="DatGridBottomPadding"
        key={display}
        sx={{ height: "calc(100vh - 165px)" }}
        columns={columns}
        disableVirtualization
        onCellClick={(r) => {
          if (r.value && r.value?.length) {
            setMercuryHistoryOpen(true);
            setJob(r.value[0]);
          }
        }}
        disableSelectionOnClick
        getRowHeight={(p) => {
          if (`${p.id}`[0] === "s") {
            return 36;
          }
          return 46;
        }}
        getCellClassName={(r) => {
          if (r.id[0] === "s") return "sectionRow";
        }}
        density="compact"
        rows={rows}
        initialState={{
          pinnedColumns: {
            left: ["musicianID"],
          },
          sorting:
            display === "alphabetical"
              ? {
                  sortModel: [{ field: "musician", sort: "asc" }],
                }
              : undefined,
        }}
      />
      {job ? (
        <ChairMercuryHistory
          job={job}
          open={mercuryHistoryOpen}
          onClose={() => {
            setMercuryHistoryOpen(false);
            setJob(null);
          }}
        />
      ) : (
        []
      )}
    </Box>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const display = useSelector(projectHiringDisplaySelector);
  const selectAll = useProjectHiringSelectAll();
  const unselectAll = useProjectHiringUnselectAll();
  const grouped = useSelector(projectHiringGroupedSelector);
  return (
    <>
      <Box
        className="header"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Tabs
            sx={{ borderRadius: 3 }}
            onChange={(e, v) => {
              switch (v) {
                case "sections":
                  dispatch(setProjectHiringSelection("sections"));
                  break;
                case "alphabetical":
                  dispatch(setProjectHiringSelection("alphabetical"));
                  break;
              }
            }}
            size="sm"
            variant="soft"
            value={display}
          >
            <TabList variant="outlined" color="neutral">
              <Tab value={"sections"}>Sections</Tab>
              <Tab value={"alphabetical"}>Alphabetical</Tab>
            </TabList>
          </Tabs>
          <JoyTypography
            level="body2"
            endDecorator={
              <Tooltip
                size="sm"
                arrow
                title="Group the Work Sessions with a similar instrumentation into a single column."
              >
                <i className="fa-sharp fa-solid fa-circle-question"></i>
              </Tooltip>
            }
            startDecorator={
              <Switch
                checked={grouped}
                onChange={(e) => {
                  dispatch(setProjectHiringGrouped(e.target.checked));
                  dispatch(projectHiring_UnselectAll_WorkSessions());
                  window.localStorage.setItem(
                    "projectHiringGrouped",
                    e.target.checked ? "true" : "false"
                  );
                }}
                size="sm"
                sx={{ ml: 1 }}
              />
            }
          >
            Group
          </JoyTypography>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            onClick={() => unselectAll()}
            variant="soft"
            color="neutral"
            size="sm"
          >
            Unselect All
          </Button>
          <Button
            onClick={() => selectAll()}
            variant="soft"
            color="neutral"
            size="sm"
          >
            Select All
          </Button>
        </Box>
      </Box>
      <ProjectHiringGroupActions />
    </>
  );
}
