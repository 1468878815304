import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { ProjectAlternate_Entity } from "entities/projectAlternate";
import { ProjectSection_Entity } from "entities/projectSection";
import { rhapsodyApi } from "../api/rhapsodyApi";

const projectAlternatesAdapter = createEntityAdapter<ProjectAlternate_Entity>();
const initialState = projectAlternatesAdapter.getInitialState();

export const projectAlternateEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectAlternates: build.query<
      EntityState<ProjectAlternate_Entity>,
      number
    >({
      query: (projectID) => `projects/${projectID}/sections`,
      transformResponse: (responseData: ProjectSection_Entity[]) => {
        const projectAlternates: ProjectAlternate_Entity[] = [];
        responseData.forEach((s) =>
          projectAlternates.push(...s.projectMusicians)
        );

        return projectAlternatesAdapter.setAll(initialState, projectAlternates);
      },
      providesTags: (result, error, id) => [{ type: "projectAlternates", id }],
    }),
    getProjectAlternate: build.query<ProjectAlternate_Entity, number>({
      query: (id) => `projectAlternates/${id}`,
      providesTags: (result, error, id) => [{ type: "projectAlternates", id }],
    }),
    createProjectAlternate: build.mutation<
      ProjectAlternate_Entity,
      {
        projectID: number;
        body:
          | Partial<ProjectAlternate_Entity>
          | Partial<ProjectAlternate_Entity>[];
      }
    >({
      query: (args) => ({
        method: "POST",
        body: args.body,
        url: `projectAlternates`,
      }),
      invalidatesTags: (result, error, args) => [
        "projectSections",
        { type: "projectAlternates", id: args.projectID },
      ],
    }),
    updateBatchProjectAlternate: build.mutation<
      ProjectAlternate_Entity,
      Partial<ProjectAlternate_Entity>[]
    >({
      query: (body) => ({
        method: "PUT",
        body: body,
        url: `projectAlternates/batch`,
      }),
      invalidatesTags: [
        "projectHirings",
        "projectSections",
        "projectAlternates",
      ],
    }),
    updateProjectAlternate: build.mutation<
      void,
      { id: number; body: Partial<ProjectAlternate_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projectAlternates/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "projectAlternates", id },
        "projectAlternates",
        "projectSections",
      ],
    }),
    deleteProjectAlternate: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projectAlternates/${id}`,
      }),
      invalidatesTags: ["projectAlternates", "projectSections"],
    }),
  }),
});

export const {
  useGetProjectAlternateQuery,
  useGetProjectAlternatesQuery,
  useLazyGetProjectAlternatesQuery,
  useUpdateBatchProjectAlternateMutation,
  useCreateProjectAlternateMutation,
  useDeleteProjectAlternateMutation,
  useUpdateProjectAlternateMutation,
} = projectAlternateEndpoints;

export default projectAlternateEndpoints;
