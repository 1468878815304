import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 48px)",
        background: "#b7d5ff",
      }}
    >
      <Box>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          We want to believe
        </Typography>
        <Typography>The requested page has not been found</Typography>
        <Button onClick={() => navigate(-1)} sx={{ mt: 1 }} variant="contained">
          Go back
        </Button>
      </Box>
      <img
        style={{ height: 400 }}
        src="https://storage.googleapis.com/rhapsody/media/ufo.png"
      />
    </Box>
  );
}
