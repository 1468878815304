import { Event } from "entities/event";
import { useMemo } from "react";
import { useGetProjectEventsQuery } from "./eventEndpoints";

export function useProjectEvents(projectID: number) {
  const eventQuery = useGetProjectEventsQuery(projectID);
  const eventEntitiesMap = eventQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof eventQuery & {
      events?: Event[];
      eventsMap?: { [id: number]: Event };
    } = {
      ...eventQuery,
      events: [],
      eventsMap: {},
    };
    if (eventEntitiesMap) {
      const events = [];
      const eventsMap = {};

      for (const key in eventEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(eventEntitiesMap, key)) {
          const c = eventEntitiesMap[key];
          const event = new Event(c);
          events.push(event);
          eventsMap[event.id] = event;
        }
      }
      ret.events = events;
      ret.eventsMap = eventsMap;
    }

    return ret;
  }, [eventEntitiesMap]);
}
