import { Dictionary } from "@reduxjs/toolkit";
import Job_Entity from "./job";
import { Chair } from "entities/chair";
export default class Job extends Job_Entity implements Chair {
  static fromList(jobsJSON: unknown): Array<Job> {
    const jobs: Job[] = [];
    if (jobsJSON)
      Array.isArray(jobsJSON) &&
        jobsJSON.forEach((jobJSON) => {
          jobs.push(new Job(jobJSON));
        });
    return jobs;
  }

  static fromMap(json: unknown): Dictionary<Job> {
    const list = Job.fromList(json);
    const map: Dictionary<Job> = {};

    list.forEach((item) => (map[item.id] = item));

    return map;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  order(): number {
    return (this.sectionOrder ?? 0) + 1;
  }
}
