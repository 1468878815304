import { ProjectHiring } from "entities/projectHiring";
import { useMemo } from "react";
import { useGetProjectHiringQuery } from "./projectHiringEndpoints";
import { getLayoutHash } from "helpers/section/section";

export function useProjectHiring(
  projectID: number | undefined,
  opt?: { skip?: boolean }
) {
  const projectHiringQuery = useGetProjectHiringQuery(projectID ?? 0, {
    skip: (projectID ?? 0) <= 0 || opt?.skip,
  });
  const projectHiringEntity = projectHiringQuery.data;

  return useMemo(() => {
    const ret: typeof projectHiringQuery & {
      projectHiring?: ProjectHiring | undefined;
    } = {
      ...projectHiringQuery,
    };
    if (projectHiringEntity) {
      const projectHiring = new ProjectHiring(projectHiringEntity);
      projectHiring.sessions.forEach((s) => {
        const layoutHash = getLayoutHash(
          projectHiring.jobs.filter((j) => j.sessionID === s.id)
        );
        s.layoutHash = layoutHash;
      });
      ret.projectHiring = projectHiring;
    }

    return ret;
  }, [projectHiringEntity]);
}
