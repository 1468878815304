// import PropTypes from 'prop-types';

// styles
import { Button, Sheet, Typography } from "@mui/joy";
import { Box } from "@mui/system";
import { PieChart } from "@mui/x-charts";
import { Dictionary } from "@reduxjs/toolkit";
import { typeOptions } from "constants/workSessionTypeOptions";
import { Assignment } from "entities/assignment";
import { Project } from "entities/project";
import { Stage } from "entities/stage";
import { Messages } from "features/dataGrids/ProjectsDataGrid/ProjectsDataGrid";

import { RouterConfig } from "hooks/AppRouter/AppRouter";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAssignments } from "redux/assignment/assignmentHooks";
import { useProjectMercury } from "redux/projectMercury/projectMercuryHooks";
import { useStages } from "redux/stage/stageHooks";
import { useProjectWorkSessions } from "redux/workSession/workSessionHooks";

/**
 *
 * @returns {ReactElement} ProjectHiring page
 */
export function ProjectHiring({ p }: { p: Project }) {
  const { stagesMap } = useStages();
  const { assignments } = useAssignments(p.version === 2 ? p.id : undefined);
  const { projectMercury } = useProjectMercury(p.id, {
    skip: p.version === 2,
  });
  const { projectWorkSessions } = useProjectWorkSessions(p.id);
  const navigate = useNavigate();

  const studioNames = projectWorkSessions.reduce((a, v) => {
    if (!a.includes(v.studioName)) {
      a.push(v.studioName);
    }
    return a;
  }, []);

  const workSessionTypes = projectWorkSessions.reduce((a, v) => {
    const type = typeOptions.find((t) => t.sessionTypeID === v.sessionTypeID);
    if (type) {
      if (a[type.title]) {
        a[type.title]++;
      } else {
        a[type.title] = 1;
      }
    }
    return a;
  }, {});

  const description = Object.keys(workSessionTypes)
    .reduce((a, v) => {
      a.push(
        `${workSessionTypes[v]} ${v}${workSessionTypes[v] > 1 ? "s" : ""}`
      );
      return a;
    }, [])
    .join(", ");

  let dates = projectWorkSessions.reduce((a, v) => {
    a.push(moment(v.dateFromUTC));
    return a;
  }, []);

  dates = dates.sort((a, b) => a.valueOf() - b.valueOf());
  const from = dates.length ? dates[0].format("ll") : undefined;
  const to = dates.length ? dates[dates.length - 1].format("ll") : undefined;

  const pieChartDatav2 =
    p.version === 2 ? getPieChartData(assignments, stagesMap) : undefined;

  const pieChartDatav1 = projectMercury?.pieChartData(stagesMap);
  const labelsv1 = projectMercury?.countPerStatusLabels(stagesMap);

  const labels = p.version === 2 ? pieChartDatav2.labels : labelsv1;

  const pieChartData = p.version === 2 ? pieChartDatav2 : pieChartDatav1;

  return (
    <Sheet
      variant="soft"
      sx={{
        borderRadius: "16px",
        height: "100%",
        p: 2,
        gap: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            level="body1"
            sx={{
              fontWeight: 600,
            }}
          >
            {p.name}
          </Typography>
          <Typography
            startDecorator={<i className="fa-solid fa-folder-open"></i>}
            level="body3"
          >
            {description}
          </Typography>
          <Typography
            startDecorator={<i className="fa-solid fa-location-dot"></i>}
            level="body3"
          >
            {studioNames.join(", ")}
          </Typography>
          <Typography
            startDecorator={<i className="fa-solid fa-calendar-days"></i>}
            level="body3"
          >
            {`${from} - ${to}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            justifyContent: "end",
          }}
        >
          <Button
            color="neutral"
            variant="outlined"
            size="sm"
            sx={{ background: "white" }}
            endDecorator={<i className="fa-solid fa-chevron-right"></i>}
            onClick={() => {
              if (p.version === 2) {
                navigate(`${RouterConfig.projects}/${p.id}/mission-control`);
              } else {
                navigate(`${RouterConfig.projects}/${p.id}/hiring`);
              }
            }}
          >
            Open
          </Button>
          <Messages projectID={p.id} stagesMap={stagesMap} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
        {pieChartData ? (
          <Box sx={{ flex: 1 }}>
            <PieChart
              colors={pieChartData.palette}
              series={[
                {
                  innerRadius: 65,
                  outerRadius: 80,
                  paddingAngle: 5,
                  data: pieChartData.data,
                  cornerRadius: 5,
                },
              ]}
              sx={{
                background: "white",
                border: "solid 2px rgba(155, 155,155,0.2)",
                borderRadius: 80,
                position: "absolute",
                top: 0,
                p: 1,
              }}
              margin={{ right: 5 }}
              width={160}
              height={160}
              legend={{ hidden: true }}
            />
          </Box>
        ) : (
          []
        )}
        <Box sx={{ flex: 1 }}>
          {labels?.map((l) => (
            <Typography
              startDecorator={<img src={l.icon} height="12px" />}
              level="body3"
              key={l.label}
            >
              {l.count} {l.label}
            </Typography>
          ))}
        </Box>
      </Box>
    </Sheet>
  );
}

export function getPieChartData(
  assignments: Assignment[],
  stagesMap: Dictionary<Stage>
) {
  const data: { label: string; value: number }[] = [];
  const palette: string[] = [];
  const labels: {
    label: string;
    icon: string;
    color: string;
    count: number;
  }[] = [];

  const activeAssignments = assignments.filter((a) => a.active ?? true);
  const countPerStatus = activeAssignments.reduce<Dictionary<number>>(
    (a, v) => {
      if (!v.mercuryStageID) {
        if (a[-1]) {
          a[-1]++;
        } else {
          a[-1] = 1;
        }
      } else if (a[v.mercuryStageID]) {
        a[v.mercuryStageID]++;
      } else {
        a[v.mercuryStageID] = 1;
      }
      return a;
    },
    {}
  );

  console.log(activeAssignments);

  for (const key in countPerStatus) {
    if (Object.hasOwnProperty.call(countPerStatus, key)) {
      const stage = stagesMap[key];
      data.push({
        label: stage?.wording() ?? "Ready",
        value: countPerStatus[key],
      });
      palette.push(stage?.color ?? "#9e9e9e");
      labels.push({
        label: stagesMap[key]?.wording() ?? "Ready",
        icon:
          stagesMap[key]?.icon ??
          "https://storage.googleapis.com/rhapsody/logos/ready.png",
        color: stagesMap[key]?.color ?? "#9e9e9e",
        count: countPerStatus[key],
      });
    }
  }

  return { data, palette, countPerStatus, labels };
}
