import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Input,
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Box, DialogActions, DialogTitle } from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { Dictionary } from "@reduxjs/toolkit";
import { Piece } from "entities/piece";
import { ProjectPiece_Entity } from "entities/projectPiece";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { useMissionControlSelectAll } from "features/projects/ProjectMissionControl/utils";
import { InstrumentationRaw } from "hooks/Instrumentation/raw";
import SlidingDialog from "hooks/SlidingDialog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  setFormOpen,
  setTmpPieceName,
} from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useLibrarianPieces } from "redux/librarianPiece/librarianPieceHooks";
import { useCreatePieceMutation } from "redux/piece/pieceEndpoints";
import { usePieces } from "redux/piece/pieceHooks";
import {
  useCreateProjectPieceMutation,
  useLazyGetProjectPiecesQuery,
  useUpdateProjectPieceMutation,
} from "redux/projectPiece/projectPieceEndpoints";
import { useCreateChairsMutation } from "redux/rhapsodyChair/rhapsodyChairEndpoints";
import {
  useCreateWorkSessionProjectPieceMutation,
  useDeleteWorkSessionProjectPieceMutation,
} from "redux/workSessionProjectPiece/workSessionProjectPieceEndpoints";

export type ProjectPieceAdderItem = {
  pieceID: number;
  pieceName: string;
  pieceComposer: string;
  shorthand: string;
  instrumentation: Dictionary<Partial<RhapsodyChair_Entity>[]>;
  workSessionIDs: number[];
};

/**
 *
 * @returns {ReactElement} FormProjectPiece page
 */
export function FormProjectPiece2() {
  const open = useSelector(formOpenSelector("projectPiece2"));
  const dispatch = useDispatch();
  const [projectPieces, setProjectPieces] = useState<
    Partial<ProjectPieceAdderItem>[]
  >([]);
  const utils = useSelector(layoutUtilsSelector);
  const [deleteWorkSessionProjectPiece] =
    useDeleteWorkSessionProjectPieceMutation();
  const [updateProjectPiece] = useUpdateProjectPieceMutation();
  const [createProjectPiece] = useCreateProjectPieceMutation();
  const [getProjectPieces] = useLazyGetProjectPiecesQuery();
  const [createChairs] = useCreateChairsMutation();
  const [createWorkSessionProjectPiece] =
    useCreateWorkSessionProjectPieceMutation();
  const { selectAll } = useMissionControlSelectAll();
  const workSessionIDs = utils?.workSessions?.reduce<number[]>((a, v) => {
    a.push(v.id);
    return a;
  }, []);
  useEffect(() => {
    selectAll();
  }, [utils]);

  const [loading, setLoading] = useState(false);

  const pieceIDs = projectPieces.reduce((a, v) => {
    a.push(v.pieceID);
    return a;
  }, []);

  const { piecesMap } = usePieces(
    {
      filters: JSON.stringify([
        {
          name: "pieces.id",
          value: pieceIDs,
          comparison: "in",
        },
      ]),
    },
    { skip: pieceIDs?.length === 0 }
  );

  const Dialog: any = SlidingDialog;

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectPiece2" }));
  };

  const processRowUpdate = async (_new: Partial<ProjectPiece_Entity>) => {
    const index = projectPieces?.findIndex((p) => p.pieceID === _new.pieceID);
    const _projectPieces = JSON.parse(JSON.stringify(projectPieces));
    _projectPieces[index] = _new;
    setProjectPieces(_projectPieces);

    return _new;
  };

  const handleRowOrderChange = async (params) => {
    const { oldIndex, targetIndex } = params;
    const _projectPieces = JSON.parse(JSON.stringify(projectPieces));
    const row = _projectPieces.splice(oldIndex, 1)[0];
    _projectPieces.splice(targetIndex, 0, row);

    setProjectPieces(_projectPieces);
  };

  const create = async () => {
    if (projectPieces.length === 0) return;
    setLoading(true);
    try {
      let defaultPieceReassigned = false;

      // if the project has a defaut piece WITH NO CHAIR, we reassign it.
      console.log("-", utils.projectPieces[0], utils.chairs);
      if (!utils.projectPieces[0].pieceID && utils.chairs.length === 0) {
        console.log("- Reassigning default piece");
        const defaultProjectPiece = utils.projectPieces[0];
        await updateProjectPiece({
          id: defaultProjectPiece.id,
          body: {
            ...defaultProjectPiece,
            pieceID: projectPieces[0].pieceID,
            description: projectPieces[0].shorthand,
          },
        });

        // deleting default WorkSessionProjectPiece
        utils.workSessionProjectPieces.forEach((wspp) =>
          deleteWorkSessionProjectPiece(wspp.id)
        );
        defaultPieceReassigned = true;
      }

      // create the Project Pieces
      for (const k in projectPieces) {
        if (Object.prototype.hasOwnProperty.call(projectPieces, k)) {
          const projectPiece = projectPieces[k];

          // if we reassigned the default piece, no need to create the first one;
          if (defaultPieceReassigned && k === "0") continue;

          await createProjectPiece({
            projectID: utils.project.id,
            pieceID: projectPiece.pieceID,
            description: projectPiece.shorthand,
          });
        }
      }

      const newProjectPieces = await getProjectPieces({
        filters: JSON.stringify([
          {
            value: utils.project.id,
            comparison: "eq",
            name: "project_pieces.projectID",
          },
        ]),
      }).unwrap();

      console.log("- New Project Pieces", newProjectPieces);

      // let's create the chairs
      for (const k in newProjectPieces.ids) {
        if (Object.prototype.hasOwnProperty.call(newProjectPieces.ids, k)) {
          const id = newProjectPieces.ids[k];
          const newProjectPiece = newProjectPieces.entities[id];
          const projectPiece = projectPieces.find(
            (p) => p.pieceID === newProjectPiece.pieceID
          );

          if (projectPiece) {
            const total = [];
            for (const key in projectPiece.instrumentation) {
              if (
                Object.hasOwnProperty.call(projectPiece.instrumentation, key)
              ) {
                total.push(...projectPiece.instrumentation[key]);
              }
            }

            const body = [];
            for (const j in total) {
              if (Object.prototype.hasOwnProperty.call(total, j)) {
                const chair = total[j];
                body.push({
                  ...chair,
                  projectPieceID: newProjectPiece.id,
                  chairCount: 1,
                });
              }
            }
            console.log("- Create Chair", newProjectPiece, projectPiece, body);
            if (body.length) await createChairs(body);
          }
        }
      }

      // Finally let's link the Project Pieces to Work Sessions
      for (const k in newProjectPieces.ids) {
        if (Object.prototype.hasOwnProperty.call(newProjectPieces.ids, k)) {
          const id = newProjectPieces.ids[k];
          const newProjectPiece = newProjectPieces.entities[id];
          const projectPiece = projectPieces.find(
            (p) => p.pieceID === newProjectPiece.pieceID
          );

          if (projectPiece) {
            for (const j in projectPiece.workSessionIDs) {
              if (
                Object.prototype.hasOwnProperty.call(
                  projectPiece.workSessionIDs,
                  j
                )
              ) {
                const workSessionID = projectPiece.workSessionIDs[j];
                await createWorkSessionProjectPiece({
                  projectID: utils.project.id,
                  workSessionID,
                  projectPieceID: newProjectPiece.id,
                });
                console.log("- createWorkSessionProjectPiece");
              }
            }
          }
        }
      }
      setProjectPieces([]);
      setTimeout(() => {
        setLoading(false);
        dispatch(setFormOpen({ isOpen: false, formID: "projectPiece2" }));
      }, 1000);
    } catch (error) {
      console.log(error);
      debugger;
      setLoading(false);
    }
  };

  const columns: GridColDef<any>[] = [
    {
      field: "pieceID",
      width: 200,
      editable: false,
      headerName: "Piece",
      renderCell: (e) => (
        <Box>
          <Typography sx={{ fontWeight: 600 }} level="body2">
            {piecesMap[e.row.pieceID]?.name}
          </Typography>
          <Typography sx={{ opacity: 0.6 }} level="body4">
            {piecesMap[e.row.pieceID]?.composer}
          </Typography>
        </Box>
      ),
      valueGetter: (e) =>
        `${piecesMap[e.row.pieceID]?.name} ${
          piecesMap[e.row.pieceID]?.composer
        }`,
    },
    {
      field: "shorthand",
      headerName: "Instr. Shorthand",
      renderCell: (e) => (
        <Typography level="body2" sx={{ opacity: e.value ? 1 : 0.3 }}>
          {e.value
            ? e.value
            : "Ex: 4(3pic)3(1ca)3(1bcl)+bcl3+cbn / 4432 / timp.4perc / 2hp.pf.cel / str"}
        </Typography>
      ),
      type: "string",
      flex: 1,
      editable: true,
    },
    {
      field: "instrumentation",
      headerName: "Instrumentation",
      type: "string",
      width: 120,
      renderCell: (e) => {
        return (
          <InstrumentationRaw
            instrumentation={e.row.instrumentation ?? {}}
            daniels={e.row.daniels}
            pieceName={`${piecesMap[e.row.pieceID]?.name ?? ""}`}
            pieceComposer={`${piecesMap[e.row.pieceID]?.composer ?? ""}`}
            sameAs={projectPieces.filter((pp) => pp.pieceID !== e.row.pieceID)}
            onChange={(i, d) => {
              const _projectPieces = JSON.parse(JSON.stringify(projectPieces));
              const index = _projectPieces.findIndex(
                (p) => p.pieceID === e.row.pieceID
              );
              _projectPieces[index].instrumentation = i;
              _projectPieces[index].shorthand = d?.formula;
              if (d) _projectPieces[index].daniels = d;
              if (d === null) _projectPieces[index].daniels = undefined;
              setProjectPieces(_projectPieces);
            }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 30,
      getActions: (p) => [
        <GridActionsCellItem
          key="Remove"
          showInMenu
          icon={<i className="fa-solid fa-trash"></i>}
          label={`Remove`}
          color="inherit"
          onClick={() => {
            setProjectPieces((pp) =>
              pp.filter((e) => e.pieceID !== p.row.pieceID)
            );
          }}
        />,
      ],
    },
  ];

  if (utils.workSessions.length) {
    columns.splice(4, 0, {
      field: "workSessionIDs",
      headerName: "Work Sessions",
      aggregable: false,
      type: "string",
      editable: false,
      width: 40 + utils.workSessions.length * 40,
      renderCell: (e) => (
        <WorkSessionsCell
          id={e.id}
          processRowUpdate={processRowUpdate}
          workSessionIDs={e.row.workSessionIDs ?? []}
        />
      ),
    });
  }

  return (
    <Dialog open={open} fullWidth fullScreen maxWidth={"lg"} onClose={onClose}>
      {loading ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography startDecorator={<CircularProgress />}>
            Creating Project Pieces
          </Typography>
        </Box>
      ) : (
        <>
          <DialogTitle sx={{ p: 0, mb: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pr: 2,
                pt: 2,
                pl: 4,
                alignItems: "center",
              }}
            >
              <Box>
                <Typography level="h5" sx={{ fontWeight: 600 }}>
                  Add Project Pieces
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <Divider />
          <Box
            sx={{
              p: 0,
              display: "flex",
              gap: 1,
              height: "calc(100vh - 180px)",
            }}
          >
            <Sheet variant="soft">
              <PieceSelect
                onSelect={(e) =>
                  setProjectPieces((p) => [
                    ...p,
                    {
                      pieceID: e.id,
                      workSessionIDs,
                      pieceName: e.name,
                      pieceComposer: e.composer,
                    },
                  ])
                }
                pieceIDs={projectPieces.reduce((a, v) => {
                  a.push(v.pieceID);
                  return a;
                }, [])}
              />
            </Sheet>
            {projectPieces.length === 0 ? (
              <Box sx={{ mt: 2, ml: 2 }}>
                <Typography
                  startDecorator={
                    <i
                      style={{ transform: "scaleX(-1)" }}
                      className="fa-solid fa-turn-down-right"
                    ></i>
                  }
                  level="h6"
                >
                  Which Pieces are you playing in this Project?
                </Typography>
                <Typography level="body2">
                  Find or create new Pieces.
                </Typography>
              </Box>
            ) : (
              <Box sx={{ p: 1, flex: 1 }}>
                <DataGridPremium
                  density="compact"
                  onProcessRowUpdateError={(e) => console.log(e)}
                  autoHeight
                  hideFooter
                  rowReordering
                  onRowOrderChange={handleRowOrderChange}
                  rowSelection={false}
                  unstable_cellSelection
                  rows={projectPieces}
                  getRowId={(e) => e.pieceID}
                  processRowUpdate={processRowUpdate}
                  experimentalFeatures={{ clipboardPaste: true }}
                  unstable_ignoreValueFormatterDuringExport
                  columns={columns}
                  initialState={{
                    pinnedColumns: { right: ["instrumentation", "actions"] },
                    columns: {
                      columnVisibilityModel: { workSessionIDs: false },
                    },
                  }}
                />
              </Box>
            )}
          </Box>
          <Divider />
          <DialogActions>
            <Button variant="soft" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={create} disabled={projectPieces.length === 0}>
              Create
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

function PieceSelect({
  onSelect,
  pieceIDs,
}: {
  onSelect: (e: Piece) => void;
  pieceIDs: number[];
}) {
  const { projectPieces } = useSelector(layoutUtilsSelector);
  const [title, setTitle] = useState("");
  const [composer, setComposer] = useState("");
  const dispatch = useDispatch();
  const [selectAfterCreate, setSelectAfterCreate] = useState(false);
  const [createPiece] = useCreatePieceMutation();
  const utils = useSelector(layoutUtilsSelector);

  const genFilters = (name) => {
    const filters = [];

    if (composer) {
      filters.push({
        name: "pieces.composer",
        comparison: "like",
        value: composer.toLocaleLowerCase(),
      });
    }
    if (title) {
      filters.push({
        name,
        comparison: "like",
        value: title.toLocaleLowerCase(),
      });
    }

    return filters;
  };

  const { pieces } = usePieces({
    filters: JSON.stringify(genFilters("pieces.name")),
  });
  const { librarianPieces } = useLibrarianPieces(
    {
      filters: JSON.stringify(genFilters("pieces.title")),
      limit: 30,
    },
    { skip: `${title}${composer}`.length < 3 }
  );

  useEffect(() => {
    if (selectAfterCreate && pieces.length) {
      const piece = pieces.reduce<Piece>((a, v) => {
        if (!a) {
          a = v;
        } else if (a.id < v.id) {
          a = v;
        }
        return a;
      }, undefined);
      onSelect(piece);
      setSelectAfterCreate(false);
    }
  }, [pieces]);

  const list = pieces
    .filter((p) => !pieceIDs.includes(p.id))
    .filter((p) => !utils.piecesMap[p.id])
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box
      sx={{
        width: 280,
        display: "flex",
        height: "100%",
        p: 1,
        gap: 0.5,
        flexDirection: "column",
      }}
    >
      <Box sx={{ justifyContent: "end", display: "flex" }}>
        <Button
          variant="outlined"
          color="neutral"
          size="sm"
          sx={{ background: "white" }}
          onClick={() => {
            setSelectAfterCreate(true);
            setTitle("");
            setComposer("");
            dispatch(setFormOpen({ isOpen: true, formID: "piece" }));
          }}
        >
          New Piece
        </Button>
      </Box>
      <Sheet sx={{ borderRadius: "8px" }}>
        <Input
          value={title}
          autoFocus
          sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          onChange={(e) => setTitle(e.target.value)}
          endDecorator={
            title ? (
              <IconButton
                color="neutral"
                sx={{ opacity: 0.3 }}
                variant="plain"
                onClick={(e) => setTitle("")}
              >
                <i className="fa-solid fa-xmark"></i>
              </IconButton>
            ) : undefined
          }
          size="sm"
          variant="plain"
          placeholder="Title"
        />
        <Divider />
        <Input
          value={composer}
          sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          onChange={(e) => setComposer(e.target.value)}
          endDecorator={
            composer ? (
              <IconButton
                color="neutral"
                variant="plain"
                sx={{ opacity: 0.3 }}
                onClick={(e) => setComposer("")}
              >
                <i className="fa-solid fa-xmark"></i>
              </IconButton>
            ) : undefined
          }
          size="sm"
          variant="plain"
          placeholder="Composer"
        />
      </Sheet>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          minHeight: "auto",
          height: 0,
          display: "flex",
          alignItems: "stretch",
        }}
      >
        {pieces.length === 0 &&
        librarianPieces.length === 0 &&
        `${title}${composer}` ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>No Match</Typography>
            <Typography level="body3">
              Looks like your piece collection doesn't have this one.
            </Typography>
            <br />
            <Button
              size="sm"
              color="neutral"
              onClick={() => {
                setSelectAfterCreate(true);
                dispatch(setTmpPieceName(title));
                setTitle("");
                dispatch(setFormOpen({ isOpen: true, formID: "piece" }));
              }}
            >
              + Create "<i>{`${title}`}</i>"
            </Button>
          </Box>
        ) : (
          <Box sx={{ flex: 1 }}>
            <List size="sm">
              {list.map((p) => (
                <ListItemButton onClick={() => onSelect(p)} key={p.id}>
                  <ListItemContent>
                    <Typography sx={{ fontWeight: 600 }} level="body2">
                      {p.name}
                    </Typography>
                    <Typography sx={{ opacity: 0.6 }} level="body4">
                      {p.composer}
                    </Typography>
                  </ListItemContent>
                  <ListItemDecorator>
                    {projectPieces?.findIndex((pp) => pp.pieceID === p.id) >=
                    0 ? (
                      <Typography color="success">
                        <i className="fa-solid fa-check"></i>
                      </Typography>
                    ) : (
                      []
                    )}
                  </ListItemDecorator>
                </ListItemButton>
              ))}
            </List>
            {`${title}${composer}` && librarianPieces.length > 0 ? (
              <>
                <Divider>More Results</Divider>
                <List size="sm">
                  {librarianPieces.map((p) => (
                    <ListItemButton
                      onClick={() => {
                        const split = p.composer.split(",");
                        const composer = p.composer.includes(",")
                          ? `${split[1]} ${split[0]}`
                          : p.composer;
                        createPiece({
                          name: p.title,
                          composer,
                        }).then((e: any) => {
                          onSelect(e.data);
                        });
                      }}
                      key={p.id}
                    >
                      <ListItemContent>
                        <Typography sx={{ fontWeight: 600 }} level="body2">
                          {p.title}
                        </Typography>
                        <Typography sx={{ opacity: 0.6 }} level="body4">
                          {p.composer}
                        </Typography>
                      </ListItemContent>
                      <ListItemDecorator>
                        {projectPieces?.findIndex(
                          (pp) => pp.pieceID === p.id
                        ) >= 0 ? (
                          <Typography color="success">
                            <i className="fa-solid fa-check"></i>
                          </Typography>
                        ) : (
                          []
                        )}
                      </ListItemDecorator>
                    </ListItemButton>
                  ))}
                </List>
              </>
            ) : (
              []
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

function WorkSessionsCell({
  id,
  workSessionIDs,
  processRowUpdate,
}: {
  id: GridRowId;
  workSessionIDs: number[];
  processRowUpdate: (_new: any, _old: any) => Promise<any>;
}) {
  const utils = useSelector(layoutUtilsSelector);
  const { workSessions, workSessionsMap } = utils;
  const apiRef = useGridApiContext();
  const [_value, setValue] = useState<number[]>(workSessionIDs);

  return (
    <Box sx={{ display: "flex", gap: 0.5 }}>
      {workSessions.map((v, i) => {
        const workSession = workSessionsMap[v.id];
        return (
          <Tooltip
            key={v.id}
            title={`${workSession?.title} - ${workSession.formatDateRange()}`}
            variant="outlined"
            size="sm"
            arrow
            placement="top"
          >
            <Avatar
              onClick={(e) => {
                let val = _value;
                if (_value?.includes(v.id)) {
                  val = val.filter((s) => s !== v.id);
                } else {
                  val = [...val, v.id];
                }
                setValue(val);

                const row = apiRef.current.getRow(id);
                processRowUpdate({ ...row, workSessionIDs: val }, row);
              }}
              sx={
                !_value?.includes(v.id)
                  ? {
                      background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='black' stroke-width='1'/></svg>")`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "100% 100%, auto;",
                      cursor: "pointer",
                    }
                  : { cursor: "pointer" }
              }
              size="sm"
            >
              {i + 1}
            </Avatar>
          </Tooltip>
        );
      })}
    </Box>
  );
}
