import { AdvancedSearchDefinition } from "@nerdjs/nerd-ui/dist/nerdAdvancedSearch/types";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetEmailSearchMetadataQuery,
  useLazyGetEmailsQuery,
} from "redux/email/emailEndpoints";
import { MusicianAutocomplete } from "./musicianAutocomplete";
import { NerdAdvancedSearch } from "features/nerd/nerdAdvancedSearch";
/**
 *
 * @returns {ReactElement} Search Email
 */
export function EmailsSearch() {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [getEmails] = useLazyGetEmailsQuery();
  const { data } = useGetEmailSearchMetadataQuery();

  const definition: AdvancedSearchDefinition[] = [
    {
      id: "sentToEmail",
      name: "sentToEmail",
      label: "Musician",
      type: "string",
      faIcon: "fa-solid fa-user-music",
      renderInput: (value, onChange) => (
        <MusicianAutocomplete
          email={value as unknown as string}
          onChange={(e) => onChange(e?.email)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "sentFromName",
      name: "sentFromName",
      label: "Sent From Name",
      type: "string",
      faIcon: "fa-solid fa-signature",
    },
    {
      id: "subject",
      name: "subject",
      label: "Subject",
      type: "string",
      faIcon: "fa-solid fa-text",
    },
    {
      id: "sent",
      name: "sent",
      label: "Sent",
      type: "bool",
      faIcon: "fa-solid fa-check",
    },
    {
      id: "createdAt",
      name: "createdAt",
      label: "Date",
      type: "date",
      faIcon: "fa-regular fa-calendar-days",
    },
  ];

  return (
    <NerdAdvancedSearch
      definitions={definition}
      open={open}
      searchMetadata={data}
      setOpen={(o) => setOpen(o)}
      onNewSearchPreset={() => setOpen(false)}
      onSearch={(q) => {
        if (q) {
          getEmails(q, false);
        } else {
          getEmails(
            {
              offset: "0",
              limit: searchParams.get("limit") ?? "50",
            },
            false
          );
        }
      }}
    />
  );
}
