import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Input,
  Link,
  List,
  ListItemButton,
  Sheet,
  Tab,
  TabList,
  Tabs,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Filters } from "@nerdjs/nerd-core";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Project_Entity } from "entities/project";
import { ProjectAlternate_Entity } from "entities/projectAlternate";
import { ProjectPiece_Entity } from "entities/projectPiece";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { usePreviewChanges } from "features/projects/ProjectMissionControl/LayoutUtils";
import { mapToArray } from "helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutUtilsSelector,
  positionsSelectedSelector,
} from "reducers/v2/missionControl";
import { useAssignments } from "redux/assignment/assignmentHooks";
import { useCompanySettings } from "redux/company/companyHooks";
import { usePieces } from "redux/piece/pieceHooks";
import { useProjectAutofillMutation } from "redux/project/projectEndpoints";
import { useTemplates } from "redux/project/projectHooks";
import { useLazyGetProjectAlternatesQuery } from "redux/projectAlternate/projectAlternateEndpoints";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useUpdateBatchChairsMutation } from "redux/rhapsodyChair/rhapsodyChairEndpoints";
import { useChairs } from "redux/rhapsodyChair/rhapsodyChairHooks";

/**
 *
 * @returns {ReactElement} FormAutofill page
 */
export function FormAutofill() {
  const open = useSelector(formOpenSelector("autofill"));
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "autofill" }));
  };
  const [loading, setLoading] = useState(false);
  const projectID = useSelector(selectedProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const { chairsMap, chairs, assignmentsMap } = utils;
  const { apiPreview } = useCompanySettings();
  const [getProjectAlternates] = useLazyGetProjectAlternatesQuery();
  const [selectedTemplate, setSelectedTemplate] = useState<Project_Entity>();
  const [selectedProjectPiece, setSelectedProjectPiece] =
    useState<ProjectPiece_Entity>();
  const [mode, setMode] = useState<number>(1);
  const debug = useSelector(layoutDebugSelector);
  const [musicianOption, setMusicianOption] = useState(true);
  const [roleOption, setRoleOption] = useState(false);
  const [instrumentOption, setInstrumentOption] = useState(false);
  const [chairMemoOption, setChairMemoOption] = useState(false);
  const [updateBatchChairs] = useUpdateBatchChairsMutation();
  const { emptyPositions, visibleChairIDs } = useSelector(
    positionsSelectedSelector
  );
  const [projectAutofill] = useProjectAutofillMutation();
  const { projectPieces } = useProjectPieces(projectID);
  const previewChanges = usePreviewChanges();
  const { piecesMap } = usePieces(
    {
      filters: JSON.stringify([
        {
          name: "pieces.id",
          comparison: "in",
          value: projectPieces?.reduce((a, v) => {
            a.push(v.pieceID);
            return a;
          }, []),
        },
      ]),
    },
    { skip: projectPieces?.length === 0 }
  );
  const filters: Filters = [
    {
      name: "template",
      comparison: "eq",
      value: true,
    },
  ];

  if (searchText) {
    filters.push({
      name: "name",
      comparison: "like",
      value: searchText.toLocaleLowerCase(),
    });
  }

  const { templates, templatesMap } = useTemplates({ filters });

  useEffect(() => {
    if (templates.length === 1) {
      setSelectedTemplate(templates[0]);
    }
  }, [templates]);

  const autofillWithTemplate = async () => {
    const template = templatesMap[selectedTemplate.id];

    if (template.version === 2) {
      const query: any = await projectAutofill({
        id: projectID,
        body: {
          chairIDs: visibleChairIDs.reduce((a, v) => {
            const _chair = utils.chairsMap[v];
            if (!_chair.musicianID) a.push(v);
            return a;
          }, []),
          projectID: template.id,
          optsInstruments: instrumentOption,
          optsMusician: musicianOption,
          optsMemo: chairMemoOption,
          optsRole: roleOption,
          preview: apiPreview,
        },
      });
      previewChanges(query.data);
      onClose();
    } else {
      autofillWithTemplateV1();
    }
  };

  const autofillWithTemplateV1 = () => {
    const body: RhapsodyChair_Entity[] = [];
    setLoading(true);
    getProjectAlternates(selectedTemplate.id)
      .unwrap()
      .then((projectAlternates) => {
        const templateChairs: ProjectAlternate_Entity[] = mapToArray(
          projectAlternates.entities
        );
        visibleChairIDs.forEach((chairID) => {
          const chair = chairsMap[chairID];
          if (chair.musicianID) return;
          const match = templateChairs.find(
            (a) =>
              a.sectionID === chair.sectionID &&
              a.sectionOrder === chair.sectionOrder - 1 &&
              a.prime
            //   && chair.sectionRoleID === a.sectionRoleID
            //   (chair.sectionRoleID !== 4
            //     ? chair.sectionRoleID === a.sectionRoleID
            //     : true)
          );
          if (match) {
            body.push({
              ...chair,
              musicianID: musicianOption ? match.musicianID : chair.musicianID,
              sectionRoleID: roleOption
                ? match.sectionRoleID
                : chair.sectionRoleID,
              instrumentIDs: instrumentOption
                ? match.instrumentIDs
                : chair.instrumentIDs,
              memo: chairMemoOption ? match.notes : chair.memo,
            });
          }
        });
        updateBatchChairs(body)
          .unwrap()
          .then((e) => {
            setLoading(false);
            onClose();
          })
          .catch((e) => {
            setLoading(false);
            onClose();
          });
      });
  };

  const autofillWithProjectPiece = () => {
    const body: RhapsodyChair_Entity[] = [];
    const chairsForSelectedProjectPiece = chairs.filter(
      (c) => c.projectPieceID === selectedProjectPiece.id
    );
    visibleChairIDs.forEach((chairID) => {
      const chair = chairsMap[chairID];
      if (chair.musicianID) return;
      const match = chairsForSelectedProjectPiece.find(
        (a) =>
          a.sectionID === chair.sectionID &&
          a.sectionOrder === chair.sectionOrder
      );
      if (match) {
        body.push({
          ...chair,
          musicianID: musicianOption
            ? assignmentsMap[match.assignmentID]?.musicianID
            : chair.musicianID,
          sectionRoleID: roleOption ? match.sectionRoleID : chair.sectionRoleID,
          instrumentIDs: instrumentOption
            ? match.instrumentIDs
            : chair.instrumentIDs,
          memo: chairMemoOption ? match.memo : chair.memo,
        });
      }
    });

    updateBatchChairs(body);
    onClose();
  };

  const template = (
    <List variant="plain" sx={{ flexGrow: 1 }}>
      {templates.map((t) => (
        <ListItemButton
          variant={t.id === selectedTemplate?.id ? "soft" : undefined}
          color={t.id === selectedTemplate?.id ? "primary" : undefined}
          onClick={() => setSelectedTemplate(t)}
          key={t.id}
        >
          {t.name}
        </ListItemButton>
      ))}
      {templates.length === 0 ? (
        <Box
          sx={{
            p: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography>No Templates found.</Typography>
            <Link
              onClick={() => {
                onClose();
                dispatch(setFormOpen({ formID: "template", isOpen: true }));
              }}
              level="body3"
            >
              Create One
            </Link>
          </Box>
        </Box>
      ) : (
        []
      )}
    </List>
  );

  const projectPiece = (
    <List sx={{ flexGrow: 1 }}>
      {projectPieces
        ?.filter(
          (p) =>
            `${piecesMap[p.pieceID]?.name ?? "Seating assignment"} ${
              piecesMap[p.pieceID]?.composer
            }`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) >= 0
        )
        ?.map((p) => (
          <ListItemButton
            variant={p.id === selectedProjectPiece?.id ? "soft" : undefined}
            color={p.id === selectedProjectPiece?.id ? "primary" : undefined}
            onClick={() => setSelectedProjectPiece(p)}
            key={p.id}
          >
            <Box>
              {piecesMap[p.pieceID]?.name ?? "Seating assignment"}
              <Typography level="body3">
                {piecesMap[p.pieceID]?.composer}
              </Typography>
            </Box>
          </ListItemButton>
        ))}
    </List>
  );

  return (
    <DialogClose open={open} onClose={onClose}>
      {!loading ? (
        <>
          <DialogTitle>
            <Box sx={{ textAlign: "center" }}>
              <Typography level="h4">
                <i className="fa-solid fa-wand-magic-sparkles"></i>
              </Typography>
              <Typography level="h6">
                Autofill {emptyPositions.length} chair
                {emptyPositions.length > 1 ? "s" : ""} with:
              </Typography>
            </Box>
            <Tabs
              size="sm"
              variant="soft"
              value={mode}
              onChange={(event, value) => {
                const v = value as number;
                setMode(v);
              }}
              sx={{ borderRadius: "lg" }}
            >
              <TabList size="sm">
                <Tab value={1}>Template</Tab>
                <Tab value={2}>Project Piece</Tab>
              </TabList>
            </Tabs>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Input
              value={searchText}
              autoFocus
              onChange={(e) => setSearchText(e.target.value)}
              startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
              sx={{
                position: "sticky",
                top: 0,
                background: "white",
                zIndex: 999,
              }}
              endDecorator={
                <IconButton
                  color="neutral"
                  variant="plain"
                  onClick={(e) => setSearchText("")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
              }
              variant="outlined"
              size="sm"
              placeholder="Search..."
            />
            <Box sx={{ minHeight: 380 }}>
              {mode === 1 ? template : []}
              {mode === 2 ? projectPiece : []}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "stretch",
            }}
          >
            <Sheet variant="soft" sx={{ p: 1 }}>
              <Typography level="body4">Autofill Options:</Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Checkbox
                    onChange={(e) => setMusicianOption(e.target.checked)}
                    checked={musicianOption}
                    size="sm"
                    label={<Typography level="body4">Musician</Typography>}
                  />
                </Grid>
                <Grid item>
                  <Checkbox
                    onChange={(e) => setRoleOption(e.target.checked)}
                    checked={roleOption}
                    size="sm"
                    label={<Typography level="body4">Role</Typography>}
                  />
                </Grid>
                <Grid item>
                  <Checkbox
                    onChange={(e) => setInstrumentOption(e.target.checked)}
                    checked={instrumentOption}
                    size="sm"
                    label={<Typography level="body4">Instruments</Typography>}
                  />
                </Grid>
                <Grid item>
                  <Checkbox
                    onChange={(e) => setChairMemoOption(e.target.checked)}
                    checked={chairMemoOption}
                    size="sm"
                    label={<Typography level="body4">Chair Memo</Typography>}
                  />
                </Grid>
              </Grid>
            </Sheet>
            <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
              <Button variant="soft" color="neutral" onClick={onClose}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (mode === 1) autofillWithTemplate();
                  if (mode === 2) autofillWithProjectPiece();
                }}
                disabled={
                  (mode === 1 && !selectedTemplate) ||
                  (mode === 2 && !selectedProjectPiece)
                }
              >
                Autofill
              </Button>
            </Box>
          </DialogActions>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 4,
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CircularProgress />
          <Box>
            <Typography level="h6">Autofill in progress</Typography>
            <Typography level="body2">
              This could take several seconds.
            </Typography>
          </Box>
        </Box>
      )}
    </DialogClose>
  );
}
