/**
 * ProjectPieceSection_Entity type guard.
 *
 * @param {any} projectPieceSectionJson ProjectPieceSection object from API
 * @returns {boolean} Return true if type is ProjectPieceSection_Entity
 */

import { ProjectPieceChair_Entity } from "entities/projectPieceChair";

export default class ProjectPieceSection_Entity {
  constructor(projectPieceSectionJson: ProjectPieceSection_Entity) {
    try {
      this.id = projectPieceSectionJson.id;
      this.projectPieceID = projectPieceSectionJson.projectPieceID;
      this.sectionID = projectPieceSectionJson.sectionID;
      this.chairs = projectPieceSectionJson.chairs;
      this.createdAt = projectPieceSectionJson.createdAt;
      this.updatedAt = projectPieceSectionJson.updatedAt;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }
  id: number;
  projectPieceID: number;
  sectionID: number;
  chairs: Array<ProjectPieceChair_Entity>;
  createdAt: string;
  updatedAt: string;
}
