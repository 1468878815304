import { Assignment } from "entities/assignment";
import { Internal } from "entities/internal";
import { InternalPosition } from "entities/internal/InternalPosition";
import { InternalSection } from "entities/internal/InternalSection";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { Selecto } from "entities/selecto";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  layoutDebugSelector,
  layoutUnselectAll,
  selectionSelector,
} from "reducers/v2/missionControl";
import {
  useDeleteAssignmentMutation,
  useUpdateBatchAssignmentsMutation,
} from "redux/assignment/assignmentEndpoints";
import { useResetMercuryJobMutation } from "redux/mission/missionEndpoints";
import {
  useCreateChairsMutation,
  useDeleteBatchChairsMutation,
  useUpdateBatchChairsMutation,
} from "redux/rhapsodyChair/rhapsodyChairEndpoints";
import { store } from "store";

export function useUpdater(
  positionIDs: string[],
  assignmentIDs: number[],
  internal: Internal,
  utils: LayoutUtils,
  fromChair?: boolean
) {
  const [updateAssignments] = useUpdateBatchAssignmentsMutation();
  const [deleteAssignment] = useDeleteAssignmentMutation();
  const [updateChairs] = useUpdateBatchChairsMutation();
  const [createChairs] = useCreateChairsMutation();
  const [deleteChairs] = useDeleteBatchChairsMutation();
  const [resetMercuryJob] = useResetMercuryJobMutation();
  const dispatch = useDispatch();

  const positions = internal.getPositions(positionIDs);

  const chairs = positions.reduce((a, v) => {
    const visibleChairs = utils.chairs.filter((c) => {
      return v.visibleChairIDs.includes(c.id);
    });
    a.push(...visibleChairs);
    return a;
  }, []);
  const debug = useSelector(layoutDebugSelector);
  const selection = useSelector(selectionSelector);
  const { assignmentsMap, assignments: allAssignments } = utils;

  const assignments = allAssignments.filter((a) =>
    assignmentIDs.includes(a.id)
  );

  const prepareChairForCreation = (_chair: RhapsodyChair_Entity) => {
    const body: RhapsodyChair_Entity = { ..._chair };
    body.chairCount = 1;
    delete body.id;
    delete body.createdAt;
    delete body.updatedAt;
    delete body.assignmentID;
    delete body.musicianID;
    delete body.workSessionIDs;
    const assignment = assignmentsMap[_chair?.assignmentID];
    if (assignment) {
      body.musicianID = assignment.musicianID;
    }

    return body;
  };

  const prepareChairsForCreation = (_chairs: RhapsodyChair_Entity[]) => {
    const body: RhapsodyChair_Entity[] = [];
    _chairs.forEach((c) => {
      body.push(prepareChairForCreation(c));
    });

    return body;
  };

  const update = (transformer: any) => {
    const mc = store.getState().missionControl;
    const selecto = new Selecto(
      mc.selecto,
      mc.internal,
      mc.layoutUtils,
      mc.selection
    );
    const _positions = internal.getPositions(positionIDs);
    const p = fromChair ? _positions : selecto.positions;
    const i = fromChair ? [_positions[0].id] : selecto.positionIDs;
    const dispatcher = selection.getDispatcher(p, i, utils);
    const uBody = [];
    const cBody = [];

    cBody.push(...transformer(prepareChairsForCreation(dispatcher.create)));
    uBody.push(...transformer(dispatcher.update));

    if (uBody.length) updateChairs(uBody);
    if (cBody.length) createChairs(cBody);
  };

  return useMemo(() => {
    const ret: Updater = {
      handleReassignMusician: (
        oldMusicianID: number,
        newMusicianID: number,
        workSessionIDs?: number[],
        projectPieceIDs?: number[]
      ) => {
        const oldAssignment = allAssignments.find(
          (a) => a.musicianID === oldMusicianID
        );
        let uBody: RhapsodyChair_Entity[] = [
          ...utils.chairs.filter((c) => c.musicianID === oldMusicianID),
        ];
        let cBody: RhapsodyChair_Entity[] = [];

        if (projectPieceIDs?.length) {
          uBody = uBody.filter((c) =>
            projectPieceIDs.includes(c.projectPieceID)
          );
        }

        if (workSessionIDs?.length) {
          const chairsPlayingInWorkSessionIDs = uBody.reduce((a, v) => {
            v.workSessionIDs.forEach((i) => {
              if (!a.includes(i)) a.push(i);
            });
            return a;
          }, []);

          // Do we need work session chairs?
          const diff = chairsPlayingInWorkSessionIDs.filter(
            (x) => !workSessionIDs.includes(x)
          );
          if (diff) {
            // yes we do
            workSessionIDs.forEach((i) => {
              const chairs = uBody.filter((c) => c.workSessionIDs.includes(i));
              chairs.forEach((c) => (c.workSessionID = i));
              cBody.push(...chairs);
            });
            uBody = [];
          } else {
            // no need
          }
        }

        cBody = prepareChairsForCreation(cBody);

        uBody.forEach((c) => {
          c.musicianID = newMusicianID;
          // c.memo = oldAssignment?.memo;
        });
        cBody.forEach((c) => {
          c.musicianID = newMusicianID;
          // c.memo = oldAssignment?.memo;
        });

        if (uBody.length) updateChairs(uBody);
        if (cBody.length) createChairs(cBody);
      },
      handleSectionRoleUpdate: (sectionRoleID: number) => {
        const transformer = (c: RhapsodyChair_Entity[]) => {
          c.forEach((c) => (c.sectionRoleID = sectionRoleID));
          return c;
        };
        update(transformer);
      },
      handleResetStatus: (resetMercuryJobIDs) => {
        resetMercuryJobIDs.forEach((a) => {
          resetMercuryJob({ id: a });
        });
      },
      handleSetInstruments: (instrumentIDs: number[]) => {
        const transformer = (c: RhapsodyChair_Entity[]) => {
          c.forEach((c) => {
            c.instrumentIDs = JSON.stringify(instrumentIDs);
          });
          return c;
        };
        update(transformer);
      },
      handleInstrumentsUpdate: (instrumentIDs: number[]) => {
        const transformer = (c: RhapsodyChair_Entity[]) => {
          c.forEach((c) => {
            const _instrumentIDs = JSON.parse(
              c.instrumentIDs ? c.instrumentIDs : "[]"
            );
            c.instrumentIDs = JSON.stringify(
              instrumentIDs
                ? [...new Set([..._instrumentIDs, ...instrumentIDs])]
                : []
            );
          });
          return c;
        };
        update(transformer);
      },
      handleDeleteInstrumentUpdate: (instrumentID: number) => {
        const transformer = (c: RhapsodyChair_Entity[]) => {
          c.forEach((c) => {
            const _instrumentIDs = JSON.parse(c.instrumentIDs ?? "[]");
            c.instrumentIDs = JSON.stringify(
              _instrumentIDs.filter((id) => id !== instrumentID)
            );
          });
          return c;
        };
        update(transformer);
      },
      handleAssign: (musicianID: number) => {
        const transformer = (c: RhapsodyChair_Entity[]) => {
          c.forEach((c) => {
            if (!c.assignmentID) c.musicianID = musicianID;
          });
          return c;
        };
        update(transformer);
      },
      handleChairMemo: (memo: string) => {
        const transformer = (c: RhapsodyChair_Entity[]) => {
          c.forEach((c) => {
            c.memo = memo;
          });
          return c;
        };
        update(transformer);
      },
      handleMoveTo: (sectionID: number) => {
        const transformer = (c: RhapsodyChair_Entity[]) => {
          c.forEach((c) => {
            c.sectionID = sectionID;
            c.sectionOrder = 99;
          });
          return c;
        };
        update(transformer);
      },
      handleMusicianMemo: (memo: string) => {
        const body = [...assignments];
        body.forEach((c) => (c.memo = memo));
        updateAssignments(body);
      },
      handleDeleteChair: (emptyPositions: InternalPosition[]) => {
        // It only deletes the chair if it is empty.
        const deleteBody: RhapsodyChair_Entity[] = [];
        const updateBody: RhapsodyChair_Entity[] = [];
        emptyPositions.forEach((p) => {
          p.visibleChairIDs.forEach((chairID) => {
            const _chair = utils.chairsMap[chairID];
            const empty = _chair?.musicianID === -1 || !_chair?.musicianID;
            if (_chair.workSessionID && empty) {
              updateBody.push({
                ..._chair,
                hidden: true,
                memo: "manual",
              });
            } else if (empty) {
              deleteBody.push(_chair);
            }
          });
        });
        deleteChairs(deleteBody);
        updateChairs(updateBody);
        dispatch(layoutUnselectAll());
      },
      handleRemoveMusicans: (declinedOnly?: boolean) => {
        const transformer = (c: RhapsodyChair_Entity[]) => {
          c.forEach((chair) => {
            const assignment = assignments.find(
              (a) => chair.assignmentID === a.id
            );
            if (declinedOnly && assignment.mercuryStageID !== 3) return;
            const removable =
              utils?.stagesMap[assignment?.mercuryStageID]?.terminus ?? false;

            if (!assignment || !assignment?.mercuryStageID || removable)
              chair.musicianID = -1;
            if (debug) {
              chair.musicianID = -1;
            }
          });
          return c;
        };
        update(transformer);
      },
      handleExcludeChairs: (positions: InternalPosition[]) => {
        const body: RhapsodyChair_Entity[] = [];
        positions.forEach((p) => {
          p.visibleChairIDs.forEach((c) => {
            const _chair = utils.chairsMap[c];
            const item = { ..._chair };
            delete item.id;
            delete item.assignmentID;
            delete item.workSessionIDs;
            item.chairCount = 1;
            item.musicianID = -1;
            item.workSessionID = p.forWorkSessionID;
            item.hidden = true;
            item.memo = "manual";
            body.push(item);
          });
        });
        return createChairs(body).unwrap();
      },
      handleRemoveFromPieceForReleasableMusicians: (
        projectPieceID: number[]
      ) => {
        const mc = store.getState().missionControl;
        const selecto = new Selecto(
          mc.selecto,
          mc.internal,
          mc.layoutUtils,
          mc.selection
        );
        const { releasableMusicians } = selecto;
        const body = [];
        releasableMusicians.forEach((m) => {
          const assignment = assignments.find((a) => a.musicianID === m.id);
          const _chairs = mc.layoutUtils.chairs.filter(
            (c) => c.assignmentID === assignment.id
          );
          _chairs.forEach((c) => {
            projectPieceID.forEach((p) => {
              if (c.projectPieceID === p) {
                body.push({ ...c, musicianID: -1 });
              }
            });
          });
        });
        return updateChairs(body).unwrap();
      },
      handleDeleteWorkSessionChairs: (_positions: InternalPosition[]) => {
        const toDelete = [];
        _positions.forEach((p) => {
          p.visibleChairIDs.forEach((c) => {
            const _chair = utils.chairsMap[c];
            if (_chair.workSessionID) {
              toDelete.push(_chair);
            }
          });
        });

        deleteChairs(toDelete);
      },
      handleRemoveMusican: (musicianID: number) => {
        const transformer = (c: RhapsodyChair_Entity[]) => {
          c.forEach((c) => {
            if (c.musicianID === musicianID) c.musicianID = -1;
          });
          return c;
        };
        update(transformer);
      },
      handleAddWorkSessionChairsForReleasableMusicians: (
        workSessionIDs: number[]
      ) => {
        const mc = store.getState().missionControl;
        const selecto = new Selecto(
          mc.selecto,
          mc.internal,
          mc.layoutUtils,
          mc.selection
        );
        const { releasableMusicians } = selecto;
        const body = [];
        releasableMusicians.forEach((m) => {
          const assignment = assignments.find((a) => a.musicianID === m.id);
          const _chairs = mc.layoutUtils.chairs.filter(
            (c) => c.assignmentID === assignment.id
          );

          _chairs.forEach((c) => {
            workSessionIDs.forEach((w) => {
              if (c.workSessionIDs.includes(w)) {
                const item = { ...c };
                delete item.id;
                delete item.assignmentID;
                delete item.workSessionIDs;
                item.chairCount = 1;
                item.musicianID = -1;
                item.workSessionID = w;
                body.push(item);
              }
            });
          });
        });
        return createChairs(body).unwrap();
      },
      handleClearRevision: () => {
        const body = [...assignments];
        body.forEach((c) => {
          c.importantChanges = false;
          c.criticalChanges = false;
        });
        updateAssignments(body);
      },
      handleReorderMusicians: (
        oldOrder: InternalPosition[],
        newOrder: InternalPosition[],
        internalSection?: InternalSection
      ) => {
        const forWorkSessionID = internalSection.forWorkSessionID;

        const update: RhapsodyChair_Entity[] = [];
        const create: RhapsodyChair_Entity[] = [];

        const snapshot = internalSection.getSnapshot(utils);

        newOrder.forEach((position, i) => {
          const newSectionOrder = i + 1;
          const chairIDs = position.visibleChairIDs;

          chairIDs.forEach((c) => {
            const _c = utils.chairsMap[c];
            _c.sectionOrder = newSectionOrder;

            const piecePositionSnapshot =
              snapshot[_c.sectionOrder - 1][_c.projectPieceID];
            if (_c.sectionRoleID !== piecePositionSnapshot.sectionRoleID) {
              _c.sectionRoleID = piecePositionSnapshot.sectionRoleID;
            }
            if (_c.instrumentIDs !== piecePositionSnapshot.instrumentIDs) {
              _c.instrumentIDs = piecePositionSnapshot.instrumentIDs;
            }
            if (_c.memo !== piecePositionSnapshot.memo) {
              _c.memo = piecePositionSnapshot.memo;
            }

            const _chair = {
              ..._c,
              sectionOrder: newSectionOrder,
            };

            const _wsChair = { ..._chair, chairCount: 1 };
            delete _wsChair.id;
            delete _wsChair.createdAt;
            delete _wsChair.updatedAt;
            delete _wsChair.workSessionIDs;
            delete _wsChair.workSessionID;

            if (forWorkSessionID) {
              if (_chair?.workSessionID) {
                update.push(_chair);
              } else {
                create.push({
                  ..._wsChair,
                  workSessionID: forWorkSessionID,
                });
              }
            } else {
              if (internal.allWorkSessionsSelected) {
                update.push(_chair);
              } else {
                selection.workSessionIDs.forEach((wID) => {
                  if (_chair?.workSessionID) {
                    update.push(_chair);
                  } else {
                    create.push({
                      ..._wsChair,
                      workSessionID: wID,
                    });
                  }
                });
              }
            }
          });
        });

        if (update.length) updateChairs(update);
        if (create.length) createChairs(create);
      },
    };

    return ret;
  }, [chairs, assignments, internal]);
}

type Updater = {
  handleReassignMusician: (
    oldMusicianID: number,
    newMusicianID: number,
    workSessionIDs?: number[],
    projectPieceIDs?: number[]
  ) => void;
  handleSectionRoleUpdate: (sectionRoleID: number) => void;
  handleInstrumentsUpdate: (instrumentIDs: number[] | null) => void;
  handleSetInstruments: (instrumentIDs: number[]) => void;
  handleDeleteInstrumentUpdate: (instrumentIDs: number) => void;
  handleAssign: (musicianID: number) => void;
  handleDeleteChair: (emptyPositions: InternalPosition[]) => void;
  handleExcludeChairs: (positions: InternalPosition[]) => void;
  handleRemoveMusicans: (declinedOnly?: boolean) => void;
  handleRemoveFromPieceForReleasableMusicians: (projectPieceID) => void;
  handleAddWorkSessionChairsForReleasableMusicians: (workSessionIDs) => void;
  handleRemoveMusican: (musicianID: number) => void;
  handleChairMemo: (memo: string) => void;
  handleMoveTo: (sectionID: number) => void;
  handleMusicianMemo: (s: string) => void;
  handleClearRevision: () => void;
  handleResetStatus: (resetMercuryJobIDs: number[]) => void;
  handleDeleteWorkSessionChairs: (p: InternalPosition[]) => void;
  handleReorderMusicians: (
    oldOrder: InternalPosition[],
    newOrder: InternalPosition[],
    internalSection?: InternalSection
  ) => void;
};

type ChairDispatcher = {
  update: RhapsodyChair_Entity[];
  create: RhapsodyChair_Entity[];
  delete: RhapsodyChair_Entity[];
};
