import { Subscription } from "entities/subscription";
import { useMemo } from "react";
import { useGetCurrentSubscriptionQuery } from "./subscriptionEndpoints";

export function useCurrentSubscription() {
  const subscriptionQuery = useGetCurrentSubscriptionQuery();
  const subscriptionEntity = subscriptionQuery.data;

  return useMemo(() => {
    const ret: typeof subscriptionQuery & {
      subscription?: Subscription | undefined;
    } = {
      ...subscriptionQuery,
    };
    if (subscriptionEntity)
      ret.subscription = new Subscription(subscriptionEntity);
    return ret;
  }, [subscriptionEntity]);
}
