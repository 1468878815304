import { Project_Entity } from "entities/project";

/**
 * Tag_Entity type guard.
 *
 * @param {any} tagJson Tag object from API
 * @returns {boolean} Return true if type is Tag_Entity
 */

export default class Tag_Entity {
  static requiredFields = ["id"];

  constructor(tagJson: Tag_Entity) {
    try {
      this.id = tagJson.id;
      this.image = tagJson.image;
      this.name = tagJson.name;
      this.organizationID = tagJson.organizationID;
      this.publicToken = tagJson.publicToken;
      this.pipelineID = tagJson.pipelineID;
      this.projects = tagJson.projects;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  image: string;
  name: string;
  organizationID: string;
  publicToken: string;
  pipelineID: number;
  projects: Project_Entity[];
}
