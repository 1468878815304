import { WorkSessionMercury } from "entities/workSessionMercury";
import { useMemo } from "react";
import { useGetWorkSessionMercuryQuery } from "./workSessionMercuryEndpoints";

export function useWorkSessionMercury(
  workSessionMercuryID: number | undefined
) {
  const workSessionMercuryQuery = useGetWorkSessionMercuryQuery(
    workSessionMercuryID ?? 0,
    {
      skip: (workSessionMercuryID ?? 0) <= 0,
    }
  );
  const workSessionMercuryEntity = workSessionMercuryQuery.data;

  return useMemo(() => {
    const ret: typeof workSessionMercuryQuery & {
      workSessionMercury?: WorkSessionMercury | undefined;
    } = {
      ...workSessionMercuryQuery,
    };
    if (workSessionMercuryEntity)
      ret.workSessionMercury = new WorkSessionMercury(workSessionMercuryEntity);
    return ret;
  }, [workSessionMercuryEntity]);
}
