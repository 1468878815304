import { Alert, Link, Typography } from "@mui/joy";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import ProjectsDataGrid from "features/dataGrids/ProjectsDataGrid";
import WorkSessionsDataGrid from "features/dataGrids/WorkSessionsDataGrid/WorkSessionsDataGrid";
import { useRouteMatch } from "helpers";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import Calendar from "hooks/Calendar";
import { Layout } from "hooks/Layout/v1";
import PieceDangerZone from "hooks/PieceDangerZone/PieceDangerZone";
import PieceGeneral from "hooks/PieceGeneral";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link as RouterLink, Route, Routes, useParams } from "react-router-dom";
import { setSelectedPieceID } from "reducers/rhapsody";
import { usePiece } from "redux/piece/pieceHooks";

export default function PiecePage({ id }) {
  const params = useParams();
  const dispatch = useDispatch();
  const pieceID = id || parseInt(params.pieceID);
  const { piece } = usePiece(pieceID);

  useEffect(() => {
    return () => {
      dispatch(setSelectedPieceID());
    };
  }, []);

  if (!piece) return <div />;

  const routeMatch = useRouteMatch([
    "/pieces/:id/general",
    "/pieces/:id/calendar",
    "/pieces/:id/instrumentation",
    "/pieces/:id/projects",
    "/pieces/:id/work-sessions",
    "/pieces/:id/danger-zone",
  ]);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        flexDirection: "column",
        margin: "auto",
        maxWidth: 1600,
      }}
    >
      <Helmet>
        <title>Rhapsody | {piece.name}</title>
      </Helmet>
      <Box>
        <Link
          component={RouterLink}
          to={RouterConfig.pieces}
          level="body1"
          underline="none"
        >
          Pieces
        </Link>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography level="h5">{piece.name}</Typography>
        </Box>
        <MyTabs routeMatch={routeMatch} />
        <Divider />
      </Box>
      <Routes>
        <Route
          path={"general"}
          element={
            <Box
              sx={{ p: 2, display: "flex", gap: 2, flexDirection: "column" }}
            >
              <Typography level="h6">Details</Typography>
              <Divider />
              <PieceGeneral id={pieceID} />
              <Typography level="h6">Related Projects</Typography>
              <Divider />
              <ProjectsDataGrid noHiring={false} pieceID={pieceID} />
              {/* <Typography level="h6">Related Work Sessions</Typography>
              <Divider />
              <WorkSessionsDataGrid pieceID={pieceID} /> */}
              {/* <Typography level="h6">Danger Zone</Typography>
              <Divider />
              <PieceDangerZone pieceID={pieceID} /> */}
            </Box>
          }
        ></Route>
        <Route
          path={"instrumentation"}
          element={
            <Box
              sx={{ p: 2, display: "flex", gap: 2, flexDirection: "column" }}
            >
              <Alert color="warning">
                Modifying this seating arrangement will not affect the Projects
                and Work Sessions that are currently utilizing this component,
                as this instrumentation functions as a standard template.
              </Alert>
              <Layout
                sections={[]}
                renderChair={(e) => <Box>Hello</Box>}
                onOrderChange={(e) => console.log(e)}
                chairType="projectPiece"
              />
            </Box>
          }
        ></Route>
        <Route
          path={"calendar"}
          element={
            <Box
              sx={{
                p: 2,
                height: "calc(100vh - 250px)",
                minHeight: 800,
                position: "relative",
                display: "flex",
              }}
            >
              <Calendar pieceID={pieceID} />
            </Box>
          }
        ></Route>
      </Routes>
    </Box>
  );
}

function MyTabs({ routeMatch }) {
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Tabs value={currentTab}>
      <Tab
        label="General"
        value="/pieces/:id/general"
        to="general"
        component={RouterLink}
      />
      {/* <Tab
        label="Instrumentation"
        value="/pieces/:id/instrumentation"
        to="instrumentation"
        component={RouterLink}
      />
      <Tab
        label="Calendar"
        value="/pieces/:id/calendar"
        to="calendar"
        component={RouterLink}
      /> */}
    </Tabs>
  );
}
