import { GET, PUT, DELETE, POST } from "config/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "account";
const verificationCodes = `${REACT_APP_API_PREFIX}/${service}/verificationCodes`;

export function getVerificationCodes(filters) {
  const url = `${verificationCodes}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getVerificationCodeById(id) {
  const url = `${verificationCodes}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateVerificationCode(id, body) {
  const url = `${verificationCodes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteVerificationCode(id) {
  const url = `${verificationCodes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createVerificationCode(body) {
  const url = `${verificationCodes}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function validateVerificationCode(body) {
  const url = `${verificationCodes}/validate`;
  return {
    method: POST,
    url,
    body,
  };
}

export function resendVerificationCode(id) {
  const url = `${verificationCodes}/${id}/resend`;
  return {
    method: GET,
    url,
  };
}
