import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Assignment } from "entities/assignment";
import { Musician } from "entities/musician";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { ChairAssignMusician } from "features/chair/v2/chairAssignMusician";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useSubRuleDescription } from "features/subs/subRuleCard";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import {
  layoutUtilsSelector,
  setAssignmentIDForLookup,
} from "reducers/v2/missionControl";
import {
  useLazyGetAssignmentsQuery,
  useUpdateAssignmentMutation,
} from "redux/assignment/assignmentEndpoints";
import { useLazyGetMusiciansSuggestionsQuery } from "redux/musician/musicianEndpoints";
import { useMusician } from "redux/musician/musicianHooks";
import { useUpdateBatchChairsMutation } from "redux/rhapsodyChair/rhapsodyChairEndpoints";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ReplaceMusicians() {
  const open = useSelector(formOpenSelector("replaceMusician"));
  const dispatch = useDispatch();
  const utils = useSelector(layoutUtilsSelector);
  const { chairs, hProject, assignments, project } =
    useSelector(layoutUtilsSelector);
  const [getMusicianSuggestions] = useLazyGetMusiciansSuggestionsQuery();
  const [updateChairs] = useUpdateBatchChairsMutation();
  const [getAssignments] = useLazyGetAssignmentsQuery();
  const [updateAssignment] = useUpdateAssignmentMutation();
  const [replace, setReplace] = useState<Assignment[]>([]);
  const [suggestions, setSuggestions] = useState<Dictionary<Musician[]>>();
  const [replaceMap, setReplaceMap] = useState<Dictionary<number>>({});
  const [loading, setLoading] = useState(false);

  const askQuestion = useAskQuestion();

  const replaceCount = Object.values(replaceMap)?.filter((e) => e)?.length;
  const replaceMapValues = Object.values(replaceMap);
  const error = new Set(replaceMapValues).size !== replaceMapValues.length;

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "replaceMusician" }));
  };

  useEffect(() => {
    if (assignments.length && replace.length === 0) {
      const _replace = assignments
        ?.filter((a) => {
          const removable =
            utils?.stagesMap[a.mercuryStageID]?.terminus ?? false;
          return (
            removable && chairs.filter((c) => c.assignmentID === a.id).length
          );
        })
        .sort((a, b) => {
          const jobA = hProject.jobs?.find((j) => j.refID === a.musicianID);
          const jobB = hProject.jobs?.find((j) => j.refID === b.musicianID);

          const dateA = moment(jobA?.currentStage?.createdAt);
          const dateB = moment(jobB?.currentStage?.createdAt);

          return dateA.valueOf() - dateB.valueOf();
        });
      setReplace(_replace);
    }
  }, [assignments]);

  useEffect(() => {
    if (!suggestions && replace?.length) {
      const _suggestions = {};
      for (const key in replace) {
        if (Object.hasOwnProperty.call(replace, key)) {
          const a = replace[key];
          const _chairs = chairs.filter((c) => c.assignmentID === a.id);
          const firstChair = _chairs.length ? _chairs[0] : undefined;

          if (firstChair) {
            _suggestions[a.id] = getMusicianSuggestions(firstChair.id)
              .unwrap()
              .then((r) => {
                setSuggestions((s) => ({
                  ...s,
                  [a.id]: r.ids
                    .reduce<Musician[]>((a, v) => {
                      a.push(new Musician(r.entities[v]));
                      return a;
                    }, [])
                    .sort((a, b) => a.position - b.position),
                }));
              });
          }
        }
      }
      setSuggestions(_suggestions);
    }
  }, [replace]);

  useEffect(() => {
    if (suggestions && replace.length) {
      const _replaceMap: Dictionary<number> = {};
      replace.forEach((a) => {
        const _suggestions = suggestions[a.id]?.length ? suggestions[a.id] : [];
        let finished = false;
        _suggestions.forEach((s) => {
          if (finished) return;
          const values = Object.values(_replaceMap);
          const assignmentForSuggestion = assignments.find(
            (e) => e.musicianID === s.id
          );
          if (
            s.subRuleID &&
            !values.includes(s.id) &&
            !assignmentForSuggestion
          ) {
            _replaceMap[a.musicianID] = s.id;
            finished = true;
          }
        });
      });

      setReplaceMap(_replaceMap);
    }
  }, [suggestions]);

  const handleReplaceMusicians = async () => {
    setLoading(true);
    const body: RhapsodyChair_Entity[] = [];
    for (const key in replaceMap) {
      if (Object.hasOwnProperty.call(replaceMap, key)) {
        const musicianID = parseInt(key);
        const newMusicianID = replaceMap[musicianID];

        if (newMusicianID) {
          const _chairs = chairs.filter((c) => c.musicianID === musicianID);
          _chairs.forEach((c) => {
            body.push({ ...c, musicianID: newMusicianID });
          });
        }
      }
    }

    if (body.length) {
      await updateChairs(body).unwrap();

      setLoading(false);
      onClose();
    }
  };

  return (
    <DialogClose maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-solid fa-rotate"></i>}
          level="h6"
        >
          Replace Musicians
        </Typography>
        <Typography level="body2">
          The following musicians need to be replaced.
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          gap: 1.5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {replace.length === 0 ? (
          <Box sx={{ textAlign: "center", pt: 6 }}>
            <Typography>
              <b>🎉 Good Job!</b>
            </Typography>
            <Typography level="body2">
              There are no more musicians to replace.
            </Typography>
          </Box>
        ) : (
          []
        )}
        {replace.map((a) => {
          return (
            <Replace
              key={a.id}
              assignment={a}
              suggestions={suggestions ? suggestions[a.id] : []}
              candidateID={replaceMap[a.musicianID]}
              onCandidateChange={(candidateID) => {
                if (candidateID) {
                  setReplaceMap((s) => ({ ...s, [a.musicianID]: candidateID }));
                } else {
                  setReplaceMap((s) => {
                    const _s = { ...s };
                    delete _s[a.musicianID];
                    return _s;
                  });
                }
              }}
              error={
                Object.values(replaceMap).filter(
                  (e) => e === replaceMap[a.musicianID]
                ).length > 1
              }
            />
          );
        })}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box
          sx={{
            flex: 1,
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Button
            fullWidth
            startDecorator={loading ? <CircularProgress /> : undefined}
            color={error ? "danger" : "primary"}
            disabled={replaceCount === 0 || error}
            onClick={handleReplaceMusicians}
          >
            {replaceCount > 0
              ? `Replace ${replaceCount} musician${replaceCount > 1 ? "s" : ""}`
              : "Replace All"}
          </Button>
        </Box>
      </DialogActions>
    </DialogClose>
  );
}

function Replace({
  assignment,
  suggestions,
  candidateID,
  onCandidateChange,
  error,
}: {
  assignment: Assignment;
  suggestions?: Musician[];
  candidateID?: number;
  onCandidateChange: (candidateID: number | null) => void;
  error?: boolean;
}) {
  const utils = useSelector(layoutUtilsSelector);
  const dispatch = useDispatch();

  const {
    stagesMap,
    sectionsMap,
    chairs: allChairs,
    sectionRolesMap,
    hProject,
  } = utils;

  const stage = stagesMap[assignment.mercuryStageID];
  const musician = utils.musiciansMap[assignment.musicianID];

  const chairs = allChairs.filter((c) => c.assignmentID === assignment.id);
  const firstChair = chairs?.length ? chairs[0] : undefined;

  const candidate1 = suggestions?.find
    ? suggestions?.find((m) => m.id === candidateID)
    : undefined;

  const { musician: candidate2 } = useMusician(candidateID, {
    skip: candidate1 !== undefined,
  });

  const candidate = candidate1 ?? candidate2;

  // const { musicians } = useMusicianSuggestions(
  //   firstChair?.id,
  //   firstChair === undefined
  // );

  // const firstSuggestion = musicians?.length ? musicians[0] : undefined;

  const description = useSubRuleDescription(candidate?.subRuleID);

  const job = hProject.jobs?.find((j) => j.refID === assignment.musicianID);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        justifyContent: "space-between",
        alignItems: "stretch",
      }}
    >
      <Sheet
        variant="soft"
        onClick={() => {
          dispatch(setAssignmentIDForLookup(assignment.id));
          dispatch(setFormOpen({ isOpen: true, formID: "assignmentLookup" }));
        }}
        sx={{
          display: "flex",
          gap: 1,
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 8,
          cursor: "pointer",
          p: 1,
          "&:hover": {
            background: "#eeeeee",
          },
        }}
      >
        <Box sx={{ display: "flex", gap: 1, flex: 1 }}>
          <MusicianAvatar musician={musician} />
          <Box>
            <Typography level="body2" sx={{ fontWeight: 600, color: "black" }}>
              {musician?.fullName()}
            </Typography>
            <Typography
              level="body3"
              sx={{ fontWeight: "normal", color: "black" }}
            >
              Role: {sectionRolesMap[firstChair?.sectionRoleID]?.name}, Section:{" "}
              {sectionsMap[firstChair?.sectionID]?.name}, Chair#
              {firstChair?.sectionOrder}
            </Typography>
            <Typography
              startDecorator={<img src={stage.icon} style={{ height: 14 }} />}
              level="body3"
              sx={{ color: stage?.color }}
              endDecorator={
                <Tooltip
                  size="sm"
                  arrow
                  variant="outlined"
                  title={moment(job?.currentStage?.createdAt).format("lll")}
                >
                  <Typography level="body4">
                    {moment(job?.currentStage?.createdAt).fromNow()}
                  </Typography>
                </Tooltip>
              }
            >
              {stage?.wording()}
            </Typography>
          </Box>
        </Box>
        <IconButton color="neutral">
          <i className="fa-regular fa-circle-info"></i>
        </IconButton>
      </Sheet>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <i className="fa-solid fa-arrow-right"></i>
      </Box>
      <ChairAssignMusician
        chairID={firstChair?.id}
        onSelect={onCandidateChange}
      >
        <Sheet
          variant="soft"
          color={error ? "danger" : "neutral"}
          sx={{
            display: "flex",
            gap: 1,
            flex: 1,
            cursor: "pointer",
            borderRadius: 8,
            p: 1,
            "&:hover": {
              background: "#eeeeee",
            },
          }}
        >
          <Box sx={{ display: "flex", gap: 1, flex: 1, alignItems: "center" }}>
            <MusicianAvatar musician={candidate} />
            <Box>
              <Typography
                level="body2"
                sx={{ fontWeight: 600, color: "black" }}
              >
                {candidate?.fullName() ?? "Select a musician"}
              </Typography>
              {candidate?.subRuleID ? (
                <Tooltip
                  arrow
                  placement="right"
                  variant="outlined"
                  sx={{ maxWidth: 300 }}
                  size="sm"
                  title={description}
                >
                  <Typography
                    level="body3"
                    color="primary"
                    endDecorator={<i className="fa-regular fa-circle-info"></i>}
                  >
                    Suggested by Rule
                  </Typography>
                </Tooltip>
              ) : (
                []
              )}
              {error ? (
                <Typography level="body3" color="danger">
                  Musician already in use
                </Typography>
              ) : (
                []
              )}
            </Box>
          </Box>
          {candidate ? (
            <IconButton
              color="neutral"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onCandidateChange(null);
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          ) : (
            []
          )}
        </Sheet>
      </ChairAssignMusician>
    </Box>
  );
}
