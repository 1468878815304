import MusicianCommunication_Entity from "./musicianCommunication";
export default class MusicianCommunication extends MusicianCommunication_Entity {
  static fromList(musicianCommunicationsJSON: unknown): Array<MusicianCommunication> {
    const musicianCommunications: MusicianCommunication[] = [];
    if (musicianCommunicationsJSON)
      Array.isArray(musicianCommunicationsJSON) &&
        musicianCommunicationsJSON.forEach((musicianCommunicationJSON) => {
          musicianCommunications.push(new MusicianCommunication(musicianCommunicationJSON));
        });
    return musicianCommunications;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
