import SectionRole_Entity from "./sectionRole";
export default class SectionRole extends SectionRole_Entity {
  static fromList(sectionRolesJSON: unknown): Array<SectionRole> {
    const sectionRoles: SectionRole[] = [];
    if (sectionRolesJSON)
      Array.isArray(sectionRolesJSON) &&
        sectionRolesJSON.forEach((sectionRoleJSON) => {
          sectionRoles.push(new SectionRole(sectionRoleJSON));
        });
    return sectionRoles;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
