import {
  Button,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Typography,
  useTheme,
} from "@mui/joy";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Dictionary } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { useFamilies } from "redux/family/familyHooks";
import { useSections } from "redux/section/sectionHooks";

/**
 *
 * @returns {ReactElement} Instrumentation page
 */

export type InstrumentationSelection = {
  [sectionID: number]: {
    createCount: number;
    altCount?: number;
  };
};

export function Instrumentation({
  subs = false,
  chairs = true,
  header,
  onAdd,
}: {
  subs?: boolean;
  chairs?: boolean;
  header?: ReactElement;
  onAdd: (i: InstrumentationSelection) => void;
}) {
  const { families } = useFamilies();
  const { sections, sectionsMap } = useSections();
  const [selection, setSelection] = useState<InstrumentationSelection>({});
  const open = useSelector(formOpenSelector("instrumentation"));
  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const [selectedFamilyID, setSelectedFamilyID] = useState<number>();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!open) {
      setSelection({});
      setSearchText("");
    }
  }, [open]);

  useEffect(() => {
    if (families.length) {
      setSelectedFamilyID(families[0].id);
    }
  }, [families]);

  const handleChange =
    (sectionID: number, key: "createCount" | "altCount") =>
    (e: React.ChangeEvent<HTMLInputElement> | "plus" | "minus") => {
      const _s = { ...selection };
      let value = 0;
      try {
        value = _s[sectionID][key];
      } catch (error) {}
      if (typeof e !== "string") {
        value = parseInt(e.target.value);
      } else {
        switch (e) {
          case "minus":
            value--;
            if (value < 0) value = 0;
            break;
          case "plus":
            value++;
            break;
        }
      }
      if (_s[sectionID]) {
        _s[sectionID][key] = value;
      } else {
        _s[sectionID] = {
          createCount: 0,
          altCount: 0,
          [key]: value,
        };
      }
      setSelection(_s);
    };

  const familyTotalChairs: Dictionary<number> = {};
  const familyTotalSubs: Dictionary<number> = {};
  let totalChairs = 0;
  let totalSubs = 0;

  for (const key in selection) {
    if (Object.prototype.hasOwnProperty.call(selection, key)) {
      const element = selection[key];
      const sectionID = parseInt(key);
      const section = sectionsMap[sectionID];
      const familyID = section.familyID;
      if (familyTotalChairs[familyID]) {
        familyTotalChairs[familyID] += element.createCount;
      } else {
        familyTotalChairs[familyID] = element.createCount;
      }

      if (familyTotalSubs[familyID]) {
        familyTotalSubs[familyID] += element.altCount;
      } else {
        familyTotalSubs[familyID] = element.altCount;
      }
      totalChairs += element.createCount;
      totalSubs += element.altCount;
    }
  }

  const primeColor = "primary";
  const subColor = "neutral";

  return (
    <DialogClose
      open={open}
      onClose={() =>
        dispatch(setFormOpen({ formID: "instrumentation", isOpen: false }))
      }
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography level="h6">Instrumentation</Typography>
        <Input
          value={searchText}
          startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
          endDecorator={
            <IconButton
              onClick={() => setSearchText("")}
              color="neutral"
              variant="plain"
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          }
          onChange={(e) => setSearchText(e.target.value)}
          size="sm"
          variant="soft"
          placeholder="Search..."
        />
      </DialogTitle>
      {header}
      {!searchText ? (
        <Box>
          <Divider />
          <Box
            sx={{
              pl: 2,
              pr: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography level="body3" sx={{ width: 160 }}>
              Families
            </Typography>
            <Typography level="body3" sx={{ flexGrow: 1, ml: 2 }}>
              Sections
            </Typography>
            <Box sx={{ display: "flex", gap: 3 }}>
              {chairs ? (
                <Typography
                  level="body3"
                  sx={{ width: "92px", textAlign: "center" }}
                >
                  Chairs
                </Typography>
              ) : (
                []
              )}
              {subs ? (
                <Typography
                  level="body3"
                  sx={{ width: "92px", textAlign: "center" }}
                >
                  Subs
                </Typography>
              ) : (
                []
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        []
      )}
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <Box
          sx={{
            width: sm ? 600 : undefined,
            height: sm ? 800 : undefined,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {!searchText ? (
              <Box>
                <List>
                  {families.map((f) => (
                    <ListItemButton
                      color={selectedFamilyID === f.id ? primeColor : "neutral"}
                      selected={selectedFamilyID === f.id}
                      variant={selectedFamilyID === f.id ? "soft" : "plain"}
                      onClick={() => setSelectedFamilyID(f.id)}
                      key={f.id}
                    >
                      <ListItemContent>
                        {f.name}
                        <Box sx={{ display: "flex", gap: 2 }}>
                          {familyTotalChairs[f.id] ? (
                            <Typography
                              startDecorator={
                                subs ? (
                                  <i className="fa-solid fa-star"></i>
                                ) : (
                                  <i className="fa-solid fa-chair"></i>
                                )
                              }
                              level="body3"
                            >
                              {familyTotalChairs[f.id]}
                            </Typography>
                          ) : (
                            []
                          )}
                          {subs && familyTotalSubs[f.id] ? (
                            <Typography
                              startDecorator={
                                <i className="fa-solid fa-arrow-right-to-line"></i>
                              }
                              level="body3"
                            >
                              {familyTotalSubs[f.id]}
                            </Typography>
                          ) : (
                            []
                          )}
                        </Box>
                      </ListItemContent>
                    </ListItemButton>
                  ))}
                </List>
              </Box>
            ) : (
              []
            )}
            <Divider orientation="vertical" />
            <Box sx={{ flexGrow: 1 }}>
              <List>
                {(searchText
                  ? sections.filter(
                      (s) =>
                        s.name
                          .toLowerCase()
                          .indexOf(searchText.toLowerCase()) !== -1
                    )
                  : sections.filter((s) => s.familyID === selectedFamilyID)
                ).map((s) => (
                  <ListItem key={s.id}>
                    <Box sx={{ flexGrow: 1 }}>
                      {s.name}
                      {searchText ? (
                        <Typography level="body4">{s.familyName}</Typography>
                      ) : (
                        []
                      )}
                    </Box>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      {chairs ? (
                        <Input
                          value={selection[s.id]?.createCount ?? 0}
                          slotProps={{
                            input: { style: { textAlign: "center" } },
                          }}
                          onChange={handleChange(s.id, "createCount")}
                          onFocus={(event) => {
                            event.target.select();
                          }}
                          type="tel"
                          startDecorator={
                            <IconButton
                              color="neutral"
                              onClick={() =>
                                handleChange(s.id, "createCount")("minus")
                              }
                            >
                              <i className="fa-solid fa-minus"></i>
                            </IconButton>
                          }
                          endDecorator={
                            <IconButton
                              color="neutral"
                              onClick={() =>
                                handleChange(s.id, "createCount")("plus")
                              }
                            >
                              <i className="fa-solid fa-plus"></i>
                            </IconButton>
                          }
                          size="sm"
                          variant={
                            selection[s.id]?.createCount ?? 0 ? "solid" : "soft"
                          }
                          color={
                            selection[s.id]?.createCount ?? 0
                              ? primeColor
                              : "neutral"
                          }
                          sx={{ width: 92, textAlign: "center" }}
                        />
                      ) : (
                        []
                      )}
                      {subs ? (
                        <Input
                          value={selection[s.id]?.altCount ?? 0}
                          slotProps={{
                            input: { style: { textAlign: "center" } },
                          }}
                          onChange={handleChange(s.id, "altCount")}
                          onFocus={(event) => {
                            event.target.select();
                          }}
                          type="tel"
                          startDecorator={
                            <IconButton
                              color="neutral"
                              onClick={() =>
                                handleChange(s.id, "altCount")("minus")
                              }
                            >
                              <i className="fa-solid fa-minus"></i>
                            </IconButton>
                          }
                          endDecorator={
                            <IconButton
                              color="neutral"
                              onClick={() =>
                                handleChange(s.id, "altCount")("plus")
                              }
                            >
                              <i className="fa-solid fa-plus"></i>
                            </IconButton>
                          }
                          size="sm"
                          variant={
                            selection[s.id]?.altCount ?? 0 ? "solid" : "soft"
                          }
                          color={
                            selection[s.id]?.altCount ?? 0
                              ? subColor
                              : "neutral"
                          }
                          sx={{ width: 92, textAlign: "center" }}
                        />
                      ) : (
                        []
                      )}
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box></Box>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Box>
          <Typography
            startDecorator={
              subs ? (
                <i className="fa-solid fa-star"></i>
              ) : (
                <i className="fa-solid fa-chair"></i>
              )
            }
            level="body3"
          >
            {totalChairs} Chair{totalChairs > 1 ? "s" : ""}
          </Typography>
          {subs ? (
            <Typography
              startDecorator={
                <i className="fa-solid fa-arrow-right-to-line"></i>
              }
              level="body3"
            >
              {totalSubs} Sub{totalSubs > 1 ? "s" : ""}
            </Typography>
          ) : (
            []
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="soft"
            color="neutral"
            onClick={() =>
              dispatch(
                setFormOpen({ formID: "instrumentation", isOpen: false })
              )
            }
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onAdd(selection);
              dispatch(
                setFormOpen({ isOpen: false, formID: "instrumentation" })
              );
            }}
          >
            Add {chairs ? "Chairs" : "Subs"}
          </Button>
        </Box>
      </DialogActions>
    </DialogClose>
  );
}
