/**
 * Email_Entity type guard.
 *
 * @param {any} emailJson Email object from API
 * @returns {boolean} Return true if type is Email_Entity
 */

export default class Email_Entity {
  constructor(emailJson: Email_Entity) {
    try {
      this.id = emailJson.id;
      this.organizationID = emailJson.organizationID;
      this.sentFromName = emailJson.sentFromName;
      this.sentFromEmail = emailJson.sentFromEmail;
      this.refID = emailJson.refID;
      this.token = emailJson.token;
      this.configurationID = emailJson.configurationID;
      this.configurationCode = emailJson.configurationCode;
      this.sent = emailJson.sent;
      this.subject = emailJson.subject;
      this.failCount = emailJson.failCount;
      this.priority = emailJson.priority;
      this.serializedData = emailJson.serializedData;
      this.plainText = emailJson.plainText;
      this.createdAt = emailJson.createdAt;
      this.updatedAt = emailJson.updatedAt;
      this.recipients = emailJson.recipients;
      this.replaceMap = emailJson.replaceMap;
      this.keyword = emailJson.keyword;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }
  id?: number;
  organizationID?: number;
  sentFromName?: string;
  sentFromEmail?: string;
  refID?: number;
  token?: string;
  configurationID?: number;
  configurationCode?: string;
  sent?: boolean;
  subject?: string;
  failCount?: number;
  priority?: number;
  serializedData?: string;
  plainText?: string;
  createdAt?: string;
  updatedAt?: string;
  recipients: {
    bcc: boolean;
    cc: boolean;
    createdAt: string;
    emailAddress: string;
    emailID: number;
    id: number;
    updatedAt: string;
    userUUID?: string;
  }[];
  replaceMap: Map<string, any>;
  keyword?: string;
}
