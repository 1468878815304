import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";

type Error = { message?: string; description?: string; status: number };

interface ErrorState {
  error?: Error;
}

const initialState = {} as ErrorState;

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
  },
});

export const { setError } = errorSlice.actions;

export const errorSelector = createSelector([(s) => s.error.error], (s) => s);
