import { Job } from "entities/job";
import { useMemo } from "react";
import {
  useGetJobQuery,
  useGetJobsQuery,
  useGetWorkSessionActiveJobsQuery,
} from "./jobEndpoints";

export function useJob(jobID: number | undefined) {
  const jobQuery = useGetJobQuery(jobID ?? 0, {
    skip: (jobID ?? 0) <= 0,
  });
  const jobEntity = jobQuery.data;

  return useMemo(() => {
    const ret: typeof jobQuery & { job?: Job | undefined } = {
      ...jobQuery,
    };
    if (jobEntity) ret.job = new Job(jobEntity);
    return ret;
  }, [jobEntity]);
}

export function useWorkSessionActiveJobs(workSessionID: number | undefined) {
  const jobQuery = useGetWorkSessionActiveJobsQuery(workSessionID, {
    skip: (workSessionID ?? 0) <= 0,
  });
  const jobEntitiesMap = jobQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof jobQuery & {
      jobs?: Job[];
      jobsMap?: { [id: number]: Job };
    } = {
      ...jobQuery,
      jobs: [],
      jobsMap: {},
    };
    if (jobEntitiesMap) {
      const jobs = [];
      const jobsMap = {};

      for (const key in jobEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(jobEntitiesMap, key)) {
          const c = jobEntitiesMap[key];
          const job = new Job(c);
          jobs.push(job);
          jobsMap[job.id] = job;
        }
      }
      ret.jobs = jobs;
      ret.jobsMap = jobsMap;
    }

    return ret;
  }, [jobEntitiesMap]);
}

export function useJobs() {
  const jobQuery = useGetJobsQuery();
  const jobEntitiesMap = jobQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof jobQuery & {
      jobs?: Job[];
      jobsMap?: { [id: number]: Job };
    } = {
      ...jobQuery,
      jobs: [],
      jobsMap: {},
    };
    if (jobEntitiesMap) {
      const jobs = [];
      const jobsMap = {};

      for (const key in jobEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(jobEntitiesMap, key)) {
          const c = jobEntitiesMap[key];
          const job = new Job(c);
          jobs.push(job);
          jobsMap[job.id] = job;
        }
      }
      ret.jobs = jobs;
      ret.jobsMap = jobsMap;
    }

    return ret;
  }, [jobEntitiesMap]);
}
