import { Box } from "@mui/material";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setMissionControlMode,
  setMissionControlViewMode,
} from "reducers/rhapsody";
import { useProjectHiringMusicians } from "redux/musician/musicianHooks";
import { useProject } from "redux/project/projectHooks";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";

/**
 *
 * @returns {ReactElement} ProjectLoader page
 */
export function ProjectLoader({ children }: { children }) {
  const params = useParams();
  const readOnly = useReadOnly();
  const projectID = parseInt(params.projectID);
  const { project } = useProject(projectID);
  const { isSuccess: s1 } = useProjectHiringMusicians(
    project && project.version !== 2 ? project?.id : undefined
  );
  const { isSuccess: s2 } = useProjectHiring(
    project && project.version !== 2 ? project?.id : undefined
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (readOnly) {
      dispatch(setMissionControlMode("view"));
    } else if (project?.template) {
      dispatch(setMissionControlMode("edit"));
      dispatch(setMissionControlViewMode("grouped"));
    }
  }, [project]);

  if ((s1 && s2) || project?.version === 2) return children;

  return <Box />;
}
