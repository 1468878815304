import {
  SELECTED_CALENDAR_DAY,
  SET_CALENDAR_OPEN_TO,
  SET_CALENDAR_OPTIONS,
  SET_DARK_MODE,
  SET_DRAWER_OPEN,
  SET_FORM_OPEN,
  SET_PREFERED_WORK_SESSION_PRESENTATION,
  SET_PROJECT_HIRING_SORT_BY,
  SET_TAB_INDEX,
  STORE_RESET_PASSWORD_TOKEN,
} from "constants/app";

export default (
  state = {
    selectedCalendarDay: {},
    calendarOptions: {},
    drawerOpen: false,

    formOpen: {
      rosterConfig: true,
    },
    projectHiringSortBy: "sections",
    workSessionPresentation:
      window.localStorage.getItem("preferedWorkSessionsPresentation") ||
      "calendar",
  },
  action
) => {
  let _s = { ...state };
  switch (action.type) {
    case SET_CALENDAR_OPTIONS:
      _s.calendarOptions = action.options;
      return _s;
    case SET_FORM_OPEN:
      const open = action.open;
      const formID = action.formID;
      _s.formOpen = { ..._s.formOpen, [formID]: open };
      return _s;
    case SET_TAB_INDEX:
      const tabID = action.tabID;
      const tabIndex = action.tabIndex;
      _s.tabIndexes = { ..._s.tabIndexes, [tabID]: tabIndex };
      return _s;
    case SET_PROJECT_HIRING_SORT_BY:
      return { ...state, projectHiringSortBy: action.sortBy };
    case SET_CALENDAR_OPEN_TO:
      return { ...state, calendarOpenTo: action.calendarOpenTo };
    case STORE_RESET_PASSWORD_TOKEN:
      return { ...state, resetPasswordToken: action.token };
    case SELECTED_CALENDAR_DAY:
      const _state = { ...state }; //eslint-disable-line
      _state.selectedCalendarDay[action.body.id] = action.body.date;
      return _state;
    case SET_DRAWER_OPEN:
      return { ...state, drawerOpen: action.drawerOpen };
    case SET_DARK_MODE:
      return { ...state, darkMode: action.token };
    case SET_PREFERED_WORK_SESSION_PRESENTATION:
      window.localStorage.setItem(
        "preferedWorkSessionsPresentation",
        action.presentation
      );
      return { ...state, workSessionPresentation: action.presentation };
    default:
      return state;
  }
};
