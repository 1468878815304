import { Box, Button, Tooltip, Typography } from "@mui/joy";
import { Dictionary } from "@reduxjs/toolkit";
import { HProject } from "entities/hProject";
import { Stage } from "entities/stage";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useUpdateInspectorMutation } from "redux/inspector/inspectorEndpoints";

/**
 *
 * @returns {ReactElement} MusicianTalkbackList page
 */
export function MusicianTalkbackList({
  hProject: _hProject,
  stagesMap: _stagesMap,
}: {
  hProject?: HProject;
  stagesMap?: Dictionary<Stage>;
}) {
  const utils = useSelector(layoutUtilsSelector);
  const hProject = _hProject ?? utils?.hProject;
  const musiciansMap = utils?.musiciansMap ?? {};
  const stagesMap = _stagesMap ?? utils?.stagesMap ?? {};
  const chairs = utils?.chairs ?? [];
  const [updateInspector] = useUpdateInspectorMutation();

  const [read, setRead] = useState<Dictionary<boolean>>({});

  const musicianTalkback = hProject.musicianTalkback();

  useEffect(() => {
    setRead(
      musicianTalkback.reduce((a, v) => {
        a[v.id] = v.success;
        return a;
      }, {})
    );
  }, []);

  const markAllAsRead = () => {
    const _read = {};
    musicianTalkback.forEach((t) => {
      _read[t.id] = true;
      if (!t.success) {
        updateInspector({ id: t.id, body: { success: !read[t.id] } });
      }
    });

    setRead(_read);
  };

  if (Object.keys(read).length === 0) return <Box />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Button
        disabled={Object.values(read).filter((v) => !v).length === 0}
        onClick={markAllAsRead}
        size="sm"
        color="neutral"
      >
        Mark all as read
      </Button>
      {musicianTalkback.map((t) => {
        const _chairs = chairs.filter((c) => c.musicianID === t.musicianID);
        let role = "";
        let section = "";
        let order = "";
        const firstChair = _chairs.length ? _chairs[0] : undefined;
        if (firstChair) {
          role = utils.sectionRolesMap[firstChair.sectionRoleID].name;
          section = utils.sectionsMap[firstChair.sectionID].name;
          order = `${firstChair.sectionOrder}`;
        }
        return (
          <Box
            key={t.id}
            sx={{
              display: "flex",
              gap: 1,
              p: 0.5,
              borderRadius: 8,
              cursor: "pointer",
              "&:hover": {
                background: "#e3f2fd",
              },
            }}
            onClick={() => {
              updateInspector({ id: t.id, body: { success: !read[t.id] } });
              setRead((e) => ({ ...e, [t.id]: !read[t.id] }));
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                height: 27,
              }}
            >
              <Box
                sx={{
                  flexShrink: 0,
                  width: 8,
                  height: 8,
                  background: read[t.id] ? "transparent" : "#2196f3",
                  borderRadius: 8,
                }}
              />
              <MusicianAvatar
                size={25}
                musician={musiciansMap[t.id]}
                musicianID={t.musicianID}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography level="body3">
                  {musiciansMap[t.musicianID]?.fullName() ?? t.createdBy},{" "}
                  <Typography
                    level="body3"
                    sx={{ color: !read[t.id] ? t.stage.color : undefined }}
                    startDecorator={
                      <img
                        src={t.stage.icon}
                        style={{
                          height: 12,
                          filter: read[t.id] ? "grayscale(1)" : undefined,
                        }}
                      />
                    }
                  >
                    {stagesMap[t.stage.id]?.wording()}{" "}
                  </Typography>
                </Typography>
                {role ? (
                  <Typography level="body4">
                    {role}, {section} #{order}
                  </Typography>
                ) : (
                  []
                )}
              </Box>
              <Tooltip
                arrow
                enterDelay={1000}
                enterNextDelay={1000}
                size="sm"
                variant="outlined"
                title={moment(t.createdAt).format("lll")}
              >
                <Typography level="body4">
                  {moment(t.createdAt).fromNow()}
                </Typography>
              </Tooltip>
              <Box>
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    background: !read[t.id] ? t.stage?.color : "#F2F2F2",
                    border: "solid 1px rgba(155,155,155,0.2)",
                    p: "4px",
                    pl: "4px",
                    display: "inline-block",
                    pr: "4px",
                    borderRadius: "8px",
                    color: !read[t.id] ? "white" : undefined,
                    maxWidth: 600,
                    height: "auto",
                    transition: "background .2s, color .2s",
                  }}
                  level="body3"
                >
                  {t.message}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end", flex: 1 }}>
                <Typography
                  level="body4"
                  sx={{
                    textAlign: "right",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.open(
                      `mailTo:${musiciansMap[t.musicianID]?.email}`,
                      "_blank"
                    );
                  }}
                  startDecorator={<i className="fa-solid fa-envelope"></i>}
                >
                  Reply via Email
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
