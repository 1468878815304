// import PropTypes from 'prop-types';
// material-ui
import { Avatar, IconButton, Tooltip } from "@mui/joy";
import { alpha, Grow } from "@mui/material";
import Tilt from "atoms/Tilt";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import React from "react";
import { useNavigate } from "react-router-dom";
// styles

export default function TagCard({
  selected,
  name,
  image,
  editable,
  icon,
  tagID = 0,
}) {
  const [hover, setHover] = React.useState(false);
  const navigate = useNavigate();

  return (
    <div
      style={{ margin: 16, cursor: "pointer", position: "relative" }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Tilt>
        <div
          variant="outlined"
          style={{
            top: 0,
            height: 130,
            width: 230,
            background: !image && alpha("#2196f3", 0.3),
            backgroundImage: image && `url(${image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            borderRadius: "4px",
          }}
        >
          {selected && editable ? (
            <Tooltip title="Open Season" size="sm" variant="outlined">
              <IconButton
                color="primary"
                variant="solid"
                onClick={() =>
                  navigate(`${RouterConfig.seasons}/${tagID}/projects`)
                }
                sx={{
                  position: "absolute",
                  top: "calc(50% - 24px)",
                  right: -10,
                  zIndex: 999,
                }}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </IconButton>
            </Tooltip>
          ) : (
            []
          )}
          {icon ? (
            <div
              style={{
                position: "absolute",
                top: 8,
                left: 32,
                color: "white",
                fontSize: 72,
              }}
            >
              <i className={icon} />
            </div>
          ) : (
            []
          )}
          {hover && editable && (
            <div
              style={{
                position: "absolute",
                top: 56,
                left: 47,
                color: "white",
                opacity: 0.4,
                textShadow: "2px 1px 5px black",
              }}
            >
              Double click to view
            </div>
          )}
          <span
            style={{
              padding: 4,
              marginRight: 4,
              color: "white",
              fonWeight: "bold",
              textShadow: "2px 1px 5px black",
            }}
          >
            {name}
          </span>
        </div>
        {selected ? (
          <div
            style={{
              zIndex: 2,
              height: 130,
              width: 230,
              position: "absolute",
              borderRadius: "4px",
              border: "solid 3px #2196f3",
              top: 0,
            }}
          />
        ) : (
          []
        )}
        {selected && image ? (
          <Grow in>
            <img
              src={image}
              alt={"shadow"}
              style={{
                zIndex: -1,
                height: 130,
                width: 230,
                filter: `blur(15px)`,
                position: "absolute",
                borderRadius: "16px",
                top: 8,
                opacity: 0.5,
                left: 0,
              }}
            />
          </Grow>
        ) : (
          []
        )}
      </Tilt>
    </div>
  );
}
