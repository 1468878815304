import moment from "moment";
import WorkSession_Entity from "./workSession";
import { WorkSessionProjectPiece } from "entities/workSessionProjectPiece";
export default class WorkSession extends WorkSession_Entity {
  static fromList(workSessionsJSON: unknown): Array<WorkSession> {
    const workSessions: WorkSession[] = [];
    if (workSessionsJSON)
      Array.isArray(workSessionsJSON) &&
        workSessionsJSON.forEach((workSessionJSON) => {
          workSessions.push(new WorkSession(workSessionJSON));
        });
    return workSessions;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  getTzName() {
    return this.tzName ?? this.studio.tzName ?? "America/Los_Angeles";
  }

  formatDateRange() {
    if (this.dateFromUTC === this.dateToUTC) {
      return moment.tz(this.dateFromUTC, this.getTzName()).format("ll");
    }
    return `${moment
      .tz(this.dateFromUTC, this.getTzName())
      .format("dd, MMM DD, h:mm A")} - ${moment
      .tz(this.dateToUTC, this.getTzName())
      .format("h:mm A (z)")}`;
  }

  workSessionProjectPieces(
    workSessionProjectPieces: WorkSessionProjectPiece[]
  ) {
    return workSessionProjectPieces.filter(
      (wpp) => wpp.workSessionID === this.id
    );
  }
}
