import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Piece_Entity } from "entities/piece";
import { rhapsodyApi } from "../api/rhapsodyApi";

const piecesAdapter = createEntityAdapter<Piece_Entity>();
const initialState = piecesAdapter.getInitialState();

export const pieceEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getPieces: build.query<
      EntityState<Piece_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `pieces`,
          params: args,
        };
      },
      transformResponse: (responseData: Piece_Entity[]) => {
        return piecesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["pieces"],
    }),
    getPiece: build.query<Piece_Entity, number>({
      query: (id) => `pieces/${id}`,
      providesTags: (result, error, id) => [{ type: "pieces", id }],
    }),
    createPiece: build.mutation<Piece_Entity, Partial<Piece_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `pieces`,
      }),
      invalidatesTags: ["pieces"],
    }),
    updatePiece: build.mutation<
      void,
      { id: number; body: Partial<Piece_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `pieces/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "pieces", id },
        "pieces",
      ],
    }),
    deletePiece: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `pieces/${id}`,
      }),
      invalidatesTags: ["pieces"],
    }),
  }),
});

export const {
  useGetPieceQuery,
  useGetPiecesQuery,
  useCreatePieceMutation,
  useDeletePieceMutation,
  useUpdatePieceMutation,
} = pieceEndpoints;

export default pieceEndpoints;
