import Venue_Entity from "./venue";
export default class Venue extends Venue_Entity {
  static fromList(venuesJSON: unknown): Array<Venue> {
    const venues: Venue[] = [];
    if (venuesJSON)
      Array.isArray(venuesJSON) &&
        venuesJSON.forEach((venueJSON) => {
          venues.push(new Venue(venueJSON));
        });
    return venues;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
