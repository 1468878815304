import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Inspector_Entity } from "entities/inspector";
import { mercuryApi } from "redux/api/mercuryApi";

const inspectorsAdapter = createEntityAdapter<Inspector_Entity>();
const initialState = inspectorsAdapter.getInitialState();

export const inspectorEndpoints = mercuryApi.injectEndpoints({
  endpoints: (build) => ({
    getInspectors: build.query<
      EntityState<Inspector_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `inspectors`,
          params: args,
        };
      },
      transformResponse: (responseData: Inspector_Entity[]) => {
        return inspectorsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["inspectors"],
    }),
    getInspector: build.query<Inspector_Entity, number>({
      query: (id) => `inspectors/${id}`,
      providesTags: (result, error, id) => [{ type: "inspectors", id }],
    }),
    createInspector: build.mutation<
      Inspector_Entity,
      Partial<Inspector_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `inspectors`,
      }),
      invalidatesTags: ["inspectors"],
    }),
    updateInspector: build.mutation<
      void,
      { id: number; body: Partial<Inspector_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `inspectors/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "inspectors", id },
        "inspectors",
      ],
    }),
    deleteInspector: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `inspectors/${id}`,
      }),
      invalidatesTags: ["inspectors"],
    }),
  }),
});

export const {
  useGetInspectorQuery,
  useGetInspectorsQuery,
  useCreateInspectorMutation,
  useDeleteInspectorMutation,
  useUpdateInspectorMutation,
} = inspectorEndpoints;

export default inspectorEndpoints;
