import { Job_Entity } from "entities/job";
import { ProjectAlternate } from "entities/projectAlternate";
import { WorkSession_Entity } from "entities/workSession";
import { WorkSessionSection_Entity } from "entities/workSessionSection";

/**
 * ProjectHiring_Entity type guard.
 *
 * @param {any} projectHiringJson ProjectHiring object from API
 * @returns {boolean} Return true if type is ProjectHiring_Entity
 */

export default class ProjectHiring_Entity {
  constructor(projectHiringJson: ProjectHiring_Entity) {
    try {
      this.id = projectHiringJson.id;
      this.jobs = projectHiringJson.jobs;
      this.musicians = projectHiringJson.musicians;
      this.sessions = projectHiringJson.sessions;
      this.sections = projectHiringJson.sections;
      this.key = projectHiringJson.key;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }
  id: number;
  jobs: Job_Entity[];
  musicians: ProjectAlternate[];
  sessions: WorkSession_Entity[];
  sections: WorkSessionSection_Entity[];
  key?: string;
}
