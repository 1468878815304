import ProjectSection_Entity from "./projectSection";
export default class ProjectSection extends ProjectSection_Entity {
  static fromList(projectSectionsJSON: unknown): Array<ProjectSection> {
    const projectSections: ProjectSection[] = [];
    if (projectSectionsJSON)
      Array.isArray(projectSectionsJSON) &&
        projectSectionsJSON.forEach((projectSectionJSON) => {
          projectSections.push(new ProjectSection(projectSectionJSON));
        });
    return projectSections;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
