import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { useProjects } from "redux/project/projectHooks";
import { useCreateProjectTagMutation } from "redux/projectTag/projectTagEndpoints";
import { useProjectTags } from "redux/projectTag/projectTagHooks";
import { useTag } from "redux/tag/tagHooks";

/**
 *
 * @returns {ReactElement} MusicianTalkback page
 */
export function AddProjectToSeason() {
  const open = useSelector(formOpenSelector("addProjectToSeason"));
  const params = useParams();
  const [searchText, setSearchText] = useState("");
  const tagID = params.tagID ? parseInt(params.tagID) : 0;
  const { tag } = useTag(tagID);
  const dispatch = useDispatch();
  const [projectIDs, setProjectIDs] = useState<number[]>([]);
  const { projects } = useProjects();
  const { projectTags } = useProjectTags();
  const [createProjectTag] = useCreateProjectTagMutation();

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "addProjectToSeason" }));
  };

  let rows = projects
    .filter(
      (p) =>
        projectTags.findIndex(
          (pt) => pt.projectID === p.id && pt.tagID === tagID
        ) === -1
    )
    .sort((a, b) => b.id - a.id);

  if (searchText)
    rows = rows.filter(
      (p) => p.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );

  const addProjectToSeason = () => {
    projectIDs.forEach((projectID) => {
      createProjectTag({ projectID, tagID });
    });
    setProjectIDs([]);
    setSearchText("");
    onClose();
  };

  return (
    <DialogClose open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography level="h6">
          Add Project to Season{" "}
          <Typography color="primary">{tag?.name}</Typography>
        </Typography>
        <Input
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          size="sm"
          autoFocus
          color="neutral"
          slotProps={{
            input: {
              autoCapitalize: "off",
              autocorrect: "off",
              autocomplete: "off",
              spellCheck: "false",
            },
          }}
          variant="soft"
          placeholder="Search..."
          startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
          endDecorator={
            searchText ? (
              <IconButton
                color="neutral"
                variant="plain"
                onClick={() => setSearchText("")}
              >
                <i className="fa-solid fa-xmark"></i>
              </IconButton>
            ) : (
              []
            )
          }
        />
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <List>
          {rows.map((i) => {
            const checked = projectIDs.includes(i.id);
            return (
              <ListItemButton
                selected={checked}
                onClick={(e) => {
                  if (checked) {
                    setProjectIDs((p) => p.filter((e) => e !== i.id));
                  } else {
                    setProjectIDs((p) => [...p, i.id]);
                  }
                }}
                key={i.id}
              >
                <ListItemDecorator>
                  <Checkbox checked={projectIDs.includes(i.id)} />
                </ListItemDecorator>
                {i.name} {i.id}
              </ListItemButton>
            );
          })}
        </List>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="neutral" variant="soft">
          Close
        </Button>
        {projectIDs.length ? (
          <Button onClick={addProjectToSeason}>
            Import {projectIDs.length} Project{projectIDs.length > 1 ? "s" : ""}
          </Button>
        ) : (
          []
        )}
      </DialogActions>
    </DialogClose>
  );
}
