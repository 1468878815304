import { Alert, Box, Button, Input, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { EMAIL_REGEXP } from "config/regexp";
import {
  mezzo,
  overture,
  useMySubscription,
} from "hooks/subscription/restrictionHooks";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  setDescription420,
  setFormOpen,
} from "reducers/rhapsody";
import { useInviteMemberMutation } from "redux/organization/organizationEndpoints";
import { useCurrentUser, useOrganizationUsers } from "redux/user/userHook";

/**
 *
 * @returns {ReactElement} FormOrganizationUser page
 */
export function FormOrganizationUser() {
  const organizationUserOpen = useSelector(
    formOpenSelector("organizationUser")
  );
  const subscription = useMySubscription();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { data: currentUser } = useCurrentUser();
  const { users } = useOrganizationUsers(currentUser?.organizationID);
  const [inviteMember] = useInviteMemberMutation();
  const onClose = () =>
    dispatch(setFormOpen({ formID: "organizationUser", isOpen: false }));

  let color;
  let endDecorator;
  if (email && EMAIL_REGEXP.test(email)) {
    color = "success";
    endDecorator = "Valid email";
  }
  if (email && !EMAIL_REGEXP.test(email)) {
    color = "danger";
    endDecorator = "Email not valid";
  }

  let userLeft;
  if (subscription === mezzo) {
    userLeft = 2 - users?.length;
  }

  if (subscription === overture) {
    userLeft = 0;
  }

  const invite = () => {
    if (userLeft !== undefined && userLeft < 1) {
      dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));
      dispatch(
        setDescription420(
          "Your current plan doesn't allow you to invite more team members."
        )
      );
    } else {
      inviteMember({
        inviteEmail: email,
        organizationID: currentUser?.organizationID,
      })
        .unwrap()
        .then(close)
        .catch((error) => setError(error?.data?.description));
    }
  };

  return (
    <DialogClose onClose={onClose} open={organizationUserOpen}>
      <DialogTitle> Invite Team Member</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Input
            autoFocus
            fullWidth
            size="lg"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            type="email"
            startDecorator="Email:"
            placeholder="Member's email address"
            endDecorator={
              <Typography color={color} level="body3">
                {endDecorator}
              </Typography>
            }
            color={color}
          />
          <Typography level="body2">
            An invite email will be sent to this person with instructions to set
            up the account.
          </Typography>
          {error ? <Alert color="warning">{error}</Alert> : []}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="neutral" variant="soft">
          Cancel
        </Button>
        <Button onClick={invite} disabled={!EMAIL_REGEXP.test(email)}>
          Invite
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
