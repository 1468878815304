import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Musician_Entity } from "entities/musician";
import { WorkSession_Entity } from "entities/workSession";
import { mapToArray } from "helpers";
import { RootState } from "store";

type DisplayType = "sections" | "alphabetical" | "removed";

interface ProjectHiringState {
  selectedMusicianMap: { [k: number]: Musician_Entity };
  selectedSessionMap: { [k: number]: WorkSession_Entity };
  projectID?: number;
  display: DisplayType;
  grouped: boolean;
}

const initialState = {
  selectedMusicianMap: {},
  selectedSessionMap: {},
  display: "sections",
  grouped: window.localStorage.getItem("projectHiringGrouped") === "true",
} as ProjectHiringState;

export const projectHiringSlice = createSlice({
  name: "projectHiring",
  initialState,
  reducers: {
    setProjectHiringSelection(state, action: PayloadAction<DisplayType>) {
      state.display = action.payload;
    },
    setProjectHiringGrouped(state, action: PayloadAction<boolean>) {
      state.grouped = action.payload;
    },
    projectHiring_UnselectAll_WorkSessions(state) {
      state.selectedSessionMap = {};
    },
    projectHiring_Select_WorkSessions(
      state,
      action: PayloadAction<WorkSession_Entity[]>
    ) {
      const workSessions = action.payload;
      workSessions.forEach((m) => {
        state.selectedSessionMap[m.id] = m;
      });
    },
    projectHiring_Unselect_WorkSessions(
      state,
      action: PayloadAction<WorkSession_Entity[]>
    ) {
      const workSessions = action.payload;
      workSessions.forEach((m) => {
        delete state.selectedSessionMap[m.id];
      });
    },
    projectHiring_UnselectAll_Musicians(state) {
      state.selectedMusicianMap = {};
    },
    projectHiring_Select_Musicians(
      state,
      action: PayloadAction<Musician_Entity[]>
    ) {
      const musicians = action.payload;
      musicians.forEach((m) => {
        state.selectedMusicianMap[m.id] = m;
      });
    },
    projectHiring_Unselect_Musicians(
      state,
      action: PayloadAction<Musician_Entity[]>
    ) {
      const musicians = action.payload;
      musicians.forEach((m) => {
        delete state.selectedMusicianMap[m.id];
      });
    },
  },
});

export const {
  setProjectHiringSelection,
  setProjectHiringGrouped,
  projectHiring_UnselectAll_Musicians,
  projectHiring_Select_Musicians,
  projectHiring_Unselect_Musicians,
  projectHiring_UnselectAll_WorkSessions,
  projectHiring_Select_WorkSessions,
  projectHiring_Unselect_WorkSessions,
} = projectHiringSlice.actions;

export const projectHiringDisplaySelector = createSelector(
  (s: RootState) => s.projectHiring.display,
  (s) => s
);

export const projectHiringGroupedSelector = createSelector(
  (s: RootState) => s.projectHiring.grouped,
  (s) => s
);

export const projectHiringSelectionSelector = createSelector(
  (s: RootState) => s.projectHiring.selectedMusicianMap,
  (s: RootState) => s.projectHiring.selectedSessionMap,
  (selectedMusicianMap, selectedSessionMap) => ({
    selectedMusicians: mapToArray(selectedMusicianMap),
    selectedSessions: mapToArray(selectedSessionMap),
    selectedMusicianMap,
    selectedSessionMap,
  })
);
