import {
  Button,
  IconButton,
  Input,
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Piece } from "entities/piece";
import { ProjectPiece_Entity } from "entities/projectPiece";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { useMySubscription } from "hooks/subscription/restrictionHooks";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setDescription420,
  setFormOpen,
  setRetrieveInstrumentationForProjectPieceID,
  setSelectedProjectPieceID,
  setTmpPieceName,
} from "reducers/rhapsody";
import { usePieces } from "redux/piece/pieceHooks";
import {
  useCreateProjectPieceMutation,
  useLazyGetProjectPiecesQuery,
} from "redux/projectPiece/projectPieceEndpoints";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useMissionControlSelectAll } from "../ProjectMissionControl/utils";

/**
 *
 * @returns {ReactElement} ProjectPieceAdder page
 */
export function ProjectPieceAdder() {
  const open = useSelector(formOpenSelector("projectPieceAdded"));
  const [createProjectPiece, { data }] = useCreateProjectPieceMutation();
  const projectID = useSelector(selectedProjectIDSelector);
  const dispatch = useDispatch();
  const askQuestion = useAskQuestion();
  const { projectPieces } = useProjectPieces(projectID);
  const { selectProjectPiece } = useMissionControlSelectAll();
  const [getProjectPieces] = useLazyGetProjectPiecesQuery();
  const subscription = useMySubscription();

  useEffect(() => {
    if (data?.id) {
      dispatch(setSelectedProjectPieceID(data.id));
    }
  }, [data]);

  const create = (p: Piece) => {
    if (subscription === "overture" && projectPieces.length >= 1) {
      dispatch(setDescription420("You plan only allows 1 piece in a project."));
      dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
      return;
    }

    if (subscription === "mezzo" && projectPieces.length >= 3) {
      dispatch(
        setDescription420("You plan only allows 3 pieces in a project.")
      );
      dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
      return;
    }

    createProjectPiece({
      pieceID: p.id,
      projectID,
    })
      .unwrap()
      .then((e) => {
        selectProjectPiece(e.id);
        getProjectPieces({
          filters: JSON.stringify([
            {
              value: p.id,
              comparison: "eq",
              name: "project_pieces.pieceID",
            },
          ]),
        })
          .unwrap()
          .then((u) => {
            const ids = u.ids.reduce((a, v) => {
              if (parseInt(v.toString()) !== projectID)
                a.push(parseInt(v.toString()));
              return a;
            }, []);
            if (ids.length > 1) {
              dispatch(setRetrieveInstrumentationForProjectPieceID(e.id));
              dispatch(
                setFormOpen({
                  isOpen: true,
                  formID: "retrieveInstrumentationForPiece",
                })
              );
            } else {
              dispatch(setFormOpen({ isOpen: true, formID: "projectProgram" }));
            }
          });
      });
  };

  const handleCreate = (p: Piece) => {
    if (projectPieces?.findIndex((pp) => pp.pieceID === p.id) >= 0) {
      askQuestion("Are you sure?", ["Cancel", "Add again"], {
        subtitle: "This Piece is already in this Project.",
      }).then((i) => {
        if (i == 1) create(p);
      });
    } else {
      create(p);
    }
    onClose();
  };

  const onClose = () =>
    dispatch(setFormOpen({ isOpen: false, formID: "projectPieceAdded" }));

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", gap: 1 }}>
        Add Piece to Project
      </DialogTitle>
      <PieceSelect onSelect={handleCreate} projectPieces={projectPieces} />
      <DialogActions sx={{ justifyContent: "start" }}>
        <Button color="neutral" variant="soft" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </DialogClose>
  );
}

export function PieceSelect({
  onSelect,
  projectPieces,
  selectedPieceID,
}: {
  onSelect: (piece: Piece) => void;
  projectPieces?: ProjectPiece_Entity[];
  selectedPieceID?: number;
}) {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  let filters: any = null;
  if (searchText)
    filters = [
      {
        name: "pieces.name",
        comparison: "like",
        value: searchText.toLocaleLowerCase(),
        subFilters: [
          {
            name: "pieces.composer",
            comparison: "like",
            value: searchText.toLocaleLowerCase(),
            orOperator: true,
          },
          {
            name: "pieces.arranger",
            comparison: "like",
            value: searchText.toLocaleLowerCase(),
            orOperator: true,
          },
          {
            name: "pieces.edition",
            comparison: "like",
            value: searchText.toLocaleLowerCase(),
            orOperator: true,
          },
        ],
      },
    ];
  const { pieces } = usePieces({ filters: JSON.stringify(filters) });

  return (
    <Box
      sx={{
        width: 450,
        height: 600,
        display: "flex",
        p: 1,
        flexDirection: "column",
      }}
    >
      <Input
        value={searchText}
        autoFocus
        onChange={(e) => setSearchText(e.target.value)}
        startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
        endDecorator={
          <IconButton
            color="neutral"
            variant="plain"
            onClick={(e) => setSearchText("")}
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        }
        size="sm"
        variant="soft"
        placeholder="Search for an existing Piece..."
      />
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          minHeight: "auto",
          height: 0,
          display: "flex",
          alignItems: "stretch",
        }}
      >
        {pieces.length === 0 && searchText ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>No Match</Typography>
            <Typography level="body3">
              Looks like your piece collection doesn't have this one.
              <br />
              Click the button bellow to add it.
            </Typography>
            <br />
            <i className="fa-solid fa-arrow-down"></i>
          </Box>
        ) : (
          <Box sx={{ flex: 1 }}>
            <List size="sm">
              {pieces
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((p) => (
                  <ListItemButton
                    selected={selectedPieceID === p.id}
                    variant={selectedPieceID === p.id ? "soft" : undefined}
                    onClick={() => onSelect(p)}
                    key={p.id}
                  >
                    <ListItemContent>
                      <Typography sx={{ fontWeight: 600 }} level="body2">
                        {p.name}
                      </Typography>
                      <Typography sx={{ opacity: 0.6 }} level="body4">
                        {p.composer}
                      </Typography>
                    </ListItemContent>
                    <ListItemDecorator>
                      {projectPieces?.findIndex((pp) => pp.pieceID === p.id) >=
                      0 ? (
                        <Typography color="success">
                          <i className="fa-solid fa-check"></i>
                        </Typography>
                      ) : (
                        []
                      )}
                    </ListItemDecorator>
                  </ListItemButton>
                ))}
            </List>
          </Box>
        )}
      </Box>
      <Button
        onClick={() => {
          dispatch(setTmpPieceName(searchText));
          dispatch(setFormOpen({ formID: "projectPieceAdded", isOpen: false }));
          dispatch(setFormOpen({ formID: "projectPiece", isOpen: false }));
          dispatch(setFormOpen({ formID: "piece", isOpen: true }));
        }}
        size="sm"
        variant={pieces.length === 0 && searchText ? "solid" : "soft"}
        color={pieces.length === 0 && searchText ? "primary" : "neutral"}
      >
        {searchText ? `+ Add ${searchText}` : `+ New Piece`}
      </Button>
    </Box>
  );
}
