import { Box, Divider } from "@mui/joy";
import { Skeleton, Typography } from "@mui/material";
import { useProjectMercury } from "redux/projectMercury/projectMercuryHooks";
import { useWorkSessionMercury } from "redux/workSessionMercury/workSessionMercuryHooks";
import Chart from "react-apexcharts";
import { useProjectActionsRequired } from "features/projects/useProjectHooks";

/**
 *
 * @returns {ReactElement} MercuryWidget page
 */
export function MercuryWidget({
  projectID,
  workSessionID,
}: {
  projectID?: number;
  workSessionID?: number;
}) {
  const { workSessionMercury, isLoading: l1 } =
    useWorkSessionMercury(workSessionID);
  const { projectMercury, isLoading: l2 } = useProjectMercury(projectID);
  const { isLoading, hasActions, musicianJobs } =
    useProjectActionsRequired(projectID);

  const stats = workSessionMercury ?? projectMercury;

  if (l1 || l2)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton variant="circular" width={170} height={170} />
        <Skeleton width={100} />
        <Skeleton width={100} />
        <Skeleton width={100} />
      </Box>
    );

  const labels =
    stats?.currentStages?.reduce((accumulator, currentV) => {
      return [...accumulator, currentV.name || "Unkown"];
    }, []) ?? [];

  const colors =
    stats?.currentStages?.reduce(
      (accumulator, currentV) => [...accumulator, currentV.color || "#bdbdbd"],
      []
    ) ?? [];

  const series =
    stats?.currentStages?.reduce(
      (accumulator, currentV) => [...accumulator, currentV.jobCount],
      []
    ) ?? [];

  const totalJob = stats?.currentStages
    ?.filter((s) => s.jobCount > 0)
    .reduce((a, v) => {
      a += v.jobCount;
      return a;
    }, 0);

  return (
    <Box>
      <Chart
        options={{
          labels,
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              expandOnClick: false,
              customScale: 0.8,
            },
          },
          stroke: {
            show: false,
            width: 0,
          },
          tooltip: {
            enabled: true,
            fillSeriesColor: false,
            y: {
              formatter: (val) => `${Math.round((val / totalJob) * 100)}%`,
            },
          },
          legend: {
            show: false,
          },
          colors,
        }}
        series={series}
        type={"donut"}
        height={230}
      />
      {stats?.currentStages
        ?.filter((s) => s.jobCount > 0)
        ?.map((s) => (
          <Box key={s.id}>
            <Typography
              variant="caption"
              color="textPrimary"
              style={{
                fontSize: 12,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              {s.icon ? (
                <img src={s.icon} style={{ height: 12, paddingRight: 8 }} />
              ) : (
                <i className="fad fa-chair" style={{ paddingRight: 8 }}></i>
              )}{" "}
              {s.jobCount} {s.name}
            </Typography>
          </Box>
        ))}
      {stats?.formerStages?.length ? (
        <div style={{ marginTop: 20 }}>
          <Divider />
          <Typography variant="caption" color="textSecondary">
            Previously removed
          </Typography>
          {stats?.formerStages?.map((s) => (
            <Box key={s.id}>
              <Typography
                variant="caption"
                color="textPrimary"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {s.icon ? (
                  <img src={s.icon} style={{ height: 12, paddingRight: 8 }} />
                ) : (
                  <i className="fad fa-chair" style={{ paddingRight: 8 }}></i>
                )}{" "}
                {s.jobCount} {s.name}
              </Typography>
            </Box>
          ))}
        </div>
      ) : (
        []
      )}
    </Box>
  );
}
