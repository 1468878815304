import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { MusicianCommunication_Entity } from "entities/musicianCommunication";
import { rhapsodyApi } from "../api/rhapsodyApi";

const musicianCommunicationsAdapter =
  createEntityAdapter<MusicianCommunication_Entity>();
const initialState = musicianCommunicationsAdapter.getInitialState();

export const musicianCommunicationEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getMusicianCommunications: build.query<
      EntityState<MusicianCommunication_Entity>,
      number
    >({
      query: (id) => `musicians/${id}/mercury`,
      transformResponse: (responseData: MusicianCommunication_Entity[]) => {
        return musicianCommunicationsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["musicianCommunications"],
    }),
  }),
});

export const { useGetMusicianCommunicationsQuery } =
  musicianCommunicationEndpoints;

export default musicianCommunicationEndpoints;
