import { Typography } from "@mui/joy";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { layoutUtilsSelector } from "reducers/v2/missionControl";

export function PieceInfo({ projectPieceID }: { projectPieceID?: number }) {
  const utils = useSelector(layoutUtilsSelector);
  const projectPiece = utils.projectPiecesMap[projectPieceID];
  const piece = utils.piecesMap[projectPiece?.pieceID];
  return (
    <Box sx={{ lineHeight: 0 }}>
      <Typography
        sx={{
          fontWeight: "normal",
          color: "inherit",
        }}
        level="body2"
      >
        {piece?.name ?? "Seating assignment"}
      </Typography>
      <br />
      <Typography
        sx={{
          fontWeight: "normal",
          color: "inherit",
        }}
        level="body3"
      >
        {piece?.composer?.length > 0 ? piece?.composer : "Composer not set"}
      </Typography>
    </Box>
  );
}
