/**
 * ProjectRoster_Entity type guard.
 *
 * @param {any} projectRosterJson ProjectRoster object from API
 * @returns {boolean} Return true if type is ProjectRoster_Entity
 */
function validator(
  projectRosterJson: unknown
): projectRosterJson is ProjectRoster_Entity {
  if (typeof projectRosterJson === "object" && projectRosterJson != null) {
    ProjectRoster_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(projectRosterJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class ProjectRoster_Entity {
  static requiredFields = ["id"];

  constructor(projectRosterJson: unknown) {
    if (validator(projectRosterJson)) {
      this.id = projectRosterJson.id;
      this.projectID = projectRosterJson.projectID;
      this.name = projectRosterJson.name;
      this.publicToken = projectRosterJson.publicToken;
      this.options = projectRosterJson.options;
      this.createdAt = projectRosterJson.createdAt;
      this.updatedAt = projectRosterJson.updatedAt;
    } else
      throw new Error(
        `Failed to create new instance of ${ProjectRoster_Entity.name}`
      );
  }

  id: number;
  projectID: number;
  name: string;
  publicToken: string;
  options: string;
  createdAt: string;
  updatedAt: string;
}
