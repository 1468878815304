/**
 * Selection_Entity type guard.
 *
 * @param {any} selectionJson Selection object from API
 * @returns {boolean} Return true if type is Selection_Entity
 */

import { Dictionary } from "@reduxjs/toolkit";

export default class Selection_Entity {
  constructor(selectionJson: Selection_Entity) {
    try {
      this.selectedWorkSessionIDs = selectionJson.selectedWorkSessionIDs;
      this.selectedProjectPieceIDs = selectionJson.selectedProjectPieceIDs;
    } catch (e) {
      console.log(`Failed to create new instance of ${Selection_Entity.name}`);
    }
  }

  selectedWorkSessionIDs: Dictionary<boolean>;
  selectedProjectPieceIDs: Dictionary<boolean>;
}
