/**
 * MusicianCommunication_Entity type guard.
 *
 * @param {any} musicianCommunicationJson MusicianCommunication object from API
 * @returns {boolean} Return true if type is MusicianCommunication_Entity
 */

export default class MusicianCommunication_Entity {
  static requiredFields = ["id"];

  constructor(musicianCommunicationJson: MusicianCommunication_Entity) {
    try {
      this.id = musicianCommunicationJson.id;
      this.jobID = musicianCommunicationJson.jobID;
      this.nudgeCount = musicianCommunicationJson.nudgeCount;
      this.projectID = musicianCommunicationJson.projectID;
      this.projectName = musicianCommunicationJson.projectName;
      this.createdAt = musicianCommunicationJson.createdAt;
      this.stageID = musicianCommunicationJson.stageID;
      this.workSessionID = musicianCommunicationJson.workSessionID;
      this.createdBy = musicianCommunicationJson.createdBy;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }
  id: number;
  jobID: number;
  nudgeCount: number;
  projectID: number;
  projectName: string;
  createdAt: string;
  stageID: number;
  workSessionID: number;
  createdBy: string;
}
