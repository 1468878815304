import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { SectionInstrument_Entity } from "entities/sectionInstrument";
import { museApi } from "redux/api/museApi";

const sectionInstrumentsAdapter =
  createEntityAdapter<SectionInstrument_Entity>();
const initialState = sectionInstrumentsAdapter.getInitialState();

export const sectionInstrumentEndpoints = museApi.injectEndpoints({
  endpoints: (build) => ({
    getSectionInstruments: build.query<
      EntityState<SectionInstrument_Entity>,
      void
    >({
      query: () => `sectionInstruments`,
      transformResponse: (responseData: SectionInstrument_Entity[]) => {
        return sectionInstrumentsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["sectionInstruments"],
    }),
    getSectionInstrument: build.query<SectionInstrument_Entity, number>({
      query: (id) => `sectionInstruments/${id}`,
      providesTags: (result, error, id) => [{ type: "sectionInstruments", id }],
    }),
    createSectionInstrument: build.mutation<
      SectionInstrument_Entity,
      Partial<SectionInstrument_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `sectionInstruments`,
      }),
      invalidatesTags: ["sectionInstruments"],
    }),
    updateSectionInstrument: build.mutation<
      void,
      { id: number; body: Partial<SectionInstrument_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `sectionInstruments/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "sectionInstruments", id },
        "sectionInstruments",
      ],
    }),
    deleteSectionInstrument: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `sectionInstruments/${id}`,
      }),
      invalidatesTags: ["sectionInstruments"],
    }),
  }),
});

export const {
  useGetSectionInstrumentQuery,
  useGetSectionInstrumentsQuery,
  useCreateSectionInstrumentMutation,
  useDeleteSectionInstrumentMutation,
  useUpdateSectionInstrumentMutation,
} = sectionInstrumentEndpoints;

export default sectionInstrumentEndpoints;
