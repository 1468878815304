import { Typography as JoyTypography } from "@mui/joy";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogClose from "atoms/DialogClose/DialogClose";
// material-ui

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import { Button, Input, Textarea } from "@mui/joy";
import { Box } from "@mui/system";
import { Piece_Entity } from "entities/piece";
import { useMySubscription } from "hooks/subscription/restrictionHooks";
import {
  formOpenSelector,
  selectedPieceIDSelector,
  selectedProjectIDSelector,
  setDescription420,
  setFormOpen,
  setSelectedPieceID,
  setTabIndex,
  setTmpPieceName,
  tmpPieceNameSelector,
} from "reducers/rhapsody";
import {
  useCreatePieceMutation,
  useDeletePieceMutation,
  useUpdatePieceMutation,
} from "redux/piece/pieceEndpoints";
import { usePiece } from "redux/piece/pieceHooks";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";

export default function FormPiece() {
  const [loading, setLoading] = useState(false);
  const selectedPieceID = useSelector(selectedPieceIDSelector);
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const { piece: _piece } = usePiece(selectedPieceID);
  const tmpPieceName = useSelector(tmpPieceNameSelector);
  const [createPiece] = useCreatePieceMutation();
  const [deletePiece] = useDeletePieceMutation();
  const [updatePiece] = useUpdatePieceMutation();
  const formOpen = useSelector(formOpenSelector("piece"));
  const [piece, setPiece] = useState<Partial<Piece_Entity>>({ name: "" });
  // const handleSoloProjectPiece = useSoloProjectPiece();
  const subscription = useMySubscription();
  const { projectPieces } = useProjectPieces(selectedProjectID);

  useEffect(() => {
    if (tmpPieceName) {
      setPiece({ name: tmpPieceName });
      dispatch(setTmpPieceName(""));
    }
  }, [tmpPieceName]);

  useEffect(() => {
    if (_piece?.id) {
      setPiece(_piece);
    }
  }, [_piece]);

  const dispatch = useDispatch();

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setPiece((s) => ({ ...s, [name]: value }));
  };

  const save = async () => {
    if (piece?.id) {
      updatePiece({ id: piece.id, body: { ...piece } });
      onClose();
    } else {
      createPiece(piece)
        .unwrap()
        .then((p) => {
          if (selectedProjectID) {
            if (subscription === "overture" && projectPieces.length >= 1) {
              dispatch(
                setDescription420("You plan only allows 1 piece in a project.")
              );
              dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
              return;
            }

            if (subscription === "mezzo" && projectPieces.length >= 3) {
              dispatch(
                setDescription420("You plan only allows 3 pieces in a project.")
              );
              dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
              return;
            }

            onClose();
          } else {
            onClose();
          }
        });
    }
  };

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "piece" }));
    dispatch(setSelectedPieceID());
  };

  const onDelete = async () => {
    deletePiece(piece.id);
    dispatch(setFormOpen({ isOpen: false, formID: "piece" }));
    dispatch(setTabIndex({ tabID: "musicians", value: 0 }));
  };

  return (
    <DialogClose
      onClose={onClose}
      open={formOpen}
      scroll="paper"
      aria-labelledby="responsive-dialog-title"
    >
      {loading ? (
        <div>
          <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogContent>
            <div
              style={{
                background: "#e3f2fd",
                padding: 16,
                borderRadius: "16px",
                textAlign: "center",
              }}
            >
              <i
                style={{ fontSize: 80, color: "#001E3C" }}
                className="fa-duotone fa-music-note"
              ></i>
              <Typography style={{ color: "#001E3C" }} variant="h6">
                {piece?.id ? "Edit" : "New"} Piece
              </Typography>
              <Typography
                style={{ color: "#001E3C", opacity: 0.8 }}
                variant="body2"
              >
                A musical work that is intended to be played
              </Typography>
            </div>

            <Box
              sx={{ display: "flex", gap: 1, flexDirection: "column", mt: 2 }}
            >
              <Input
                startDecorator="Name:"
                variant="outlined"
                endDecorator="Required"
                fullWidth
                slotProps={{ input: { autoFocus: true } }}
                value={piece.name}
                onChange={handleChange("name")}
              />
              <Input
                startDecorator="Composer:"
                variant="outlined"
                fullWidth
                value={piece.composer}
                onChange={handleChange("composer")}
              />
              <Input
                startDecorator="Arranger:"
                variant="outlined"
                fullWidth
                value={piece.arranger}
                onChange={handleChange("arranger")}
              />
              <Input
                startDecorator="Edition:"
                variant="outlined"
                fullWidth
                value={piece.edition}
                onChange={handleChange("edition")}
              />
              <Textarea
                placeholder="Anything to write down about this Piece?"
                sx={{ width: "100%", background: "rgba(255,235,59,0.1)" }}
                onChange={handleChange("notes")}
                value={piece?.notes ?? ""}
                size="sm"
                endDecorator={
                  <JoyTypography level="body4">
                    Not visible by musicians
                  </JoyTypography>
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {piece?.id ? (
              <Button variant="soft" color="danger" onClick={onDelete}>
                Delete
              </Button>
            ) : (
              []
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="soft" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={piece.name.length === 0} onClick={save}>
              {piece?.id ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </>
      )}
    </DialogClose>
  );
}
