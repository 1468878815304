import {
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  List,
  ListItemButton,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Box, ClickAwayListener, alpha } from "@mui/material";
import { Musician } from "entities/musician";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar/MusicianAvatar";
import { SuggestedBySubRule } from "features/subs/subRuleCard";
import { getCurrentStageName } from "helpers";
import { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  missionControlModeSelector,
  selectedMusicianIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  assignmentIDForLookupSelector,
  layoutInternalSelector,
  layoutUtilsSelector,
  setAssignmentIDForLookup,
} from "reducers/v2/missionControl";
import {
  useMusicianSuggestions,
  useMusicians,
} from "redux/musician/musicianHooks";
import { CHAIR_WIDTH } from "./position";

/**
 *
 * @returns {ReactElement} ChairAssignMusician page
 */
export function ChairAssignMusician({
  children,
  onSelect,
  chairID,
  assignmentID,
  whosnext,
  musicianID,
}: {
  children: ReactElement;
  onSelect?: (musicianID: number) => void;
  chairID?: number;
  assignmentID?: number;
  whosnext?: boolean;
  musicianID?: number;
}) {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const missionControlMode = useSelector(missionControlModeSelector);
  const assignmentIDforLoopup = useSelector(assignmentIDForLookupSelector);
  const selectedMusicianID = useSelector(selectedMusicianIDSelector);
  const { musicians } = useMusicianSuggestions(chairID, !open || !chairID);
  const utils = useSelector(layoutUtilsSelector);
  const internal = useSelector(layoutInternalSelector);
  const dispatch = useDispatch();
  const askQuestion = useAskQuestion();

  const {
    musicians: otherMusicians,
    isLoading: l2,
    isFetching: f2,
  } = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.firstName",
          comparison: "like",
          value: searchText.trim().toLowerCase(),
          subFilters: [
            {
              name: "musicians.nickName",
              comparison: "like",
              value: searchText.trim().toLowerCase(),
              orOperator: true,
            },
            {
              name: "musicians.lastName",
              comparison: "like",
              value: searchText.trim().toLowerCase(),
              orOperator: true,
            },
          ],
        },
      ]),
    },
    { skip: searchText.length === 0 && !musicianID }
  );

  const featuredMusicianID =
    musicians?.length && !musicians[0].settled && musicians[0].subRuleID
      ? musicians[0].id
      : undefined;

  const musicianMenuItem = (m: Musician, other?: boolean, index?: number) => {
    let mercuryStage = m?.mercuryStage;
    if (!mercuryStage && other) {
      mercuryStage = musicians?.find((e) => e.id === m.id)?.mercuryStage;
    }

    const duplicateWarning = () => {
      setOpen(false);
      askQuestion("Duplicate Warning", ["OK"], {
        subtitle: (
          <Typography>
            The musician cannot be place on the chair because they are already
            playing. Doing this action would have created a duplicate.
          </Typography>
        ),
      });
    };

    return (
      <ListItemButton
        variant={m.id === featuredMusicianID ? "soft" : "plain"}
        color={m.id === featuredMusicianID ? "primary" : "neutral"}
        sx={{ justifyContent: "space-between", alignItems: "start" }}
        key={m.id}
        onClick={
          onSelect
            ? () => {
                if (musicianID) {
                  setOpen(false);
                  return onSelect(m.id);
                }
                if (
                  !mercuryStage?.terminus &&
                  internal.willBeDuplicated(utils, m.id, chairID)
                ) {
                  return duplicateWarning();
                }
                if (onSelect) onSelect(m.id);
                setOpen(false);
              }
            : undefined
        }
      >
        <Box>
          {m.id === featuredMusicianID ? (
            <Typography
              sx={{ color: "inherit", fontWeight: 800 }}
              level="body5"
            >
              NEXT IN LINE
            </Typography>
          ) : (
            <Box />
          )}
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <MusicianAvatar noBadge size={25} musician={m} />
            <Box>
              <Typography
                startDecorator={index ? `${index}.` : undefined}
                level="body3"
              >
                {m.fullName()}
              </Typography>
              <Typography level="body4">{m.instrumentNames}</Typography>
            </Box>
          </Box>
          <Sheet sx={{ background: "rgba(255,235,59,0.1)", width: "auto" }}>
            <Typography level="body3">{m.comments}</Typography>
          </Sheet>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            alignItems: "end",
            gap: 0.5,
          }}
        >
          {m.subRuleID ? <SuggestedBySubRule subRuleID={m.subRuleID} /> : []}
          {mercuryStage?.id ? (
            <Chip
              size="sm"
              startDecorator={
                <img style={{ height: 12 }} src={mercuryStage.icon} />
              }
              sx={{
                background: mercuryStage.color
                  ? alpha(mercuryStage.color, 0.1)
                  : undefined,
                color: mercuryStage.color,
              }}
            >
              {getCurrentStageName(mercuryStage)}
            </Chip>
          ) : (
            []
          )}
        </Box>
      </ListItemButton>
    );
  };

  const onDeck = musicians?.filter(
    (m) =>
      `${m.fullName()} ${m.firstName} ${m.lastName}`
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) >= 0 && m.onDeck
  );

  const pending = musicians?.filter(
    (m) =>
      `${m.fullName()} ${m.firstName} ${m.lastName}`
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) >= 0 &&
      !m.onDeck &&
      !m.settled
  );

  const settled = musicians?.filter(
    (m) =>
      `${m.fullName()} ${m.firstName} ${m.lastName}`
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) >= 0 && m.settled
  );

  const noSuggestions =
    onDeck.length === 0 &&
    pending.length === 0 &&
    settled.length === 0 &&
    otherMusicians.length === 0;

  return (
    <Tooltip
      arrow
      open={open && !selectedMusicianID}
      color="neutral"
      variant="outlined"
      title={
        <ClickAwayListener
          disableReactTree
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Box
            sx={{
              height: 390,
              width: CHAIR_WIDTH + 40,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!onSelect ? (
              <Typography sx={{ fontWeight: 600 }}>
                Who's next on this chair:
              </Typography>
            ) : (
              []
            )}
            {onSelect ? (
              <Input
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                size="sm"
                autoFocus
                color="neutral"
                slotProps={{
                  input: {
                    autoCapitalize: "off",
                    autocorrect: "off",
                    autocomplete: "off",
                    spellCheck: "false",
                  },
                }}
                variant="soft"
                placeholder="Search..."
                startDecorator={
                  <i className="fa-solid fa-magnifying-glass"></i>
                }
                endDecorator={
                  searchText ? (
                    <IconButton
                      color="neutral"
                      variant="plain"
                      onClick={() => setSearchText("")}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </IconButton>
                  ) : (
                    []
                  )
                }
              />
            ) : (
              []
            )}
            {assignmentID ? (
              <Button
                variant="solid"
                color="neutral"
                sx={{ mt: 1 }}
                size="sm"
                onClick={() => {
                  setOpen(false);
                  dispatch(setAssignmentIDForLookup(assignmentID));
                  dispatch(
                    setFormOpen({ isOpen: true, formID: "assignmentLookup" })
                  );
                }}
                endDecorator={<i className="fa-solid fa-circle-info"></i>}
              >
                View Assignment Details
              </Button>
            ) : (
              []
            )}
            <Box
              sx={{
                flexGrow: 1,
                overflow: "auto",
                minHeight: "auto",
                height: 0,
              }}
            >
              <List>
                {onDeck?.length ? (
                  <Divider
                    sx={{
                      p: 1,
                      position: "sticky",
                      top: 0,
                      zIndex: 999,
                      backdropFilter: "blur(10px)",
                      background: "rgba(255,255,255,0.8)",
                    }}
                  >
                    <Typography level="body4">ON DECK</Typography>
                  </Divider>
                ) : (
                  []
                )}
                {onDeck?.map((m, i) => musicianMenuItem(m, false, i + 1))}
                {pending?.length ? (
                  <Divider
                    sx={{
                      p: 1,
                      position: "sticky",
                      top: 0,
                      zIndex: 999,
                      backdropFilter: "blur(10px)",
                      background: "rgba(255,255,255,0.8)",
                    }}
                  >
                    <Typography level="body4">PENDING</Typography>
                  </Divider>
                ) : (
                  []
                )}
                {pending?.map((m, i) => musicianMenuItem(m, false, i + 1))}
                {settled?.length ? (
                  <Divider
                    sx={{
                      p: 1,
                      position: "sticky",
                      top: 0,
                      zIndex: 999,
                      backdropFilter: "blur(10px)",
                      background: "rgba(255,255,255,0.8)",
                    }}
                  >
                    <Typography level="body4">SETTLED</Typography>
                  </Divider>
                ) : (
                  []
                )}
                {settled?.map((m) => musicianMenuItem(m, false))}
                {otherMusicians.length ? (
                  <>
                    <Divider
                      sx={{
                        p: 1,
                        position: "sticky",
                        top: 0,
                        zIndex: 999,
                        backdropFilter: "blur(10px)",
                        background: "rgba(255,255,255,0.8)",
                      }}
                    >
                      <Typography level="body4">OTHER</Typography>
                    </Divider>
                    {otherMusicians?.map((m) => musicianMenuItem(m, true))}
                  </>
                ) : (
                  []
                )}
              </List>
              {noSuggestions ? (
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography sx={{ fontWeight: 600 }}>No Match</Typography>
                  <Typography level="body2">No musicians found</Typography>
                </Box>
              ) : (
                []
              )}
            </Box>
            {noSuggestions ? (
              <Button
                fullWidth
                size="sm"
                onClick={() => {
                  setOpen(false);
                  dispatch(setFormOpen({ isOpen: true, formID: "musician" }));
                }}
              >
                + Add Musician
              </Button>
            ) : (
              []
            )}
          </Box>
        </ClickAwayListener>
      }
    >
      <Box
        sx={{ width: "100%", flex: 1 }}
        onClick={
          whosnext ||
          missionControlMode === "edit" ||
          missionControlMode === "invite" ||
          assignmentIDforLoopup
            ? () => setOpen(true)
            : undefined
        }
      >
        {children}
      </Box>
    </Tooltip>
  );
}
