/**
 * Action_Entity type guard.
 *
 * @param {any} actionJson Action object from API
 * @returns {boolean} Return true if type is Action_Entity
 */

import { Option } from "entities/option";

export default class Action_Entity {
  constructor(actionJson: Action_Entity) {
    try {
      this.id = actionJson.id;
      this.name = actionJson.name;
      this.description = actionJson.description;
      this.icon = actionJson.icon;
      this.parentActionID = actionJson.parentActionID;
      this.callback = actionJson.callback;
      this.options = actionJson.options;
      this.enabled = actionJson.enabled;
      this.timedelay = actionJson.timedelay;
      this.createdAt = actionJson.createdAt;
      this.updatedAt = actionJson.updatedAt;
      this.relationID = actionJson.relationID;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  description: string;
  icon: string;
  parentActionID: number;
  callback: string;
  options: Array<Option>;
  enabled: boolean;
  timedelay: number;
  createdAt: string;
  updatedAt: string;
  relationID: number;
}
