import { useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Selecto from "react-selecto";

import {
  positionsSelectedSelector,
  selectPositions,
  unselectPositions,
} from "reducers/v2/missionControl";

export function GroupSelection({ reference }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const ref = useRef();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  // const internal = useSelector(layoutInternalSelector);
  const { visibleChairIDs, positionIDs } = useSelector(
    positionsSelectedSelector
  );

  useEffect(() => {
    if (ref.current) {
      const selectoApi = ref.current as any;
      const elements = document.getElementsByClassName("selecto");
      const selected = [];
      Array.from(elements).forEach((el) => {
        const index = positionIDs?.findIndex((e) => e === el.id);
        if (index !== -1) {
          selected.push(el);
        }
      });
      selectoApi.setSelectedTargets(selected);
    }
  }, [visibleChairIDs]);

  if (sm) return <div />;

  const throttleTime = 5;
  const threshold = 0;

  return (
    <Selecto
      ref={ref}
      dragContainer={reference?.current}
      selectableTargets={[".selecto"]}
      hitRate={1}
      selectByClick={false}
      selectFromInside={false}
      ratio={0}
      toggleContinueSelect={"shift"}
      onScroll={({ direction }) => {
        reference.current!.scrollBy(direction[0] * 10, direction[1] * 10);
      }}
      scrollOptions={{
        container: reference,
        getScrollPosition: () => {
          return [reference.current!.scrollLeft, reference.current!.scrollTop];
        },
        throttleTime,
        threshold,
      }}
      continueSelectWithoutDeselect
      onSelect={(e) => {
        if (e.inputEvent.srcElement.id === "selecto-checkbox") return;
        try {
          const added = e.added.reduce((a, i) => {
            a.push(i.id);
            return a;
          }, []);
          if (added.length) {
            dispatch(selectPositions(added));
          }
          const removed = e.removed.reduce((a, i) => {
            a.push(i.id);
            return a;
          }, []);
          if (removed.length) {
            dispatch(unselectPositions(removed));
          }
        } catch (error) {}
      }}
    ></Selecto>
  );
}
