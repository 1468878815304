import { Box } from "@mui/material";
import { DataGridPro, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import Id from "atoms/Id/Id";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useNavigate } from "react-router-dom";
import { useCustomers } from "redux/customer/customerHooks";

export default function CustomersDataGrid({ autoheight = true }) {
  const readOnly = useReadOnly();
  const { customers, loading } = useCustomers();

  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id startDecorator={<i className="fa-solid fa-building"></i>}>
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      type: "string",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      type: "string",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "string",
      flex: 1,
    },
  ];

  return (
    <DataGridPro
      autoHeight={autoheight}
      pagination
      pageSize={25}
      density="compact"
      loading={loading}
      onRowClick={(p) => {
        if (!readOnly)
          navigate(`${RouterConfig.customers}/${p.row.id}/general`);
      }}
      rows={customers ?? []}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: Toolbar,
      }}
    />
  );
}

function Toolbar() {
  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        gap: 2,
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
