import { Button, Chip, ChipDelete } from "@mui/joy";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useInstruments } from "redux/instrument/instrumentHooks";
import {
  useDeleteMusicianInstrumentsMutation,
  useUpdateMusicianInstrumentsMutation,
} from "redux/musician/musicianEndpoints";
import { useMusician } from "redux/musician/musicianHooks";
import { InstrumentsPopover } from "../InstrumentsPopover/InstrumentsPopover";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";

/**
 *
 * @returns {ReactElement} MusicianInstruments page
 */
export function MusicianInstruments({ musicianID }: { musicianID?: number }) {
  const { musician } = useMusician(musicianID);
  const readOnly = useReadOnly();
  const [updateMusicianInstrument] = useUpdateMusicianInstrumentsMutation();
  const [deleteMusicianInstrument] = useDeleteMusicianInstrumentsMutation();

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLAnchorElement) | null
  >(null);

  function handleNewInstruments(instrumentIDs: number[]) {
    instrumentIDs.forEach((instrumentID) => {
      updateMusicianInstrument({ id: musician.id, body: { instrumentID } });
    });
  }

  return (
    <>
      <Button
        disabled={readOnly}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="neutral"
        variant="outlined"
        sx={{ background: "white" }}
        endDecorator={<i className="fa-duotone fa-piano"></i>}
      >
        + Add Instruments
      </Button>
      <InstrumentsPopover
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSelect={handleNewInstruments}
      />
      <Grid container sx={{ mt: 1 }} spacing={1}>
        {musician?.instruments?.map((i) => (
          <Grid item key={i.id}>
            <Chip
              color="neutral"
              variant="solid"
              endDecorator={
                <ChipDelete
                  disabled={readOnly}
                  onDelete={() =>
                    deleteMusicianInstrument({
                      id: musician.id,
                      body: { instrumentID: i.id },
                    })
                  }
                />
              }
            >
              {i.name}
            </Chip>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
