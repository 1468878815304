import ProjectTag_Entity from "./projectTag";
export default class ProjectTag extends ProjectTag_Entity {
  static fromList(projectTagsJSON: unknown): Array<ProjectTag> {
    const projectTags: ProjectTag[] = [];
    if (projectTagsJSON)
      Array.isArray(projectTagsJSON) &&
        projectTagsJSON.forEach((projectTagJSON) => {
          projectTags.push(new ProjectTag(projectTagJSON));
        });
    return projectTags;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
