import { Dictionary } from "@reduxjs/toolkit";
import { Chair, Chair_Entity } from "entities/chair";
import { InstrumentationSelection } from "hooks/Instrumentation/v1";
import sha256 from "crypto-js/sha256";
import { Section } from "hooks/Layout/types";

export function getInstrumentationsFromSections(
  parentSections: Dictionary<Section[]>
): InstrumentationSelection {
  const ret: InstrumentationSelection = {};

  for (const key in parentSections) {
    if (Object.prototype.hasOwnProperty.call(parentSections, key)) {
      const sections = parentSections[key];
      sections.forEach((s) => {
        const count =
          (s.chairs ?? []).length +
          (s.projectMusicians ?? []).length +
          (s.jobs ?? []).length;
        if (ret[s.sectionID]) {
          if (count > ret[s.sectionID].createCount) {
            ret[s.sectionID].createCount = count;
          }
        } else {
          ret[s.sectionID] = {
            createCount: count,
          };
        }
      });
    }
  }

  return ret;
}

export function getLayoutHash(
  chairs: Chair[],
  level?: ("musicianID" | "sectionOrder" | "sectionID" | "sectionRoleID")[]
) {
  let _chairs: Chair[] = JSON.parse(JSON.stringify(chairs));
  const _level = level ?? [
    "musicianID",
    "sectionOrder",
    "sectionID",
    "sectionRoleID",
  ];
  let ret;
  _chairs.sort((a, b) => {
    if (a.sectionID === b.sectionID) return a.sectionOrder - b.sectionOrder;
    return a.sectionID - b.sectionID;
  });
  _chairs = _chairs.reduce((a, c) => {
    const chair: Partial<Chair_Entity> = {};
    if (_level.indexOf("musicianID") >= 0) chair.musicianID = c.musicianID;
    if (_level.indexOf("sectionOrder") >= 0)
      chair.sectionOrder = c.sectionOrder;
    if (_level.indexOf("sectionID") >= 0) chair.sectionID = c.sectionID;
    if (_level.indexOf("sectionRoleID") >= 0)
      chair.sectionRoleID = c.sectionRoleID;
    a.push(chair);
    return a;
  }, []);

  const jsonStr = sha256(JSON.stringify(_chairs)).toString();
  return jsonStr;
}
