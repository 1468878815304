export default class Chair_Entity {
  constructor(chairJson: Chair_Entity) {
    try {
      this.id = chairJson.id;
      this.memo = chairJson.memo;
      this.projectPieceID = chairJson.projectPieceID;
      this.workSessionIDs = chairJson.workSessionIDs;
      this.projectID = chairJson.projectID;
      this.assignmentID = chairJson.assignmentID;
      this.sectionID = chairJson.sectionID;
      this.sectionRoleID = chairJson.sectionRoleID;
      this.sectionOrder = chairJson.sectionOrder;
      this.instrumentIDs = chairJson.instrumentIDs;
      this.workSessionID = chairJson.workSessionID;
      this.chairCount = chairJson.chairCount;
      this.musicianID = chairJson.musicianID;
      this.createdAt = chairJson.createdAt;
      this.updatedAt = chairJson.updatedAt;
      this.forWorkSessionID = chairJson.forWorkSessionID;
      this.forProjectPieceID = chairJson.forProjectPieceID;
      this.hidden = chairJson.hidden;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Chair_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  memo: string;
  projectPieceID: number;
  workSessionIDs: number[];
  assignmentID: number;
  projectID: number;
  sectionID: number;
  sectionRoleID: number;
  workSessionID: number;
  sectionOrder: number;
  instrumentIDs: string;
  musicianID?: number;
  chairCount: number;
  createdAt: string;
  updatedAt: string;
  forWorkSessionID?: number;
  forProjectPieceID?: number;
  hidden?: boolean;
}
