import { Avatar, Divider, IconButton, Switch, Typography } from "@mui/joy";
import { Box, Drawer, Grow, Hidden, Paper, Tooltip } from "@mui/material";
import { Musician_Entity } from "entities/musician";
import { WorkSession_Entity } from "entities/workSession";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { notifierBodySelector, setBody } from "reducers/notifier";
import { projectHiringSelectionSelector } from "reducers/projectHiring";
import {
  selectedProjectIDSelector,
  setFormOpen,
  setNotifierSelectedStage,
} from "reducers/rhapsody";
import { useGetHiringNotifyQuery } from "redux/hiringNotify/hiringNotifyEndpoints";

export default function ProjectHiringGroupActions() {
  const projectHiringSelection = useSelector(projectHiringSelectionSelector);
  const { selectedMusicians, selectedSessions } = projectHiringSelection;
  const projectID = useSelector(selectedProjectIDSelector);
  const body = useSelector(notifierBodySelector);
  const selection: {
    selectedMusicians: Musician_Entity[];
    selectedSessions: WorkSession_Entity[];
  } = useSelector(projectHiringSelectionSelector);
  const dispatch = useDispatch();

  const projectNotifyBody = {
    musicianIDs: selection.selectedMusicians.reduce((a, item) => {
      a.push(item.id);
      return a;
    }, new Array<number>()),
    sessionIDs: selection.selectedSessions.reduce((a, item) => {
      a.push(item.id);
      return a;
    }, new Array<number>()),
    nudge: body.nudge,
  };

  const hasSelection =
    projectNotifyBody.musicianIDs.length && projectNotifyBody.sessionIDs.length;

  const { data: projectNotify } = useGetHiringNotifyQuery(
    { projectID, body: projectNotifyBody },
    {
      skip: !projectID || !hasSelection,
      refetchOnMountOrArgChange: true,
    }
  );

  const nudge = body.nudge ?? false;

  const stages = nudge
    ? projectNotify?.currentStages.filter((s) => s.nudgeable)
    : projectNotify?.upcomingStages;

  const content = (
    <>
      <Box
        sx={{ display: "flex", gap: 1, alignItems: "center", flexShrink: 0 }}
      >
        <Box
          sx={{
            display: "flex",
            background: "#eeeeee",
            borderRadius: 30,
            gap: 1,
          }}
        >
          <Tooltip title="Communication">
            <Avatar
              onClick={() => dispatch(setBody({ nudge: false }))}
              sx={{
                opacity: !nudge ? 1 : 0.4,
                cursor: "pointer",
                "&:hover": { opacity: !nudge ? 1 : 0.6 },
              }}
              color={nudge ? "neutral" : "primary"}
              variant="solid"
            >
              <i className="fa-solid fa-paper-plane"></i>
            </Avatar>
          </Tooltip>
          <Tooltip title="Nudge">
            <Avatar
              onClick={() => dispatch(setBody({ nudge: true }))}
              sx={{
                opacity: nudge ? 1 : 0.4,
                cursor: "pointer",
                "&:hover": { opacity: nudge ? 1 : 0.6 },
              }}
              color={!nudge ? "neutral" : "danger"}
              variant="solid"
            >
              <i className="fa-solid fa-bullhorn"></i>
            </Avatar>
          </Tooltip>
        </Box>
        <Box>
          <Typography level="h6" noWrap>
            {selectedMusicians.length} Musician
            {selectedMusicians.length > 1 ? "s" : ""}
          </Typography>
          <Typography level="body3" noWrap>
            on {selectedSessions.length} Work Session
            {selectedSessions.length > 1 ? "s" : ""}
          </Typography>
        </Box>
        <Divider orientation="vertical" />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          overflowX: "auto",
        }}
      >
        {stages?.map((s) => (
          <IconButton
            key={s.id}
            variant="plain"
            color="neutral"
            sx={{ flexDirection: "column", flexShrink: 0 }}
            onClick={() => {
              dispatch(setNotifierSelectedStage(s));
              dispatch(setFormOpen({ formID: "notifier", isOpen: true }));
            }}
          >
            <img src={s.icon} style={{ height: 20 }} />
            <Typography level="body4">{s.name}</Typography>
            <Typography level="body5">
              {s.jobCount} musician{s.jobCount > 1 ? "s" : ""}
            </Typography>
          </IconButton>
        ))}
      </Box>
    </>
  );

  return (
    <>
      <Hidden mdDown>
        <Grow in={selectedMusicians.length > 0 && selectedSessions.length > 0}>
          <Paper
            sx={{
              position: "fixed",
              bottom: 32,
              left: "30%",
              right: "30%",
              display: "flex",
              alignItems: "center",
              gap: 1,
              maxWidth: 840,
              width: "calc(100vw - 400px)",
              zIndex: 999,
              borderRadius: 50,
              justifyContent: "space-between",
              p: 2,
              boxShadow: "0px 15px 50px rgba(0, 0, 0, 0.3)",
            }}
          >
            {content}
          </Paper>
        </Grow>
      </Hidden>
      <Hidden mdUp>
        <Grow in={selectedMusicians.length > 0}>
          <Drawer anchor="bottom" variant="persistent" open>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
              }}
            >
              {content}
            </Box>
          </Drawer>
        </Grow>
      </Hidden>
    </>
  );
}
