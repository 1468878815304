import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "config/methods";

const service = "account";
const organizations = `${REACT_APP_API_PREFIX}/${service}/organizations`;

export function getOrganizations() {
  const url = `${organizations}`;
  return {
    method: GET,
    url,
  };
}

export function getOrganizationById(id) {
  const url = `${organizations}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getOrganizationWithToken(token) {
  const url = `${organizations}/token/${token}`;
  return {
    method: GET,
    url,
  };
}

export function joinOrganization(body) {
  const url = `${organizations}/join`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function setOrganizationByDefault(id) {
  const url = `${organizations}/${id}/default`;
  return {
    method: PUT,
    url,
  };
}

export function addUserToOrganization(id, body) {
  const url = `${organizations}/${id}/users`;
  return {
    method: POST,
    url,
    body,
  };
}

export function updateOrganization(id, body) {
  const url = `${organizations}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteOrganization(id) {
  const url = `${organizations}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createOrganization(body) {
  const url = `${organizations}`;
  return {
    method: POST,
    url,
    body,
  };
}
