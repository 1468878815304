import { Button, Divider, IconButton, Input, Typography } from "@mui/joy";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
} from "@mui/material";
import { useMissionControlSelectAll } from "features/projects/ProjectMissionControl/utils";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import hotkeys from "hotkeys-js";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { layoutSetReordering } from "reducers/layout";
import {
  formOpenSelector,
  setFormOpen,
  setMissionControlMode,
  setMissionControlViewMode,
  toggleViewChairMemos,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutSetDebug,
} from "reducers/v2/missionControl";
/**
 *
 * @returns {ReactElement} Shortcut page
 */
export function Shortcut() {
  const [char, setChar] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const debug = useSelector(layoutDebugSelector);
  const open = useSelector(formOpenSelector("shortcut"));
  const [password, setPassword] = useState("");
  const [passwordOpen, setPasswordOpen] = useState(false);
  const readOnly = useReadOnly();
  const projectSidebarOpen =
    useSelector(formOpenSelector("projectSidebar")) ?? true;
  const missionControlSelectAll = useMissionControlSelectAll();

  useEffect(() => {
    handleShortcut();
  }, [char]);

  useEffect(() => {
    hotkeys("command+k", function (event) {
      event.preventDefault(); // Prevent the default action
      dispatch(setFormOpen({ isOpen: true, formID: "searchMusician" }));
    });
    hotkeys("*", function (event: any) {
      if (
        this !== event.target &&
        (/textarea|select/i.test(event.target.nodeName) ||
          event.target.type === "text")
      ) {
        return;
      }
      setChar((e) => `${e}${event.key}`);
      setTimeout(() => setChar(""), 1000);
    });
  }, []);

  const debugMode = async () => {
    console.log("debugMode");
    const sha = await sha256(password);
    if (
      sha ===
        "2ed1120c814ebee08f557eda31218614b274aa2e11e06fed523e48380c434cab" ||
      sha === "fd1f3dd5b3dff40e42978c35f8760d4428dcc956d53a108b22fa546f0d2b62b5"
    ) {
      dispatch(layoutSetDebug(true));
      setPassword("");
      setPasswordOpen(false);
    }
  };

  function handleShortcut() {
    if (readOnly) return;
    switch (char) {
      case "cm":
        dispatch(setFormOpen({ formID: "musician", isOpen: true }));
        break;
      case "cp":
        dispatch(setFormOpen({ formID: "project", isOpen: true }));
        break;
      case "cw":
        dispatch(setFormOpen({ formID: "workSession", isOpen: true }));
        break;
      case "cv":
        dispatch(setFormOpen({ formID: "venue", isOpen: true }));
        break;
      case "ct":
        dispatch(setFormOpen({ formID: "template", isOpen: true }));
        break;

      case "ga":
        navigate(RouterConfig.addressBook);
        break;
      case "gs":
        navigate(`${RouterConfig.myAccount}/credentials`);
        break;
      case "gw":
        navigate(RouterConfig.workSessions);
        break;
      case "gp":
        navigate(RouterConfig.projects);
        break;
      case "ge":
        navigate(RouterConfig.emails);
        break;
      case "gc":
        navigate(RouterConfig.calendar);
        break;
      case "gh":
        navigate(RouterConfig.root);
        break;

      case "ar":
        dispatch(layoutSetReordering(true));
        break;
      case "ac":
        dispatch(setFormOpen({ formID: "instrumentation", isOpen: true }));
        break;
      case "as":
        dispatch(
          setFormOpen({ formID: "projectSidebar", isOpen: !projectSidebarOpen })
        );
        break;
      case "xd":
        if (debug) {
          dispatch(layoutSetDebug(false));
        } else {
          setPasswordOpen(true);
        }
        break;

      case "mv":
        dispatch(setMissionControlMode("view"));
        break;
      case "me":
        dispatch(setMissionControlMode("edit"));
        break;
      case "mi":
        dispatch(setMissionControlMode("invite"));
        break;
      case "mt":
        dispatch(setFormOpen({ isOpen: true, formID: "musicianTalkback" }));
        break;
      case "mm":
        dispatch(toggleViewChairMemos());
        break;
      case "mg":
        dispatch(setMissionControlViewMode("grouped"));
        break;
      case "ms":
        dispatch(setMissionControlViewMode("grouped"));
        break;
      case "mw":
        dispatch(setMissionControlViewMode("workSessions"));
        break;
      case "mp":
        dispatch(setMissionControlViewMode("pieces"));
        break;
      case "ma":
        dispatch(setMissionControlViewMode("assignments"));
        break;
      case "ss":
        missionControlSelectAll?.selectAll();
        break;
      default:
        break;
    }
  }
  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        onClose={() =>
          dispatch(setFormOpen({ formID: "shortcut", isOpen: false }))
        }
      >
        <Box
          sx={{
            p: 2,
            width: 380,
            display: "flex",
            gap: 1,
            alignItems: "stretch",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {char}
            <Typography level="h6">Keyboard Shortcuts</Typography>
            <IconButton
              onClick={() =>
                dispatch(setFormOpen({ formID: "shortcut", isOpen: false }))
              }
              variant="plain"
              color="neutral"
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            gap: 4,
            flexDirection: "column",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <Typography>
              <b>Navigation</b>
            </Typography>
            <Short a="g" b="h" action="Home" />
            <Short a="g" b="c" action="Calendar" />
            <Short a="g" b="p" action="Projects" />
            <Short a="g" b="w" action="Work Sessions" />
            <Short a="g" b="a" action="Address Book" />
            <Short a="g" b="e" action="Emails" />
            <Short a="g" b="s" action="Settings" />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <Typography>
              <b>Create</b>
            </Typography>
            <Short a="c" b="m" action="Musician" />
            <Short a="c" b="p" action="Project" />
            <Short a="c" b="w" action="Work Session" />
            <Short a="c" b="v" action="Venue" />
            <Short a="c" b="t" action="Template" />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <Typography>
              <b>Actions</b>
            </Typography>
            <Short a="a" b="c" action="Add Chairs" />
            <Short a="a" b="s" action="Collapse Project Sidebar" />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <Typography>
              <b>Mission Control</b>
            </Typography>
            <Short a="m" b="v" action="View mode" />
            <Short a="m" b="e" action="Edit mode" />
            <Short a="m" b="i" action="Notify mode" />
            <Short a="m" b="m" action="Chair Memos" />
            <Short a="m" b="t" action="Musician Talkback" />
            <Short a="m" b="g" action="View > Genius" />
            <Short a="m" b="t" action="View > Work Sessions" />
            <Short a="m" b="p" action="View > Pieces" />
            <Short a="m" b="a" action="View > Assignments" />
            <Short a="s" b="s" action="Sidebar > Select all" />
            <Short
              a={
                <i style={{ fontSize: 11 }} className="fa-solid fa-command"></i>
              }
              b="k"
              action="Search Musician"
            />
          </Box>
        </Box>
      </Drawer>
      <Dialog open={passwordOpen} onClose={() => setPasswordOpen(false)}>
        <DialogTitle>You trying to access the debug mode</DialogTitle>
        <DialogContent>
          <Input
            autoFocus
            type="password"
            placeholder="Admin Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") debugMode();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="neutral"
            variant="soft"
            onClick={() => {
              setPasswordOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={debugMode}>Debug Mode</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function Short({
  a,
  b,
  action,
  compact,
}: {
  a: string | ReactElement;
  b: string;
  action?: string;
  compact?: boolean;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: compact ? 0.4 : 1,
        alignItems: "baseline",
        ml: compact ? 3 : 0,
        color: "inherit",
        fontWeight: "inherit",
      }}
    >
      <Letter compact={compact}>{a}</Letter>{" "}
      <Typography
        sx={{ color: "inherit", fontWeight: "inherit" }}
        level={compact ? "body5" : "body2"}
      >
        then
      </Typography>{" "}
      <Letter compact={compact}>{b}</Letter>
      {action ? `: ${action}` : []}
    </Box>
  );
}

function Letter({
  children,
  compact,
}: {
  children: string | ReactElement;
  compact?: boolean;
}) {
  return (
    <Box
      sx={{
        background: "#ECEEF1",
        color: "#424551",
        borderRadius: "4px",
        padding: compact ? 0.3 : 0.5,
        minWidth: compact ? 14 : 30,
        fontSize: compact ? 10 : undefined,
        textAlign: "center",
        fontWeight: "inherit",
      }}
    >
      {children}
    </Box>
  );
}

async function sha256(str) {
  // Convert the string to an ArrayBuffer
  const buffer = new TextEncoder().encode(str);

  // Calculate the hash
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);

  // Convert the hash to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  console.log(hashHex);
  return hashHex;
}
