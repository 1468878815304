import { Button } from "@mui/joy";
import { Box, Hidden } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Job } from "entities/job";
import RhapsodyChair from "features/chair/v1";
import { AddSection } from "hooks/AddSection/addSection";
import { Autofill } from "hooks/Autofill/Autofill";
import {
  Instrumentation,
  InstrumentationSelection,
} from "hooks/Instrumentation/v1";
import { Layout } from "hooks/Layout/v1";
import { WorkSessionSideBar } from "hooks/workSessionSideBar/workSessionSideBar";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { setFormOpen } from "reducers/rhapsody";
import {
  useCreateJobMutation,
  useUpdateJobMutation,
} from "redux/job/jobEndpoints";
import { useWorkSessionActiveJobs } from "redux/job/jobHooks";
import { useWorkSessionMercuryJobs } from "redux/mercuryJob/mercuryJobHooks";
import {
  useCreateWorkSessionSectionMutation,
  useDeleteWorkSessionSectionMutation,
} from "redux/workSessionSection/workSessionSectionEndpoints";
import { useWorkSessionSections } from "redux/workSessionSection/workSessionSectionHooks";

export default function Hiring({ workSessionID }) {
  const dispatch = useDispatch();
  const ref = useRef();
  const { jobsMap } = useWorkSessionActiveJobs(workSessionID);
  const { mercuryJobMap } = useWorkSessionMercuryJobs(workSessionID);
  const [updateJob] = useUpdateJobMutation();
  const [createWorkSessionSection] = useCreateWorkSessionSectionMutation();

  const { workSessionSections, isLoading, requestId } =
    useWorkSessionSections(workSessionID);

  const [createJob] = useCreateJobMutation();
  const [deleteWorkSessionSection] = useDeleteWorkSessionSectionMutation();

  const addMusicians = async (selection: InstrumentationSelection) => {
    const body = [];
    for (const sectionID in selection) {
      if (selection.hasOwnProperty(sectionID)) {
        const chairs = selection[sectionID];
        body.push({
          sessionID: workSessionID,
          sectionID: parseInt(sectionID),
          createCount: chairs.createCount,
        });
      }
    }

    createJob(body);
  };

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ paddingTop: 32 }}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
        <Grid item>
          <Typography color="textSecondary">Please wait</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flex: 1,
        p: 2,
        position: "relative",
        gap: 2,
      }}
    >
      <Hidden smDown>
        <WorkSessionSideBar workSessionID={workSessionID} />
      </Hidden>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Instrumentation onAdd={addMusicians} />
            <Button
              startDecorator={<i className="fa-solid fa-plus"></i>}
              endDecorator={<i className="fa-duotone fa-chair"></i>}
              size="sm"
              onClick={() =>
                dispatch(
                  setFormOpen({
                    isOpen: true,
                    formID: "instrumentation",
                  })
                )
              }
            >
              <Hidden smDown>Add</Hidden> Chairs
            </Button>
            <AddSection
              existingSectionIDs={workSessionSections.reduce((a, i) => {
                a.push(i.sectionID);
                return a;
              }, [])}
              onSelect={(sectionID) =>
                createWorkSessionSection({
                  sectionID,
                  sessionID: workSessionID,
                })
              }
            />
          </Box>
          <Autofill type="workSession" />
        </Box>
        <Layout
          key={requestId}
          chairType="workSession"
          onDeleteSection={(id) => deleteWorkSessionSection(id)}
          onOrderChange={(jobID, sectionID, sectionOrder) => {
            updateJob({
              id: jobID,
              body: { sectionID, sectionOrder },
            });
          }}
          sections={workSessionSections}
          renderChair={({ chair, emptyChairIndex }) => {
            const job = chair as Job;
            return (
              <RhapsodyChair
                emptyChairIndex={emptyChairIndex}
                layer={-1}
                chairType="workSession"
                chair={jobsMap[job.id]}
                mercuryJob={mercuryJobMap[job.mercuryJobID]}
              />
            );
          }}
        />
      </Box>
    </Box>
  );
}
