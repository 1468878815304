import { Instrument } from "entities/instrument";
import { Stage_Entity } from "entities/stage";
import { readyStage } from "entities/stage/helper";
import Musician_Entity from "./musician";
export default class Musician extends Musician_Entity {
  instruments: Instrument[];

  constructor(musiciansJSON: Musician_Entity) {
    super(musiciansJSON);
    if (musiciansJSON.instruments)
      this.instruments = Instrument.fromList(musiciansJSON.instruments);
  }

  static fromList(musiciansJSON: unknown): Array<Musician> {
    const musicians: Musician[] = [];
    if (musiciansJSON)
      Array.isArray(musiciansJSON) &&
        musiciansJSON.forEach((musicianJSON) => {
          musicians.push(new Musician(musicianJSON));
        });
    return musicians;
  }

  fullName(withNickname = true) {
    if (withNickname && this.nickName) return this.nickName;
    return `${this.firstName ?? ""} ${this.lastName ?? ""}`;
  }

  condensedName() {
    if (this.nickName) return this.nickName;
    return `${this.firstName ? this.firstName[0] : ""}. ${this.lastName ?? ""}`;
  }

  formattedAddress() {
    return `${this.address ?? ""}\n ${this.city ?? ""} ${this.state ?? ""} ${
      this.zipcode ?? ""
    }`;
  }

  initials() {
    let ret = "";
    if (this.firstName) ret += this.firstName[0];
    if (this.lastName) ret += this.lastName[0];
    return ret;
  }

  getStage(stagesMap): Stage_Entity {
    if (this?.mercuryStage?.id) {
      return stagesMap[this?.mercuryStage?.id ?? 0];
    }

    return readyStage;
  }

  toJson() {
    return JSON.stringify(this);
  }
}
