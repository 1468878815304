import Layout_Entity from "./layout";
export default class Layout extends Layout_Entity {
  static fromList(layoutsJSON: unknown): Array<Layout> {
    const layouts: Layout[] = [];
    if (layoutsJSON)
      Array.isArray(layoutsJSON) &&
        layoutsJSON.forEach((layoutJSON) => {
          layouts.push(new Layout(layoutJSON));
        });
    return layouts;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
