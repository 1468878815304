import { Box } from "@mui/joy";
import { Dictionary } from "@reduxjs/toolkit";
import { Assignment } from "entities/assignment";
import { HProject_Entity } from "entities/hProject";
import { Mission } from "entities/mission";
import { Musician } from "entities/musician";
import { Project } from "entities/project";
import { Stage } from "entities/stage";
import { Tag } from "entities/tag";
import { WorkSession } from "entities/workSession";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedTagIDSelector } from "reducers/rhapsody";
import { setSeasonUtils } from "reducers/v2/seasonControl";
import { useTagAssignments } from "redux/assignment/assignmentHooks";
import { useMissions } from "redux/mission/missionHooks";
import { useSeasonMusicians } from "redux/musician/musicianHooks";
import { useTagProjects } from "redux/project/projectHooks";
import { useGetSeasonUpcomingStagesQuery } from "redux/projectMercury/projectMercuryEndpoints";
import { useStages } from "redux/stage/stageHooks";
import { useTag } from "redux/tag/tagHooks";
import { useWorkSessionsFilter } from "redux/workSession/workSessionHooks";

/**
 *
 * @returns {ReactElement} SeasonUtils page
 */
export function SeasonUtils() {
  const tagID = useSelector(selectedTagIDSelector);
  const { tag, isLoading: l1 } = useTag(tagID);
  const { musiciansMap, musicians, isLoading: l2 } = useSeasonMusicians(tagID);
  const { tagProjects, tagProjectsMap, isLoading: l6 } = useTagProjects(tagID);
  const { stagesMap, isLoading: l3 } = useStages();
  const projectIDs =
    tagProjects.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []) ?? [];
  const {
    workSessions,
    workSessionsMap,
    isLoading: l7,
  } = useWorkSessionsFilter(
    {
      filters: JSON.stringify([
        {
          name: "studio_sessions.projectID",
          comparison: "in",
          value: projectIDs,
        },
      ]),
    },
    { skip: projectIDs?.length === 0 }
  );

  const {
    assignments,
    assignmentsMap,
    isLoading: l8,
  } = useTagAssignments(tag?.id);
  const {
    missions,
    isLoading: l4,
    missionsMap,
  } = useMissions({
    filters: JSON.stringify([
      {
        name: "missions.tagID",
        comparison: "eq",
        value: tagID,
      },
    ]),
  });
  const { data: seasonMercury, isLoading: l5 } =
    useGetSeasonUpcomingStagesQuery(
      { id: tagID, body: {} },
      { skip: !tagID, refetchOnMountOrArgChange: true }
    );
  const dispatch = useDispatch();

  const seasonUtils: SeasonUtils = {
    tag,
    musiciansMap,
    musicians,
    stagesMap,
    missions,
    missionsMap,
    seasonMercury,
    workSessions,
    workSessionsMap,
    assignments,
    assignmentsMap,
    projects: tagProjects,
    projectsMap: tagProjectsMap,
    activeMusicians: musicians.filter((m) => {
      let active = false;
      const mAssignments = assignments.filter((a) => a.musicianID === m.id);
      mAssignments.forEach((a) => {
        if (a.active) active = true;
      });
      return active;
    }),
  };

  useEffect(() => {
    const loading = l1 || l2 || l3 || l4 || l5 || l6 || l7 || l8;

    if (!loading) {
      dispatch(setSeasonUtils(seasonUtils));
    }
  }, [
    tag,
    musiciansMap,
    stagesMap,
    missions,
    missionsMap,
    seasonMercury,
    tagProjects,
    workSessions,
    assignments,
    assignmentsMap,
    l1,
    l2,
    l3,
    l4,
    l5,
    l6,
    l7,
    l8,
  ]);

  return <Box sx={{ position: "absolute" }} />;
}

export type SeasonUtils = {
  tag: Tag;
  musiciansMap: Dictionary<Musician>;
  musicians: Musician[];
  activeMusicians: Musician[];
  stagesMap: Dictionary<Stage>;
  missions: Mission[];
  missionsMap: Dictionary<Mission>;
  seasonMercury: HProject_Entity;
  projects: Project[];
  assignments: Assignment[];
  assignmentsMap: Dictionary<Assignment>;
  projectsMap: Dictionary<Project>;
  workSessions: WorkSession[];
  workSessionsMap: Dictionary<WorkSession>;
};
