/**
 * PdLabor_Entity type guard.
 *
 * @param {any} pdPdLaborJson PdLabor object from API
 * @returns {boolean} Return true if type is PdLabor_Entity
 */

export default class PdLabor_Entity {
  constructor(laborJson: PdLabor_Entity) {
    try {
      this.id = laborJson.id;
      this.itemID = laborJson.itemID;
      this.parentID = laborJson.parentID;
      this.projectID = laborJson.projectID;
      this.uuid = laborJson.uuid;
      this.description = laborJson.description;
      this.baseScale = laborJson.baseScale;
      this.overscale = laborJson.overscale;
      this.rate = laborJson.rate;
      this.adjustmentPercent = laborJson.adjustmentPercent;
      this.adjustment = laborJson.adjustment;
      this.doubling = laborJson.doubling;
      this.scaleWages = laborJson.scaleWages;
      this.cartage = laborJson.cartage;
      this.benefitsOnly = laborJson.benefitsOnly;
      this.pension = laborJson.pension;
      this.welfare = laborJson.welfare;
      this.pensionAdjustment = laborJson.pensionAdjustment;
      this.welfareAdjustment = laborJson.welfareAdjustment;
      this.total = laborJson.total;
      this.createdAt = laborJson.createdAt;
      this.updatedAt = laborJson.updatedAt;
    } catch (e) {
      console.log(`Failed to create new instance of ${PdLabor_Entity.name}`);
    }
  }

  id: number;
  itemID: number;
  parentID: number;
  projectID: number;
  uuid: string;
  description: string;
  baseScale: number;
  overscale: number;
  rate: number;
  adjustmentPercent: boolean;
  adjustment: number;
  doubling: number;
  scaleWages: number;
  cartage: number;
  benefitsOnly: boolean;
  pension: number;
  welfare: number;
  pensionAdjustment: number;
  welfareAdjustment: number;
  total: number;
  createdAt: string;
  updatedAt: string;
}
