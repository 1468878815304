import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Card, Typography } from "@mui/joy";
import { Box } from "@mui/material";
import { SubRule } from "entities/subRule";
import { HelpCard } from "hooks/helpCard/helpCard";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedProjectIDSelector, setFormOpen } from "reducers/rhapsody";
import { usePieces } from "redux/piece/pieceHooks";
import { useTemplates } from "redux/project/projectHooks";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import {
  useCreateSubRuleMutation,
  useUpdateSubRuleMutation,
} from "redux/subRule/subRuleEndpoints";
import { useSubRules } from "redux/subRule/subRuleHooks";
import { SubRuleCard } from "./subRuleCard";

/**
 *
 * @returns {ReactElement} Subs page
 */
export function Subs() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { sectionRoles } = useSectionRoles();
  const dispatch = useDispatch();
  const { sections } = useSections();
  const readOnly = useReadOnly();
  const { projectPieces } = useProjectPieces(projectID);
  const { templatesMap } = useTemplates();
  const [updateSubRule] = useUpdateSubRuleMutation();
  const { piecesMap } = usePieces(
    {
      filters: JSON.stringify([
        {
          name: "pieces.id",
          comparison: "in",
          value: projectPieces?.reduce((a, v) => {
            a.push(v.pieceID);
            return a;
          }, []),
        },
      ]),
    },
    { skip: projectPieces?.length === 0 }
  );
  const { subRules, subRulesMap } = useSubRules({
    filters: JSON.stringify([
      {
        name: "sub_rules.projectID",
        comparison: "eq",
        value: projectID,
      },
    ]),
  });

  const [list, setList] = useState<SubRule[]>();

  useEffect(() => {
    setList(subRules);
  }, [subRules]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [defaultEdit, setDefaultEdit] = useState(false);
  const [createSubRule] = useCreateSubRuleMutation();

  function handleDragEnd(e: DragEndEvent) {
    const { active, over } = e;
    // if (!over || !requirementsMap || publicView) return;
    const activeID = parseInt(active.id.toString());
    const overID = parseInt(over.id.toString());
    if (active.id !== over?.id) {
      setList((items) => {
        const oldIndex = items.findIndex((i) => i.id === activeID);
        const newIndex = items.findIndex((i) => i.id === overID);
        updateSubRule({
          id: activeID,
          body: {
            ...subRulesMap[activeID],
            position: newIndex + 1,
          },
        });

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexDirection: "column",
        alignItems: "stretch",
        flex: 1,
        p: 2,
        background: "#F0F5FC",
      }}
    >
      <HelpCard
        sx={{ background: "white" }}
        title="Set up Rhapsody so it suggests the proper musicians"
      >
        <Typography>
          Create rules that define the next-in-line policy.
          <br />
          When a musician declines, Rhapsody will suggest musicians based on
          your rules.
        </Typography>
      </HelpCard>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          disabled={readOnly}
          items={list ?? []}
          strategy={verticalListSortingStrategy}
        >
          {list?.map((s, i) => (
            <SortableItem key={s.id} id={s.id}>
              <SubRuleCard
                key={s.id}
                order={i + 1}
                defaultEdit={defaultEdit}
                sectionRoles={sectionRoles}
                sections={sections}
                projectPieces={projectPieces}
                piecesMap={piecesMap}
                templatesMap={templatesMap}
                subRuleID={s.id}
              />
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
        <Button
          onClick={() => {
            dispatch(setFormOpen({ isOpen: true, formID: "importRule" }));
          }}
          disabled={readOnly}
          size="sm"
          // variant="soft"
          color="neutral"
          startDecorator={<i className="fa-solid fa-arrow-down"></i>}
        >
          Import
        </Button>
        <Button
          onClick={() => {
            setDefaultEdit(true);
            createSubRule({ projectID, position: list.length + 1 });
          }}
          disabled={readOnly}
          size="sm"
          startDecorator={<i className="fa-solid fa-plus"></i>}
        >
          Add Rule
        </Button>
      </Box>
    </Box>
  );
}

export function parser(e: string): number[] {
  const ret: number[] = JSON.parse(e ? e : "[]");

  return ret;
}

export function serializer(e: number[]) {
  return JSON.stringify(e);
}

function SortableItem({
  id,
  children,
}: {
  id: number;
  children: ReactElement;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const readOnly = useReadOnly();

  return (
    <Card
      size="sm"
      sx={{
        position: "relative",
        cursor: "default",
        alignItems: "center",
        display: "flex",
      }}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flex: 1,
          width: "100%",
          alignItems: "center",
        }}
      >
        {!readOnly ? (
          <Box
            sx={{
              width: 20,
              height: 20,
              cursor: "grab",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            {...listeners}
          >
            <i className="fa-regular fa-grip-dots-vertical"></i>
          </Box>
        ) : (
          []
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            position: "relative",
          }}
        >
          {children}
        </Box>
      </Box>
    </Card>
  );
}
