import ProjectContact_Entity from "./projectContact";
export default class ProjectContact extends ProjectContact_Entity {
  static fromList(projectContactsJSON: unknown): Array<ProjectContact> {
    const projectContacts: ProjectContact[] = [];
    if (projectContactsJSON)
      Array.isArray(projectContactsJSON) &&
        projectContactsJSON.forEach((projectContactJSON) => {
          projectContacts.push(new ProjectContact(projectContactJSON));
        });
    return projectContacts;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
