import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  Link,
  Sheet,
  Textarea,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/joy";
import {
  Collapse,
  Drawer,
  Skeleton,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { useTour } from "@reactour/tour";
import Id from "atoms/Id/Id";
import { Project_Entity } from "entities/project";
import { RhapsodyChair } from "entities/rhapsodyChair";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import {
  duplicateMusicianTour,
  editModeTour,
  inviteModeTour,
  misplacedMusicianTour,
  notifyTour,
  projectCoverTour,
  roleTour,
} from "features/tour/tour";
import { heightWithToolbar } from "global";
import { mapToArray } from "helpers";

import { RouterConfig } from "hooks/AppRouter/AppRouter";
import Autopilot from "hooks/Autopilot/Autopilot";
import { finale, useMySubscription } from "hooks/subscription/restrictionHooks";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  backToSeasonSelector,
  formOpenSelector,
  missionControlModeSelector,
  missionControlViewModeSelector,
  notifierBodySelector,
  selectedProjectIDSelector,
  setBackToSeason,
  setDescription420,
  setFormOpen,
  setMissionControlMode,
  setMissionControlViewMode,
  setNotifier2,
  setNotifierBody,
  toggleViewChairMemos,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutInternalSelector,
  layoutUnselectAll,
  layoutUtilsSelector,
  selectPositions,
  selectionSelector,
  setInstrumentationProjectPieceIDs,
  setNotifyMode,
} from "reducers/v2/missionControl";
import { setWorkSessionMode } from "reducers/workSessions";
import { useProjectAutopilot } from "redux/autopilot/autopilotHooks";
import { useProjectLinks } from "redux/link/linkHooks";
import { useUpdateProjectMutation } from "redux/project/projectEndpoints";
import { useProject } from "redux/project/projectHooks";
import { useProjectContacts } from "redux/projectContact/projectContactHooks";
import { useLazyGetUpcomingStagesQuery } from "redux/projectMercury/projectMercuryEndpoints";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { useProjectRosters } from "redux/projectRoster/projectRosterHooks";
import { useStages } from "redux/stage/stageHooks";
import { useSubRules } from "redux/subRule/subRuleHooks";
import { getPieChartData } from "routes/journal/v2/projectHiring";

type ChecklistItem = {
  id: number;
  group: "followUp" | "Build" | "Send";
  label: string;
  required?: boolean;
  completed?: boolean;
  color?: "success" | "info" | "warning" | "danger" | "neutral";
  endDecorator?: ReactElement;
  hidden?: boolean;
  disabled?: boolean;
  onClick: () => void;
  tooltip?: string;
  tooltipOnlyIfDisabled?: boolean;
  loading?: boolean;
  startDecorator?: ReactElement;
  whatIsIt?: () => void;
  skipInCompletionCalculation?: boolean;
};

/**
 *
 * @returns {ReactElement} ProjectSideBar page
 */
export function ProjectSideBar() {
  const projectID = useSelector(selectedProjectIDSelector);
  const askQuestion = useAskQuestion();
  const theme = useTheme();
  const readOnly = useReadOnly();
  const { stagesMap } = useStages();
  const md = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const lg = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
  const { project: _project } = useProject(projectID);
  const { projectContacts, isLoading: projectContactsLoading } =
    useProjectContacts(projectID);
  const [projectName, setProjectName] = useState("");
  const [project, setProject] = useState<Project_Entity>();
  const open = useSelector(formOpenSelector("projectSidebar")) ?? true;
  const [updateProject] = useUpdateProjectMutation();
  const internal = useSelector(layoutInternalSelector);
  const utils = useSelector(layoutUtilsSelector);
  const backToSeason = useSelector(backToSeasonSelector);
  const { hProject } = utils;
  const [getUpcomingStages] = useLazyGetUpcomingStagesQuery();
  const { autopilot } = useProjectAutopilot(projectID);
  const debug = useSelector(layoutDebugSelector);
  const selection = useSelector(selectionSelector);
  const subscription = useMySubscription();
  const {
    validProjectPieces,
    revisionNeededForMusicianIDs,
    callSent,
    releaseRequests,
  } = internal;
  const body = useSelector(notifierBodySelector);
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  const missionControlMode = useSelector(missionControlModeSelector);
  const missionControlView = useSelector(missionControlViewModeSelector);
  const { workSessions, projectPieces, musiciansMap } =
    useSelector(layoutUtilsSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subRules } = useSubRules({
    filters: JSON.stringify([
      {
        name: "sub_rules.projectID",
        comparison: "eq",
        value: projectID,
      },
    ]),
  });
  console.log(utils.assignmentsMap);
  const assignments = mapToArray(utils.assignmentsMap);
  const chairs: RhapsodyChair[] = utils.chairs;
  const replace = assignments?.filter((a) => {
    const removable = utils?.stagesMap[a.mercuryStageID]?.terminus;
    return removable && chairs.filter((c) => c.assignmentID === a.id).length;
  });
  const musicianTalkback = hProject.musicianTalkback();
  const newTalkback = musicianTalkback?.filter((t) => !t.success);
  const [observer, setObserver] = useState<{
    mode: "edit" | "invite";
    callback: () => void;
  } | null>();

  useEffect(() => {
    if (missionControlMode === "edit" && observer?.mode === "edit") {
      observer.callback();
      setObserver(null);
    }
    if (missionControlMode === "invite" && observer?.mode === "invite") {
      observer.callback();
      setObserver(null);
    }
  }, [missionControlMode]);

  const emptyPositions = internal.positions.reduce((a, v) => {
    if (v.emptyVisibleChairsCount) a.push(v);
    return a;
  }, []);

  const chairMemoCount = chairs.reduce((a, v) => {
    if (v.memo) a++;
    return a;
  }, 0);

  const roleCount = chairs.reduce((a, v) => {
    if (v.sectionRoleID !== 4) a++;
    return a;
  }, 0);

  const shorthandCount = projectPieces.reduce((a, v) => {
    if (v.description) a++;
    return a;
  }, 0);

  const workSesionMemoCount = workSessions.reduce((a, v) => {
    if (v.memo) a++;
    return a;
  }, 0);

  const musicians = mapToArray(musiciansMap);

  const { links, isLoading: linksLoading } = useProjectLinks(projectID);
  const { projectRosters, isLoading: rostersLoading } =
    useProjectRosters(projectID);

  useEffect(() => {
    if (lg) {
      dispatch(setFormOpen({ formID: "projectSidebar", isOpen: false }));
    } else {
      dispatch(setFormOpen({ formID: "projectSidebar", isOpen: true }));
    }
  }, [lg]);

  useEffect(() => {
    setProjectName(project?.name ?? "");
  }, [project]);

  const { projectNotes } = useProjectNotes(projectID);

  const steps: ChecklistItem[] = [
    {
      id: 1,
      group: "Build",
      label: `Add Project Pieces`,
      completed: validProjectPieces.length > 0,
      hidden: false,
      endDecorator:
        validProjectPieces.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {validProjectPieces.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        navigate("mission-control");
        const callback = () => {
          setIsOpen(false);
          dispatch(setFormOpen({ isOpen: true, formID: "projectPiece2" }));
        };

        if (missionControlMode !== "edit") {
          setSteps([editModeTour]);
          setCurrentStep(0);
          setTimeout(() => setIsOpen(true), 400);
          setObserver({ mode: "edit", callback });
        } else {
          callback();
        }
      },
    },
    {
      id: 2,
      group: "Build",
      label: `Add Chairs`,
      required: true,
      disabled: projectPieces.length === 0,
      completed: chairs.length > 0,
      endDecorator:
        internal.positionCount > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {internal.positionCount}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        navigate("mission-control");
        const callback = () => {
          setIsOpen(false);
          dispatch(
            setFormOpen({
              isOpen: true,
              formID: "instrumentation",
            })
          );
          dispatch(setInstrumentationProjectPieceIDs(null));
        };

        if (missionControlMode !== "edit") {
          setSteps([editModeTour]);
          setCurrentStep(0);
          setTimeout(() => setIsOpen(true), 400);
          setObserver({ mode: "edit", callback });
        } else {
          callback();
        }
      },
    },
    {
      id: 3,
      group: "Build",
      label: "Add Work Sessions",
      required: true,
      disabled: false,
      tooltip: "Add chairs and assign musicians first",
      tooltipOnlyIfDisabled: true,
      completed: workSessions.length > 0,
      endDecorator:
        workSessions.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {workSessions.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        navigate("mission-control");
        const callback = () => {
          setIsOpen(false);
          dispatch(setWorkSessionMode("datagrid"));
          dispatch(setFormOpen({ isOpen: true, formID: "workSession" }));
        };

        if (missionControlMode !== "edit") {
          setSteps([editModeTour]);
          setCurrentStep(0);
          setTimeout(() => setIsOpen(true), 400);
          setObserver({ mode: "edit", callback });
        } else {
          callback();
        }
      },
    },
    {
      id: 4,
      group: "Build",
      label: "Work Session Memos",
      disabled: workSessions.length === 0,
      tooltip: "Create Work Sessions first",
      completed: workSesionMemoCount > 0,
      color: workSesionMemoCount > 0 ? "success" : undefined,
      loading: linksLoading,
      endDecorator: workSesionMemoCount ? (
        <Avatar
          color={workSesionMemoCount ? "success" : "neutral"}
          variant="solid"
        >
          <Typography level="body4" sx={{ color: "white" }}>
            {workSesionMemoCount}
          </Typography>
        </Avatar>
      ) : undefined,
      onClick: () => {
        dispatch(setFormOpen({ isOpen: true, formID: "workSessionMemos" }));
      },
    },
    {
      id: 5,
      hidden: chairs.length === 0,
      group: "Build",
      label:
        emptyPositions.length > 0 ? "Fill Empty Chairs" : "No Empty Chairs",
      color: emptyPositions.length > 0 ? "warning" : undefined,
      required: true,

      endDecorator:
        emptyPositions.length > 0 ? (
          <Avatar
            variant="solid"
            color={emptyPositions.length === 0 ? "success" : "warning"}
          >
            <Typography level="body4" sx={{ color: "white" }}>
              {emptyPositions.length}
            </Typography>
          </Avatar>
        ) : undefined,
      disabled: emptyPositions.length === 0,
      completed: chairs.length > 0 && emptyPositions.length === 0,
      loading: false,
      onClick: () => {
        navigate("mission-control");
        if (missionControlView === "assignments") {
          dispatch(setMissionControlViewMode("grouped"));
        }
        const callback = () => {
          const allEmptyPositions = document.querySelectorAll(
            '[class*="tour-empty"]'
          );
          const steps = Array.from(allEmptyPositions).map((element, index) => {
            const selector = [...element.classList].find(
              (c) => c.indexOf("tour-empty") >= 0
            );
            return {
              selector: `.${selector}`,
              content: `This is an emtpy chair`,
            };
          });

          setIsOpen(false);
          setSteps(steps);
          setCurrentStep(0);
          setTimeout(() => setIsOpen(true), 1000);
        };

        const steps = [];
        if (missionControlMode !== "edit") {
          steps.push(editModeTour);
          setObserver({ mode: "edit", callback });
          setSteps(steps);
        } else {
          callback();
        }
        setCurrentStep(0);
        setTimeout(() => setIsOpen(true), 600);
      },
    },
    {
      id: 6,
      group: "Build",
      hidden: chairs.length === 0,
      startDecorator: <i className="fa-solid fa-user-group"></i>,
      label:
        internal.duplicateMusicianIDs.length === 0
          ? "No Duplicate Musicians"
          : "Duplicate Musicians",
      required: true,
      color: internal.duplicateMusicianIDs.length > 0 ? "danger" : undefined,
      endDecorator:
        internal.duplicateMusicianIDs.length > 0 ? (
          <Avatar variant="solid" color="danger">
            <Typography level="body4" sx={{ color: "white" }}>
              {internal.duplicateMusicianIDs.length}
            </Typography>
          </Avatar>
        ) : undefined,
      disabled: internal.duplicateMusicianIDs.length === 0,
      completed: internal.duplicateMusicianIDs.length === 0,
      loading: false,
      onClick: () => {
        navigate("mission-control");
        setSteps([
          duplicateMusicianTour(
            internal.duplicateMusicianIDs.reduce((a, v) => {
              a.push(utils.musiciansMap[v].fullName());
              return a;
            }, [])
          ),
        ]);
        setCurrentStep(0);
        setTimeout(() => setIsOpen(true), 400);
      },
    },
    {
      id: 7,
      group: "Build",
      hidden: chairs.length === 0,
      startDecorator: <i className="fa-solid fa-music-note-slash"></i>,
      label:
        internal.misplacedMusicianIDs.length === 0
          ? "No Misplaced Musicians"
          : "Misplaced Musicians",
      required: true,
      color: internal.misplacedMusicianIDs.length > 0 ? "warning" : undefined,
      endDecorator:
        internal.misplacedMusicianIDs.length > 0 ? (
          <Avatar variant="solid" color="warning">
            <Typography level="body4" sx={{ color: "white" }}>
              {internal.misplacedMusicianIDs.length}
            </Typography>
          </Avatar>
        ) : undefined,
      disabled: internal.misplacedMusicianIDs.length === 0,
      completed: internal.misplacedMusicianIDs.length === 0,
      loading: false,
      onClick: () => {
        navigate("mission-control");
        setSteps([
          misplacedMusicianTour(
            internal.misplacedMusicianIDs.reduce((a, v) => {
              a.push(utils.musiciansMap[v].fullName());
              return a;
            }, [])
          ),
        ]);
        setCurrentStep(0);
        setTimeout(() => setIsOpen(true), 400);
      },
    },
    {
      id: 8,
      group: "Build",
      label: "Add Chair Memos",
      disabled: chairs.length === 0,
      hidden: chairs.length === 0,
      tooltip: "Add chairs first",
      tooltipOnlyIfDisabled: true,
      completed: chairMemoCount > 0,
      endDecorator:
        chairMemoCount > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {chairMemoCount}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        navigate("mission-control");
        dispatch(toggleViewChairMemos());
      },
    },
    {
      id: 9,
      group: "Build",
      label: "Assigning Roles",
      hidden: chairs.length === 0,
      disabled: chairs.length === 0,
      tooltip: "Add chairs first",
      tooltipOnlyIfDisabled: true,
      completed: roleCount > 0,
      onClick: () => {
        navigate("mission-control");
        const steps = [];
        if (missionControlMode !== "edit") steps.push(editModeTour);
        steps.push(roleTour);
        setSteps(steps);
        setCurrentStep(0);
        setTimeout(() => setIsOpen(true), 500);
      },
    },
    {
      id: 10,
      group: "Build",
      label: "Who's Next Rules",
      loading: false,
      tooltip: "Add chairs first",
      tooltipOnlyIfDisabled: true,
      completed: subRules.length > 0,
      endDecorator:
        subRules.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {subRules.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => navigate("who-is-next"),
    },
    {
      id: 11,
      group: "Build",
      label: "Project Contacts",
      completed: projectContacts.length > 0 ? true : false,
      color: projectContacts.length > 0 ? "success" : undefined,
      loading: projectContactsLoading,
      endDecorator:
        projectContacts.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {projectContacts.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        dispatch(setFormOpen({ isOpen: true, formID: "projectContacts" }));
      },
    },
    {
      id: 12,
      group: "Build",
      label: "Piece Inst. Shorthand",
      completed: projectPieces?.length === shorthandCount,
      loading: linksLoading,
      endDecorator: shorthandCount ? (
        <Avatar
          color={
            projectPieces?.length === shorthandCount ? "success" : "neutral"
          }
          variant="solid"
        >
          <Typography level="body4" sx={{ color: "white" }}>
            {shorthandCount}
          </Typography>
        </Avatar>
      ) : undefined,
      onClick: () => {
        dispatch(
          setFormOpen({ isOpen: true, formID: "projectPieceShorthand" })
        );
      },
    },
    {
      id: 13,
      group: "Build",
      label: "Dress Code",
      completed: project?.dressCode ? true : false,
      color: project?.dressCode ? "success" : undefined,
      onClick: () => {
        dispatch(setFormOpen({ isOpen: true, formID: "projectDressCode" }));
      },
    },
    {
      id: 14,
      group: "Build",
      label: "Wage Info",
      completed:
        project?.agreement || project?.wageDescription || project?.wageUnit
          ? true
          : false,
      color:
        project?.agreement || project?.wageDescription || project?.wageUnit
          ? "success"
          : undefined,
      loading: false,
      onClick: () => {
        dispatch(setFormOpen({ isOpen: true, formID: "projectWageInfo" }));
      },
    },
    {
      id: 23,
      group: "Build",
      label: "Project Cover",
      color: project?.logoURL ? "success" : undefined,
      completed: project?.logoURL ? true : false,
      loading: false,
      onClick: () => {
        navigate(`${RouterConfig.projects}/${projectID}/settings`);
        setSteps([projectCoverTour]);
        setCurrentStep(0);
        setTimeout(() => setIsOpen(true), 400);
        // dispatch(setFormOpen({ isOpen: true, formID: "link" }));
      },
    },
    {
      id: 20,
      group: "Build",
      label: "Live Rosters",
      completed: projectRosters.length > 0 ? true : false,
      color: projectRosters.length > 0 ? "success" : undefined,
      loading: linksLoading,
      endDecorator:
        projectRosters.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {projectRosters.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        dispatch(setFormOpen({ isOpen: true, formID: "projectRosters" }));
      },
    },
    {
      id: 15,
      group: "Build",
      label: "Add Links",
      completed: links.length > 0 ? true : false,
      color: links.length > 0 ? "success" : undefined,
      loading: linksLoading,
      endDecorator:
        links.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {links.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        dispatch(setFormOpen({ isOpen: true, formID: "projectLinks" }));
      },
    },
    {
      id: 16,
      group: "Build",
      label: "Add Notes",
      completed: projectNotes.length > 0 ? true : false,
      color: projectNotes.length > 0 ? "success" : undefined,
      endDecorator:
        projectNotes.length > 0 ? (
          <Avatar color="success" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {projectNotes.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }));
      },
    },
    {
      id: 17,
      group: "Send",
      startDecorator: <i className="fa-solid fa-plane"></i>,
      label: "Pre-flight Check",
      required: true,
      skipInCompletionCalculation: true,
      color:
        internal.preFlight.length > 0 && chairs.length ? "warning" : undefined,
      endDecorator:
        internal.preFlight.length > 0 && chairs.length ? (
          <Avatar color="warning" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {internal.preFlight.length}
            </Typography>
          </Avatar>
        ) : undefined,
      onClick: () => {
        navigate(`${RouterConfig.projects}/${projectID}/mission-control`);
        dispatch(setFormOpen({ isOpen: true, formID: "projectPreFlight" }));
      },
    },
    {
      id: 18,
      group: "Send",
      skipInCompletionCalculation: callSent,
      startDecorator: <i className="fa-solid fa-paper-plane"></i>,
      label: "Send the call",
      required: true,
      completed: false,
      onClick: () => {
        navigate("mission-control");
        const callback = () => {
          setIsOpen(false);
          dispatch(selectPositions(internal.positionIDs));
          setSteps([notifyTour]);
          setCurrentStep(0);
          setTimeout(() => setIsOpen(true), 1000);
        };
        const steps = [];
        if (missionControlMode !== "invite") {
          steps.push(inviteModeTour);
          setObserver({ mode: "invite", callback });
          setSteps(steps);
        } else {
          callback();
        }
        setCurrentStep(0);
        setTimeout(() => setIsOpen(true), 500);
      },
      disabled: musicians.length === 0,
      tooltip: "Add chairs with musicians first",
      tooltipOnlyIfDisabled: true,
    },
  ];

  const [buildCollapsed, setBuildCollapsed] = useState(
    steps.reduce((a, v) => {
      if (v.group === "Build" && v.required && !v.completed) {
        a = true;
      }
      return a;
    }, false)
  );

  if (revisionNeededForMusicianIDs.length) {
    steps.unshift({
      id: 19,
      group: "followUp",
      label: "Send Revision Call",
      // whatIsIt: () =>
      //   dispatch(setFormOpen({ isOpen: true, formID: "revisionCallInfo" })),
      startDecorator: <i className="fa-solid fa-bolt"></i>,
      color: "warning",
      onClick: () => {
        dispatch(layoutUnselectAll());
        navigate("mission-control");
        dispatch(setMissionControlMode("invite"));
        dispatch(setMissionControlViewMode("assignments"));
        dispatch(setNotifyMode("communication"));
        sendMessage(
          revisionNeededForMusicianIDs,
          utils.revisionStage?.id ?? utils.messageStage?.id
        );
      },
      endDecorator:
        revisionNeededForMusicianIDs.length > 0 ? (
          <Avatar color="warning" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {revisionNeededForMusicianIDs.length}
            </Typography>
          </Avatar>
        ) : undefined,
    });
  }

  // if (criticalChangesForMusicianIDs.length) {
  //   steps.unshift({
  //     id: 20,
  //     group: "followUp",
  //     label: "Send Critical Revision Call",
  //     whatIsIt: () =>
  //       dispatch(setFormOpen({ isOpen: true, formID: "revisionCallInfo" })),
  //     startDecorator: <i className="fa-solid fa-bolt"></i>,
  //     color: "danger",
  //     completed: false,
  //     onClick: () => {
  //       dispatch(layoutUnselectAll());
  //       navigate("mission-control");
  //       dispatch(setMissionControlMode("invite"));
  //       dispatch(setMissionControlViewMode("assignments"));
  //       dispatch(setNotifyMode("communication"));
  //       sendMessage(criticalChangesForMusicianIDs, IMPORTANT_REVISION_ID);
  //     },
  //     endDecorator:
  //       criticalChangesForMusicianIDs.length > 0 ? (
  //         <Avatar color="danger" variant="solid">
  //           <Typography level="body4" sx={{ color: "white" }}>
  //             {criticalChangesForMusicianIDs.length}
  //           </Typography>
  //         </Avatar>
  //       ) : undefined,
  //   });
  // }

  if (replace.length) {
    steps.unshift({
      group: "followUp",
      id: 22,
      label: "Replace Musicans",
      startDecorator: <i className="fa-solid fa-arrows-rotate"></i>,
      color: "warning",
      required: true,
      completed: false,
      onClick: () => {
        if (missionControlMode === "view")
          dispatch(setMissionControlMode("edit"));
        dispatch(setFormOpen({ formID: "replaceMusician", isOpen: true }));
      },
      endDecorator:
        replace.length > 0 ? (
          <Avatar color="warning" variant="solid">
            <Typography level="body4" sx={{ color: "white" }}>
              {replace.length}
            </Typography>
          </Avatar>
        ) : undefined,
      disabled: musicians.length === 0,
    });
  }

  if (releaseRequests.length)
    steps.unshift({
      group: "followUp",
      id: 22,
      label: "Release Request",
      startDecorator: <i className="fa-solid fa-person-from-portal"></i>,
      color: "danger",
      required: true,
      completed: false,
      onClick: () => {
        dispatch(setFormOpen({ formID: "releaseRequests", isOpen: true }));
      },
      endDecorator: (
        <Avatar color="danger" variant="solid">
          <Typography level="body4" sx={{ color: "white" }}>
            {releaseRequests.length}
          </Typography>
        </Avatar>
      ),
    });

  const requiredSteps = steps.filter(
    (s) => s.required && !s.skipInCompletionCalculation && !s.hidden
  );

  const completion = Math.round(
    (requiredSteps.filter((s) => s.completed).length / requiredSteps.length) *
      100
  );

  useEffect(() => {
    setProject(_project);
  }, [_project]);

  if (!project?.id) return <Skeleton sx={{ width: 300, p: 1 }} />;

  const Container = md ? Drawer : Box;

  const getChecklistItem = (s: ChecklistItem) => {
    let startDecorator = s.startDecorator;
    if (s.completed) startDecorator = <i className="fa-solid fa-check"></i>;
    if (s.color === "warning" && !s.startDecorator)
      startDecorator = <i className="fa-solid fa-triangle-exclamation"></i>;
    let tooltip = s.tooltip;
    if (s.tooltipOnlyIfDisabled && !s.disabled) tooltip = undefined;

    return (
      <>
        {s?.loading ? (
          <Skeleton
            sx={{
              width: s.label.length * 10,
              height: 25,
            }}
            key={s.id}
          />
        ) : (
          <Box
            key={s.id}
            sx={{
              display: "flex",
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <Tooltip variant="outlined" size="sm" arrow title={tooltip}>
              <Chip
                onClick={s.onClick}
                disabled={s.disabled || readOnly}
                size="sm"
                endDecorator={
                  s.endDecorator ? (
                    s.endDecorator
                  ) : (
                    <>
                      {!s.required && !s.completed ? (
                        <Typography level="body4">Optional</Typography>
                      ) : (
                        ""
                      )}
                    </>
                  )
                }
                color={s.completed ? "success" : s.color ?? "neutral"}
                variant={s.color || s.completed ? "soft" : "outlined"}
                startDecorator={startDecorator}
              >
                {s.label}
              </Chip>
            </Tooltip>
            {s.whatIsIt ? (
              <Tooltip
                title="What is it?"
                arrow
                variant="outlined"
                size="sm"
                enterDelay={1000}
                enterNextDelay={1000}
              >
                <Box
                  onClick={s.whatIsIt}
                  sx={{
                    fontSize: 11,
                    width: 20,
                    height: 20,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 4,
                    "&:hover": {
                      background: alpha("#9e9e9e", 0.2),
                    },
                    "&:active": {
                      background: alpha("#9e9e9e", 0.3),
                    },
                  }}
                >
                  <i className="fa-solid fa-question"></i>
                </Box>
              </Tooltip>
            ) : (
              []
            )}
          </Box>
        )}
      </>
    );
  };

  const followUp = steps.filter((s) => !s.hidden && s.group === "followUp");
  const build = steps.filter((s) => !s.hidden && s.group === "Build");
  const send = steps.filter((s) => !s.hidden && s.group === "Send");
  const buildCompleted = build.filter((e) => e.completed);
  const buildWarning = build.filter((e) => !e.hidden && e.color === "warning");
  const formerAssignments = assignments.filter((a) => !(a.active ?? true));

  const pieChartData = getPieChartData(assignments, stagesMap);

  const confirmNameChange = () => {
    if (internal.callSent) {
      askQuestion("Are you sure?", ["Cancel", "Yes"], {
        subtitle: (
          <Typography>
            The call has been sent. Make sure to share the new Project name with
            musicians to avoid any confusion.
          </Typography>
        ),
      }).then((i) => {
        if (i == 1) {
          updateProject({
            id: project?.id,
            body: { ...project, name: projectName },
          });
        } else {
          setProjectName(project.name);
        }
      });
    } else {
      updateProject({
        id: project?.id,
        body: { ...project, name: projectName },
      });
    }
  };

  const sendMessage = async (musicianIDs: number[], stageID: number) => {
    const { data } = await getUpcomingStages({
      id: projectID,
      body: {
        musicianIDs,
      },
    });

    const targetAssignments = assignments.filter((a) =>
      musicianIDs.includes(a.musicianID)
    );

    let positionIDs: string[] = [];
    targetAssignments.forEach((a) => {
      positionIDs = internal.getAssignmentPositionIDs(a.id, utils, selection);
    });
    dispatch(selectPositions(positionIDs));
    dispatch(setNotifierBody({ ...body }));
    dispatch(
      setNotifier2({
        selectedStageID: stageID,
        hProject: data,
      })
    );
    dispatch(setFormOpen({ formID: "notifier2", isOpen: true }));
  };

  let backTo = project.template
    ? RouterConfig.templates
    : RouterConfig.projects;
  let backToLabel = project.template ? "Templates" : "Projects";

  if (backToSeason) {
    backTo = `${RouterConfig.seasons}/${backToSeason}`;
    backToLabel = "Season";
  }

  return (
    <>
      <Container
        onClose={() =>
          dispatch(setFormOpen({ formID: "projectSidebar", isOpen: false }))
        }
        open={md && open}
        sx={
          md
            ? undefined
            : { display: "flex", height: "100%", overflowY: "auto" }
        }
      >
        {open ? (
          <Box
            sx={{
              width: 300,
              height: heightWithToolbar,
              pb: 2,
              pl: 2,
              pr: 2,
              flexShrink: 0,
              display: "flex",
              gap: 2,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                pt: 2,
                zIndex: 99,
                background: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "space-between",
                }}
              >
                <Link
                  sx={{ pl: 1 }}
                  component={RouterLink}
                  to={backTo}
                  startDecorator={
                    <i className="fa-regular fa-chevron-left"></i>
                  }
                  onClick={() => dispatch(setBackToSeason())}
                  level="body1"
                  underline="none"
                >
                  {backToLabel}
                </Link>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              {project?.logoURL ? (
                <img
                  src={project?.logoURL}
                  style={{ height: 38, borderRadius: 4 }}
                />
              ) : (
                []
              )}
              <Input
                disabled={readOnly}
                variant="plain"
                onBlur={confirmNameChange}
                // startDecorator={
                //   project?.version === 2 ? (
                //     <Typography color="info" variant="soft" level="body4">
                //       Maestro
                //     </Typography>
                //   ) : undefined
                // }
                onChange={(e) => setProjectName(e.target.value)}
                sx={{
                  "&:hover": {
                    background: "rgba(155,155,155,0.1)",
                  },
                  fontWeight: 600,
                  flex: 1,
                }}
                value={projectName}
                endDecorator={
                  <Id
                    startDecorator={<i className="fa-solid fa-folder-open"></i>}
                  >
                    {project.id}
                  </Id>
                }
              />
            </Box>
            {project?.archived ? (
              <Alert
                sx={{ background: "#C0AE97" }}
                startDecorator={<i className="fa-solid fa-box-archive"></i>}
                color="neutral"
                variant="solid"
              >
                This {project.template ? "template" : "project"} is archived
              </Alert>
            ) : (
              []
            )}
            {chairs.length && debug ? (
              <>
                <Chip
                  startDecorator={<i className="fa-solid fa-robot"></i>}
                  variant="soft"
                  color={autopilot?.enabled ? "success" : "neutral"}
                  endDecorator={autopilot?.enabled ? "On" : "Off"}
                  onClick={() => {
                    if (subscription !== finale) {
                      dispatch(
                        setFormOpen({ formID: "subscribe", isOpen: true })
                      );
                      dispatch(
                        setDescription420(
                          "Autopilot is an exclusive feature to the Finale plan."
                        )
                      );
                    } else {
                      dispatch(
                        setFormOpen({ formID: "autopilot", isOpen: true })
                      );
                    }
                  }}
                >
                  Autopilot
                </Chip>
                <Autopilot projectID={projectID} />{" "}
              </>
            ) : (
              []
            )}
            {!project.template ? (
              <Sheet
                className="tour-checklist"
                variant="outlined"
                sx={{
                  borderRadius: "8px",
                  p: 1,
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                  alignItems: "start",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography level="body2">Checklist:</Typography>
                  {/* <Typography level="body3" color="success">
                    <b>{completion}% Completed</b>
                  </Typography> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "start",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {followUp?.length ? (
                    <>
                      <Divider>Actions Required</Divider>
                      {followUp.map((s) => getChecklistItem(s))}
                    </>
                  ) : (
                    []
                  )}
                  <Divider>
                    <Button
                      onClick={() => setBuildCollapsed((e) => !e)}
                      size="sm"
                      color="neutral"
                      variant="plain"
                      endDecorator={
                        <i
                          style={{
                            fontSize: 10,
                            transform: !buildCollapsed
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                          className="fa-solid fa-chevron-up"
                        ></i>
                      }
                    >
                      <Typography level="body2">Build</Typography>
                    </Button>
                  </Divider>
                  <Collapse in={buildCollapsed}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        gap: 1,
                        alignItems: "start",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      {build.map((s) => getChecklistItem(s))}
                    </Box>
                  </Collapse>
                  {!buildCollapsed ? (
                    <>
                      {buildCompleted.length ? (
                        <Chip
                          onClick={() => setBuildCollapsed((e) => !e)}
                          size="sm"
                          variant="soft"
                          color="success"
                          endDecorator={
                            <Typography level="body4" color="success">
                              Click to expand
                            </Typography>
                          }
                        >
                          {buildCompleted.length} completed items
                        </Chip>
                      ) : (
                        []
                      )}
                      {buildWarning?.length ? (
                        <Chip
                          onClick={() => setBuildCollapsed((e) => !e)}
                          size="sm"
                          variant="soft"
                          color={
                            internal.duplicateMusicianIDs.length
                              ? "danger"
                              : "warning"
                          }
                          endDecorator={
                            <Typography
                              level="body4"
                              color={
                                internal.duplicateMusicianIDs.length
                                  ? "danger"
                                  : "warning"
                              }
                            >
                              Click to expand
                            </Typography>
                          }
                        >
                          {buildWarning.length} warning
                        </Chip>
                      ) : (
                        []
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <Divider>Send</Divider>
                  {send.map((s) => getChecklistItem(s))}
                </Box>
              </Sheet>
            ) : (
              []
            )}
            {musicianTalkback.length ? (
              <Sheet
                variant="outlined"
                sx={{
                  borderRadius: "8px",
                  p: 1,
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                  alignItems: "stretch",
                  position: "relative",
                  textAlign: "left",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(
                    setFormOpen({
                      isOpen: true,
                      formID: "musicianTalkback",
                    })
                  )
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography level="body2">
                      {musicianTalkback.length} Messages:
                    </Typography>
                    <Typography level="body4">Left by musicians</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {newTalkback.length ? (
                      <Typography variant="soft" color="danger" level="body2">
                        {newTalkback.length} New
                      </Typography>
                    ) : (
                      []
                    )}
                    <Tooltip title="View" size="sm" variant="outlined" arrow>
                      <IconButton size="sm" color="neutral" variant="plain">
                        <i className="fa-regular fa-arrow-up-right"></i>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Sheet>
            ) : (
              []
            )}
            {assignments.length && !project?.template ? (
              <Sheet
                variant="outlined"
                sx={{
                  borderRadius: "8px",
                  p: 1,
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography level="body2">Hiring Progress:</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <PieChart
                    colors={pieChartData.palette}
                    series={[
                      {
                        innerRadius: 55,
                        outerRadius: 70,
                        paddingAngle: 5,
                        data: pieChartData.data,
                        cornerRadius: 8,
                      },
                    ]}
                    sx={{
                      background: "white",
                      border: "solid 2px rgba(155, 155,155,0.2)",
                      borderRadius: 70,
                      position: "absolute",
                      width: 140,
                      top: 0,
                      p: 1,
                    }}
                    margin={{ right: 5 }}
                    width={140}
                    height={140}
                    legend={{ hidden: true }}
                  />
                  <Box>
                    {pieChartData.labels?.map((l) => (
                      <Typography
                        startDecorator={<img src={l.icon} height="12px" />}
                        level="body3"
                        key={l.label}
                      >
                        {l.count} {l.label}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Sheet>
            ) : (
              []
            )}
            <Sheet
              variant="outlined"
              sx={{
                borderRadius: "8px",
                p: 1,
                display: "flex",
                gap: 1,
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography level="body2">Internal Memo:</Typography>
              <Textarea
                disabled={readOnly}
                placeholder={`Anything to write down about this ${
                  project.template ? "Template" : "Project"
                }?`}
                sx={{ width: "100%", background: "rgba(255,235,59,0.1)" }}
                onChange={(e) =>
                  setProject((p) => ({ ...p, description: e.target.value }))
                }
                value={project.description ?? ""}
                onBlur={() => {
                  updateProject({
                    id: project.id,
                    body: { ...project, description: project.description },
                  });
                }}
                size="sm"
                endDecorator={
                  <Typography level="body4">
                    Not visible by musicians
                  </Typography>
                }
              />
            </Sheet>
            {formerAssignments?.length ? (
              <Sheet
                variant="outlined"
                sx={{
                  borderRadius: "8px",
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography level="body2">
                      {formerAssignments.length} Removed:
                    </Typography>
                    <Typography level="body4">
                      Musicians no longer in Project.
                    </Typography>
                  </Box>
                  <Tooltip title="View" size="sm" variant="outlined" arrow>
                    <IconButton
                      onClick={() => {
                        dispatch(
                          setFormOpen({
                            formID: "formerAssignments",
                            isOpen: true,
                          })
                        );
                      }}
                      size="sm"
                      color="neutral"
                      variant="plain"
                    >
                      <i className="fa-regular fa-arrow-up-right"></i>
                    </IconButton>
                  </Tooltip>
                </Box>
                {/* <Box sx={{ maxHeight: 400, overflow: "auto" }}>
                  <List size="sm">
                    {formerAssignments.map((a) => {
                      const musician = musiciansMap[a.musicianID];
                      const stage = stagesMap[a.mercuryStageID];
                      const job = hProject.jobs.find(
                        (j) => j.uuid === a.mercuryJobID
                      );

                      if (!stage) return <></>;

                      return (
                        <ListItemButton
                          className={`musician-${a.musicianID}`}
                          onClick={() => {
                            dispatch(setMercuryJobIDForHistory(a.mercuryJobID));
                            dispatch(
                              setFormOpen({
                                isOpen: true,
                                formID: "mercuryHistory",
                              })
                            );
                          }}
                          key={a.id}
                          sx={{ gap: 0.5 }}
                        >
                          <MusicianAvatar musician={musician} size={25} />
                          <Box>
                            <Typography level="body2">
                              {musician?.fullName()}
                            </Typography>
                            <Typography level="body4">
                              {stage?.wording()},{" "}
                              {moment(job?.currentStage?.createdAt).fromNow()}
                            </Typography>
                          </Box>
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Box> */}
              </Sheet>
            ) : (
              []
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ pb: 4 }}>
              {project.tags?.map((t) => (
                <Button
                  fullWidth
                  onClick={() =>
                    navigate(`${RouterConfig.seasons}/${t.id}/missions`)
                  }
                  startDecorator={
                    t.image ? (
                      <img
                        src={t.image}
                        style={{ width: 30, height: 30, borderRadius: 4 }}
                      />
                    ) : undefined
                  }
                  size="sm"
                  variant="outlined"
                  color="neutral"
                  key={t.id}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      level="body4"
                      startDecorator={<i className="fa-solid fa-ticket"></i>}
                    >
                      Season
                    </Typography>
                    <Typography level="body2">{t.name}</Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />
                </Button>
              ))}
            </Box>
          </Box>
        ) : (
          []
        )}
      </Container>
      <Box
        sx={{ position: "absolute", bottom: "8px", zIndex: 999, left: "8px" }}
      >
        <Tooltip
          title={`${open ? "Close" : "Open"} ${
            project.template ? "Template" : "Project"
          } Sidebar`}
          placement="right"
          arrow
        >
          <IconButton
            onClick={() => {
              dispatch(
                setFormOpen({ formID: "projectSidebar", isOpen: !open })
              );
            }}
            sx={{ width: 14, transform: open ? "rotate(180deg)" : "" }}
            size={open ? "sm" : "md"}
            variant={open ? "soft" : "solid"}
            color={open ? "neutral" : "primary"}
          >
            <i className="fa-solid fa-chevrons-right"></i>
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" />
      </Box>
    </>
  );
}
