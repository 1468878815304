import { Instrument } from "entities/instrument";
import { useMemo } from "react";
import {
  useGetInstrumentQuery,
  useGetInstrumentsQuery,
} from "./instrumentEndpoints";

export function useInstrument(instrumentID: number | undefined) {
  const instrumentQuery = useGetInstrumentQuery(instrumentID ?? 0, {
    skip: (instrumentID ?? 0) <= 0,
  });
  const instrumentEntity = instrumentQuery.data;

  return useMemo(() => {
    const ret: typeof instrumentQuery & {
      instrument?: Instrument | undefined;
    } = {
      ...instrumentQuery,
    };
    if (instrumentEntity) ret.instrument = new Instrument(instrumentEntity);
    return ret;
  }, [instrumentEntity]);
}

export function useInstruments(opt?: { skip: boolean }) {
  const instrumentQuery = useGetInstrumentsQuery();
  const instrumentEntitiesMap = instrumentQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof instrumentQuery & {
      instruments?: Instrument[];
      instrumentsMap?: { [id: number]: Instrument };
    } = {
      ...instrumentQuery,
      instruments: [],
      instrumentsMap: {},
    };
    if (instrumentEntitiesMap) {
      const instruments = [];
      const instrumentsMap = {};

      for (const key in instrumentEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(instrumentEntitiesMap, key)) {
          const c = instrumentEntitiesMap[key];
          const instrument = new Instrument(c);
          instruments.push(instrument);
          instrumentsMap[instrument.id] = instrument;
        }
      }
      ret.instruments = instruments;
      ret.instrumentsMap = instrumentsMap;
    }

    return ret;
  }, [instrumentEntitiesMap]);
}
