import { Link, Typography } from "@mui/joy";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import ProjectsDataGrid from "features/dataGrids/ProjectsDataGrid";
import WorkSessionsDataGrid from "features/dataGrids/WorkSessionsDataGrid/WorkSessionsDataGrid";
import { useRouteMatch } from "helpers";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import Calendar from "hooks/Calendar";
import VenueDangerZone from "hooks/VenueDangerZone";
import VenueGeneral from "hooks/VenueGeneral";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link as RouterLink, Route, Routes, useParams } from "react-router-dom";
import { setSelectedVenueID } from "reducers/rhapsody";
import { useVenue } from "redux/venue/venueHooks";

export default function VenuePage({ id }) {
  const params = useParams();
  const dispatch = useDispatch();
  const venueID = id || parseInt(params.venueID);
  const { venue } = useVenue(venueID);

  useEffect(() => {
    return () => {
      dispatch(setSelectedVenueID());
    };
  }, []);

  if (!venue) return <div />;

  const routeMatch = useRouteMatch([
    "/venues/:id/general",
    "/venues/:id/calendar",
    "/venues/:id/projects",
    "/venues/:id/work-sessions",
    "/venues/:id/danger-zone",
  ]);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        flexDirection: "column",
        margin: "auto",
        maxWidth: 1600,
      }}
    >
      <Helmet>Rhapsody | {venue.name}</Helmet>
      <Box>
        <Link
          component={RouterLink}
          to={RouterConfig.venues}
          level="body1"
          underline="none"
        >
          Venues
        </Link>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography level="h5">{venue.name}</Typography>
        </Box>
        <MyTabs routeMatch={routeMatch} />
        <Divider />
      </Box>
      <Routes>
        <Route
          path={"general"}
          element={
            <Box
              sx={{ p: 2, display: "flex", gap: 2, flexDirection: "column" }}
            >
              <Typography level="h6">Details</Typography>
              <Divider />
              <VenueGeneral id={venueID} />
              <Typography level="h6">Related Projects</Typography>
              <Divider />
              <ProjectsDataGrid venueID={venueID} />
              <Typography level="h6">Related Work Sessions</Typography>
              <Divider />
              <WorkSessionsDataGrid venueID={venueID} />
              <Typography level="h6">Danger Zone</Typography>
              <Divider />
              <VenueDangerZone venueID={venueID} />
            </Box>
          }
        ></Route>
        <Route
          path={"calendar"}
          element={
            <Box
              sx={{
                p: 2,
                height: "calc(100vh - 250px)",
                minHeight: 800,
                position: "relative",
                display: "flex",
              }}
            >
              <Calendar venueID={venueID} />
            </Box>
          }
        ></Route>
      </Routes>
    </Box>
  );
}

function MyTabs({ routeMatch }) {
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Tabs value={currentTab}>
      <Tab
        label="General"
        value="/venues/:id/general"
        to="general"
        component={RouterLink}
      />
      <Tab
        label="Calendar"
        value="/venues/:id/calendar"
        to="calendar"
        component={RouterLink}
      />
    </Tabs>
  );
}
