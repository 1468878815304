import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Tie_Entity } from "entities/tie";
import { rhapsodyApi } from "../api/rhapsodyApi";

const tiesAdapter = createEntityAdapter<Tie_Entity>();
const initialState = tiesAdapter.getInitialState();

export const tieEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getTies: build.query<EntityState<Tie_Entity>, void>({
      query: () => `linkTies`,
      transformResponse: (responseData: Tie_Entity[]) => {
        return tiesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["ties"],
    }),
    getTie: build.query<Tie_Entity, number>({
      query: (id) => `linkTies/${id}`,
      providesTags: (result, error, id) => [{ type: "ties", id }],
    }),
    createTie: build.mutation<Tie_Entity, Partial<Tie_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `linkTies`,
      }),
      invalidatesTags: ["ties", "links"],
    }),
    updateTie: build.mutation<void, { id: number; body: Partial<Tie_Entity> }>({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `linkTies/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "ties", id },
        "ties",
      ],
    }),
    deleteTie: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `linkTies/${id}`,
      }),
      invalidatesTags: ["ties", "links"],
    }),
  }),
});

export const {
  useGetTieQuery,
  useGetTiesQuery,
  useCreateTieMutation,
  useDeleteTieMutation,
  useUpdateTieMutation,
} = tieEndpoints;

export default tieEndpoints;
