import { Button, Divider, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { MusicianTalkbackList } from "hooks/MusicianTalkbackList/MusicianTalkbackList";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { rhapsodyApi } from "redux/api";

/**
 *
 * @returns {ReactElement} MusicianTalkback page
 */
export function MusicianTalkback() {
  const open = useSelector(formOpenSelector("musicianTalkback"));
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(rhapsodyApi.util.invalidateTags(["projectMercury"]));
    dispatch(setFormOpen({ isOpen: false, formID: "musicianTalkback" }));
  };

  return (
    <DialogClose open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography level="h6">Messages</Typography>
        <Typography
          startDecorator={<i className="fa-solid fa-eye"></i>}
          level="body2"
        >
          Click on messages to mark them as read
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <MusicianTalkbackList />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="neutral" variant="soft">
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
