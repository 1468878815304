import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import { DanielsFragment_Entity } from "entities/danielsFragment";
import { DanielsWork } from "entities/danielsWork";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useCompanySettings } from "redux/company/companyHooks";
import { useLazyDanielsLookupQuery } from "redux/danielsFragment/danielsFragmentEndpoints";
import { useDanielsWork } from "redux/danielsWork/danielsWorkHooks";

/**
 *
 * @returns {ReactElement} ImportFromDaniels page
 */
export function ImportFromDaniels({
  name: _name,
  composer: _composer,
  onChange,
}: {
  name: string;
  composer: string;
  onChange: (
    e: Dictionary<Partial<RhapsodyChair_Entity>[]>,
    v: DanielsWork
  ) => void;
}) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(_name);
  const [composer, setComposer] = useState(_composer);
  const utils = useSelector(layoutUtilsSelector);
  const [fragments, setFragments] = useState<DanielsFragment_Entity[]>([]);
  const [selectedDanielsFragment, setSelectedDanielsFragment] =
    useState<DanielsFragment_Entity>();
  const [loading, setLoading] = useState(false);
  const [getFragments] = useLazyDanielsLookupQuery();
  const askQuestion = useAskQuestion();
  const settings = useCompanySettings();
  if (_composer.includes(",")) _composer = _composer.split(",")[0];

  useEffect(() => {
    if (title || composer) searchFragments();
  }, []);

  const searchFragments = () => {
    setLoading(true);
    getFragments({
      filters: JSON.stringify(genFilters()),
    })
      .unwrap()
      .then((e) => {
        setFragments(
          e.ids.reduce((a, v) => {
            a.push(e.entities[v]);
            return a;
          }, [])
        );
        setLoading(false);
      });
  };

  const genFilters = () => {
    const filters = [];

    if (composer) {
      filters.push({
        name: "composer",
        comparison: "like",
        value: composer.toLocaleLowerCase(),
      });
    }
    if (title) {
      filters.push({
        name: "work",
        comparison: "like",
        value: title.toLocaleLowerCase(),
      });
    }

    return filters;
  };

  const {
    danielsWork,
    isLoading: workLoading,
    isFetching: workFetching,
  } = useDanielsWork(parseInt(`${selectedDanielsFragment?.id}`));

  const onClose = () => {
    setOpen(false);
    setSelectedDanielsFragment(undefined);
  };

  return (
    <>
      <Tooltip
        size="sm"
        arrow
        variant="outlined"
        title="Retrieve instrumentation from daniels-orchestral.com"
      >
        <Button
          onClick={() => setOpen(true)}
          size="sm"
          color="neutral"
          variant="plain"
          startDecorator={
            <img
              height="15"
              src={
                "https://storage.googleapis.com/rhapsody_alpha/data-upload/929c2650-15cc-44e8-bf60-e4b81b893fee/OM-logo-transparent-bg.png"
              }
            />
          }
          endDecorator={<i className="fa-solid fa-arrow-down"></i>}
        >
          Import from Daniels
        </Button>
      </Tooltip>
      <DialogClose open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>Import from Daniels</DialogTitle>
        <DialogContent>
          {selectedDanielsFragment ? (
            <>
              <Button
                size="sm"
                variant="plain"
                startDecorator={<i className="fa-solid fa-chevron-left"></i>}
                onClick={() => {
                  setSelectedDanielsFragment(undefined);
                }}
              >
                Back
              </Button>
              <Sheet
                invertedColors
                variant="solid"
                color="primary"
                sx={{
                  height: 500,
                  p: 1,
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography sx={{ color: "inherit" }} level="body1">
                    <b>{danielsWork?.title}</b>
                  </Typography>
                  <List size="sm">
                    <ListItem>
                      <ListItemContent>Composed in: </ListItemContent>
                      <Typography level="body2">
                        {danielsWork?.composed_from}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemContent>Duration:</ListItemContent>
                      <Typography level="body2">
                        {danielsWork?.duration}
                      </Typography>
                    </ListItem>
                    <ListItem
                      sx={{ flexDirection: "column", alignItems: "start" }}
                    >
                      <ListItemContent>Shorthand:</ListItemContent>
                      <Typography level="body2">
                        {danielsWork?.formula}
                      </Typography>
                    </ListItem>
                    <ListItem
                      sx={{ flexDirection: "column", alignItems: "start" }}
                    >
                      <ListItemContent>Percussion Info:</ListItemContent>
                      <Typography level="body2">
                        {danielsWork?.perc_info}
                      </Typography>
                    </ListItem>
                    {danielsWork?.additional_info ? (
                      <ListItem
                        sx={{ flexDirection: "column", alignItems: "start" }}
                      >
                        <ListItemContent>Additional Info:</ListItemContent>
                        <Typography level="body2">
                          {danielsWork?.additional_info}
                        </Typography>
                      </ListItem>
                    ) : (
                      []
                    )}
                  </List>
                </Box>
                {workFetching || workLoading ? <CircularProgress /> : []}
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  fullWidth
                  variant="solid"
                  onClick={() => {
                    const { chairs, unkownSections } =
                      danielsWork.instrumentation(
                        utils,
                        danielsWork.formula,
                        settings.defaultStrings
                      );
                    onChange(chairs, danielsWork);
                    if (unkownSections.length) {
                      askQuestion("Warning", ["Ok"], {
                        subtitle: (
                          <Typography>
                            Daniels returned unkown section(s):{" "}
                            <Typography color="primary">
                              <b>{unkownSections.join(", ")}</b>
                            </Typography>
                            . Please double check the import.
                            <br />
                            <br />
                            For more information, see the Daniels' abbreviation
                            dictionary:
                            <br />
                            <a href="https://daniels-orchestral.com/other-resources/abbreviations/">
                              https://daniels-orchestral.com/other-resources/abbreviations/
                            </a>
                          </Typography>
                        ),
                      });
                    }
                    onClose();
                  }}
                >
                  Use
                </Button>
              </Sheet>
            </>
          ) : (
            <Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Sheet
                  variant="soft"
                  color="neutral"
                  sx={{ borderRadius: "8px", flexGrow: 1 }}
                >
                  <Input
                    value={title}
                    sx={{
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    onChange={(e) => setTitle(e.target.value)}
                    endDecorator={
                      title ? (
                        <IconButton
                          color="neutral"
                          sx={{ opacity: 0.3 }}
                          variant="plain"
                          onClick={(e) => setTitle("")}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </IconButton>
                      ) : undefined
                    }
                    size="sm"
                    variant="soft"
                    color="neutral"
                    placeholder="Title"
                  />
                  <Divider />
                  <Input
                    value={composer}
                    sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                    onChange={(e) => setComposer(e.target.value)}
                    endDecorator={
                      composer ? (
                        <IconButton
                          color="neutral"
                          variant="plain"
                          sx={{ opacity: 0.3 }}
                          onClick={(e) => setComposer("")}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </IconButton>
                      ) : undefined
                    }
                    size="sm"
                    variant="soft"
                    color="neutral"
                    placeholder="Composer"
                  />
                </Sheet>
                <IconButton variant="solid" onClick={searchFragments}>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </IconButton>
              </Box>
              <Box sx={{ height: 500 }}>
                <List>
                  {fragments?.map((d) => (
                    <ListItemButton
                      onClick={() => {
                        setSelectedDanielsFragment(d);
                      }}
                      key={d.work_domo_uid}
                    >
                      <Box>
                        <Typography level="body1">{d.title}</Typography>
                        <Typography level="body2">{d.composer}</Typography>
                      </Box>
                    </ListItemButton>
                  ))}
                  {fragments.length === 0 && !loading && (title || composer) ? (
                    <Box sx={{ textAlign: "center", mt: 5 }}>
                      <Typography>
                        <b>No Match</b>
                      </Typography>
                      <Typography level="body2">
                        Try to adjust the search parameters
                      </Typography>
                    </Box>
                  ) : (
                    []
                  )}
                  {loading ? (
                    <Box sx={{ textAlign: "center", mt: 5 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    []
                  )}
                </List>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="soft" color="neutral">
            Cancel
          </Button>
        </DialogActions>
      </DialogClose>
    </>
  );
}
