import { PdItem_Entity } from "entities/pdItem";

/**
 * PdGroup_Entity type guard.
 *
 * @param {any} pdGroupJson PdGroup object from API
 * @returns {boolean} Return true if type is PdGroup_Entity
 */
export default class PdGroup_Entity {
  constructor(groupJson: PdGroup_Entity) {
    try {
      this.id = groupJson.id;
      this.projectID = groupJson.projectID;
      this.name = groupJson.name;
      this.description = groupJson.description;
      this.subtotal = groupJson.subtotal;
      this.reservedKey = groupJson.reservedKey;
      this.position = groupJson.position;
      this.subgroups = groupJson.subgroups;
      this.items = groupJson.items;
      this.color = groupJson.color;
      this.createdAt = groupJson.createdAt;
      this.updatedAt = groupJson.updatedAt;
    } catch (e) {
      console.log(`Failed to create new instance of ${PdGroup_Entity.name}`);
    }
  }
  id: number;
  projectID: number;
  name: string;
  description: string;
  subtotal: number;
  reservedKey: string;
  position: number;
  color: string;
  subgroups: Array<PdGroup_Entity>;
  items: Array<PdItem_Entity>;
  createdAt: string;
  updatedAt: string;
}
