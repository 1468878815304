// import PropTypes from 'prop-types';

// styles
import SlidingDialog from "hooks/SlidingDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedEmailIDSelector,
  selectedMusicianIDSelector,
  selectedProjectIDSelector,
  selectedWorkSessionIDSelector,
  setSelectedEmailID,
  setSelectedMusicianID,
  setSelectedWorkSessionID,
} from "reducers/rhapsody";
import { useProject } from "redux/project/projectHooks";
import EmailPage from "routes/email/email";
import MusicianPage from "routes/musician/musician";
import WorkSession from "routes/workSession/workSession";

export default function Openner({ children }) {
  const projectID = useSelector(selectedProjectIDSelector);
  const { project } = useProject(projectID);
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const musicianID = useSelector(selectedMusicianIDSelector);
  const emailID = useSelector(selectedEmailIDSelector);
  const dispatch = useDispatch();

  let v2 = project?.version === 2;

  return (
    <>
      {children}
      <div>
        <SlidingDialog
          open={workSessionID && !v2}
          onClose={() => dispatch(setSelectedWorkSessionID())}
        >
          {workSessionID && <WorkSession id={workSessionID} />}
        </SlidingDialog>
        <SlidingDialog
          open={musicianID}
          onClose={() => dispatch(setSelectedMusicianID())}
        >
          {musicianID && <MusicianPage id={musicianID} />}
        </SlidingDialog>
        <SlidingDialog
          open={emailID}
          onClose={() => dispatch(setSelectedEmailID())}
        >
          {emailID && <EmailPage id={emailID} header={false} />}
        </SlidingDialog>
      </div>
    </>
  );
}
