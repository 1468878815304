import { Job_Entity } from "entities/job";

/**
 * WorkSessionSection_Entity type guard.
 *
 * @param {any} workSessionSectionJson WorkSessionSection object from API
 * @returns {boolean} Return true if type is WorkSessionSection_Entity
 */

export default class WorkSessionSection_Entity {
  constructor(workSessionSectionJson: WorkSessionSection_Entity) {
    try {
      this.id = workSessionSectionJson.id;
      this.sectionID = workSessionSectionJson.sectionID;
      this.sectionName = workSessionSectionJson.sectionName;
      this.sectionPos = workSessionSectionJson.sectionPos;
      this.familyID = workSessionSectionJson.familyID;
      this.familyName = workSessionSectionJson.familyName;
      this.familyPos = workSessionSectionJson.familyPos;
      this.sessionID = workSessionSectionJson.sessionID;
      this.jobs = workSessionSectionJson.jobs;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  sectionID: number;
  sectionName: string;
  sectionPos: number;
  familyID: number;
  familyName: string;
  familyPos: number;
  sessionID: number;
  jobs?: Job_Entity[];
}
