import { WorkSessionMercury_Entity } from "entities/workSessionMercury";
import { rhapsodyApi } from "../api/rhapsodyApi";

export const workSessionMercuryEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkSessionMercury: build.query<WorkSessionMercury_Entity, number>({
      query: (id) => `workSessions/${id}/mercury`,
      providesTags: (result, error, id) => [{ type: "workSessionMercury", id }],
    }),
  }),
});

export const { useGetWorkSessionMercuryQuery } = workSessionMercuryEndpoints;

export default workSessionMercuryEndpoints;
