import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedProjectIDSelector, setFormOpen } from "reducers/rhapsody";
import { useMusicians } from "redux/musician/musicianHooks";
import { useProject } from "redux/project/projectHooks";
import { useProjectMercury } from "redux/projectMercury/projectMercuryHooks";
import { Indexes, addWorksheet, createWorkbook, downloadExcel } from "../utils";
import { useDispatch } from "react-redux";

export function useDownloadProjectRemovedMusiciansExcel() {
  const [trigger, setTrigger] = useState(false);
  const [projectID, setProjectID] = useState<number>();
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const { projectMercury, isSuccess: s1 } = useProjectMercury(
    projectID ?? selectedProjectID,
    {
      skip: !trigger,
    }
  );

  const { project, isSuccess: s2 } = useProject(
    projectID ?? selectedProjectID,
    {
      skip: !trigger,
    }
  );

  const { musiciansMap, isSuccess: s3 } = useMusicians(undefined, {
    skip: !trigger,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (trigger && s1 && s2 && s3) {
      setTrigger(false);
      download();
    }
  }, [trigger, s1, s2, s3]);

  function download() {
    const workbook = createWorkbook();
    const fileName = `${project.name} - Removed Musicians`;
    const worksheet = addWorksheet(workbook, "Removed Musicians");

    const musicianIDs =
      projectMercury?.formerJobs?.reduce((a, j) => {
        a[j.musician?.id] = true;
        return a;
      }, {}) ?? {};

    const musicianIDsArray = Object.keys(musicianIDs);
    const musicians = [];
    musicianIDsArray.forEach((s) => {
      const musicianID = parseInt(s);
      const musician = musiciansMap[musicianID];

      if (musician) {
        musicians.push(musician);
      }
    });
    musicians.sort((a, b) => a.lastName?.localeCompare(b.lastName));
    const indexes = new Indexes(worksheet);
    indexes.text(`Removed Musicians for Project: ${project.name}`);
    indexes.bold();
    indexes.fontSize(24);
    indexes.nextRow();
    indexes.text(
      "An removed musician is someone who has declined, being cancelled or released for at least 1 Work Session on this project."
    );
    indexes.nextRow();
    indexes.nextRow();
    indexes.width(20);

    indexes.text("Musician");
    indexes.header();
    indexes.nextColumn();
    indexes.width(30);

    indexes.text("Email");
    indexes.header();
    indexes.nextColumn();
    indexes.width(20);

    indexes.text("Phone");
    indexes.header();
    indexes.nextColumn();
    indexes.goToColumn(0);
    indexes.nextRow();

    musicians.forEach((musician) => {
      if (musician) {
        indexes.text(musician.fullName());
        indexes.nextColumn();
        indexes.text(musician.email);
        indexes.nextColumn();
        indexes.text(musician.phone ?? "");
        indexes.goToColumn(0);
      }
      indexes.nextRow();
    });
    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
    downloadExcel(workbook, fileName);
  }

  return (_projectID?: number) => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setProjectID(_projectID);
    setTrigger(true);
  };
}
