import WorkSessionType_Entity from "./workSessionType";
export default class WorkSessionType extends WorkSessionType_Entity {
  static fromList(workSessionTypesJSON: unknown): Array<WorkSessionType> {
    const workSessionTypes: WorkSessionType[] = [];
    if (workSessionTypesJSON)
      Array.isArray(workSessionTypesJSON) &&
        workSessionTypesJSON.forEach((workSessionTypeJSON) => {
          workSessionTypes.push(new WorkSessionType(workSessionTypeJSON));
        });
    return workSessionTypes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
