import { InternalPosition } from "entities/internal/InternalPosition";
import { Piece } from "entities/piece";
import { ProjectPiece } from "entities/projectPiece";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { WorkSession } from "entities/workSession";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { mapToArray } from "helpers";
import moment from "moment";
import Selection_Entity from "./selection";
export default class Selection extends Selection_Entity {
  workSessionIDs: number[];
  projectPieceIDs: number[];
  selectedWorkSessions: WorkSession[];
  unselectedWorkSessions: WorkSession[];
  selectedProjectPieces: ProjectPiece[];
  selectedPieces: Piece[];
  allWorkSessionsSelected: boolean;
  allProjectPiecesSelected: boolean;

  constructor(json: Selection_Entity, utils: LayoutUtils) {
    super(json);
    this.workSessionIDs = [];
    this.projectPieceIDs = [];
    this.selectedWorkSessions = [];
    this.selectedProjectPieces = [];
    this.selectedPieces = [];
    if (utils) {
      const allWorkSessions = mapToArray(utils.workSessionsMap);
      this.workSessionIDs = Object.keys(this.selectedWorkSessionIDs)
        .map((e) => {
          if (this.selectedWorkSessionIDs[e]) return parseInt(e);
        })
        .filter((e) => e);
      this.unselectedWorkSessions = allWorkSessions.reduce((a, v) => {
        if (!this.workSessionIDs.includes(v.id)) a.push(v);
        return a;
      }, []);
      this.allWorkSessionsSelected =
        this.workSessionIDs.length ===
        Object.keys(utils.workSessionsMap).length;
      this.projectPieceIDs = Object.keys(this.selectedProjectPieceIDs)
        .map((e) => {
          if (this.selectedProjectPieceIDs[e]) return parseInt(e);
        })
        .filter((e) => e);
      this.allProjectPiecesSelected =
        this.projectPieceIDs.length === Object.keys(utils.projectPieces).length;
      this.selectedWorkSessions = this.workSessionIDs.reduce((a, v) => {
        if (utils.workSessionsMap[v])
          a.push(new WorkSession(utils.workSessionsMap[v]));
        return a;
      }, []);
      this.selectedWorkSessions.sort(
        (a, b) =>
          moment(a.dateFromUTC).valueOf() - moment(b.dateFromUTC).valueOf()
      );

      this.selectedProjectPieces = this.projectPieceIDs.reduce((a, v) => {
        if (utils.projectPiecesMap[v])
          a.push(new ProjectPiece(utils.projectPiecesMap[v]));
        return a;
      }, []);
      this.selectedProjectPieces.sort((a, b) => a.position - b.position);

      this.selectedPieces = this.selectedProjectPieces.reduce((a, v) => {
        if (utils.piecesMap[v.pieceID])
          a.push(new Piece(utils.piecesMap[v.pieceID]));
        return a;
      }, []);
    }
  }

  userIsAboutToCreateWorkSessionChairs(
    selectedPositions: InternalPosition[],
    positionIDs: string[],
    utils: LayoutUtils
  ) {
    const dispatcher = this.getDispatcher(
      selectedPositions,
      positionIDs,
      utils
    );
    return dispatcher.create.length > 0;
  }

  workSessionCountSelectionIsAbout(
    selectedPositions: InternalPosition[],
    positionIDs: string[],
    utils: LayoutUtils
  ) {
    const dispatcher = this.getDispatcher(
      selectedPositions,
      positionIDs,
      utils
    );
    return dispatcher.create.reduce((a, v) => {
      if (!a.includes(v.workSessionID)) a.push(v.workSessionID);
      return a;
    }, []);
  }

  getDispatcher(
    selectedPositions: InternalPosition[],
    positionIDs: string[],
    utils: LayoutUtils
  ) {
    const ret: {
      update: RhapsodyChair_Entity[];
      create: RhapsodyChair_Entity[];
    } = {
      update: [],
      create: [],
    };
    selectedPositions.forEach((position) => {
      const { visibleChairIDs, forWorkSessionID, forProjectPieceID } = position;

      visibleChairIDs.forEach((chairID) => {
        const _c = utils.chairsMap[chairID];
        const _chair = { ..._c, forWorkSessionID, forProjectPieceID };

        const chairAlreadyInCreate =
          ret.create?.findIndex((e) => e.id === _chair?.id) >= 0;
        const chairAlreadyInUpdate =
          ret.update?.findIndex((e) => e.id === _chair?.id) >= 0;
        const chairPlayingOnUnselectedWorkSessions =
          this.unselectedWorkSessions.reduce((a, v) => {
            if (_chair?.workSessionIDs.includes(v.id)) a = true;
            return a;
          }, false);

        // work session side by side case:
        if (forWorkSessionID) {
          // work session chairs case:
          if (
            _chair?.workSessionID &&
            _chair?.workSessionID === forWorkSessionID
          ) {
            if (!chairAlreadyInUpdate) ret.update.push(_chair);
          }
          // regular chairs
          else {
            // if the whole row is selected, no need to create
            let rowSelected = true;
            _chair?.workSessionIDs.forEach((workSessionID) => {
              if (
                !positionIDs.includes(
                  `${_chair?.sectionID}|${
                    _chair?.sectionOrder
                  }|${workSessionID}|${forProjectPieceID ?? 0}`
                )
              ) {
                rowSelected = false;
              }
            });
            if (rowSelected) {
              if (!chairAlreadyInUpdate) ret.update.push(_chair);
            } else {
              if (!chairAlreadyInCreate)
                ret.create.push({
                  ..._chair,
                  workSessionID: forWorkSessionID,
                });
            }
          }
        }
        // grouped case:
        else {
          // work session chairs case:
          if (
            _chair?.workSessionID &&
            _chair?.workSessionID === forWorkSessionID
          ) {
            if (!chairAlreadyInUpdate) ret.update.push(_chair);
          }
          // regular chairs
          else {
            if (
              this.allWorkSessionsSelected ||
              !chairPlayingOnUnselectedWorkSessions
            ) {
              if (!chairAlreadyInUpdate) ret.update.push(_chair);
            } else {
              if (chairPlayingOnUnselectedWorkSessions)
                this.selectedWorkSessions.forEach((w) => {
                  if (
                    _chair?.workSessionIDs.includes(w.id) &&
                    !chairAlreadyInCreate
                  )
                    ret.create.push({ ..._chair, workSessionID: w.id });
                });
            }
          }
        }
      });
    });
    return ret;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
