import { Typography } from "@mui/joy";
import { Box, DialogContent, alpha } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Internal } from "entities/internal";
import { getInternal } from "entities/internal/helper";
import { RhapsodyChair } from "entities/rhapsodyChair";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { mapToArray } from "helpers";
import { useMissionControlModeTint } from "hooks/Layout/v2";
import hotkeys from "hotkeys-js";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  missionControlModeSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  assignmentIDForLookupSelector,
  layoutInternalSelector,
  layoutUnselectAll,
  layoutUtilsSelector,
  selectionSelector,
  setAssignmentIDForLookup,
  setInternalPositionForLookup,
} from "reducers/v2/missionControl";
import { CHAIR_WIDTH, Position } from "./position";
import { useLocation } from "react-router-dom";

/**
 *
 * @returns {ReactElement} ChairsLookup page
 */
export function AssignmentLookup() {
  const assignmentID = useSelector(assignmentIDForLookupSelector);
  const location = useLocation();
  const open = useSelector(formOpenSelector("assignmentLookup"));
  const utils = useSelector(layoutUtilsSelector);
  const internal = useSelector(layoutInternalSelector);
  const selection = useSelector(selectionSelector);
  const dispatch = useDispatch();
  const missionControlModeTint = useMissionControlModeTint();
  const onClose = () => {
    if (season) {
      dispatch(layoutUnselectAll());
    }
    dispatch(setFormOpen({ isOpen: false, formID: "assignmentLookup" }));
    dispatch(setAssignmentIDForLookup(null));
    dispatch(setInternalPositionForLookup());
  };
  const missionControlMode = useSelector(missionControlModeSelector);
  let className = "";
  if (missionControlMode === "edit") className = "missionControlEdit";
  if (missionControlMode === "invite") className = "missionControlInvite";

  const season = location.pathname.includes("seasons");

  hotkeys("esc", close);

  const chairsMap: Dictionary<RhapsodyChair> = internal.positions.reduce(
    (a, v) => {
      v.chairIDs.forEach((c) => {
        const _chair = utils.chairsMap[c];
        a[_chair?.id] = _chair;
      });
      return a;
    },
    {}
  );

  const chairs = mapToArray(chairsMap).filter(
    (c) => c.assignmentID === assignmentID
  );

  const firstChair: RhapsodyChair | undefined = chairs.length
    ? chairs[0]
    : undefined;
  const musician = utils.musiciansMap[firstChair?.musicianID];

  const getPosition = (workSessionID, projectpieceID) => {
    const pChairs = chairs.filter(
      (c) =>
        c.workSessionIDs.includes(workSessionID) &&
        c.projectPieceID === projectpieceID
    );

    if (pChairs.length === 0)
      return (
        <Typography level="body2">
          <i>Not Playing</i>
        </Typography>
      );

    const iUtils = { ...utils, chairs: pChairs };
    const i = new Internal(
      getInternal(iUtils, workSessionID, projectpieceID),
      iUtils,
      selection
    );

    const ret = [];

    i.positions.forEach((p) => {
      ret.push(
        <Position
          internalPosition={p}
          disableLookup
          reassign
          internal={internal}
          utils={utils}
        />
      );
    });

    return ret;
  };

  return (
    <DialogClose
      onClose={onClose}
      open={open}
      PaperComponent={Box}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      // disablePortal
      // fullWidth
      maxWidth="lg"
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <MusicianAvatar musician={musician} border={2} />
          <Box>
            <Typography sx={{ color: "white" }} level="body4">
              Assignment for:
            </Typography>
            <Typography sx={{ color: "white" }} level="h5">
              {`${musician?.fullName()}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <DialogContent
        className={className}
        sx={{
          background: "white",
          mt: 2,
          position: "relative",
          borderRadius: "8px",
          overflowY: "auto",
          padding: 0,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: 180,
              flexShrink: 0,
              position: "sticky",
              left: 0,
              zIndex: 999,
              background: "white",
              borderRight: "solid 1px #e0e0e0",

              p: 1,
            }}
          >
            Pieces / Work Sessions
          </Box>
          {utils.workSessions.map((w) => (
            <Box
              key={w.id}
              sx={{
                width: CHAIR_WIDTH + 24,
                flexShrink: 0,
                p: 1,
              }}
            >
              <Typography>{w.title}</Typography>
              <Typography level="body2">{w.formatDateRange()}</Typography>
            </Box>
          ))}
        </Box>
        {utils.projectPieces.map((p) => {
          const piece = utils.piecesMap[p.pieceID];
          return (
            <Box
              key={p.id}
              sx={{ display: "flex", borderTop: "solid 1px #e0e0e0" }}
            >
              <Box
                sx={{
                  width: 180,
                  flexShrink: 0,
                  position: "sticky",
                  left: 0,
                  zIndex: 999,
                  minHeight: 150,
                  background: "white",
                  borderRight: "solid 1px #e0e0e0",
                  p: 1,
                }}
              >
                <Typography>{piece?.name ?? "Seating assignment"}</Typography>
                <Typography level="body2">{piece?.composer}</Typography>
              </Box>
              {utils.workSessions.map((w) => (
                <Box
                  key={w.id}
                  sx={{
                    background: alpha(missionControlModeTint, 0.05),
                    width: CHAIR_WIDTH + 24,
                    flexShrink: 0,
                    p: 1,
                    minHeight: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {getPosition(w.id, p.id)}
                </Box>
              ))}
            </Box>
          );
        })}
      </DialogContent>
    </DialogClose>
  );
}
