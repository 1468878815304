import {
  Badge,
  Button,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuItem,
  Sheet,
  Typography,
} from "@mui/joy";
import {
  Box,
  Dialog,
  DialogActions,
  Grow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Filters } from "@nerdjs/nerd-core";
import { useEffect, useRef, useState, ReactElement } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSearchParams } from "react-router-dom";
import { Searchable } from "./searchable";
import { AdvancedSearchDefinition, AdvancedSearchProps } from "./types";
import { genericIcons } from "./utils";

/**
 *
 * @param {AdvancedSearchProps} props component props
 * @returns {ReactElement} NerdAdvancedSearch
 */
export function NerdAdvancedSearch({
  definitions,
  open = false,
  setOpen,
  variant = "toolbar",
  buttonFaIcon = "fa-solid fa-magnifying-glass",
  buttonLabel,
  onSearch,
  onNewSearchPreset,
  searchMetadata,
}: AdvancedSearchProps): ReactElement {
  const [_open, _setOpen] = useState(open);
  const [_variant, _setVariant] = useState(
    variant ?? localStorage.getItem("searchVariant")
  );
  const [_advancedMode, _setAdvancedMode] = useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [addFieldAnchorEl, setAddFieldAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const _definitions =
    _advancedMode && searchMetadata ? searchMetadata : definitions;

  const [searchParams, setSearchParams] = useSearchParams();
  const filtersString = searchParams.get("filters");
  const filters: Filters = filtersString ? JSON.parse(filtersString) : [];
  const ref = useRef<HTMLElement | null>();

  useEffect(() => {
    if (sm) {
      _setVariant("dialog");
    } else {
      _setVariant("toolbar");
    }
  }, [sm]);

  useEffect(() => {
    localStorage.setItem("searchVariant", _variant);
  }, [_variant]);

  useEffect(() => {
    if (setOpen) setOpen(_open);
    if (
      _open &&
      _variant === "toolbar" &&
      filters.length === 0 &&
      ref.current
    ) {
      setAddFieldAnchorEl(ref?.current);
    }
  }, [_open, ref]);

  useEffect(() => {
    if (open != undefined) _setOpen(open);
  }, [open]);

  const go = () => {
    if (_variant === "dialog") _setOpen(false);
    setSearchParams((prevState) => {
      prevState.set("offset", "0");
      return prevState;
    });
    if (onSearch)
      onSearch({
        filters: searchParams.get("filters"),
        offset: searchParams.get("offset") ?? 0,
      });
  };

  useHotkeys(`enter`, go, { enableOnTags: ["INPUT", "TEXTAREA"] });

  const clearAll = () => {
    setSearchParams((prevState) => {
      prevState.set("offset", "0");
      prevState.delete("filters");
      return prevState;
    });

    if (onSearch) onSearch(undefined);
  };

  const handleAddField = (s: AdvancedSearchDefinition) => () => {
    setAddFieldAnchorEl(null);
    filters.push({
      name: s.name,
      comparison: "like",
      value: s.type === "bool" ? true : "",
    });
    setSearchParams((prevState) => {
      prevState.set("filters", JSON.stringify(filters));
      prevState.set("offset", "0");
      return prevState;
    });
  };

  const goButton = onSearch ? (
    <Tooltip title={"Enter"}>
      <Button
        endDecorator={<i className="fa-sharp fa-solid fa-arrow-right"></i>}
        variant="soft"
        size="sm"
        sx={{ background: _variant === "toolbar" ? "white" : undefined }}
        onClick={go}
      >
        Go
      </Button>
    </Tooltip>
  ) : (
    []
  );

  const closeButton = (
    <Tooltip title="Close Search">
      <IconButton size="sm" variant="solid" onClick={() => _setOpen(false)}>
        <i className="fa-solid fa-xmark"></i>
      </IconButton>
    </Tooltip>
  );

  const moreOptionsButton = (
    <>
      <IconButton
        size="sm"
        variant="solid"
        onClick={(e) => setMoreAnchorEl(e.currentTarget)}
      >
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </IconButton>
      <Menu
        anchorEl={moreAnchorEl}
        open={Boolean(moreAnchorEl)}
        onClose={() => setMoreAnchorEl(null)}
      >
        {onNewSearchPreset ? (
          <MenuItem
            onClick={() => {
              onNewSearchPreset();
              setMoreAnchorEl(null);
            }}
          >
            <ListItemDecorator sx={{ color: "inherit" }}>
              <i className="fa-solid fa-download"></i>
            </ListItemDecorator>
            Save as preset
          </MenuItem>
        ) : (
          []
        )}
        <MenuItem
          onClick={() => {
            setMoreAnchorEl(null);
            _setVariant((s) => (s === "dialog" ? "toolbar" : "dialog"));
          }}
        >
          <ListItemDecorator sx={{ color: "inherit" }}>
            <i
              className={
                _variant === "toolbar"
                  ? "fa-solid fa-up-right-and-down-left-from-center"
                  : "fa-solid fa-down-left-and-up-right-to-center"
              }
            ></i>
          </ListItemDecorator>
          {_variant === "toolbar" ? "Expand" : "Reduce"}
        </MenuItem>
        {(searchMetadata?.length ?? 0) > 0 ? (
          <Tooltip title={"Uses Search Metadata"}>
            <MenuItem
              onClick={() => {
                setMoreAnchorEl(null);
                _setAdvancedMode((s) => !s);
              }}
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <i className="fa-solid fa-hammer"></i>
              </ListItemDecorator>
              {_advancedMode ? "Turn Dev Mode off" : "Dev Mode"}
            </MenuItem>
          </Tooltip>
        ) : (
          []
        )}
        <MenuItem
          onClick={() => {
            setMoreAnchorEl(null);
            clearAll();
          }}
        >
          <ListItemDecorator sx={{ color: "inherit" }}>
            <i className="fa-solid fa-trash"></i>
          </ListItemDecorator>
          Clear all"
        </MenuItem>
      </Menu>
    </>
  );

  const addFieldList = (
    <Menu
      placement="bottom-start"
      anchorEl={addFieldAnchorEl}
      open={Boolean(addFieldAnchorEl)}
      onClose={() => setAddFieldAnchorEl(null)}
      sx={{
        maxHeight: 400,
        overflow: "auto",
        width: sm ? "calc(100% - 32px)" : undefined,
      }}
    >
      {_definitions?.map((s) => (
        <MenuItem key={s.id} onClick={handleAddField(s)}>
          <ListItemDecorator sx={{ color: "inherit" }}>
            <i className={s.faIcon ?? genericIcons[s.type]} />
          </ListItemDecorator>
          {s.label}
        </MenuItem>
      ))}
    </Menu>
  );

  const addFieldIconButton = (
    <>
      <Tooltip title={"Add a new field"}>
        <IconButton
          size="sm"
          onClick={(e) => setAddFieldAnchorEl(e.currentTarget)}
        >
          <i className="fa-sharp fa-solid fa-plus"></i>
        </IconButton>
      </Tooltip>
      {addFieldList}
    </>
  );

  const toolbarVariant = (
    <Grow in>
      <Sheet
        sx={{
          p: 1,
          borderRadius: 1,
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          zIndex: 99,
        }}
        color="primary"
        variant="solid"
      >
        <Box
          className="noScrollBar"
          sx={{
            display: "flex",
            gap: 1,
            flexGrow: 1,
            overflowY: "hidden",
            overflowX: "auto",
            scrollbarWidth: "none",
            width: 0,
          }}
        >
          {closeButton}
          {filters.map((f, i) => (
            <Box key={f.name} sx={{ flexShrink: 0, display: "flex" }}>
              <Searchable
                index={i}
                definition={[..._definitions, ...(searchMetadata ?? [])]?.find(
                  (m) => m.name === f.name
                )}
                onDelete={(i) => console.log(i)}
              />
            </Box>
          ))}
          <Sheet
            variant="solid"
            color="primary"
            sx={{
              position: "sticky",
              right: 0,
              zIndex: 99,
              gap: 1,
              display: "flex",
            }}
          >
            {addFieldIconButton}
          </Sheet>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          {goButton}
          {moreOptionsButton}
        </Box>
      </Sheet>
    </Grow>
  );

  const dialogVariant = (
    <Dialog
      open
      onClose={() => _setOpen(false)}
      maxWidth={!sm ? "sm" : undefined}
      fullWidth
      fullScreen={sm}
    >
      <Sheet
        color="primary"
        variant="solid"
        sx={{
          p: 2,
          color: "white",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          flex: sm ? 1 : undefined,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            level="h6"
            sx={{ color: "inherit" }}
            startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
          >
            Search
          </Typography>
          {moreOptionsButton}
        </Box>
        <Typography level="body2" sx={{ color: "inherit" }}>
          Description
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "stretch",
          }}
        >
          {filters.map((f, i) => (
            <Searchable
              key={f.name}
              index={i}
              definition={_definitions?.find((m) => m.name === f.name)}
              onDelete={(i) => console.log(i)}
            />
          ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {addFieldIconButton}
        </Box>
      </Sheet>
      <DialogActions>
        <Button
          size="sm"
          onClick={() => _setOpen(false)}
          variant="soft"
          color="neutral"
        >
          Close
        </Button>
        {goButton}
      </DialogActions>
    </Dialog>
  );

  return (
    <Box ref={ref}>
      <Badge
        badgeContent={filters.length}
        size="sm"
        color="danger"
        badgeInset="8%"
      >
        {sm ? (
          <IconButton
            variant="solid"
            size="sm"
            onClick={() => _setOpen((o) => !o)}
          >
            <i style={{ fontSize: 14 }} className={buttonFaIcon}></i>
          </IconButton>
        ) : (
          <Button
            variant="solid"
            size="sm"
            onClick={() => _setOpen((o) => !o)}
            endDecorator={
              !sm && filters.length ? (
                <Tooltip title="Clear Preset">
                  <Box
                    sx={{ p: 0.5 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      clearAll();
                    }}
                  >
                    <i
                      style={{ fontSize: 14, color: "white" }}
                      className="fa-solid fa-xmark"
                    ></i>
                  </Box>
                </Tooltip>
              ) : undefined
            }
            startDecorator={
              <i style={{ fontSize: 14 }} className={buttonFaIcon}></i>
            }
          >
            {buttonLabel || "Search"}
          </Button>
        )}
      </Badge>
      {_open ? (
        <>
          {_variant === "toolbar" ? toolbarVariant : []}
          {_variant === "dialog" ? dialogVariant : []}
        </>
      ) : (
        []
      )}
    </Box>
  );
}
