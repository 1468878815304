import { Piece } from "entities/piece";
import { useMemo } from "react";
import { useGetPieceQuery, useGetPiecesQuery } from "./pieceEndpoints";

export function usePiece(pieceID: number | undefined) {
  const pieceQuery = useGetPieceQuery(pieceID ?? 0, {
    skip: (pieceID ?? 0) <= 0,
  });
  const pieceEntity = pieceQuery.data;

  return useMemo(() => {
    const ret: typeof pieceQuery & { piece?: Piece | undefined } = {
      ...pieceQuery,
    };
    if (pieceEntity) ret.piece = new Piece(pieceEntity);
    return ret;
  }, [pieceEntity]);
}

export function usePieces(params?: any, config?: any) {
  const pieceQuery = useGetPiecesQuery(params, config);
  const pieceEntitiesMap = pieceQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof pieceQuery & {
      pieces?: Piece[];
      piecesMap?: { [id: number]: Piece };
    } = {
      ...pieceQuery,
      pieces: [],
      piecesMap: {},
    };
    if (pieceEntitiesMap) {
      const pieces = [];
      const piecesMap = {};

      for (const key in pieceEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(pieceEntitiesMap, key)) {
          const c = pieceEntitiesMap[key];
          const piece = new Piece(c);
          pieces.push(piece);
          piecesMap[piece.id] = piece;
        }
      }
      ret.pieces = pieces;
      ret.piecesMap = piecesMap;
    }

    return ret;
  }, [pieceEntitiesMap]);
}
