import {
  EntityState,
  configureStore,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { Group_Entity } from "entities/group";
import { Job_Entity } from "entities/job";
import { Musician_Entity } from "entities/musician";
import { ProjectHiring_Entity } from "entities/projectHiring";
import { rhapsodyApi } from "../api/rhapsodyApi";

const musiciansAdapter = createEntityAdapter<Musician_Entity>();
const initialState = musiciansAdapter.getInitialState();

export const musicianEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getMusiciansSuggestions: build.query<EntityState<Musician_Entity>, number>({
      query: (chairID) => `chairs/${chairID}/suggestions`,
      transformResponse: (responseData: Musician_Entity[]) => {
        return musiciansAdapter.setAll(initialState, responseData);
      },
      providesTags: ["musicians"],
    }),
    getMusiciansForSection: build.query<
      EntityState<Musician_Entity>,
      { sectionID: number; workSessionID: number; sectionRoleID?: number }
    >({
      query: ({ sectionID, workSessionID, sectionRoleID }) =>
        `instrumentSections/${sectionID}/musicians?sessionID=${workSessionID}${
          sectionRoleID ? `&sectionRoleID=${sectionRoleID}` : ""
        }`,
      transformResponse: (responseData: Musician_Entity[]) => {
        return musiciansAdapter.setAll(initialState, responseData);
      },
      providesTags: (a, r, { sectionID, workSessionID, sectionRoleID }) => [
        {
          type: "musicians",
          id: `section_${sectionID}_${workSessionID}_${sectionRoleID}`,
        },
        "musicianSuggestions",
      ],
    }),
    getMusiciansForProjectSection: build.query<
      EntityState<Musician_Entity>,
      { sectionID: number; projectID: number; sectionRoleID?: number }
    >({
      query: ({ sectionID, projectID, sectionRoleID }) =>
        `instrumentSections/${sectionID}/alternates?projectID=${projectID}${
          sectionRoleID ? `&sectionRoleID=${sectionRoleID}` : ""
        }`,
      transformResponse: (responseData: Musician_Entity[]) => {
        return musiciansAdapter.setAll(initialState, responseData);
      },
      providesTags: (a, r, { sectionID, projectID, sectionRoleID }) => [
        {
          type: "musicians",
          id: `section_${sectionID}_${projectID}_${sectionRoleID}`,
        },
      ],
    }),
    getMusiciansForWorkSession: build.query<
      EntityState<Musician_Entity>,
      number
    >({
      query: (workSessionID) => `workSessions/${workSessionID}/musicians`,
      transformResponse: (responseData: Job_Entity[]) => {
        const resp = responseData.reduce((a, i) => {
          a.push(i.musician);
          return a;
        }, []);
        return musiciansAdapter.setAll(initialState, resp);
      },
      providesTags: (a, r, workSessionID) => [
        {
          type: "musicians",
          id: `workSession_${workSessionID}`,
        },
      ],
    }),
    getMusiciansForGroup: build.query<EntityState<Musician_Entity>, number>({
      query: (groupID) => `groups/${groupID}`,
      transformResponse: (responseData: Group_Entity) => {
        return musiciansAdapter.setAll(
          initialState,
          responseData.musicians ?? []
        );
      },
      providesTags: (a, r, groupID) => [
        "groups",
        {
          type: "musicians",
          id: `group_${groupID}`,
        },
      ],
    }),
    getMusicians: build.query<
      EntityState<Musician_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `musicians`,
          params: args,
        };
      },
      transformResponse: (responseData: Musician_Entity[]) => {
        return musiciansAdapter.setAll(initialState, responseData);
      },
      providesTags: ["musicians"],
    }),
    getSeasonMusicians: build.query<EntityState<Musician_Entity>, number>({
      query: (seasonID) => {
        return {
          url: `tags/${seasonID}/musicians`,
        };
      },
      transformResponse: (responseData: Musician_Entity[]) => {
        return musiciansAdapter.setAll(initialState, responseData);
      },
      providesTags: ["musicians", "seasonMusicians"],
    }),
    getProjectHiringMusicians: build.query<
      EntityState<Musician_Entity>,
      number
    >({
      query: (projectID) => `projects/${projectID}/hiring2`,
      transformResponse: (responseData: ProjectHiring_Entity) => {
        const musiciansFromProjectAlternates = responseData.musicians.reduce(
          (a, m) => {
            if (m.musician?.id) a[m.musician?.id] = m.musician;
            return a;
          },
          {}
        );
        const musiciansFromJobs = responseData.jobs.reduce((a, j) => {
          if (j.musicianID) a[j.musicianID] = j.musician;
          return a;
        }, {});

        const merged = {
          ...musiciansFromProjectAlternates,
          ...musiciansFromJobs,
        };

        const musicians = [];
        for (const key in merged) {
          if (Object.prototype.hasOwnProperty.call(merged, key)) {
            const musician = merged[key];
            if (musician?.id) musicians.push(musician);
          }
        }

        return musiciansAdapter.setAll(initialState, musicians);
      },
      providesTags: (result, error, projectID) => [
        { type: "musicians", id: `hiring_${projectID}` },
      ],
    }),
    getMusician: build.query<Musician_Entity, number>({
      query: (id) => `musicians/${id}`,
      providesTags: (result, error, id) => [{ type: "musicians", id }],
    }),
    createMusician: build.mutation<Musician_Entity, Partial<Musician_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `musicians`,
      }),
      invalidatesTags: ["musicians"],
    }),
    createMusician2: build.mutation<Musician_Entity, Partial<Musician_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `musicians`,
      }),
      invalidatesTags: [],
    }),
    updateMusicianInstruments: build.mutation<
      void,
      { id: number; body: { instrumentID: number } }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `musicians/${args.id}/instruments`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "musicians", id },
        "musicians",
      ],
    }),
    updateMusicianInstruments2: build.mutation<
      void,
      { id: number; body: { instrumentID: number } }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `musicians/${args.id}/instruments`,
      }),
    }),
    updateMusician: build.mutation<
      void,
      { id: number; body: Partial<Musician_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `musicians/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "musicians", id },
        "musicians",
      ],
    }),
    updateMusician2: build.mutation<
      void,
      { id: number; body: Partial<Musician_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `musicians/${args.id}`,
      }),
      invalidatesTags: [],
    }),
    deleteMusician: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `musicians/${id}`,
      }),
      invalidatesTags: ["musicians"],
    }),
    deleteMusicianInstruments: build.mutation<
      void,
      { id: number; body: { instrumentID: number } }
    >({
      query: (args) => ({
        method: "DELETE",
        body: args.body,
        url: `musicians/${args.id}/instruments`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "musicians", id },
        "musicians",
      ],
    }),
    deleteMusicianInstruments2: build.mutation<
      void,
      { id: number; body: { instrumentID: number } }
    >({
      query: (args) => ({
        method: "DELETE",
        body: args.body,
        url: `musicians/${args.id}/instruments`,
      }),
      invalidatesTags: [],
    }),
  }),
});

export const {
  useLazyGetMusiciansSuggestionsQuery,
  useGetMusiciansSuggestionsQuery,
  useGetMusicianQuery,
  useGetSeasonMusiciansQuery,
  useCreateMusician2Mutation,
  useGetMusiciansForWorkSessionQuery,
  useGetMusiciansForGroupQuery,
  useGetMusiciansQuery,
  useLazyGetMusiciansQuery,
  useGetMusiciansForProjectSectionQuery,
  useGetMusiciansForSectionQuery,
  useGetProjectHiringMusiciansQuery,
  useCreateMusicianMutation,
  useDeleteMusicianMutation,
  useUpdateMusicianMutation,
  useUpdateMusician2Mutation,
  useUpdateMusicianInstrumentsMutation,
  useDeleteMusicianInstrumentsMutation,
  useDeleteMusicianInstruments2Mutation,
  useUpdateMusicianInstruments2Mutation,
} = musicianEndpoints;

export default musicianEndpoints;

export const selectMusiciansResult =
  musicianEndpoints.endpoints.getMusicians.select();

const selectMusiciansData = createSelector(
  selectMusiciansResult,
  (musiciansResult) => musiciansResult.data
);

const store = configureStore({
  reducer: {
    [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllMusicians, selectById: selectMusicianById } =
  musiciansAdapter.getSelectors<RootState>(
    (state) => selectMusiciansData(state) ?? initialState
  );
