import { PdProject } from "entities/pdProject";
import { useMemo } from "react";
import {
  useGetPdProjectQuery,
  useGetPdProjectsQuery,
} from "./pdProjectEndpoints";

export function usePdProject(pdProjectID: number | undefined) {
  const pdProjectQuery = useGetPdProjectQuery(pdProjectID ?? 0, {
    skip: (pdProjectID ?? 0) <= 0,
  });
  const pdProjectEntity = pdProjectQuery.data;

  return useMemo(() => {
    const ret: typeof pdProjectQuery & { pdProject?: PdProject | undefined } = {
      ...pdProjectQuery,
    };
    if (pdProjectEntity) ret.pdProject = new PdProject(pdProjectEntity);
    return ret;
  }, [pdProjectEntity]);
}

export function usePdProjects(projectID?: number) {
  const pdProjectQuery = useGetPdProjectsQuery(
    {
      filters: JSON.stringify([
        {
          name: "projects.parentID",
          comparison: "eq",
          value: projectID,
        },
      ]),
    },
    { skip: !projectID }
  );
  const pdProjectEntitiesMap = pdProjectQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof pdProjectQuery & {
      pdProjects?: PdProject[];
      pdProjectsMap?: { [id: number]: PdProject };
    } = {
      ...pdProjectQuery,
      pdProjects: [],
      pdProjectsMap: {},
    };
    if (pdProjectEntitiesMap) {
      const pdProjects = [];
      const pdProjectsMap = {};

      for (const key in pdProjectEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(pdProjectEntitiesMap, key)) {
          const c = pdProjectEntitiesMap[key];
          const pdProject = new PdProject(c);
          pdProjects.push(pdProject);
          pdProjectsMap[pdProject.id] = pdProject;
        }
      }
      ret.pdProjects = pdProjects;
      ret.pdProjectsMap = pdProjectsMap;
    }

    return ret;
  }, [pdProjectEntitiesMap]);
}
