import { Button, Divider, Input, Typography } from "@mui/joy";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { NerdPhoneField } from "@nerdjs/nerd-ui";
import { Company_Entity } from "entities/company";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import { useCreateCompanyMutation } from "redux/company/companyEndpoints";
import { useCurrentCompany } from "redux/company/companyHooks";
import { useCurrentUser } from "redux/user/userHook";

/**
 *
 * @returns {ReactElement} Formcompany page
 */
export function Formcompany() {
  const { company } = useCurrentCompany();
  const open = useSelector(formOpenSelector("company"));
  const { me } = useCurrentUser();
  const [_company, setCompany] = useState<Partial<Company_Entity>>();

  const [createcompany] = useCreateCompanyMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (me)
      setCompany({
        ...company,
        name: `${me.firstName} ${me.lastName}`,
        contact: `${me.firstName} ${me.lastName}`,
        email: me.email,
      });
  }, [me]);

  useEffect(() => {
    if (company?.id) onClose();
  }, [company]);

  const onClose = () => {
    if (company.id) dispatch(setFormOpen({ isOpen: false, formID: "company" }));
  };

  const save = () => {
    createcompany({
      ..._company,
      defaultProjectVersion: 2,
      icon: "https://storage.googleapis.com/rhapsody/Avatars/rhapsody.png",
      color: "#0D1D38",
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: "center" }}>
        <Typography level="h6">Finish your setup</Typography>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {_company ? (
          <>
            <Input
              autoFocus
              fullWidth
              endDecorator="Required"
              startDecorator="Name:"
              value={_company.name}
              onChange={(e) =>
                setCompany((v) => ({ ...v, name: e.target.value }))
              }
            />
            <Input
              fullWidth
              startDecorator="Address:"
              value={_company.address}
              onChange={(e) =>
                setCompany((v) => ({ ...v, address: e.target.value }))
              }
            />
            <Input
              fullWidth
              startDecorator="City:"
              value={_company.city}
              onChange={(e) =>
                setCompany((v) => ({ ...v, city: e.target.value }))
              }
            />
            <Input
              fullWidth
              startDecorator="Zipcode:"
              value={_company.zipcode}
              onChange={(e) =>
                setCompany((v) => ({ ...v, zipcode: e.target.value }))
              }
            />
            <Input
              fullWidth
              startDecorator="State:"
              value={_company.state}
              onChange={(e) =>
                setCompany((v) => ({ ...v, state: e.target.value }))
              }
            />
            <Box>
              <Input
                fullWidth
                startDecorator="Contractor Name:"
                value={_company.contact}
                onChange={(e) =>
                  setCompany((v) => ({ ...v, contact: e.target.value }))
                }
              />
              <Typography level="body3">
                This name will appear in your communications and payroll forms.
              </Typography>
            </Box>
            <Box>
              <Input
                fullWidth
                startDecorator="Contractor Email:"
                value={_company.email}
                onChange={(e) =>
                  setCompany((v) => ({ ...v, email: e.target.value }))
                }
              />
              <Typography level="body3">
                This email will be used as the reply-to address.
              </Typography>
            </Box>
            <Box>
              <NerdPhoneField
                value={_company?.phone ?? ""}
                onAccept={(e) => setCompany((v) => ({ ...v, phone: e }))}
                textFieldProps={{
                  fullWidth: true,
                  variant: "outlined",
                  size: "small",
                  label: "Phone",
                }}
              />
              <Typography level="body3">
                This phone will be displayed your communications.
              </Typography>
            </Box>
          </>
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={!_company?.name} fullWidth onClick={save}>
          {"Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
