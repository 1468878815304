import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { Job_Entity } from "entities/job";
import { rhapsodyApi } from "../api/rhapsodyApi";

const jobsAdapter = createEntityAdapter<Job_Entity>();
const initialState = jobsAdapter.getInitialState();

export const jobEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getJobs: build.query<EntityState<Job_Entity>, void>({
      query: () => `jobs`,
      transformResponse: (responseData: Job_Entity[]) => {
        return jobsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["jobs"],
    }),
    getJob: build.query<Job_Entity, number>({
      query: (id) => `jobs/${id}`,
      providesTags: (result, error, id) => [{ type: "jobs", id }],
    }),
    getWorkSessionActiveJobs: build.query<EntityState<Job_Entity>, number>({
      query: (id) => `workSessions/${id}/activeJobs`,
      transformResponse: (responseData: Job_Entity[]) => {
        return jobsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["jobs"],
    }),
    createJob: build.mutation<
      Job_Entity,
      Partial<Job_Entity> | Partial<Job_Entity>[]
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `jobs`,
      }),
      invalidatesTags: () => [
        "projectHirings",
        "workSessionSections",
        "jobs",
        "musicianSuggestions",
      ],
    }),
    updateJob: build.mutation<
      Job_Entity,
      { id: number; body: Partial<Job_Entity> }
    >({
      query: (args) => {
        return {
          method: "PUT",
          body: args.body,
          url: `jobs/${args.id}`,
        };
      },
      invalidatesTags: (result, error, args) => {
        return [
          "jobs",
          "musicianSuggestions",
          "projectHirings",
          args.body.sectionOrder !== undefined ? "workSessionSections" : "dumb",
        ];
      },
    }),
    updateBatchJob: build.mutation<Job_Entity, Partial<Job_Entity>[]>({
      query: (body) => ({
        method: "PUT",
        body: body,
        url: `jobs/batch`,
      }),
      invalidatesTags: () => [
        "projectHirings",
        "workSessionSections",
        "musicianSuggestions",
        "jobs",
      ],
    }),
    deleteJob: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `jobs/${id}`,
      }),
      invalidatesTags: () => [
        "projectHirings",
        "workSessionSections",
        "jobs",
        "musicianSuggestions",
      ],
    }),
  }),
});

export const {
  useGetJobQuery,
  useGetJobsQuery,
  useGetWorkSessionActiveJobsQuery,
  useCreateJobMutation,
  useDeleteJobMutation,
  useUpdateJobMutation,
  useUpdateBatchJobMutation,
} = jobEndpoints;

export default jobEndpoints;

export const selectJobsResult = jobEndpoints.endpoints.getJobs.select();

const selectJobsData = createSelector(
  selectJobsResult,
  (jobsResult) => jobsResult.data
);

const store = configureStore({
  reducer: {
    [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllJobs, selectById: selectJobById } =
  jobsAdapter.getSelectors<RootState>(
    (state) => selectJobsData(state) ?? initialState
  );
