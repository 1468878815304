import {
  Avatar,
  Box,
  ButtonProps,
  Card,
  IconButton,
  Sheet,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
  SxProps,
  alpha,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useTour } from "@reactour/tour";
import { Dictionary } from "@reduxjs/toolkit";
import Id from "atoms/Id/Id";
import { Assignment } from "entities/assignment";
import { Instrument_Entity } from "entities/instrument";
import { Internal } from "entities/internal";
import { InternalPosition } from "entities/internal/InternalPosition";
import { MercuryJob } from "entities/mercuryJob";
import { Musician } from "entities/musician";
import { Piece_Entity } from "entities/piece";
import { readyStage } from "entities/stage/helper";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { InstrumentManager } from "features/musicians/InstrumentsPopover/InstrumentManager";
import MusicianAvatar from "features/musicians/MusicianAvatar/MusicianAvatar";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { FlipCard } from "hooks/FlipCard/FlipCard";
import { SelectoCheckbox2 } from "hooks/Layout/v2";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactVisibilitySensor from "react-visibility-sensor";
import {
  missionControlModeSelector,
  missionControlViewModeSelector,
  setFormOpen,
  setMercuryJobIDForHistory,
  setSelectedMusicianID,
  viewChairMemosSelector,
} from "reducers/rhapsody";
import {
  assignmentIDForLookupSelector,
  chairIDsHoveredSelector,
  layoutDebugSelector,
  selectionSelector,
  setAssignmentIDForRelease,
  setChairIDsHovered,
  setInternalPositionForLookup,
} from "reducers/v2/missionControl";
import { ChairSectionRole } from "../chairSectionRole/v2";
import { actionLabel } from "../useChairActions";
import { ChairAssignMusician } from "./chairAssignMusician";
import { useUpdater } from "./useUpdater";

export const CHAIR_WIDTH = 250;
export const CHAIR_HEIGHT = 100;
export const DEBUG_CHAIR_HEIGHT = 180;

export function Position(props: ChairGroupProps) {
  const missionControlViewMode = useSelector(missionControlViewModeSelector);
  const debug = useSelector(layoutDebugSelector);
  const { internalPosition, utils, internal } = props;
  const className = internalPosition.visibleChairIDs.length ? ["selecto"] : [];
  const emptyChairs = internalPosition.visibleChairIDs.filter((c) => {
    const _chair = utils.chairsMap[c];
    return !_chair?.assignmentID;
  });
  if (emptyChairs.length)
    className.push(`tour-empty-${internalPosition.id?.replace(/\|/g, "")}`);
  internalPosition.visibleMusicianIDs.forEach((mID) =>
    className.push(`musician-${mID}`)
  );

  if (
    internal.duplicateMusicianIDs.filter((value) =>
      internalPosition.visibleMusicianIDs.includes(value)
    ).length > 0
  ) {
    className.push("tour-duplicate");
  }

  if (
    internal.misplacedMusicianIDs.filter((value) =>
      internalPosition.visibleMusicianIDs.includes(value)
    ).length > 0
  ) {
    className.push("tour-misplaced");
  }

  if (
    missionControlViewMode === "pieces" ||
    missionControlViewMode === "workSessions" ||
    internal.positionIDs.length > 50
  )
    return (
      <ReactVisibilitySensor partialVisibility>
        {({ isVisible }) => {
          if (isVisible) {
            return (
              <Box className={className.join(" ")} id={internalPosition.id}>
                <PositionComp
                  {...props}
                  height={debug ? DEBUG_CHAIR_HEIGHT : CHAIR_HEIGHT}
                />
              </Box>
            );
          }
          return (
            <Box
              className={className.join(" ")}
              id={internalPosition.id}
              sx={{
                width: CHAIR_WIDTH,
                height: debug ? DEBUG_CHAIR_HEIGHT : CHAIR_HEIGHT,
              }}
            />
          );
        }}
      </ReactVisibilitySensor>
    );

  return (
    <Box className={className.join(" ")} id={internalPosition.id}>
      <PositionComp
        {...props}
        height={debug ? DEBUG_CHAIR_HEIGHT : CHAIR_HEIGHT}
      />
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} RhapsodyChair page
 */
export function PositionComp(props: ChairGroupProps) {
  const internal = props.internal;
  const layoutUtils = props.utils;
  const viewChairMemos = useSelector(viewChairMemosSelector);
  const [flipped, setFlipped] = useState(viewChairMemos);
  const {
    sectionRoles,
    sectionRolesMap,
    instrumentsMap,
    project,
    hProject,
    musiciansMap,
    assignments,
    chairsMap,
  } = layoutUtils;
  const [hover, setHover] = useState<number | null>();
  const { internalPosition, disableLookup = false } = props;
  const {
    visibleChairIDs,
    visibleAssignmentIDs,
    firstChairID,
    visibleSectionRoleIDs,
    visibleMusicianIDs,
  } = internalPosition;
  const missionControlMode = useSelector(missionControlModeSelector);
  const debug = useSelector(layoutDebugSelector);
  const updater = useUpdater(
    [internalPosition.id],
    visibleAssignmentIDs,
    internal,
    layoutUtils,
    true
  );
  const emptyChairs = internalPosition.visibleChairIDs.filter((c) => {
    const _chair = chairsMap[c];
    return !_chair?.musicianID;
  });
  const emptyChairIDs = emptyChairs.reduce((a, v) => {
    a.push(v);
    return a;
  }, []);
  const dispatch = useDispatch();
  const [lookupOpen, setLookupOpen] = useState(false);

  useEffect(() => {
    setFlipped(viewChairMemos);
  }, [viewChairMemos]);

  useEffect(() => {
    if (lookupOpen) {
      dispatch(setInternalPositionForLookup(internalPosition));
    }
  }, [lookupOpen, internalPosition]);

  useEffect(() => {
    setHover(null);
  }, [internal]);

  const openLookup = () => {
    setLookupOpen(true);
    dispatch(setInternalPositionForLookup(internalPosition));
    dispatch(setFormOpen({ formID: "positionLookup", isOpen: true }));
  };

  if (!firstChairID) return <Box />;

  const musiciansStack = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "calc(100% + 4px)",
      }}
    >
      {visibleMusicianIDs.map((musicianID, i) => {
        const m = musiciansMap[musicianID];
        if (!m) return;
        const assignment = assignments.find((a) => a.musicianID === musicianID);
        const job = hProject.jobs.find(
          (j) => j.uuid === assignment?.mercuryJobID
        );
        return (
          <MItem
            key={m.id}
            utils={layoutUtils}
            onHover={(h) => {
              setHover(h);
              dispatch(
                setChairIDsHovered(
                  internalPosition.visibleChairIDs.reduce<number[]>((a, v) => {
                    const _chair = layoutUtils.chairsMap[v];
                    if (_chair?.musicianID === h) a.push(v);
                    return a;
                  }, [])
                )
              );
            }}
            hover={hover}
            musician={m}
            forWorkSessionID={internalPosition.forWorkSessionID}
            assignment={assignment}
            job={job}
            template={project?.template}
            count={
              emptyChairs.length
                ? visibleMusicianIDs.length + 1
                : visibleMusicianIDs.length
            }
            misplaced={internal.misplacedMusicianIDs.includes(m.id)}
            duplicated={internal.duplicateMusicianIDs.includes(m.id)}
            visibleChairIDs={visibleChairIDs}
            onRemoveMusician={(musicianID) =>
              updater.handleRemoveMusican(musicianID)
            }
            onReassignMusician={(oldMusicianID, newMusicianID) => {
              let workSessionIDs: number[];
              let projectPieceIDs: number[];
              if (internalPosition.forWorkSessionID)
                workSessionIDs = [internalPosition.forWorkSessionID];
              if (internalPosition.forProjectPieceID)
                projectPieceIDs = [internalPosition.forProjectPieceID];
              updater.handleReassignMusician(
                oldMusicianID,
                newMusicianID,
                workSessionIDs,
                projectPieceIDs
              );
            }}
          ></MItem>
        );
      })}
      {emptyChairs.length ? (
        <Box
          sx={{
            flexGrow: hover === -1 ? 1 : 0,
            transition: "flex .3s, width .3s, flexGrow .3s",
            width: hover && hover !== -1 ? "20px" : "100%",
            display: "flex",
            cursor: "pointer",
            position: "relative",
          }}
          onMouseEnter={() => setHover(-1)}
          onMouseLeave={() => setHover(null)}
        >
          <ChairAssignMusician
            chairID={
              props.reassign
                ? internalPosition.firstChairID
                : internalPosition.firstEmptyChairID
            }
            onSelect={updater.handleAssign}
          >
            <EmptyChair
              shrink={hover && hover !== -1}
              chairIDs={emptyChairIDs}
              count={
                emptyChairs.length
                  ? visibleMusicianIDs.length + 1
                  : visibleMusicianIDs.length
              }
            />
          </ChairAssignMusician>
          <Box sx={{ width: 4 }} />
        </Box>
      ) : (
        []
      )}
    </Box>
  );

  const chairIDsForHover = internalPosition.chairIDs.filter((c) => {
    const _chair = layoutUtils.chairsMap[c];
    if (hover === -1) {
      return !_chair?.musicianID;
    }
    return _chair?.musicianID === hover;
  });

  const sectionRoleIDForHover = chairIDsForHover.reduce((a, v) => {
    const _chair = layoutUtils.chairsMap[v];
    if (!a.includes(_chair?.sectionRoleID)) a.push(_chair?.sectionRoleID);
    return a;
  }, []);

  const instrumentNamesForHover = chairIDsForHover.reduce((a, v) => {
    const _chair = layoutUtils.chairsMap[v];
    const ids = JSON.parse(
      _chair?.instrumentIDs ? _chair?.instrumentIDs : "[]"
    );
    ids.forEach((i) => {
      if (!a.includes(instrumentsMap[i].name)) a.push(instrumentsMap[i].name);
    });
    return a;
  }, []);

  const allHidden =
    visibleChairIDs.filter((c) => layoutUtils.chairsMap[c].hidden).length ===
    visibleChairIDs.length;

  const firstChair = layoutUtils.chairsMap[firstChairID];

  return (
    <PositionBase
      height={props.height}
      flipped={flipped}
      setFlipped={setFlipped}
      visibleChairIDs={visibleChairIDs}
      disabled={visibleChairIDs.length === 0 || allHidden}
      debug={
        debug ? (
          <>
            <Typography level="body5">id: {internalPosition.id}</Typography>
            <DebugChairIDs
              utils={layoutUtils}
              internalPosition={internalPosition}
            />
          </>
        ) : undefined
      }
      trailing={
        !project?.template ? (
          <>
            <ChairAssignMusician
              whosnext
              chairID={internalPosition.firstChairID}
            >
              <Tooltip
                enterDelay={1000}
                enterNextDelay={1000}
                variant="outlined"
                arrow
                size="sm"
                title="Who's next?"
              >
                <Typography
                  level="body3"
                  sx={{
                    width: 16,
                    borderRadius: 2,
                    cursor: "pointer",
                    position: "relative",
                    textAlign: "center",
                    "&:hover": {
                      background: alpha("#9e9e9e", 0.1),
                    },
                    "&:active": {
                      background: alpha("#9e9e9e", 0.2),
                    },
                  }}
                >
                  <i className="fa-solid fa-forward-step"></i>
                </Typography>
              </Tooltip>
            </ChairAssignMusician>
            <Tooltip
              enterDelay={1000}
              enterNextDelay={1000}
              variant="outlined"
              arrow
              size="sm"
              title="Piece Memo"
            >
              <Typography
                onClick={() => setFlipped((s) => !s)}
                level="body3"
                sx={{
                  width: 16,
                  borderRadius: 2,
                  cursor: "pointer",
                  position: "relative",
                  textAlign: "center",
                  "&:hover": {
                    background: alpha("#9e9e9e", 0.1),
                  },
                  "&:active": {
                    background: alpha("#9e9e9e", 0.2),
                  },
                }}
              >
                <i className="fa-regular fa-memo"></i>
                {internalPosition.visibleMemos?.length ? (
                  <Box
                    sx={{
                      width: 5,
                      height: 5,
                      borderRadius: 5,
                      background: "#f44336",
                      position: "absolute",
                      top: 1,
                      right: -4,
                    }}
                  ></Box>
                ) : (
                  []
                )}
              </Typography>
            </Tooltip>
            {!disableLookup ? (
              <Tooltip
                enterDelay={1000}
                enterNextDelay={1000}
                variant="outlined"
                arrow
                size="sm"
                title="Position Details"
              >
                <Typography
                  onClick={openLookup}
                  level="body3"
                  sx={{
                    width: 16,
                    borderRadius: 2,
                    cursor: "pointer",
                    position: "relative",
                    textAlign: "center",
                    "&:hover": {
                      background: alpha("#9e9e9e", 0.1),
                    },
                    "&:active": {
                      background: alpha("#9e9e9e", 0.2),
                    },
                  }}
                >
                  <i className="fa-solid fa-chair"></i>
                </Typography>
              </Tooltip>
            ) : undefined}
          </>
        ) : (
          <Box />
        )
      }
      // className={visibleChairs.length ? "selecto" : undefined}
      sx={{ opacity: visibleChairIDs.length === 0 || allHidden ? 0.3 : 1 }}
      checkbox={
        <>
          {missionControlMode !== "view" ? (
            <SelectoCheckbox2
              cssClassHandler
              size="sm"
              positionIDs={
                internalPosition.enabled ? [internalPosition.id] : []
              }
            />
          ) : (
            []
          )}
        </>
      }
      leading={
        <>
          <Typography level="body3" sx={{ fontWeight: 600 }}>
            {firstChair.order()}
          </Typography>
          <ChairSectionRole
            text={
              hover &&
              sectionRoleIDForHover.length === 1 &&
              internalPosition.visibleMusicianIDs.length > 1
                ? sectionRolesMap[sectionRoleIDForHover[0]]?.name
                : undefined
            }
            sectionRoles={sectionRoles}
            sectionRolesMap={sectionRolesMap}
            sectionRoleIDs={visibleSectionRoleIDs}
            onChange={updater.handleSectionRoleUpdate}
          />
        </>
      }
      center={musiciansStack}
      bottom={
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
            // flexDirection: "column",
          }}
        >
          <ChairInstruments
            text={
              hover &&
              internalPosition.visibleMusicianIDs.length > 1 &&
              instrumentNamesForHover.join(", ")
            }
            sectionID={firstChair.sectionID}
            onChange={updater.handleSetInstruments}
            instrumentsMap={instrumentsMap}
            instrumentIDs={internalPosition.visibleInstrumentIDs}
          />
          <ProjectPiecesAtGlance
            hover={hover}
            internalPosition={internalPosition}
            layoutUtils={layoutUtils}
          />
        </Box>
      }
      back={
        flipped ? (
          <ChairMemo
            visibleChairIDs={visibleChairIDs}
            onChange={updater.handleChairMemo}
            utils={layoutUtils}
          />
        ) : (
          <Box />
        )
      }
    />
  );
}

function MItem({
  musician,
  onHover,
  hover,
  job,
  assignment,
  forWorkSessionID,
  count,
  duplicated,
  misplaced,
  visibleChairIDs,
  onReassignMusician,
  onRemoveMusician,
  template,
  utils,
}: {
  musician: Musician;
  onHover: (e: number | null) => void;
  hover: number;
  job: MercuryJob;
  forWorkSessionID?: number;
  assignment?: Assignment;
  count: number;
  misplaced?: boolean;
  duplicated?: boolean;
  visibleChairIDs: number[];
  onReassignMusician?: (oldMusicianID: number, newMusicianID: number) => void;
  onRemoveMusician: (musicianID: number) => void;
  template?: boolean;
  utils: LayoutUtils;
}) {
  const debug = useSelector(layoutDebugSelector);
  const dispatch = useDispatch();
  const { stagesMap } = utils;
  let stage = assignment?.mercuryStageID
    ? stagesMap[assignment?.mercuryStageID]
    : readyStage;

  if (template) stage = undefined;
  const revisionBdage = false;

  const workSessionIDs = !forWorkSessionID
    ? visibleChairIDs.reduce((a, v) => {
        const _chair = utils.chairsMap[v];
        _chair?.workSessionIDs.forEach((w) => {
          if (_chair?.musicianID === musician.id && !a.includes(w)) {
            a.push(w);
          }
        });

        return a;
      }, [])
    : [forWorkSessionID];

  // const assignmentWorkSessionIDs: number[] = assignment?.workSessionIDs
  //   ? JSON.parse(assignment?.workSessionIDs)
  //   : [];

  // workSessionIDs.forEach((w) => {
  //   if (!assignmentWorkSessionIDs.includes(w) && assignment?.workSessionIDs) {
  //     if (stage) revisionBdage = true;
  //     stage = undefined;
  //   }
  // });

  const missionControlMode = useSelector(missionControlModeSelector);
  const assignmentIDforLoopup = useSelector(assignmentIDForLookupSelector);
  let color = stage?.color ?? "#bdbdbd";
  const releaseRequestForWorkSessionID: number[] =
    assignment?.releaseWorkSessionIDs
      ? JSON.parse(assignment?.releaseWorkSessionIDs)
      : undefined;
  const askQuestion = useAskQuestion();
  let releaseRequest = false;
  if (forWorkSessionID) {
    releaseRequest = releaseRequestForWorkSessionID?.includes(forWorkSessionID);
  } else {
    releaseRequest = workSessionIDs.reduce((a, v) => {
      if (
        releaseRequestForWorkSessionID?.includes(v) &&
        assignment?.mercuryStageID !== utils.releaseStage?.id &&
        assignment?.mercuryStageID !== utils?.releasedStage?.id
      )
        a = true;
      return a;
    }, false);
  }

  if (releaseRequest) {
    color = "#f44336";
  }

  const background = alpha(color, 0.2);

  const active = hover === musician.id;

  let name = musician.fullName();
  if (count === 2 && !active) name = musician.condensedName();
  if (count > 2 && !active) name = musician.initials();

  const removable = stagesMap[assignment?.mercuryStageID]?.terminus ?? false;

  const talkback = job?.talkback();

  const expanded = count === 1 || active;

  const styles: Dictionary<SxProps> = {
    root: {
      flexGrow: hover === musician.id ? 1 : 0,
      transition: "flex .3s, width .3s, flexGrow .3s",
      width: hover && hover !== musician.id ? "20px" : "100%",
      display: "flex",
      cursor: !template ? "pointer" : undefined,
      position: "relative",
    },
    badgeTalkback: {
      background: "#f44336",
      color: "white",
      position: "absolute",
      fontSize: 9,
      zIndex: 99,
      borderRadius: 4,
      pl: "2px",
      pr: "2px",
      right: 0,
      top: -4,
      width: 8,
      height: 8,
    },
    badgeWarning: {
      background: "#ffc107",
      color: "white",
      position: "absolute",
      fontSize: 9,
      zIndex: 99,
      borderRadius: 4,
      pl: "2px",
      pr: "2px",
      right: 4,
      top: -4,
      width: 8,
      height: 8,
    },
    musician: {
      minHeight: 40,
      justifyContent: "center",
      p: 0.5,
      pl: count === 5 ? 0.8 : undefined,
      gap: 0.5,
      display: "flex",
      flex: 1,
      borderRadius: 8,
      overflow: "hidden",
      background,
      cursor: !template ? "pointer" : undefined,
      "&:hover": !template
        ? {
            background: alpha(color, 0.3),
          }
        : undefined,
      "&:active": !template
        ? {
            background: alpha(color, 0.4),
          }
        : undefined,
    },
    gap: { width: 4 },
    musicianName: {
      color: "black",
      fontWeight: 500,
      flexShrink: 1,
      lineHeight: 1.3,
      pl: 0.5,
      pr: 0.5,
    },
    inspector: {
      pl: 0.5,
      pr: 0.5,
    },
    stack: {
      display: "flex",
      flex: 1,
      position: "relative",
      overflow: "hidden",
      alignItems: "center",
    },
  };

  return (
    <Box
      onClick={() => {
        if (!template) {
          dispatch(setMercuryJobIDForHistory(assignment?.mercuryJobID));
          dispatch(setFormOpen({ isOpen: true, formID: "mercuryHistory" }));
        }
      }}
      onMouseEnter={() => onHover(musician.id)}
      onMouseLeave={() => onHover(null)}
      sx={styles.root}
    >
      {!expanded ? (
        <MItemBadge
          duplicated={duplicated}
          misplaced={misplaced}
          talkback={talkback !== undefined}
          revision={
            revisionBdage ||
            (assignment?.importantChanges && !stage?.terminus) ||
            (assignment?.criticalChanges && !stage?.terminus)
          }
          color={
            assignment?.criticalChanges && !stage?.terminus
              ? "danger"
              : "warning"
          }
        />
      ) : (
        []
      )}
      <Box sx={styles.musician}>
        <Box sx={{ flex: 1 }}>
          <Box sx={styles.stack}>
            <Box sx={{ width: 30 }}>
              <MusicianAvatar musician={musician} size={30} />
            </Box>
            <Box
              sx={{
                width: count <= 2 || active ? 20 : 0,
                flexGrow: 1,
              }}
            >
              <Typography
                endDecorator={
                  job?.version === 3 ? (
                    <Tooltip
                      arrow
                      size="sm"
                      variant="outlined"
                      title="Season Invite"
                    >
                      <i
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          askQuestion("About Season Invites", ["Got it"], {
                            subtitle: (
                              <Typography>
                                <b>{musician.fullName()}</b> has been contacted
                                for the whole season. This Assignment is
                                controlled by a broader Mission that may contain
                                other Projects. Open the Season | Missions to
                                now more.
                              </Typography>
                            ),
                          });
                        }}
                        style={{ opacity: 0.58 }}
                        className="fa-solid fa-ticket"
                      ></i>
                    </Tooltip>
                  ) : undefined
                }
                level="body3"
                sx={styles.musicianName}
                noWrap
              >
                {name}
              </Typography>
              {active || count === 1 ? (
                <>
                  <Typography noWrap level="body4" sx={styles.inspector}>
                    {releaseRequest ? (
                      <Typography
                        startDecorator={
                          <i className="fa-solid fa-person-from-portal" />
                        }
                        level="body4"
                        noWrap
                        sx={{
                          background: color,
                          borderRadius: 4,
                          color: "white",
                          pl: 0.5,
                          pr: 0.5,
                          mt: 0.5,
                          maxWidth: 150,
                        }}
                      >
                        {assignment?.releaseExplanation}
                      </Typography>
                    ) : (
                      job?.description()
                    )}
                  </Typography>
                </>
              ) : (
                []
              )}
            </Box>
            <Box
              sx={{
                width: count <= 2 || active ? "auto" : 0,
                transition: "width .3s",
                gap: 0.5,
                display: "flex",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <Tooltip
                title={
                  releaseRequest ? "View Release Request" : stage?.wording()
                }
                variant="outlined"
                arrow
                size="sm"
                enterDelay={1000}
                enterNextDelay={1000}
              >
                {releaseRequest ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      dispatch(
                        setFormOpen({ isOpen: true, formID: "releaseRequests" })
                      );
                      dispatch(setAssignmentIDForRelease(assignment?.id));
                    }}
                    color="danger"
                    size="sm"
                  >
                    <i
                      style={{ color }}
                      className="fa-solid fa-person-from-portal"
                    ></i>
                  </IconButton>
                ) : (
                  <img src={stage?.icon} style={{ height: 14 }} />
                )}
              </Tooltip>
              {job?.nudgeCount() ? (
                <Tooltip
                  title={`Nudged ${job?.nudgeCount()} time${
                    job?.nudgeCount() > 1 ? "s" : ""
                  }`}
                  arrow
                  variant="outlined"
                  size="sm"
                >
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: color,
                      borderRadius: 12,
                      color: "white",
                    }}
                  >
                    <Typography level="body4" sx={{ color: "inherit" }}>
                      {job?.nudgeCount()}
                    </Typography>
                  </Box>
                </Tooltip>
              ) : (
                []
              )}
              {(revisionBdage ||
                (assignment?.importantChanges && !stage?.terminus) ||
                (assignment?.criticalChanges && !stage?.terminus)) &&
              expanded ? (
                <ChairTag
                  icon="fa-solid fa-bolt"
                  onClick={() =>
                    dispatch(
                      setFormOpen({ isOpen: true, formID: "revisionCallInfo" })
                    )
                  }
                  tooltip={
                    <Box>
                      <Typography level="body3" style={{ textAlign: "center" }}>
                        Revision Call{" "}
                        {assignment?.criticalChanges ? "needed" : "advised"}
                      </Typography>
                      <Typography level="body4">Click for more info</Typography>
                    </Box>
                  }
                  color={assignment?.criticalChanges ? "danger" : undefined}
                />
              ) : (
                []
              )}
              {duplicated && expanded ? (
                <ChairTag
                  icon="fa-solid fa-user-group"
                  color="danger"
                  tooltip="Duplicate musician playing on multiple chairs."
                />
              ) : (
                []
              )}
              {misplaced && expanded ? (
                <ChairTag
                  icon="fa-solid fa-music-note-slash"
                  tooltip="Misplaced musician who doesn't play the instrument"
                />
              ) : (
                []
              )}
              {removable && missionControlMode !== "view" ? (
                <Tooltip
                  variant="outlined"
                  size="sm"
                  arrow
                  enterDelay={1000}
                  enterNextDelay={1000}
                  title="Replace Musician"
                >
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <ChairAssignMusician
                      chairID={visibleChairIDs[0]}
                      onSelect={(e) =>
                        onReassignMusician(assignment?.musicianID, e)
                      }
                      assignmentID={assignment?.id}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          fontSize: 14,
                          p: 0.5,
                          color: "#6E6E6E",
                        }}
                      >
                        <i className="fa-solid fa-rotate"></i>
                      </Box>
                    </ChairAssignMusician>
                  </Box>
                </Tooltip>
              ) : (
                []
              )}
              {(missionControlMode === "edit" || assignmentIDforLoopup) &&
              (removable || !assignment?.mercuryStageID) ? (
                <Tooltip
                  variant="outlined"
                  size="sm"
                  enterDelay={1000}
                  enterNextDelay={1000}
                  title="Remove Musician"
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      fontSize: 14,
                      p: 0.5,
                      color: "#6E6E6E",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onRemoveMusician(musician.id);
                    }}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Box>
                </Tooltip>
              ) : (
                []
              )}
            </Box>
          </Box>
          {debug && (count === 1 || active) ? (
            <Typography level="body5">
              MusicianID: <Id noStyle>{musician.id}</Id>| AssignmentID:{" "}
              <Id noStyle>{assignment?.id}</Id>
              <br />
              Mrcry: <Id noStyle>{assignment?.mercuryJobID}</Id>
              <br />
              Token: <Id noStyle>{assignment?.publicToken}</Id>
            </Typography>
          ) : (
            []
          )}
        </Box>
      </Box>
      <Box sx={styles.gap} />
    </Box>
  );
}

function MItemBadge({
  talkback,
  misplaced,
  duplicated,
  revision,
  color,
}: {
  talkback?: boolean;
  misplaced?: boolean;
  duplicated?: boolean;
  revision?: boolean;
  color?: "danger" | "warning" | "success" | "info" | "neutral";
}) {
  if (!talkback && !misplaced && !revision && !duplicated) return <Box />;
  return (
    <Box sx={{ position: "absolute", top: -4, right: 2, display: "flex" }}>
      <Sheet
        color={color ?? "warning"}
        variant="solid"
        sx={{
          p: "2px",
          height: 12,
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 7,
          color: color === "danger" ? "white" : "inherit",
          gap: "1px",
        }}
      >
        {talkback ? (
          <i style={{ color: "inherit" }} className="fa-solid fa-comment"></i>
        ) : (
          []
        )}
        {misplaced ? (
          <i
            style={{ color: "inherit" }}
            className="fa-solid fa-music-note-slash"
          ></i>
        ) : (
          []
        )}
        {duplicated ? (
          <i
            style={{ color: "inherit" }}
            className="fa-solid fa-user-group"
          ></i>
        ) : (
          []
        )}
        {revision ? (
          <i style={{ color: "inherit" }} className="fa-solid fa-bolt"></i>
        ) : (
          []
        )}
      </Sheet>
    </Box>
  );
}

type PieceOrder = Piece_Entity & { order: number };
function ProjectPiecesAtGlance({
  internalPosition,
  hover,
  layoutUtils,
}: {
  internalPosition: InternalPosition;
  hover?: number;
  layoutUtils: LayoutUtils;
}) {
  const { projectPieces } = layoutUtils;

  const pieces = projectPieces.reduce<PieceOrder[]>((a, v) => {
    if (
      internalPosition.visibleChairIDs.find((c) => {
        const _chair = layoutUtils.chairsMap[c];
        return _chair?.projectPieceID === v.id;
      })
    ) {
      a.push({ ...layoutUtils.piecesMap[v.pieceID], order: v.position });
    }
    return a;
  }, []);

  if (projectPieces.length <= 1) return <Box />;

  return (
    <Tooltip
      variant="outlined"
      size="sm"
      arrow
      title={
        <Box>
          {pieces.map((p) => (
            <Typography startDecorator={`${p.order}.`} level="body4" key={p.id}>
              {p.name}, {p.composer}
            </Typography>
          ))}
        </Box>
      }
    >
      <Box sx={{ display: "flex", gap: 0.2 }}>
        {projectPieces.map((p) => {
          const chairIDsForProjectPiece =
            internalPosition.visibleChairIDs.filter((c) => {
              const _chair = layoutUtils.chairsMap[c];
              return _chair?.projectPieceID === p.id;
            });
          let hoverProjectPiece =
            chairIDsForProjectPiece.find((c) => {
              const _chair = layoutUtils.chairsMap[c];
              return _chair?.musicianID === hover;
            }) !== undefined;
          let background = chairIDsForProjectPiece?.length
            ? "#424242"
            : "#e0e0e0";

          if (hoverProjectPiece) background = "#00b0ff";

          if (
            hover === -1 &&
            chairIDsForProjectPiece.find((c) => {
              const _chair = layoutUtils.chairsMap[c];
              return !_chair?.assignmentID;
            })
          ) {
            background = "#00b0ff";
            hoverProjectPiece = true;
          }
          return (
            <Box
              key={p.id}
              sx={{
                width: hoverProjectPiece ? 10 : 5,
                transition: "width .3s",
                height: 5,
                background,
                borderRadius: 6,
              }}
            />
          );
        })}
      </Box>
      {/* <Typography level="body4" sx={{ width: 50, textAlign: "right" }}>
        {pieces.length} Piece{pieces.length > 1 ? "s" : ""}
      </Typography> */}
    </Tooltip>
  );
}

function ChairMemo({
  onChange,
  visibleChairIDs,
  utils,
}: {
  onChange: (e: string) => void;
  visibleChairIDs: number[];
  utils: LayoutUtils;
}) {
  const [value, setValue] = useState("");
  const selection = useSelector(selectionSelector);
  const missionControlMode = useSelector(missionControlModeSelector);
  const ref = useRef();

  useEffect(() => {
    let count = 0;
    let _value = "";
    selection.projectPieceIDs.forEach((pp) => {
      const chairIDs = visibleChairIDs.filter((c) => {
        const _chair = utils.chairsMap[c];
        return _chair?.projectPieceID === pp;
      });
      chairIDs.forEach((c) => {
        const _chair = utils.chairsMap[c];
        if (_chair?.memo?.length) {
          if (count === 0) {
            _value = _chair?.memo;
            count++;
          } else if (_value !== _chair?.memo) _value = "Multiple";
        }
      });
    });
    setValue(_value);
  }, [selection]);

  return (
    <Textarea
      ref={ref}
      readOnly={missionControlMode !== "edit"}
      onBlur={() => onChange(value)}
      sx={{
        fontSize: 11,
        p: 0.5,
        flex: 1,
        background: value ? "#2196f322" : "rgba(220,220,220,0.4)",
      }}
      slotProps={{
        textarea: {
          style: {
            cursor: missionControlMode !== "edit" ? "not-allowed" : undefined,
          },
        },
      }}
      variant="outlined"
      maxRows={1}
      endDecorator={
        <Box
          sx={{
            pl: 0.5,
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            level="body4"
            endDecorator={
              <Tooltip
                size="sm"
                title={
                  <Box style={{ textAlign: "center" }}>
                    The memo is attached to the chair playing the piece.
                    <br />
                    Anyone who sits on it will receive it.
                  </Box>
                }
              >
                <i className="fa-sharp fa-regular fa-circle-info"></i>
              </Tooltip>
            }
          >
            {actionLabel["piece-memo"]}
          </Typography>
          {missionControlMode === "edit" ? (
            <i
              onClick={() => {
                onChange("");
                setValue("");
              }}
              style={{ cursor: "pointer" }}
              className="fa-solid fa-trash"
            ></i>
          ) : (
            []
          )}
        </Box>
      }
      placeholder={`Anything to say for this chair?`}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      size="md"
    />
  );
}

function EmptyChair({
  shrink,
  count,
  chairIDs,
}: {
  shrink?: boolean;
  count: number;
  chairIDs: number[];
}) {
  const missionControlMode = useSelector(missionControlModeSelector);
  const tour = useTour();
  const dispatch = useDispatch();

  return (
    <Box
      onMouseEnter={() => {
        dispatch(setChairIDsHovered(chairIDs));
      }}
      onMouseLeave={() => {
        dispatch(setChairIDsHovered([]));
      }}
      onClick={() => tour.setIsOpen(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 1,
        overflow: "hidden",
        background: "rgba(155,155,155,0.06)",
        cursor: missionControlMode === "edit" ? "pointer" : undefined,
        border: "solid .5px rgba(100,100,100,0.1)",
        boxShadow: "inset 2px 2px 2px rgba(100,100,100,0.15)",
        borderRadius: 8,
        height: 38,
        flex: 1,
        "&:hover": {
          background:
            missionControlMode === "edit"
              ? "rgba(155,155,155,0.15)"
              : undefined,
        },
      }}
    >
      <Typography
        startDecorator={<i className="fa-solid fa-user-music"></i>}
        endDecorator={
          missionControlMode === "edit" && !shrink ? (
            <i className="fa-solid fa-caret-down"></i>
          ) : undefined
        }
        level="body4"
      >
        {!shrink && count < 4 && (
          <>{missionControlMode === "edit" ? "Assign" : "Empty Chair"}</>
        )}
      </Typography>
    </Box>
  );
}

export function MusicianStack({
  musicians,
  onRemove,
  noTooltip = false,
  position,
  utils,
}: {
  musicians: Musician[];
  onRemove?: (musicianID: number) => void;
  noTooltip?: boolean;
  position?: InternalPosition;
  utils: LayoutUtils;
}) {
  const [musicianHover, setMusicianHover] = useState<number | null>();
  const { stagesMap, assignments } = utils;
  const dispatch = useDispatch();

  const onMouseEnter = (m: Musician) => {
    setMusicianHover(m.id);
  };

  const onMouseLeave = () => {
    setMusicianHover(null);
  };

  return (
    <>
      <Box key="musicianAvatar">
        <StackedMusicianAvatar
          musicianHover={musicianHover}
          musicians={musicians}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          noTooltip={noTooltip}
        />
      </Box>
      <Box key="musicianName">
        <Grid
          container
          sx={{
            fontWeight: 600,
            color: "black",
            display: "flex",
            fontSize: 12,
          }}
        >
          {musicians.map((m, i) => {
            const hovered = musicianHover && m.id === musicianHover;
            const assignment = assignments.find((a) => a.musicianID === m?.id);
            const stage = stagesMap[assignment?.mercuryStageID] ?? readyStage;

            if (!m) return <Box />;
            return (
              <Grid
                item
                key={m.id}
                onMouseEnter={() => onMouseEnter(m)}
                onMouseLeave={() => onMouseLeave()}
                sx={{
                  cursor: !noTooltip ? "pointer" : undefined,
                  opacity: !musicianHover ? 1 : hovered ? 1 : 0.2,
                  display: "flex",
                  gap: 0.2,
                }}
              >
                <Box
                  sx={{
                    borderRadius: 6,
                    background: hovered
                      ? "rgba(255,255,255,1)"
                      : "rgba(255,255,255,0)",
                    transition: "background .3s, padding-right .3s",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 0.4,
                    paddingRight: hovered ? 0.4 : 0,
                    height: 20,
                    gap: 0.5,
                  }}
                >
                  <Tooltip
                    arrow
                    size="sm"
                    sx={{ textAlign: "center" }}
                    title={
                      !noTooltip ? (
                        <Typography
                          level="body4"
                          sx={{ textAlign: "center", color: "inherit" }}
                        >
                          <b>{m?.fullName()}</b>
                          <br />
                          <Typography
                            level="body5"
                            sx={{ textAlign: "center", color: "inherit" }}
                          >
                            Click to open profile{" "}
                            <i className="fa-solid fa-arrow-up"></i>
                          </Typography>
                        </Typography>
                      ) : undefined
                    }
                  >
                    <Typography
                      sx={{
                        color: stage?.color,
                        fontWeight: "inherit",
                        fontSize: "inherit",
                      }}
                      endDecorator={
                        stage ? (
                          <img src={stage.icon} style={{ height: 10 }} />
                        ) : (
                          []
                        )
                      }
                      onClick={() => dispatch(setSelectedMusicianID(m.id))}
                    >
                      {musicians.length === 1 ? m.fullName() : m.firstName}
                    </Typography>
                  </Tooltip>
                  {hovered && onRemove ? (
                    <Grow in>
                      <Box
                        onClick={() => onRemove(m.id)}
                        sx={{
                          p: 0.4,
                          background: "rgba(155,155,155,0)",
                          transition: "background .3s",
                          borderRadius: 10,
                          width: 18,
                          height: 18,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "&:hover": {
                            background: "rgba(155,155,155,.2)",
                          },
                        }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </Box>
                    </Grow>
                  ) : (
                    []
                  )}
                </Box>
                {i !== musicians.length - 1 ? ", " : ""}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

export function StackedMusicianAvatar({
  musicians,
  onMouseEnter,
  onMouseLeave,
  musicianHover,
  size = 28,
  max = 6,
  opacity,
  musiciansWithPictureFirst = false,
  noTooltip = false,
}: {
  musicians: Musician[];
  onMouseEnter?: (m: Musician) => void;
  onMouseLeave?: (m: Musician) => void;
  musicianHover?: number;
  size?: number;
  max?: number;
  opacity?: boolean;
  musiciansWithPictureFirst?: boolean;
  noTooltip?: boolean;
}) {
  const length = musicians.slice(0, max).length;
  const overflow = musicians.length > max;
  let baseWidth = 28 + musicians.slice(0, max).length * 8;
  if (overflow) baseWidth += size;
  const _musicians = Musician.fromList(JSON.parse(JSON.stringify(musicians)));

  if (musiciansWithPictureFirst)
    _musicians.sort((a, b) => {
      if (a.avatar && b.avatar) return 0;
      if (a.avatar) return -1;
      if (b.avatar) return -1;
    });
  return (
    <Box
      sx={{
        position: "relative",
        width: baseWidth,
        height: 28,
      }}
    >
      {_musicians.slice(0, max).map((m, i) => {
        let op = opacity ? 1 - (1 / length) * i : 1;
        if (musicianHover && m.id === musicianHover) op = 1;
        if (musicianHover && m.id !== musicianHover) op = 0.3;
        return (
          <Box
            onMouseEnter={onMouseEnter ? () => onMouseEnter(m) : undefined}
            onMouseLeave={onMouseLeave ? () => onMouseLeave(m) : undefined}
            key={m.id}
            sx={{
              position: "absolute",
              background: "white",
              borderRadius: size,
              left: i + i * 8,
              zIndex: musicianHover === m.id ? 101 : 100 - i,
            }}
          >
            <Box sx={{ opacity: op }}>
              <Grow>
                <MusicianAvatar border={1} size={size} noBadge musician={m} />
              </Grow>
            </Box>
          </Box>
        );
      })}
      {musicians.length > max && !opacity ? (
        <Avatar
          variant="outlined"
          sx={{
            position: "absolute",
            right: 0,
            height: size,
            width: size,
            fontSize: 12,
            // background: "#BDBDBD",
            // color: "white",
            fontWeight: 600,
          }}
        >
          +{musicians.length - max}
        </Avatar>
      ) : (
        []
      )}
    </Box>
  );
}

export function ChairInstruments({
  text,
  instrumentIDs,
  sectionID,
  onChange,
  instrumentsMap,
  disabled = false,
}: {
  text?: string;
  instrumentIDs: number[];
  sectionID: number;
  instrumentsMap: Dictionary<Instrument_Entity>;
  onChange: (instrumentIDS: number[]) => void;
  disabled?: boolean;
}) {
  const missionControlMode = useSelector(missionControlModeSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>();

  const instrumentNames = instrumentIDs.reduce((a, v) => {
    a.push(instrumentsMap[v]?.name);
    return a;
  }, []);

  if (text) {
    return (
      <Typography
        level="body4"
        sx={{
          color: "#00b0ff",
          fontWeight: 600,
          p: 0.3,
          borderRadius: "4px",
          maxWidth: CHAIR_WIDTH - 68,
          cursor: "pointer",
          "&:hover": { background: "#F2F2F2" },
        }}
      >
        {text}
      </Typography>
    );
  }

  return (
    <>
      <Typography
        onClick={
          missionControlMode === "edit"
            ? (e) => setAnchorEl(e.currentTarget)
            : undefined
        }
        noWrap
        level="body4"
        sx={{
          p: 0.3,
          ml: 0,
          borderRadius: "4px",
          maxWidth: CHAIR_WIDTH - 68,
          cursor: missionControlMode === "edit" ? "pointer" : undefined,
          "&:hover":
            missionControlMode === "edit"
              ? { background: "#F2F2F2" }
              : undefined,
        }}
      >
        {instrumentNames?.length ? instrumentNames.join(", ") : "+ Instrument"}
      </Typography>
      <Grow in>
        <Grid2>
          {anchorEl ? (
            <Popper
              sx={{ zIndex: 9999 }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
            >
              <ClickAwayListener
                onClickAway={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setAnchorEl(null);
                }}
              >
                <Card
                  size="sm"
                  sx={{
                    width: 400,
                    maxHeight: 400,
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InstrumentManager
                    readOnly={missionControlMode !== "edit"}
                    instrumentIDs={instrumentIDs}
                    hideToolbar
                    sectionID={sectionID}
                    onSelect={(e) => {
                      onChange(e);
                      setAnchorEl(null);
                    }}
                  />
                </Card>
              </ClickAwayListener>
            </Popper>
          ) : (
            []
          )}
        </Grid2>
      </Grow>
    </>
  );
}

export function PositionBase({
  id,
  backgroundImage,
  debug,
  disabled,
  className,
  leading,
  trailing,
  center,
  bottom,
  checkbox,
  back,
  setFlipped,
  height,
  onClick,
  flipped = false,
  sx,
}: {
  id?: string;
  backgroundImage?: string;
  setFlipped?: React.Dispatch<React.SetStateAction<boolean>>;
  visibleChairIDs?: number[];
  disabled?: boolean;
  debug?: ReactElement;
  className?: string;
  leading?: ReactElement;
  checkbox?: ReactElement;
  trailing?: ReactElement;
  center?: ReactElement;
  bottom?: ReactElement;
  back?: ReactElement;
  actionButtonProps?: ButtonProps;
  height?: number;
  flipped?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps;
}) {
  // const { setNodeRef, isOver } = useDroppable({
  //   id: JSON.stringify(visibleChairIDs),
  // });

  // https://csshero.org/mesher/

  const paperSx: SxProps = {
    pl: "4px",
    pr: "4px",
    borderStyle: "solid",
    pointerEvents: disabled ? "none" : undefined,
    display: "flex",
    transition: "box-shadow .3s, radial-gradient .3s",
    flexDirection: "column",
    gap: 0.5,
    backgroundImage,
    width: CHAIR_WIDTH,
    height: height ?? CHAIR_HEIGHT,
    overflowY: "auto",
    position: "relative",
    cursor: "default",
    ...sx,
    border: "solid 1px #e0e0e0",
    // background: "#E1EFDE",
  };

  const boxSx: SxProps = {
    pt: "4px",
    display: "flex",
    justifyContent: "space-between",
    gap: 0.5,
    zIndex: 99,
  };

  return (
    <FlipCard
      width={`${CHAIR_WIDTH}px`}
      height={`${height ?? CHAIR_HEIGHT}px`}
      flipped={flipped}
    >
      <FlipCard.Front>
        {!flipped ? (
          <Paper
            // ref={setNodeRef}
            id={id}
            className={className}
            variant="outlined"
            sx={paperSx}
          >
            <Box sx={boxSx}>
              <Box sx={{ display: "flex", gap: 0.5, justifyContent: "start" }}>
                {checkbox}
                {leading}
              </Box>
              <Box sx={{ display: "flex", gap: 0.2, justifyContent: "end" }}>
                {trailing}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                alignItems: "stretch",
                flexDirection: "column",
              }}
            >
              {center}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 0.5,

                zIndex: 99,
                pb: "4px",
              }}
            >
              {bottom}
            </Box>
            {debug}
          </Paper>
        ) : (
          []
        )}
      </FlipCard.Front>
      <FlipCard.Back>
        {flipped ? (
          <Paper
            // ref={setNodeRef}
            id={id}
            className={className}
            variant="outlined"
            sx={{
              ...paperSx,
              // background: "radial-gradient(at center bottom, #757575, #212121);",
            }}
          >
            <Box sx={boxSx}>
              <Box sx={{ display: "flex", gap: 0.5, justifyContent: "start" }}>
                {checkbox}
                {leading}
              </Box>
              <Box sx={{ display: "flex", gap: 1, justifyContent: "end" }}>
                <Typography
                  onClick={() => setFlipped((s) => !s)}
                  level="body4"
                  sx={{ cursor: "pointer", fontWeight: 600 }}
                >
                  DONE
                </Typography>
              </Box>
            </Box>
            <Box sx={{ pb: 0.5, display: "flex", flexGrow: 1 }}>{back}</Box>
          </Paper>
        ) : (
          []
        )}
      </FlipCard.Back>
    </FlipCard>
  );
}

//helpers

export function visibleChairsFirst(selectedProjectPieceIDs) {
  return (a, b) => {
    const y = selectedProjectPieceIDs[a.projectPieceID] ?? false;
    const x = selectedProjectPieceIDs[b.projectPieceID] ?? false;
    return x === y ? 0 : x ? -1 : 1;
  };
}

type ChairGroupProps = {
  internalPosition: InternalPosition;
  projectPieceID?: number;
  workSessionID?: number;
  disableLookup?: boolean;
  reassign?: boolean;
  height?: number;
  internal: Internal;
  utils: LayoutUtils;
};

export function DebugChairIDs({
  internalPosition,
  utils,
}: {
  internalPosition: InternalPosition;
  utils: LayoutUtils;
}) {
  const { chairIDs, workSessionChairIDs, visibleChairIDs } = internalPosition;
  const chairIDsHovered = useSelector(chairIDsHoveredSelector);

  const piecesMap = utils.piecesMap;
  const projectPiecesMap = utils.projectPiecesMap;

  return (
    <Grid container sx={{ display: "flex", gap: 0.5 }}>
      {chairIDs.map((c) => {
        const _chair = utils.chairsMap[c];
        const piece =
          piecesMap[projectPiecesMap[_chair?.projectPieceID]?.pieceID];
        let color = workSessionChairIDs.includes(_chair?.id) ? "red" : "black";

        if (chairIDsHovered?.includes(_chair.id)) color = "#00B0FF";
        return (
          <Grid key={_chair?.id} item>
            <Tooltip
              size="sm"
              title={
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box>{piece?.name}</Box>
                  {_chair?.workSessionID ? (
                    <Box>#{_chair?.workSessionID} ONLY</Box>
                  ) : (
                    []
                  )}
                </Box>
              }
            >
              <Typography
                level="body5"
                sx={{
                  color: color,
                  fontWeight: color === "#00B0FF" ? 600 : undefined,
                  opacity: visibleChairIDs.includes(_chair?.id) ? 1 : 0.3,
                }}
              >
                {_chair?.id}
              </Typography>
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
}

export function ChairTag({
  tooltip,
  icon,
  color,
  onClick,
}: {
  tooltip?: string | ReactElement;
  icon?: string;
  color?: "danger" | "warning" | "success" | "info" | "neutral";
  onClick?: () => void;
}) {
  return (
    <Tooltip size="sm" variant="soft" title={tooltip} arrow>
      <Sheet
        color={color ?? "warning"}
        variant="solid"
        onClick={
          onClick
            ? (e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
              }
            : undefined
        }
        sx={{
          display: "flex",
          gap: 1,
          fontSize: 9,
          borderRadius: 9,
          alignItems: "center",
          justifyContent: "center",
          color: color === "danger" ? "white" : "inherit",
          width: 18,
          height: 18,
        }}
      >
        <i className={icon ?? "fa-solid fa-triangle-exclamation"}></i>
      </Sheet>
    </Tooltip>
  );
}
