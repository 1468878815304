import { Musician_Entity } from "entities/musician";
import fileToArrayBuffer from "file-to-array-buffer";
import { useEffect, useState } from "react";
import { Indexes, createWorkbook, downloadExcel } from "../utils";
import { setFormOpen } from "reducers/rhapsody";
import { useDispatch } from "react-redux";

export function useDownloadMusicianImportTemplate() {
  const [trigger, setTrigger] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (trigger) {
      setTrigger(false);
      download();
    }
  }, [trigger]);

  function download() {
    const workbook = createWorkbook();
    const fileName = `Rhapsody Musicians Import Template`;

    const worksheet = workbook.addWorksheet(`Musicians`);
    const indexes = new Indexes(worksheet);

    indexes.width(40);
    indexes.text("First Name");
    indexes.header();

    indexes.nextColumn();

    indexes.width(40);
    indexes.text("Last Name");
    indexes.header();

    indexes.nextColumn();

    indexes.width(60);
    indexes.text("Email");
    indexes.header();
    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
    downloadExcel(workbook, fileName);
  }

  return () => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setTrigger(true);
  };
}

export const processMusiciansTemplate = async (file) => {
  const workbook = createWorkbook();
  const arrayBuffer = await fileToArrayBuffer(file);
  await workbook.xlsx.load(arrayBuffer);
  const worksheet = workbook.getWorksheet("Musicians");
  const indexes = new Indexes(worksheet);

  let finished = false;
  indexes.nextRow();

  const musicians: Partial<Musician_Entity>[] = [];

  while (!finished) {
    const firstName = (await indexes.get()) as string;
    indexes.nextColumn();
    const lastName = (await indexes.get()) as string;
    indexes.nextColumn();
    const email = (await indexes.get()) as string;
    indexes.previousColumn();
    indexes.previousColumn();
    indexes.nextRow();

    finished = !firstName && !lastName && !email;

    if (!finished) {
      musicians.push({ firstName, lastName, email, id: musicians.length });
    }
  }

  return musicians;
};
