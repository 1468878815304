import { WorkSessionProjectPiece } from "entities/workSessionProjectPiece";
import { useMemo } from "react";
import {
  useGetWorkSessionProjectPieceQuery,
  useGetWorkSessionProjectPiecesQuery,
} from "./workSessionProjectPieceEndpoints";

export function useWorkSessionProjectPiece(
  workSessionProjectPieceID: number | undefined
) {
  const workSessionProjectPieceQuery = useGetWorkSessionProjectPieceQuery(
    workSessionProjectPieceID ?? 0,
    {
      skip: (workSessionProjectPieceID ?? 0) <= 0,
    }
  );
  const workSessionProjectPieceEntity = workSessionProjectPieceQuery.data;

  return useMemo(() => {
    const ret: typeof workSessionProjectPieceQuery & {
      workSessionProjectPiece?: WorkSessionProjectPiece | undefined;
    } = {
      ...workSessionProjectPieceQuery,
    };
    if (workSessionProjectPieceEntity)
      ret.workSessionProjectPiece = new WorkSessionProjectPiece(
        workSessionProjectPieceEntity
      );
    return ret;
  }, [workSessionProjectPieceEntity]);
}

export function useWorkSessionProjectPieces(projectID?: number) {
  const workSessionProjectPieceQuery = useGetWorkSessionProjectPiecesQuery(
    {
      filters: JSON.stringify([
        {
          name: "worksession_project_pieces.projectID",
          comparison: "eq",
          value: projectID,
        },
      ]),
    },
    { skip: !projectID }
  );
  const workSessionProjectPieceEntitiesMap =
    workSessionProjectPieceQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof workSessionProjectPieceQuery & {
      workSessionProjectPieces?: WorkSessionProjectPiece[];
      workSessionProjectPiecesMap?: { [id: number]: WorkSessionProjectPiece };
    } = {
      ...workSessionProjectPieceQuery,
      workSessionProjectPieces: [],
      workSessionProjectPiecesMap: {},
    };
    if (workSessionProjectPieceEntitiesMap) {
      const workSessionProjectPieces = [];
      const workSessionProjectPiecesMap = {};

      for (const key in workSessionProjectPieceEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(
            workSessionProjectPieceEntitiesMap,
            key
          )
        ) {
          const c = workSessionProjectPieceEntitiesMap[key];
          const workSessionProjectPiece = new WorkSessionProjectPiece(c);
          workSessionProjectPieces.push(workSessionProjectPiece);
          workSessionProjectPiecesMap[workSessionProjectPiece.id] =
            workSessionProjectPiece;
        }
      }
      ret.workSessionProjectPieces = workSessionProjectPieces;
      ret.workSessionProjectPiecesMap = workSessionProjectPiecesMap;
    }

    return ret;
  }, [workSessionProjectPieceEntitiesMap]);
}
