import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip as JoyTooltip,
  Typography as JoyTypography,
  Sheet,
  Switch,
} from "@mui/joy";
import {
  Box,
  Divider,
  Grid,
  Grow,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Job, Job_Entity } from "entities/job";
import { WorkSession } from "entities/workSession";
import { ChairAssignMusician } from "features/chair/chairAssignMusician";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { getSectionDragType, move, reorder } from "helpers/rhapsody";
import {
  Instrumentation,
  InstrumentationSelection,
} from "hooks/Instrumentation/v1";
import { HelpCard } from "hooks/helpCard/helpCard";
import moment from "moment";
import React, { useCallback } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  jobToUpdateSelector,
  projectRostersGroupedSelector,
  projectRostersIsHoverSelector,
  projectRostersSelectionSelector,
  projectRosters_Select_WorkSessions,
  projectRosters_Set_Hover,
  projectRosters_UnselectAll_WorkSessions,
  projectRosters_Unselect_WorkSessions,
  setProjectRostersGrouped,
} from "reducers/projectRosters";
import {
  selectedProjectIDSelector,
  selectedWorkSessionIDSelector,
  setFormOpen,
  setSelectedWorkSessionID,
} from "reducers/rhapsody";
import {
  useCreateJobMutation,
  useDeleteJobMutation,
  useUpdateBatchJobMutation,
} from "redux/job/jobEndpoints";
import {
  useMusician,
  useProjectHiringMusicians,
} from "redux/musician/musicianHooks";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { store } from "store";
import { useRosterData } from "./useRosterData";
import { Musician } from "entities/musician";
import { ProjectHiring } from "entities/projectHiring";

const MUSICIAN_CHIP_HEIGHT = 25;

export const removableStages = {
  0: true,
  3: true,
  9: true,
  20: true,
};

const SectionRoleChip = ({
  job,
  projectHiring,
}: {
  job: Job;
  projectHiring: ProjectHiring;
}) => {
  const [value, setValue] = React.useState(job.sectionRoleID || 9);
  const { sectionRoles } = useSectionRoles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { selectedSessionMap } = useSelector(projectRostersSelectionSelector);
  const selected = selectedSessionMap[job.sessionID] != undefined;
  const [updateBatchMercuryJob] = useUpdateBatchJobMutation();

  const changeSectionRole = useCallback(
    (role) => {
      const jobsToUpdate = jobToUpdateSelector(projectHiring)(store.getState());
      const body: Partial<Job_Entity>[] = [];
      jobsToUpdate.forEach((j) => {
        body.push({
          id: j.id,
          sectionRoleID: role.id,
        });
      });
      updateBatchMercuryJob(body);
    },
    [job, projectHiring]
  );

  return (
    <>
      <Box
        onClick={(e) => selected && setAnchorEl(e.currentTarget)}
        sx={{
          background: "rgba(155,155,155,0.1)",
          border: "solid 1px rgba(155,155,155,0.3)",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          padding: "0px 2px",
          cursor: selected ? "pointer" : undefined,
          width: "auto",
          textAlign: "center",
          marginLeft: 4,
        }}
      >
        <Typography noWrap variant="caption" color="textSecondary">
          {sectionRoles.find((s) => s.id === value)?.name}
        </Typography>
      </Box>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          disableEnforceFocus
          disableAutoFocus
          disableRestoreFocus
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {sectionRoles?.map((sr) => (
            <MenuItem
              key={sr.id}
              onClick={() => {
                setAnchorEl(null);
                setValue(sr.id);
                changeSectionRole(sr);
              }}
              value={sr.id}
            >
              {sr.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

const SectionOrderDiff = ({
  job,
  fullName,
}: {
  job: Job;
  fullName: string;
}) => {
  if (!job.sectionOrderDiff) return <div />;
  let color;
  if (job.sectionOrderDiff) color = "#f44336";

  return (
    <Tooltip title={`${fullName} order has changed`}>
      <Box
        sx={{
          flexShrink: 0,
          fontSize: 10,
          background: color,
          color: "white",
          display: "inline-block",
          borderRadius: "8px",
          pl: 0.5,
          mr: 0.5,
          pr: 0.5,
        }}
      >
        <i
          style={{ paddingRight: 2 }}
          className={`fa-solid fa-arrow-${
            job.sectionOrderDiff > 0 ? "up" : "down"
          }`}
        ></i>
        {Math.abs(job.sectionOrderDiff)}
      </Box>
    </Tooltip>
  );
};

const MusicianChip = ({ job, workSession, projectHiring }) => {
  const dispatch = useDispatch();
  const { selectedSessionMap } = useSelector(projectRostersSelectionSelector);
  const selected = selectedSessionMap[job.sessionID] != undefined;
  const projectID = useSelector(selectedProjectIDSelector);
  const [updateBatchMercuryJob] = useUpdateBatchJobMutation();
  const [deleteRhapsodyJob] = useDeleteJobMutation();
  const rosterHoverPayload = {
    musicianID: job.musicianID,
    sectionOrder: job.sectionOrder,
    sectionID: job.sectionID,
  };
  const rosterHover = useSelector(
    projectRostersIsHoverSelector(rosterHoverPayload)
  );
  const { musiciansMap } = useProjectHiringMusicians(projectID);
  const musician = musiciansMap[job?.musicianID];
  const askQuestion = useAskQuestion();

  const removable =
    job.mercuryStageID === 0 ||
    !job.mercuryStageID ||
    removableStages[job.mercuryStageID];
  const emptyChair = !musician;
  let musicianFullName = "Empty Chair";
  if (!emptyChair) musicianFullName = musician.fullName();

  const mouseEnter = useCallback(() => {
    if (selected) dispatch(projectRosters_Set_Hover(rosterHoverPayload));
  }, [selected, rosterHoverPayload]);

  const linkMusician = useCallback(
    (musicianID: number) => {
      const body: Partial<Job_Entity>[] = [];
      const jobsToUpdate = jobToUpdateSelector(projectHiring)(store.getState());
      jobsToUpdate.forEach((j) => {
        body.push({
          id: j.id,
          musicianID: musicianID,
        });
      });
      updateBatchMercuryJob(body);
    },
    [projectHiring]
  );

  const handleRemoveMusician = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      const jobsToUpdate = jobToUpdateSelector(projectHiring)(store.getState());
      askQuestion(`Confirm removing a Musician`, ["Cancel", "Confirm"], {
        subtitle: (
          <Typography>
            You're about to remove <b>{musicianFullName}</b> across{" "}
            <b>
              {jobsToUpdate.length} Work Session
              {jobsToUpdate.length > 1 ? "s" : ""}
            </b>
          </Typography>
        ),
      }).then(async (r) => {
        if (r) {
          const body: Partial<Job_Entity>[] = [];
          jobsToUpdate.forEach((j) => {
            body.push({
              id: j.id,
              musicianID: -1,
            });
          });
          updateBatchMercuryJob(body);
        }
      });
    },
    [projectHiring]
  );

  const handleDeleteChair = useCallback(() => {
    const jobsToUpdate = jobToUpdateSelector(projectHiring)(store.getState());
    askQuestion(`Confirm deleting a Chair`, ["Cancel", "Confirm"], {
      subtitle: (
        <Typography>
          You're about to remove an empty chair across {jobsToUpdate.length}{" "}
          <b>
            Work Session
            {jobsToUpdate.length > 1 ? "s" : ""}
          </b>
        </Typography>
      ),
    }).then(async (r) => {
      if (r) {
        jobsToUpdate.forEach((j) => {
          deleteRhapsodyJob(j.id);
        });
      }
    });
  }, [projectHiring]);

  const isEmpty = !job?.musicianID || job.musicianID === -1;

  return (
    <Box
      onMouseEnter={mouseEnter}
      sx={{
        display: "flex",
        flex: 1,
        gap: 0.5,
        alignItems: "center",
        height: MUSICIAN_CHIP_HEIGHT,
        background:
          selected && rosterHover
            ? "rgba(33,150,243, 0.6)"
            : "rgba(33,150,243, 0)",
        transition: "background .1s",
      }}
    >
      <Typography variant="body2">{`${job.sectionOrder + 1}. `}</Typography>
      <SectionOrderDiff job={job} fullName={musician?.fullName()} />
      {isEmpty ? (
        <ChairAssignMusician
          label="Empty Position"
          disabled={!selected}
          job={job}
          onSelect={linkMusician}
        />
      ) : (
        <Typography
          noWrap
          sx={{
            cursor: selected && emptyChair ? "pointer" : undefined,
            flexGrow: 1,
            fontWeight: removable ? 300 : 500,
            opacity: removable && !emptyChair ? 0.65 : 1,
            fontStyle: removable ? "italic" : undefined,
            border: emptyChair ? "solid 1px rgba(155,155,155,0.3)" : undefined,
            background: emptyChair && "white",
            borderRadius: emptyChair ? 4 : 0,
            textAlign: emptyChair && "center",
          }}
          variant="body2"
        >
          {musicianFullName}
        </Typography>
      )}
      <SectionRoleChip projectHiring={projectHiring} job={job} />
      {selected ? (
        <Box
          onClick={
            removable && (emptyChair ? handleDeleteChair : handleRemoveMusician)
          }
          sx={{
            width: 24,
            height: 24,
            p: 1,
            border: removable && "solid 1px rgba(155,155,155,0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 10,
            fontSize: 11,
            background: removable && "white",
          }}
        >
          {removable ? (
            <Tooltip title={emptyChair ? "Delete Chair" : "Remove Musician"}>
              <Box>
                <i
                  className={
                    emptyChair
                      ? "fa-regular fa-trash"
                      : "fa-regular fa-user-minus"
                  }
                ></i>
              </Box>
            </Tooltip>
          ) : (
            []
          )}
        </Box>
      ) : (
        []
      )}
    </Box>
  );
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

function WorkSessionHeader({ workSessionID }: { workSessionID: number }) {
  const projectID = useSelector(selectedProjectIDSelector);
  const grouped = useSelector(projectRostersGroupedSelector);
  const { projectHiring } = useProjectHiring(projectID);
  const selection = useSelector(projectRostersSelectionSelector);
  const dispatch = useDispatch();
  const workSessions = projectHiring.sessions;
  const selected = selection.selectedSessionMap[workSessionID] !== undefined;
  const workSession = workSessions.find((w) => w.id === workSessionID);
  const target = grouped
    ? workSessions.filter((s) => s.layoutHash === workSession.layoutHash)
    : [workSession];

  const handleCheck = (value: boolean) => {
    if (value) {
      dispatch(projectRosters_Select_WorkSessions(target));
    } else {
      dispatch(projectRosters_Unselect_WorkSessions(target));
    }
  };

  function getWorkSessionHeader(ws: WorkSession) {
    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          width: "100%",
          flex: 1,
        }}
      >
        {target.length === 1 ? (
          <Checkbox
            onChange={(e) => handleCheck(e.target.checked)}
            checked={selected}
            size="sm"
            sx={{ flexShrink: 1 }}
          />
        ) : (
          []
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width:
              target.length > 1 ? "calc(100% - 45px)" : "calc(100% - 65px)",
            overflow: "hidden",
            flexShrink: 0,
            cursor: target.length === 1 ? "pointer" : undefined,
          }}
          onClick={
            target.length === 1 ? () => handleCheck(!selected) : undefined
          }
        >
          <JoyTypography
            noWrap
            sx={{ color: "black", fontWeight: 600 }}
            level="body2"
          >
            {workSession?.title}
          </JoyTypography>
          <JoyTypography noWrap level="body3">
            {workSession.formatDateRange()}
          </JoyTypography>
        </Box>
        <Box sx={{ width: 50, flexShrink: 1 }}>
          <Grow in>
            <JoyTooltip title="Open Work Session" arrow size="sm">
              <IconButton
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={(e) =>
                  dispatch(setSelectedWorkSessionID(workSession?.id))
                }
              >
                <i className="fa-solid fa-arrow-up-from-square"></i>
              </IconButton>
            </JoyTooltip>
          </Grow>
        </Box>
      </Box>
    );
  }

  if (target.length > 1) {
    return (
      <JoyTooltip
        variant="plain"
        size="lg"
        arrow
        title={
          <Box
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {target.map((w) => (
              <>
                {getWorkSessionHeader(w)}
                <Divider />
              </>
            ))}
          </Box>
        }
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Checkbox
            onChange={(e) => handleCheck(e.target.checked)}
            checked={selected}
            size="sm"
          />
          <Avatar
            size="sm"
            color="danger"
            variant="solid"
            sx={{ height: 20, width: 20 }}
          >
            {target.length}
          </Avatar>
          <Box
            sx={{ display: "flex", flexDirection: "column", lineHeight: 0.8 }}
          >
            <JoyTypography
              sx={{ fontWeight: 600, color: "black" }}
              level="body2"
            >
              Work Sesssion{target.length > 1 ? "s" : ""}
            </JoyTypography>
            <JoyTypography level="body3">Grouped Together</JoyTypography>
          </Box>
        </Box>
      </JoyTooltip>
    );
  }
  return getWorkSessionHeader(workSession);
}

function WorkSessionRoster({ workSession }) {
  const [lists, setLists] = React.useState<any>();
  const [k, setK] = React.useState(0);
  const { layout, key } = useRosterData();
  const [updateBatchMercuryJob] = useUpdateBatchJobMutation();
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectHiring } = useProjectHiring(projectID);
  const { selectedSessionMap } = useSelector(projectRostersSelectionSelector);
  const selected = selectedSessionMap[workSession.id] != undefined;

  React.useEffect(() => {
    if (workSession.sections) {
      const _lists = {};
      for (const key in workSession.sections) {
        if (Object.hasOwnProperty.call(workSession.sections, key)) {
          const section = workSession.sections[key];
          _lists[section.id] = section.jobs;
        }
      }
      setLists(_lists);
      setK((s) => s + 1);
    }
  }, [key]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const relativeMove = destination.index - source.index;
    const body: Partial<Job_Entity>[] = [];
    const jobsToUpdate = jobToUpdateSelector(projectHiring)(store.getState());
    jobsToUpdate.forEach((j) => {
      let newOrder = j.sectionOrder + relativeMove;
      if (newOrder < 0) newOrder = 0;
      body.push({
        id: j.id,
        sectionOrder: newOrder,
        sectionID: parseInt(destination.droppableId),
      });
    });
    updateBatchMercuryJob(body);

    if (source.droppableId === destination.droppableId) {
      // move within the same list
      const result = reorder(
        lists[source.droppableId],
        source.index,
        destination.index
      );

      const _lists = { [source.droppableId]: result };

      setLists({ ...lists, ..._lists });
    } else {
      const result = move(
        lists[source.droppableId],
        lists[destination.droppableId],
        source,
        destination
      );
      setLists({
        ...lists,
        ...result,
      });
    }
  };

  if (!lists) return <div />;

  return (
    <Box
      sx={{
        width: 300,
        borderRight: "solid 1px rgba(155,155,155,0.3)",
        background: selected ? "rgba(33,150,243, 0)" : undefined,
      }}
    >
      <Box sx={{ pl: 1, pr: 1, position: "relative", display: "flex" }}>
        <WorkSessionHeader workSessionID={workSession?.id} />
      </Box>
      <div style={{ padding: 8, position: "relative" }}>
        <DragDropContext onDragEnd={onDragEnd} key={k}>
          <div style={{ marginRight: 8 }}>
            {workSession.sections.map((s) => (
              <div key={s.id}>
                <Typography variant="subtitle1">
                  <b>{s.name}</b>
                </Typography>
                <Divider />
                <Box sx={{ position: "relative", pt: 1 }}>
                  <Droppable
                    droppableId={`${s.id}`}
                    type={getSectionDragType(s)}
                  >
                    {(provided) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          style={{
                            paddingBottom:
                              (layout[s.id] - lists[s.id]?.length) *
                              MUSICIAN_CHIP_HEIGHT,
                          }}
                        >
                          {lists[s.id]?.map((item, index) => {
                            if (item)
                              return (
                                <Draggable
                                  key={item.id}
                                  isDragDisabled={!selected}
                                  draggableId={`${item.id}`}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <MusicianChip
                                        job={item}
                                        workSession={workSession}
                                        projectHiring={projectHiring}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </Box>
              </div>
            ))}
          </div>
        </DragDropContext>
      </div>
    </Box>
  );
}

function RostersToolbar() {
  const selectedWorkSessionID = useSelector(selectedWorkSessionIDSelector);
  const { selectedSessions } = useSelector(projectRostersSelectionSelector);
  const nothingSelected = selectedSessions.length === 0;
  const [createRhapsodyJob] = useCreateJobMutation();
  const dispatch = useDispatch();
  const askQuestion = useAskQuestion();
  const grouped = useSelector(projectRostersGroupedSelector);
  const { data } = useRosterData();

  const addChairs = (selection: InstrumentationSelection) => {
    const count = selectedSessions.length;
    askQuestion("Confirming adding new Chairs", ["Cancel", "Confirm"], {
      subtitle: (
        <Typography>
          You're about to add new Chair(s) across {count} Work Session
          {count > 1 ? "s" : ""}
        </Typography>
      ),
    }).then(async (r) => {
      if (r) {
        const body = [];
        for (const key in selectedSessions) {
          if (Object.hasOwnProperty.call(selectedSessions, key)) {
            const workSession = selectedSessions[key];
            for (const sectionID in selection) {
              if (selection.hasOwnProperty(sectionID)) {
                const chairs = selection[sectionID];
                body.push({
                  sessionID: workSession.id,
                  sectionID: parseInt(sectionID),
                  createCount: chairs.createCount,
                });
              }
            }
          }
        }
        createRhapsodyJob(body);
      }
    });
  };

  return (
    <Box sx={{ background: "white", position: "sticky", top: 0, zIndex: 999 }}>
      <Box sx={{ p: 1, display: "flex", gap: 1, background: "white" }}>
        <Button
          startDecorator={<i className="fa-solid fa-plus"></i>}
          endDecorator={<i className="fa-duotone fa-chair"></i>}
          size="sm"
          disabled={nothingSelected}
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "instrumentation" }))
          }
        >
          Add Chairs
        </Button>
        <JoyTypography
          level="body2"
          endDecorator={
            <JoyTooltip
              size="sm"
              arrow
              title="Group the Work Sessions with a similar instrumentation into a single column."
            >
              <i className="fa-sharp fa-solid fa-circle-question"></i>
            </JoyTooltip>
          }
          startDecorator={
            <Switch
              checked={grouped}
              onChange={(e) => {
                dispatch(setProjectRostersGrouped(e.target.checked));
                dispatch(projectRosters_UnselectAll_WorkSessions());
                window.localStorage.setItem(
                  "projectRostersGrouped",
                  e.target.checked ? "true" : "false"
                );
              }}
              size="sm"
              sx={{ ml: 1 }}
            />
          }
        >
          Group
        </JoyTypography>
        {!selectedWorkSessionID ? <Instrumentation onAdd={addChairs} /> : []}
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => dispatch(projectRosters_UnselectAll_WorkSessions())}
          size="sm"
          variant="soft"
          color="neutral"
        >
          Unselect All
        </Button>
        <Button
          onClick={() => dispatch(projectRosters_Select_WorkSessions(data))}
          size="sm"
          variant="soft"
          color="neutral"
        >
          Select All
        </Button>
      </Box>
      <Divider />
    </Box>
  );
}

export default function Rosters() {
  const { data, isLoading } = useRosterData();
  const grouped = useSelector(projectRostersGroupedSelector);
  const dispatch = useDispatch();

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (data.length === 0) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "50vh", textAlign: "center" }}
      >
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            {" "}
            <i style={{ fontSize: 70 }} className="fa-duotone fa-users"></i>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            Rosters
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Easily view the musician roster for all your Work Sessions in one
            place
            <br />
            and make quick adjustments on the go.
          </Typography>
          <br />
          <Button
            startDecorator={<i className="fa-solid fa-plus"></i>}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "workSession" }))
            }
            sx={{ mt: 1 }}
          >
            Add Work Session
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        flex: 1,
        alignItems: "stretch",
        minWidth: "auto",
        width: 0,
      }}
    >
      <HelpCard title="Rosters Quick Edits">
        <Typography>
          In this view, use the checkboxes to select the work sessions you want
          to work with.
          <br />
          Drag and drop musicians to reorder. Edit their roles. View order
          discrepencies. Add new chairs. Remove and add musicians.
        </Typography>
      </HelpCard>
      <Sheet
        variant="outlined"
        sx={{
          flex: 1,
          minHeight: 0,
          overflow: "auto",
        }}
      >
        <RostersToolbar />
        <Box
          sx={{
            padding: 1,
            overflow: "auto",
            position: "relative",
          }}
        >
          <Box sx={{ display: "inline-flex" }}>
            {!isLoading &&
              data
                .reduce((a, i) => {
                  if (!grouped) {
                    a.push(i);
                  } else if (
                    a?.findIndex((e) => e.layoutHash === i.layoutHash) === -1
                  ) {
                    a.push(i);
                  }
                  return a;
                }, [])
                .map((workSession) => (
                  <Box key={workSession.id} sx={{ position: "relative" }}>
                    <WorkSessionRoster workSession={workSession} />
                  </Box>
                ))}
          </Box>
        </Box>
      </Sheet>
    </Box>
  );
}
