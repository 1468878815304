import { useMemo } from "react";
import {
  useGetCurrentUserQuery,
  useGetOrganizationUsersQuery,
  useGetUserQuery,
  useGetUsersQuery,
} from "./userEndpoint";
import { User } from "@nerdjs/account-kit";

export function useCurrentUser() {
  const userQuery = useGetCurrentUserQuery();
  const userEntity = userQuery.data;

  return useMemo(() => {
    const ret: typeof userQuery & { me?: User | undefined } = {
      ...userQuery,
    };
    if (userEntity) ret.me = new User(userEntity);
    return ret;
  }, [userEntity]);
}

export function useUser(userID: number | undefined) {
  const userQuery = useGetUserQuery(userID ?? 0, {
    skip: (userID ?? 0) <= 0,
  });
  const userEntity = userQuery.data;

  return useMemo(() => {
    const ret: typeof userQuery & { user?: User | undefined } = {
      ...userQuery,
    };
    if (userEntity) ret.user = new User(userEntity);
    return ret;
  }, [userEntity]);
}

export function useUsers() {
  const userQuery = useGetUsersQuery();
  const userEntitiesMap = userQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof userQuery & {
      users?: User[];
      usersMap?: { [id: number]: User };
    } = {
      ...userQuery,
      users: [],
      usersMap: {},
    };
    if (userEntitiesMap) {
      const users = [];
      const usersMap = {};

      for (const key in userEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(userEntitiesMap, key)) {
          const c = userEntitiesMap[key];
          const user = new User(c);
          users.push(user);
          usersMap[user.id] = user;
        }
      }
      ret.users = users;
      ret.usersMap = usersMap;
    }

    return ret;
  }, [userEntitiesMap]);
}

export function useOrganizationUsers(organizationID: number | undefined) {
  const sneakyJoes = [70, 71, 144, 14];
  // const sneakyJoes = [];
  const organizationUsersQuery = useGetOrganizationUsersQuery(
    organizationID ?? 0,
    {
      skip: (organizationID ?? 0) <= 0,
    }
  );
  const organizationUsers = organizationUsersQuery.data;

  return useMemo(() => {
    const ret: typeof organizationUsersQuery & {
      users?: User[];
      usersMap?: { [id: number]: User };
    } = {
      ...organizationUsersQuery,
      users: [],
      usersMap: {},
    };
    if (organizationUsers) {
      const users = [];
      const usersMap = {};

      for (const key in organizationUsers) {
        if (Object.prototype.hasOwnProperty.call(organizationUsers, key)) {
          const u = organizationUsers[key];
          if (sneakyJoes.indexOf(u.id) >= 0) continue;

          const user = new User(u);
          users.push(user);
          usersMap[user.id] = user;
        }
      }

      ret.users = users;
      ret.usersMap = usersMap;
    }

    return ret;
  }, [organizationUsers]);
}
