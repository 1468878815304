/**
 * PdItem_Entity type guard.
 *
 * @param {any} pdItemJson PdItem object from API
 * @returns {boolean} Return true if type is PdItem_Entity
 */

import { PdLabor_Entity } from "entities/pdLabor";

export default class PdItem_Entity {
  constructor(itemJson: PdItem_Entity) {
    try {
      this.id = itemJson.id;
      this.groupID = itemJson.groupID;
      this.projectID = itemJson.projectID;
      this.name = itemJson.name;
      this.description = itemJson.description;
      this.scale = itemJson.scale;
      this.overscale = itemJson.overscale;
      this.rate = itemJson.rate;
      this.extra = itemJson.extra;
      this.quantity = itemJson.quantity;
      this.subtotal = itemJson.subtotal;
      this.reservedKey = itemJson.reservedKey;
      this.position = itemJson.position;
      this.labors = itemJson.labors;
      this.createdAt = itemJson.createdAt;
      this.updatedAt = itemJson.updatedAt;
    } catch (e) {
      console.log(`Failed to create new instance of ${PdItem_Entity.name}`);
    }
  }

  id: number;
  groupID: number;
  projectID: number;
  name: string;
  description: string;
  scale: number;
  overscale: number;
  rate: number;
  extra: number;
  quantity: number;
  subtotal: number;
  reservedKey: string;
  position: number;
  labors: Array<PdLabor_Entity>;
  createdAt: string;
  updatedAt: string;
}
