import { Action } from "entities/action";
import { useMemo } from "react";
import { useGetActionQuery, useGetActionsQuery } from "./actionEndpoints";

export function useAction(actionID: number | undefined) {
  const actionQuery = useGetActionQuery(actionID ?? 0, {
    skip: (actionID ?? 0) <= 0,
  });
  const actionEntity = actionQuery.data;

  return useMemo(() => {
    const ret: typeof actionQuery & { action?: Action | undefined } = {
      ...actionQuery,
    };
    if (actionEntity) ret.action = new Action(actionEntity);
    return ret;
  }, [actionEntity]);
}

export function useActions() {
  const actionQuery = useGetActionsQuery();
  const actionEntitiesMap = actionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof actionQuery & {
      actions?: Action[];
      actionsMap?: { [id: number]: Action };
    } = {
      ...actionQuery,
      actions: [],
      actionsMap: {},
    };
    if (actionEntitiesMap) {
      const actions = [];
      const actionsMap = {};

      for (const key in actionEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(actionEntitiesMap, key)) {
          const c = actionEntitiesMap[key];
          const action = new Action(c);
          actions.push(action);
          actionsMap[action.id] = action;
        }
      }
      ret.actions = actions;
      ret.actionsMap = actionsMap;
    }

    return ret;
  }, [actionEntitiesMap]);
}
