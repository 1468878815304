import {
  Avatar,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Grow, Skeleton } from "@mui/material";
import { Box, alpha } from "@mui/system";
import { GridColumns } from "@mui/x-data-grid-pro";
import { Dictionary } from "@reduxjs/toolkit";
import { CONNECT } from "config";
import { Instrument } from "entities/instrument";
import { Job } from "entities/job";
import { Musician, Musician_Entity } from "entities/musician";
import { ProjectHiring } from "entities/projectHiring";
import { Section } from "entities/section";
import { SectionRole } from "entities/sectionRole";
import { Stage } from "entities/stage";
import { WorkSession } from "entities/workSession";
import { getCurrentStageName, mapToArray } from "helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  projectHiringDisplaySelector,
  projectHiringGroupedSelector,
  projectHiringSelectionSelector,
  projectHiring_Select_Musicians,
  projectHiring_Select_WorkSessions,
  projectHiring_UnselectAll_Musicians,
  projectHiring_UnselectAll_WorkSessions,
  projectHiring_Unselect_Musicians,
  projectHiring_Unselect_WorkSessions,
} from "reducers/projectHiring";
import {
  selectedProjectIDSelector,
  setSelectedMusicianID,
  setSelectedWorkSessionID,
} from "reducers/rhapsody";
import { useInstruments } from "redux/instrument/instrumentHooks";
import { useProjectHiringMusicians } from "redux/musician/musicianHooks";
import { useProjectHiring } from "redux/projectHiring/projectHiringHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { useStages } from "redux/stage/stageHooks";
import { RepaceWith } from "../ProjectActionsRequired/ProjectActionsRequired";
import { useProjectActionsRequired } from "../useProjectHooks";

export function useProjectHiringDataGrid() {
  const display = useSelector(projectHiringDisplaySelector);

  const columns = useColumns();
  const sectionRows = useSectionsRows();
  const alphabeticalRows = useAlphabeticalRows();

  const ret = { columns, rows: [] };
  if (display === "alphabetical") ret.rows = alphabeticalRows;
  if (display === "sections") ret.rows = sectionRows;

  return ret;
}

export function useProjectHiringSelectAll() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectHiring, isLoading: l1 } = useProjectHiring(projectID);
  const { musicians, isLoading: l2 } = useProjectHiringMusicians(projectID);
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  const [requested, setRequested] = useState(false);
  const [type, setType] = useState<"workSessions" | "musicians">();
  const workSessions = projectHiring?.sessions ?? [];

  const ret = (type?: "workSessions" | "musicians") => {
    setType(type);
    setRequested(true);
  };

  useEffect(() => {
    return () => {
      setRequested(false);
    };
  }, []);

  useEffect(() => {
    if (!l1 && !l2) {
      setInit(true);
    }
  }, [l1, l2]);

  useEffect(() => {
    if (init && requested && musicians?.length && workSessions?.length) {
      if (type === "workSessions") {
        dispatch(projectHiring_Select_WorkSessions(workSessions));
      } else if (type === "musicians") {
        dispatch(projectHiring_Select_Musicians(musicians));
      } else {
        dispatch(projectHiring_Select_Musicians(musicians));
        dispatch(projectHiring_Select_WorkSessions(workSessions));
      }
      setRequested(false);
      setType(null);
    }
  }, [init, requested, workSessions, musicians]);

  return ret;
}

export function useCallSentForProject(projectID) {
  const { projectHiring, isLoading: l1 } = useProjectHiring(projectID);
  const jobs = projectHiring?.jobs;

  if (l1) return false;

  for (const key in jobs) {
    if (Object.prototype.hasOwnProperty.call(jobs, key)) {
      const job = jobs[key];
      if (job?.mercuryStageID > 0) return true;
    }
  }

  return false;
}

export function useProjectHiringUnselectAll() {
  const dispatch = useDispatch();

  return (type?: "workSessions" | "musicians") => {
    if (type === "workSessions") {
      dispatch(projectHiring_UnselectAll_WorkSessions());
    } else if (type === "musicians") {
      dispatch(projectHiring_UnselectAll_Musicians());
    } else {
      dispatch(projectHiring_UnselectAll_Musicians());
      dispatch(projectHiring_UnselectAll_WorkSessions());
    }
  };
}

export function useProjectHiringSelectSection(
  sectionID: number,
  select: boolean
) {
  const musicians = useMusiciansForSections(sectionID);
  const dispatch = useDispatch();

  return () => {
    select
      ? dispatch(projectHiring_Select_Musicians(musicians))
      : dispatch(projectHiring_Unselect_Musicians(musicians));
  };
}

function useMusiciansForSections(sectionID: number) {
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectHiring, isLoading: l1 } = useProjectHiring(projectID);
  const jobs = projectHiring?.jobs ?? [];
  const { musiciansMap: projectHiringMusiciansMap, isLoading: l2 } =
    useProjectHiringMusicians(projectID);

  const jobsForSection = jobs.filter((j) => j.sectionID === sectionID);

  return jobsForSection.reduce((a, j) => {
    const musician = projectHiringMusiciansMap[j.musicianID];
    if (musician && a.indexOf(musician) < 0) {
      a.push(musician);
    }
    return a;
  }, []);
}

export function useColumns() {
  const grouped = useSelector(projectHiringGroupedSelector);
  const projectID = useSelector(selectedProjectIDSelector);
  const selectAll = useProjectHiringSelectAll();
  const unselectAll = useProjectHiringUnselectAll();
  const display = useSelector(projectHiringDisplaySelector);
  const { projectHiring, isLoading: l1 } = useProjectHiring(projectID);
  const { musiciansMap } = useProjectHiringMusicians(projectID);
  const selection = useSelector(projectHiringSelectionSelector);
  let allWorkSessionsSelected = true;
  const { musicians } = useProjectHiringMusicians(projectID);
  let allMusiciansSelected = true;
  const { musicianJobs } = useProjectActionsRequired();
  projectHiring?.sessions.forEach((s) => {
    if (!selection.selectedSessionMap[s.id]) allWorkSessionsSelected = false;
  });

  const { sectionRolesMap, isLoading: l2 } = useSectionRoles();
  const { stagesMap, isLoading: l3 } = useStages();
  const { instrumentsMap, isLoading: l4 } = useInstruments();

  musicians?.forEach((m) => {
    if (!selection.selectedMusicianMap[m.id]) allMusiciansSelected = false;
  });

  if (l1 || l2 || l3 || l4) return [];
  const workSessions = projectHiring?.sessions ?? [];
  const columns: GridColumns = [
    {
      field: "musicianID",
      groupable: true,
      width: 200,
      disableColumnMenu: true,
      sortable: display !== "sections",
      renderHeader: () => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              flex: 1,
            }}
          >
            <Tooltip title="All Musicians">
              <Checkbox
                checked={allMusiciansSelected}
                onClick={() =>
                  allMusiciansSelected
                    ? unselectAll("musicians")
                    : selectAll("musicians")
                }
                size="sm"
              />
            </Tooltip>
            <Tooltip title="All Work Sessions">
              <Checkbox
                checked={allWorkSessionsSelected}
                onClick={() =>
                  allWorkSessionsSelected
                    ? unselectAll("workSessions")
                    : selectAll("workSessions")
                }
                size="sm"
              />
            </Tooltip>
          </Box>
        );
      },
      renderCell: (p) =>
        p.id[0] !== "s" ? (
          <MusicianCell
            musicianID={p.row.musicianID}
            musician={musiciansMap[p.row.musicianID]}
            sectionOrder={p.row.sectionOrder}
            projectHiring={projectHiring}
            musicianJobs={musicianJobs}
          />
        ) : (
          <SectionHeader section={p.row.section} />
        ),
      valueGetter: (p) => {
        if (p.id[0] === "s") return p.row.section.name;
        return `${p.row.musician?.lastName}`;
      },
    },
  ];
  const workSessionIDs = workSessions.reduce<number[]>((a, w) => {
    if (a.indexOf(w.id) === -1) a.push(w.id);
    return a;
  }, []);

  workSessionIDs.forEach((workSessionID) => {
    columns.push({
      field: `${workSessionID}`,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (p) => (
        <WorkSessionHeader workSessionID={parseInt(p.field)} />
      ),
      description: workSessions.find((s) => s.id === workSessionID)?.layoutHash,
      width: 200,
      renderCell: (p) =>
        p.id[0] !== "s" ? (
          <JobCell
            sectionRolesMap={sectionRolesMap}
            stagesMap={stagesMap}
            instrumentsMap={instrumentsMap}
            jobs={p.value}
          />
        ) : undefined,
    });
  });

  return grouped
    ? columns.reduce<GridColumns>((a, i) => {
        if (a?.findIndex((e) => e.description === i.description) === -1) {
          a.push(i);
        }
        return a;
      }, [])
    : columns;
}

export function useSectionsRows() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectHiring, isLoading: l1 } = useProjectHiring(projectID);
  const { sectionsMap: sMap, isLoading: l2 } = useSections();
  const { musiciansMap: projectHiringMusiciansMap, isLoading: l3 } =
    useProjectHiringMusicians(projectID);
  const jobs = projectHiring?.jobs ?? [];
  const workSessions = projectHiring?.sessions ?? [];

  const rows = [];

  const sectionsMap = jobs.reduce<Dictionary<Section>>((a, j) => {
    if (j.sectionID) a[j.sectionID] = sMap[j.sectionID];
    return a;
  }, {});

  const sections = mapToArray(sectionsMap);
  sections.sort((a, b) => {
    if (a.familyID === b.familyID) {
      return a.pos - b.pos;
    }
    return a.familyPos - b.familyPos;
  });

  const workSessionIDs = workSessions.reduce<number[]>((a, w) => {
    if (a.indexOf(w.id) === -1) a.push(w.id);
    return a;
  }, []);

  sections.forEach((s) => {
    if (!s) return;
    const sectionRow = {
      id: `s_${s.id}`,
      section: s,
    };
    const musiciansRows = [];
    const jobsForSection = jobs
      .filter((j) => j.sectionID === s.id)
      .sort((a, b) => a.sectionOrder - b.sectionOrder);

    const musicianRows = {};
    jobsForSection.forEach((j) => {
      if (j.musicianID) {
        musicianRows[`${j.musicianID}_${j.sectionOrder}_${j.sectionID}`] = true;
      }
    });

    for (const key in musicianRows) {
      if (Object.prototype.hasOwnProperty.call(musicianRows, key)) {
        const split = key.split("_");
        const musicianID = parseInt(split[0]);
        if (musicianID == -1) continue;
        const sectionOrder = parseInt(split[1]);
        const sectionID = parseInt(split[2]);

        const row = {
          id: `musicianID:${musicianID}_sectionOrder:${sectionOrder}_sectionID:${sectionID}`,
          musician: projectHiringMusiciansMap[musicianID],
          musicianID: musicianID,
          sectionOrder,
        };

        workSessionIDs.forEach((workSessionID) => {
          row[workSessionID] = jobs.filter(
            (j) =>
              j.musicianID === musicianID &&
              j.sessionID === workSessionID &&
              j.sectionOrder === sectionOrder
          );
        });

        musiciansRows.push(row);
      }
    }
    if (musiciansRows.length) {
      rows.push(sectionRow);
      rows.push(...musiciansRows);
    }
  });

  return rows;
}

export function useAlphabeticalRows() {
  const projectID = useSelector(selectedProjectIDSelector);
  const {
    projectHiring,
    isLoading: l1,
    isFetching: f1,
  } = useProjectHiring(projectID);
  const {
    musiciansMap: projectHiringMusiciansMap,
    isLoading: l2,
    isFetching: f2,
  } = useProjectHiringMusicians(projectID);
  const jobs = projectHiring?.jobs ?? [];
  const workSessions = projectHiring?.sessions ?? [];

  if (l1 || l2 || f2 || f1) return [];

  const rows = [];

  const musiciansMap = jobs.reduce<Dictionary<Musician_Entity>>((a, j) => {
    if (j.musicianID) a[j.musicianID] = projectHiringMusiciansMap[j.musicianID];
    return a;
  }, {});

  const musicians = mapToArray(musiciansMap);
  musicians.sort((a, b) => a.lastName?.localeCompare(b.lastName));

  const workSessionIDs = workSessions.reduce<number[]>((a, w) => {
    if (a.indexOf(w.id) === -1) a.push(w.id);
    return a;
  }, []);

  musicians.forEach((musician) => {
    if (musician) {
      const row = { id: musician.id, musician, musicianID: musician.id };
      workSessionIDs.forEach((workSessionID) => {
        row[workSessionID] = jobs.filter(
          (j) => j.musicianID === musician.id && j.sessionID === workSessionID
        );
      });
      rows.push(row);
    }
  });

  return rows;
}

function MusicianCell({
  musicianID,
  sectionOrder,
  projectHiring,
  musician,
  musicianJobs,
}: {
  musicianID: number;
  sectionOrder: number;
  projectHiring: ProjectHiring;
  musician: Musician;
  musicianJobs: {
    musicianID: number;
    musician: Musician;
    mercuryStageID: number;
    stage: Stage;
    jobs: Job[];
  }[];
}) {
  const dispatch = useDispatch();
  const selection = useSelector(projectHiringSelectionSelector);
  const selected = selection.selectedMusicianMap[musicianID] !== undefined;
  const jobsForMusician = projectHiring.jobs.filter(
    (j) => j.musicianID === musicianID
  );
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();

  const actionMusician = musicianJobs.find((m) => m.musicianID === musicianID);
  const jobs = actionMusician?.jobs.filter(
    (j) => j.sectionOrder === sectionOrder
  );

  const handleCheck = (value: boolean) => {
    if (value) {
      dispatch(projectHiring_Select_Musicians([musician]));
    } else {
      dispatch(projectHiring_Unselect_Musicians([musician]));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flex: 1,
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 0,
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Checkbox
          size="sm"
          onChange={(e) => handleCheck(e.target.checked)}
          checked={selected}
        />
        {`${
          sectionOrder !== undefined ? `${sectionOrder + 1}. ` : ""
        }${musician?.fullName()}`}
        {/* <Typography noWrap level="body2">
          {`${
            sectionOrder !== undefined ? `${sectionOrder + 1}. ` : ""
          }${musician?.fullName()}`}
        </Typography> */}
      </Box>
      <Box
        sx={{
          position: "sticky",
          right: 0,
          height: "calc(100% - 2px)",
          backdropFilter: "blur(10px)",
          background: "none",
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          pl: 1,
          borderLeft: "solid 1px #E0E0E0",
        }}
      >
        {actionMusician ? <RepaceWith size="sm" jobs={jobs} /> : ""}
        <Box
          sx={{
            height: 16,
            marginTop: "-2px",
            width: 16,
            textAlign: "center",
            color: alpha("#9e9e9e", 0.8),
            cursor: "pointer",
            "&:hover": {
              color: alpha("#9e9e9e", 0.9),
            },
            "&:active": {
              color: alpha("#9e9e9e", 1),
            },
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </Box>
      </Box>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {/* <MenuItem>
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              gap: 1,
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <Box>Answer for {musician.firstName}</Box>
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          </Box>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            window.open(
              `${CONNECT}?token=${jobsForMusician[0].publicToken}`,
              "_blank"
            );
          }}
        >
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              gap: 1,
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <Box> Open Invite</Box>
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => dispatch(setSelectedMusicianID(musician.id))}>
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              flex: 1,
              gap: 1,
              justifyContent: "space-between",
            }}
          >
            <Box>Open Musician Profile</Box>
            <i className="fa-solid fa-arrow-up-from-square"></i>
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
}

function JobCell({
  jobs,
  stagesMap,
  sectionRolesMap,
  instrumentsMap,
}: {
  jobs?: Job[];
  sectionRolesMap: Dictionary<SectionRole>;
  stagesMap: Dictionary<Stage>;
  instrumentsMap: Dictionary<Instrument>;
}) {
  const firstJob = jobs?.length && jobs[0];
  const instrument = instrumentsMap[firstJob.instrumentID];
  const stage = stagesMap[firstJob?.mercuryStageID];

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      {firstJob?.mercuryStageID ? (
        <Chip
          size="sm"
          sx={{ background: alpha(stage.color, 0.1), color: stage.color }}
        >
          {getCurrentStageName(stage)}
        </Chip>
      ) : (
        <Typography level="body3">{firstJob ? "Ready" : " "}</Typography>
      )}
      {instrument || firstJob.sectionRoleID ? (
        <Typography level="body4">
          {instrument?.name} - {sectionRolesMap[firstJob.sectionRoleID]?.name}
        </Typography>
      ) : (
        []
      )}
    </Box>
  );
}

function WorkSessionHeader({ workSessionID }: { workSessionID: number }) {
  const projectID = useSelector(selectedProjectIDSelector);
  const grouped = useSelector(projectHiringGroupedSelector);
  const { projectHiring } = useProjectHiring(projectID);
  const selection = useSelector(projectHiringSelectionSelector);
  const dispatch = useDispatch();
  const workSessions = projectHiring.sessions;
  const selected = selection.selectedSessionMap[workSessionID] !== undefined;
  const workSession = workSessions.find((w) => w.id === workSessionID);
  const target = grouped
    ? workSessions.filter((s) => s.layoutHash === workSession.layoutHash)
    : [workSession];

  const handleCheck = (value: boolean) => {
    if (value) {
      dispatch(projectHiring_Select_WorkSessions(target));
    } else {
      dispatch(projectHiring_Unselect_WorkSessions(target));
    }
  };

  function getWorkSessionHeader(ws: WorkSession) {
    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          width: "100%",
          flex: 1,
        }}
      >
        {target.length === 1 ? (
          <Checkbox
            onChange={(e) => handleCheck(e.target.checked)}
            checked={selected}
            size="sm"
            sx={{ flexShrink: 1 }}
          />
        ) : (
          []
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width:
              target.length > 1 ? "calc(100% - 45px)" : "calc(100% - 65px)",
            overflow: "hidden",
            flexShrink: 0,
            cursor: target.length === 1 ? "pointer" : undefined,
          }}
          onClick={
            target.length === 1 ? () => handleCheck(!selected) : undefined
          }
        >
          <Typography
            noWrap
            sx={{ color: "black", fontWeight: 600 }}
            level="body2"
            endDecorator={
              moment(workSession.dateToUTC).isBefore(moment()) ? (
                <Typography level="body4" color="warning">
                  Past
                </Typography>
              ) : (
                []
              )
            }
          >
            {workSession?.title}
          </Typography>
          <Typography noWrap level="body3">
            {workSession.formatDateRange()}
          </Typography>
        </Box>
        <Box sx={{ width: 50, flexShrink: 1 }}>
          <Grow in>
            <Tooltip title="Open Work Session" arrow size="sm">
              <IconButton
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={(e) =>
                  dispatch(setSelectedWorkSessionID(workSession?.id))
                }
              >
                <i className="fa-solid fa-arrow-up-from-square"></i>
              </IconButton>
            </Tooltip>
          </Grow>
        </Box>
      </Box>
    );
  }

  if (target.length > 1) {
    return (
      <Tooltip
        variant="plain"
        size="lg"
        arrow
        title={
          <Box
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {target.map((w) => (
              <>
                {getWorkSessionHeader(w)}
                <Divider />
              </>
            ))}
          </Box>
        }
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Checkbox
            onChange={(e) => handleCheck(e.target.checked)}
            checked={selected}
            size="sm"
          />
          <Avatar
            size="sm"
            color="danger"
            variant="solid"
            sx={{ height: 20, width: 20 }}
          >
            {target.length}
          </Avatar>
          <Box
            sx={{ display: "flex", flexDirection: "column", lineHeight: 0.8 }}
          >
            <Typography sx={{ fontWeight: 600, color: "black" }} level="body2">
              Work Sesssion{target.length > 1 ? "s" : ""}
            </Typography>
            <Typography level="body3">Grouped Together</Typography>
          </Box>
        </Box>
      </Tooltip>
    );
  }
  return getWorkSessionHeader(workSession);
}

function SectionHeader({ section }: { section: Section }) {
  const musiciansForSection = useMusiciansForSections(section.id);
  const selection = useSelector(projectHiringSelectionSelector);

  let checked = true;
  musiciansForSection.forEach((m) => {
    if (selection.selectedMusicianMap[m.id] === undefined) checked = false;
  });

  const select = useProjectHiringSelectSection(section?.id, !checked);

  return (
    <Checkbox
      checked={checked}
      onChange={select}
      size="sm"
      label={`${section.name}`}
    />
  );
}
