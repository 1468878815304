import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const autopilotApi = createApi({
  reducerPath: "autopilotApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "autopilot" }),
  endpoints: () => ({}),
  tagTypes: ["autopilot", "hooks", "hookActions", "events", "actions"],
});
