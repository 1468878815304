import { Button, Chip, IconButton, Typography, Tooltip, Box } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Link_Entity } from "entities/link";
import { LinkTarget } from "hooks/projectSettings/projectSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
  setSelectedLinkID,
} from "reducers/rhapsody";
import { useProjectLinks } from "redux/link/linkHooks";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ProjectLinks() {
  const open = useSelector(formOpenSelector("projectLinks"));
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const { links } = useProjectLinks(projectID);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectLinks" }));
  };

  return (
    <DialogClose maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-solid fa-paperclip"></i>}
          level="h6"
        >
          Links
        </Typography>
        <Typography level="body2">
          Attach links or upload documents for the musicians. They will be
          included in the call and communication.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Tooltip
            title="New Link"
            variant="outlined"
            arrow
            size="sm"
            enterDelay={1000}
            enterNextDelay={1000}
          >
            <IconButton
              onClick={() =>
                dispatch(setFormOpen({ isOpen: true, formID: "link" }))
              }
              variant="solid"
              size="sm"
              color="neutral"
            >
              <i className="fa-solid fa-plus"></i>
            </IconButton>
          </Tooltip>
        </Box>
        {links.length ? (
          <DataGridPremium
            sx={{ background: "white" }}
            hideFooter
            onRowClick={(r) => {
              dispatch(setSelectedLinkID(r.row.id));
              dispatch(setFormOpen({ isOpen: true, formID: "link" }));
            }}
            initialState={{
              pinnedColumns: {
                right: ["target", "url"],
              },
            }}
            density="compact"
            rows={links}
            columns={[
              { field: "name", headerName: "Name" },
              { field: "description", headerName: "Description", flex: 1 },
              {
                field: "url",
                headerName: "URL",
                width: 100,
                renderCell: (p) => (
                  <Tooltip
                    title={`${p.row.url}`}
                    size="sm"
                    arrow
                    variant="outlined"
                  >
                    <Chip
                      endDecorator={
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                      }
                      size="sm"
                      variant="outlined"
                      color="neutral"
                      onClick={() => window.open(`${p.row.url}`, "_blank")}
                    >
                      Open link
                    </Chip>
                  </Tooltip>
                ),
              },
              {
                field: "target",
                headerName: "Who gets it?",
                width: 200,
                renderCell: (p) => <LinkTarget link={p.row as Link_Entity} />,
              },
            ]}
          />
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
