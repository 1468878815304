/**
 * Piece_Entity type guard.
 *
 * @param {any} pieceJson Piece object from API
 * @returns {boolean} Return true if type is Piece_Entity
 */

export default class Piece_Entity {
  static requiredFields = [
    "id",
    "name",
    "composer",
    "arranger",
    "edition",
    "notes",
    "createdAt",
    "updatedAt",
  ];

  constructor(pieceJson: Piece_Entity) {
    try {
      this.id = pieceJson.id;
      this.name = pieceJson.name;
      this.composer = pieceJson.composer;
      this.arranger = pieceJson.arranger;
      this.edition = pieceJson.edition;
      this.notes = pieceJson.notes;
      this.deleted = pieceJson.deleted;
      this.createdAt = pieceJson.createdAt;
      this.updatedAt = pieceJson.updatedAt;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  composer: string;
  arranger: string;
  edition: string;
  notes: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
}
