/**
 * ProjectTag_Entity type guard.
 *
 * @param {any} projectTagJson ProjectTag object from API
 * @returns {boolean} Return true if type is ProjectTag_Entity
 */

export default class ProjectTag_Entity {
  constructor(projectTagJson: ProjectTag_Entity) {
    try {
      this.id = projectTagJson.id;
      this.projectID = projectTagJson.projectID;
      this.tagID = projectTagJson.tagID;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectID: number;
  tagID: number;
}
