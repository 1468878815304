import {
  Alert,
  Checkbox,
  Button as JoyButton,
  Tab,
  Chip as JoyChip,
  TabList,
  Tabs,
  Typography,
} from "@mui/joy";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  Icon,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { Autopilot_Entity } from "entities/autopilot";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  setFormOpen,
  setSelectedWorkSessionID,
  setTabIndex,
  tabIndexSelector,
} from "reducers/rhapsody";
import { useActions } from "redux/action/actionHooks";
import { rhapsodyApi } from "redux/api";
import { useProjectAutopilot } from "redux/autopilot/autopilotHooks";
import { useUpdateProjectEventMutation } from "redux/event/eventEndpoints";
import { useProjectEvents } from "redux/event/eventHooks";
import { useHooks } from "redux/hook/hookHooks";
import { useMusician } from "redux/musician/musicianHooks";
import { useUpdateProjectAutopilotMutation } from "redux/project/projectEndpoints";
import { useWorkSession } from "redux/workSession/workSessionHooks";

const durations = [
  { value: 60, label: "1mn" },
  { value: 300, label: "5mn" },
  { value: 600, label: "10mn" },
  { value: 900, label: "15mn" },
  { value: 1800, label: "30mn" },
  { value: 2700, label: "45mn" },
  { value: 3600, label: "1hr" },
  { value: 7200, label: "2hr" },
  { value: 18000, label: "5hr" },
  { value: 36000, label: "10hr" },
  { value: 86400, label: "24h" },
  { value: 129600, label: "36h" },
  { value: 172800, label: "48h" },
  { value: 259200, label: "72h" },
];

const timeDelay = [
  { value: 0, label: "No delay" },
  { value: 60 * 5, label: "5mn" },
  { value: 60 * 10, label: "10mn" },
  { value: 60 * 20, label: "20mn" },
];

export default function Autopilot({ projectID }) {
  const dispatch = useDispatch();
  const [_autopilot, _setAutopilot] = React.useState<Autopilot_Entity>();
  const [hasChanged, setHasChanged] = React.useState(0);
  const { actionsMap } = useActions();
  const { hooksMap } = useHooks();
  const [updateProjectEvent] = useUpdateProjectEventMutation();
  const [updateProjectAutopilot] = useUpdateProjectAutopilotMutation();

  const open = useSelector(formOpenSelector("autopilot"));
  const { autopilot } = useProjectAutopilot(projectID);
  const { events } = useProjectEvents(projectID);

  const tabIndex = useSelector(tabIndexSelector("autopilot"));

  const askQuestion = useAskQuestion();

  useEffect(() => {
    if (autopilot) {
      _setAutopilot(JSON.parse(JSON.stringify(autopilot)));
    }
  }, [autopilot]);

  useEffect(() => {
    if (hasChanged) {
      updateProjectAutopilot({ projectID, body: _autopilot })
        .unwrap()
        .then(() => {
          dispatch(rhapsodyApi.util.invalidateTags(["autopilot"]));
        });
    }
  }, [hasChanged]);

  const closeDrawer = () => {
    dispatch(setFormOpen({ formID: "autopilot", isOpen: false }));
  };

  function getSettings() {
    const updater = (key, value) => {
      _setAutopilot((e) => ({ ...e, [key]: value }));
      setHasChanged((c) => c + 1);
    };
    return (
      <Grid
        container
        spacing={2}
        style={
          {
            // filter: !_autopilot.enabled && "grayscale(100%)",
            // opacity: !_autopilot.enabled && 0.5,
          }
        }
      >
        {_autopilot.hooks?.map((h, i) => {
          const hookUpdater = (key, value) => {
            const _hooks = [..._autopilot.hooks];
            _hooks[i][key] = value;
            updater("hooks", _hooks);
          };

          let color;
          if (h.enabled) color = "success";
          if (h.enabled && !_autopilot.enabled) color = "warning";
          return (
            <>
              <Grid item xs={12} key={h.id}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    endDecorator={
                      h.id === 1 ? (
                        <JoyChip color="neutral" size="sm">
                          BETA
                        </JoyChip>
                      ) : (
                        []
                      )
                    }
                    style={{ fontWeight: 600, flexGrow: 1 }}
                  >
                    {h.description}
                  </Typography>
                  <Tabs
                    size="sm"
                    variant="soft"
                    value={h.enabled ? 1 : 0}
                    onChange={(event, value) => {
                      if (h.id === 1 && value) {
                        askQuestion(
                          "This is a BETA Feature",
                          ["Cancel", "Activate"],
                          {
                            subtitle: (
                              <Typography>
                                By activating this feature, please be aware that
                                it is currently in its beta stage. We are
                                diligently working to enhance its reliability.
                                Exercise caution during use, as it may still
                                contain some unforeseen bugs.
                              </Typography>
                            ),
                          }
                        ).then((a) => {
                          if (a) {
                            hookUpdater("enabled", value ? true : false);
                            setHasChanged((e) => e + 1);
                          }
                        });
                      } else {
                        hookUpdater("enabled", value ? true : false);
                        setHasChanged((e) => e + 1);
                      }
                    }}
                    sx={{ borderRadius: "lg" }}
                  >
                    <TabList size="sm">
                      <Tab value={0}>Off</Tab>
                      <Tab
                        value={1}
                        color={color}
                        variant={h.enabled ? "solid" : undefined}
                      >
                        On
                      </Tab>
                    </TabList>
                  </Tabs>
                </Box>
              </Grid>
              {h.actions.length ? (
                <Grid
                  item
                  xs={12}
                  style={
                    {
                      // filter: !h.enabled && "grayscale(100%)",
                      // opacity: !h.enabled && 0.5,
                    }
                  }
                >
                  {h.options?.map((o, m) => {
                    const optionUpdater = (value) => {
                      const _options = [...h.options];
                      _options[m] = value;
                      hookUpdater("options", _options);
                    };
                    return (
                      <Box
                        sx={{ display: "flex", alignItems: "baseline" }}
                        key={o.id}
                      >
                        <Tooltip title={o.description}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color={h.enabled ? "success" : "warning"}
                                size="sm"
                                onChange={(e) =>
                                  optionUpdater({
                                    ...o,
                                    value: e.target.checked ? "1" : "0",
                                  })
                                }
                                checked={o.value === "1"}
                              />
                            }
                            label={
                              <Typography sx={{ ml: 1 }} level="body2">
                                {o.name}
                              </Typography>
                            }
                          />
                        </Tooltip>
                      </Box>
                    );
                  })}
                  {h.triggers?.map((t, j) => {
                    const triggerUpdater = (value) => {
                      const _triggers = [...h.triggers];
                      _triggers[j] = value;
                      hookUpdater("triggers", _triggers);
                    };
                    const label = {
                      musician_nudge: "After",
                      musician_remind: "Before",
                    };
                    if (t.timedelay != 0)
                      return (
                        <Box
                          sx={{ display: "flex", alignItems: "baseline" }}
                          key={t.id}
                        >
                          {t.timedelay > 0 && (
                            <Typography level="body2">
                              {label[t.event]}
                            </Typography>
                          )}
                          <Select
                            value={Math.abs(t.timedelay)}
                            size="small"
                            sx={{ ml: 1, mr: 1 }}
                            onChange={(e) =>
                              triggerUpdater({
                                ...t,
                                timedelay:
                                  t.timedelay < 0
                                    ? -e.target.value
                                    : e.target.value,
                              })
                            }
                          >
                            {durations?.map((d) => (
                              <MenuItem key={d.label} value={d.value}>
                                {d.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {t.timedelay < 0 && (
                            <Typography level="body2">
                              {label[t.event]}
                            </Typography>
                          )}
                        </Box>
                      );
                  })}
                </Grid>
              ) : (
                []
              )}
              {h.actions.length ? (
                <Grid
                  item
                  xs={12}
                  style={
                    {
                      // filter: !h.enabled && "grayscale(100%)",
                      // opacity: !h.enabled && 0.5,
                    }
                  }
                >
                  <ButtonGroup
                    size="large"
                    variant="outlined"
                    aria-label="text button group"
                  >
                    {h.actions?.map((a, j) => {
                      const actionUpdater = (value) => {
                        const _actions = [...h.actions];
                        _actions[j] = value;
                        hookUpdater("actions", _actions);
                      };

                      const hasParent = a.parentActionID;
                      const parentEnabled =
                        hasParent &&
                        h.actions.find((e) => e.id === a.parentActionID)
                          ?.enabled;

                      return (
                        <CustomButton
                          active={_autopilot.enabled && h.enabled}
                          title={`${a.name}`}
                          timedelay={a.timedelay}
                          disabled={hasParent && !parentEnabled}
                          enabled={a.enabled}
                          gray={hasParent && !parentEnabled}
                          onClick={() =>
                            actionUpdater({ ...a, enabled: !a.enabled })
                          }
                          onDelayChange={(v) => {
                            actionUpdater({
                              ...a,
                              timedelay: v,
                            });
                          }}
                          icon={a.icon}
                          tooltip={a.description}
                          key={a.id}
                        />
                      );
                    })}
                  </ButtonGroup>
                </Grid>
              ) : (
                []
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={h.enabled ? "success" : "warning"}
                      size="sm"
                      onChange={(e) => hookUpdater("notify", e.target.checked)}
                      checked={h.notify}
                    />
                  }
                  label={
                    <Typography sx={{ ml: 1 }} level="body2">
                      Notify me by e-mail
                    </Typography>
                  }
                />
                <Box
                  sx={{
                    height: 8,
                    background: "rgba(155,155,155,0.3)",
                    mt: 2,
                    mb: 2,
                  }}
                />
              </Grid>
            </>
          );
        })}
      </Grid>
    );
  }

  function getJournal() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                flex: 1,
              }}
            >
              <Typography level="body2" sx={{ color: "text.secondary" }}>
                <i className="fa-regular fa-arrow-left-long"></i> Pending
                Actions
              </Typography>
              <Typography level="body3" sx={{ color: "text.secondary" }}>
                Still time to cancel.
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                flex: 1,
              }}
            >
              <Typography level="body2" sx={{ color: "text.secondary" }}>
                Completed Actions{" "}
                <i className="fa-regular fa-arrow-right-long"></i>
              </Typography>
              <Typography level="body3" sx={{ color: "text.secondary" }}>
                Can't be canceled anymore.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Timeline>
            {events.map((e) => {
              {
                /* const e = event; */
              }
              if (e.scope === "Configuration") {
                return (
                  <Tooltip key={e.id} title={moment(e.createdAt).format("lll")}>
                    <Chip
                      sx={{ m: 1 }}
                      label={`Autopilot configuration ${e.description} by ${e.createdBy}`}
                    />
                  </Tooltip>
                );
              }

              const hook = hooksMap[e.hookID];

              if (e.scope === "Hook") {
                let tooltip = "Scheduled";
                let lineColor = "#2196f3";
                let dotIcon = "fa-duotone fa-clock";
                let severity: "primary" | "success" | "warning" = "primary";
                let date = e.startingAt;
                if (e.completed) {
                  lineColor = "#4caf50";
                  dotIcon = "fa-regular fa-circle-check";
                  tooltip = "Completed";
                  severity = "success";
                  date = e.completedAt;
                }
                if (e.cancelled) {
                  lineColor = "#ff9800";
                  dotIcon = "fa-regular fa-trash-can-xmark";
                  tooltip = "Canceled";
                  severity = "warning";
                  date = e.cancelledAt;
                }
                return (
                  <TimelineItem
                    key={e.id}
                    position={e.completed || e.cancelled ? "right" : "left"}
                  >
                    <TimelineSeparator>
                      <Tooltip title={tooltip}>
                        <TimelineDot
                          variant="filled"
                          sx={{
                            background: "none",
                            boxShadow: "none",
                            mt: 1,
                            mb: 1,
                          }}
                        >
                          <i
                            style={{ color: lineColor }}
                            className={dotIcon}
                          ></i>
                        </TimelineDot>
                      </Tooltip>
                      <TimelineConnector sx={{ background: lineColor }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ mt: 3 }}>
                      {!e.cancelled && !e.completed ? (
                        <Tooltip title={moment(date).format("lll")}>
                          <Typography level="body2">
                            <Countdown prefix="In " date={moment(date)} />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Tooltip title={moment(date).format("lll")}>
                          <Typography level="body2">
                            {moment(date).fromNow()}
                          </Typography>
                        </Tooltip>
                      )}
                      {hook.model === "musician" ? (
                        <Musician id={e.itemID} />
                      ) : (
                        []
                      )}

                      {hook.model === "workSession" ? (
                        <WorkSession id={e.itemID} />
                      ) : (
                        []
                      )}

                      <Typography
                        sx={{ textTransform: "capitalize", mb: 1 }}
                        level="body2"
                      >
                        {e.description}
                      </Typography>
                      {e.steps?.map((s) => {
                        const action = actionsMap[s.actionID];
                        const timeDelay = moment
                          .duration(s.timedelay, "s")
                          .asMinutes();
                        return (
                          <Tooltip
                            key={s.id}
                            title={moment(s.completedAt).format("lll")}
                          >
                            <Typography
                              level="body3"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent:
                                  e.completed || e.cancelled ? "start" : "end",
                                gap: 0.5,
                                color: s.completed
                                  ? "#4caf50"
                                  : "text.secondary",
                              }}
                              display="block"
                            >
                              <i className={`${action?.icon}`}></i>{" "}
                              {action?.name}{" "}
                              {moment(s.completedAt).isAfter(moment()) ? (
                                <Countdown
                                  prefix="in "
                                  date={moment(s.completedAt)}
                                />
                              ) : (
                                <>
                                  {s.timedelay > 0
                                    ? ` after ${timeDelay} minute${
                                        timeDelay > 1 ? "s" : ""
                                      }`
                                    : ""}
                                </>
                              )}
                              {s.completed ? (
                                <i className="fa-regular fa-circle-check p-left"></i>
                              ) : (
                                []
                              )}
                            </Typography>
                          </Tooltip>
                        );
                      })}
                      <JoyButton
                        color={severity}
                        onClick={() => {
                          if (!e.cancelled && !e.completed)
                            askQuestion(
                              "You are about to cancel an autopilot action. Are you sure about this?",
                              ["Cancel", "Yes"]
                            ).then((a) => {
                              if (a)
                                updateProjectEvent({
                                  eventID: e.id,
                                  body: {
                                    cancelled: true,
                                  },
                                });
                            });
                        }}
                        size="sm"
                        variant={
                          e.cancelled || e.completed ? "outlined" : "soft"
                        }
                        sx={{
                          mt: 1,
                          opacity: 1,
                          cursor:
                            e.cancelled || e.completed
                              ? "not-allowed"
                              : undefined,
                        }}
                      >
                        {e.cancelled ? `Canceled by ${e.cancelledBy}` : []}
                        {e.completed ? "Completed" : []}
                        {!e.cancelled && !e.completed ? "Cancel" : []}
                      </JoyButton>
                    </TimelineContent>
                  </TimelineItem>
                );
              }
            })}
          </Timeline>
        </Grid>
      </Grid>
    );
  }

  function getStepContent() {
    switch (tabIndex) {
      case 0:
        return (
          <Box style={{ paddingLeft: 32, paddingRight: 32, paddingBottom: 32 }}>
            {getSettings()}
          </Box>
        );
      case 1:
        return (
          <Box style={{ paddingLeft: 32, paddingRight: 32 }}>
            {getJournal()}
          </Box>
        );
      default:
        break;
    }
  }

  function getHeader() {
    return (
      <Box
        style={{
          paddingLeft: 32,
          paddingRight: 32,
          paddingTop: 32,
          paddingBottom: 8,
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              zIndex: 999,
            }}
          >
            <div>
              <Typography
                startDecorator={
                  <i
                    className={`${
                      _autopilot.enabled
                        ? "fa-solid fa-robot"
                        : "fa-kit fa-solid-robot-slash"
                    } p-right`}
                  ></i>
                }
                level="h5"
                endDecorator={
                  <Typography
                    level="h5"
                    display="inline-block"
                    sx={{ fontWeight: 600 }}
                    color={_autopilot.enabled ? "success" : "warning"}
                  >
                    {_autopilot.enabled ? "is on" : "is off"}
                  </Typography>
                }
              >
                Autopilot{" "}
              </Typography>
              <Typography level="body2">
                Allows you to automate actions for you hiring.
              </Typography>
            </div>
            <Tabs
              size="sm"
              variant="soft"
              value={_autopilot.enabled ? 1 : 0}
              onChange={(event, value) => {
                _setAutopilot((e) => ({ ...e, enabled: value ? true : false }));
                setHasChanged((e) => e + 1);
              }}
              sx={{ borderRadius: "lg" }}
            >
              <TabList size="sm">
                <Tab value={0}>Off</Tab>
                <Tab
                  value={1}
                  color={_autopilot.enabled ? "success" : undefined}
                  variant={_autopilot.enabled ? "solid" : undefined}
                >
                  On
                </Tab>
              </TabList>
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {_autopilot.enabled ? (
              <Alert
                color="success"
                startDecorator={<i className="fa-solid fa-gear fa-spin"></i>}
              >
                The Autopilot is up and running.
                <br /> Configured automations will be performed automatically.
              </Alert>
            ) : (
              <Alert
                startDecorator={
                  <i className="fa-solid fa-ban fa-beat-fade"></i>
                }
                color="warning"
              >
                It's all off. Nothing will be performed automatically.
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                height: 8,
                background: "rgba(155,155,155,0.3)",
                mt: 2,
                mb: 2,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  function getBottomNavigation() {
    return (
      <BottomNavigation
        value={tabIndex}
        onChange={(event, newValue) => {
          dispatch(setTabIndex({ tabID: "autopilot", value: newValue }));
        }}
        showLabels
        style={{
          position: "sticky",
          bottom: 0,
          zIndex: 999,
          background: "rgba(240,240,240)",
          borderTop: "solid 1px rgba(155,155,155,0.3)",
        }}
      >
        <BottomNavigationAction
          label="Settings"
          icon={<Icon className="fa-solid fa-sliders-simple" />}
        />
        <BottomNavigationAction
          label="Journal"
          icon={<Icon className="fa-solid fa-newspaper" />}
        />
      </BottomNavigation>
    );
  }

  if (!_autopilot) return <div />;

  return (
    <>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={closeDrawer}
        sx={{ zIndex: 1300 }}
      >
        <Box
          sx={{
            display: "flex",
            width: 600,
            position: "relative",
            flex: 1,
            flexDirection: "column",
          }}
        >
          {getHeader()}
          <Box style={{ flexGrow: 1 }}>{getStepContent()}</Box>
          {getBottomNavigation()}
        </Box>
      </Drawer>
    </>
  );
}

function CustomButton(props) {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();
  const c = props.active ? "success" : "warning";
  const colors = {
    warning: ["#E4C663", "#492E0A"],
    success: ["#DDF4DF", "#2A5C2C"],
  };

  const enabledStyle = {
    ...props.style,
    borderColor: !props.disabled ? colors[c][1] : undefined,
    color: colors[c][1],
    background: props.enabled ? colors[c][0] : undefined,
    width: 135,
    // filter: props.gray && "grayscale(100%)",
  };
  const delayValue = timeDelay.find((t) => t.value === props.timedelay);

  return (
    <>
      <Button
        {...props}
        variant={props.enabled ? "contained" : "outlined"}
        style={enabledStyle}
      >
        <Tooltip title={props.tooltip}>
          <div style={{ textAlign: "center", padding: 8 }}>
            <i className={props.icon} style={{ fontSize: 22 }}></i>
            <br />
            {props.title}
            {!props.enabled && !props.disabled ? (
              <Typography level="body4">Click to enable</Typography>
            ) : (
              []
            )}
          </div>
        </Tooltip>
        <Tooltip title="Delay the action">
          <Button
            size="small"
            variant="text"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(e.currentTarget);
            }}
            sx={{
              position: "absolute",
              bottom: 2,
              right: 2,
              fontSize: 12,
              p: 0,
              height: 20,
              textAlign: "right",
              color: colors[c][1],
            }}
          >
            <i className="fa-solid fa-timer p-right"></i>{" "}
            {delayValue?.value == 0 ? "" : delayValue.label}
          </Button>
        </Tooltip>
      </Button>
      <Menu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
      >
        {timeDelay.map((t) => (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(null);
              props.onDelayChange(t.value);
            }}
            key={t.label}
          >
            {t.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function Countdown({ date, prefix = "" }) {
  const [countDown, setCountDown] = React.useState("0");
  const isPast = moment().isAfter(date);

  if (isPast)
    return (
      <Typography color="primary" level="body2">
        In Queue <i className="fa-solid fa-spinner fa-spin-pulse"></i>
      </Typography>
    );

  React.useEffect(() => {
    refresh();
    const interval = setInterval(refresh, 1000);
    return () => clearInterval(interval);
  }, []); //eslint-disable-line

  function refresh() {
    const now = moment(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
    const diffTime = date.unix() - now.unix();
    const duration = moment.duration(diffTime * 1000, "milliseconds");
    const days = duration.days();
    const hours = duration.hours();
    const min = duration.minutes();
    const sec = duration.seconds();

    let string = `${hours < 10 ? `0${hours}` : `${hours}`}:${
      min < 10 ? `0${min}` : `${min}`
    }:${sec < 10 ? `0${sec}` : `${sec}`}`;

    if (days > 0) string = `${days} day${days > 1 ? "s" : ""}`;

    setCountDown(string);
  }

  return (
    <div>
      {prefix}
      {countDown}
    </div>
  );
}

function Musician({ id }) {
  const { musician, isLoading, isFetching } = useMusician(id);

  if (isLoading || isFetching || !musician) return <Skeleton />;

  return (
    <Box
      sx={{
        display: "inline-flex",
        textTransform: "capitalize",
        gap: 1,
      }}
    >
      <MusicianAvatar musician={musician} size={20} />
      <Typography>{musician.fullName()}</Typography>
    </Box>
  );
}

function WorkSession({ id }) {
  const { workSession, isLoading, isFetching } = useWorkSession(id);
  const dispatch = useDispatch();

  if (isLoading || isFetching || !workSession) return <Skeleton />;

  return (
    <Tooltip
      title={
        <span>
          Open Work Session<i className="fa-solid fa-arrow-up p-left"></i>
        </span>
      }
    >
      <Box
        onClick={() => dispatch(setSelectedWorkSessionID(id))}
        sx={{
          display: "inline-flex",
          textTransform: "capitalize",
          gap: 1,
          cursor: "pointer",
        }}
      >
        <i className="fa-duotone fa-music-note p-right"></i>
        <Typography>{workSession.title}</Typography>
      </Box>
    </Tooltip>
  );
}
