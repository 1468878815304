import { Search } from "@mui/icons-material";
import { Button, Checkbox } from "@mui/joy";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

import DialogClose from "atoms/DialogClose/DialogClose";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedProjectIDSelector, setFormOpen } from "reducers/rhapsody";
import { useCreateProjectNoteMutation } from "redux/projectNote/projectNoteEndpoints";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { formOpenSelector } from "reducers/rhapsody";
import { useProjects } from "redux/project/projectHooks";

export default function ProjectNotesFromProject() {
  const [searchText, setSearchText] = useState("");
  const [index, setIndex] = useState(0);
  const [selected, setSelected] = useState([]);
  const [selectedProjectID, setSelectedProjectID] = useState<number>();
  const projectID = useSelector(selectedProjectIDSelector);
  const formOpen = useSelector(formOpenSelector("projectNotesFromProject"));
  const [createProjectNote] = useCreateProjectNoteMutation();
  const { projectNotes, isLoading, isFetching } =
    useProjectNotes(selectedProjectID);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { projects } = useProjects();

  const closeForm = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectNotesFromProject" }));
  };

  const importNotes = async () => {
    selected.forEach((n) => {
      createProjectNote({ ...n, projectID });
    });
    closeForm();
  };

  const handleSelect = (n) => {
    const index = selected.indexOf(n);
    const _selected = [...selected];
    if (index >= 0) {
      _selected.splice(index, 1);
    } else {
      _selected.push(n);
    }
    setSelected(_selected);
  };

  const getStepContent = () => {
    switch (index) {
      case 0:
        return (
          <>
            <DialogTitle>
              Which Project?
              <Typography>
                Select the project you wish to import the notes from:
              </Typography>
            </DialogTitle>
            <div
              style={{
                position: "sticky",
                alignSelf: "flex-start",
                padding: "5px 10px 10px 10px",
                width: "calc(100% - 20px)",
                top: 0,
                flex: 1,
                zIndex: 99999,
                background: theme.palette.mode === "dark" ? "#424242" : "white",
              }}
            >
              <InputBase
                style={{
                  background: "rgba(155,155,155,0.1)",
                  borderRadius: 40,
                  padding: "0px 8px",
                }}
                onChange={(e) => setSearchText(e.target.value)}
                fullWidth
                value={searchText}
                autoFocus
                startAdornment={
                  <Search style={{ color: "rgba(155,155,155,0.8)" }} />
                }
                placeholder="Search..."
              />
            </div>
            <List style={{ height: "50vh", overflow: "auto", width: 600 }}>
              {projects
                .filter((p) => !p.archived && !p.template)
                .filter(
                  (a) =>
                    `${a.name} ${a.customerName}`
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase()) !== -1
                )
                .map((p) => (
                  <ListItem
                    key={p.id}
                    button
                    divider
                    onClick={() => {
                      setSelectedProjectID(p.id);
                      setIndex((e) => e + 1);
                    }}
                    style={{ borderLeft: `solid 4px ${p.color}` }}
                  >
                    <ListItemText primary={p.name} secondary={p.customerName} />
                  </ListItem>
                ))}
            </List>
            <DialogActions>
              <Button variant="soft" color="neutral" onClick={closeForm}>
                Cancel
              </Button>
            </DialogActions>
          </>
        );
      case 1:
        return (
          <>
            <DialogContent>
              {!isLoading && !isFetching && projectNotes?.length === 0 ? (
                <Typography>
                  Looks like the selected project does not have notes.
                </Typography>
              ) : (
                <Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h6">
                      Select the notes you wish to import
                    </Typography>
                    <Button
                      size="sm"
                      variant="plain"
                      color="primary"
                      onClick={() => setSelected(projectNotes)}
                    >
                      Select All
                    </Button>
                  </Box>
                  <List
                    style={{ height: "50vh", overflow: "auto", width: 600 }}
                  >
                    {projectNotes.map((p) => (
                      <ListItem
                        key={p.id}
                        button
                        divider
                        selected={selected.indexOf(p) >= 0}
                        onClick={() => handleSelect(p)}
                      >
                        <ListItemAvatar>
                          <Checkbox checked={selected.indexOf(p) >= 0} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={p.name || <i>Untitled Note</i>}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="soft" color="neutral" onClick={closeForm}>
                Cancel
              </Button>
              <Button
                variant="soft"
                color="primary"
                onClick={() => setIndex(0)}
              >
                Select another Project
              </Button>
              <Button
                variant="solid"
                color="primary"
                disabled={selected.length === 0}
                onClick={importNotes}
              >
                Import
              </Button>
            </DialogActions>
          </>
        );
      default:
        break;
    }
  };

  return (
    <DialogClose open={formOpen} onClose={closeForm}>
      {getStepContent()}
    </DialogClose>
  );
}
