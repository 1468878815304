import { Menu, MenuItem, Typography } from "@mui/joy";
import { alpha } from "@mui/system";
import { Dictionary } from "@reduxjs/toolkit";
import { SectionRole } from "entities/sectionRole";
import { useState } from "react";
import { useSelector } from "react-redux";
import { missionControlModeSelector } from "reducers/rhapsody";

export function ChairSectionRole({
  text,
  sectionRoleIDs,
  onChange,
  disabled,
  sectionRolesMap,
  sectionRoles,
}: {
  text?: string;
  sectionRoleIDs: number[];
  onChange: (id: number) => void;
  disabled?: boolean;
  sectionRolesMap: Dictionary<SectionRole>;
  sectionRoles: SectionRole[];
}) {
  const missionControlMode = useSelector(missionControlModeSelector);
  const [anchorEl, setAnchorEl] = useState();
  const open = anchorEl ? true : false;
  const role =
    sectionRolesMap[sectionRoleIDs.length ? sectionRoleIDs[0] : undefined];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (sectionRoleID: number) => {
    onChange(sectionRoleID);
    handleClose();
  };

  if (text) {
    return (
      <Typography level="body3" sx={{ color: "#00b0ff", fontWeight: 600 }}>
        {text}
      </Typography>
    );
  }

  return (
    <>
      <Typography
        onClick={handleClick}
        level="body3"
        className="tour-role"
        // noWrap
        endDecorator={
          !disabled && missionControlMode === "edit" ? (
            <i style={{ fontSize: 8 }} className="fa-solid fa-chevron-down"></i>
          ) : (
            []
          )
        }
        sx={{
          pl: "2px",
          pr: "2px",
          borderRadius: "4px",
          overflow: "hidden",
          display: "inline-block",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          flexGrow: 1,
          maxWidth: 135,
          cursor:
            !disabled && missionControlMode === "edit" ? "pointer" : undefined,
          "&:hover": {
            background:
              !disabled && missionControlMode === "edit"
                ? alpha("#9e9e9e", 0.2)
                : undefined,
          },
          "&:active": {
            background:
              !disabled && missionControlMode === "edit"
                ? alpha("#9e9e9e", 0.3)
                : undefined,
          },
        }}
      >
        {sectionRoleIDs?.length > 1 ? "Multiple Roles" : role?.name}
      </Typography>
      {!disabled && missionControlMode === "edit" && open ? (
        <Menu
          variant="outlined"
          size="sm"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ zIndex: 2000 }}
        >
          {sectionRoles.map((sectionRole) => (
            <MenuItem
              key={sectionRole.id}
              onClick={() => handleChange(sectionRole.id)}
            >
              {sectionRole.name}
            </MenuItem>
          ))}
        </Menu>
      ) : (
        []
      )}
    </>
  );
}
