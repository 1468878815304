import { Box, Button } from "@mui/joy";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import DialogClose from "atoms/DialogClose/DialogClose";
import { paydayMap, scaleTypeMap } from "constants/payday";
import { typeOptions } from "constants/workSessionTypeOptions";
import { Scale, Scale_Entity } from "entities/scale";
import { currencyFormatter, getterDivider, setterDivider } from "helpers";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  selectedTagIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useInstruments } from "redux/instrument/instrumentHooks";
import { useUpdateScaleMutation } from "redux/scale/scaleEndpoints";
import { useProjectScales, useTagScales } from "redux/scale/scaleHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";

/**
 *
 * @returns {ReactElement} ScalesForm page
 */
export function ScalesForm() {
  const open = useSelector(formOpenSelector("scales"));
  const dispatch = useDispatch();
  const { instrumentsMap } = useInstruments();
  const tagID = useSelector(selectedTagIDSelector);
  const projectID = useSelector(selectedProjectIDSelector);
  const { scales: projectScales } = useProjectScales(projectID);
  const { scales: tagScales } = useTagScales(tagID);

  let scales: Scale[];
  if (projectID) scales = projectScales;
  if (tagID) scales = tagScales;
  const onClose = () => {
    dispatch(setFormOpen({ formID: "scales", isOpen: false }));
  };
  const { sectionRolesMap } = useSectionRoles();

  const [updateScale] = useUpdateScaleMutation();

  const onScaleUpdate = async (newScale: Scale_Entity) => {
    return new Promise<Scale_Entity>((resolve) => {
      updateScale({ id: newScale.id, body: newScale });
      resolve(newScale);
    });
  };

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle>
        {tagID ? "Season" : ""}
        {projectID ? "Project" : ""} Scales
      </DialogTitle>
      <DialogContent>
        <DataGridPremium
          sx={{ height: "75vh" }}
          experimentalFeatures={{ clipboardPaste: true }}
          unstable_cellSelection
          slots={{ toolbar: QuickSearchToolbar }}
          density="compact"
          hideFooter
          getCellClassName={(p) => {
            let classes = "";
            if (!p.isEditable && p.colDef.type !== "actions")
              classes += "cellReadOnly ";
            return classes;
          }}
          processRowUpdate={onScaleUpdate}
          columns={[
            {
              field: "itemKey",
              headerName: "Name",
              editable: false,
              width: 150,
              valueGetter: (p) => scaleTypeMap[p.value] ?? p.value,
            },
            {
              field: "itemID",
              headerName: "Item ID",
              editable: false,
              width: 200,
              valueGetter: (p) => {
                switch (p.api.getRow(p.id)?.itemKey) {
                  case "service":
                    return (
                      typeOptions.find((e) => e.sessionTypeID === p.value)
                        ?.title ?? p.value
                    );
                  case "sectionRole":
                    if (p.value === 50) return "Contractor";
                    return sectionRolesMap[p.value]?.name ?? p.value;
                  case "payday":
                    return paydayMap[p.value] ?? p.value;
                  case "muse":
                    return instrumentsMap[p.value]?.name;
                  case "doubling":
                    return `${p.value} Instruments`;
                  default:
                    return p.value;
                }
              },
            },
            {
              field: "amount",
              headerName: "Amount",
              editable: true,
              valueGetter: getterDivider("amount", 1000),
              valueSetter: setterDivider("amount", 1000),
              valueFormatter: (p) =>
                p.api.getRow(p.id).percentage
                  ? `${p.value.toFixed(2)}%`
                  : `${currencyFormatter.format(p.value)}`,
            },
          ]}
          rows={scales}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        fullWidth
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
      />
    </Box>
  );
}
