import { Customer } from "entities/customer";
import { useMemo } from "react";
import { useGetCustomerQuery, useGetCustomersQuery } from "./customerEndpoints";

export function useCustomer(customerID: number | undefined) {
  const customerQuery = useGetCustomerQuery(customerID ?? 0, {
    skip: (customerID ?? 0) <= 0,
  });
  const customerEntity = customerQuery.data;

  return useMemo(() => {
    const ret: typeof customerQuery & { customer?: Customer | undefined } = {
      ...customerQuery,
    };
    if (customerEntity) ret.customer = new Customer(customerEntity);
    return ret;
  }, [customerEntity]);
}

export function useCustomers() {
  const customerQuery = useGetCustomersQuery();
  const customerEntitiesMap = customerQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof customerQuery & {
      customers?: Customer[];
      customersMap?: { [id: number]: Customer };
    } = {
      ...customerQuery,
      customers: [],
      customersMap: {},
    };
    if (customerEntitiesMap) {
      const customers = [];
      const customersMap = {};

      for (const key in customerEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(customerEntitiesMap, key)) {
          const c = customerEntitiesMap[key];
          const customer = new Customer(c);
          customers.push(customer);
          customersMap[customer.id] = customer;
        }
      }
      customers.sort((a, b) => a.name.localeCompare(b.name));
      ret.customers = customers;
      ret.customersMap = customersMap;
    }

    return ret;
  }, [customerEntitiesMap]);
}
