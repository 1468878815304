import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { MusicianGroup_Entity } from "entities/musicianGroup";
import { rhapsodyApi } from "../api/rhapsodyApi";

const musicianGroupsAdapter = createEntityAdapter<MusicianGroup_Entity>();
const initialState = musicianGroupsAdapter.getInitialState();

export const musicianGroupEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getMusicianGroups: build.query<EntityState<MusicianGroup_Entity>, void>({
      query: () => `musicianGroups`,
      transformResponse: (responseData: MusicianGroup_Entity[]) => {
        return musicianGroupsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["musicianGroups"],
    }),
    getMusicianGroup: build.query<MusicianGroup_Entity, number>({
      query: (id) => `musicianGroups/${id}`,
      providesTags: (result, error, id) => [{ type: "musicianGroups", id }],
    }),
    createMusicianGroup: build.mutation<
      MusicianGroup_Entity,
      Partial<MusicianGroup_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `musicianGroups`,
      }),
      invalidatesTags: (a, e, b) => [
        "musicianGroups",
        "groups",
        { type: "musicians", id: b.musicianID },
      ],
    }),
    createMusicianGroup2: build.mutation<
      MusicianGroup_Entity,
      Partial<MusicianGroup_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `musicianGroups`,
      }),
    }),
    updateMusicianGroup: build.mutation<
      void,
      { id: number; body: Partial<MusicianGroup_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `musicianGroups/${args.id}`,
      }),
      invalidatesTags: ["musicianGroups", "groups"],
    }),
    deleteMusicianGroup: build.mutation<
      void,
      { musicianID: number; id: number }
    >({
      query: (body) => ({
        method: "DELETE",
        url: `musicianGroups/${body.id}`,
      }),
      invalidatesTags: (a, e, b) => [
        "musicianGroups",
        "groups",
        { type: "musicians", id: b.musicianID },
      ],
    }),
  }),
});

export const {
  useGetMusicianGroupQuery,
  useGetMusicianGroupsQuery,
  useCreateMusicianGroup2Mutation,
  useCreateMusicianGroupMutation,
  useDeleteMusicianGroupMutation,
  useUpdateMusicianGroupMutation,
} = musicianGroupEndpoints;

export default musicianGroupEndpoints;
