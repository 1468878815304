import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const mercuryApi = createApi({
  reducerPath: "mercuryApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "mercury" }),
  endpoints: () => ({}),
  tagTypes: ["stages", "pipelines", "inspectors"],
});
