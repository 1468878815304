import { useMediaQuery, useTheme } from "@mui/material";

import SaveIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogClose from "atoms/DialogClose/DialogClose";
// material-ui
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedTagIDSelector,
  setSelectedTagID,
} from "reducers/rhapsody";
// styles
import FileUpload from "atoms/FileUpload";
import { setFormOpen } from "reducers/rhapsody";
import {
  useCreateTagMutation,
  useDeleteTagMutation,
  useUpdateTagMutation,
} from "redux/tag/tagEndpoints";
import { useTag } from "redux/tag/tagHooks";
import { Box, Button, Chip, Input, Typography } from "@mui/joy";
import Upload from "atoms/FileUpload/FileUpload";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";

export default function FormTag() {
  const tagID = useSelector(selectedTagIDSelector);
  const { tag: selectedTag } = useTag(tagID);
  const [createTag] = useCreateTagMutation();
  const [updateTag] = useUpdateTagMutation();
  const [deleteTag] = useDeleteTagMutation();

  const formOpen = useSelector(formOpenSelector("tag"));
  const [tag, setTag] = useState({
    name: "",
    image: "",
  });
  const askQuestion = useAskQuestion();

  useEffect(() => {
    if (selectedTag?.id) {
      setTag(selectedTag);
    }
  }, [selectedTag]);

  const dispatch = useDispatch();

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setTag((s) => ({ ...s, [name]: value }));
  };

  const save = async () => {
    if (selectedTag?.id) {
      updateTag({ id: selectedTag.id, body: tag });
    } else {
      createTag(tag);
    }
    onClose();
  };

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "tag" }));
    dispatch(setSelectedTagID());
  };

  const askDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>
          You are about to delete a season, associated projects won't be
          affected. Please confirm.
        </Typography>
      ),
    }).then((i) => {
      if (i == 1) confirmDelete();
    });
  };

  const confirmDelete = async () => {
    deleteTag(tag.id);

    onClose();
  };

  return (
    <DialogClose
      open={formOpen}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <div
          style={{
            background: "#e3f2fd",
            padding: 16,
            borderRadius: "16px",
            textAlign: "center",
          }}
        >
          <i
            style={{ fontSize: 80, color: "#001E3C" }}
            class="fa-solid fa-ticket"
          ></i>
          <Typography style={{ color: "#001E3C" }} level="h6">
            {selectedTag ? "Edit" : "New"} Season
          </Typography>
          <Typography style={{ color: "#001E3C", opacity: 0.8 }} level="body2">
            A season is a group of projects.
          </Typography>
        </div>
        <Grid
          container
          spacing={2}
          style={{ marginTop: 8 }}
          justifyContent="center"
        >
          <Grid item>
            <Box sx={{ display: "flex", gap: 1 }}>
              {tag.image ? (
                <img
                  src={tag.image}
                  style={{ width: 95, height: 95, borderRadius: "8px" }}
                />
              ) : (
                <Upload
                  onUpload={(f) => setTag((t) => ({ ...t, image: f }))}
                  cropDimension={{ width: 250, height: 250 }}
                >
                  <Box
                    sx={{
                      width: 95,
                      height: 95,
                      flexShrink: 0,
                      border: "dashed 1.5px #9e9e9e",
                      borderRadius: "8px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography level="h4" sx={{ color: "#9e9e9e" }}>
                      <i className="fa-duotone fa-image"></i>
                    </Typography>
                  </Box>
                </Upload>
              )}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography
                  level="body2"
                  sx={{ color: "black", fontWeight: 600 }}
                >
                  Season Cover
                </Typography>
                <Typography level="body3">
                  An artwork respresenting your Season. It must be at least
                  250px by 250px with a max size of 512KB. The supported MIME
                  types are JPG, PNG, and GIf.
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Upload
                    cropDimension={{ width: 250, height: 250 }}
                    onUpload={(f) => setTag((t) => ({ ...t, image: f }))}
                  />
                  {tag.image ? (
                    <Chip
                      onClick={() => setTag((t) => ({ ...t, image: "" }))}
                      size="sm"
                      variant="outlined"
                      color="danger"
                    >
                      Remove Cover
                    </Chip>
                  ) : (
                    []
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Input
              autoFocus
              startDecorator="Name:"
              value={tag.name}
              onChange={handleChange("name")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {tag.id ? (
          <Button color="danger" variant="soft" onClick={askDelete}>
            Delete
          </Button>
        ) : (
          []
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Button color="neutral" variant="soft" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={tag.name.length === 0} onClick={save}>
          {selectedTag ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
