import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { ProjectTag_Entity } from "entities/projectTag";
import { rhapsodyApi } from "../api/rhapsodyApi";

const projectTagsAdapter = createEntityAdapter<ProjectTag_Entity>();
const initialState = projectTagsAdapter.getInitialState();

export const projectTagEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectTags: build.query<EntityState<ProjectTag_Entity>, void>({
      query: () => `projectTags`,
      transformResponse: (responseData: ProjectTag_Entity[]) => {
        return projectTagsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["projectTags"],
    }),
    getProjectTag: build.query<ProjectTag_Entity, number>({
      query: (id) => `projectTags/${id}`,
      providesTags: (result, error, id) => [{ type: "projectTags", id }],
    }),
    createProjectTag: build.mutation<
      ProjectTag_Entity,
      Partial<ProjectTag_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `projectTags`,
      }),
      invalidatesTags: ["projectTags", "projects"],
    }),
    updateProjectTag: build.mutation<
      void,
      { id: number; body: Partial<ProjectTag_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projectTags/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "projectTags", id },
        "projectTags",
      ],
    }),
    deleteProjectTag: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projectTags/${id}`,
      }),
      invalidatesTags: ["projectTags", "projects"],
    }),
  }),
});

export const {
  useGetProjectTagQuery,
  useGetProjectTagsQuery,
  useCreateProjectTagMutation,
  useDeleteProjectTagMutation,
  useUpdateProjectTagMutation,
} = projectTagEndpoints;

export default projectTagEndpoints;
