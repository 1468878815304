import { Chair } from "entities/chair";
import ProjectAlternate_Entity from "./projectAlternate";
export default class ProjectAlternate
  extends ProjectAlternate_Entity
  implements Chair
{
  static fromList(projectAlternatesJSON: unknown): Array<ProjectAlternate> {
    const projectAlternates: ProjectAlternate[] = [];
    if (projectAlternatesJSON)
      Array.isArray(projectAlternatesJSON) &&
        projectAlternatesJSON.forEach((projectAlternateJSON) => {
          projectAlternates.push(new ProjectAlternate(projectAlternateJSON));
        });
    return projectAlternates;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  order(): number {
    return this.seatingRank ?? 1;
  }

  color(): string {
    if (this.prime) return "#D7B740";
    return "#607D8B";
  }
}
