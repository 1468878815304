import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedWorkSessionIDSelector, setFormOpen } from "reducers/rhapsody";
import { useWorkSessionActiveJobs } from "redux/job/jobHooks";
import { useWorkSessionMusicians } from "redux/musician/musicianHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { useWorkSession } from "redux/workSession/workSessionHooks";
import {
  ExportColDef,
  createWorkbook,
  downloadExcel,
  setColumns,
} from "../utils";

export function useDownloadWorkSessionStewardExcel() {
  const [trigger, setTrigger] = useState(false);
  const [workSessionID, setWorkSessionID] = useState<number>();
  const selectedWorkSessionID = useSelector(selectedWorkSessionIDSelector);
  const { workSession, isSuccess: s1 } = useWorkSession(
    workSessionID ?? selectedWorkSessionID,
    { skip: !trigger }
  );
  const { jobs, isSuccess: s2 } = useWorkSessionActiveJobs(
    workSessionID ?? selectedWorkSessionID
  );
  const { musiciansMap, isSuccess: s3 } = useWorkSessionMusicians(
    workSessionID ?? selectedWorkSessionID
  );
  const { sectionRoles, isSuccess: s4 } = useSectionRoles();

  const dispatch = useDispatch();

  useEffect(() => {
    if (trigger && s1 && s2 && s3 && s4) {
      setTrigger(false);
      download();
    }
  }, [trigger, s1, s2, s3, s4]);

  function download() {
    const workbook = createWorkbook();
    const fileName = `${workSession.title} - Steward Report`;

    const exportColDef: ExportColDef[] = [
      {
        field: "sectionRoleID",
        headerName: "Position",
        width: 20,
        center: true,
        valueGetter: (v) => sectionRoles.find((s) => s.id === v)?.name ?? "",
      },
      {
        field: "instrument",
        width: 25,
        center: true,
        headerName: "Instrument",
        valueGetter: (v) => v?.name ?? "",
      },
      {
        field: "musicianID",
        width: 25,
        center: true,
        headerName: "Employee's Name",
        valueGetter: (v) => {
          if (v) {
            const musician = musiciansMap[v];
            return musician?.fullName();
          }
          return "";
        },
      },
      {
        field: "musicianID",
        width: 25,
        center: true,
        headerName: "SSN",
        valueGetter: (v) => {
          if (v) {
            const musician = musiciansMap[v];
            return musician?.ssn;
          }
          return "";
        },
      },
      {
        field: "musicianID",
        width: 25,
        headerName: "Home Address",
        center: true,
        valueGetter: (v) => {
          if (v) {
            const musician = musiciansMap[v];
            return `${musician?.address}\n${musician?.city} ${musician?.state} ${musician?.zipcode}`;
          }
          return "";
        },
      },
      {
        field: "rehearsalScale",
        width: 15,
        headerName: "Reh Wages",
        dollar: true,
        center: true,
        total: true,
        valueGetter: (v) => (workSession.rehearsalScale ?? 0) / 100,
      },
      {
        field: "openRehWages",
        width: 15,
        headerName: "Open Reh Wages",
        dollar: true,
        center: true,
        total: true,
        valueGetter: (v) => (v ?? 0) / 100,
      },
      {
        field: "performanceScale",
        width: 15,
        headerName: "Perf Wages",
        dollar: true,
        center: true,
        total: true,
        valueGetter: (v) => (workSession.performanceScale ?? 0) / 100,
      },
      {
        field: "premiumPercentage",
        width: 15,
        center: true,
        percentage: true,
        headerName: "Premium Percentage",
        valueGetter: (v) => (v ?? 0) / 100,
      },
      {
        field: "premiumWage",
        width: 15,
        center: true,
        headerName: "Premium Wage",
        dollar: true,
        total: true,
        formulaGetter: (_i) =>
          `(${_i.relativeCellID(-4, 0)} + ${_i.relativeCellID(
            -3,
            0
          )} + ${_i.relativeCellID(-2, 0)}) * ${_i.relativeCellID(-1, 0)}`,
      },
      {
        field: "doubles",
        width: 10,
        center: true,
        dollar: true,
        total: true,
        headerName: "DBL",
        valueGetter: (v) => v,
      },
      {
        field: "overtime",
        width: 15,
        center: true,
        dollar: true,
        total: true,
        headerName: "Overtime",
        valueGetter: (v) => (v ?? 0) / 100,
      },
      {
        field: "otherPensionnable",
        width: 20,
        center: true,
        dollar: true,
        total: true,
        headerName: "Additional Pensionable Wage",
        valueGetter: (v) => (v ?? 0) / 100,
      },
      {
        field: "totalScaleWages",
        width: 15,
        center: true,
        dollar: true,
        total: true,
        headerName: "Total Scale Wages",
        formulaGetter: (_i) =>
          `${_i.relativeCellID(-8, 0)} + ${_i.relativeCellID(
            -7,
            0
          )}+${_i.relativeCellID(-6, 0)}+${_i.relativeCellID(
            -4,
            0
          )}+${_i.relativeCellID(-3, 0)}+${_i.relativeCellID(
            -2,
            0
          )}+${_i.relativeCellID(-1, 0)}`,
      },
      {
        field: "cartage",
        width: 10,
        center: true,
        dollar: true,
        total: true,
        headerName: "Cartage",
        valueGetter: (v) => (v ?? 0) / 100,
      },
      {
        field: "librarian",
        width: 15,
        center: true,
        dollar: true,
        total: true,
        headerName: "Librarian",
        valueGetter: (v) => (v ?? 0) / 100,
      },
      {
        field: "overscale",
        width: 15,
        center: true,
        dollar: true,
        headerName: "Overscale",
        valueGetter: (v) => (v ?? 0) / 100,
      },
      {
        field: "totalCheck",
        width: 20,
        center: true,
        dollar: true,
        total: true,
        headerName: "Total Check (minus WDs)",
        formulaGetter: (_i) =>
          `(${_i.relativeCellID(-5, 0)}+${_i.relativeCellID(
            -4,
            0
          )}+${_i.relativeCellID(-3, 0)}+${_i.relativeCellID(
            -2,
            0
          )})-${_i.relativeCellID(1, 0)}`,
      },
      {
        field: "workDues",
        width: 15,
        center: true,
        dollar: true,
        total: true,
        headerName: "Work Dues",
        formulaGetter: (_i) =>
          `${_i.relativeCellID(-5, 0)}*${
            workSession.workDuesPercent
              ? workSession.workDuesPercent / 100
              : 100
          }%`,
      },
      {
        field: "pension",
        width: 15,
        center: true,
        dollar: true,
        total: true,
        headerName: "Pension",
        formulaGetter: (_i) =>
          `${_i.relativeCellID(-6, 0)}*${
            workSession.pensionPercentage
              ? workSession.pensionPercentage / 100
              : 100
          }%`,
      },
      {
        field: "welfareValue",
        width: 15,
        center: true,
        dollar: true,
        total: true,
        headerName: "H&W",
        valueGetter: !workSession.welfarePercent
          ? (v) => (v ?? 0) / 100
          : undefined,
        formulaGetter: workSession.welfarePercent
          ? (_i) =>
              `${_i.relativeCellID(-7, 0)}*${
                workSession.welfareValue ? workSession.welfareValue / 100 : 100
              }%`
          : undefined,
      },
    ];
    const worksheet = workbook.addWorksheet(`Page 1`, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    worksheet.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };

    jobs.sort((a, b) => {
      const musicianA = musiciansMap[a.musicianID];
      const musicianB = musiciansMap[b.musicianID];

      return musicianA?.lastName?.localeCompare(musicianB?.lastName);
    });

    setColumns(exportColDef, jobs, worksheet);
    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
    downloadExcel(workbook, fileName);
  }

  return (_workSessionID?: number) => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setWorkSessionID(_workSessionID);
    setTrigger(true);
  };
}

export const reportTitles = {
  G: "REPORT FORM",
  LV: "LIVE REPORT FORM",
  3: "REPORT FORM\nMADE AND PLAYED LOCAL COMMERCIAL ANNOUNCEMENTS\nContinuation Sheet",
  4: "REPORT FORM\nPHONOGRAPH RECORDS, SOUNDTRACK RELEASES, VIDEO PROMOS\nContinuation Sheet",
  5: "REPORT FORM\nFOR DEMONSTRATION RECORDING - AUDIO ONLY\nContinuation Sheet",
  6: "REPORT FORM\nTELEVISION AND RADIO COMMERCIAL ANNOUNCEMENTS\nContinuation Sheet",
  7: "REPORT FORM\nMOTION PICTURES -THEATRICAL & TELEVISION FILM (STANDARD, NON-STANDARD & BASIC CABLE),\nINDUSTRIAL (NON-THEATRICAL-NON-TV), MISCELLANEOUS, LOW BUDGET FILMS\nContinuation Sheet",
  8: "REPORT FORM\nFOR ALL VIDEOTAPE/LIVE TELEVISION/CABLE TV/PUBLIC TV\nContinuation Sheet",
  9: "LIMITED PRESSING RECORDING REPORT FORM\nContinuation Sheet",
  10: "REPORT FORM\nFOR COMMERCIAL (SYNDICATED), PUBLIC AND LOCAL RADIO\nAND NON-COMMERCIAL I.D.\nContinuation Sheet",
  11: "REPORT FORM\nFOR SYMPHONY, OPERA, BALLET AUDIO-VISUAL AGREEMENT\nContinuation Sheet",
  12: "RADIO TO NON-COMMERCIAL RECORDING REPORT FORM\nFOR SYMPHONIC USE ONLY\nContinuation Sheet",
};

export const footerSentence = {
  3: "(1) Insert X if wages being paid are overscale",
  4: "Include all music prep. info on this form or continuation sheet, with copies of invoices attached.",
  5: "(1) Insert overscale wages being paid.\nInclude all music prep. info on this form or continuation sheet, with copies of invoices attached.",
  6: "(1) Insert X if wages being paid are overscale.",
  7: "(1) Insert overscale wages being paid.\nInclude all music Prep, information on this form or continuation sheet, with copies of invoices attached.",
  8: "(1) Insert X if wages being paid are overscale.\nInclude all Music Preparation on this form along with attached copies of invoices.",
  9: "(1) Insert overscale wages being paid.\nInclude all music prep. info on this form or continuation sheet, with copies of invoices attached.",
  10: "(1) Insert overscale wages being paid.\nInclude all music prep. information on this form or a continuation sheet, with copies of invoices attached.",
  11: "Include all music prep. information on this form or a continuation sheet, with copies of invoices attached.",
  12: "Distribution of the 6 copies of this report form is as follows:\n1. Original page is to be sent to, AFM-EP Fund, 304 East 44th St., New York, NY 10017\nwith Pension contribution check made payable to the AFM-EP Fund\n2. One copy is to be retained by the Signatory of Records\n3. The remaining 4 copies are to be sent to the applicable AFM Local with the musicians’ checksThe AFM Local will:\n· retain one copy\n· send one copy to the American Federation of Musicians, 1501 Broadway, Suite 500, New York, NY 10036\n· send one copy to the Leader\n· send one copy to Health & Welfare Fund (where applicable)\n· send one photo copy to the Orchestra Committee Chairperson",
};

export const linesPerForm = {
  G: 25,
  LV: 25,
  3: 34,
  4: 21,
  5: 22,
  6: 32,
  7: 19,
  8: 26,
  9: 22,
  10: 22,
  11: 23,
  12: 17,
};
