import { ProjectSection } from "entities/projectSection";
import { useMemo } from "react";
import {
  useGetProjectSectionQuery,
  useGetProjectSectionsQuery,
} from "./projectSectionEndpoints";

export function useProjectSection(projectSectionID: number | undefined) {
  const projectSectionQuery = useGetProjectSectionQuery(projectSectionID ?? 0, {
    skip: (projectSectionID ?? 0) <= 0,
  });
  const projectSectionEntity = projectSectionQuery.data;

  return useMemo(() => {
    const ret: typeof projectSectionQuery & {
      projectSection?: ProjectSection | undefined;
    } = {
      ...projectSectionQuery,
    };
    if (projectSectionEntity)
      ret.projectSection = new ProjectSection(projectSectionEntity);
    return ret;
  }, [projectSectionEntity]);
}

export function useProjectSections(projectID) {
  const projectSectionQuery = useGetProjectSectionsQuery(projectID, {
    skip: (projectID ?? 0) <= 0,
  });
  const projectSectionEntitiesMap = projectSectionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof projectSectionQuery & {
      projectSections?: ProjectSection[];
      projectSectionsMap?: { [id: number]: ProjectSection };
    } = {
      ...projectSectionQuery,
      projectSections: [],
      projectSectionsMap: {},
    };
    if (projectSectionEntitiesMap) {
      const projectSections = [];
      const projectSectionsMap = {};

      for (const key in projectSectionEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(projectSectionEntitiesMap, key)
        ) {
          const c = projectSectionEntitiesMap[key];
          const projectSection = new ProjectSection(c);
          projectSections.push(projectSection);
          projectSectionsMap[projectSection.id] = projectSection;
        }
      }
      ret.projectSections = projectSections;
      ret.projectSectionsMap = projectSectionsMap;
    }

    return ret;
  }, [projectSectionEntitiesMap]);
}
