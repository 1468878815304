import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  ChipDelete,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Grid } from "@mui/material";
import { Dictionary, nanoid } from "@reduxjs/toolkit";
import { Piece } from "entities/piece";
import { Project } from "entities/project";
import { ProjectPiece } from "entities/projectPiece";
import { Section } from "entities/section";
import { SectionRole } from "entities/sectionRole";
import { useEffect, useState } from "react";
import { useMusicians } from "redux/musician/musicianHooks";
import { PickMusicians } from "./pickMusicians";
import { PickTemplates } from "./pickTemplate";

import { SubRule_Entity } from "entities/subRule";
import { mapToArray } from "helpers";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useSelector } from "react-redux";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useTemplates } from "redux/project/projectHooks";
import {
  useDeleteSubRuleMutation,
  useUpdateSubRuleMutation,
} from "redux/subRule/subRuleEndpoints";
import { useSubRule } from "redux/subRule/subRuleHooks";
import { parser, serializer } from "./subs";

/**
 *
 * @returns {ReactElement} SubRule page
 */
export function SubRuleCard({
  sectionRoles,
  sections,
  projectPieces,
  piecesMap,
  templatesMap,
  subRuleID,
  defaultEdit,
  order,
}: {
  sectionRoles: SectionRole[];
  sections: Section[];
  projectPieces: ProjectPiece[];
  piecesMap: Dictionary<Piece>;
  templatesMap: Dictionary<Project>;
  subRuleID: number;
  defaultEdit: boolean;
  order: number;
}) {
  const readOnly = useReadOnly();
  const { subRule: _subRule, isLoading } = useSubRule(subRuleID);
  const [subRule, setSubRule] =
    useState<Partial<SubRule_Entity & { key: string }>>(_subRule);
  const [edit, setEdit] = useState(defaultEdit);
  const [targetValues, setTargetValues] = useState<number[] | null>();
  const [replaceValues, setReplaceValues] = useState<number[] | null>();
  const [templateValues, setTemplateValues] = useState<number[] | null>();
  const [deleteSubRule] = useDeleteSubRuleMutation();
  const [updateSubRule] = useUpdateSubRuleMutation();
  const targetIDs = parser(subRule?.targetIDs) ?? [];
  const replaceIDs = parser(subRule?.replaceIDs) ?? [];

  const musicianIDs = [...targetIDs, ...replaceIDs];

  const { musiciansMap } = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.id",
          comparison: "in",
          value: musicianIDs,
        },
      ]),
    },
    { skip: musicianIDs?.length === 0 }
  );

  useEffect(() => {
    setSubRule(_subRule);
  }, [_subRule]);

  useEffect(() => {
    if (subRule && subRule.key) {
      save();
    }
  }, [subRule]);

  if (isLoading || !_subRule || !_subRule.id || !subRule) return <Skeleton />;

  const save = () => {
    if (!subRule) return;
    const body = { ...subRule };

    // Pick Musicians Open
    if (targetIDs.filter((i) => i === -1 || i === -2).length > 0) return;
    if (replaceIDs.filter((i) => i === -1 || i === -2).length > 0) return;

    // Pick Template open
    if (replaceIDs[0] === -3) return;

    body.targetIDs = serializer(parser(subRule.targetIDs).filter((s) => s > 0));
    body.replaceIDs = serializer(
      parser(subRule.replaceIDs).filter((s) => s > 0)
    );
    updateSubRule({ id: subRule.id, body });
  };

  const ruleChunks = [
    {
      key: "targetIDs",
      prefix: "If",
      prefixPlural: "If",
      data: [
        { id: 0, label: "anyone" },
        { id: -1, label: "this musician..." },
        { id: -2, label: "these musicians..." },
      ],
      hiddable: false,
      multiple: false,
      callback: (e: number[]) => console.log(e),
      render: (c) => {
        if (targetIDs[0] > 0) {
          return (
            <Tooltip
              arrow
              size="sm"
              title={
                <Box>
                  {targetIDs.map((id, i) => (
                    <Box key={id}>
                      {i + 1}. {musiciansMap[id]?.fullName()}
                    </Box>
                  ))}
                </Box>
              }
            >
              <Chip
                endDecorator={
                  edit ? (
                    <ChipDelete
                      onClick={() => {
                        setSubRule((s) => ({
                          ...s,
                          targetIDs: "[]",
                          key: nanoid(),
                        }));
                      }}
                    />
                  ) : undefined
                }
                onClick={edit ? () => setTargetValues(targetIDs) : undefined}
                size="sm"
                color="neutral"
                variant="solid"
              >
                {targetIDs
                  ?.map((id) => musiciansMap[id]?.fullName())
                  .join(", ")}
              </Chip>
            </Tooltip>
          );
        }
        return c;
      },
    },
    {
      key: "sectionIDs",
      prefix: "in section",
      prefixPlural: "in sections",
      data: sections.reduce(
        (a, v) => {
          a.push({ id: v.id, label: v.name });
          return a;
        },
        [{ id: 0, label: "any" }]
      ),
      hiddable: true,
      multiple: true,
      callback: (e: number[]) => console.log(e),
      render: (c) => {
        return c;
      },
    },
    {
      key: "sectionRoleIDs",
      prefix: "with role",
      prefixPlural: "with roles",
      data: sectionRoles.reduce(
        (a, v) => {
          a.push({ id: v.id, label: v.name });
          return a;
        },
        [{ id: 0, label: "any" }]
      ),
      hiddable: true,
      multiple: true,
      callback: (e: number[]) => console.log(e),
      render: (c) => {
        return c;
      },
    },
    {
      key: "orders",
      prefix: "with section order",
      prefixPlural: "with section orders",
      data: getOrderData(),
      hiddable: true,
      multiple: true,
      callback: (e: number[]) => console.log(e),
      render: (c) => {
        return c;
      },
    },
    {
      key: "replaceIDs",
      prefix: `decline${targetIDs.length === 1 ? "s" : ""} THEN suggest`,
      prefixPlural: `decline${targetIDs.length === 1 ? "s" : ""}  THEN suggest`,
      data: [
        { id: 0, label: "nobody" },
        { id: -1, label: "this musician..." },
        { id: -2, label: "these musicians..." },
        { id: -3, label: "next in line on this template..." },
      ],
      hiddable: false,
      multiple: false,
      callback: (e: number[]) => console.log(e),
      render: (c) => {
        if (subRule.templateID) {
          return (
            <Chip
              endDecorator={
                edit ? (
                  <ChipDelete
                    onClick={() => {
                      setSubRule((s) => ({
                        ...s,
                        replaceIDs: "[]",
                        templateID: undefined,
                        key: nanoid(),
                      }));
                    }}
                  />
                ) : undefined
              }
              onClick={
                edit ? () => setTemplateValues([subRule.templateID]) : undefined
              }
              size="sm"
              color="neutral"
              variant="solid"
            >
              next in Template {templatesMap[subRule.templateID]?.name}
            </Chip>
          );
        }
        if (replaceIDs[0] > 0) {
          return (
            <Tooltip
              arrow
              size="sm"
              title={
                <Box>
                  {replaceIDs.map((id, i) => (
                    <Box key={id}>
                      {i + 1}. {musiciansMap[id]?.fullName()}
                    </Box>
                  ))}
                </Box>
              }
            >
              <Chip
                sx={{ maxWidth: 400 }}
                endDecorator={
                  edit ? (
                    <ChipDelete
                      onClick={() => {
                        setSubRule((s) => ({
                          ...s,
                          replaceIDs: "[]",
                          key: nanoid(),
                        }));
                      }}
                    />
                  ) : undefined
                }
                onClick={edit ? () => setReplaceValues(replaceIDs) : undefined}
                size="sm"
                color="neutral"
                variant="solid"
              >
                {replaceIDs
                  ?.map((id) => musiciansMap[id]?.fullName())
                  .join(", ")}
              </Chip>
            </Tooltip>
          );
        }
        return c;
      },
    },
    {
      key: "projectPieceIDs",
      prefix: "for project piece",
      prefixPlural: "for project pieces",
      data: projectPieces.reduce(
        (a, v) => {
          a.push({
            id: v.id,
            label: `${piecesMap[v.pieceID]?.name ?? "Seating assignment"} ${
              piecesMap[v.pieceID] ? `, ${piecesMap[v.pieceID]?.composer}` : ""
            }`,
          });
          return a;
        },
        [{ id: 0, label: "any" }]
      ),
      hiddable: true,
      multiple: true,
      callback: (e: number[]) => console.log(e),
      render: (c) => {
        if (subRule[0] > 0) {
          return <Chip>Hello World</Chip>;
        }
        return c;
      },
    },
  ];

  return (
    // <ClickAwayListener onClickAway={save}>
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Avatar size="sm">{_subRule.position}</Avatar>
        <Divider orientation="vertical" />
        <Grid container spacing={1} alignItems="center">
          {ruleChunks.map((c) => {
            const values = parser(subRule[c.key]);
            if (values?.length === 0 && c.hiddable && !edit) return <Box />;
            return (
              <Grid
                item
                key={c.key}
                sx={{ display: "flex", gap: 1, alignItems: "center" }}
              >
                <Typography level="body2">
                  {values.length > 1 ? c.prefixPlural : c.prefix}
                </Typography>
                {c.render(
                  <Dropdown
                    disabled={!edit}
                    data={c.data}
                    values={parser(subRule[c.key])}
                    onChange={(e) => {
                      c.callback(e);
                      setSubRule((s) => ({
                        ...s,
                        [c.key]: serializer(e),
                        key: nanoid(),
                      }));
                    }}
                    multiple={c.multiple}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
        <Divider orientation="vertical" />
        {!edit ? (
          <IconButton
            onClick={() => setEdit((e) => !e)}
            size="sm"
            variant="plain"
            color="neutral"
            disabled={readOnly}
          >
            <i className="fa-solid fa-pen"></i>
          </IconButton>
        ) : (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <IconButton
              onClick={() => deleteSubRule(subRule.id)}
              size="sm"
              variant="plain"
              color="neutral"
            >
              <i className="fa-solid fa-trash"></i>
            </IconButton>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                setEdit((e) => !e);
                save();
              }}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
      {targetValues ||
      targetIDs.filter((i) => i === -1 || i === -2).length > 0 ? (
        <PickMusicians
          open
          values={targetValues}
          multiple={targetIDs.includes(-2) || targetValues?.length > 1}
          onChange={(e) => {
            setTargetValues(null);
            setSubRule((s) => ({
              ...s,
              targetIDs: serializer(e),
              key: nanoid(),
            }));
          }}
          onDismiss={() => {
            if (targetValues) return setTargetValues(null);
            setSubRule((s) => ({
              ...s,
              targetIDs: serializer([0]),
              key: nanoid(),
            }));
          }}
        />
      ) : (
        []
      )}
      {replaceValues ||
      replaceIDs.filter((i) => i === -1 || i === -2).length > 0 ? (
        <PickMusicians
          open
          values={replaceValues}
          multiple={replaceIDs.includes(-2) || replaceValues?.length > 1}
          onChange={(e) => {
            setReplaceValues(null);
            setSubRule((s) => ({
              ...s,
              replaceIDs: serializer(e),
              key: nanoid(),
            }));
          }}
          onDismiss={() => {
            if (replaceValues) return setReplaceValues(null);
            setSubRule((s) => ({
              ...s,
              replaceIDs: serializer([0]),
              key: nanoid(),
            }));
          }}
        />
      ) : (
        []
      )}
      {templateValues || (replaceIDs[0] === -3 && !subRule.templateID) ? (
        <PickTemplates
          open
          values={templateValues}
          onChange={(e) => {
            setTemplateValues(null);
            setSubRule((s) => ({
              ...s,
              replaceIDs: "[]",
              templateID: e[0],
              key: nanoid(),
            }));
          }}
          onDismiss={() => {
            if (templateValues) return setTemplateValues(null);
            setSubRule((s) => ({
              ...s,
              replaceIDs: serializer([0]),
              key: nanoid(),
            }));
          }}
        />
      ) : (
        []
      )}
    </Box>
    // </ClickAwayListener>
  );
}

function Dropdown({
  disabled = false,
  values = [],
  data,
  onChange,
  multiple = false,
}: {
  disabled: boolean;
  values: number[];
  data: { id: number; label: string }[];
  onChange: (e: number[]) => void;
  multiple: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
  const dataValues = data.filter((i) => values?.includes(i.id));
  let variant: "outlined" | "solid" | "soft" = "outlined";
  let labels = dataValues.reduce((a, v) => {
    a.push(v.label);
    return a;
  }, []);
  if (values.length === 0) {
    labels = [data.find((i) => i.id === 0)?.label];
  } else {
    variant = "solid";
  }

  return (
    <>
      <Chip
        endDecorator={
          !disabled ? <i className="fa-solid fa-caret-down"></i> : undefined
        }
        color={"neutral"}
        size="sm"
        variant={variant}
        onClick={!disabled ? (e) => setAnchorEl(e.currentTarget) : undefined}
      >
        {labels.join(", ")}
      </Chip>
      <Menu
        size="sm"
        placement="bottom-start"
        disablePortal
        sx={{ maxHeight: 400, overflow: "auto" }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {data.map((d) => {
          return (
            <MenuItem
              onClick={() => {
                if (multiple) {
                  if (d.id === 0) {
                    onChange([]);
                    setAnchorEl(null);
                    return;
                  }
                  const index = values?.findIndex((i) => i === d.id);
                  if (index === -1) {
                    onChange([...values, d.id]);
                  } else {
                    const _values = [...values];
                    _values.splice(index, 1);
                    onChange(_values);
                  }
                } else {
                  onChange([d.id]);
                  setAnchorEl(null);
                }
              }}
              sx={{ gap: 1 }}
              key={d.id}
            >
              {multiple && d.id !== 0 ? (
                <Checkbox checked={values.includes(d.id)} />
              ) : (
                []
              )}
              {multiple && d.id === 0 ? <Box sx={{ width: "20px" }} /> : []}
              {d.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

function getOrderData() {
  const ret: { id: number; label: string }[] = [{ label: "any", id: 0 }];

  for (let index = 1; index < 100; index++) {
    ret.push({ id: index, label: `${index}` });
  }

  return ret;
}

export function SuggestedBySubRule({ subRuleID }: { subRuleID: number }) {
  const description = useSubRuleDescription(subRuleID);
  return (
    <Tooltip
      arrow
      placement="right"
      variant="outlined"
      sx={{ maxWidth: 300 }}
      size="sm"
      title={description}
    >
      <Typography
        endDecorator={<i className="fa-regular fa-circle-info"></i>}
        sx={{ opcaity: 0.58 }}
        level="body5"
      >
        Suggested by Rule
      </Typography>
    </Tooltip>
  );
}

export function useSubRuleDescription(subRuleID?: number) {
  const utils = useSelector(layoutUtilsSelector);
  const { sectionRoles, sectionsMap, projectPieces, piecesMap } = utils;
  const { subRule } = useSubRule(subRuleID);
  const { templatesMap } = useTemplates();
  const sections = mapToArray(sectionsMap);

  let targetIDs = [];
  let replaceIDs = [];

  let musicianIDs = [];
  if (subRule) {
    targetIDs = parser(subRule.targetIDs);
    replaceIDs = parser(subRule.replaceIDs);
    musicianIDs = [...targetIDs, ...replaceIDs];
  }

  const { musiciansMap } = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.id",
          comparison: "in",
          value: musicianIDs,
        },
      ]),
    },
    { skip: musicianIDs?.length === 0 }
  );

  if (!subRuleID) return <Box />;

  const ruleChunks = [
    {
      key: "targetIDs",
      prefix: "If",
      prefixPlural: "If",
      data: [
        { id: 0, label: "anyone" },
        { id: -1, label: "this musician..." },
        { id: -2, label: "these musicians..." },
      ],
      hiddable: false,
      multiple: false,
    },
    {
      key: "sectionIDs",
      prefix: "in section",
      prefixPlural: "in sections",
      data: sections.reduce(
        (a, v) => {
          a.push({ id: v.id, label: v.name });
          return a;
        },
        [{ id: 0, label: "any" }]
      ),
      hiddable: true,
      multiple: true,
    },
    {
      key: "sectionRoleIDs",
      prefix: "with role",
      prefixPlural: "with roles",
      data: sectionRoles.reduce(
        (a, v) => {
          a.push({ id: v.id, label: v.name });
          return a;
        },
        [{ id: 0, label: "any" }]
      ),
      hiddable: true,
      multiple: true,
    },
    {
      key: "orders",
      prefix: "with section order",
      prefixPlural: "with section orders",
      data: getOrderData(),
      hiddable: true,
      multiple: true,
    },
    {
      key: "replaceIDs",
      prefix: `decline${targetIDs.length === 1 ? "s" : ""} THEN suggest`,
      prefixPlural: `decline${targetIDs.length === 1 ? "s" : ""}  THEN suggest`,
      data: [
        { id: 0, label: "nobody" },
        { id: -1, label: "this musician..." },
        { id: -2, label: "these musicians..." },
        { id: -3, label: "next in line on this template..." },
      ],
      hiddable: false,
      multiple: false,
    },
    {
      key: "projectPieceIDs",
      prefix: "for project piece",
      prefixPlural: "for project pieces",
      data: projectPieces.reduce(
        (a, v) => {
          a.push({
            id: v.id,
            label: `${piecesMap[v.pieceID]?.name ?? "Seating assignment"} ${
              piecesMap[v.pieceID] ? `, ${piecesMap[v.pieceID]?.composer}` : ""
            }`,
          });
          return a;
        },
        [{ id: 0, label: "any" }]
      ),
      hiddable: true,
      multiple: true,
    },
  ];

  const ret = [];

  if (!subRule) return ret;

  ruleChunks.forEach((c) => {
    const values = parser(subRule[c.key]);
    if (values?.length === 0 && c.hiddable) return;
    ret.push(
      <Typography sx={{ m: "1px" }} level="body4">{`${
        values.length > 1 ? c.prefixPlural ?? "" : c.prefix ?? ""
      } `}</Typography>
    );
    const options = [];
    if (values.length === 0 && c.key === "targetIDs") {
      options.push(`anyone`);
    }
    if (values.length === 0 && c.key === "replaceIDs" && subRule.templateID) {
      options.push(
        `Next in template ${templatesMap[subRule.templateID]?.name}`
      );
    }
    values.forEach((v) => {
      if (c.key === "targetIDs" || c.key === "replaceIDs") {
        const musician = v ? musiciansMap[v] : undefined;
        options.push(`${musician ? musician.fullName() : "anyone"}`);
      } else {
        const dataValue = c.data.find((e) => e.id === v);
        options.push(`${dataValue?.label ?? v}`);
      }
    });
    options.forEach((o) =>
      ret.push(
        <Typography
          sx={{ m: "1px" }}
          color="primary"
          variant="outlined"
          level="body4"
          noWrap
        >
          {o}
        </Typography>
      )
    );
  });

  return <Typography level="body2">{ret}</Typography>;
}
