import { Tooltip, Typography } from "@mui/joy";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import {
  layoutDebugSelector,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";

export function WorkSessionInfo({ workSessionID }: { workSessionID: number }) {
  const utils = useSelector(layoutUtilsSelector);
  const debug = useSelector(layoutDebugSelector);

  const workSession = utils.workSessionsMap[workSessionID];
  return (
    <Box sx={{ lineHeight: 0 }}>
      <Typography
        sx={{
          fontWeight: "normal",
        }}
        endDecorator={
          debug ? (
            <Typography level="body5">{workSessionID}</Typography>
          ) : undefined
        }
        level="body2"
      >
        {workSession?.title}
      </Typography>
      <br />
      <Tooltip
        size="sm"
        variant="outlined"
        arrow
        enterDelay={1000}
        enterNextDelay={1000}
        title={workSession?.formatDateRange()}
      >
        <Typography
          sx={{
            fontWeight: "normal",
          }}
          level="body3"
        >
          {workSession?.formatDateRange()}
        </Typography>
      </Tooltip>
    </Box>
  );
}
