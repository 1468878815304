import { Button, Input, Option, Select, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { usePayrolls } from "redux/payroll/payrollHooks";
import { useUpdateProjectMutation } from "redux/project/projectEndpoints";

/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ProjectWageInfo() {
  const open = useSelector(formOpenSelector("projectWageInfo"));
  const dispatch = useDispatch();
  const selectedProjectID = useSelector(selectedProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const project = utils?.project;
  const [updateProject] = useUpdateProjectMutation();
  const { payrolls } = usePayrolls();
  const [wageAgreement, setWageAgreement] = useState(project?.wageAgreement);
  const [wageUnit, setWageUnit] = useState(project?.wageUnit);
  const [payrollID, setPayrollID] = useState(project?.payrollID);
  const [wageDescription, setWageDescription] = useState(
    project?.wageDescription
  );

  useEffect(() => {
    if (project) {
      setWageAgreement(project.agreement);
      setWageUnit(project.wageUnit);
      setWageDescription(project.wageDescription);
      setPayrollID(project?.payrollID);
    }
  }, [project]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectWageInfo" }));
  };

  const save = () => {
    updateProject({
      id: selectedProjectID,
      body: {
        ...project,
        agreement: wageAgreement,
        wageUnit,
        wageDescription,
        payrollID,
      },
    });
    onClose();
  };

  return (
    <DialogClose maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          startDecorator={<i className="fa-solid fa-wallet"></i>}
          level="h6"
        >
          Wage Info
        </Typography>
        <Typography level="body2">
          This info will be included in the call and communication.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        <Input
          startDecorator="Wage:"
          placeholder="ex: $250"
          value={wageAgreement}
          onChange={(e) => setWageAgreement(e.target.value)}
        />
        <Input
          startDecorator="Wage Unit:"
          placeholder="ex: Per service"
          value={wageUnit}
          onChange={(e) => setWageUnit(e.target.value)}
        />
        <Input
          startDecorator="Wage Description:"
          placeholder="ex: OS 200% for Principal"
          value={wageDescription}
          onChange={(e) => setWageDescription(e.target.value)}
        />
        {payrolls?.length ? (
          <Select
            slotProps={{
              listbox: { sx: { zIndex: 9999 } },
            }}
            onChange={(e, v) => setPayrollID(v)}
            value={payrollID}
            startDecorator={"Payroll Company:"}
          >
            {payrolls.map((p) => (
              <Option key={p.id} value={p.id}>
                {p.name}
              </Option>
            ))}
          </Select>
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </DialogClose>
  );
}
