import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Stage_Entity } from "entities/stage";
import { mercuryApi } from "../api/mercuryApi";

const stagesAdapter = createEntityAdapter<Stage_Entity>();
const initialState = stagesAdapter.getInitialState();

export const stageEndpoints = mercuryApi.injectEndpoints({
  endpoints: (build) => ({
    getStages: build.query<EntityState<Stage_Entity>, void>({
      query: () => `stages`,
      transformResponse: (responseData: Stage_Entity[]) => {
        return stagesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["stages"],
    }),
    getStage: build.query<Stage_Entity, number>({
      query: (id) => `stages/${id}`,
      providesTags: (result, error, id) => [{ type: "stages", id }],
    }),
    createStage: build.mutation<Stage_Entity, Partial<Stage_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `stages`,
      }),
      invalidatesTags: ["stages"],
    }),
    updateStage: build.mutation<
      void,
      { id: number; body: Partial<Stage_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `stages/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "stages", id },
        "stages",
      ],
    }),
    deleteStage: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `stages/${id}`,
      }),
      invalidatesTags: ["stages"],
    }),
  }),
});

export const {
  useGetStageQuery,
  useGetStagesQuery,
  useCreateStageMutation,
  useDeleteStageMutation,
  useUpdateStageMutation,
} = stageEndpoints;
