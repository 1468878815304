import {
  createSelector,
  createSlice,
  Dictionary,
  PayloadAction,
} from "@reduxjs/toolkit";
import { InternalPosition } from "entities/internal/InternalPosition";
import { Internal } from "hooks/Layout/types";
import { RootState } from "store";

interface LayoutState {
  internal: Internal;
  selected: Dictionary<boolean>;
  reordering?: boolean;
  moveToSectionID?: number;
  hoverSectionID?: number;
}

const initialState = {
  selected: {},
  reordering: false,
} as LayoutState;

export const layoutSlice = createSlice({
  name: "layoutSlice",
  initialState,
  reducers: {
    layoutHoverSectionID(state, action: PayloadAction<number>) {
      state.hoverSectionID = action.payload;
    },
    layoutSetInternal(state, action: PayloadAction<Internal>) {
      state.internal = action.payload;
    },
    layoutSetMoveToSectionID(state, action: PayloadAction<number>) {
      state.moveToSectionID = action.payload;
    },
    layoutSetReordering(state, action: PayloadAction<boolean>) {
      state.reordering = action.payload;
    },
    layoutSelect(state, action: PayloadAction<number[]>) {
      action.payload.forEach((id) => (state.selected[id] = true));
    },
    layoutUnselect(state, action: PayloadAction<number[]>) {
      action.payload.forEach((id) => delete state.selected[id]);
    },
    layoutUnselectAll(state) {
      const elements = document.getElementsByClassName("selecto");
      Array.from(elements).forEach((el) =>
        el.classList.remove("selectoSelected")
      );
      state.selected = {};
    },
  },
});

export const {
  layoutSelect,
  layoutSetInternal,
  layoutSetMoveToSectionID,
  layoutSetReordering,
  layoutUnselect,
  layoutUnselectAll,
  layoutHoverSectionID,
} = layoutSlice.actions;

export const layoutReorderEnabledForSectionSelector = (sectionID?: number) => {
  return createSelector(
    [(s: RootState) => s.layoutSlice.hoverSectionID],
    (hoverSectionID) => {
      if (sectionID) return sectionID === hoverSectionID;
      return false;
    }
  );
};

export const layoutHoverSectionIDSelector = createSelector(
  (s: RootState) => s.layoutSlice.hoverSectionID,
  (s) => s
);

export const layoutSelectionMapSelector = createSelector(
  (s: RootState) => s.layoutSlice.selected,
  (s) => s
);

export const layoutInternalSelector = createSelector(
  (s: RootState) => s.layoutSlice.internal,
  (s) => s
);

export const layoutSelectionSelector = createSelector(
  (s: RootState) => s.layoutSlice.selected,
  (s) => Object.keys(s)
);

export const layoutReorderingSelector = createSelector(
  (s: RootState) => s.layoutSlice.reordering,
  (s) => s
);

export const layoutMoveToSectionIDSelector = createSelector(
  (s: RootState) => s.layoutSlice.moveToSectionID,
  (s) => s
);

export const layoutSectionsWithEmptyChairs = createSelector(
  (s: RootState) => s.layoutSlice.internal,
  (internal) => {
    const ret: Dictionary<boolean> = {};
    internal?.forEach((f) => {
      f.sections.forEach((s) => {
        s.chairs.forEach((c) => {
          if (c.emptyChairIndex >= 0) {
            ret[s.sectionID] = true;
          }
        });
      });
    });
    return ret;
  }
);
