export const STORE_RESET_PASSWORD_TOKEN = "STORE_RESET_PASSWORD_TOKEN";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const WS_MESSAGE = "WS_MESSAGE";
export const SELECTED_CALENDAR_DAY = "SELECTED_CALENDAR_DAY";
export const SET_PREFERED_WORK_SESSION_PRESENTATION =
  "SET_PREFERED_WORK_SESSION_PRESENTATION";
export const SET_GOOGLE_CONTACT = "SET_GOOGLE_CONTACT";
export const SET_PROJECT_HIRING_SORT_BY = "SET_PROJECT_HIRING_SORT_BY";
export const SET_CALENDAR_OPEN_TO = "SET_CALENDAR_OPEN_TO";
export const SET_TAB_INDEX = "SET_TAB_INDEX";
export const SET_FORM_OPEN = "SET_FORM_OPEN";
export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";
export const SET_CALENDAR_OPTIONS = "SET_CALENDAR_OPTIONS";
