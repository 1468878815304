import Customer_Entity from "./customer";
export default class Customer extends Customer_Entity {
  static fromList(customersJSON: unknown): Array<Customer> {
    const customers: Customer[] = [];
    if (customersJSON)
      Array.isArray(customersJSON) &&
        customersJSON.forEach((customerJSON) => {
          customers.push(new Customer(customerJSON));
        });
    return customers;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
