import { Tag } from "entities/tag";
import { useMemo } from "react";
import { useGetTagQuery, useGetTagsQuery } from "./tagEndpoints";

export function useTag(tagID: number | undefined) {
  const tagQuery = useGetTagQuery(tagID ?? 0, {
    skip: (tagID ?? 0) <= 0,
  });
  const tagEntity = tagQuery.data;

  return useMemo(() => {
    const ret: typeof tagQuery & { tag?: Tag | undefined } = {
      ...tagQuery,
    };
    if (tagEntity) ret.tag = new Tag(tagEntity);
    return ret;
  }, [tagEntity]);
}

export function useTags() {
  const tagQuery = useGetTagsQuery();
  const tagEntitiesMap = tagQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof tagQuery & {
      tags?: Tag[];
      tagsMap?: { [id: number]: Tag };
    } = {
      ...tagQuery,
      tags: [],
      tagsMap: {},
    };
    if (tagEntitiesMap) {
      const tags = [];
      const tagsMap = {};

      for (const key in tagEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(tagEntitiesMap, key)) {
          const c = tagEntitiesMap[key];
          const tag = new Tag(c);
          tags.push(tag);
          tagsMap[tag.id] = tag;
        }
      }
      ret.tags = tags;
      ret.tagsMap = tagsMap;
    }

    return ret;
  }, [tagEntitiesMap]);
}
