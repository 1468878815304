import { Digest_Entity } from "entities/digest";
import { rhapsodyApi } from "../api/rhapsodyApi";

export const digestEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getDailyDigest: build.query<Digest_Entity[], void>({
      query: () => `companies/current/dailyDigest`,
      providesTags: ["digests"],
    }),
    getWeeklyDigest: build.query<Digest_Entity[], void>({
      query: () => `companies/current/weeklyDigest`,
      providesTags: ["digests"],
    }),
  }),
});

export const { useGetDailyDigestQuery, useGetWeeklyDigestQuery } =
  digestEndpoints;

export default digestEndpoints;
