import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Sheet,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";
import { Tab, Tabs } from "@mui/material";
import ColorPicker from "atoms/ColorPicker";
import Upload from "atoms/FileUpload/FileUpload";
import { Company_Entity } from "entities/company";
import { finale, useMySubscription } from "hooks/subscription/restrictionHooks";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDescription420, setFormOpen } from "reducers/rhapsody";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import { useCurrentCompany } from "redux/company/companyHooks";

/**
 *
 * @returns {ReactElement} Branding page
 */
export function Branding() {
  const subscription = useMySubscription();
  const readOnly = useReadOnly();
  const { company } = useCurrentCompany();
  const [updateCompany, { isSuccess, isLoading }] =
    useUpdateCurrentCompanyMutation();
  const [_company, setCompany] = useState<Company_Entity>();

  useEffect(() => {
    setCompany(company);
  }, [company]);

  const dispatch = useDispatch();
  return (
    <Sheet variant="soft" sx={{ borderRadius: "8px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
        <Typography level="h6">Branding</Typography>
        <Button
          disabled={readOnly}
          color={isSuccess ? "success" : "primary"}
          startDecorator={isLoading ? <CircularProgress /> : undefined}
          size="sm"
          onClick={() => {
            if (subscription !== finale) {
              dispatch(
                setDescription420(
                  "Upgrade to Finale to be able to customize your call & communication emails."
                )
              );
              dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
            } else {
              updateCompany(_company);
            }
          }}
          endDecorator={
            subscription !== "finale" ? (
              <Typography
                sx={{
                  background: "rgba(255,255,255,0.3)",
                  pl: 0.5,
                  pr: 0.5,
                  borderRadius: 4,
                  color: "white",
                }}
                level="body4"
              >
                FINALE
              </Typography>
            ) : undefined
          }
        >
          Save Change
        </Button>
      </Box>
      <Divider />
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 320,
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography>Brand elements</Typography>
          <Typography level="body2">
            Customize how your brand appears globally across the Rhapsody
            products your customers use.
          </Typography>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              level="body2"
              endDecorator={
                <Tooltip
                  sx={{ width: 220 }}
                  variant="outlined"
                  arrow
                  size="sm"
                  title="A smaller representation of your logo (like a favicon). It must be square and at least 128px by 128px with a max size of 512KB. The supported MIME types are JPG, PNG, and GIF."
                >
                  <i className="fa-solid fa-square-info"></i>
                </Tooltip>
              }
            >
              Icon
            </Typography>
            {_company?.icon ? (
              <img
                style={{ height: 30, background: "#bdbdbd" }}
                src={_company?.icon}
              />
            ) : (
              []
            )}
            <Upload
              disabled={readOnly}
              label="Upload Icon"
              onUpload={(f) => {
                setCompany((c) => ({ ...c, icon: f }));
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              level="body2"
              endDecorator={
                <Tooltip
                  sx={{ width: 220 }}
                  variant="outlined"
                  arrow
                  size="sm"
                  title="The full-sized version of vour logo. It must be at least 128px by 128px with a max size of 512KB. The supported MIME types are JPG, PNG, and GIf."
                >
                  <i className="fa-solid fa-square-info"></i>
                </Tooltip>
              }
            >
              Logo
            </Typography>
            {_company?.logoURL ? (
              <img
                style={{ height: 30, background: "#bdbdbd" }}
                src={_company?.logoURL}
              />
            ) : (
              []
            )}
            <Upload
              disabled={readOnly}
              label="Upload Logo"
              onUpload={(f) => {
                setCompany((c) => ({ ...c, logoURL: f }));
              }}
            />
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              level="body2"
              endDecorator={
                <Tooltip
                  sx={{ width: 220 }}
                  variant="outlined"
                  arrow
                  size="sm"
                  title="Add a splash of color to your branded pages"
                >
                  <i className="fa-solid fa-square-info"></i>
                </Tooltip>
              }
            >
              Brand Color
            </Typography>
            <ColorPicker
              disabled={readOnly}
              label=""
              onChange={(c) => {
                setCompany((e) => ({ ...e, color: c }));
              }}
              color={_company?.color ? _company?.color : "#0D1D38"}
            />
          </Box>
        </Box>
        <Divider orientation="vertical" />
        <Box
          sx={{
            flexGrow: 1,
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography level="body3">PREVIEWS</Typography>
          <Sheet variant="outlined" sx={{ width: 825, background: "#F6F8FA" }}>
            <Tabs value={0} sx={{ background: "white" }}>
              <Tab label="Call & Communication Emails" value={0} />
            </Tabs>
            <Box sx={{ p: 1 }}>
              <Box
                sx={{
                  background: _company?.color ? _company?.color : "#0D1D38",
                  width: 690,
                  borderRadius: 2,
                  margin: "auto",
                  mt: 2,
                  p: 4,
                  pt: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <img
                  style={{ height: 40 }}
                  src={
                    _company?.logoURL
                      ? _company?.logoURL
                      : "https://storage.googleapis.com/rhapsody/Avatars/rhapsody.png"
                  }
                />
                <Paper>
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={18}
                    width={"50%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                </Paper>
                <Paper>
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={18}
                    width={"50%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Skeleton
                      animation={false}
                      variant="text"
                      height={24}
                      width={50}
                    />
                    <Skeleton
                      animation={false}
                      variant="text"
                      height={24}
                      width={50}
                    />
                    <Skeleton
                      animation={false}
                      variant="text"
                      height={24}
                      width={50}
                    />
                  </Box>
                </Paper>
                <Paper>
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={18}
                    width={"50%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                </Paper>
                <Paper>
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={18}
                    width={"50%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                  <Skeleton
                    animation={false}
                    variant="text"
                    height={14}
                    width={"80%"}
                  />
                </Paper>
              </Box>
            </Box>
          </Sheet>
        </Box>
      </Box>
    </Sheet>
  );
}

function Paper({ children, sx }: { children: ReactNode; sx?: SxProps }) {
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0px 2px 6px rgba(0,0,0,0.12)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        //   width: "100%",
        width: 320,
        gap: 1,
        p: 1,
        position: "relative",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export function FinaleChip({ transparent }: { transparent?: boolean }) {
  return (
    <Typography
      sx={{
        background: transparent ? "rgba(255,255,255,0.3)" : "#9c27b0",
        color: "white",
        pl: 0.5,
        pr: 0.5,
        borderRadius: 4,
      }}
      level="body4"
    >
      FINALE
    </Typography>
  );
}

export function MezzoChip() {
  return (
    <Typography
      sx={{
        background: "#9c27b0",
        color: "white",
        pl: 0.5,
        pr: 0.5,
        borderRadius: 4,
      }}
      level="body4"
    >
      MEZZO
    </Typography>
  );
}
