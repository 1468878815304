// import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { SelectNetworkApiValues, VirtualMainState } from "@nerdjs/nerd-network";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export const unityApi = createApi({
  reducerPath: "unityApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "unity" }),
  endpoints: () => ({}),
  tagTypes: ["uploads"],
});

const rawBaseQuery = fetchBaseQuery({
  baseUrl: "",
  prepareHeaders(headers: Headers) {
    headers.append("Accept", "application/json");
    if (window._APP_CONFIG?.api?.authServerToken)
      headers.append(
        "auth-server-token",
        window._APP_CONFIG?.api?.authServerToken
      );
    return headers;
  },
  credentials: "include",
});

export const nerdNetworkBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  { service: string; overwriteApiVersion?: string }
> = async (args, api, extraOptions) => {
  const apiValues = SelectNetworkApiValues(api.getState() as VirtualMainState);
  const { overwriteApiVersion, service } = extraOptions;

  let url = apiValues.endpoint;
  if (overwriteApiVersion) {
    url += overwriteApiVersion;
  } else {
    url += apiValues.routePrefix;
  }

  if (service) {
    url += `/${service}/`;
  } else {
    url += "/";
  }

  let adjustedArgs: string | FetchArgs;
  if (typeof args === "string") {
    adjustedArgs = `${url}${args}`;
  } else {
    adjustedArgs = { ...args, url: `${url}${args.url}` };
  }

  const result = await rawBaseQuery(adjustedArgs, api, extraOptions);
  if (result.error && result.error.status === 401 && args === "users/current") {
    return { data: null };
  }
  return result;
};
