import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Scale_Entity } from "entities/scale";
import { rhapsodyApi } from "../api/rhapsodyApi";

const scalesAdapter = createEntityAdapter<Scale_Entity>();
const initialState = scalesAdapter.getInitialState();

export const scaleEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getScales: build.query<EntityState<Scale_Entity>, void>({
      query: () => `scales`,
      transformResponse: (responseData: Scale_Entity[]) => {
        return scalesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["scales"],
    }),
    getProjectScales: build.query<EntityState<Scale_Entity>, number>({
      query: (id) => `projects/${id}/scales`,
      transformResponse: (responseData: Scale_Entity[]) => {
        return scalesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["scales"],
    }),
    getTagScales: build.query<EntityState<Scale_Entity>, number>({
      query: (id) => `tags/${id}/scales`,
      transformResponse: (responseData: Scale_Entity[]) => {
        return scalesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["scales"],
    }),
    getScale: build.query<Scale_Entity, number>({
      query: (id) => `scales/${id}`,
      providesTags: (result, error, id) => [{ type: "scales", id }],
    }),
    createScale: build.mutation<Scale_Entity, Partial<Scale_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `scales`,
      }),
      invalidatesTags: ["scales"],
    }),
    updateScale: build.mutation<
      void,
      { id: number; body: Partial<Scale_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `scales/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "scales", id },
        "scales",
      ],
    }),
    deleteScale: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `scales/${id}`,
      }),
      invalidatesTags: ["scales"],
    }),
  }),
});

export const {
  useGetProjectScalesQuery,
  useGetTagScalesQuery,
  useGetScaleQuery,
  useGetScalesQuery,
  useCreateScaleMutation,
  useDeleteScaleMutation,
  useUpdateScaleMutation,
} = scaleEndpoints;

export default scaleEndpoints;
