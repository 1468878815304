import {
  Alert,
  Button,
  Divider,
  ListItem,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import { Box, Grow, Popover, Skeleton } from "@mui/material";
import { AppConfig } from "environement";
import moment from "moment";
import { alpha } from "@mui/system";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormOpen } from "reducers/rhapsody";
import { useCurrentSubscription } from "redux/subscription/subscriptionHooks";
import { meSelector } from "selectors/me";
import { mezzo, overture, useMySubscription } from "./restrictionHooks";
import { iconForPlan } from "./constants";
import { useGetCurrentSubscriptionQuery } from "redux/subscription/subscriptionEndpoints";

// const subscription = {
//   id: "sub_1MqMdKHhdcePdOToQDMz8fsO",
//   customerID: "cus_NbZmtAuoc8ByRA",
//   email: "olivier@rhapsody.la",
//   productID: "prod_NUBgUsIV8wAEg0",
//   productName: "Rhapsody Finale",
//   created: "2023-03-27T20:27:14Z",
//   startDate: "2023-03-27T20:27:14Z",
//   trialStart: "1970-01-01T00:00:00Z",
//   trialEnd: "1970-01-01T00:00:00Z",
//   endedAt: "1970-01-01T00:00:00Z",
//   daysUntilDue: 0,
//   currentPeriodStart: "2023-03-27T20:27:14Z",
//   currentPeriodEnd: "2023-04-27T20:27:14Z",
//   canceledAt: "1970-01-01T00:00:00Z",
//   cancelAtPeriodEnd: false,
//   cancelAt: "1970-01-01T00:00:00Z",
//   cancellationDetail: { comment: "", feedback: "", reason: "" },
//   discount: "",
//   paymentMethod: "card visa 4242",
//   status: "active",
//   companyID: 0,
// };

// const isLoading = false;
// const isFetching = false;
// const isError = false;
// const mySubscription: Mezzo | Finale | Overture = overture;

/**
 *
 * @returns {ReactElement} Subscription page
 */
export function Subscription({ dark }: { dark?: boolean }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const mySubscription = useMySubscription();
  const { subscription } = useCurrentSubscription();
  const dispatch = useDispatch();

  const color = dark ? "#081B31" : "#ffffff";

  if (!mySubscription)
    return (
      <Skeleton
        sx={{
          background: alpha(color, 0.2),
          height: 42,
          width: 80,
        }}
      />
    );
  return (
    <Grow in>
      <Box>
        <Box
          onClick={(e) => {
            mySubscription === overture
              ? dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }))
              : setAnchorEl(e.currentTarget);
          }}
          sx={{
            border: `solid 2px ${alpha(color, 0.2)}`,
            background: alpha(color, 0.1),
            pl: 1,
            overflow: "hidden",
            alignItems: "stretch",
            borderRadius: 16,
            cursor: "pointer",
            mt: "4px",
            display: "flex",
            "&:hover": {
              background: alpha(color, 0.2),
              borderColor: color,
            },
          }}
        >
          <Typography
            startDecorator={iconForPlan[mySubscription]}
            level="body2"
            sx={{ color: color, pr: 1 }}
          >
            {mySubscription}
          </Typography>
        </Box>
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box
            sx={{
              p: 2,
              width: 320,
              display: "flex",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <Typography level={"h6"}>
              {subscription?.productName ?? "Overture"}
            </Typography>
            <Divider />
            {subscription ? (
              <>
                {subscription.cancelAtPeriodEnd ? (
                  <Alert size="sm" color="warning" variant="solid">
                    You requested a cancellation on{" "}
                    {moment(subscription.canceledAt).format("l")}. This will be
                    effective on {moment(subscription.cancelAt).format("l")}
                  </Alert>
                ) : (
                  []
                )}
                <ListItem>
                  <ListItemDecorator>Current Period:</ListItemDecorator>
                  <Typography
                    level={"body2"}
                    sx={{ flexGrow: 1, textAlign: "right" }}
                  >
                    {moment(subscription.currentPeriodStart).format("l")} -{" "}
                    {moment(subscription.currentPeriodEnd).format("l")}
                  </Typography>
                </ListItem>
                {subscription.daysUntilDue ? (
                  <ListItem>
                    <ListItemDecorator>Next billing in:</ListItemDecorator>
                    <Typography
                      level={"body2"}
                      sx={{ flexGrow: 1, textAlign: "right" }}
                    >
                      {subscription.daysUntilDue} days
                    </Typography>
                  </ListItem>
                ) : (
                  []
                )}
                <ListItem>
                  <ListItemDecorator>Credit Card:</ListItemDecorator>
                  <Typography
                    level={"body2"}
                    sx={{ flexGrow: 1, textAlign: "right" }}
                  >
                    {subscription.paymentMethod}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemDecorator>Status:</ListItemDecorator>
                  <Typography
                    level={"body2"}
                    sx={{ flexGrow: 1, textAlign: "right" }}
                  >
                    {subscription.status}
                  </Typography>
                </ListItem>
                {subscription.discount ? (
                  <ListItem>
                    <ListItemDecorator>Discount:</ListItemDecorator>
                    <Typography
                      level={"body2"}
                      sx={{ flexGrow: 1, textAlign: "right" }}
                    >
                      {subscription.discount}
                    </Typography>
                  </ListItem>
                ) : (
                  []
                )}
                <Button
                  onClick={() =>
                    window.open(
                      `${AppConfig.stripe.portal}?prefilled_email=${subscription.email}`,
                      "_blank"
                    )
                  }
                  color="neutral"
                  variant="soft"
                  fullWidth
                >
                  Manage Plan
                </Button>
                {mySubscription === mezzo ? (
                  <Button
                    onClick={() =>
                      dispatch(
                        setFormOpen({ isOpen: true, formID: "subscribe" })
                      )
                    }
                    color="primary"
                    variant="soft"
                    fullWidth
                  >
                    Upgrade Plan
                  </Button>
                ) : (
                  []
                )}
                <Typography sx={{ textAlign: "center" }} level="body3">
                  Rhapsody partners with Stripe for simplified billing.
                </Typography>
              </>
            ) : (
              []
            )}
          </Box>
        </Popover>
      </Box>
    </Grow>
  );
}
