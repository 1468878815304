import { showNewMessage } from "@intercom/messenger-js-sdk";
import { Alert, Button, Chip, Sheet, Typography } from "@mui/joy";
import { Box } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import {
  mezzo,
  overture,
  useMySubscription,
} from "hooks/subscription/restrictionHooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDescription420, setFormOpen } from "reducers/rhapsody";
import { useDeleteOrganizationUserMutation } from "redux/user/userEndpoint";
import { useCurrentUser, useOrganizationUsers } from "redux/user/userHook";

/**
 *
 * @returns {ReactElement} OrganizationUsers page
 */
export function OrganizationUsers() {
  const subscription = useMySubscription();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: currentUser } = useCurrentUser();
  const { users } = useOrganizationUsers(currentUser?.organizationID);
  const askQuestion = useAskQuestion();
  const [deleteOrganizationUser] = useDeleteOrganizationUserMutation();

  useEffect(() => {
    if (subscription === overture) {
      dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));
      dispatch(
        setDescription420(
          "Additional users is an exclusive feature to the Mezzo & Finale plan."
        )
      );
      navigate(-1);
    }
  }, [subscription]);

  const confirmDelete = (id) => {
    deleteOrganizationUser(id);
  };

  let userLeft;
  if (subscription === mezzo) {
    userLeft = 2 - users?.length;
  }

  return (
    <Sheet
      variant="soft"
      sx={{
        p: 2,
        maxWidth: 600,
        margin: "auto",
        display: "flex",
        gap: 2,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography level="h6">Team Members</Typography>
          <Typography level="body2">
            Invite members to help managing your Rhapsody account
          </Typography>
        </Box>
        <Button
          onClick={() => {
            showNewMessage(
              "Hi Team, I would like to add a new user in my organization."
            );
          }}
          startDecorator={<i className="fa-solid fa-plus"></i>}
          size="sm"
        >
          Add Member
        </Button>
      </Box>
      {userLeft !== undefined ? (
        <Alert
          size="sm"
          color="warning"
          endDecorator={
            <Button
              onClick={() => {
                dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));
              }}
              size="sm"
              color="warning"
              variant="plain"
            >
              Upgrade
            </Button>
          }
        >
          {userLeft === 0
            ? `Your ${subscription} plan doesn't allow you to invite more members. `
            : `You current plan allows ${userLeft} additional user.`}
        </Alert>
      ) : (
        []
      )}
      <DataGridPro
        sx={{ background: "white" }}
        autoHeight
        density="compact"
        initialState={{
          pinnedColumns: { right: ["actions"] },
        }}
        columns={[
          {
            field: "firstName",
            headerName: "Name",
            flex: 1,
            renderCell: (p) => (
              <Box>
                <Typography
                  level="body2"
                  endDecorator={
                    currentUser?.id === p.row.id ? (
                      <Chip size="sm" variant="soft">
                        You
                      </Chip>
                    ) : (
                      []
                    )
                  }
                >
                  {p.row?.fullname()}
                </Typography>
              </Box>
            ),
          },
          { field: "email", headerName: "Email", flex: 1 },
          {
            field: "active",
            headerName: "Status",
            width: 160,
            align: "right",
            renderCell: (p) =>
              p.row.active ? (
                <Chip size="sm" color="success" variant="soft">
                  Active
                </Chip>
              ) : (
                <Chip size="sm" color="info" variant="soft">
                  Invitation Pending
                </Chip>
              ),
          },
          {
            field: "actions",
            headerName: "",
            type: "actions",
            width: 30,
            getActions: (p) => [
              <GridActionsCellItem
                key="Remove"
                showInMenu
                icon={<i className="fa-solid fa-trash"></i>}
                label={`Remove`}
                color="inherit"
                onClick={() => {
                  askQuestion("Are you sure?", ["Cancel", "Yes"], {
                    subtitle: (
                      <Typography>
                        This user won't have access to your organization
                        anymore.
                      </Typography>
                    ),
                  }).then((i) => {
                    if (i == 1) confirmDelete(p.row.relationID);
                  });
                }}
              />,
            ],
          },
        ]}
        rows={users}
      />
    </Sheet>
  );
}
