/**
 * ProjectNote_Entity type guard.
 *
 * @param {any} projectNoteJson ProjectNote object from API
 * @returns {boolean} Return true if type is ProjectNote_Entity
 */

export default class ProjectNote_Entity {
  constructor(projectNoteJson: ProjectNote_Entity) {
    try {
      this.id = projectNoteJson.id;
      this.autopilot = projectNoteJson.autopilot;
      this.include = projectNoteJson.include;
      this.name = projectNoteJson.name;
      this.priority = projectNoteJson.priority;
      this.projectID = projectNoteJson.projectID;
      this.roles = projectNoteJson.roles;
      this.sections = projectNoteJson.sections;
      this.stages = projectNoteJson.stages;
      this.createdAt = projectNoteJson.createdAt;
      this.updatedAt = projectNoteJson.updatedAt;
      this.value = projectNoteJson.value;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  autopilot: boolean;
  include: boolean;
  name: string;
  priority: number;
  projectID: number;
  roles: string;
  sections: string;
  stages: string;
  createdAt: string;
  updatedAt: string;
  value: string;
}
