import { Chip, IconButton, Sheet, Typography } from "@mui/joy";
import { Box } from "@mui/system";
import { ReactNode, useState } from "react";
import { SxProps } from "@mui/material";

export const helpdeskStatus = (id) => {
  let helpCardDismiss = {};
  try {
    helpCardDismiss = JSON.parse(localStorage.getItem("helpCardDismiss"));
  } catch {
    helpCardDismiss = {};
  }
  if (helpCardDismiss === null) helpCardDismiss = {};
  const dismissed = helpCardDismiss[id];

  return dismissed ?? false;
};

export const helpdeskDismiss = (id) => {
  let helpCardDismiss = {};
  try {
    helpCardDismiss = JSON.parse(localStorage.getItem("helpCardDismiss"));
  } catch {
    helpCardDismiss = {};
  }
  if (helpCardDismiss === null) helpCardDismiss = {};
  helpCardDismiss[id] = true;
  localStorage.setItem("helpCardDismiss", JSON.stringify(helpCardDismiss));
};

/**
 *
 * @returns {ReactElement} HelpCard page
 */
export function HelpCard({
  title,
  children,
  sx = {},
}: {
  title: string;
  children: ReactNode;
  sx?: SxProps;
}) {
  const [mounted, setMounted] = useState(true);
  const localStorage = window.localStorage;
  let helpCardDismiss = {};
  try {
    helpCardDismiss = JSON.parse(localStorage.getItem("helpCardDismiss"));
  } catch {
    helpCardDismiss = {};
  }
  if (helpCardDismiss === null) helpCardDismiss = {};
  const dismissed = helpCardDismiss[title];

  const handleDismiss = () => {
    helpdeskDismiss(title);
    setMounted(false);
  };

  if (!mounted || dismissed) return <></>;

  return (
    <Sheet
      variant="soft"
      sx={{
        p: 4,
        borderRadius: "8px",
        background: "#F6F8FA",
        border: "solid 1px rgba(155,155,155,0.1)",
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          startDecorator={
            <Chip color="info" size="sm" variant="soft">
              Helpdesk
            </Chip>
          }
          level="h6"
          sx={{ fontWeight: 600 }}
        >
          {title}
        </Typography>
        <IconButton onClick={handleDismiss} size="sm" color="neutral">
          <i className="fa-solid fa-xmark"></i>
        </IconButton>
      </Box>
      <Box sx={{ pt: 2 }}>{children}</Box>
    </Sheet>
  );
}
