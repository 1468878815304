import { Layout } from "entities/layout";
import { useMemo } from "react";
import { useGetProjectLayoutQuery } from "./layoutEndpoints";

export function useProjectLayout(projectID: number | undefined) {
  const layoutQuery = useGetProjectLayoutQuery(projectID ?? 0, {
    skip: (projectID ?? 0) <= 0,
  });

  const layoutEntitiesMap = layoutQuery.data;

  return useMemo(() => {
    const ret: typeof layoutQuery & {
      layouts?: Layout[];
      layoutsMap?: { [id: number]: Layout };
    } = {
      ...layoutQuery,
      layouts: [],
      layoutsMap: {},
    };
    if (layoutEntitiesMap) {
      const layouts = [];
      const layoutsMap = {};

      for (const key in layoutEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(layoutEntitiesMap, key)) {
          const c = layoutEntitiesMap[key];
          const layout = new Layout(c);
          layouts.push(layout);
          layoutsMap[layout.id] = layout;
        }
      }
      ret.layouts = layouts;
      ret.layoutsMap = layoutsMap;
    }

    return ret;
  }, [layoutEntitiesMap]);
}
