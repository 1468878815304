import { PdItem } from "entities/pdItem";
import PdGroup_Entity from "./pdGroup";
export default class PdGroup extends PdGroup_Entity {
  subgroups: Array<PdGroup>;
  items: Array<PdItem>;

  constructor(json: PdGroup_Entity) {
    super(json);
    this.subgroups = PdGroup.fromList(json.subgroups);
    this.items = PdItem.fromList(json.items);
  }

  static fromList(pdGroupsJSON: unknown): Array<PdGroup> {
    const pdGroups: PdGroup[] = [];
    if (pdGroupsJSON)
      Array.isArray(pdGroupsJSON) &&
        pdGroupsJSON.forEach((pdGroupJSON) => {
          pdGroups.push(new PdGroup(pdGroupJSON));
        });
    return pdGroups;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
