import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Event_Entity } from "entities/event";
import { rhapsodyApi } from "redux/api";

const eventsAdapter = createEntityAdapter<Event_Entity>();
const initialState = eventsAdapter.getInitialState();

export const eventEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectEvents: build.query<EntityState<Event_Entity>, number>({
      query: (projectID) => `projects/${projectID}/events`,
      transformResponse: (responseData: Event_Entity[]) => {
        return eventsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["events"],
    }),
    updateProjectEvent: build.mutation<
      void,
      { eventID: number; body: Partial<Event_Entity> }
    >({
      query: ({ eventID, body }) => ({
        method: "PUT",
        body: body,
        url: `project/events/${eventID}`,
      }),
      invalidatesTags: ["events"],
    }),
  }),
});

export const { useGetProjectEventsQuery, useUpdateProjectEventMutation } =
  eventEndpoints;

export default eventEndpoints;
