import { Typography } from "@mui/joy";
import { Inspector_Entity } from "entities/inspector";
import MercuryJob_Entity from "./mercuryJob";
export default class MercuryJob extends MercuryJob_Entity {
  static fromList(mercuryJobsJSON: unknown): Array<MercuryJob> {
    const mercuryJobs: MercuryJob[] = [];
    if (mercuryJobsJSON)
      Array.isArray(mercuryJobsJSON) &&
        mercuryJobsJSON.forEach((mercuryJobJSON) => {
          mercuryJobs.push(new MercuryJob(mercuryJobJSON));
        });
    return mercuryJobs;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  getMusicianFeedback(): string | undefined {
    let inspectors = [];
    if (this.currentJobStage?.inspectors)
      inspectors = this.currentJobStage?.inspectors;
    if (this.currentStage?.inspectors)
      inspectors = this.currentStage?.inspectors;
    return inspectors?.find((i) => i.title === "Message")?.message;
  }

  nudgeCount(): number | undefined {
    return (
      this.currentJobStage?.nudgeCount ?? this.currentStage?.nudgeCount ?? 0
    );
  }

  latestInspector(): Inspector_Entity | undefined {
    let inspectors = [];
    if (this.currentJobStage?.inspectors)
      inspectors = this.currentJobStage?.inspectors;
    if (this.currentStage?.inspectors)
      inspectors = this.currentStage?.inspectors;
    if (inspectors && inspectors.length)
      return inspectors[inspectors.length - 1];
  }

  visibleInspector(): Inspector_Entity | undefined {
    const excluded = ["emailOutbox", "signature", "Message"];
    let inspectors = [];
    if (this.currentJobStage?.inspectors)
      inspectors = this.currentJobStage?.inspectors;
    if (this.currentStage?.inspectors)
      inspectors = this.currentStage?.inspectors;

    inspectors = inspectors.filter((i) => excluded.indexOf(i.title) === -1);

    if (inspectors && inspectors.length)
      return inspectors[inspectors.length - 1];
  }

  talkback() {
    const musicianTalkback = this.currentStage?.inspectors?.find(
      (i) => i.title === "Message"
    );

    return musicianTalkback;
  }

  description() {
    const inspectors = this.currentStage?.inspectors?.filter(
      (e) => e.kind !== "file" && e.kind !== "signature" && e.kind !== "release"
    );
    const lastInspector = inspectors?.length
      ? inspectors[inspectors.length - 1]
      : undefined;

    const musicianTalkback = this.talkback();

    if (musicianTalkback)
      return (
        <Typography
          startDecorator={<i className={musicianTalkback.icon} />}
          level="body4"
          noWrap
          sx={{
            background: this.currentStage.color,
            borderRadius: 4,
            color: "white",
            pl: 0.5,
            pr: 0.5,
            mt: 0.5,
            maxWidth: 150,
          }}
        >
          {musicianTalkback.message}
        </Typography>
      );

    if (inspectors?.length)
      return (
        <Typography
          startDecorator={<i className={lastInspector.icon} />}
          level="body4"
          sx={{ maxWidth: 150 }}
        >
          {lastInspector.message}
        </Typography>
      );
  }
}
