import { CircularProgress, Input } from "@mui/joy";
import { Box } from "@mui/system";
import { NerdPhoneField } from "@nerdjs/nerd-ui";
import { Customer_Entity } from "entities/customer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectedCustomerIDSelector } from "reducers/rhapsody";
import { useUpdateCustomerMutation } from "redux/customer/customerEndpoints";
import { useCustomer } from "redux/customer/customerHooks";

export default function CustomerGeneral({ id }) {
  const params = useParams();
  const customerID =
    id ||
    parseInt(params.customerID) ||
    useSelector(selectedCustomerIDSelector);

  const { customer, isLoading } = useCustomer(customerID);
  const [updateCustomer, { isLoading: isUpdating }] =
    useUpdateCustomerMutation();

  const [_customer, _setCustomer] = useState<Customer_Entity | undefined>();

  useEffect(() => {
    _setCustomer(new Customer_Entity(customer));
  }, [customer]);

  const save = async (name: string, numeric?: boolean) => {
    if (customer[name] === _customer[name]) return;
    updateCustomer({
      id: customer.id,
      body: {
        [name]: numeric ? Number(_customer[name]) : _customer[name],
      },
    });
  };

  if (!_customer) return <CircularProgress />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        maxWidth: 640,
      }}
    >
      <Input
        startDecorator="Name"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("name");
        }}
        fullWidth
        onBlur={() => save("name")}
        className="inputBase"
        value={_customer.name || ""}
        onChange={(e) =>
          _setCustomer((c) => {
            c.name = e.target.value;
            return { ...c };
          })
        }
      />
      <Input
        startDecorator="Contact"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("contact");
        }}
        fullWidth
        onBlur={() => save("contact")}
        className="inputBase"
        value={_customer.contact || ""}
        onChange={(e) =>
          _setCustomer((c) => {
            c.contact = e.target.value;
            return { ...c };
          })
        }
      />
      <NerdPhoneField
        value={_customer.phone}
        onAccept={(e) =>
          _setCustomer((c) => {
            c.phone = e;
            return { ...c };
          })
        }
        textFieldProps={{
          label: "Phone Number",
          fullWidth: true,
          disabled: isUpdating,
          size: "small",
          onBlur: () => save("phone"),
        }}
      />
      <Input
        startDecorator="Address"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("address");
        }}
        fullWidth
        onBlur={() => save("address")}
        className="inputBase"
        value={_customer.address || ""}
        onChange={(e) =>
          _setCustomer((c) => {
            c.address = e.target.value;
            return { ...c };
          })
        }
      />
      <Input
        startDecorator="Zip"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("zipcode");
        }}
        fullWidth
        onBlur={() => save("zipcode")}
        className="inputBase"
        value={_customer.zipcode || ""}
        onChange={(e) =>
          _setCustomer((c) => {
            c.zipcode = e.target.value;
            return { ...c };
          })
        }
      />
      <Input
        startDecorator="City"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("city");
        }}
        fullWidth
        onBlur={() => save("city")}
        className="inputBase"
        value={_customer.city || ""}
        onChange={(e) =>
          _setCustomer((c) => {
            c.city = e.target.value;
            return { ...c };
          })
        }
      />
      <Input
        startDecorator="State"
        onKeyPress={(e) => {
          if (e.key === "Enter") save("state");
        }}
        fullWidth
        onBlur={() => save("state")}
        className="inputBase"
        value={_customer.state || ""}
        onChange={(e) =>
          _setCustomer((c) => {
            c.state = e.target.value;
            return { ...c };
          })
        }
      />
    </Box>
  );
}
