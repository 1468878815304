import {
  Box,
  Button,
  Divider,
  Input,
  List,
  ListItemButton,
  Sheet,
  Tab,
  TabList,
  Tabs,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Project_Entity } from "entities/project";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useAllProjects } from "redux/project/projectHooks";
import {
  useCreateSubRuleMutation,
  useLazyGetSubRulesQuery,
} from "redux/subRule/subRuleEndpoints";

/**
 *
 * @returns {ReactElement} ImportRules page
 */
export function ImportRules() {
  const projectID = useSelector(selectedProjectIDSelector);
  const [searchText, setSearchText] = useState("");
  const [mode, setMode] = useState("template");
  const [getSubRules] = useLazyGetSubRulesQuery();
  const { projects } = useAllProjects({
    filters: JSON.stringify([
      {
        name: "version",
        value: 2,
        comparison: "eq",
      },
    ]),
  });

  const askQuestion = useAskQuestion();

  const [createSubRule] = useCreateSubRuleMutation();

  const importSubRules = async (project: Project_Entity) => {
    const subRules = await getSubRules({
      filters: JSON.stringify([
        {
          name: "sub_rules.projectID",
          comparison: "eq",
          value: project.id,
        },
      ]),
    });

    if (subRules?.data?.ids?.length > 0) {
      subRules.data.ids.forEach((id) => {
        createSubRule({ ...subRules.data[id], projectID });
        onClose();
      });
    } else {
      askQuestion("No Rules found,", ["No", "Yes"], {
        subtitle: (
          <Typography>
            The selected Project does not contain any Rules.
            <br />
            Do you want to select another Project?
          </Typography>
        ),
      }).then((i) => {
        if (i == 2) onClose();
      });
    }
  };

  const open = useSelector(formOpenSelector("importRule"));
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "importRule" }));
  };

  return (
    <DialogClose
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{ gap: 0 }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box sx={{ p: 1 }}>
          <Typography
            level="h6"
            startDecorator={<i className="fa-solid fa-arrow-down"></i>}
          >
            Import Rules
          </Typography>
          <Typography level="body2">
            Select the Template/Project you wish to import the Rules from:
          </Typography>
        </Box>
      </DialogTitle>
      <Sheet variant="soft" sx={{ p: 0 }}>
        <Tabs
          onChange={(e, v) => {
            setMode(`${v}`);
          }}
          value={mode}
          size="sm"
          variant="soft"
          sx={{ borderRadius: 3 }}
        >
          <TabList variant="outlined" color="neutral">
            <Tab value="template">Templates</Tab>
            <Tab value="project">Projects</Tab>
          </TabList>
        </Tabs>
        <Input
          autoFocus
          size="sm"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
          sx={{ m: 1, background: "#eeeeee" }}
          variant="outlined"
          placeholder="Search..."
        />
        <Box sx={{ height: 400, mt: 0, overflow: "auto" }}>
          <List size="sm">
            {projects
              .filter((p) => (mode === "template" ? p.template : true))
              .filter((p) =>
                searchText
                  ? p.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
                  : true
              )
              .map((p) => (
                <ListItemButton
                  onClick={() => importSubRules(p)}
                  key={p.id}
                  sx={{
                    "&:hover": {
                      background: "#eeeeee",
                    },
                  }}
                >
                  {p.name}
                </ListItemButton>
              ))}
          </List>
        </Box>
        {projects.length === 0 ? (
          <Typography>You don't have any Maestro Projects.</Typography>
        ) : (
          []
        )}
      </Sheet>
      <Divider />
      <DialogActions>
        <Button color="neutral" variant="soft" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
