import { Dictionary } from "@reduxjs/toolkit";
import { Project_Entity } from "entities/project";
import { Tag_Entity } from "entities/tag";
import { WorkSession_Entity } from "entities/workSession";
import moment, { Moment } from "moment";
import {
  setSelectedProjectID,
  setSelectedTagID,
  setSelectedWorkSessionID,
} from "reducers/rhapsody";
import { rhapsodyApi } from "redux/api";
import { autopilotApi } from "redux/api/autopilotApi";

const time: Dictionary<Moment> = {};
const planned: Dictionary<any> = {};

export function centrifugoMiddleware(store) {
  return (next) => (action) => {
    const state = store.getState();
    const dispatch = store.dispatch;
    const centrifugo = state.centrifugo;

    if (action.type == setSelectedProjectID) {
      if (action.payload) {
        const endpoints = rhapsodyApi.endpoints as any;
        const result = store.dispatch(
          endpoints.getProject.initiate(action.payload)
        );
        result.then(
          ({
            data: project,
            isSuccess,
          }: {
            data: Project_Entity;
            isSuccess: boolean;
          }) => {
            if (
              isSuccess &&
              !centrifugo._subs[`rhapsody:${project.publicToken}`]
            ) {
              const sub = centrifugo.newSubscription(
                `rhapsody:${project.publicToken}`
              );
              sub.on("publication", function (ctx) {
                const data = ctx.data;
                console.log(data.resource);
                if (data.id && data.resource === "projects") {
                  proceedWithBuffer(() => {
                    dispatch(
                      rhapsodyApi.util.invalidateTags([
                        "projectHirings",
                        "projectAlternates",
                        "projectSections",
                        "projects",
                        "hiringNotify",
                        "projectMercury",
                      ])
                    );
                  }, "project");
                }
                if (data.id && data.resource === "workSessions") {
                  proceedWithBuffer(() => {
                    dispatch(
                      rhapsodyApi.util.invalidateTags([
                        "projectHirings",
                        "workSessionSections",
                      ])
                    );
                  }, "workSessions");
                }
                if (data.id && data.resource === "assignments") {
                  proceedWithBuffer(() => {
                    dispatch(
                      rhapsodyApi.util.invalidateTags([
                        "assignments",
                        "projectMercury",
                        "chairs",
                      ])
                    );
                  }, "assignments");
                }
                if (data.id && data.resource === "autopilot") {
                  proceedWithBuffer(() => {
                    dispatch(
                      autopilotApi.util.invalidateTags(["events", "autopilot"])
                    );
                  }, "autopilot");
                }
                if (data.id && data.resource === "jobs") {
                  proceedWithBuffer(() => {
                    dispatch(
                      rhapsodyApi.util.invalidateTags([
                        "workSessionSections",
                        "projectHirings",
                      ])
                    );
                  }, "jobs");

                  time["jobs"] = moment();
                }
              });
              sub.subscribe();
            }
          }
        );
      }
    }
    if (action.type == setSelectedTagID) {
      if (action.payload) {
        const endpoints = rhapsodyApi.endpoints as any;
        const result = store.dispatch(
          endpoints.getTag.initiate(action.payload)
        );

        result.then(
          ({
            data: tag,
            isSuccess,
          }: {
            data: Tag_Entity;
            isSuccess: boolean;
          }) => {
            if (isSuccess && !centrifugo._subs[`rhapsody:${tag.publicToken}`]) {
              const sub = centrifugo.newSubscription(
                `rhapsody:${tag.publicToken}`
              );

              sub.on("publication", function (ctx) {
                const data = ctx.data;

                if (data.id && data.resource === "missions") {
                  proceedWithBuffer(() => {
                    dispatch(rhapsodyApi.util.invalidateTags(["missions"]));
                  }, "missions");
                  proceedWithBuffer(() => {
                    dispatch(
                      rhapsodyApi.util.invalidateTags(["seasonMercury"])
                    );
                  }, "seasonMercury");
                }
                if (data.id && data.resource === "tags") {
                  proceedWithBuffer(() => {
                    dispatch(rhapsodyApi.util.invalidateTags(["tags"]));
                  }, "tags");
                }
              });

              sub.subscribe();
            }
          }
        );
      }
    }

    if (action.type == setSelectedWorkSessionID) {
      if (action.payload) {
        const endpoints = rhapsodyApi.endpoints as any;
        const result = store.dispatch(
          endpoints.getWorkSession.initiate(action.payload)
        );

        result.then(
          ({
            data: workSession,
            isSuccess,
          }: {
            data: WorkSession_Entity;
            isSuccess: boolean;
          }) => {
            if (
              isSuccess &&
              !centrifugo._subs[`rhapsody:${workSession.publicToken}`]
            ) {
              const sub = centrifugo.newSubscription(
                `rhapsody:${workSession.publicToken}`
              );

              sub.on("publication", function (ctx) {
                const data = ctx.data;
                if (data.id && data.resource === "jobs")
                  store.dispatch(
                    rhapsodyApi.util.invalidateTags(["workSessionSections"])
                  );
                if (data.id && data.resource === "workSessions") {
                  store.dispatch(
                    rhapsodyApi.util.invalidateTags(["workSessions"])
                  );
                }
              });

              sub.subscribe();
            }
          }
        );
      }
    }

    next(action);
  };
}

function proceedWithBuffer(
  func: () => void,
  key: string,
  maxEllasped?: number
) {
  const _maxEllapsed = maxEllasped ?? 5;
  if (time[key] && moment().diff(time[key], "s") < _maxEllapsed) {
    // we ignore the "ws" but we make sure that the func will be executed;
    if (!planned[key]) {
      planned[key] = setTimeout(() => {
        func();
        planned[key] = undefined;
        time[key] = undefined;
      }, _maxEllapsed * 1000);
    }
  } else {
    func();
    time[key] = moment();
  }
}
