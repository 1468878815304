import { networkMiddleware, rtkQueryErrorLogger } from "@nerdjs/nerd-network";
import { configureStore } from "@reduxjs/toolkit";
import { centrifugoMiddleware } from "centrifugoMiddleware";
import { rhapsodyApi } from "redux/api";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import { museApi } from "redux/api/museApi";
import { mercuryApi } from "redux/api/mercuryApi";
import { autopilotApi } from "redux/api/autopilotApi";
import { postalApi } from "redux/api/postalApi";
import { accountKitApi } from "@nerdjs/account-kit";
import { unityApi } from "redux/api/unity";
import { accountApi } from "redux/api/accountApi";
import { middleware420 } from "middleware420";
import { paydayApi } from "redux/api/paydayApi";
import { errorMiddleware } from "errorMiddleware";
import { librarianApi } from "redux/api/librarian";

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunkMiddleware,
    accountKitApi.middleware,
    rhapsodyApi.middleware,
    museApi.middleware,
    mercuryApi.middleware,
    autopilotApi.middleware,
    unityApi.middleware,
    postalApi.middleware,
    accountApi.middleware,
    librarianApi.middleware,
    paydayApi.middleware,
    centrifugoMiddleware,
    rtkQueryErrorLogger,
    networkMiddleware,
    middleware420,
    errorMiddleware,
  ],
});

export type RootState = ReturnType<typeof rootReducer>;
