// import PropTypes from 'prop-types';

// styles
import { Button, Typography } from "@mui/joy";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useNavigate } from "react-router-dom";
import { useUpdateVenueMutation } from "redux/venue/venueEndpoints";
import { useVenue } from "redux/venue/venueHooks";

export default function VenueDangerZone({ venueID }) {
  const { venue } = useVenue(venueID);
  const [updateVenue] = useUpdateVenueMutation();

  const askQuestion = useAskQuestion();

  const navigate = useNavigate();

  if (!venue) return <Skeleton />;
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Button
        color="danger"
        onClick={async () => {
          askQuestion(
            "You're about to delete this Venue",
            ["Cancel", "Delete"],
            {
              subtitle: (
                <Typography>
                  You won't be able to recover anything from this venue.
                </Typography>
              ),
            }
          ).then((t) => {
            if (t) {
              updateVenue({
                id: venue.id,
                body: {
                  deleted: true,
                },
              });
              navigate(RouterConfig.venues);
            }
          });
        }}
      >
        Delete permanently
      </Button>
      <Typography level="body2">
        You won't be able to recover anything from this venue.
        <br />
        Existing Projects and Work Sessions won't be affected.
      </Typography>
    </Box>
  );
}
