import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { ProjectNote_Entity } from "entities/projectNote";
import { rhapsodyApi } from "../api/rhapsodyApi";

const projectNotesAdapter = createEntityAdapter<ProjectNote_Entity>();
const initialState = projectNotesAdapter.getInitialState();

export const projectNoteEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectNotes: build.query<
      EntityState<ProjectNote_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `projectNotes`,
          params: args,
        };
      },
      transformResponse: (responseData: ProjectNote_Entity[]) => {
        return projectNotesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["projectNotes"],
    }),
    getProjectNote: build.query<ProjectNote_Entity, number>({
      query: (id) => `projectNotes/${id}`,
      providesTags: (result, error, id) => [{ type: "projectNotes", id }],
    }),
    createProjectNote: build.mutation<
      ProjectNote_Entity,
      Partial<ProjectNote_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `projectNotes`,
      }),
      invalidatesTags: ["projectNotes"],
    }),
    updateProjectNote: build.mutation<
      void,
      { id: number; body: Partial<ProjectNote_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projectNotes/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "projectNotes", id },
        "projectNotes",
      ],
    }),
    deleteProjectNote: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projectNotes/${id}`,
      }),
      invalidatesTags: ["projectNotes"],
    }),
  }),
});

export const {
  useGetProjectNoteQuery,
  useGetProjectNotesQuery,
  useCreateProjectNoteMutation,
  useDeleteProjectNoteMutation,
  useUpdateProjectNoteMutation,
} = projectNoteEndpoints;

export default projectNoteEndpoints;

export const selectProjectNotesResult =
  projectNoteEndpoints.endpoints.getProjectNotes.select();

const selectProjectNotesData = createSelector(
  selectProjectNotesResult,
  (projectNotesResult) => projectNotesResult.data
);

const store = configureStore({
  reducer: {
    [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const {
  selectAll: selectAllProjectNotes,
  selectById: selectProjectNoteById,
} = projectNotesAdapter.getSelectors<RootState>(
  (state) => selectProjectNotesData(state) ?? initialState
);
