import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { PdProject_Entity } from "entities/pdProject";
import { paydayApi } from "redux/api/paydayApi";

const pdProjectsAdapter = createEntityAdapter<PdProject_Entity>();
const initialState = pdProjectsAdapter.getInitialState();

export const pdProjectEndpoints = paydayApi.injectEndpoints({
  endpoints: (build) => ({
    getPdProjects: build.query<
      EntityState<PdProject_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `projects`,
          params: args,
        };
      },
      transformResponse: (responseData: PdProject_Entity[]) => {
        return pdProjectsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["pdProjects"],
    }),
    getPdProject: build.query<PdProject_Entity, number>({
      query: (id) => `projects/${id}`,
      providesTags: (result, error, id) => [{ type: "pdProjects", id }],
    }),
    createPdProject: build.mutation<
      PdProject_Entity,
      Partial<PdProject_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `pdProjects`,
      }),
      invalidatesTags: ["pdProjects"],
    }),
    updatePdProject: build.mutation<
      void,
      { id: number; body: Partial<PdProject_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projects/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "pdProjects", id },
        "pdProjects",
      ],
    }),
    deletePdProject: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projects/${id}`,
      }),
      invalidatesTags: ["pdProjects"],
    }),
  }),
});

export const {
  useGetPdProjectQuery,
  useGetPdProjectsQuery,
  useCreatePdProjectMutation,
  useDeletePdProjectMutation,
  useUpdatePdProjectMutation,
} = pdProjectEndpoints;

export default pdProjectEndpoints;
