import { Variation } from "entities/variation";

/**
 * Instrument_Entity type guard.
 *
 * @param {any} instrumentJson Instrument object from API
 * @returns {boolean} Return true if type is Instrument_Entity
 */

export default class Instrument_Entity {
  constructor(instrumentJson: Instrument_Entity) {
    try {
      this.id = instrumentJson.id;
      this.name = instrumentJson.name;
      this.icon = instrumentJson.icon;
      this.familyID = instrumentJson.familyID;
      this.shorthand = instrumentJson.shorthand;
      this.relationID = instrumentJson.relationID;
      this.variations = instrumentJson.variations;
      this.variation = instrumentJson.variation;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  icon?: string;
  familyID?: number;
  shorthand?: string;
  relationID?: number;
  variations: Array<Variation>;
  variation?: boolean;
}
