import { Company } from "entities/company";
import { useMemo } from "react";
import {
  useGetCompanyQuery,
  useGetCompaniesQuery,
  useGetCurrentCompanyQuery,
} from "./companyEndpoints";
import { Dictionary } from "@reduxjs/toolkit";
import { RosterOptions } from "hooks/projectRoster/projectRoster";

export type CompanySettings = {
  calendarFirstDay?: "0" | "1";
  workSessionSort?: "desc" | "asc";
  assignmentsForSubs?: boolean;
  canContactArchivedMusicians?: boolean;
  hideWhenNotPlaying?: boolean;
  restricted?: boolean;
  apiPreview?: boolean;
  payrollMusicianFields?: string[];
  payrollMusicianCustomFieldIDs?: number[];
  payrollMusicianAggregationFieldIDs?: number[];
  hideApiErrors?: boolean;
  dontShowAgain?: Dictionary<boolean>;
  defaultStrings?: number[];
  rosterOptions?: Partial<RosterOptions>;
  defaultDressCodes?: { name: string; content: string }[];
  defaultProjectNotes?: { name: string; content: string }[];
  stripeBalance?: {
    dueDate: string;
    blocking: boolean;
    show: boolean;
    balance: string;
    rows: {
      id: string;
      invoiceDate: string;
      dueDate: string;
      amount: string;
      status: string;
      url: string;
    }[];
  };
};

export function useCompanySettings() {
  const { company } = useCurrentCompany();

  return useMemo(() => {
    let ret: CompanySettings = {};

    if (company?.settings) {
      try {
        ret = JSON.parse(company.settings) as CompanySettings;
      } catch (error) {
        return ret;
      }
    }
    return ret;
  }, [company]);
}

export function useCurrentCompany() {
  const companyQuery = useGetCurrentCompanyQuery();
  const companyEntity = companyQuery.data;

  return useMemo(() => {
    const ret: typeof companyQuery & { company?: Company | undefined } = {
      ...companyQuery,
    };
    if (companyEntity) ret.company = new Company(companyEntity);
    return ret;
  }, [companyEntity]);
}

export function useCompany(companyID: number | undefined) {
  const companyQuery = useGetCompanyQuery(companyID ?? 0, {
    skip: (companyID ?? 0) <= 0,
  });
  const companyEntity = companyQuery.data;

  return useMemo(() => {
    const ret: typeof companyQuery & { company?: Company | undefined } = {
      ...companyQuery,
    };
    if (companyEntity) ret.company = new Company(companyEntity);
    return ret;
  }, [companyEntity]);
}

export function useCompanies() {
  const companyQuery = useGetCompaniesQuery();
  const companyEntitiesMap = companyQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof companyQuery & {
      companies?: Company[];
      companiesMap?: { [id: number]: Company };
    } = {
      ...companyQuery,
      companies: [],
      companiesMap: {},
    };
    if (companyEntitiesMap) {
      const companies = [];
      const companiesMap = {};

      for (const key in companyEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(companyEntitiesMap, key)) {
          const c = companyEntitiesMap[key];
          const company = new Company(c);
          companies.push(company);
          companiesMap[company.id] = company;
        }
      }
      ret.companies = companies;
      ret.companiesMap = companiesMap;
    }

    return ret;
  }, [companyEntitiesMap]);
}
