import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';

// material-ui
import Typography from "@mui/material/Typography";

// styles
import {
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import ColorPicker from "atoms/ColorPicker";
import FileUpload from "atoms/FileUpload";
import { isConciergeReadOnly } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectedProjectIDSelector } from "reducers/rhapsody";
import { useCustomers } from "redux/customer/customerHooks";
import { usePayrolls } from "redux/payroll/payrollHooks";
import { useProject } from "redux/project/projectHooks";
import { meSelector } from "selectors/me";

import { Checkbox, Input } from "@mui/joy";
import { useUpdateProjectMutation } from "redux/project/projectEndpoints";
import { layoutDebugSelector } from "reducers/v2/missionControl";

export default function ProjectDescription({ id }) {
  const projectID = id || useSelector(selectedProjectIDSelector);
  const { customers } = useCustomers();
  const [updateProject] = useUpdateProjectMutation();
  const { project: _project } = useProject(projectID);
  const [project, setProject] = useState();
  const { payrolls } = usePayrolls();
  const me = useSelector(meSelector);
  const conciergeReadOnly = isConciergeReadOnly(project, me);
  const archived = project?.archived ?? false;
  const debug = useSelector(layoutDebugSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    setProject(_project);
  }, [_project]);

  useEffect(() => {
    // checkbox
    if (!_project || !project) return;
    if (_project.color != project.color) save("color");
    if (_project.template != project.template) save("template");
    if (_project.logoURL != project.logoURL) save("logoURL");
    if (_project.emailNotes != project.emailNotes) save("emailNotes");
    if (_project.hideEmailLogo != project.hideEmailLogo) save("hideEmailLogo");
    if (_project.hideRosterLogo != project.hideRosterLogo)
      save("hideRosterLogo");
    if (_project.configurationCode != project.configurationCode)
      save("configurationCode");
    if (_project.customerID != project.customerID) save("customerID");
    if (_project.payrollID != project.payrollID) save("payrollID");
  }, [project]);

  const handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    setProject({ ...project, [name]: value });
  };

  const save = async (name, numeric) => {
    if (project[name] === _project[name]) return;

    updateProject({
      id: project.id,
      body: {
        ...project,
        [name]: numeric ? Number(project[name]) : project[name],
      },
    });
  };

  if (!project) {
    return <div />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}
            >
              <Typography variant="h6" color="textPrimary">
                About the Project
              </Typography>
              <Input
                startDecorator="Name:"
                onKeyPress={(e) => {
                  if (e.key === "Enter") save("name");
                }}
                disabled={archived || conciergeReadOnly}
                fullWidth
                onBlur={() => save("name")}
                className="Input"
                value={project.name || ""}
                onChange={handleChange("name")}
              />
              <Input
                startDecorator="Contractor:"
                onKeyPress={(e) => {
                  if (e.key === "Enter") save("contractor");
                }}
                disabled={archived || conciergeReadOnly}
                fullWidth
                onBlur={() => save("contractor")}
                className="Input"
                value={project.contractor}
                onChange={handleChange("contractor")}
              />
              <Input
                startDecorator="Composer:"
                onKeyPress={(e) => {
                  if (e.key === "Enter") save("composer");
                }}
                disabled={archived || conciergeReadOnly}
                fullWidth
                onBlur={() => save("composer")}
                className="Input"
                value={project.composer}
                onChange={handleChange("composer")}
              />
              <Input
                startDecorator="Leader:"
                onKeyPress={(e) => {
                  if (e.key === "Enter") save("leader");
                }}
                disabled={archived || conciergeReadOnly}
                fullWidth
                onBlur={() => save("leader")}
                className="Input"
                value={project.leader}
                onChange={handleChange("leader")}
              />
              <Input
                onKeyPress={(e) => {
                  if (e.key === "Enter") save("agreement");
                }}
                disabled={archived || conciergeReadOnly}
                fullWidth
                startDecorator="Wage Scale:"
                onBlur={() => save("agreement")}
                className="Input"
                value={project.agreement}
                onChange={handleChange("agreement")}
              />
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                  <Typography>Project Colors:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <ColorPicker
                    onChange={(c) => {
                      setProject((pro) => ({ ...pro, color: c }));
                    }}
                    disabled={archived || conciergeReadOnly}
                    color={project.color}
                  />
                </Grid>
              </Grid>
              {debug ? (
                <Checkbox
                  onChange={(e) =>
                    setProject((pro) => ({
                      ...pro,
                      template: e.target.checked,
                    }))
                  }
                  value={project.template}
                  label="Template"
                />
              ) : (
                []
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}
            >
              <Typography variant="h6" color="textPrimary">
                Project Cover
              </Typography>
              <Typography variant="caption" color="textSecondary">
                The Roster and emails will show this cover instead of your
                company's logo.
              </Typography>
              <div style={{ marginBottom: 20 }}>
                <Grid container spacing={1}>
                  <Grid item>
                    <FileUpload
                      cropDimension={{ width: 250, height: 250 }}
                      disabled={archived || conciergeReadOnly}
                      onUpload={(f) => {
                        setProject((pro) => ({ ...pro, logoURL: f }));
                      }}
                    />
                  </Grid>
                  {project.logoURL ? (
                    <Grid item>
                      <Chip
                        onClick={() => {
                          setProject((pro) => ({ ...pro, logoURL: "" }));
                        }}
                        style={{ marginBottom: 4 }}
                        label="Remove Logo"
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                  ) : (
                    []
                  )}
                </Grid>
                {project.logoURL ? (
                  <img
                    src={project.logoURL}
                    alt="Your Logo"
                    style={{
                      width: 100,
                      border: "solid 1px rgba(155,155,155,0.3)",
                      borderRadius: "16px",
                    }}
                  />
                ) : (
                  []
                )}
                {/* <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Help
                      title="Hide Logo"
                      help="Your logo won't show in the Email"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={project.hideEmailLogo}
                          disabled={archived || conciergeReadOnly}
                          size="small"
                          onChange={(e) => {
                            setProject((pro) => ({
                              ...pro,
                              hideEmailLogo: e.target.checked,
                            }));
                          }}
                        />
                      }
                      label=""
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Help
                      title="Hide Roster Logo"
                      help="Your logo won't show in the Roster"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={project.hideRosterLogo}
                          disabled={archived || conciergeReadOnly}
                          size="small"
                          onChange={(e) => {
                            setProject((pro) => ({
                              ...pro,
                              hideRosterLogo: e.target.checked,
                            }));
                          }}
                        />
                      }
                      label=""
                    />
                  </Grid>
                </Grid> */}
                {project.configurations && project.configurations.length ? (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={8}>
                      <FormControl fullWidth>
                        <Select
                          inputProps={{
                            name: "Project",
                            id: "project-simple",
                          }}
                          disabled={archived || conciergeReadOnly}
                          value={project.configurationCode}
                          onChange={(e) => {
                            setProject((pro) => ({
                              ...pro,
                              configurationCode: e.target.checked,
                            }));
                          }}
                        >
                          {project?.configurations.map((c) => (
                            <MenuItem
                              key={`configurations_${c.id}`}
                              value={c.code}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    alt="appLogo"
                                    src={c.appLogo}
                                    style={{ width: 200 }}
                                  />
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}
            >
              <Typography variant="h6" color="textPrimary">
                Related
              </Typography>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2">
                    Customer / Billing Entity:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <Select
                      inputProps={{
                        name: "Project",
                        id: "project-simple",
                      }}
                      disabled={archived || conciergeReadOnly}
                      value={project.customerID}
                      onChange={(e) => {
                        setProject((pro) => ({
                          ...pro,
                          customerID: e.target.value,
                        }));
                      }}
                    >
                      {customers?.map((c) => (
                        <MenuItem key={`customer_${c.id}`} value={c.id}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2">Payroll:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <Select
                      inputProps={{
                        name: "Payroll",
                        id: "Payroll-simple",
                      }}
                      disabled={archived || conciergeReadOnly}
                      value={project.payrollID}
                      onChange={(e) => {
                        setProject((pro) => ({
                          ...pro,
                          payrollID: e.target.value,
                        }));
                      }}
                    >
                      {payrolls?.map((p) => (
                        <MenuItem key={`payroll_${p.id}`} value={p.id}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
