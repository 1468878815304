import { show } from "@intercom/messenger-js-sdk";
import { Button, Typography } from "@mui/joy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AppConfig } from "environement";
import { useCurrentSubscription } from "redux/subscription/subscriptionHooks";

/**
 *
 * @returns {ReactElement} FormRestrictedAccount page
 */
export function RestrictedAccount() {
  const { subscription } = useCurrentSubscription();

  return (
    <Dialog open>
      <DialogTitle>
        <Typography level="h6">
          Your Rhapsody subscription has been paused
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        Contact us to reactivate.
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="solid"
          startDecorator={<i className="fa-solid fa-comment"></i>}
          onClick={() => {
            show();
          }}
        >
          Chat with us
        </Button>
      </DialogActions>
    </Dialog>
  );
}
